import {useLocalization} from "../hooks/Utils/useLocalization";
import {useRef} from "react";
import {copyToClipboard} from "./Utility";
import classNames from "classnames";

const CopyToClipboard = (props) => {

    const { text, className, iconClassName = "text-[18px]" } = props;
    const strings = useLocalization();

    const copiedRef = useRef();

    const handleCopy = (text) => {
        copyToClipboard(text);

        copiedRef.current.classList.remove("hidden");
        setTimeout(() => copiedRef.current.classList.add("hidden"), 1000);
    }

    return (
        <button
            type="button"
            className={classNames("relative flex-shrink-0", {
                [className]: !!className
            })}
            onClick={() => handleCopy(text)}
        >
          <span
              className={classNames("material-symbols-outlined", {
                  [iconClassName]: !!iconClassName
              })}
          >
            content_copy
          </span>
          <div ref={copiedRef} className="tooltip-top-content hidden duration-500">
            <p>{strings.member.member_detail.profile.copied}</p>
          </div>
        </button>
    )
}

export default CopyToClipboard;