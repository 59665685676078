import React, { useEffect, useState } from "react";
import { Loading, showTopAlert } from "../../../../components";
import { ISOStringToDate, calcTaxPrice, formatCurrency, getCompanyLogoByName, isItSpecified } from "../../../../components/Utility";
import useAuth from "../../../../hooks/Log/useAuth";
import { useLocalization } from "../../../../hooks/Utils/useLocalization";
import usePrimeData from "../../../../hooks/Utils/usePrimeData";
import { GetInvoicesById } from "../../../../services/Accounting/CurrentCardService";
import { Accordion } from "../../../Member/components";

const ShowInvoiceModal = (props) => {

  const { currentCardId, name, closeButton } = props;
  const strings = useLocalization();
  const notSpecified = strings.errors.it_is_not_specified;

  const { employee } = useAuth();
  const primeData = usePrimeData();

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const getCurrentCardInvoices = async (currentCardId) => {

    const result = await GetInvoicesById(currentCardId);

    if (result.status === 200) {
      let resultContent = JSON.parse(result.content);

      setData(data => resultContent);

      setLoading(loading => false);
    } else {
      showTopAlert(strings.errors.an_error_occurred, "error");
      setTimeout(() => closeButton(), 1000);
    }
  }

  //Her bir satıra ait toplam tutarı hesaplar.
  const calcTotalPriceForPackage = (unitPrice, taxValue, quantity) => {
    let taxPrice = calcTaxPrice(unitPrice, taxValue);
    return (parseFloat(unitPrice) + taxPrice) * quantity;
  }

  //Mal Hizmet toplam tutarını hesaplar.
  const calcTotalUnitPrice = (data) => {
    let result = 0.0;

    data.map((detail) => {
      result += (detail.quantity * parseFloat(detail.unitPrice))
    })

    return result;
  }

  //Hesaplanan KDV Gerçek tutarını hesaplar.
  const calcTotalTaxPrice = (data) => {
    let result = 0.0;

    data.map((detail) => {
      result += calcTaxPrice((detail.unitPrice * detail.quantity), detail.vatRate);
    })

    return result;
  }

  //Vergiler Dahil Toplam Tutar hesaplar.
  const calcTaxValueAddedPrice = (data) => {
    let totalUnitPrice = calcTotalUnitPrice(data);
    let totalTaxPrice = calcTotalTaxPrice(data);

    let result = parseFloat(totalUnitPrice) + parseFloat(totalTaxPrice);

    return formatCurrency(result);
  }

  useEffect(() => {
    if (currentCardId) {
      getCurrentCardInvoices(currentCardId);
    }
  }, [])

  return (
    <>
      <div className="flex items-center gap-2">
        <p className="font-bold text-[15px]">
          {name}{" "}{strings.accounting.current_card.invoice_information}
        </p>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div className={`!h-[80vh] mr-0 pr-3 ${data.invoices.length > 0 ? 'overflow-y-scroll' : ''}`}>
          {data.invoices.length > 0 ? (
            data.invoices.map((item, index) => {

              let companyDetails = primeData.localCompanyDetails.find(company => company.name === item.companyName);

              return (
                <Accordion
                  key={index}
                  title={`${strings.accounting.current_card.total_invoice_price}: ${calcTaxValueAddedPrice(item.details)}`}
                  outSideClose={true}
                  isOpen={index === 0 ? true : ''}
                  content={
                    <>
                      <div className="flex flex-col gap-4 px-[10px]">
                        <div className="flex flex-col md:flex-row gap-3 md:justify-between w-full">
                          <div>
                            <img
                              src={getCompanyLogoByName(companyDetails.name)}
                              width="200"
                              alt="logo"
                            />
                            <p className="font-bold">
                              {companyDetails.name}
                            </p>
                            <p className='max-w-[400px]'>
                              {companyDetails.address}
                            </p>
                            <p>
                              <span className='font-bold'>{strings.form_info.tax_number}:</span>
                              {companyDetails.taxNumber} ({companyDetails.taxOffice})
                            </p>
                          </div>
                          <div>
                            <p><span className='font-bold'>{strings.form_info.dear},</span> {isItSpecified(data.currentCardName, notSpecified)}</p>
                            <p><span className='font-bold'>{strings.form_info.city}:</span> {isItSpecified(data.address, notSpecified)}</p>
                            <p><span className='font-bold'>{strings.form_info.tax_number}:</span> {isItSpecified(data.taxNumber, notSpecified)}</p>
                            <p><span className='font-bold'>{strings.form_info.tax_office}:</span> {isItSpecified(data.taxOffice, notSpecified)}</p>
                            <p><span className='font-bold'>{strings.form_info.invoice_date}:</span> {item.createDate}</p>
                            <p><span className='font-bold'>{strings.form_info.payment_date}:</span> {ISOStringToDate(item.paymentDate, employee.TimeZone)}</p>
                          </div>
                        </div>
                        <div className="overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
                          <div className="inline-block min-w-full rounded-lg overflow-hidden">
                            <table className="min-w-full leading-normal text-header">
                              <thead>
                                <tr>
                                  <th className="th"><p>{strings.table.number}</p></th>
                                  <th className="th"><p className="min-w-[150px]">{strings.table.service_product_name}</p></th>
                                  <th className="th"><p className="min-w-[200px]">{strings.form_info.description}</p></th>
                                  <th className="th"><p>{strings.table.amount}</p></th>
                                  <th className="th"><p>{strings.table.unit_price}</p></th>
                                  <th className="th"><p>{strings.form_info.kdv_rate}</p></th>
                                  <th className="th"><p>{strings.table.total}</p></th>
                                </tr>
                              </thead>
                              <tbody>
                                {item.details.length > 0 && item.details.map((detail, index) => (
                                  <tr key={index} className="bg-white hover:bg-[#f0f0f0]">
                                    <td className="td align-middle">
                                      <p>{index + 1}</p>
                                    </td>
                                    <td className="td align-middle">
                                      <p>{detail.productName}</p>
                                    </td>
                                    <td className="td align-middle">
                                      <p>{detail.description}</p>
                                    </td>
                                    <td className="td align-middle">
                                      <p>{detail.quantity}</p>
                                    </td>
                                    <td className="td align-middle">
                                      <p>{formatCurrency(detail.unitPrice)}</p>
                                    </td>
                                    <td className="td align-middle">
                                      <p>%{detail.vatRate}</p>
                                    </td>
                                    <td className="td align-middle">
                                      <p>{formatCurrency(calcTotalPriceForPackage(detail.unitPrice, detail.vatRate, detail.quantity))}</p>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <table className="mt-5 p-2 ml-auto max-w-[500px]">
                        <tbody>
                          <tr>
                            <td className="td">{strings.member.member_detail.accounting.goods_service_total_amount}</td>
                            <td className="td">{formatCurrency(calcTotalUnitPrice(item.details))}</td>
                          </tr>
                          <tr>
                            <td className="td">{strings.member.member_detail.accounting.calculated_kdv_real}</td>
                            <td className="td">{formatCurrency(calcTotalTaxPrice(item.details))}</td>
                          </tr>
                          <tr>
                            <td className="font-bold td">{strings.member.member_detail.accounting.tax_includes_total_amount}</td>
                            <td className="font-bold td">{calcTaxValueAddedPrice(item.details)}</td>
                          </tr>
                        </tbody>
                      </table>
                      <p className="font-bold mt-4">
                        {strings.member.member_detail.accounting.invoice_note}
                      </p>
                      <p>{item.description}</p>
                    </>
                  }
                />
              )
            })
          ) : (
            <div className="py-4 min-w-full text-center hover:bg-[#f0f0f0]">{strings.errors.no_records_found}</div>
          )}
        </div>
      )}
    </>
  );
};

export default ShowInvoiceModal;
