import { fetchApi } from "../FetchApi"

export const GetBrands = async () => {
    const url = '/member/v1/brand/all/';

    const payload = {};

    return await fetchApi(url, payload);
}

export const GetTeamLeaders = async () => {
    const url = '/employee/v1/employee/team_leads/';

    const payload = {};

    return await fetchApi(url, payload);
}

export const GetEmployeeTypes = async () => {
    const url = '/employee/v1/employee_type/all/';

    const payload = {};

    return await fetchApi(url, payload);
}

export const GetEmployeeStatus = async () => {
    const url = '/employee/v2/employee/get_employee_status/';

    const payload = {};

    return await fetchApi(url, payload);
}

export const GetMemberFormStatus = async () => {
    const url = '/member/v2/member/get_member_form_status/';

    const payload = {};

    return await fetchApi(url, payload);
}

export const GetMemberResource = async () => {
    const url = '/member/v1/member_form/utm_source_select_list/';

    const payload = {};

    return await fetchApi(url, payload);
}

export const GetEmployees = async () => {
    const url = '/employee/v1/employee/get_all_employees_selectlist/';

    const payload = {};

    return await fetchApi(url, payload);
}

export const GetLogCategories = async () => {
    const url = '/prime_log/v1/log/get_log_category_list/';

    const payload = {};

    return await fetchApi(url, payload);
}

export const GetTeachers = async () => {
    const url = '/teacher/v1/teacher/all/';

    const payload = {};

    return await fetchApi(url, payload);
}

export const GetTeachersWithJosiah = async () => {
    const url = '/teacher/v1/teacher/get_all_teachers_with_josiah/';

    const payload = {};

    return await fetchApi(url, payload);
}

export const GetWorkingTimes = async () => {
    const url = '/teacher/v1/shift/working-times/';

    const payload = {};

    return await fetchApi(url, payload);
}

export const GetProfessions = async () => {
    const url = '/member/v1/profession/all/';

    const payload = {};

    return await fetchApi(url, payload);
}

export const GetTags = async () => {
    const url = '/member/v1/tag/all/';

    const payload = {};

    return await fetchApi(url, payload);
}

export const GetNotTitles = async () => {
    const url = '/member/v1/member/all_note_titles/';

    const payload = {};

    return await fetchApi(url, payload);
}

export const GetMemberDemandTypes = async () => {

    const url = '/member/v1/member_demand_type/get_member_demand_types/';

    const payload = {};

    return await fetchApi(url, payload);
}

export const GetTransferStatuses = async () => {

    const url = '/member/v1/member_transfer_status/all/';

    const payload = {};

    return await fetchApi(url, payload);
}

export const GetProductSelectList = async () => {

    const url = '/member/v1/product/get_product_select_list/';

    const payload = {};

    return await fetchApi(url, payload);
}

export const GetSourceTypes = async () => {

    const url = '/member/v1/payment/get_source_types/';

    const payload = {};

    return await fetchApi(url, payload);
}

export const GetPaymentTypes = async () => {

    const url = '/member/v1/payment/get_payment_types/';

    const payload = {};

    return await fetchApi(url, payload);
}

export const GetSalesTypes = async () => {

    const url = '/member/v1/sales/get_sales_types/';

    const payload = {};

    return await fetchApi(url, payload);
}

export const GetAllBrandLocalCompanies = async () => {

    const url = '/member/v1/brand/get_all_brand_local_companies/';

    const payload = {};

    return await fetchApi(url, payload);
}

export const GetExerciseTypes = async () => {

    const url = '/member/v1/campaign/get_exercise_types/';

    const payload = {};

    return await fetchApi(url, payload);
}

export const GetBrandLocalCompanyDetails = async () => {

    const url = '/member/v1/brand_local_company/';

    const payload = {};

    return await fetchApi(url, payload);
}

export const GetHolidayList = async () => {

    const url = '/member/v1/holiday/list_all/';

    const payload = { };

    return await fetchApi(url, payload);
}