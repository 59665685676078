import tr from 'date-fns/locale/tr';
import React, {useEffect, useState} from "react";
import DatePicker, {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {useParams} from 'react-router-dom';
import {Dropdown, Input, LoadingSpinner, PageTitle, showTopAlert} from "../../../../../components";
import {checkNumber} from '../../../../../components/Utility';
import {ProductCategoryType, SalesTypeEnum} from '../../../../../consts/Constants';
import useAuth from '../../../../../hooks/Log/useAuth';
import {useLocalization} from "../../../../../hooks/Utils/useLocalization";
import useMemberStatus from '../../../../../hooks/Member/useMemberStatus';
import {GetMemberBrandId} from '../../../../../services/Member/MemberService';
import {GetSalesProducts} from '../../../../../services/Product/ProductService';
import {AddDemoProduct} from '../../../../../services/Sales/SalesService';
import {useSelector} from 'react-redux';
import classNames from 'classnames';
import useDataLayer from "../../../../../hooks/Log/useDataLayer";
import {CustomEventDescriptions, CustomEvents} from "../../../../../consts/DataLayer";
import usePrimeData from "../../../../../hooks/Utils/usePrimeData";
import useAnalytics from "../../../../../hooks/Log/useAnalytics";
import {ClickLogs} from "../../../../../consts/Logs";
import useCreateMemberNote from "../../../../../hooks/Log/useCreateMemberNote";
import {GetMemberDemoRightCount} from "../../../../../services/Member/MemberProfileService";
import useMemberBrand from "../../../../../hooks/Member/useMemberBrand";

const DemoRegistration = () => {

  registerLocale('tr', tr)

  const strings = useLocalization();
  const dataLayer = useDataLayer();
  const analytics = useAnalytics();
  const memberNote = useCreateMemberNote();
  const primeData = usePrimeData();
  const { memberId } = useParams();
  const { employee } = useAuth();
  const memberStatus = useMemberStatus(memberId) || false;
  const brandId = useMemberBrand(memberId) || false;
  const language = useSelector(state => state.localizationStore.language);

  const [productDays, setProductDays] = useState([]);
  const firstProductDay = { id: -1, name: strings.member.member_detail.sales_operations.choose_weekly_lesson_time };
  const productDayList = [firstProductDay, ...productDays];

  const [productMinutes, setProductMinutes] = useState([]);
  const firstProductMinute = { id: -1, name: strings.member.member_detail.sales_operations.choose_daily_lesson_time };
  const productMinuteList = [firstProductMinute, ...productMinutes];

  const [productDay, setProductDay] = useState(firstProductDay);
  const [productMinute, setProductMinute] = useState(firstProductMinute);
  const [lessonCount, setLessonCount] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isRequestSend, setIsRequestSend] = useState(false);
  const [demoRightCount, setDemoRightCount] = useState(0);

  const getSalesProducts = async (productCategoryId, brandId, language) => {

    let tmpProductDays = [];
    let tmpProductMinutes = [];

    const result = await GetSalesProducts(productCategoryId, brandId, language);

    if (result.status === 200) {
      let resultContent = JSON.parse(result.content);

      resultContent?.productDayList.map((item) => {
        tmpProductDays.push({ id: +item.value, name: item.text });
      })

      resultContent?.productMinuteList.map((item) => {
        tmpProductMinutes.push({ id: +item.value, name: item.text });
      })

      setProductDays(productDays => tmpProductDays);
      setProductMinutes(productMinutes => tmpProductMinutes);
    }
  }

  const submitHandle = async (e) => {
    e.preventDefault();

    let valid = true;

    if (productDay.id === -1) {
      valid = false;
    }
    if (productMinute.id === -1) {
      valid = false;
    }
    if (lessonCount === null) {
      valid = false;
    }

    if (valid) {
      const model = {
        brandId: parseInt(brandId),
        memberId: memberId,
        createUserId: employee.EmployeeId,
        salesType: SalesTypeEnum.Demo,
        beginDate: startDate,
        memberStatus: memberStatus,
        status: 1, //term status active
        demoLessonCount: parseInt(lessonCount),
        productOptions: {
          monthId: 6,
          minute: productMinute.id,
          dayCount: productDay.id,
          categoryId: ProductCategoryType.Demo
        }
      };

      setIsRequestSend(isRequestSend => true)
      const result = await AddDemoProduct(model);

      if (result.status === 200) {
        showTopAlert(strings.member.member_detail.sales_operations.demo_registration_success_message, "success");
        setTimeout(() => window.location.href = "/member/detail/profile/" + memberId, 3000);

        //DATA LAYER LOGGED
        dataLayer.useCustomEvents(CustomEvents.EMPLOYEE_EVENTS, CustomEventDescriptions.DEMO_PACKAGE_CREATED);

        //LOGGED
        analytics.useAnalytics(
            ClickLogs.DEMO_TERM_OPENED,
            {
              employeeId: null,
              memberId: memberId
            }
        );

        //NOTE CREATED
        memberNote.useCreateMemberNote(
            memberId,
            `${demoRightCount} adet demo ders hakkı varken sistem üzerinden demo kayıt açıldı.`,
            43 //Default
        );

        clearStates();
      } else {
        showTopAlert(strings.errors.an_error_occurred, "error")
      }

    } else {
      showTopAlert(strings.alert.top_alert.inform, "warning")
    }
    setIsRequestSend(isRequestSend => false)
  }

  const isWeekend = (date) => {
    const day = date.getDay();
    return day === 0 || day === 6;
  };

  const isPassedTwoMonth = (date) => {
    const today = new Date();
    const dayDifference = date.getTime() / (1000 * 60 * 60 * 24) - today.getTime() / (1000 * 60 * 60 * 24);

    //Eğer 2 aydan daha ileri bir tarih olursa false değeri dönmesi sağlanır
    return dayDifference > +process.env.REACT_APP_UPDATE_TERM_START_DATE_MAX_DIFFERENCE;
  };

  const isHoliday = (date) => {
    if (Object.keys(primeData?.holidayList).length > 0) {
      return primeData?.holidayList.some((holiday) => new Date(holiday.date).setHours(0, 0, 0, 0) === new Date(date).setHours(0, 0,0 ,0));
    }
  };

  const isNoLessonDay = (date, productDay) => {
    const day = date.getDay();

    // Eğer haftada 5 günlük bir paket seçilirse sadece haftasonları listelenecektir. 
    //Onu da zaten isHoli,day'de filtreliyoruz

    // Eğer haftada 3 günlük bir paket seçilirse Salı ve Perşmebe günleri filtrelenir.
    if (productDay.id === 3) {
      return day === 2 || day === 4;
    }

    // Eğer haftada 2 günlük bir paket seçilirse Pazartesi, Çarşamba ve Cuma günleri filtrelenir
    if (productDay.id === 2) {
      return day === 1 || day === 3 || day === 5;
    }

    // Yukarıdaki koşulların hiçbiri uymuyorsa, herhangi bir tarihi kabul etmeyin
    return false;
  };

  const isDateActive = (date) => {
    return !isWeekend(date) && !isHoliday(date) && !isNoLessonDay(date, productDay) && !isPassedTwoMonth(date);
  };

  const clearStates = () => {
    setProductDay(productDay => -1);
    setProductMinute(productMinute => -1);
    setLessonCount(lessonCount => null);
  }

  useEffect(() => {
      // startDate, isDateActive işlevinin aktif olduğu ilk tarihe ayarlanır.
      let activeDate = new Date();

      while (!isDateActive(activeDate)) {
        // Bir sonraki tarihi kontrol eder
        activeDate.setDate(activeDate.getDate() + 1);
      }

      setStartDate(activeDate);
  }, [productDay])

  useEffect(() => {
    if (brandId) {
      getSalesProducts(6, +brandId, language);
    }
  }, [brandId, language])

  useEffect(() => {
    setProductDay(productDay => firstProductDay);
    setProductMinute(productMinute => firstProductMinute);
    setLessonCount(lessonCount => null);
    setStartDate(startDate => new Date());
  }, [language])

  useEffect(() => {
    if(memberId) {
      GetMemberDemoRightCount(memberId)
          .then(result => {
            if(result.status === 200) setDemoRightCount(+result.content);
          })
          .catch()
    }
  }, [memberId]);

  return (
    <>
      <form onSubmit={submitHandle} className="mt-5">
        <PageTitle title={strings.member.member_detail.sales_operations.demo_title} />
        <div className="flex flex-col lg:flex-row lg:justify-center">
          <div className="mt-5 w-full lg:w-1/2 lg:max-w-[480px] ml-auto">
            <div className="flex flex-col px-[10px] pb-0">
              <label>
                {strings.member.member_detail.sales_operations.weekly_lesson_time}
              </label>
              <Dropdown
                classnames="md:max-w-full"
                data={productDayList}
                selectedData={productDay}
                setSelectedData={setProductDay}
              />
            </div>
          </div>
          <div className="mt-5 w-full lg:w-1/2 lg:max-w-[480px] mr-auto">
            <div className="flex flex-col px-[10px] pb-0">
              <label htmlFor="demo_minute">
                {strings.member.member_detail.sales_operations.daily_lesson_time}
              </label>
              <Dropdown
                classnames="md:max-w-full"
                data={productMinuteList}
                selectedData={productMinute}
                setSelectedData={setProductMinute}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row lg:justify-center">
          <div className="mt-5 w-full lg:w-1/2 lg:max-w-[480px] ml-auto">
            <div className="flex flex-col px-[10px] pb-0">
              <label htmlFor="demo_lesson_count">
                {strings.member.member_detail.sales_operations.package_lesson_count}
              </label>
              <Input
                id="demo_lesson_count"
                onKeyDown={(e) => !checkNumber(e) && e.preventDefault()}
                value={lessonCount ? lessonCount : ""}
                onChange={(e) => setLessonCount(e.target.value)}
              />
            </div>
          </div>
          <div className="mt-5 w-full lg:w-1/2 lg:max-w-[480px] mr-auto">
            <div className="flex flex-col px-[10px] pb-0">
              <label htmlFor="demo_begin_date">
                {strings.form_info.start_date}
              </label>
              <div className="w-full lg:max-w-[480px] flex flex-col gap-2 relative">
                <label htmlFor='demo_begin_date' className='font-normal'>
                  <DatePicker
                    locale="tr"
                    dateFormat="dd/MM/yyyy"
                    className="form-input w-full lg:max-w-[480px]"
                    selected={startDate}
                    minDate={new Date()}
                    onChange={(date) => { setStartDate(date); setIsOpen(false) }}
                    onClickOutside={() => setIsOpen(false)}
                    filterDate={isDateActive}
                    placeholderText={strings.form_info.date_placeholder}
                    open={isOpen}
                    readOnly={true}
                    onFocus={() => setIsOpen(true)}
                  />
                </label>
                <button 
                  id='demo_begin_date'
                  type="button" 
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <span className="material-symbols-outlined absolute right-6 top-2 text-lg">
                    calendar_today
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <button
          type="submit"
          disabled={isRequestSend}
          className={classNames("button search-button gap-3 text-white bg-blue border-blue mt-7 mx-auto px-10", {
            "opacity-70 hover:opacity-70 cursor-not-allowed": isRequestSend
          })}
        >
          {isRequestSend && <LoadingSpinner />}
          {!isRequestSend && strings.button.save_upper}
        </button>
      </form>
    </>
  );
};

export default DemoRegistration;
