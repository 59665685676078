import { fetchApi } from '../index';

//Eğitmenin çalışma programını getiren servis
export const GetTeacherProgram = async (teacherId) => {

    const url = '/teacher/v1/schedule/list/';

    const payload = {
        teacherId: teacherId
    };

    return await fetchApi(url, payload);
};

//Eğitmene ait dersleri listeleyen servis
export const GetTeacherScheduleTerm = async (teacherId) => {

    const url = '/member/v1/term/teacher_schedule_term/';

    const payload = {
      teacherId: teacherId  
    };

    return await fetchApi(url, payload);
}

//Eğitmene ait missed call'ları listeleyen servis
export const GetTeacherMissedCalls = async (teacherId) => {

    const url = '/member/v1/term/get_missed_call_by_teacherId/';

    const payload = {
      teacherId: teacherId  
    };

    return await fetchApi(url, payload);
}

//İlgili teacher'a ait bloke veya geçiçi bloke listesini getiren servis.
export const BreakList = async (teacherId) => {

    const url = '/teacher/v1/schedule/break_list/';

    const payload = {
        teacherId: teacherId
    };

    return await fetchApi(url, payload);
}

//İlgili teacher'a bloke veya geçiçi bloke ekleyen servis.
export const AddBreak = async (model) => {

    const url = '/teacher/v1/schedule/add_break/';

    const payload = { 
        ...model
    };

    return await fetchApi(url, payload);
}

//İlgili teacher'a eklenen bloke veya geçiçi blokeyi kaldıran servis.
export const DeleteBreak = async (model) => {

    const url = '/teacher/v1/schedule/delete_schedule/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

//Eğitmene ait derslerin detaylarını listeyeleyen servis
export const GetTeacherScheduleTermDetail = async (termId) => { 

    const url = '/member/v1/term/teacher_schedule_term_detail/';

    const payload = {
        termId: termId
    };

    return await fetchApi(url, payload);
}

export const GetTeacherScheduleListInTimeInterval = async (model) => {

    const url = '/teacher/v1/teacher/get_teachers_schedule_list_in_time_interval/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}


export const GetTeacherListWithShift = async () => {
    const url = '/teacher/v1/teacher/get_teacher_list_with_shift/';

    const payload = {};

    return await fetchApi(url, payload);
}

export const GetTeacherNameByTeacherId = async (teacherId) => {
    const url = '/teacher/v1/teacher/name/';

    const payload = {
        teacherId: teacherId
    };

    return await fetchApi(url, payload);
}
