import tr from 'date-fns/locale/tr';
import React, {useEffect, useState} from 'react';
import DatePicker, {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {LoadingSpinner, showTopAlert} from '../../../../../../../components';
import {fullDateToShortDate} from '../../../../../../../components/Utility';
import {useLocalization} from '../../../../../../../hooks/Utils/useLocalization';
import {GetProductDayCountByTermId, UpdateBeginDate} from '../../../../../../../services/Term/TermService';
import usePrimeData from "../../../../../../../hooks/Utils/usePrimeData";
import {ServiceErrorMessages} from "../../../../../../../consts/Constants";
import employee from "../../../../../../Employee/Employee";
import classNames from "classnames";

const TermStartDateModal = (props) => {

    registerLocale('tr', tr)
    const {date, closeButton, termId, createDate, activeTermEndDate, sendRequestToBeginDateAndDayEndpoint} = props;

    const strings = useLocalization();
    const primeData = usePrimeData();

    const [isOpen, setIsOpen] = useState(false);
    const [input, setInput] = useState(new Date(date));
    const [productDayCount, setProductDayCount] = useState(-1);
    const [loading, setLoading] = useState(false);

    //Dönemin ders gün sayısını getiren servis
    const getProductDayCount = async (termId) => {

        const result = await GetProductDayCountByTermId(termId);

        if(result.status === 200)
            setProductDayCount(productDayCount => JSON.parse(result.content));
    }

    const submitHandle = async (e) => {
        e.preventDefault();

        let valid = true;

        if (input === null) {
            valid = false;
            showTopAlert(strings.alert.top_alert.inform, "warning");
        }

        if (valid) {
            setLoading(loading => true);

            //Kullanıcı herhangi bir değişiklik yapmadan 'kaydet' butonuna basarsa endpointe istek atmadan kullanıcıya başarılı alert'ini döndürüyoruz.
            if (fullDateToShortDate(input) === fullDateToShortDate(date)) {
                showTopAlert(strings.member.member_detail.terms.modal.term_begin_date_success, "success");
                setTimeout(() => closeButton(), 3000);

            } else {
                const model = {
                    termId: termId,
                    newBeginDate: fullDateToShortDate(input),
                    createUserId: employee?.EmployeeId
                };

                const result = await UpdateBeginDate(model);

                if (result.status === 200) {
                    showTopAlert(strings.member.member_detail.terms.modal.term_begin_date_success, "success");
                    sendRequestToBeginDateAndDayEndpoint();
                    setTimeout(() => closeButton(), 1500);
                } else {
                    //Spesifik errorlar kontrol edilip, ekrana basılıyor.
                    if(result.content === ServiceErrorMessages.BEGIN_DATE_MUST_BE_EQUAL_OR_GREATER)
                        showTopAlert(strings.member.member_detail.terms.term_start_date_error_messages.begin_date_error, "error");
                    else if(result.content === ServiceErrorMessages.TERM_BEGIN_DATE_AND_BEGIN_DATE_ARE_EQUAL)
                        showTopAlert(strings.member.member_detail.terms.term_start_date_error_messages.term_date_equal_error, "error");
                    else if(result.content === ServiceErrorMessages.ALL_LESSONS_MUST_BE_ACTIVE)
                        showTopAlert(strings.member.member_detail.terms.term_start_date_error_messages.all_lessons_active_error, "error");
                    else if(result.content === ServiceErrorMessages.TIME_EXPIRED_ERROR)
                        showTopAlert(strings.member.member_detail.terms.term_start_date_error_messages.selected_time_expired_error, "error");
                    else if(result.content === ServiceErrorMessages.TWO_HOURS_ERROR)
                        showTopAlert(strings.member.member_detail.terms.term_start_date_error_messages.two_hours_time_error, "error");
                    else
                        showTopAlert(strings.errors.an_error_occurred, "error");
                }
            }

            setLoading(loading => false);
        }
    }

    //Haftasonu kontrolü yapan fonksiyon.
    const isWeekend = (date) => {
        const day = date.getDay();
        return day === 0 || day === 6;
    };

    const isPassedTargetMonth = (date) => {
        let targetDateDifference = +process.env.REACT_APP_UPDATE_TERM_START_DATE_MAX_DIFFERENCE;

        if(activeTermEndDate !== null) {
            const termCreateDate = new Date(activeTermEndDate)
            const dayDifference = date.getTime() / (1000 * 60 * 60 * 24) - termCreateDate.getTime() / (1000 * 60 * 60 * 24);
            return dayDifference > targetDateDifference - 1;
        }

        // bugün üzerine dönem başlangıç tarihi aralığı kadar gün ekleniyor.
        const todayDate = new Date();
        todayDate.setDate(todayDate.getDate() + targetDateDifference);

        return date > todayDate;
    };

    //Tatil günleri kontrolü yapan fonksiyon.
    const isHoliday = (date) => {
        if (Object.keys(primeData?.holidayList).length > 0) {
            return primeData?.holidayList.some((holiday) => new Date(holiday.date).setHours(0, 0, 0, 0) === new Date(date).setHours(0, 0, 0, 0));
        }
    };

    //Cuma günü kontrolü yapan fonksiyon.
    const isFriday = (date) => {
        const day = date.getDay();
        return day === 5;
    };

    //Ders günlerine göre tarihleri ayarlayan fonksiyon.
    const isNoLessonDay = (date, productDay) => {
        const day = date.getDay();

        // Eğer haftada 5 günlük bir paket seçilirse sadece haftasonları listelenecektir.
        //Onu da zaten isHoliday'de filtreliyoruz

        // Eğer haftada 3 günlük bir paket seçilirse Salı ve Perşmebe günleri filtrelenir.
        if (productDay === 3) {
            return day === 2 || day === 4;
        }

        // Eğer haftada 2 günlük bir paket seçilirse Pazartesi, Çarşamba ve Cuma günleri filtrelenir
        if (productDay === 2) {
            return day === 1 || day === 3 || day === 5;
        }

        // Yukarıdaki koşulların hiçbiri uymuyorsa, herhangi bir tarihi kabul etmiyoruz.
        return false;
    };

    //Aktif tarihleri, kontrol fonksiyonlarına göre derleyen genel fonksiyon.
    const isDateActive = (date) => {
        return !isWeekend(date) && !isHoliday(date) && !isNoLessonDay(date, productDayCount) && !isFriday(date) && !isPassedTargetMonth(date);
    };

    // useEffect(() => {
    //     // startDate, isDateActive işlevinin aktif olduğu ilk tarihe ayarlanır.
    //     let activeDate = date;
    //
    //     while (!isDateActive(activeDate)) {
    //         // Bir sonraki tarihi kontrol eder
    //         activeDate.setDate(activeDate.getDate() + 1);
    //     }
    //
    //     setInput(activeDate);
    // }, [])

    useEffect(() => {
        if(termId.length > 0)
            getProductDayCount(termId);
    }, [termId])

    return (
        <>
            <div className="flex justify-center">
                <p className="font-bold text-[15px] mb-2">
                    {strings.member.member_detail.terms.term_start_date}
                </p>
            </div>
            <form onSubmit={submitHandle} className="flex flex-col text-start">
                <label htmlFor="student_name">{strings.member.member_detail.terms.term_start_date}</label>
                <div className='relative'>
                    <DatePicker
                        locale="tr"
                        dateFormat="dd/MM/yyyy"
                        className="form-input w-full"
                        selected={input}
                        minDate={
                            Math.max(
                                new Date(), // Bugünün tarihi
                                activeTermEndDate !== null
                                    ? new Date(new Date(activeTermEndDate).setDate(new Date(activeTermEndDate).getDate() + 1))
                                    : new Date(createDate)
                            )
                        }
                        onChange={(date) => {
                            setInput(date);
                            setIsOpen(false);
                        }}
                        placeholderText={strings.form_info.date_placeholder}
                        onClickOutside={() => setIsOpen(false)}
                        filterDate={isDateActive}
                        open={isOpen}
                        onFocus={() => setIsOpen(true)}
                    />
                    <button type="button" onClick={() => setIsOpen(!isOpen)}>
                        <span className="material-symbols-outlined absolute right-4 top-2 text-lg">
                            calendar_today
                        </span>
                    </button>
                </div>
                <div className="flex gap-3 justify-center">
                    <button
                        type='submit'
                        className={classNames("button search-button gap-3 text-white bg-blue border-blue mt-7 px-10 w-[115px]", {
                            "opacity-70 cursor-not-allowed hover:opacity-70" : loading
                        })}
                        disabled={loading}
                    >
                        {loading && <LoadingSpinner />}
                        {!loading && strings.button.save_upper}
                    </button>
                    <button
                        type='button'
                        className={classNames("button search-button gap-3 text-white bg-red focus:border-red border-red mt-7 px-10", {
                            "opacity-70 cursor-not-allowed hover:opacity-70" : loading
                        })}
                        disabled={loading}
                        onClick={closeButton}
                    >
                        {strings.button.cancel_upper}
                    </button>
                </div>
            </form>
        </>
    )
}

export default TermStartDateModal