import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Loading, PageTitle, Pagination, SearchCount, showTopAlert } from '../../../../../../components'
import { stringToDate } from '../../../../../../components/Utility';
import { useLocalization } from '../../../../../../hooks/Utils/useLocalization';
import { MemberNotesFromClickivo } from '../../../../../../services/Member/MemberOldSystemService';
import { GetMemberBrandId } from '../../../../../../services/Member/MemberService';
import OldSystemNoteDetailSearch from './OldSystemNoteDetailSearch';
import OldSystemNoteTable from './OldSystemNoteTable'

const OldSystemNote = () => {

    const strings = useLocalization();
    const { memberId } = useParams() || false;
    const limit = 10;

    const [start, setStart] = useState(0);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecord, setTotalRecord] = useState(0);
    const [data, setData] = useState([]);
    const [brandId, setBrandId] = useState(0);

    const [detailSearch, setDetailSearch] = useState({
        beginDate: null,
        endDate: null,
    });

    //Arama verileri değiştiğinde değerleri sıfırlıyoruz
    const resetValue = () => {
        setStart(start => 0);
        setCurrentPage(currentPage => 1);
        setLoading(loading => true);
    }

    //select değişiklikleri bu alandan yönetiliyor
    const changeDetail = (values) => {
        setDetailSearch({ ...detailSearch, ...values });
        resetValue();
    };

    //Member a ait brandID yi alıyoruz.
    const getMemberBrandId = async (memberId) => {
        const result = await GetMemberBrandId(memberId);

        if (result.status === 200) {
            setBrandId(brandId => parseInt(result.content));
        }
    }

    const getMemberNotesFromClickivo = async () => {
        const model = {
            memberId: memberId,
            brandId: brandId,
            start: start,
            length: limit,
            beginDate: detailSearch.beginDate ? stringToDate(detailSearch.beginDate) : "",
            endDate: detailSearch.endDate ? stringToDate(detailSearch.endDate, 23, 59, 59) : ""
        }

        setLoading(loading => true);
        const tmpResult = await MemberNotesFromClickivo(model);
        const result = JSON.parse(tmpResult)

        if (result.status === 200) {
            let resultContent = result.content;
            setData(data => resultContent.data);
            setTotalRecord(totalRecord => resultContent.totalRecord);
        } else {
            showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
        }
        setLoading(loading => false);
    }

    useEffect(() => {
        if (totalRecord !== 0 && brandId) {
            getMemberNotesFromClickivo();
        }
    }, [start])

    useEffect(() => {
        let abortController = new AbortController();

        brandId && getMemberNotesFromClickivo();

        return () => {
            abortController.abort();
        }

    }, [detailSearch, brandId])

    useEffect(() => {
        memberId && getMemberBrandId(memberId);
    }, [])

    return (
        <>

            <OldSystemNoteDetailSearch changeDetail={changeDetail} />

            <div className="overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
                <div className="inline-block min-w-full rounded-lg overflow-hidden py-6">
                    {!loading && <SearchCount count={totalRecord} />}
                    <PageTitle title={strings.member.member_detail.header.content.old_system_notes} />
                    {loading ?
                        (
                            <Loading />
                        )
                        :
                        (
                            <>
                                <OldSystemNoteTable
                                    data={data}
                                />
                                <Pagination
                                    totalCount={totalRecord}
                                    limit={limit}
                                    start={start}
                                    setStart={setStart}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    setLoading={setLoading}
                                    loadScreen={true}
                                />
                            </>
                        )
                    }
                </div>
            </div>
        </>
    )
}

export default OldSystemNote