import {useLocalization} from "../../../../hooks/Utils/useLocalization";
import {CopyToClipboard, Loading, NoRecordsFound} from "../../../../components";
import React, {useEffect, useState} from "react";
import {
    GetCandidateMembers,
    GetDropPoolMembers,
    GetPoolMembers,
    GetReminderMembers
} from "../../../../services/Report/ReportService";
import {fullDateToShortDateWithTime, maskPhoneNumber} from "../../../../components/Utility";
import {Link, useNavigate} from "react-router-dom";
import {url} from "../../../../routes/utility";

const MemberListModal = ({ source, selectedDate, employeeId }) => {

    const strings = useLocalization();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [modalTitle, setModalTitle] = useState("");

    useEffect(() => {

        const commonModel = {
            employeeId: employeeId,
            startDate: fullDateToShortDateWithTime(selectedDate, 0, 0, 0),
            endDate: fullDateToShortDateWithTime(selectedDate, 23, 59, 59)
        }

        switch (source) {
            case "candidate-count":
                setModalTitle(strings.report.salesman_reports.all_candidates);
                GetCandidateMembers(commonModel)
                    .then(result => {
                        if(result.status === 200)
                            setData(data => JSON.parse(result.content));
                    })
                    .catch(e => {})
                break;
            case "pool-count":
                setModalTitle(strings.report.salesman_reports.all_get_candidates);
                GetPoolMembers(commonModel)
                    .then(result => {
                        if(result.status === 200)
                            setData(data => JSON.parse(result.content));
                    })
                    .catch(e => {})
                break;
            case "drop-pool-count":
                setModalTitle(strings.report.salesman_reports.dropped_pool_candidates);
                GetDropPoolMembers(commonModel)
                    .then(result => {
                        if(result.status === 200)
                            setData(data => JSON.parse(result.content));
                    })
                    .catch(e => {})
                break;
            case "reminder-count":
                setModalTitle(strings.report.salesman_reports.reminder_count);
                GetReminderMembers(commonModel)
                    .then(result => {
                        if(result.status === 200)
                            setData(data => JSON.parse(result.content));
                    })
                    .catch(e => {})
                break;
            default:
                setModalTitle('');
                setData([]);
        }

        setLoading(false);
    }, []);

    return (
        <>
            <div className="flex justify-center">
                <p className="font-bold text-[15px]">{modalTitle}</p>
            </div>

            {loading && <Loading />}

            {!loading &&
                <>
                    <div className="mx-auto px-4">
                        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
                            <div className="inline-block min-w-full rounded-lg overflow-hidden">
                                <p>
                                    <span className="font-bold">{strings.report.salesman_reports.total_data_count}:</span>{" "}
                                    {data?.memberCount}
                                </p>
                                <table className="min-w-full leading-normal text-header">
                                    <thead className="table table-fixed w-full">
                                        <tr>
                                            <th className="th">{strings.form_info.full_name}</th>
                                            <th className="th">{strings.form_info.phone_number}</th>
                                        </tr>
                                    </thead>
                                    <tbody className="block sm:flex sm:flex-col overflow-y-scroll h-[28vh] md:h-[30vh] lg:h-[32vh] w-full">
                                    {Object.keys(data).length > 0
                                        && data?.memberList.map((item, index) => (
                                            <tr key={index} className="hover:bg-[#f0f0f0] table table-fixed w-full">
                                                <td className="td align-middle py-4">
                                                    {item.memberInPool && item.memberName}
                                                    {!item.memberInPool &&
                                                        <Link
                                                            to={url("member.detail.profile", {memberId: item.memberId})}
                                                            className="font-bold text-blue hover:underline"
                                                            target="_blank"
                                                        >
                                                            {item.memberName}
                                                        </Link>
                                                    }
                                                </td>
                                                <td className="td align-middle py-4">
                                                    <div className="flex gap-0.5">
                                                        <p className="min-w-max">{maskPhoneNumber(item.memberPhoneNumber)}</p>
                                                        <CopyToClipboard
                                                            text={item.memberPhoneNumber}
                                                            className="-mb-1"
                                                            iconClassName="!text-[13px]"
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }

                                    {Object.keys(data).length > 0
                                        && data?.memberList.length === 0 && (
                                            <tr className="bg-white hover:bg-[#f0f0f0]">
                                                <td className="flex items-center" colSpan="2">
                                                    <NoRecordsFound/>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default MemberListModal;