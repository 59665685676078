import React, { useState } from 'react'
import { Modal } from '../../../../components';
import MemberTermDetailModal from './MemberTermDetailModal';
import { GetLessonCountDetails } from '../../../../services/Term/TermService';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { url } from '../../../../routes/utility';
import { useLocalization } from '../../../../hooks/Utils/useLocalization';

const ActiveLesson = (props) => {

  const { time, data } = props;
  const strings = useLocalization();

  const [modal, setModal] = useState(false);
  const [remainingLessonCount, setRemainingLessonCount] = useState([]);
  const [selectedData, setSelectedData] = useState({});

  const closeModal = () => {
    setModal(modal => false);
  }

  let firstActiveDay = -1;
  for (let i = 0; i < 7; i++) {
    if (document.getElementById(`${time}_${i}_active`)) {
      firstActiveDay = i;
      break;
    }
  }

  //Kalan ders bilgileri için servis
  // const getLessonCountDetails = async (termId) => {
  //   const result = await GetLessonCountDetails(termId);
  //
  //
  //   if (result.status === 200) {
  //     let resultContent = JSON.parse(result.content);
  //
  //     tmpLessonCountArr.push({
  //       termId: termId,
  //       lessonCount: resultContent.remainingLesson
  //     })
  //     console.log(tmpLessonCountArr)
  //     setRemainingLessonCount(remainingLessonCount => tmpLessonCountArr);
  //   }
  //
  // }

  useEffect(() => {
    if (data.length > 0) {
      const fetchData = async () => {
        let tmpLessoncountArr = [];

        for (const item of data) {
          try {
            const response = await GetLessonCountDetails(item.termId);
            if (response.status === 200) {
              const resultContent = JSON.parse(response.content);
              tmpLessoncountArr.push({
                termId: item.termId,
                lessonCount: resultContent.remainingLesson,
              });
            }
          } catch (error) {
            console.error(error);
          }
        }

        setRemainingLessonCount(tmpLessoncountArr);
      };

      fetchData();
    }
  }, [data]);


  return (
    <>
      <div className='active-lesson  min-h-[60px] -m-[1px] py-1 flex flex-col items-start justify-center gap-1.5 relative'>
        {(data.length > 0) && data.map((item, index) => (
            <div key={index} className="flex items-center justify-center gap-1.5">
              <button
                onClick={() => {
                    setSelectedData(selectedData => item)
                    setModal(true);
                }}
                className="flex-shrink-0 group inline-flex items-center justify-center bg-transparent border border-white bg-card text-base-text search-button w-5 h-5 rounded-[3px] relative"
              >
                <span className="material-symbols-outlined text-white text-[14px]">visibility</span>
              </button>
              <div className='flex gap-2 align-middle items-center'>
                <Link
                  className='min-w-max hover:underline'
                  to={url("member.detail.profile", { memberId: item.memberId })}
                  target='_blank'
                >
                  {item?.fullName}
                </Link>
                {/*{Math.min(...data.days) === day && (*/}
                  <p className='-ml-1'>({item.days.length})</p>
                {/*)}*/}
                {/*{Math.min(...data.days) === day && (*/}
                  <div className='absolute right-2'>
                    <div className="bg-red badge-color !min-w-0 !py-[2px] !px-3 relative group">
                      <p className='cursor-default'>{remainingLessonCount.find(remaining => remaining.termId === item.termId)?.lessonCount}</p>
                      <div className="tooltip-top">
                        <p className='normal-case'>{strings.teacher.teacher_program.remaining_lesson_count}</p>
                      </div>
                    </div>
                  </div>
                {/*)}*/}
              </div>
            </div>
        ))}
      </div>

      <Modal modal={modal} setModal={setModal} classes={{ modal: "h-[400px] max-w-[850px]" }} >
        <MemberTermDetailModal data={selectedData} closeModal={closeModal} />
      </Modal>
    </>
  )
};

export default ActiveLesson