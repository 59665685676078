import React, { useEffect, useRef, useState } from 'react'
import { Loading, showTopAlert } from '../../components'
import NotAuthorized from '../../components/NotAuthorized';
import { fullDateToShortDateWithTime, getddMMyyyyDate } from '../../components/Utility';
import { Brands } from '../../consts/Constants';
import useAuth from '../../hooks/Log/useAuth';
import { useLocalization } from '../../hooks/Utils/useLocalization';
import { GetDailyReport } from '../../services/Report/ReportService';
import DailyReportAccordion from './components/DailyReport/DailyReportAccordion';
import DailyReportDetailSearch from './components/DailyReport/DailyReportDetailSearch'

const DailyReport = () => {

  const strings = useLocalization();
  const firstVisit = useRef(true);

  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(null);
  const [data, setData] = useState([])

  //Sayfa yetkileri için bu bölüm kullanılıyor
  const { employeeTypeId, checkPermission, getPermission, employee } = useAuth();
  const roles = getPermission();
  const permission = checkPermission(roles, employeeTypeId);
  //Sayfa yetkileri için bu bölüm kullanılıyor

  const [detailSearch, setDetailSearch] = useState({
    brandId: -1,
    selectedDate: null,
  })

  const getDailyReport = async (selectedDate, brandId) => {
    setLoading(loading => true);
    let model = {
      brandId: brandId,
      startDate: fullDateToShortDateWithTime(selectedDate, 0, 0, 0),
      endDate: fullDateToShortDateWithTime(selectedDate, 23, 59, 59)
    }

    const result = await GetDailyReport(model);

    if (result.status === 200) {
      let resultContent = JSON.parse(result.content);
      setData(data => resultContent)
    } else {
      showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
    }
    setLoading(loading => false);
  }

  //select değişiklikleri bu alandan yönetiliyor
  const changeDetail = (values) => {
    setDetailSearch({ ...detailSearch, ...values });
  }

  useEffect(() => {
    firstVisit.current = false;
  }, [])

  useEffect(() => {
    if(selectedDate && detailSearch.brandId !== -1) {
      getDailyReport(selectedDate, detailSearch.brandId);
    }
  }, [detailSearch.brandId, selectedDate])

  return (
    <>
      <DailyReportDetailSearch
        changeDetail={changeDetail}
        loading={loading}
        employee={employee}
        employeeTypeId={employeeTypeId}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        permission={permission}
      />
      <div className="mt-5">
        <p className="my-2 text-[14px] h-[35px] leading-[35px] text-base-text bg-card px-5 rounded-md flex flex-row justify-between">
          <span className='font-bold'>{strings.sidebar.report.daily}</span>
          {selectedDate && permission && (
            <span><span className="font-bold">{strings.table.date}:</span> {getddMMyyyyDate(selectedDate)}</span>
          )}
        </p>
      </div>
      {permission ? (
        loading ?
          (
            <Loading />
          )
          :
          (
            <>
              <DailyReportAccordion
                data={data.newRegistration}
                brandId={detailSearch.brandId}
                title={strings.sidebar.report.new_registration}
                showAgentCol={true}
              />

              {detailSearch.brandId === Brands.KO && (
                <DailyReportAccordion
                  data={data.newRegistrationCorporate}
                  brandId={detailSearch.brandId}
                  title={strings.sidebar.report.new_registration_corporate}
                />
              )}

              <DailyReportAccordion
                data={data.newRegistrationOperation}
                brandId={detailSearch.brandId}
                title={strings.sidebar.report.new_registration_operation}
              />

              {detailSearch.brandId === Brands.KO && (
                <DailyReportAccordion
                  data={data.newRegistrationOperationCorporate}
                  brandId={detailSearch.brandId}
                  title={strings.sidebar.report.new_registration_operation_corporate}
                />
              )}

              <DailyReportAccordion
                data={data.dropPool}
                brandId={detailSearch.brandId}
                title={strings.sidebar.report.dropped_pool}
                isDropPool={true}
              />
            </>
          )
      ) : (<NotAuthorized />)}
    </>
  )
}

export default DailyReport