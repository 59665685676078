import React from "react";

const Input = React.forwardRef((props, ref) => {

    const classname = typeof props.classnames === "undefined" ? '' : props.classnames;

    return(
        <input 
            id={props.id}
            ref={ref}
            type={props.type}
            name={props.name}
            value={props.value}
            disabled={props.isDisabled}
            min={props.minValue}
            max={props.maxValue}
            minLength={props.minLength}
            maxLength={props.maxLength}
            defaultValue={props.defaultValue}
            placeholder={props.placeholder} 
            className={`form-input w-full placeholder:!text-[13px] ${props.isDisabled ? 'opacity-70 bg-[#f5f5f5] focus:border-[#ebedf2] cursor-not-allowed' : ''} ${classname}`}
            onChange={props.onChange}
            onKeyDown={props.onKeyDown}
            readOnly={props.isReadOnly}
            onClick={props.action}
            onFocus={props.onFocus}
            onPaste={props.onPaste}
        />
    );
})
        
export default Input