import {Loading, PageTitle} from "../../../../components";
import {AbsentList} from "../index";
import React, {useEffect, useState} from "react";
import {GetTeacherAbsentList} from "../../../../services/International/InternationalService";
import {useLocalization} from "../../../../hooks/Utils/useLocalization";

const Absent = (props) => {

    const strings = useLocalization();
    const { teacherId, sendToEndpoint } = props;

    const [absentLoading, setAbsentLoading] = useState(false);
    const [absentList, setAbsentList] = useState([]);
    const [isRequestSend, setIsRequestSend] = useState(false);
    const [isCrashed, setIsCrashed] = useState(false);

    const getTeacherAbsentList = async (teacherId) => {

        const result = await GetTeacherAbsentList(teacherId);

        if(result.status === 200) {
            let resultContent = JSON.parse(result.content).absentlist;
            resultContent.length !== 0
                ? setAbsentList(absentList => resultContent.split(",").reverse())
                : setAbsentList(absentList => []);

            setIsRequestSend(isRequestSend => true);
        } else {
            setIsCrashed(isCrashed => true);
        }

        setAbsentLoading(absentLoading => false);
    }

    useEffect(() => {
        if(sendToEndpoint) {
            setAbsentLoading(absentLoading => true);
            teacherId && getTeacherAbsentList(teacherId);
        }
        // Applications array'i ters çeviriliyor ve kullanıcının eklediği son uygulamanın en üste gelmesi sağlanıyor.
    }, [sendToEndpoint]);

    return(
        <>
            <PageTitle title={strings.teacher.teacher_program.teacher_absent_list} />
            {absentLoading && (
                <div className="mb-4">
                    <Loading />
                </div>
            )}
            {!absentLoading && (
                <>
                    {isCrashed && (
                        <div className="py-4 min-w-full text-center">{strings.errors.an_error_occured_refresh_page}</div>
                    )}

                    {!isCrashed && (
                        <>
                            {absentList.length > 0 && (
                                <AbsentList
                                    data={absentList}
                                />
                            )}
                            {(absentList.length === 0 && isRequestSend) && (
                                <div className="py-4 min-w-full text-center">{strings.teacher.teacher_program.teacher_absent_not_found}</div>
                            )}
                            {(absentList.length === 0 && !isRequestSend) && (
                                <div className="py-4 min-w-full text-center">{strings.teacher.teacher_program.select_teacher_to_see_absent}</div>
                            )}
                        </>
                    )}
                </>
            )}
        </>
    )
}

export default Absent;