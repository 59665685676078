import classNames from "classnames";
import React, { useState } from "react";
import { showCenteredAlertTwoButtonsWithParameters, showTopAlert } from "../../../../components";
import { addTimeZoneToFullDate, getBrandNameById, getLogoByBrandId, handleCheckbox } from "../../../../components/Utility";
import useAuth from "../../../../hooks/Log/useAuth";
import { useLocalization } from "../../../../hooks/Utils/useLocalization";
import { ChangeDiscountCodeStatus } from "../../../../services/Accounting/DiscountCodeService";
import { getButtonPermission } from "../../../../rights/Utility";
import { useNavigate } from "react-router-dom";
import { url } from "../../../../routes/utility";

const DiscountCodeTableRow = (props) => {

  const { discount } = props;
  const { employee, employeeTypeId, checkPermission } = useAuth();
  const navigate = useNavigate();

  const strings = useLocalization();

  const statusColumnRoles = getButtonPermission({ group: "discountStatusColumn", name: "statusColumn" });
  const permission = checkPermission(statusColumnRoles, employeeTypeId);

  const [isDiscountActive, setIsDiscountActive] = useState(discount.status);

  const changeDiscountCodeStatus = async (campaignId) => {

    const result = await ChangeDiscountCodeStatus(campaignId);

    if (result.status === 200) {
      setIsDiscountActive(isDiscountActive => !isDiscountActive);
      if (!isDiscountActive) {
        showTopAlert(strings.accounting.discount_code.discount_status_active, "success");
      } else {
        showTopAlert(strings.accounting.discount_code.discount_status_passive, "success");
      }
    } else {
      showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
    }

  }

  //Bu kontrol eklenerek 48 Saat gibi indirimlerin durumunun güncellenmesi engelleniyor.
  const changeStatusButtonAuthority = (campaignType, campaignUsageType) => {
    if (campaignType === 2 && campaignUsageType === 0) {
      return true;
    }
    return false;
  }

  return (
    <>
      <tr className="bg-white hover:bg-[#f0f0f0]">
        <td className="td align-top">
          <div className="relative group max-w-max ml-6">
            <img src={getLogoByBrandId(discount.brand.id)} width="43" height="48" />
            <div className="tooltip-top">
              <p>{getBrandNameById(discount.brand.id)}</p>
            </div>
          </div>
        </td>
        <td className="td text-center">
          <div className="flex flex-col items-center">
            <button
              onClick={() => navigate(url("accounting.discountdetail"), { state: { discountId: discount.id } })}
              className="font-bold group"
            >
              <h3 className="text-base font-bold mb-2 w-[200px] text-blue group-hover:underline group-focus:underline">{discount.discountCode.toUpperCase()}</h3>
            </button>
            <div className="badge my-1 w-[200px]">
              <p className="font-bold uppercase">%{discount.discountRate} {strings.accounting.campaign.discount_upper}</p>
            </div>
          </div>
        </td>
        <td className="td min-w-[300px]">
          <div className="flex items-center mt-[6px]">
            <span className="material-symbols-outlined mr-1 text-[19px]">
              event_note
            </span>
            <p className="font-bold">{discount.title}</p>
          </div>
          <p className="mt-[6px] max-w-[400px]">
            <span className="material-symbols-outlined text-[19px] align-top">
              info
            </span>
            {/* 29/10/2022 00:00 - 29/10/2022 23:59 tarihleri arasında geçerlidir. */}
            <span
              dangerouslySetInnerHTML={{
                __html: strings.accounting.campaign.valid_between
                  .replace(/#beginDate#/g, addTimeZoneToFullDate(discount.beginDate, employee.TimeZone))
                  .replace(/#endDate#/g, addTimeZoneToFullDate(discount.endDate, employee.TimeZone))
              }}
            />
          </p>
          <div className="flex items-center mt-[3px]">
            <span className="material-symbols-outlined mr-1 text-[19px]">
              person
            </span>
            <p>{discount.employeeName}</p>
          </div>
        </td>
        <td className="td align-middle">
          {changeStatusButtonAuthority(discount.campaignType, discount.campaignUsageType) && (
            <div className="flex justify-center w-[60px] relative group">
              <input
                id="isActive1"
                type="checkbox"
                className="hidden"
                disabled={!permission}
              />
              <label
                id="campaign_button1"
                htmlFor="isActive1"
                className={`${handleCheckbox(isDiscountActive)} ${!permission ? 'opacity-70 cursor-default' : 'cursor-pointer'} mr-2 w-7 h-7 flex items-center justify-center rounded-[3px] duration-500`}
                onClick={() => {
                  if (permission) {
                    showCenteredAlertTwoButtonsWithParameters(
                      strings.alert.centered_alert_two_buttons.title,
                      strings.accounting.discount_code.change_discount_status_question,
                      strings.button.confirm,
                      strings.button.cancel,
                      changeDiscountCodeStatus,
                      [discount.id]
                    );
                  }
                }}
              >
                <span
                  className={classNames('text-inherit text-[25px] material-symbols-outlined animate-fadeIn font-bold', {
                    'hidden': !isDiscountActive
                  })}
                >
                  done
                </span>
              </label>
              {!permission && <div className="tooltip-top">{strings.errors.not_authorized}</div>}
            </div>
          )}
        </td>
        <td className="td align-middle">
          <div className="flex gap-2">
            <div className="flex justify-center">
              <div className={`mt-[3px] w-5 h-5 rounded-[3px] ${discount.usingStatus === false ? 'bg-green' : 'bg-red'}`}></div>
            </div>
            <h5 className={`text-base font-bold mb-2 ${discount.usingStatus === false ? 'text-green' : 'text-red'}`}>
              {discount.usingStatus === false ? strings.accounting.discount_code.not_used : strings.accounting.discount_code.used}
            </h5>
          </div>
        </td>
      </tr>
    </>
  );
};

export default DiscountCodeTableRow;
