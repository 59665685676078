import React from "react";
import { useState, useEffect } from "react";
import { Loading, PageTitle, Pagination, showTopAlert, SearchCount } from "../../../../../../components";
import { stringToDate } from "../../../../../../components/Utility";
import { useLocalization } from "../../../../../../hooks/Utils/useLocalization";
import { useParams } from "react-router-dom";
import { MemberNotesFilter } from "../../../../../../services/Member/MemberNoteService";
import MemberNoteDetailSearch from "./MemberNoteDetailSearch";
import MemberNoteTable from "./MemberNoteTable";

const MemberNote = () => {

    const strings = useLocalization();
    const { memberId } = useParams() || false;
    const limit = 10;
    const [start, setStart] = useState(0);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecord, setTotalRecord] = useState(0);
    const [data, setData] = useState([]);

    const [detailSearch, setDetailSearch] = useState({
        beginDate: null,
        endDate: null,
        employee: null,
    });

    //Arama verileri değiştiğinde değerleri sıfırlıyoruz
    const resetValue = () => {
        setStart(start => 0);
        setCurrentPage(currentPage => 1);
        setLoading(loading => true);
    }

    //select değişiklikleri bu alandan yönetiliyor
    const changeDetail = (values) => {
        setDetailSearch({ ...detailSearch, ...values });
        resetValue();
    };

    useEffect(() => {
        if (totalRecord !== 0) {
            getMemberNotes();
        }
    }, [start])

    const getMemberNotes = async () => {
        const model = {
            memberId: memberId,
            start: start,
            length: limit,
            beginDate: detailSearch.beginDate ? stringToDate(detailSearch.beginDate) : null,
            endDate: detailSearch.endDate ? stringToDate(detailSearch.endDate, 23, 59, 59) : null,
            createUser: detailSearch.employee === -1 ? null : detailSearch.employee
        }

        const result = await MemberNotesFilter(model);

        if(result.status === 200){
            let resultContent = JSON.parse(result.content);
            setData(data => resultContent.data);
            setTotalRecord(totalRecord => resultContent.recordsTotal);
            setLoading(loading => false);
        } else {
            showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
        }
    }

    useEffect(() => {
        let abortController = new AbortController();

        getMemberNotes();

        return () => {
            abortController.abort();
        }

    }, [detailSearch])

    return (
        <>
            <MemberNoteDetailSearch changeDetail={changeDetail} />

            <div className="overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
                <div className="inline-block min-w-full rounded-lg overflow-hidden py-6">
                    {!loading && <SearchCount count={totalRecord} />}
                    <PageTitle title={strings.member.member_detail.header.content.notes} />
                    {loading ?
                        (
                            <Loading />
                        )
                        :
                        (
                            <>
                                <MemberNoteTable
                                    data={data}
                                />
                                <Pagination
                                    totalCount={totalRecord}
                                    limit={limit}
                                    start={start}
                                    setStart={setStart}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    setLoading={setLoading}
                                    loadScreen={true}
                                />
                            </>
                        )
                    }
                </div>
            </div>
        </>
    )
}

export default MemberNote;