const useDataLayer = () => {

    const useEmployeeDimension = (abc, value) => {
        window.dataLayer.push({employee_email: value});
    }

    const useCustomEvents = (event, action) => {
        window.dataLayer.push({
            "event": event,
            "action": action
        });
    }

    return { useCustomEvents, useEmployeeDimension };
}

export default useDataLayer;