import { fetchApi } from '../index';

export const GetStudentAvailability = async (studentNo) => {
    const url = '/international/get_student_availability/';

    const payload = {
        studentNo: studentNo
    };

    return await fetchApi(url, payload);
}

export const GetTeacherAbsentList = async (teacherId) => {

    const url = '/international/get_teacher_absent_list/';

    const payload = {
        teacherId: teacherId
    };

    return await fetchApi(url, payload);
}

export const GetTeacherCallInformationByDate = async (studentNo, lessonDate) => {
    const url = '/international/teacher_by_date/';

    const payload = {
        studentNo: studentNo,
        lessonDate: lessonDate
    };

    return await fetchApi(url, payload);
}

export const GetNetworkLogs = async (studentNo, lessonDate) => {

    const url = '/international/system_log/';

    const payload = {
        studentNo: studentNo,
        lessonDate: lessonDate
    };

    return await fetchApi(url, payload);
}