import {useLocalization} from "../../hooks/Utils/useLocalization";
import React, {useEffect, useState} from "react";
import {GetTeacherScheduleListInTimeInterval} from "../../services/Teacher/TeacherService";
import {Loading, PageTitle} from "../../components";
import {AllProgramTable, AllTeacherProgramDetailSearch, IconDescriptions} from "./components";

const AllTeacherProgram = () => {

    const strings = useLocalization();

    const [loading, setLoading] = useState(false);
    const [detailSearch, setDetailSearch] = useState({
        beginTime: "", endTime: "", gender: -1, listOption: -1
    });
    const [allProgramData, setAllProgramData] = useState([]);
    const [filteredAllProgramData, setFilteredAllProgramData] = useState([]);

    //select değişiklikleri bu alandan yönetiliyor
    const changeDetail = (values) => {
        setDetailSearch({...detailSearch, ...values});
    }

    const getTeacherScheduleListInTimeInterval = async (detailSearch) => {
        let model = {
            beginTime: `${detailSearch.beginTime}:00`, endTime: `${detailSearch.endTime}:00`,
        }

        const result = await GetTeacherScheduleListInTimeInterval(model);

        if (result.status === 200) {
            let resultContent = JSON.parse(result.content);
            setAllProgramData(allProgramData => resultContent);
            setFilteredAllProgramData(filteredAllProgramData => resultContent);
            setLoading(loading => false);
        }

    }

    const filterFreeLessonData = (allProgramData) => {
        let tmpTeacherIdArr = [];

        allProgramData.map(data => {
            data.scheduleInformations.map(schedule => {
                if (!schedule.hasLesson) {
                    tmpTeacherIdArr.push(data.teacherId);
                }
            });
        });

        let teacherIdArr = Array.from(new Set(tmpTeacherIdArr));
        let filteredAllProgramData = [];

        allProgramData.map(data => {
            teacherIdArr.map(teacherId => {
                if (data.teacherId === teacherId) {
                    filteredAllProgramData.push(data);
                }
            });
        })

        return filteredAllProgramData;
    }

    useEffect(() => {

        let abortController = new AbortController();

        if (detailSearch.beginTime !== "" && detailSearch.endTime !== "") {
            setLoading(loading => true);
            getTeacherScheduleListInTimeInterval(detailSearch);
        } else {
            setAllProgramData(allProgramData => [])
            setFilteredAllProgramData(filteredAllProgramData => []);
        }

        return () => {
            abortController.abort();
        }
    }, [detailSearch.beginTime, detailSearch.endTime])

    useEffect(() => {
        if (detailSearch.gender !== -1 && detailSearch.listOption !== -1) {

            let filteredAllProgramData = allProgramData.filter(data => data.gender === detailSearch.gender);
            filteredAllProgramData = filterFreeLessonData(filteredAllProgramData);

            setFilteredAllProgramData(allProgramData => filteredAllProgramData);

        } else if (detailSearch.gender !== -1) {

            let filteredAllProgramData = allProgramData.filter(data => data.gender === detailSearch.gender);
            setFilteredAllProgramData(allProgramData => filteredAllProgramData);

        } else if (detailSearch.listOption !== -1) {

            let filteredAllProgramData = filterFreeLessonData(allProgramData);
            setFilteredAllProgramData(allProgramData => filteredAllProgramData);

        } else {
            setFilteredAllProgramData(filteredAllProgramData => allProgramData);
        }

    }, [detailSearch, allProgramData]);

    return (<>
            <AllTeacherProgramDetailSearch loading={loading} changeDetail={changeDetail}
                                           allProgramDataLength={filteredAllProgramData.length}/>
            <div className="mb-6">
                <PageTitle title={strings.sidebar.teacher.all_teacher_program}/>
            </div>
            {loading && <Loading/>}

            {(filteredAllProgramData.length > 0 && !loading) && (
                <>
                    <IconDescriptions/>
                    <AllProgramTable allProgramData={filteredAllProgramData}/>
                </>
            )}

            {
                (filteredAllProgramData.length === 0 && !loading && detailSearch.beginTime === "" && detailSearch.endTime === "") && (
                    <div
                        className='py-4 min-w-full text-center'>{strings.teacher.all_teacher_program.select_time_range_to_see}</div>

                )
            }
        </>
    )
}

export default AllTeacherProgram;