import { useRoutes } from 'react-router-dom';
import routes from './routes/routes';
import useScreenViewLogs from './hooks/Log/useScreenViewLogs';

function App() {
  
  useScreenViewLogs();
  
  return useRoutes(routes);
}

export default App;
