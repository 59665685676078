import { useLocalization } from "../../hooks/Utils/useLocalization"
import { DefaultProfile } from "../../assets/img";
import { useParams} from "react-router-dom";
import { useEffect, useState } from "react";
import { EmployeeDetails } from "../../services/Employee/EmployeeService";
import {showTopAlert, Loading, PageTitle, CopyToClipboard} from "../../components";
import useAnalytics from "../../hooks/Log/useAnalytics";
import {GeneralStatusType} from "../../consts/Constants";
import classNames from "classnames";
import {getButtonPermission} from "../../rights/Utility";
import useAuth from "../../hooks/Log/useAuth";
import { ISOStringToDate2 } from "../../components/Utility";

const EmployeeDetail = () => {

  const strings = useLocalization();
  const analytics = useAnalytics();
  const { employeeId} = useParams();
  const { employeeTypeId, checkPermission } = useAuth();

  const employeeIdRoles = getButtonPermission({ group: "employee", name: "detailEmployeeId" });
  const employeeIdPermission = checkPermission(employeeIdRoles, employeeTypeId);

  const [loading, setLoading] = useState(true);
  const [detail, setDetail] = useState({});
  const [avatar, setAvatar] = useState("");

  const getDetails = async (employeeId) => {
    const result = await EmployeeDetails(employeeId);
    let resultContent = JSON.parse(result.content);

    if (result.status === 200) {
      setDetail(detail => resultContent);

      //LOGGED
      analytics.useAnalytics("Kullanici_Detay_Ekranina_Girdi", { employeeId: employeeId });

      setLoading(loading => false);
    } else {
      showTopAlert(strings.errors.an_error_occurred + " / " + resultContent, "error")
    }

  }

  useEffect(() => {
    if (employeeId) {
      getDetails(employeeId);
    } else {
      showTopAlert(strings.errors.an_error_occurred, "error");
    }
  }, [])

  useEffect(() => {
    if (Object.keys(detail).length > 0) {
      setAvatar(avatar => process.env.REACT_APP_PROFILE_URL + "/employee" + "/" + detail.profilePicture);
    }
  }, [detail])

  return (
    <>
      <PageTitle title={strings.employee.detail.employee_details} />
      {loading ?
        (
          <Loading />
        )
        :
        (
          <>
            <div className="h-[100px] rounded-md mb-8">
              <div className="flex justify-center">
                <div className="relative mt-8 flex flex-col items-center">
                  <img
                    className={classNames("w-[100px] h-[100px] shadow-profilePhoto rounded-full border-[2px]", {
                      "border-green" : detail.status === GeneralStatusType.Active,
                      "border-red" : detail.status === GeneralStatusType.Passive
                    })}
                    src={avatar !== "" ? avatar : DefaultProfile}
                    alt=""
                  />
                  <p className="text-lg font-bold text-center">{detail.name}</p>
                </div>
              </div>
            </div>
            <div className="flex flex-col md:flex-row mt-20">
              <div className="flex flex-col gap-3 mt-5 md:mx-3 w-full">
                {employeeIdPermission && (
                  <div className="h-[65px] px-[10px] py-[6px] align-middle text-[11px] border rounded-2xl outline-0 text-center flex flex-col gap-3">
                    <div className="flex justify-center items-center gap-0.5">
                      <p className="text-[14px] text-base-text font-bold">{strings.employee.detail.employee_id}</p>
                      <CopyToClipboard text={detail?.id} className="-mb-1" iconClassName="!text-[13px]"/>
                    </div>
                    <p className="text-[12px] text-[#2a2f5b]">{detail?.id}</p>
                  </div>
                )}
                <div className="h-[65px] px-[10px] py-[6px] align-middle text-[11px] border rounded-2xl outline-0 text-center flex flex-col gap-3">
                  <p className="text-[14px] text-base-text font-bold">{strings.employee.detail.brand_upper}</p>
                  <p className="text-[12px] text-[#2a2f5b]">{detail.brand.name ? detail.brand.name : " - "}</p>
                </div>
                <div
                    className="h-[65px] px-[10px] py-[6px] align-middle text-[11px] border rounded-2xl outline-0 text-center flex flex-col gap-3">
                  <p className="text-[14px] text-base-text font-bold">{strings.employee.detail.email}</p>
                  <p className="text-[12px] text-[#2a2f5b]">{detail.email ? detail.email : " - "}</p>
                </div>
                <div
                    className="h-[65px] px-[10px] py-[6px] align-middle text-[11px] border rounded-2xl outline-0 text-center flex flex-col gap-3">
                  <p className="text-[14px] text-base-text font-bold">{strings.employee.detail.timezone}</p>
                  <p className="text-[12px] text-[#2a2f5b]">{detail.timeZone ? detail.timeZone : " - "}</p>
                </div>
                <div
                    className="h-[65px] px-[10px] py-[6px] align-middle text-[11px] border rounded-2xl outline-0 text-center flex flex-col gap-3">
                  <p className="text-[14px] text-base-text font-bold">{strings.employee.detail.start_date_upper}</p>
                  <p className="text-[12px] text-[#2a2f5b]">{detail.beginDate ? detail.beginDate : " - "}</p>
                </div>
                <div
                    className="h-[65px] px-[10px] py-[6px] align-middle text-[11px] border rounded-2xl outline-0 text-center flex flex-col gap-3">
                  <p className="text-[14px] text-base-text font-bold">{strings.employee.detail.last_update_date}</p>
                  <p className="text-[12px] text-[#2a2f5b]">{detail.updateDate ? detail.updateDate : " - "}</p>
                </div>
              </div>
              <div className="flex flex-col gap-3 mt-5 md:mx-3 w-full">
                <div
                    className="h-[65px] px-[10px] py-[6px] align-middle text-[11px] border rounded-2xl outline-0 text-center flex flex-col gap-3">
                  <p className="text-[14px] text-base-text font-bold">{strings.employee.detail.position}</p>
                  <p className="text-[12px] text-[#2a2f5b]">{detail.employeeType.title ? detail.employeeType.title : " - "}</p>
                </div>
                <div className="h-[65px] px-[10px] py-[6px] align-middle text-[11px] border rounded-2xl outline-0 text-center flex flex-col gap-3">
                  <p className="text-[14px] text-base-text font-bold">{strings.employee.detail.corporate_phone}</p>
                  <p className="text-[12px] text-[#2a2f5b]">{detail.corporatePhone ? detail.corporatePhone : " - "}</p>
                </div>
                <div className="h-[65px] px-[10px] py-[6px] align-middle text-[11px] border rounded-2xl outline-0 text-center flex flex-col gap-3">
                  <p className="text-[14px] text-base-text font-bold">{strings.employee.detail.corporate_email}</p>
                  <p className="text-[12px] text-[#2a2f5b]">{detail.corporateEmail ? detail.corporateEmail : " - "}</p>
                </div>
                <div className="h-[65px] px-[10px] py-[6px] align-middle text-[11px] border rounded-2xl outline-0 text-center flex flex-col gap-3">
                  <p className="text-[14px] text-base-text font-bold">{strings.employee.detail.end_date}</p>
                  <p className="text-[12px] text-[#2a2f5b]">{detail.leaveDate !== "0001-01-01T00:00:00" ? ISOStringToDate2(detail.leaveDate) : "-"}</p>
                </div>
                <div className="h-[65px] px-[10px] py-[6px] align-middle text-[11px] border rounded-2xl outline-0 text-center flex flex-col gap-3">
                  <p className="text-[14px] text-base-text font-bold">{strings.employee.detail.manager}</p>
                  <p className="text-[12px] text-[#2a2f5b]">{detail.managerName ? detail.managerName : " - "}</p>
                </div>
              </div>
            </div>
          </>)}
    </>
  );
};

export default EmployeeDetail;
