import { fetchApi } from '../index';

export const GetMemberContactData = async (memberId) => {
    
    const url = '/member/v1/member_contact/get_member_contact_data/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const PostNewContact = async (model) => {
    
    const url = '/member/v1/member_contact/post_new_contact/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const UpdateContact = async (model) => {
    
    const url = '/member/v2/member_contact/update_contact/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const SetPrimaryPhoneNumberOrEmail = async (model) => {
    
    const url = '/member/v2/member_contact/set_primary_phone_number_or_email/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const ChangeMemberContactStatus = async (model) => {
    
    const url = '/member/v2/member_contact/change_status/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const GetMemberContactPermissions = async (memberId) => {

    const url = '/member/v1/member_contact/get_member_contact_permissions/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const AddMemberContactPermission = async (model) => {

    const url = '/member/v1/member_contact/add_member_contact_permission/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}