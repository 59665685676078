import { fetchApi } from '../index';

export const GetEmployeeSalesReport = async (model) => {

    const url = '/employee/v1/employee/get_employee_sales_report/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const GetDailyReport = async (model) => {

    const url = '/member/v1/report/get_daily_report/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const GetPoolMembers = async (model) => {

    const url = '/member/v1/report/get_pool_members/'

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const GetDropPoolMembers = async (model) => {

    const url = '/member/v1/report/get_drop_pool_members/'

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const GetReminderMembers = async (model) => {

    const url = '/member/v1/report/get_reminder_members/'

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const GetCandidateMembers = async (model) => {

    const url = '/member/v1/report/get_candidate_members/'

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}