import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DropdownSearch, PageTitle, showCenteredAlertTwoButtons, showTopAlert } from "../../../../../components";
import useAuth from "../../../../../hooks/Log/useAuth";
import { useLocalization } from "../../../../../hooks/Utils/useLocalization";
import usePrimeData from "../../../../../hooks/Utils/usePrimeData";
import { url } from "../../../../../routes/utility";
import { SetDropPool } from "../../../../../services/Member/MemberFormService";
import useDataLayer from "../../../../../hooks/Log/useDataLayer";
import {CustomEventDescriptions, CustomEvents} from "../../../../../consts/DataLayer";

const DropPool = () => {

  const strings = useLocalization();
  const dataLayer = useDataLayer();
  const primeData = usePrimeData();
  const { memberId } = useParams() || false;
  const { employee } = useAuth();
  const navigate = useNavigate();

  const errorRef = useRef();

  const firstDropPoolReason = { id: -1, name: strings.member.member_detail.candidate_operations.choose_reason };
  const reasonList = [firstDropPoolReason, ...primeData.dropPoolReasonList];

  const [selectedReason, setSelectedReason] = useState(firstDropPoolReason);
  const [note, setNote] = useState('');

  const submitHandle = async e => {
    e.preventDefault();

    let valid = true;

    //Hata mesajının içini boşaltıyoruz.
    errorRef.current.innerText = '';

    if (selectedReason.id === -1) {
      valid = false;
      errorRef.current.innerText = strings.member.member_detail.candidate_operations.drop_pool_pool_error;
    }

    if (valid && memberId) {
      showCenteredAlertTwoButtons(
        strings.alert.centered_alert_two_buttons.title,
        strings.member.member_detail.candidate_operations.drop_pool_question,
        strings.button.confirm,
        strings.button.cancel,
        sendToEndPoint
      );
    }
  };

  const sendToEndPoint = async () => {

    const model = {
      memberId: memberId,
      note: note,
      memberNoteTitleId: selectedReason.id,
      createUserId: employee.EmployeeId,
      callDate: new Date().toUTCString()
    };

    const result = await SetDropPool(model);

    //Eğer işlem başarılıysa aday listesine gidelim
    if (result.status === 200) {
      showTopAlert(strings.member.member_detail.candidate_operations.drop_pool_success, "success");

      //DATA LAYER LOGGED
      dataLayer.useCustomEvents(CustomEvents.EMPLOYEE_EVENTS, CustomEventDescriptions.DROP_STUDENT_TO_POOL);

      navigate(url("member.candidate"));
    } else {
      showTopAlert(strings.member.member_detail.candidate_operations.drop_pool_error, "error");
    }
    
    
  };

  useEffect(() => {
    //Hata mesajının içini boşaltıyoruz.
    if (selectedReason.id !== -1) {
      errorRef.current.innerText = '';
    }
  }, [selectedReason])

  return (
    <>
      <PageTitle title={strings.member.member_detail.header.content.drop_pool} />
      <form onSubmit={submitHandle}>
        <div className="mt-5 w-full">
          <div className="flex flex-col px-[10px] pb-0">
            <label htmlFor="member_note_title_id">
              {strings.member.member_detail.candidate_operations.choose_reason}
            </label>
            <DropdownSearch
              classnames="md:max-w-full"
              inputPlaceholder={strings.member.member_detail.candidate_operations.search_drop_pool_reason}
              data={reasonList}
              selectedData={selectedReason}
              setSelectedData={setSelectedReason}
            />
            <span
              ref={errorRef}
              className="text-danger field-validation-valid"
            >
            </span>
          </div>
        </div>
        <div className="flex flex-col px-[10px] pb-0 mt-5 w-full">
          <label className="font-bold mb-2" htmlFor="note">
            {strings.member.member_detail.candidate_operations.note}
          </label>
          <textarea
            id="note"
            className="border rounded-lg outline-0 focus:border-blue py-3 px-2 w-full"
            type="text"
            rows="2"
            onChange={(e) => setNote(e.target.value)}
          ></textarea>
        </div>
        <button
          type="submit"
          className="button search-button gap-3 text-white bg-blue border-blue mt-7 mx-auto px-10"
        >
          {strings.button.save}
        </button>
      </form>
    </>
  );
};

export default DropPool;
