import { fetchApi } from '../index';

export const InvoiceFilter = async (model) => {
    
    const url = '/member/v2/invoice/filter/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const ChangeInvoiceApprovalStatus = async (model) => {

    const url = '/member/v1/invoice/change_invoice_approval_status/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}