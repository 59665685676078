import React, { useRef, useState, useEffect } from 'react'
import { DetailSearchLayout, Dropdown, DropdownMultiSelect, DropdownSearch } from '../../../components';
import { DateRanges } from '../../../consts/Constants';
import { useLocalization } from '../../../hooks/Utils/useLocalization';
import { getddMMyyyyDate, getDateWithDayDifference } from '../../../components/Utility';
import DatePicker from 'react-datepicker';
import useAuth from '../../../hooks/Log/useAuth';
import { GetEmployeeListById } from '../../../services/Employee/EmployeeService';
import usePrimeData from '../../../hooks/Utils/usePrimeData';
import { GetTaskStatusType, GetTaskTitleTypes } from '../../../services/Task/TaskService';
import {getButtonPermission} from "../../../rights/Utility";

const TaskDetailSearch = ({ changeDetail, loading }) => {

  const strings = useLocalization();
  const { employee, employeeTypeId, checkPermission } = useAuth();
  const primeData = usePrimeData();

  const taskUserFilterAuthorityRoles = getButtonPermission({ group: "taskFilterAuthority", name: "taskUserFilterAuthority" });
  const taskUserFilterPermission = checkPermission(taskUserFilterAuthorityRoles, employeeTypeId);

  const brandFilterAuthorityRoles = getButtonPermission({ group: "taskFilterAuthority", name: "brandFilterAuthority" });
  const brandFilterPermission = checkPermission(brandFilterAuthorityRoles, employeeTypeId);

  //Bu bölüm belirli tarih aralığı seçmek için kullanılan değişkenlerin/fonksiyonların olduğu bölüm
  const datePickerRef = useRef();

  const [beginDate, setBeginDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleDateRange = (dates) => {
    const [start, end] = dates;
    setBeginDate(start);
    setEndDate(end);
    if (start !== null && end !== null) {
      let tmpDateRange = { id: 8, name: getddMMyyyyDate(start) + " - " + getddMMyyyyDate(end) };
      dateRangeList.push(tmpDateRange);
      changeDetail?.({ beginDate: getddMMyyyyDate(start), endDate: getddMMyyyyDate(end) });
      setBeginDate(null);
      setEndDate(null);
      setDateRange(dateRange => dateRangeList[8]);
      datePickerRef.current.classList.add("hidden");
    }
  };
  //Bu bölüm belirli tarih aralığı seçmek için kullanılan değişkenlerin/fonksiyonların olduğu bölüm

  const firstDateRange = { id: -1, name: strings.detail_search_titles.date_range };
  const dateRangeList = [firstDateRange, ...DateRanges];

  const firstTaskSubject = { id: -1, name: strings.task.task_subjects };
  const [taskSubjectList, setTaskSubjectList] = useState([firstTaskSubject]);

  const taskUserTitle = strings.detail_search_titles.employees;
  const firstTaskUser = { id: -1, name: taskUserTitle };
  const [taskUserList, setTaskUserList] = useState([firstTaskUser]);

  const firstBrand = { id: -1, name: strings.detail_search_titles.brands };
  const [brand, setBrand] = useState(firstBrand);
  const brandList = [firstBrand, ...primeData.brandList];

  const [taskUser, setTaskUser] = useState(firstTaskUser);
  const [dateRange, setDateRange] = useState(firstDateRange);
  const [taskSubject, setTaskSubject] = useState(firstTaskSubject);
  const [taskStatus, setTaskStatus] = useState([]);
  const [taskStatusList, setTaskStatusList] = useState([]);

  const firstVisit = useRef(true);

  //Kullanıcı tipine göre kimlerin görevlerini görebileceğini listeliyoruz
  const getEmployeeListById = async (employeeId) => {

    const result = await GetEmployeeListById(employeeId);
    
    if (result.status === 200) {
      let resultContent = JSON.parse(result.content);

      let arrayList = [];

      resultContent.map((item) => {
        let arrayItem = { id: item.value, name: item.text };
        arrayList.push(arrayItem);
      });

      if (arrayList.length > 0) {
        setTaskUserList(taskUserList => [firstTaskUser, ...arrayList]);
      }
    }
  }

  //Görev başlık listesini alıyoruz
  const getTaskTitleTypes = async () => {

    const result = await GetTaskTitleTypes();

    if (result.status === 200) {
      let resultContent = JSON.parse(result.content);

      let arrayList = [];

      resultContent.map((item) => {
        //Tanışma araması ve paket değişikliği araması listeden çıkarılıyor.
        if(+item.value !== 4 && +item.value !== 5){
          let arrayItem = { id: item.value, name: item.text };
          arrayList.push(arrayItem);
        }
      });

      if (arrayList.length > 0) {
        setTaskSubjectList(taskSubjectList => [firstTaskSubject, ...arrayList]);
      }
    }
  }

  //Görev durum listesini alıyoruz
  const getTaskStatusTypes = async () => {
    const result = await GetTaskStatusType();
    
    if (result.status === 200) {
      let resultContent = JSON.parse(result.content);

      let arrayList = [];

      resultContent.map((item) => {
        if(+item.value) {
          let arrayItem = { id: item.value, name: item.text };
          arrayList.push(arrayItem);
        }
      });

      if (arrayList.length > 0) {
        setTaskStatusList(taskStatusList => arrayList)
      }
    }
  }

  useEffect(() => {
    getEmployeeListById(employee.EmployeeId);
    getTaskTitleTypes();
    getTaskStatusTypes();
  }, [])

  useEffect(() => {
    //Sayfa ilk kez yüklenmiyorsa işlemi yap
    //Servise birden çok gez gitmesini engelliyoruz
    if (!firstVisit.current) {
      let todayDate = new Date();
      let tmpBeginDate = '';
      let tmpEndDate = '';

      datePickerRef.current.classList.add("hidden");

      switch (dateRange.id) {
        case 1:
          //Bugün seçildiyse 
          tmpBeginDate = getddMMyyyyDate(todayDate);
          tmpEndDate = tmpBeginDate;
          break;
        case 2:
          //Dün seçildiyse 
          tmpBeginDate = getddMMyyyyDate(getDateWithDayDifference(todayDate, 1));
          tmpEndDate = tmpBeginDate;
          break;
        case 3:
          //Son 7 gün seçildiyse 
          tmpEndDate = getddMMyyyyDate(todayDate);
          tmpBeginDate = getddMMyyyyDate(getDateWithDayDifference(todayDate, 6));
          break;
        case 4:
          //Son 30 gün seçildiyse 
          tmpEndDate = getddMMyyyyDate(todayDate);
          tmpBeginDate = getddMMyyyyDate(getDateWithDayDifference(todayDate, 30));
          break;
        case 5:
          //Bu ay seçildiyse 
          tmpBeginDate = getddMMyyyyDate(new Date(todayDate.getFullYear(), todayDate.getMonth(), 1));
          tmpEndDate = getddMMyyyyDate(new Date(todayDate.getFullYear(), todayDate.getMonth() + 1, 0));
          break;
        case 6:
          //Geçen ay seçildiyse 
          tmpBeginDate = getddMMyyyyDate(new Date(todayDate.getFullYear(), todayDate.getMonth() - 1, 1));
          tmpEndDate = getddMMyyyyDate(new Date(todayDate.getFullYear(), todayDate.getMonth(), 0));
          break;
        case 7:
          //Tarih aralığı seçildiyse 
          datePickerRef.current.classList.remove('hidden');
          break;
      }

      (dateRange.id < 7) && changeDetail?.({ beginDate: tmpBeginDate, endDate: tmpEndDate });

      firstVisit.current = false;
    }
  }, [dateRange])

  useEffect(() => {
    //Sayfa ilk kez yüklenmiyorsa işlemi yap
    //Servise birden çok gez gitmesini engelliyoruz
    if (!firstVisit.current) {
      changeDetail?.({ brandId: brand?.id });
      firstVisit.current = false;
    }
  }, [brand])

  useEffect(() => {
    //Sayfa ilk kez yüklenmiyorsa işlemi yap
    //Servise birden çok gez gitmesini engelliyoruz
    if (!firstVisit.current) {
      changeDetail?.({ taskUser: taskUser.id });
      firstVisit.current = false;
    }
  }, [taskUser])

  useEffect(() => {
    //Sayfa ilk kez yüklenmiyorsa işlemi yap
    //Servise birden çok gez gitmesini engelliyoruz
    if (!firstVisit.current) {
      changeDetail?.({ taskSubject: parseInt(taskSubject.id) });
      firstVisit.current = false;
    }
  }, [taskSubject])

  useEffect(() => {
    //Sayfa ilk kez yüklenmiyorsa işlemi yap
    //Servise birden çok gez gitmesini engelliyoruz
    if (!firstVisit.current) {
      setTimeout(() => {
        changeDetail?.({ status: taskStatus.map(item => parseInt(item)) });
        firstVisit.current = false;
      }, 500)
    }
  }, [taskStatus])

  useEffect(() => {
    if(brandList.length > 1) {
      const selectedBrand = brandList.find(brand => brand.id === parseInt(employee.BrandId))
      setBrand(brand => selectedBrand)
    }
}, [primeData.brandList])

  useEffect(() => {
    //Sayfa yüklendikten sonra false yaparak her değişiklikte
    //servisin ziyaret edilmesini sağlıyoruz
    firstVisit.current = false;
  }, [])

  return (
    <>
      <DetailSearchLayout
        isDisabled={loading}
        children={
          <div className="!flex gap-x-4 gap-y-5 max-md-lg:justify-center flex-wrap relative">
            <Dropdown
              data={dateRangeList}
              selectedData={dateRange}
              setSelectedData={setDateRange}
              isDisabled={loading}
            />
            <div ref={datePickerRef} className='absolute top-[75px] z-10 hidden'>
              <button className='absolute button search-button !h-[25px] !p-2 top-[-28px] bg-red text-white focus:!border-none right-0'
                onClick={() => datePickerRef.current.classList.add("hidden")}>X</button>
              <DatePicker
                selected={beginDate}
                onChange={handleDateRange}
                onClickOutside={() => datePickerRef.current.classList.add("hidden")}
                startDate={beginDate}
                endDate={endDate}
                selectsRange
                inline
              />
            </div>
            <DropdownSearch
              inputPlaceholder={strings.task.search_task_subject}
              data={taskSubjectList}
              selectedData={taskSubject}
              setSelectedData={setTaskSubject}
              isDisabled={loading}
            />
            <DropdownMultiSelect
                title={strings.task.task_status}
                data={taskStatusList}
                selectedData={taskStatus}
                setSelectedData={setTaskStatus}
                randomId="taskStatusListMS"
                inputPlaceholder={strings.detail_search_titles.search_task_status}
                isDisabled={loading}
            />
            {taskUserFilterPermission && (
              <DropdownSearch
                inputPlaceholder={strings.task.search_employee}
                data={taskUserList}
                selectedData={taskUser}
                setSelectedData={setTaskUser}
                isDisabled={loading}
              />
            )} 
            {brandFilterPermission && (
              <Dropdown
                data={brandList}
                selectedData={brand}
                setSelectedData={setBrand}
                isDisabled={loading}
              />
            )}
          </div>
        }
      />
    </>
  )
}

export default TaskDetailSearch