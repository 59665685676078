import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { toggleTicketAccordion } from '../../../../components';
import DailyReportDropPoolTable from './DailyReportDropPoolTable';
import DailyReportTable from './DailyReportTable';

const DailyReportAccordion = (props) => {

    const {
        data,
        brandId,
        title,
        isDropPool = false,
        showAgentCol = false
    } = props

    const accordionRef = useRef();
    const plusSignRef = useRef();
    const minusSignRef = useRef();

    const [isClick, setIsClick] = useState(false);

    return (
        <div className="relative">
            <div
                onClick={() => {
                    toggleTicketAccordion(accordionRef, plusSignRef, minusSignRef)
                    setIsClick(isClick => true);
                }}
                className={classNames(
                    "px-5 py-4 cursor-pointer border border-[#ebebeb] hover:bg-light-gray focus:bg-light-gray transition-colors text-dark-text bg-card rounded-t-md flex justify-between items-center mt-5", {
                    "rounded-b-md": accordionRef.current?.classList.contains("max-h-0")
                })}
            >
                <div className="relative flex flex-col gap-2">

                    <p className='font-bold'>{title}</p>
                </div>
                <span
                    ref={plusSignRef}
                    className="material-symbols-outlined font-bold text-base cursor-pointer"
                >
                    add
                </span>
                <span
                    ref={minusSignRef}
                    className="material-symbols-outlined font-bold text-base hidden cursor-pointer"
                >
                    remove
                </span>
            </div>
            <div
                ref={accordionRef}

                className="max-h-0 px-4 gap-x-4 gap-y-5 !py-0 flex-wrap max-md-lg:justify-center border-[#ebebeb] rounded-b-md animate-fadeOut transition-max-height duration-500 overflow-y-scroll">
                <div className={`${!isClick ? 'hidden' : ''}`}>

                    {
                        isDropPool && (
                            <DailyReportDropPoolTable
                                data={data}
                                brandId={brandId}
                            />
                        )
                    }
                    {
                        !isDropPool && (
                            <DailyReportTable
                                data={data}
                                brandId={brandId}
                                showAgentCol={showAgentCol}
                            />
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default DailyReportAccordion