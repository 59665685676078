import {useLocalization} from "../../../../hooks/Utils/useLocalization";
import CandidateTableRow from "./CandidateTableRow";
import {Modal, NoRecordsFound} from "../../../../components";
import React, {useState} from "react";
import {ReasonToLearnEnglishModal} from "../index";
import CandidateNotesModal from "./CandidateNotesModal";

const CandidateTable = ({ data, isFirstRequest }) => {

  const strings = useLocalization();
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState({});

  return (
    <>

      {isFirstRequest && (
        <div className="mx-auto px-4 my-4">
        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
          <div className="inline-block min-w-full rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal text-header">
              <thead >
                <tr className="bg-white hover:bg-[#f0f0f0]">
                  <th className="th text-center"><p>{strings.table.brand}</p></th>
                  <th className="th">{strings.table.student_informations}</th>
                  <th className="th">
                    {strings.table.application_informations}
                  </th>
                  <th className="th">
                    {strings.table.operation_informations}
                  </th>
                  <th className="th">{strings.table.users}</th>
                </tr>
              </thead>
              <tbody>
                  {data.length > 0 ? 
                    (
                      data.map((member, index) => (
                        <CandidateTableRow
                            key={index}
                            member={member}
                            setModal={setModal}
                            setModalContent={setModalContent}
                        />
                      ))
                    )
                    :
                    (
                      <tr className="bg-white hover:bg-[#f0f0f0]">
                        <td colSpan="5">
                            <NoRecordsFound />
                        </td>
                      </tr>
                    )
                  }
              </tbody>
            </table>
          </div>
        </div>
      </div>
      )}

      {!isFirstRequest && (
          <div className="py-4 min-w-full text-center">{strings.table.filter_for_result}</div>
      )}

      {(Object.keys(modalContent).length !== 0) && (
          <Modal modal={modal} setModal={setModal} classes={{
              modal: `${modalContent.element === "reasonToLearnEnglish" ? "h-[420px] max-w-[350px]" : "h-[90vh] max-w-[1140px]"}`,
            }}
          >
            {modalContent.element === "reasonToLearnEnglish" && <ReasonToLearnEnglishModal memberId={modalContent.memberId} />}
            {modalContent.element === "candidateNotes" && <CandidateNotesModal memberId={modalContent.memberId} />}
          </Modal>
      )}
    </>
  );
};

export default CandidateTable;
