import {useLocalization} from "../../hooks/Utils/useLocalization";
import {PageTitle} from "../../components";
import {Accordion} from "../Member/components";
import React from "react";

const TargetFollowAnalysis = () => {
    const strings = useLocalization();

    return (
        <>
            <PageTitle title={strings.sidebar.report.target_follow_analysis} />

            <Accordion
                title={strings.report.target_analysis.information_title}
                outSideClose={true}
                isOpen={true}
                content={<p dangerouslySetInnerHTML={{ __html: strings.report.target_analysis.information_one}}></p>}
            />
            <div className="flex justify-center mt-5">
                <iframe title="target" width="1725" height="1725" src="https://lookerstudio.google.com/embed/reporting/2d6f8d8f-cddf-4561-88b5-cf8ab64d261d/page/Z1jnD" frameBorder="0" allowFullScreen></iframe>
            </div>
        </>
    )
}

export default TargetFollowAnalysis;