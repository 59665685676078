import { fetchApi } from '../index';

export const GetBankPoses = async () => {

    const url = '/virtual_pos/v1/bank_pos/bank_poses/';

    const payload = {};

    return await fetchApi(url, payload);
}

export const GetBankInfos = async () => {

    const url = '/virtual_pos/v1/bank_info/bank_infos/';

    const payload = {};

    return await fetchApi(url, payload);
}

export const GetBankListByBrandId = async (brandId) => {

    const url = '/virtual_pos/v1/bank/get_bank_list_by_brand_id/';

    const payload = {
        brandId: brandId
    };

    return await fetchApi(url, payload);
}

export const GetBankInfoDetailsByBrandId = async (brandId) => {

    const url = '/virtual_pos/v1/bank_info/get_bank_info_details_by_id/';

    const payload = {
        brandId: brandId
    };

    return await fetchApi(url, payload);
}

export const GetBankPosInstallmentByBankPosId = async (bankPosId) => {

    const url = '/virtual_pos/v1/bank_pos_installment/get_bank_pos_installment_by_bank_pos_id/';

    const payload = {
        bankPosId: bankPosId
    };

    return await fetchApi(url, payload);
}