import { fetchApi } from '../index';

export const TaskFilter = async (model) => {

    const url = '/member/v4/task/filter/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const GetMemberDetailTaskList = async (memberId, employeeId, isActive) => {

    const url = '/member/v3/task/get_member_detail_task_list/';

    const payload = {
        memberId: memberId,
        employeeId: employeeId,
        isActive: isActive
    };

    return await fetchApi(url, payload);
}

export const GetMemberAiAnalysis = async (taskId) => {

    const url = '/member/v1/member_task_ai_analysis/send_captions_to_ai_by_task_type/';

    const payload = {
        taskId: taskId,
    };

    return await fetchApi(url, payload);
}

export const GetTaskNotes = async (taskId) => {

    const url = '/member/v1/task/notes/';

    const payload = {
        taskId: taskId
    };

    return await fetchApi(url, payload);
}

export const GetTaskCountByEmployeeId = async (model) => {

    const url = '/member/v2/task/get_task_count_by_employee_id/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const GetTaskPostponedDates = async (taskId) => {

    const url = '/member/v1/task/postponed_dates/';

    const payload = {
        taskId: taskId
    };

    return await fetchApi(url, payload);
}

export const EndTask = async (model) => {

    const url = '/member/v2/task/end/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const PostponeTask = async (model) => {

    const url = '/member/v2/task/postpone/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const GetTaskTitleTypes = async () => {

    const url = '/member/v2/task/get_task_title_types/';

    const payload = {
        
    };

    return await fetchApi(url, payload);
}

export const GetTaskStatusType = async () => {

    const url = '/member/v2/task/get_task_status_type/';

    const payload = {
        
    };

    return await fetchApi(url, payload);
}

export const GetActiveTasks = async (employeeId) => {
    
    const url = '/member/v2/task/get_active_tasks/';

    const payload = {
        employeeId: employeeId
    };

    return await fetchApi(url, payload);
}

export const GetActiveTaskCount = async (employeeId) => {

    const url = '/member/v2/task/get_active_task_count/';

    const payload = {
        employeeId: employeeId
    };

    return await fetchApi(url, payload);
}