import { fetchApi } from '../index';

//Fatura oluşturan servis.
export const CreateInvoice = async (model) => {
    const url = '/member/v1/invoice/create/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

//Kişiye özel faturaları listeleyen servis.
export const ListInvoice = async (memberId) => {

    const url = '/member/v1/invoice/list/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

//Faturanın durumunu pasife çeken servis.
export const DeleteInvoice = async (invoiceId) => {
    
    const url = '/member/v1/invoice/delete/';

    const payload = {
        invoiceId: invoiceId
    };

    return await fetchApi(url, payload);
}

//Faturanın durumunu tekrar aktif hale getiren servis.
export const UndoDeleteInvoice = async (invoiceId) => {
    
    const url = '/member/v1/invoice/undo_delete/';

    const payload = {
        invoiceId: invoiceId
    };

    return await fetchApi(url, payload);
}

//Faturanın görüntülenmesini sağlayan servis.
export const GetInvoicePreview = async (invoiceId) => {
    
    const url = '/member/v1/invoice/get_invoice_preview/';

    const payload = {
        invoiceId: invoiceId
    };

    return await fetchApi(url, payload);
}


export const UpdatePaymentDate = async (paymentId, paymentDate) => {
    const url = '/member/v1/payment/update_payment_date/';

    const payload = {
        id: paymentId,
        paymentDate: paymentDate
    };

    return await fetchApi(url, payload);
}