import { fetchApi } from "../FetchApi";

export const MemberTaskNotesFilter = async (model) => {

    const url = '/member/v2/task/task_notes_filter/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}