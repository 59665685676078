export const addToLocalStorage = (key, value) => {
    let data = localStorage.getItem(key);

    if (!data) {
        data = [];
    } else {
        data = JSON.parse(data);
    }

    data.push(value);
    localStorage.setItem(key, JSON.stringify(data));
}

export const removeFromLocalStorage = (key, id) => {
    let data = localStorage.getItem(key);

    if (data) {
        data = JSON.parse(data);

        data = data.filter(item => item.id !== id);

        if (data.length === 0) {
            localStorage.removeItem(key);
        } else {
            localStorage.setItem(key, JSON.stringify(data));
        }
    }
}

export const getFromLocalStorage = (key) => {
    let data = localStorage.getItem(key);

    if (data) {
        return JSON.parse(data);
    }

    return [];
}