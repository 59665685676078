import React, { useEffect, useState } from "react";
import {
  Loading,
  NoRecordsFound
} from "../../../../../../../../components";
import { useLocalization } from "../../../../../../../../hooks/Utils/useLocalization";
import { GetMemberLessonAppsWithTerm } from "../../../../../../../../services/Term/TermService";
import CallTypeModalTableRow from "./CallTypeModalTableRow";
import useAnalytics from "../../../../../../../../hooks/Log/useAnalytics";
import { ComponentViewLogs, ScreenViewLogs } from "../../../../../../../../consts/Logs";

const CallTypeModal = (props) => {
  const { termId, memberId, studentNo, sendRequestToCallTypeEndpoint } = props;
  const strings = useLocalization();
  const analytics = useAnalytics();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState([]);
  const [activeCallType, setActiveCallType] = useState([]);
  const [isRequestSend, setIsRequestSend] = useState(false);

  const getMemberLessonAppList = async (termId, memberId) => {
    const result = await GetMemberLessonAppsWithTerm(termId, memberId);

    if (result.status === 200) {
      let resultContent = JSON.parse(result.content); 
      
      setData((data) => resultContent);
      setLoading((loading) => false);
      setIsRequestSend((isRequestSend) => !isRequestSend);

      let arrayList = [];
      resultContent?.map((item) => {
        arrayList.push(item.termLessonAppStatus);
      });

      setActiveCallType((activeCallType) => arrayList);
    }
  };

  //Sadece 1 adet aktif  arama tipi varsa, o arama tipinin index'ini getirir.
  const activeCallTypeIndex = () => {
    let count = 0;

    for (let i = 0; i < activeCallType.length; i++) {
      if (activeCallType[i] === "1") {
        count++;
      }
    }

    if (count === 1) {
      let index = activeCallType.indexOf("1");

      return index;
    }

    return -1;
  };

  const sendRequestToEndpoint = () => {
    if (memberId && termId) {
      getMemberLessonAppList(termId, memberId);
    }
  };

  useEffect(() => {
    sendRequestToEndpoint();
    //LOGGED
    analytics.useAnalytics(ComponentViewLogs.CHOOSE_CALL_TYPE_COMPONENT, { termId: termId });
  }, []);

  return (
    <>
      <div className="flex justify-center">
        <p className="font-bold text-[15px] mb-2">
          {strings.member.member_detail.terms.modal.call_type_title}
        </p>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div className={`pb-5 text-[13px] text-center max-h-[520px] ${data.length > 7 ? "overflow-y-scroll" : ""}`} >
          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
            <div className="inline-block min-w-full rounded-lg overflow-hidden">
              <table>
                <thead className="bg-[#f5f5f5]">
                  <tr>
                    <th className="th">
                      <p className="min-w-max">
                        {strings.member.member_detail.terms.call_type}
                      </p>
                    </th>
                    <th className="th">
                      {strings.member.member_detail.others.substructure}
                    </th>
                    <th className="th">
                      {strings.member.member_detail.others.device}
                    </th>
                    <th className="th">
                      <p className="min-w-max">
                        {strings.member.member_detail.others.device_name}
                      </p>
                    </th>
                    <th className="th">
                      {strings.member.member_detail.header.operations}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 ? (
                    data.map((calltype, index) => (
                      <CallTypeModalTableRow
                        key={index}
                        callType={calltype}
                        termId={termId}
                        studentNo={studentNo}
                        sendRequestToEndpoint={sendRequestToEndpoint}
                        sendRequestToCallTypeEndpoint={sendRequestToCallTypeEndpoint}
                        isRequestSend={isRequestSend}
                        isDisabled={
                          activeCallTypeIndex() === index ? true : false
                        }
                      />
                    ))
                  ) : (
                    <tr className="bg-white hover:bg-[#f0f0f0]">
                      <td colSpan="5">
                        <NoRecordsFound />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CallTypeModal;
