import React, {useEffect, useState} from 'react'
import {Loading, NoRecordsFound, showTopAlert} from '../../../../components';
import Pagination from '../../../../components/Pagination';
import {fullDateToISOWithTimeZone, isItSpecified, stringToDate} from '../../../../components/Utility';
import useAuth from '../../../../hooks/Log/useAuth';
import {useLocalization} from '../../../../hooks/Utils/useLocalization';
import {MemberPoolScoreFilter} from '../../../../services/Member/MemberPoolService';
import CandidatePoolScoreDetailSearch from './CandidatePoolScoreDetailSearch';

const CandidatePoolScoreModal = (props) => {
    const strings = useLocalization();
    const notSpecified = strings.errors.it_is_not_specified;

    const { memberId, classes } = props;
    const { employee } = useAuth();

    const limit = 5;
    const [start, setStart] = useState(0);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);

    const [detailSearch, setDetailSearch] = useState({
        memberId: memberId,
        beginDate: null,
        endDate: null,
        userType: -1,
        logCategoryId: -1,
        createUser: null

    })

    const [totalRecord, setTotalRecord] = useState(0);
    const [data, setData] = useState([]);

    const getSourceTypeNameBySourceId = (sourceId) => {
        switch (sourceId) {
            case 1:
                return strings.member.member_detail.operations.source_type.web_application;
            case 2:
                return strings.member.member_detail.operations.source_type.panel;
            case 3:
                return strings.member.member_detail.operations.source_type.mobile_application;
            case 4:
                return strings.member.member_detail.operations.source_type.landing_page;
            default:
                return notSpecified;
        }
    }

    //Arama verileri değiştiğinde değerleri sıfırlıyoruz
    const resetValue = () => {
        setStart(start => 0);
        setCurrentPage(currentPage => 1);
        setLoading(loading => true);
    }

    //select değişiklikleri bu alandan yönetiliyor
    const changeDetail = (values) => {
        setDetailSearch({ ...detailSearch, ...values });
        resetValue();
    }

    //sayfa değiştikçe bilgileri yeniden çağırıyoruz
    useEffect(() => {
        if (totalRecord !== 0) {
            getMemberScores();
        }
    }, [start])

    const getMemberScores = async () => {
        const model = {
            memberId: memberId,
            start: start,
            length: limit,
            beginDate: detailSearch.beginDate ? stringToDate(detailSearch.beginDate) : null,
            endDate: detailSearch.endDate ? stringToDate(detailSearch.endDate, 23, 59, 59) : null,
            userType: detailSearch.userType,
            logCategoryId: detailSearch.logCategoryId,
            createUser: detailSearch.createUser
        }

        const result = await MemberPoolScoreFilter(model);
        if (result.status === 200) {
            let resultContent = JSON.parse(result.content);
            setData(data => resultContent.data);
            setTotalRecord(totalRecord => resultContent.recordsTotal);
            setLoading(loading => false);
        } else {
            showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
        }
    }

    useEffect(() => {

        let abortController = new AbortController();

        getMemberScores();

        return () => {
            abortController.abort();
        }
    }, [detailSearch])

    return (
        <div className={`animate-fadeIn w-full ${classes}`}>
            <div className="text-[13px] text-start">
                <div className="flex justify-center mb-1">
                    <p className="font-bold text-[15px]">{strings.member.candidate_pool.score.page_title}</p>
                </div>



                <CandidatePoolScoreDetailSearch detailSearch={detailSearch} changeDetail={changeDetail} />
                {loading ?
                    (
                        <Loading />
                    )
                    :
                    (
                        <>
                            <div className="overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
                                <div className="inline-block min-w-full rounded-lg pt-8 pb-2">
                                    <table className="min-w-full leading-normal text-header">
                                        <thead>
                                        <tr>
                                            <th className="th">
                                                <p className='min-w-max'>{strings.member.candidate_pool.score.td_title1}</p>
                                            </th>
                                            <th className="th">
                                                <p className='min-w-max'>{strings.member.candidate_pool.score.td_title2}</p>
                                            </th>
                                            <th className="th">
                                                <p className='min-w-max'>{strings.member.candidate_pool.score.td_title3}</p>
                                            </th>
                                            <th className="th">
                                                <p className="min-w-max">{strings.member.member_detail.accounting.source}</p>
                                            </th>
                                            <th className="th">
                                                <p className="min-w-max">{strings.table.device_name}</p>
                                            </th>
                                            <th className="th">
                                                <p className='min-w-max'>{strings.member.candidate_pool.score.td_title4}</p>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {data.length > 0 ?
                                            (
                                                    data.map((score, index) => (
                                                            <tr key={index}>
                                                                <td className="td h-[60px] align-middle">
                                                                    <p className='min-w-[200px]'>{score.description}</p>
                                                                </td>
                                                                <td className="td h-[60px] align-middle">
                                                                    <p>{score.createUser}</p>
                                                                </td>
                                                                <td className="td h-[60px] align-middle">
                                                                    <p>{score.score}</p>
                                                                </td>
                                                                <td className="td h-[60px] align-middle">
                                                                    <p>{getSourceTypeNameBySourceId(score.sourceType)}</p>
                                                                </td>
                                                                <td className="td h-[60px] align-middle">
                                                                    <p>{isItSpecified(score.deviceName, notSpecified)}</p>
                                                                </td>
                                                                <td className="td h-[60px] align-middle">
                                                                    <p className="min-w-max">{fullDateToISOWithTimeZone(score.logDate, employee.TimeZone)}</p>
                                                                </td>
                                                            </tr>
                                                        )
                                                    )
                                            )
                                            :
                                                (
                                                    <tr className='bg-white hover:bg-[#f0f0f0]'>
                                                        <td colSpan={6}>
                                                            <NoRecordsFound />
                                                        </td>
                                                    </tr>
                                                )
                                            }


                                        </tbody>
                                    </table>
                                    <Pagination
                                        totalCount={totalRecord}
                                        limit={limit}
                                        start={start}
                                        setStart={setStart}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        setLoading={setLoading}
                                        loadScreen={true}
                                    />
                                </div>

                            </div>
                        </>
                    )}
            </div>
        </div>
    )
}

export default CandidatePoolScoreModal;