import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CreateCurrentCardModal, EditCurrentCardModal } from "../../..";
import { Loading, Modal, NoRecordsFound, PageTitle, SquareButton, showCenteredAlertTwoButtonsWithParameters, showTopAlert } from "../../../../../../components";
import { SquareButtonWithoutLink } from "../../../../../../components/Buttons";
import { CurrentCardType } from "../../../../../../consts/Constants";
import { addTimeZoneToFullDate2 } from "../../../../../../components/Utility";
import useAnalytics from "../../../../../../hooks/Log/useAnalytics";
import useAuth from "../../../../../../hooks/Log/useAuth";
import { useLocalization } from "../../../../../../hooks/Utils/useLocalization";
import { DeleteCurrentCard, GetCurrentCardList, UndoDeleteCurrentCard } from "../../../../../../services/Accounting/CurrentCardService";

const CurrentCards = () => {

  const strings = useLocalization();
  const language = useSelector(state => state.localizationStore.language);
  const { memberId } = useParams() || false;
  const { employee } = useAuth();
  const analytics = useAnalytics();

  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [currentCards, setCurrentCards] = useState([]);
  const [loading, setLoading] = useState(true);

  const closeButton = () => {
    setModal(false);
    getCurrentCardList(memberId);
  };

  const deleteRequest = async (currentCardId) => {
    if (currentCardId) {

      const model = {
        id: currentCardId,
        memberId: memberId,
        employeeId: employee.EmployeeId
      }

      const result = await DeleteCurrentCard(model);
      if (result.status === 200) {
        showTopAlert(strings.alert.top_alert.success, "success");
        getCurrentCardList(memberId);
      }
    }
  };

  const undoDeleteRequest = async (currentCardId) => {
    if (currentCardId) {

      const model = {
        id: currentCardId,
        memberId: memberId,
        employeeId: employee.EmployeeId
      }

      const result = await UndoDeleteCurrentCard(model);
      if (result.status === 200) {
        showTopAlert(strings.alert.top_alert.success, "success");
        getCurrentCardList(memberId);
      }
    }
  };

  const getCurrentCardList = async (memberId) => {
    const result = await GetCurrentCardList(memberId);
    if (result.status === 200) {
      setCurrentCards(JSON.parse(result.content));
      setLoading(loading => false);
    }
  }

  useEffect(() => {
    if (memberId) {
      getCurrentCardList(memberId);
    }
  }, [])

  return (
    <>
      <PageTitle title={strings.member.member_detail.header.content.current_card} />
      <div className="mt-5 flex justify-end px-5">
        <button
          className="button search-button gap-3 text-white bg-green border-green focus:border-green"
          onClick={() => { setModal(true); setModalContent({ element: "create" }) }}
        >
          <span className="material-symbols-outlined">add</span>
          <p>{strings.member.member_detail.accounting.create_current_card}</p>
        </button>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div className="overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
          <div className="inline-block min-w-full rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal text-header">
              <thead>
                <tr>
                  <th className="th">{strings.member.member_detail.header.content.current_card}</th>
                  <th className="th">{strings.table.address}</th>
                  <th className="th"><p className="min-w-max">{strings.table.created_date}</p></th>
                  <th className="th">{strings.table.operation}</th>
                </tr>
              </thead>
              <tbody>
                {currentCards.length > 0 ? currentCards.map((currentCard, index) => {
                  var currentCardType = CurrentCardType.find(cct => cct.id === currentCard.cardType);
                  return (
                    <tr key={index} className="bg-white hover:bg-[#f0f0f0]">
                      <td className="td">
                        <div className="ml-3 min-w-max">
                          <p>
                            <strong>{strings.form_info.invoice_type}: </strong>{currentCardType[language]}
                          </p>
                          <p>
                            <strong>{currentCard.cardType === 0 ? strings.form_info.full_name : strings.form_info.company_name}: </strong>{currentCard.name}
                          </p>
                          <p>
                            <strong>{strings.form_info.email}: </strong>{currentCard.email}
                          </p>
                          {currentCard.cardType === 1 ? (
                            <>
                              <p>
                                <strong>{strings.form_info.tax_office}: </strong>{currentCard.taxOffice}
                              </p>
                              <p>
                                <strong>{strings.form_info.tax_number}: </strong>{currentCard.taxNumber}
                              </p>
                            </>
                          ) : (
                            <p>
                              <strong>{strings.form_info.identity_number}: </strong>{currentCard.taxNumber}
                            </p>
                          )}

                        </div>
                      </td>
                      <td className="td align-middle">
                        <p className="min-w-max">{currentCard.address}</p>
                      </td>
                      <td className="td align-middle">
                        <p className="min-w-max">{addTimeZoneToFullDate2(currentCard.createDate, employee.TimeZone)}</p>
                      </td>
                      <td className="td align-middle">
                        <div className="flex gap-1">
                          <SquareButton
                            classnames="bg-blue"
                            iconName="edit"
                            tooltip={strings.button.edit}
                            action={() => { setModal(true); setModalContent({ element: "edit", currentCardId: currentCard.id }) }}
                          />
                          {currentCard.status === 1 ? <SquareButtonWithoutLink
                            classnames="bg-red"
                            iconName="delete"
                            tooltip={strings.button.delete}
                            tooltipClassnames="tooltip-top"
                            action={() => {
                              showCenteredAlertTwoButtonsWithParameters(
                                strings.alert.centered_alert_two_buttons.title,
                                strings.alert.centered_alert_two_buttons.save_question,
                                strings.button.confirm,
                                strings.alert.centered_alert_two_buttons.cancel_text,
                                deleteRequest,
                                [currentCard.id]
                              );
                              //LOGGED
                              analytics.useAnalytics('Cari_Kart_Silme_Ekrani_Goruntulendi', {memberId: memberId});
                            }}
                          /> : <SquareButtonWithoutLink
                            classnames="bg-red"
                            iconName="undo"
                            tooltip={strings.button.undo}
                            tooltipClassnames="tooltip-top"
                            action={() => {
                              showCenteredAlertTwoButtonsWithParameters(
                                strings.alert.centered_alert_two_buttons.title,
                                strings.alert.centered_alert_two_buttons.save_question,
                                strings.button.confirm,
                                strings.alert.centered_alert_two_buttons.cancel_text,
                                undoDeleteRequest,
                                [currentCard.id]
                              );
                              //LOGGED
                              analytics.useAnalytics('Cari_Kart_Geri_Al_Ekrani_Goruntulendi', {memberId: memberId});
                            }}
                          />}
                        </div>
                      </td>
                    </tr>)
                }) : (
                  <tr className="bg-white hover:bg-[#f0f0f0]">
                    <td colSpan={4}>
                      <NoRecordsFound />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {(Object.keys(modalContent).length !== 0) && (
        <Modal modal={modal} setModal={setModal} classes={{ modal: 'h-[90vh] max-w-[800px]' }}>
          {modalContent.element === "create" && <CreateCurrentCardModal memberId={memberId} closeButton={closeButton} classes="!h-[65vh]" />}
          {modalContent.element === "edit" && <EditCurrentCardModal memberId={memberId} currentCardId={modalContent.currentCardId} closeButton={closeButton} classes="!h-[65vh]" />}
        </Modal>
      )}


    </>
  );
};

export default CurrentCards;
