import classNames from "classnames";
import React from "react";
import { useLocalization } from "../hooks/Utils/useLocalization";

const AlphabetBar = ({ changeLetter, detailSearch, isDisabled = false }) => {
  
  const strings = useLocalization();
  const alphabet = ['A', 'B', 'C', 'Ç', 'D', 'E', 'F', 'G', 'H', 'I', 'İ', 'J', 'K', 'L', 'M', 'N', 'O', 'Ö', 'P', 'Q', 'R', 'S', 'Ş', 'T', 'U', 'Ü', 'V', 'W', 'X', 'Y', 'Z'];

  const handleClick = (letter) => {
    changeLetter?.(letter)
  }

  return (
    <>
      <hr className="mb-4" />
      <div 
        className={classNames("selectgroup mb-4", {
          "pointer-events-none opacity-70": isDisabled
        })}
      >
        <button 
          className={classNames('selectgroup-button relative group', {'border-blue bg-[#1572e826] text-blue' : detailSearch.letter === ''})} 
          onClick={() => handleClick('')}
        >
          <span className="material-symbols-outlined text-[17px] translate-y-1">
            select_all
          </span>
          <div className="tooltip">
            <p>{strings.detail_search_titles.cards}</p>
          </div>
        </button>
        { alphabet && 
            alphabet.map((letter, index) => (
                <button 
                  key={index} 
                  className={classNames('selectgroup-button', {'border-blue bg-[#1572e826] text-blue' : detailSearch.letter === letter })} 
                  onClick={() => handleClick(letter)}
                >
                  {letter}
                </button>
            ))}
      </div>
    </>
  );
};

export default AlphabetBar;
