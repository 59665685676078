import classNames from "classnames";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { showCenteredAlertTwoButtons, showTopAlert } from "../../../../components";
import { addTimeZoneToFullDate, dateStringToReadableDate, getBrandNameById, getLogoByBrandId, handleCheckbox } from "../../../../components/Utility";
import useAuth from "../../../../hooks/Log/useAuth";
import { useLocalization } from "../../../../hooks/Utils/useLocalization";
import { url } from "../../../../routes/utility";
import { CampaignChangeStatus } from "../../../../services/Accounting/CampaignService";

const CampaignTableRow = (props) => {

  const strings = useLocalization();
  const { campaign } = props;
  const { employee } = useAuth();
  const navigate = useNavigate();

  //Başlangıç değeri servisten gelen veriye göre güncellenecek.
  const [isCampaignActive, setIsCampaignActive] = useState(campaign.status === 1 ? true : false);

  const changeCampaignStatus = async () => {
    const model = {
      employeeId: employee.EmployeeId,
      status: isCampaignActive === false ? 1 : 0,
      campaignId: campaign.id
    }

    const result = await CampaignChangeStatus(model);

    if (result.status === 200) {
      setIsCampaignActive(isCampaignActive => !isCampaignActive);
      if (!isCampaignActive) {
        showTopAlert(strings.accounting.campaign.campaign_status_active, "success");
      } else {
        showTopAlert(strings.accounting.campaign.campaign_status_passive, "success");
      }
    } else {
      showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
    }
  }

  return (
    <tr className="bg-white hover:bg-[#f0f0f0]">
      <td className="td align-top">
        <div className="relative group max-w-max ml-6">
          <img src={getLogoByBrandId(campaign.brand.id)} width="43" height="48" alt="" />
          <div className="tooltip-top">
            <p>{getBrandNameById(campaign.brand.id)}</p>
          </div>
        </div>
      </td>
      <td className="td text-center">
        <div className="flex flex-col items-center">
          <button
            onClick={() => navigate(url("accounting.campaigndetail"), { state: { campaignId: campaign.id } })}
            className="font-bold group"
          >
            <h3 className="text-base font-bold mb-2 w-[200px] text-blue group-hover:underline group-focus:underline">{campaign.code.toUpperCase()}</h3>
          </button>
          <div className="badge w-[200px] my-1">
            <p className="font-bold uppercase">%{campaign.discountRate} {strings.accounting.campaign.discount_upper}</p>
          </div>
        </div>
      </td>
      <td className="td">

        <p className="mt-[6px] max-w-[400px] min-w-[250px]">
          <span className="material-symbols-outlined mr-1 text-[19px] align-top">
            event_note
          </span>
          <span className="font-bold">{campaign.title}</span>
        </p>
        <p className="mt-[6px] max-w-[400px] min-w-[250px]">
          <span className="material-symbols-outlined mr-1 text-[19px] align-top">
            info
          </span>
          {/* 29/10/2022 00:00 - 29/10/2022 23:59 tarihleri arasında geçerlidir. */}
          <span
            dangerouslySetInnerHTML={{
              __html: strings.accounting.campaign.valid_between
                .replace(/#beginDate#/g, dateStringToReadableDate(campaign.beginDate))
                .replace(/#endDate#/g, dateStringToReadableDate(campaign.endDate))
            }}
          />
        </p>
      </td>
      <td className="td align-middle">
        <div className="badge w-[200px] my-1 flex items-center justify-center">
          <span className="material-symbols-outlined mr-1 text-[19px]">
            calendar_month
          </span>
          {addTimeZoneToFullDate(campaign.createDate, employee.TimeZone)}
        </div>
      </td>
      <td className="td align-middle">
        <div className="flex justify-center w-[60px]">
          <input
            id="isActive1"
            type="checkbox"
            className="hidden"
          />
          <label
            id="campaign_button1"
            htmlFor="isActive1"
            className={`${handleCheckbox(isCampaignActive)} mr-2 w-7 h-7 rounded-[3px] cursor-pointer duration-500 flex items-center justify-center`}
            onClick={() => {
              showCenteredAlertTwoButtons(
                strings.alert.centered_alert_two_buttons.title,
                strings.accounting.campaign.change_campaing_status_question,
                strings.button.confirm,
                strings.button.cancel,
                changeCampaignStatus
              );
            }}
          >
            <span className={classNames('text-inherit text-[25px] material-symbols-outlined animate-fadeIn font-bold', { 'hidden': !isCampaignActive })}>
              done
            </span>
          </label>
        </div>
      </td>
    </tr>
  );
};

export default CampaignTableRow;
