import {useSelector} from "react-redux";
import {getBrandNameById, getLogoByBrandId, ISOStringToDate} from "../../../../components/Utility";
import {SalesType} from "../../../../consts/Constants";
import {url} from "../../../../routes/utility";
import {Link} from "react-router-dom";
import useAuth from "../../../../hooks/Log/useAuth";

const DailyReportTableRow = (props) => {

    const { item, brandId } = props;
    const { employee } = useAuth();
    const language = useSelector(state => state.localizationStore.language);

    return (
        <>
            <tr className="bg-white hover:bg-[#f0f0f0]">
                <td className="td align-middle">
                    <div className="relative group max-w-max ml-6">
                        <img src={getLogoByBrandId(brandId)} width="43" height="48" alt="logo"/>
                        <div className="tooltip-top">
                            <p>{getBrandNameById(brandId)}</p>
                        </div>
                    </div>
                </td>
                <td className="td align-middle">
                    <div className="max-w-[320px] flex items-center gap-1">
                        <span className="material-symbols-outlined flex-shrink-0 text-[19px]">
                            person
                        </span>
                        <Link
                            to={url("member.detail.profile", {memberId: item.memberId})}
                            target="_blank"
                            className="font-bold text-blue hover:underline focus:underline"
                        >
                            <span>{item.memberName}</span>
                        </Link>
                    </div>
                </td>
                <td className="td align-middle">
                    <p
                        // to={url("employee.detail", { employeeId: employee.employeeId })}
                        // className="font-bold text-blue hover:underline focus:underline min-w-max"
                        className="min-w-max"
                    >
                        {item.createUserName}
                    </p>
                </td>
                {item?.salesName &&
                    <td className="td align-middle">
                        {item?.salesName}
                    </td>
                }
                <td className="td align-middle">
                    {SalesType.find(salesType => salesType.id === item.salesType)[language]}
                </td>
                <td className="td align-middle">
                    {ISOStringToDate(item.createDate, employee?.TimeZone)}
                </td>
            </tr>
        </>
    );
}

export default DailyReportTableRow