import React, {useEffect, useState} from "react";
import {Dropdown} from "./Dropdown";
import {useLocalization} from "../hooks/Utils/useLocalization";

const TimePicker = (props) => {

    const { defaultTime = "", setSelectedTime, listAllHours = true } = props;

    const [hour, setHour] = useState({ id: 0, name: "00"});
    const [minute, setMinute] = useState({ id: 0, name: "00"});

    const [hourList, setHourList] = useState([]);
    const [minuteList, setMinuteList] = useState([]);

    const generateTimeOptions = () => {
        const timeOptions = [];
        let tmpHour = listAllHours ? 0 : new Date().getHours();

        for (let hour = tmpHour; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 60) {
                const formattedHour = hour.toString().padStart(2, '0');
                const time = `${formattedHour}`;
                timeOptions.push({ id: +time, name: time });
            }
        }
        return timeOptions;
    };

    const generateMinuteOptions = () => {
        const minuteOptions = [];

        for (let minute = 0; minute < 60; minute++) {
            const formattedMinute = minute.toString().padStart(2, '0');
            minuteOptions.push({ id: minute, name: formattedMinute });
        }

        return minuteOptions;
    };


    useEffect(() => {
        setHourList(hourList => generateTimeOptions());
        setMinuteList(minuteList => generateMinuteOptions());
    }, [listAllHours]);

    useEffect(() => {
        let currentHour = new Date().getHours();
        let currentMinute = new Date().getMinutes() + 1;

        if(!listAllHours) {
            if(currentHour === hour.id){
                let tmpMinuteList = generateMinuteOptions().filter(minute => minute.id >= currentMinute);
                setMinuteList(minuteList => tmpMinuteList);
                tmpMinuteList.map((minute) => {
                    if(minute.id === currentMinute){
                        setMinute(minute);
                    } else {
                        setMinute(tmpMinuteList[0]);
                    }
                })

            } else {
                setMinuteList(minuteList => generateMinuteOptions());
            }
        }
    }, [hour.id, listAllHours]);

    useEffect(() => {
        if(defaultTime.length > 0) {
            let tmpDefaultHour = defaultTime.split(":")[0];
            let tmpDefaultMinute = defaultTime.split(":")[1];

            const selectedHour = { id: +tmpDefaultHour, name: tmpDefaultHour };
            const selectedMinute = { id: +tmpDefaultMinute, name: tmpDefaultMinute };

            setHour(hour => selectedHour);
            setMinute(minute => selectedMinute);
        } else {
            let currentHour = new Date().getHours().toString().padStart(2, '0');
            let currentMinute = new Date().getMinutes().toString().padStart(2, '0');

            const selectedHour = { id: +currentHour, name: currentHour };
            const selectedMinute = { id: +currentMinute, name: currentMinute };

            setHour(hour => selectedHour);
            setMinute(minute => selectedMinute);
        }
    }, [defaultTime]);

    useEffect(() => {
        const currentTime = `${hour.name}:${minute.name}`
        setSelectedTime(currentTime);
    }, [hour, minute])

    return (
        <>
            <div className="flex flex-col max-md:w-full md:flex-row lg:gap-3 items-center ">
                {hourList.length > 0 && minuteList.length > 0 && (
                    <>
                        <Dropdown
                            data={hourList}
                            selectedData={hour}
                            setSelectedData={setHour}
                            classnames="md:max-w-full "
                        />
                        <p className="text-[20px]">:</p>
                        <Dropdown
                            data={minuteList}
                            selectedData={minute}
                            setSelectedData={setMinute}
                            classnames="md:max-w-full "
                        />
                    </>
                )}
            </div>
        </>
    )

}

export default TimePicker;