import { useEffect, useRef, useState } from "react"
import { Loading, PageTitle, SearchBar, SearchCount, showTopAlert } from "../../components"
import NotAuthorized from "../../components/NotAuthorized"
import Pagination from "../../components/Pagination"
import useAuth from "../../hooks/Log/useAuth"
import { useLocalization } from "../../hooks/Utils/useLocalization"
import { EmployeeFilter } from "../../services/Employee/EmployeeService"
import EmployeeDetailSearch from "./components/EmployeeDetailSearch"
import EmployeeTable from "./components/EmployeeTable"

const EmployeeList = () => {

    const strings = useLocalization();
    const firstVisit = useRef(true);

    const limit = parseInt(process.env.REACT_APP_TABLE_ROW_LIMIT);
    const [start, setStart] = useState(0);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);

    const [detailSearch, setDetailSearch] = useState({
        brandId: -1,
        status: -1,
        department: -1,
        searchText: ''
    })

    //Sayfa yetkileri için bu bölüm kullanılıyor
    const { employeeTypeId, checkPermission, getPermission } = useAuth();
    const roles = getPermission();
    const permission = checkPermission(roles, employeeTypeId);
    //Sayfa yetkileri için bu bölüm kullanılıyor

    const [totalRecord, setTotalRecord] = useState(0);
    const [data, setData] = useState([]);

    //Arama verileri değiştiğinde değerleri sıfırlıyoruz
    const resetValue = () => {
        setStart(start => 0);
        setCurrentPage(currentPage => 1);
        setLoading(loading => true);
    }

    //input değişiklikleri bu alandan yönetiliyor
    const changeValue = (value) => {
        setDetailSearch({ ...detailSearch, searchText: value });
        resetValue();
    }

    //select değişiklikleri bu alandan yönetiliyor
    const changeDetail = (values) => {
        setDetailSearch({ ...detailSearch, ...values });
        resetValue();
    }

    //sayfa değiştikçe bilgileri yeniden çağırıyoruz
    useEffect(() => {
        if (totalRecord !== 0) {
            getEmployees();
        }
    }, [start])

    const getEmployees = async () => {
        const model = {
            start: start,
            length: limit,
            ...detailSearch
        }

        const result = await EmployeeFilter(model);
        if (result.status === 200) {
            let resultContent = JSON.parse(result.content);
            setData(data => resultContent.data);
            setTotalRecord(totalRecord => resultContent.recordsTotal);
            setLoading(loading => false);
        } else {
            showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
        }
    }

    const sendRequestToEndpoint = () => {
        getEmployees();
    }

    useEffect(() => {

        let abortController = new AbortController();

        permission && sendRequestToEndpoint();

        return () => {
            abortController.abort();
        }
    }, [detailSearch, permission])

    useEffect(() => {
        firstVisit.current = false;
    }, [])

    return (
        <>
            {permission && (
                <>
                    <EmployeeDetailSearch
                        changeDetail={changeDetail}
                        loading={loading}
                    />
                    <SearchBar
                        changeValue={changeValue}
                        placeHolder={strings.search_bar.search_employee}
                        isDisabled={loading}
                    />
                    {!loading && <SearchCount count={totalRecord} />}
                </>
            )}
            <PageTitle title={strings.sidebar.employee.list} />
            {permission ? (
                loading ?
                (
                    <Loading />
                )
                :
                (
                    <>
                        <EmployeeTable data={data} sendRequestToEndpoint={sendRequestToEndpoint} />
                        <Pagination
                            totalCount={totalRecord}
                            limit={limit}
                            start={start}
                            setStart={setStart}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            setLoading={setLoading}
                            loadScreen={true}
                        />
                    </>
                )
            ) : (<NotAuthorized />)}   
        </>
    )
}

export default EmployeeList