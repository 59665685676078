import React, { useState, useEffect } from 'react'
import ReactDOM  from 'react-dom';

const Portal = ({children, container}) => {

  const [tooltipContainer, setTooltipContainer] = useState(null);

  useEffect(() => {
    setTooltipContainer(container)
  }, [container])

  return tooltipContainer ? ReactDOM.createPortal(children, tooltipContainer) : null;
}

export default Portal