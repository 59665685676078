import { fetchApi } from "../FetchApi";

export const GetMemberTickets = async (memberId) => {

    const url = '/prime_ticket/v1/support/list/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}