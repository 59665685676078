import { useLocalization } from "../../../../hooks/Utils/useLocalization";
import useAuth from "../../../../hooks/Log/useAuth";
import {
    addTimeZoneToFullDate,
    getBrandNameById,
    getLogoByBrandId,
    isItSpecified,
    maskPhoneNumber
} from "../../../../components/Utility";
import { CopyToClipboard } from "../../../../components";
import { MemberApplicationStatus } from "../../../../consts/Constants";
import { applicationStatusColorPicker } from "../Common/GetPerson";
import classNames from "classnames";
import { url } from "../../../../routes/utility";
import { Link } from "react-router-dom";

const NewCandidateTableRow = (props) => {

    const { member, setModal, setModalContent } = props;

    const strings = useLocalization();
    const { employee } = useAuth();

    const notSpecified = strings.errors.it_is_not_specified;

    return (
        <tr className="bg-white hover:bg-[#f0f0f0]">
            <td className="td align-middle">
                <div className="group relative max-w-max ml-6">
                    <img src={getLogoByBrandId(member.brand.id)} width="43" height="48" alt="" />
                    <div className="tooltip-top">
                        <p>{getBrandNameById(member.brand.id)}</p>
                    </div>
                </div>
            </td>
            <td className="td align-middle">
                <div className="flex items-center">
                    <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
                        person
                    </span>
                    <Link
                        to={url("member.detail.profile", { memberId: member?.memberId })}
                        target="_blank"
                        className="font-bold min-w-max text-blue hover:underline"
                    >
                        {member.fullName}
                    </Link>
                </div>
                <div className="flex items-center">
                    <span
                        className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
                        call
                    </span>
                    <div className="flex items-center gap-0.5">
                        <p className="min-w-max">{isItSpecified(maskPhoneNumber(member.phone), notSpecified)}</p>
                        <CopyToClipboard text={member.phone} className="-mb-1" iconClassName="!text-[13px]" />
                    </div>
                </div>
                <div className="flex items-center">
                    <span
                        className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
                        mail
                    </span>
                    <p>{isItSpecified(member.email, notSpecified)}</p>
                </div>
            </td>
            <td className="td align-middle">
                <button
                    type="button"
                    className={`badge-color border-none focus:border-none hover:border-none min-w-[200px] my-1 ${MemberApplicationStatus.NewRegistration !== member.applicationStatus ? 'cursor-pointer' : 'cursor-default'}  ${applicationStatusColorPicker(member.applicationStatus)} uppercase`}
                    onClick={() => {
                        if (MemberApplicationStatus.NewRegistration !== member.applicationStatus) {
                            setModal(true); setModalContent({ memberId: member.memberId, element: "log" })
                        }
                    }}
                >
                    <p>{member.applicationStatus}</p>
                </button>
                <div className="flex items-center max-w-max">
                    <div className="relative group">
                        <span
                            className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
                            equalizer
                        </span>
                        {member?.level && (
                            <div className="tooltip-top">
                                {strings.member.member_detail.terms.level}
                            </div>
                        )}
                    </div>
                    <p className="mb-1">{isItSpecified(member.level, notSpecified)}</p>
                </div>
                <div className="flex items-center max-w-max">
                    <div className="relative group">
                        <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
                            language
                        </span>
                        {member?.reasonToLearnEnglish && (
                            <div className="tooltip-top">
                                {strings.form_info.use_case}
                            </div>
                        )}
                    </div>
                    <div className="relative group">
                        <button
                            type="button"
                            className={classNames("min-w-max", {
                                "text-blue hover:underline font-bold": member?.reasonToLearnEnglish !== "Belirtilmedi",
                                "cursor-text": !member?.reasonToLearnEnglish !== "Belirtilmedi"
                            })}
                            onClick={() => {
                                if (member?.reasonToLearnEnglish !== "Belirtilmedi") {
                                    setModal(true);
                                    setModalContent({ memberId: member.memberId, element: "reasonToLearnEnglish" });
                                }
                            }}
                        >
                            <p className="mb-1">{isItSpecified(member?.reasonToLearnEnglish, notSpecified)}</p>
                        </button>
                        {member?.reasonToLearnEnglish &&
                            <div className="tooltip-top">
                                {strings.member.member_detail.profile.click_for_see_all_reasons}
                            </div>
                        }
                    </div>
                </div>
                {member?.utmSource && (
                    <div className="flex items-center max-w-max">
                        <div className="relative group">
                            <span
                                className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
                                ads_click
                            </span>
                            {member?.utmSource && (
                                <div className="tooltip-top">
                                    Utm Source
                                </div>
                            )}
                        </div>
                        <p className="mb-1">{member.utmSource}</p>
                    </div>
                )}
                {member?.landingPage && (
                    <div className="flex items-center max-w-max">
                        <div className="relative group">
                            <span
                                className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
                                link
                            </span>
                            {member?.landingPage && (
                                <div className="tooltip-top">
                                    Landing Page
                                </div>
                            )}
                        </div>
                        <p className="mb-1.5">{member.landingPage}</p>
                    </div>
                )}
                {member?.utmMedium && (
                    <div className="flex items-center max-w-max">
                        <div className="relative group">
                            <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
                                travel_explore
                            </span>
                            {member?.utmMedium && (
                                <div className="tooltip-top">
                                    Utm Medium
                                </div>
                            )}
                        </div>
                        <p className="mb-1">{member.utmMedium}</p>
                    </div>
                )}
                {member?.utmContent && (
                    <div className="flex items-center max-w-max">
                        <div className="relative group">
                            <span
                                className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
                                note_stack
                            </span>
                            {member?.utmContent && (
                                <div className="tooltip-top">
                                    Utm Content
                                </div>
                            )}
                        </div>
                        <p className="mb-1">{member.utmContent}</p>
                    </div>
                )}
            </td>
            <td className="td align-middle">
                <div className="badge max-w-[340px] my-1 text-center min-w-max">
                    <p><b>{strings.table.apply_date} </b>{addTimeZoneToFullDate(member.formDate, employee.TimeZone)}</p>
                </div>
            </td>
            <td className="td align-middle">
                <div className="badge my-1 min-w-[48px] text-center">
                    <button
                        type="submit"
                        onClick={() => { setModal(true); setModalContent({ memberId: member.memberId, element: "score" }) }}
                        className="font-bold text-blue hover:underline focus:underline cursor-pointer"
                    >
                        {member.score}
                    </button>
                </div>
            </td>
        </tr>
    )
}

export default NewCandidateTableRow;