import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { WeekDays } from '../../../../consts/Constants';
import { GetTeacherMissedCalls, GetTeacherScheduleTerm } from '../../../../services/Teacher/TeacherService';
import { ActiveLesson, BlockedLesson, FreeLesson, MissedLesson, TemproraryBlockedLesson } from '../../components';
import useAuth from '../../../../hooks/Log/useAuth';
import { addTimeZone } from '../../../../components/Utility';

const ProgramTable = (props) => {

    const { scheduleData, teacherId, sendRequestToEndpoint } = props;
    const { employee } = useAuth();

    const language = useSelector(state => state.localizationStore.language);

    const [times, setTimes] = useState([]);
    const [startHour, setStartHour] = useState(null);
    const [endHour, setEndHour] = useState(null);
    const [difference, setDifference] = useState(null);
  
    const [termData, setTermData] = useState([]);
    const [missedCallData, setMissedCallData] = useState([]);
  
    //Eğitmene ait aktif derslerin bilgisini getirir.
    const getTeacherScheduleTerm = async (teacherId) => {
  
      const result = await GetTeacherScheduleTerm(teacherId);
  
      if(result.status === 200){
          let resultContent = JSON.parse(result.content);

          let tmpTermData = [];
          
          resultContent?.map((term) => {
            let tmpBeginTime = addTimeZone(term.beginTime, employee.TimeZone);
            tmpTermData.push({ 
                beginTime: tmpBeginTime, 
                days: term.days, 
                lessonDuration: term.lessonDuration, 
                fullName: term.fullName, 
                memberId: term.memberId,
                termId: term.termId,
                studentNo: term.studentNo
            });
          });

          setTermData(termData => tmpTermData);
      }
    }

    const getTeacherMissedCalls = async (teacherId) => {
        
        const result = await GetTeacherMissedCalls(teacherId);

        if(result.status === 200){
            let resultContent = JSON.parse(result.content);

            let tmpMissedCallData = [];

            resultContent?.map((missed) => {
                let tmpBeginTime = addTimeZone(missed.beginTime, employee.TimeZone);
                tmpMissedCallData.push({
                    memberId: missed.memberId,
                    beginTime: tmpBeginTime,
                    fullName: missed.fullName,
                    day: missed.day,
                    lessonDuration: missed.lessonDuration,
                });
            });

            setMissedCallData(missedData => tmpMissedCallData);
        }
    }
    
  
    //Eğitmenin çalışma saati ve günü term bilgisi ile uyuşuyorsa term bilgisini içeren objeyi döndürür.
    const filterTermData = (beginTime, day) => {
        return termData.filter(term => term.beginTime === beginTime && term.days.includes(day));
    }

    //Eğitmenin çalışma saati ve günü term bilgisi ile uyuşuyorsa missed call bilgisini içeren objeyi döndürür.
    const filterMissedCallData = (beginTime, day) => {
        return missedCallData.find(missed => missed.beginTime === beginTime && missed.day === day);
    }
  
    useEffect(() => { 
      getTeacherScheduleTerm(teacherId);
      getTeacherMissedCalls(teacherId);
    }, []);
    
    useEffect(() => {    
      let scheduleTime = [];
  
      scheduleData.map((schedule) => {
          scheduleTime.push(parseInt(schedule.time.substring(0,2)))
      });
  
      const set = new Set(scheduleTime);
      const formattedScheduleTime = Array.from(set);
  
      setStartHour(startHour => Math.min(...formattedScheduleTime));
      setEndHour(endHour => Math.max(...formattedScheduleTime));
      setDifference(difference => endHour - startHour);
    }, [scheduleData, times])
  
    useEffect(() => {
  
      let hours = Array.from({ length: difference + 1 }, (_ , i) => i + startHour);
      let minutes = Array.from({ length: 6 }, (_ , i) => i * 10);
      let tmpTimes = [];
  
      for (let hour of hours) {
          for (let minute of minutes) {
            // Saati ve dakikayı iki haneli sayıya dönüştürün
            let hourString = hour.toString().padStart(2, "0");
            let minuteString = minute.toString().padStart(2, "0");
            
            // Saati ve dakikayı yazdırın
            tmpTimes.push(hourString + ":" + minuteString);
          }
      }

      let tmpScheduleArr = [];

      tmpScheduleArr = scheduleData.filter(schedule => schedule.day === 1)
      tmpTimes = tmpScheduleArr.map(schedule => schedule.time);
      
      setTimes(tmpTimes);
    }, [difference, startHour, endHour, scheduleData]);
    
    return (
      <>
          <div className="mx-auto px-4 my-4">
              <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
                  <div className="inline-block min-w-full rounded-lg overflow-x-hidden  overflow-y-scroll relative">
                      <table className="min-w-full leading-normal text-header">
                          <thead className="table-fixed sticky top-0 left-0 right-0 bg-white z-10">
                            <tr>
                                <th className="th w-[12.5%]"></th>
                                {WeekDays.length > 0 &&
                                    WeekDays.map((day, index) => (
                                        <th key={index} className='min-w-[225px] th w-[12.5%] border-l'>{day[language]}</th>
                                    ))
                                }
                            </tr>
                          </thead>
                          <tbody>
                              {times.length > 0 && times.map((time, index) => (
                                  <tr key={index} className=''>
                                      <td className="td font-bold p-5 align-middle">{time}</td>
                                      {[1, 2, 3, 4, 5].map((day) => {
                                          const matchingSchedule = scheduleData.find(
                                              (schedule) => schedule.time === time && schedule.day === day
                                          );
                                          
                                          if (!matchingSchedule) {
                                              return (
                                                  <td
                                                      key={day}
                                                      className="teacher-program-td"
                                                  ></td>
                                              );
                                          }
  
                                          switch (matchingSchedule.status) {
                                              case 0:
                                                  return (
                                                      <td
                                                          key={day}
                                                          className="teacher-program-td"
                                                      >
                                                          {/*

                                                          Geçici bloke koyma işlemi kaldırıldığı için
                                                          burayı kapattık.

                                                          İleride açılırsa burası açılarak aktif
                                                          hale getirilebilir

                                                          <FreeLesson
                                                              teacherId={teacherId} 
                                                              day={matchingSchedule.day} 
                                                              time={matchingSchedule.time} 
                                                              sendRequestToEndpoint={sendRequestToEndpoint} 
                                                          />*/}
                                                      </td>
                                                  );
                                              case 1:
                                                  return (
                                                      <td
                                                          key={day}
                                                          className="teacher-program-td bg-blue"
                                                          id={`${matchingSchedule.time}_${matchingSchedule.day}_active`}
                                                      >
                                                          <ActiveLesson 
                                                              day={matchingSchedule.day} 
                                                              time={matchingSchedule.time} 
                                                              data={filterTermData(time, matchingSchedule.day)} 
                                                          />
                                                      </td>
                                                  );
                                              case 2:
                                                return (
                                                    <td
                                                        key={day}
                                                        className="teacher-program-td bg-blue"
                                                        id={`${matchingSchedule.time}_${matchingSchedule.day}_active`}
                                                    >
                                                        <ActiveLesson 
                                                            day={matchingSchedule.day} 
                                                            time={matchingSchedule.time} 
                                                            data={filterTermData(time, matchingSchedule.day)} 
                                                        />
                                                    </td>
                                                );
                                                  
                                             case 3:
                                                return (
                                                    <td
                                                        key={day}
                                                        className="teacher-program-td"
                                                        id={`${matchingSchedule.time}_${matchingSchedule.day}_missed`}
                                                    >
                                                        <MissedLesson 
                                                            day={matchingSchedule.day} 
                                                            time={matchingSchedule.time} 
                                                            data={filterMissedCallData(time, matchingSchedule.day)}
                                                            termData={filterTermData(time, matchingSchedule.day)}
                                                        />
                                                    </td>
                                                );
                                              case 4:
                                                  return (
                                                      <td
                                                          key={day}
                                                          className="teacher-program-td"
                                                          id={`${matchingSchedule.time}_${matchingSchedule.day}_blocked`}
                                                      >
                                                          <BlockedLesson 
                                                              teacherId={teacherId} 
                                                              day={matchingSchedule.day} 
                                                              time={matchingSchedule.time} 
                                                              sendRequestToEndpoint={sendRequestToEndpoint}
                                                          />
                                                      </td>
                                                  );
                                              case 6:
                                                  return (
                                                      <td
                                                          key={day}
                                                          className="teacher-program-td"
                                                          id={`${matchingSchedule.time}_${matchingSchedule.day}_temprorary_blocked`}
                                                      >
                                                          <TemproraryBlockedLesson 
                                                              teacherId={teacherId}  
                                                              day={matchingSchedule.day} 
                                                              time={matchingSchedule.time}
                                                              sendRequestToEndpoint={sendRequestToEndpoint}
                                                          />
                                                      </td>
                                                  );
                                              default:
                                                  return null;
                                          }
                                      })}
                                  </tr>
                              ))}
  
                          </tbody>
                      </table>
                  </div>
              </div>
          </div>
      </>
    )
  };

export default ProgramTable;