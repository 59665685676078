import { fetchApi } from '../index';

//Term schedule sayfasında "attented" derslerin bilgisini getiren servis.
export const GetLessonDetail = async (scheduleId) => {

    const url = '/member/v1/lesson/get_lesson_details/';

    const payload = {
        scheduleId: scheduleId
    };

    return await fetchApi(url, payload);
}