import {useLocalization} from "../../hooks/Utils/useLocalization";
import {PageTitle} from "../../components";
import {Accordion} from "../Member/components";
import React from "react";

const MarketReport = () => {

    const strings = useLocalization();

    return (
        <>
            <PageTitle title={strings.sidebar.report.market_reports} />

            <Accordion
                title={strings.report.market_reports.information_title}
                outSideClose={true}
                isOpen={true}
                content={
                    <p dangerouslySetInnerHTML={{ __html: strings.report.market_reports.information_one}}></p>
                }
            />
            <div className="flex justify-center mt-5">
                <iframe title="market" width="1725" height="1725" src="https://lookerstudio.google.com/embed/reporting/3ebcad72-1696-418f-bac3-6dda743594fd/page/EiisD" frameBorder="0" allowFullScreen></iframe>
            </div>
        </>
    )
}

export default MarketReport;