import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {
  Dropdown,
  DropdownReadonly,
  PageTitle,
  showCenteredAlertTwoButtons,
  showTopAlert
} from "../../../../../components";
import {useLocalization} from "../../../../../hooks/Utils/useLocalization";
import {
  GetAllCorporateSalesPerson,
  GetAllCorporateSupportPerson,
  GetAllProductivityPerson,
  GetAllReferencerPerson,
  GetAllSalesPerson,
} from "../../../../../services/Employee/EmployeeService";
import {CheckMemberCorporate, GetMemberBrandId} from "../../../../../services/Member/MemberService";
import {FindEmployeeMember, UpdateEmployeeMember} from "../../../../../services/Member/MemberEmployeeService";
import useAuth from "../../../../../hooks/Log/useAuth";
import {EmployeeStatusType, ServiceErrorMessages} from "../../../../../consts/Constants";

const Employees = () => {

  const strings = useLocalization();
  const { employeeTypeId, checkPermission, employee } = useAuth();
  const { memberId } = useParams() || false;

  const permission = checkPermission([EmployeeStatusType.Yönetici, EmployeeStatusType.Yazılımcı], employeeTypeId);
  const SEDRA_EMPLOYEE_ID = "cbd4a681-b2dd-445b-a486-af6efd6163e3";

  const firstVisit = useRef(true);
  const firstSalesConsultant = { id: -1, name: strings.member.member_detail.others.no_sales_man };
  const firstProductivityConsultant = { id: -1, name: strings.member.member_detail.others.no_productivity };
  const firstReferencer = { id: -1, name: strings.member.member_detail.others.no_referencer };
  const firstCorporateSales = { id: -1, name: strings.member.member_detail.others.no_corporate_sales };
  const firstCorporateSupport = { id: -1, name: strings.member.member_detail.others.no_corporate_support };

  const [selectedSales, setSelectedSales] = useState(firstSalesConsultant);
  const [selectedProductivity, setSelectedProductivity] = useState(firstProductivityConsultant);
  const [selectedReferencer, setSelectedReferencer] = useState(firstReferencer);
  const [oldSales, setOldSales] = useState(firstSalesConsultant);
  const [oldProductivity, setOldProductivity] = useState(firstProductivityConsultant);
  const [oldReferencer, setOldReferencer] = useState(firstReferencer);
  const [memberBrandId, setMemberBrandId] = useState(0);
  const [salesConsultantList, setSalesConsultantList] = useState([firstSalesConsultant]);
  const [productivityConsultantList, setProductivityConsultantList] = useState([firstProductivityConsultant]);
  const [referencerList, setReferencerList] = useState([firstReferencer]);
  const [employeeMembers, setEmployeeMembers] = useState([]);

  const [isMemberCorporate, setIsMemberCorporate] = useState();
  const [selectedCorporateSales, setSelectedCorporateSales] = useState(firstCorporateSales);
  const [selectedCorporateSupport, setSelectedCorporateSupport] = useState(firstCorporateSupport);
  const [corporateSalesPersonList, setCorporateSalesPersonList] = useState([firstCorporateSales]);
  const [corporateSupportPersonList, setCorporateSupportPersonList] = useState([firstCorporateSupport]);

  //Reddedildiğinde seçili verileri  eski haline getireceğiz
  const dismissSales = () => {
    setSelectedSales(oldSales);
  }

  const dismissProductivity = () => {
    setSelectedProductivity(oldProductivity);
  }

  const dismissReferencer = () => {
    setSelectedReferencer(oldReferencer);
  }

  //Reddedildiğinde seçili verileri  eski haline getireceğiz

  //Satış personeli güncelleniyor.
  const sendToEndpointSales = async () => {

    const result = await UpdateEmployeeMember(selectedSales.id, memberId, employee.EmployeeId);

    //Eğer işlem başarılıyla eski satış personeli güncelleniyor
    if (result.status === 200) {
      showTopAlert(strings.member.member_detail.others.success_message, "success");
      setOldSales(selectedSales);
    } else {
      if(result.content === ServiceErrorMessages.DISTRIBUTION_STATUS_INACTIVE) {
        showTopAlert(strings.member.member_detail.others.distribute_error_message, "error");
      } else {
        showTopAlert(strings.errors.an_error_occurred, "error");
      }

      //Eğer işlem başarısızsa seçilen satış personeli eski satış personeli olarak atanıyor.
      setSelectedSales(oldSales);
    }
  }

  //verimlilik danışmanı güncelleniyor.
  const sendToEndpointProductivity = async () => {
    const result = await UpdateEmployeeMember(selectedProductivity.id, memberId, employee.EmployeeId);

    //Eğer işlem başarılıyla eski satış personeli güncelleniyor
    if (result.status === 200) {
      showTopAlert(strings.member.member_detail.others.success_message, "success");
      setOldProductivity(selectedProductivity);
    } else {
      if(result.content === ServiceErrorMessages.DISTRIBUTION_STATUS_INACTIVE) {
        showTopAlert(strings.member.member_detail.others.distribute_error_message, "error");
      } else {
        showTopAlert(strings.errors.an_error_occurred, "error");
      }

      //Eğer işlem başarısızsa seçilen satış personeli eski satış personeli olarak atanıyor.
      setSelectedProductivity(oldProductivity);
    }

  }

  const sendToEndpointReferencer = async () => {
    const result = await UpdateEmployeeMember(selectedReferencer.id, memberId, employee.EmployeeId);

    //Eğer işlem başarılıyla eski satış personeli güncelleniyor
    if (result.status === 200) {
      showTopAlert(strings.member.member_detail.others.success_message, "success");
      setOldReferencer(selectedReferencer);
    } else {
      if(result.content === ServiceErrorMessages.DISTRIBUTION_STATUS_INACTIVE) {
        showTopAlert(strings.member.member_detail.others.distribute_error_message, "error");
      } else {
        showTopAlert(strings.errors.an_error_occurred, "error");
      }

      //Eğer işlem başarısızsa seçilen satış personeli eski satış personeli olarak atanıyor.
      setSelectedReferencer(oldReferencer);
    }
  }

  useEffect(() => {

    if (firstVisit.current === false && salesConsultantList.length > 0) {
      if (selectedSales.id === -1) {
        showTopAlert(strings.member.member_detail.others.cannot_perform_this_assignment, "warning");
        setSelectedSales(oldSales);
      } else if (oldSales.id !== selectedSales.id) {
        showCenteredAlertTwoButtons(
          strings.alert.centered_alert_two_buttons.title,
          strings.alert.centered_alert_two_buttons.save_question,
          strings.button.confirm,
          strings.alert.centered_alert_two_buttons.cancel_text,
          sendToEndpointSales,
          dismissSales
        )
      }
    }

  }, [selectedSales])

  useEffect(() => {

    if (firstVisit.current === false && productivityConsultantList.length > 0) {
      if (selectedProductivity.id === -1) {
        showTopAlert(strings.member.member_detail.others.cannot_perform_this_assignment, "warning");
        setSelectedProductivity(oldProductivity);
      } else if (oldProductivity.id !== selectedProductivity.id) {
        showCenteredAlertTwoButtons(
          strings.alert.centered_alert_two_buttons.title,
          strings.alert.centered_alert_two_buttons.save_question,
          strings.button.confirm,
          strings.alert.centered_alert_two_buttons.cancel_text,
          sendToEndpointProductivity,
          dismissProductivity
        )
      }
    }
  }, [selectedProductivity])

  useEffect(() => {

    if (firstVisit.current === false && referencerList.length > 0) {
      if (selectedReferencer.id === -1) {
        showTopAlert(strings.member.member_detail.others.cannot_perform_this_assignment, "warning");
        setSelectedReferencer(oldReferencer);
      } else if (oldReferencer.id !== selectedReferencer.id) {
        showCenteredAlertTwoButtons(
          strings.alert.centered_alert_two_buttons.title,
          strings.alert.centered_alert_two_buttons.save_question,
          strings.button.confirm,
          strings.alert.centered_alert_two_buttons.cancel_text,
          sendToEndpointReferencer,
          dismissReferencer
        )
      }
    }
  }, [selectedReferencer])

  //Markaya ait tüm satış personellerini getiriyoruz
  const getAllSalesPerson = async (brandId) => {
    const result = await GetAllSalesPerson(brandId);

    let arrayList = [];
    if (result.status === 200) {
      const data = JSON.parse(result.content);
      data.map((item) => {
        let arrayItem = { id: item.value, name: item.text };
        arrayList.push(arrayItem);
      })
    }

    setSalesConsultantList([firstSalesConsultant, ...arrayList]);
  }

  //Markaya ait tüm verimlilik danışmanlarını getiriyoruz
  const getAllProductivityPerson = async (brandId) => {
    const result = await GetAllProductivityPerson(brandId);

    let arrayList = [];

    if (result.status === 200) {
      const data = JSON.parse(result.content);
      data.map((item) => {
        let arrayItem = { id: item.value, name: item.text };
        arrayList.push(arrayItem);
      })
    }

    setProductivityConsultantList([firstProductivityConsultant, ...arrayList]);
  }

  //Markaya ait tüm referans personellerini getiriyoruz
  const getAllReferencerPerson = async (brandId) => {
    const result = await GetAllReferencerPerson(brandId);

    let arrayList = [];

    if (result.status === 200) {
      const data = JSON.parse(result.content);
      data.map((item) => {
        let arrayItem = { id: item.value, name: item.text };
        arrayList.push(arrayItem);
      })
    }

    setReferencerList([firstReferencer, ...arrayList]);
  }

  const getAllCorporateSupportPerson = async (brandId) => {
    const result = await GetAllCorporateSupportPerson(brandId);

    let arrayList = [];

    if (result.status === 200) {
      const data = JSON.parse(result.content);
      data.map((item) => {
        let arrayItem = { id: item.value, name: item.text };
        arrayList.push(arrayItem);
      })
    }

    setCorporateSupportPersonList([firstCorporateSupport, ...arrayList]);
  }

  const getAllCorporateSalesPerson = async (brandId) => {
    const result = await GetAllCorporateSalesPerson(brandId);

    let arrayList = [];

    if (result.status === 200) {
      const data = JSON.parse(result.content);
      data.map((item) => {
        let arrayItem = { id: item.value, name: item.text };
        arrayList.push(arrayItem);
      })
    }

    setCorporateSalesPersonList([firstCorporateSales, ...arrayList]);
  }

  //Member a ait brandID yi alıyoruz.
  const getMemberBrandId = async (memberId) => {
    const result = await GetMemberBrandId(memberId);

    if (result.status === 200) {
      setMemberBrandId(memberBrandId => parseInt(result.content));
    }
  }

  //Member a ait çalışanları alıyoruz
  const findEmployeeMember = async (memberId) => {
    const result = await FindEmployeeMember(memberId);

    if (result.status === 200) {
      setEmployeeMembers(JSON.parse(result.content));
    }
  }

  //Member'ın corporate olup olmadığını sorguluyoruz
  const checkMemberCorporate = async (memberId) => {
    const result = await CheckMemberCorporate(memberId);

    if (result.status === 200) {
      setIsMemberCorporate(JSON.parse(result.content));
    }
  }

  //Eğer member a ait brand id gelirse o brand e ait kullanıcıları ve o member a ait kullanıcıları getiriyoruz
  useEffect(() => {
    if (memberBrandId !== 0 && memberId) {
      checkMemberCorporate(memberId);
      findEmployeeMember(memberId);
      getAllReferencerPerson(memberBrandId);
    }
  }, [memberBrandId])

  useEffect(() => {
    if(typeof isMemberCorporate !== "undefined" && isMemberCorporate) {
      getAllCorporateSupportPerson(memberBrandId);
      getAllCorporateSalesPerson(memberBrandId);
    } else if (typeof isMemberCorporate !== "undefined" && !isMemberCorporate) {
      getAllSalesPerson(memberBrandId);
      getAllProductivityPerson(memberBrandId);
    }
  }, [isMemberCorporate]);

  //Eğer member a ait herhangi bir çalışan varsa seçili çalışan yapacağız
  useEffect(() => {
    if (employeeMembers.length > 0) {
      let idList = new Set(employeeMembers.map(({ id }) => id));

      idList.forEach((id) => {
        if(typeof isMemberCorporate !== "undefined") {

          let selectedReferencer = referencerList.find(person => person.id === id);
          if (typeof selectedReferencer !== 'undefined') {
            setSelectedReferencer(selectedReferencer);
            setOldReferencer(selectedReferencer);
          }

          if(!isMemberCorporate) {
            if(salesConsultantList.length > 0 || productivityConsultantList.length > 0 || referencerList.length > 0) {

              let selectedSales = salesConsultantList.find(person => person.id === id);
              let selectedProductivity = productivityConsultantList.find(person => person.id === id);

              if (typeof selectedSales !== 'undefined') {
                setSelectedSales(selectedSales);
                setOldSales(selectedSales);
              }
              if (typeof selectedProductivity !== 'undefined') {
                setSelectedProductivity(selectedProductivity);
                setOldProductivity(selectedProductivity);
              }              

            }
          } else {
            if(corporateSalesPersonList.length > 0 || corporateSalesPersonList.length > 0) {
              let tmpSelectedCorporateSales = corporateSalesPersonList.find(person => person.id === id);
              let tmpSelectedCorporateSupport = corporateSupportPersonList.find(person => person.id === id);

              if (typeof tmpSelectedCorporateSales !== 'undefined') {
                setSelectedCorporateSales(tmpSelectedCorporateSales);
              }
              if (typeof tmpSelectedCorporateSupport !== 'undefined') {
                setSelectedCorporateSupport(tmpSelectedCorporateSupport);
              }
            }
          }          
        }
      })
    }
  }, [employeeMembers, isMemberCorporate, corporateSupportPersonList, corporateSalesPersonList, salesConsultantList, productivityConsultantList, referencerList])


  useEffect(() => {
    //Sayfa yüklendikten sonra false yaparak her değişiklikte
    //servisin ziyaret edilmesini sağlıyoruz 
    firstVisit.current = false;

    if (memberId) {
      getMemberBrandId(memberId);
    }
  }, [])

  return (
    <>
      <PageTitle title={strings.member.member_detail.header.content.users} />
      <div className="flex flex-col lg:flex-row lg:justify-between">
        <div className="mt-5 w-full lg:w-[32.5%]">
          <div className="flex flex-col px-[10px] pb-0">
            <label className="text-[13px] font-bold mb-2">
              {!isMemberCorporate && strings.member.member_detail.others.sales}
              {isMemberCorporate && strings.member.member_detail.others.corporate_sales}
            </label>
            {!isMemberCorporate && (
              <Dropdown
                classnames="md:max-w-full"
                data={salesConsultantList || []}
                selectedData={selectedSales}
                setSelectedData={setSelectedSales}
                isDisabled={employee.EmployeeId === SEDRA_EMPLOYEE_ID ? false : !permission}
              />
            )}
            {isMemberCorporate && (
                <DropdownReadonly
                    classnames="md:max-w-full"
                    data={corporateSalesPersonList || []}
                    title={selectedCorporateSales.name}
                />
            )}
          </div>
        </div>
        <div className="mt-5 w-full lg:w-[32.5%]">
          <div className="flex flex-col px-[10px] pb-0">
            <label className="text-[13px] font-bold mb-2">
              {!isMemberCorporate && strings.member.member_detail.others.productivity}
              {isMemberCorporate && strings.member.member_detail.others.corporate_support}
            </label>
            {!isMemberCorporate && (
              <Dropdown
                classnames="md:max-w-full"
                data={productivityConsultantList || []}
                selectedData={selectedProductivity}
                setSelectedData={setSelectedProductivity}
                isDisabled={employee.EmployeeId === SEDRA_EMPLOYEE_ID ? false : !permission}
              />
            )}
            {isMemberCorporate && (
                <DropdownReadonly
                    classnames="md:max-w-full"
                    data={corporateSupportPersonList || []}
                    title={selectedCorporateSupport.name}
                />
            )}
          </div>
        </div>
        <div className="mt-5 w-full lg:w-[32.5%]">
          <div className="flex flex-col px-[10px] pb-0">
            <label className="text-[13px] font-bold mb-2">
              {strings.member.member_detail.others.referencer}
            </label>
            <Dropdown
              classnames="md:max-w-full"
              data={referencerList || []}
              selectedData={selectedReferencer}
              setSelectedData={setSelectedReferencer}
              isDisabled={employee.EmployeeId === SEDRA_EMPLOYEE_ID ? false : !permission}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Employees;
