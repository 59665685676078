import React from "react";
import DiscountCodeTableRow from "./DiscountCodeTableRow";
import { NoRecordsFound } from "../../../../components";
import { useLocalization } from "../../../../hooks/Utils/useLocalization";
import useAuth from "../../../../hooks/Log/useAuth";
import { getButtonPermission } from "../../../../components/Buttons";

const DiscountCodeTable = ({ data }) => {

  const strings = useLocalization();

  return (
    <>
      <div className="mx-auto px-4 my-4">
        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
          <div className="inline-block min-w-full rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal text-header">
              <thead>
                <tr>
                  <th className="th"><p className="ml-6">{strings.table.brand}</p></th>
                  <th className="th w-[200px]">{strings.table.discount_code_details}</th>
                  <th className="th">{strings.table.discount_details}</th>
                  <th className="th w-[100px]">{strings.form_info.status}</th>
                  <th className="th w-[200px]"><p className="min-w-max">{strings.table.use_status}</p></th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 ?
                      data.map((discount, index) => (
                        <DiscountCodeTableRow 
                          key={index}
                          discount={discount}
                        />
                      ))
                      : 
                      (
                        <tr className="bg-white hover:bg-[#f0f0f0]">
                          <td colSpan="5">
                              <NoRecordsFound />
                          </td>
                        </tr>
                      )
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default DiscountCodeTable;
