import classNames from 'classnames';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Loading, showTopAlert } from '../../../../../../components'
import { PermissionTypes } from '../../../../../../components/Utility';
import { useLocalization } from '../../../../../../hooks/Utils/useLocalization';
import { GetMemberNotificationPermission } from '../../../../../../services/Permission/PermissionService';

const PermissionsModal = (props) => {

    const { deviceId } = props

    const strings = useLocalization();
    const { memberId } = useParams() || false;

    const [loading, setLoading] = useState(true);
    const [permissions, setPermissions] = useState([])

    //Silinen ödemeleri geri alma işlemini gerçekleştiren fonksiyon.
    const getMemberNotificationPermission = async (memberId, deviceId) => {
        setLoading(loading => true)

        const model = {
            memberId: memberId,
            deviceId: deviceId
        }
        const result = await GetMemberNotificationPermission(model);

        if (result.status === 200) {
            const resultContent = JSON.parse(result.content);
            setPermissions(permissions => resultContent)
        } else {
            showTopAlert(strings.errors.an_error_occurred, "error");
        }
        setLoading(loading => false)
    }

    useEffect(() => {
        if (memberId && deviceId) {
            getMemberNotificationPermission(memberId, deviceId)
        }
    }, [])

    return (
        <>
            <div className="flex justify-center">
                <p className="font-bold text-[15px]">{strings.member.member_detail.others.permissions}</p>
            </div>
            {loading ? (
                <Loading />
            ) : (
                <div className="mx-auto px-4">
                    <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
                        <div className="inline-block min-w-full rounded-lg overflow-hidden">
                            <table className="min-w-full leading-normal text-header">
                                <thead>
                                    <tr>
                                        <th className="th">{strings.member.member_detail.others.permission_type}</th>
                                        <th className="th w-[170px]">{strings.member.member_detail.others.permission_status}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {PermissionTypes.map((permissionType, index) => (
                                        permissionType.id !== 2 &&
                                        <tr key={index} className="bg-white hover:bg-[#f0f0f0]">
                                            <td className="td align-middle py-4">
                                                {permissionType.name}
                                            </td>
                                            <td className="td align-middle py-4">
                                                <div
                                                    className={classNames("mr-2 w-5 h-5 flex-shrink-0 rounded-[3px] cursor-pointer duration-500 flex items-center justify-center", {
                                                        "bg-green border border-green text-white": permissions.some(permission => permission === permissionType.id),
                                                        "border border-[#aaaaaa]": !permissions.some(permission => permission === permissionType.id)
                                                    })}
                                                >
                                                    <span className={classNames('text-inherit text-[20px] material-symbols-outlined animate-fadeIn font-bold', { 'hidden': !permissions.some(permission => permission === permissionType.id) })}>
                                                        done
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default PermissionsModal