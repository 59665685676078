import React, { useState } from 'react';
import { Input, showTopAlert } from '../../../../../../../components';
import { useLocalization } from '../../../../../../../hooks/Utils/useLocalization';
import { UpdateStudentName } from '../../../../../../../services/Term/TermService';
import {allowLatinCharacters, allowPasteLatinCharacters, preventPaste} from "../../../../../../../components/Utility";
import useAuth from "../../../../../../../hooks/Log/useAuth";

const StudentNameModal = (props) => {

  const { name, closeButton, termId, memberId, sendRequestToNameEndpoint } = props;
  const strings = useLocalization();
  const { employee } = useAuth();
  const [input, setInput] = useState(name);

  const submitHandle = async (e) => {
    e.preventDefault();

    if(name === input){
        showTopAlert(strings.member.member_detail.terms.modal.student_name_success, "success");
        setTimeout(() => closeButton() , 3000);
    } else {
        const model = {
            termId: termId,
            memberId: memberId,
            studentName: input,
            createUserId: employee?.EmployeeId
        };

        const result = await UpdateStudentName(model);

        if(result.status === 200){
            showTopAlert(strings.member.member_detail.terms.modal.student_name_success, "success");
            sendRequestToNameEndpoint();
            setTimeout(() => closeButton() , 3000);
        } else {
            showTopAlert(strings.errors.an_error_occurred, "error");
        }
    }
  }

  return (
    <>
        <form onSubmit={submitHandle} className="flex flex-col text-start">
            <label htmlFor="student_name">{strings.member.member_detail.terms.student_name}</label>
            <Input 
                id="student_name"
                type="text"   
                defaultValue={name}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={allowLatinCharacters}
                onPaste={allowPasteLatinCharacters}
            />
            <div className="flex gap-3 justify-center">
                <button 
                    type='submit'
                    className="button search-button gap-3 text-white bg-blue border-blue mt-7 px-10"
                >
                    {strings.button.save_upper}
                </button>
                <button 
                    type='button'
                    className="button search-button gap-3 text-white bg-red focus:border-red border-red mt-7 px-10"
                    onClick={closeButton}
                >
                    {strings.button.cancel_upper}
                </button>
            </div>
        </form>
    </>
  )
}

export default StudentNameModal