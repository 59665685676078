import React, {useEffect, useState} from "react";
import {useLocalization} from "../../../../hooks/Utils/useLocalization";
import {showTopAlert} from "../../../../components";
import {
  colorForCurrentCard,
  formatCurrency,
  getCurrencySymbolByBrandId,
  handleCheckbox,
  ISOStringToDate
} from "../../../../components/Utility";
import {Link} from "react-router-dom";
import {url} from "../../../../routes/utility";
import classNames from "classnames";
import useAuth from "../../../../hooks/Log/useAuth";
import {ChangeInvoiceApprovalStatus} from "../../../../services/Accounting/InvoiceService";

const InvoiceTableRow = (props) => {

  const strings = useLocalization();
  const { invoice } = props;
  const { employee } = useAuth();

  const [isInvoiceApproved, setIsInvoiceApproved] = useState(null);
  const [cardType, setCardType] = useState(null);

  const handleInvoiceStatus = (invoiceId) => {
    setIsInvoiceApproved(!isInvoiceApproved);
    changeInvoiceStatus(invoiceId, isInvoiceApproved);
  }

  const localizationForCardType = (cardType) => {
    switch(cardType.toLocaleUpperCase()){
        case 'ŞAHIS':
            return setCardType(strings.accounting.current_card.person_upper);
        case 'KURUMSAL':
            return setCardType(strings.accounting.current_card.corporate_upper);
        default:
            return strings.errors.it_is_not_specified_upper;
    }
  }

  const changeInvoiceStatus = async (invoiceId, status) => {

    let model = {
      invoiceId: invoiceId,
      status: status ? 0 : 1
    }

    const result = await ChangeInvoiceApprovalStatus(model);

    if(result.status === 200) {
      if(status){
        showTopAlert(strings.accounting.invoice.invoice_canceled_alert, "success");
      } else {
        showTopAlert(strings.accounting.invoice.invoice_approved_alert, "success");
      }
    } else {
      showTopAlert(strings.errors.an_error_occurred, "error");
    }
  }

  useEffect(() => {
    localizationForCardType(invoice.accountingMemberType);
    setIsInvoiceApproved(isInvoiceApproved => invoice.status ? true : false);
  }, [invoice])


  return (
    <>
      <tr className="group">
        <td className="td align-middle">
          <h3 className="text-base font-bold mb-2">{invoice.invoiceNo}</h3>
          <div className="flex items-center min-w-[200px]">
            <input
              id={`isActive_${invoice.id}`}
              type="checkbox"
              className="hidden peer"
              onClick={() => { handleInvoiceStatus(invoice.id)}}
            />
            <label
              htmlFor={`isActive_${invoice.id}`}
              className={`${handleCheckbox(isInvoiceApproved)} mr-2 w-5 h-5 rounded-[3px] cursor-pointer duration-500 flex items-center justify-center`}
            >
              <span className={classNames('text-inherit text-[20px] material-symbols-outlined animate-fadeIn font-bold', { 'hidden' : !isInvoiceApproved})}>
                done
              </span>
            </label>
            {isInvoiceApproved && <p className="animate-fadeIn">{strings.accounting.invoice.invoice_approved}</p>}
            {!isInvoiceApproved && <p className="animate-fadeIn">{strings.accounting.invoice.invoice_canceled}</p>}
          </div>
        </td>
        <td className="td align-middle">
          <div className={`px-[25px] py-[6px] inline-flex justify-center items-center text-[11px] text-white rounded-full ${colorForCurrentCard(invoice.accountingMemberType)}`}>
            {cardType}
          </div>
          <div className="flex items-center mt-[6px]">
            <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
              person
            </span>
            <Link
              to={url("member.detail.profile", { memberId: invoice.memberId })}
              target="_blank"
              className="font-bold text-blue hover:underline focus:underline"
            >
              <p className="min-w-max">{invoice.memberName}</p>
            </Link>
          </div>
          <div className="flex items-center">
            <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
              location_on
            </span>
            <p>{invoice.address}</p>
          </div>
        </td>
        <td className="td align-middle">
          <div className="flex items-center">
            <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
              business_center
            </span>
            <p className="font-bold">{invoice.bankName}</p>
          </div>
          <div className="flex items-center">
            <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
              credit_card
            </span>
            <p>{invoice.paymentType} - {invoice.installment} {strings.member.member_detail.sales_operations.payment_option}</p>
          </div>
          <div className="flex items-center">
            <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
              calendar_month
            </span>
            <p>{ISOStringToDate(invoice.paymentDate, employee.TimeZone)}</p>
          </div>
        </td>
        <td className="td w-[200px]">
          <p className="font-bold text-xl mb-2">
            {formatCurrency(invoice.totalAmount)} {getCurrencySymbolByBrandId(invoice?.brandId)}
          </p>
          <div className="badge mb-2 uppercase">
            <p>
              <b>{strings.accounting.invoice.net_amount_upper}: </b>
              {formatCurrency(invoice.netAmount)} {getCurrencySymbolByBrandId(invoice?.brandId)}
            </p>
          </div>
          <div className="badge mb-2 uppercase">
            <p>
              <b>{strings.member.member_detail.sales_operations.kdv}: </b>
              {formatCurrency(invoice.valueAddedTax)} {getCurrencySymbolByBrandId(invoice?.brandId)}
            </p>
          </div>
        </td>
      </tr>
    </>
  );
};

export default InvoiceTableRow;
