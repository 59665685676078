import React, {useState} from 'react'
import { useLocalization } from '../../../hooks/Utils/useLocalization'
import { NoRecordsFound } from '../../../components';
import TaskTableRow from './TaskTableRow';
import { Modal } from '../../../components';
import StartTaskModal from '../../Member/components/MemberDetail/Tasks/StartTaskModal';

const TaskTable = ({data}) => {

  const strings = useLocalization();
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState({});

  return (
    <>
        <div className="mx-auto px-4 my-4">
            <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
                <div className="inline-block min-w-full rounded-lg overflow-hidden">
                    <table className="min-w-full leading-normal text-header">
                        <thead>
                            <tr>
                                <th className="th sm:w-[10%] min-w-max">
                                    <p className='ml-6'>{strings.table.brand}</p>
                                </th>
                                <th className="th sm:w-[45%] min-w-max">
                                    {strings.table.task_information}
                                </th>
                                <th className="th sm:w-[25%] min-w-max">
                                    {strings.table.operation_person_information}
                                </th>
                                <th className="th sm:w-[10%] min-w-max">
                                    {strings.table.operation}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.length > 0 ? data.map((task, index) => (
                                <TaskTableRow
                                    key={index}
                                    task={task}
                                    setModal={setModal}
                                    setModalData={setModalData}
                                />
                            )) 
                            : 
                            (
                            <tr className="bg-white hover:bg-[#f0f0f0]">
                                <td colSpan="4">
                                    <NoRecordsFound />
                                </td>
                            </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <Modal modal={modal} setModal={setModal} classes={{ modal: "h-[90vh] max-w-[1140px]", content: "max-md:overflow-y-scroll overflow-x-hidden h-[95%]"}}>
            <StartTaskModal data={modalData} />
        </Modal>
    </>
  )
}

export default TaskTable