import { useEffect, useState } from 'react'
import {cookieName, MemberFormOperationReasonType} from '../../consts/Constants';
import { GetMemberNoteTitlesByStatus } from '../../services/Member/MemberFormService';
import {
    GetBrands, GetTeamLeaders, GetEmployeeTypes, GetEmployeeStatus, GetMemberFormStatus,
    GetMemberResource, GetEmployees, GetLogCategories, GetTeachers, GetTeachersWithJosiah, GetProfessions,
    GetNotTitles, GetTags, GetMemberDemandTypes, GetTransferStatuses, GetProductSelectList,
    GetSalesTypes, GetPaymentTypes, GetSourceTypes, GetAllBrandLocalCompanies, GetExerciseTypes, GetWorkingTimes, GetBrandLocalCompanyDetails,
} from '../../services/PrimeData/PrimeDataService';
import {getCookie} from "ko-basic-cookie";
import {addTimeZone, decodeToken} from "../../components/Utility";
import {GetTeacherListWithShift} from "../../services/Teacher/TeacherService";
import {GetHolidayList} from "../../services/Member/MemberHolidayService";

const usePrimeData = () => {

    //Timezone gibi değişkenlerin kullanılacağı servisler için entegre edildi.
    const token = getCookie(cookieName);
    const employee = token && decodeToken(token);

    const [brandList, setBrandList] = useState([]);
    const [teamLeadList, setTeamLeadList] = useState([]);
    const [employeeTypeList, setEmployeeTypeList] = useState([]);
    const [employeeStatusList, setEmployeeStatusList] = useState([]);
    const [memberFormStatusList, setMemberFormStatusList] = useState([]);
    const [memberResourceList, setMemberResourceList] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const [logCategoryList, setLogCategoryList] = useState([]);
    const [teacherList, setTeacherList] = useState([]);
    const [teacherListWithJosiah, setTeacherListWithJosiah] = useState([]);
    const [teacherListWithShifts, setTeacherListWithShifts] = useState([]);
    const [workingTimeList, setWorkingTimeList] = useState([]);
    const [professionList, setProfessionList] = useState([]);
    const [tagList, setTagList] = useState([]);
    const [noteTitleList, setNoteTitleList] = useState([]);
    const [dropPoolReasonList, setDropPoolReasonList] = useState([]);
    const [reminderReasonList, setReminderReasonList] = useState([]);
    const [memberDemandTypeList, setMemberDemandTypeList] = useState([]);
    const [transferStatusList, setTransferStatusList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [salesTypeList, setSalesTypeList] = useState([]);
    const [paymentTypeList, setPaymentTypeList] = useState([]);
    const [sourceTypeList, setSourceTypeList] = useState([]);
    const [exerciseTypeList, setExerciseTypeList] = useState([]);
    const [localCompanyList, setLocalCompanyList] = useState([]);
    const [localCompanyDetails, setLocalCompanyDetails] = useState([]);
    const [holidayList, setHolidayList] = useState([]);

    //brand işlemleri
    const getBrands = async () => {
        const result = await GetBrands();

        let arrayList = [];

        if (result.status === 200) {
            const data = JSON.parse(result.content);
            data.map((item) => {
                let arrayItem = { id: parseInt(item.value), name: item.text };
                arrayList.push(arrayItem);
            })
        }

        setBrandList(brandList => arrayList);
        //fonksiyonu direkt çağıracak bölümler için return ediyoruz
        return arrayList;

    }

    //Takım lideri bölümü işlemleri
    const getTeamLeads = async () => {
        const result = await GetTeamLeaders();

        let arrayList = [];

        if (result.status === 200) {
            const data = JSON.parse(result.content);
            data.map((item) => {
                let arrayItem = { id: item.value, name: item.text };
                arrayList.push(arrayItem);
            })
        }

        setTeamLeadList(teamLeadList => arrayList);
        //fonksiyonu direkt çağıracak bölümler için return ediyoruz
        return arrayList;

    }

    //Çalışan bölümü işlemleri
    const getEmployeeTypes = async () => {
        const result = await GetEmployeeTypes();

        let arrayList = [];

        if (result.status === 200) {
            const data = JSON.parse(result.content);
            data.map((item) => {
                let arrayItem = { id: parseInt(item.value), name: item.text };
                arrayList.push(arrayItem);
            })
        }

        setEmployeeTypeList(employeeTypeList => arrayList);
        //fonksiyonu direkt çağıracak bölümler için return ediyoruz
        return arrayList;

    }

    //Çalışan durumu işlemleri
    const getEmployeeStatus = async () => {
        const result = await GetEmployeeStatus();

        let arrayList = [];

        if (result.status === 200) {
            const data = JSON.parse(result.content);
            data.map((item) => {
                let arrayItem = { id: parseInt(item.value), name: item.text };
                arrayList.push(arrayItem);
            })
        }

        setEmployeeStatusList(employeeStatusList => arrayList);
        //fonksiyonu direkt çağıracak bölümler için return ediyoruz
        return arrayList;

    }

    //Üye form durumu işlemleri
    const getMemberFormStatus = async () => {
        const result = await GetMemberFormStatus();

        let arrayList = [];

        if (result.status === 200) {
            const data = JSON.parse(result.content);
            data.map((item) => {
                // Havuzdan Aday Çekildi, Hatırlatma Silindi, Hatırlatma Güncellendi
                // Durumları listeden çıkarılıyor
                if(+item.value !== 4 && +item.value !== 6 && +item.value !== 7) {
                    let arrayItem = { id: parseInt(item.value), name: item.text };
                    arrayList.push(arrayItem);
                }
            })
        }

        setMemberFormStatusList(memberFormStatusList => arrayList);
        //fonksiyonu direkt çağıracak bölümler için return ediyoruz
        return arrayList;
    }

    //Üye kaynakları işlemleri
    const getMemberResource = async () => {
        const result = await GetMemberResource();

        let arrayList = [];

        if (result.status === 200) {
            const data = JSON.parse(result.content);
            data.map((item) => {
                let arrayItem = { id: item.value, name: item.text };
                arrayList.push(arrayItem);
            })
        }

        setMemberResourceList(memberResourceList => arrayList);
        //fonksiyonu direkt çağıracak bölümler için return ediyoruz
        return arrayList;

    }

    //Kullanıcı listesi işlemleri
    const getEmployees = async () => {
        const result = await GetEmployees();

        let arrayList = [];

        if (result.status === 200) {
            const data = JSON.parse(result.content);
            data.map((item) => {
                let arrayItem = { id: item.value, name: item.text };
                arrayList.push(arrayItem);
            })
        }

        setEmployeeList(employeeList => arrayList);
        //fonksiyonu direkt çağıracak bölümler için return ediyoruz
        return arrayList;

    }

    //Log kategori listesi işlemleri
    const getLogCategories = async () => {
        const result = await GetLogCategories();
        let arrayList = [];

        if (result.status === 200) {
            const data = JSON.parse(result.content);
            data.map((item) => {
                let arrayItem = { id: parseInt(item.value), name: item.text };
                arrayList.push(arrayItem);
            })
        }

        setLogCategoryList(logCategoryList => arrayList);
        //fonksiyonu direkt çağıracak bölümler için return ediyoruz
        return arrayList;

    }

    //Eğitmen listesi işlemleri
    const getTeachers = async () => {
        const result = await GetTeachers();
        let arrayList = [];

        if (result.status === 200) {
            const data = JSON.parse(result.content);
            data.map((item) => {
                let arrayItem = { id: parseInt(item.value), name: item.text };
                arrayList.push(arrayItem);
            })
        }

        setTeacherList(teacherList => arrayList);
        //fonksiyonu direkt çağıracak bölümler için return ediyoruz
        return arrayList;

    }

    const getTeachersWithJosiah = async () => {
        const result = await GetTeachersWithJosiah();
        let arrayList = [];

        if (result.status === 200) {
            const data = JSON.parse(result.content);
            data.map((item) => {
                let arrayItem = {id: parseInt(item.value), name: item.text};
                arrayList.push(arrayItem);
            })
        }

        setTeacherListWithJosiah(teacherListWithJosiah => arrayList);
        //fonksiyonu direkt çağıracak bölümler için return ediyoruz
        return arrayList;

    }

    const getTeacherWithShifts = async (timeZone) => {
        const result = await GetTeacherListWithShift();
        let arrayList = [];

        if (result.status === 200) {
            const data = JSON.parse(result.content);

            data.map((teacher) => {

                let tmpName = teacher.teacherName;

                if(teacher.times.length === 0) {
                    tmpName += " (Çalışmıyor)"
                } else {

                    tmpName += " (";

                    teacher.times.map((time, index) => {
                        tmpName += addTimeZone(time.beginTime, timeZone) + " - " + addTimeZone(time.endTime, timeZone);
                        if(teacher.times.length - 1 !== index)
                            tmpName += ", "
                    })

                    tmpName += ")";
                }

                let arrayItem = {
                    id: parseInt(teacher.teacherId),
                    name: tmpName
                };
                arrayList.push(arrayItem);
            })
        }

        setTeacherListWithShifts(teacherData => arrayList);
        //fonksiyonu direkt çağıracak bölümler için return ediyoruz
        return arrayList;
    }


    //Eğitmen listesi işlemleri
    const getWorkingTimeList = async () => {
        const result = await GetWorkingTimes();
        let arrayList = [];

        if (result.status === 200) {
            const data = JSON.parse(result.content);
            data.map((item) => {
                let arrayItem = { id: item, name: item };
                arrayList.push(arrayItem);
            })
        }

        setWorkingTimeList(workingTimeList => arrayList);
        //fonksiyonu direkt çağıracak bölümler için return ediyoruz
        return arrayList;

    }



    //Meslek listesi işlemleri
    const getProfessions = async () => {
        const result = await GetProfessions();
        let arrayList = [];

        if (result.status === 200) {
            const data = JSON.parse(result.content);
            data.map((item) => {
                let arrayItem = { id: parseInt(item.value), name: item.text };
                arrayList.push(arrayItem);
            })
        }

        setProfessionList(professionList => arrayList);
        //fonksiyonu direkt çağıracak bölümler için return ediyoruz
        return arrayList;

    }

    //Meslek listesi işlemleri
    const getTags = async () => {
        const result = await GetTags();
        let arrayList = [];

        if (result.status === 200) {
            const data = JSON.parse(result.content);
            data.map((item) => {
                let arrayItem = { id: parseInt(item.value), name: item.text };
                arrayList.push(arrayItem);
            })
        }

        setTagList(tagList => arrayList);
        //fonksiyonu direkt çağıracak bölümler için return ediyoruz
        return arrayList;

    }

    //Not listesi işlemleri
    const getNoteTitles = async () => {
        const result = await GetNotTitles();
        let arrayList = [];

        if (result.status === 200) {
            const data = JSON.parse(result.content);
            data.map((item) => {
                let arrayItem = { id: parseInt(item.value), name: item.text };
                arrayList.push(arrayItem);
            })
        }

        setNoteTitleList(noteTitleList => arrayList);
        //fonksiyonu direkt çağıracak bölümler için return ediyoruz
        return arrayList;

    }

    //Not listesi işlemleri
    const getDropPoolReasonList = async () => {
        const result = await GetMemberNoteTitlesByStatus(MemberFormOperationReasonType["Drop"]);
        let arrayList = [];

        if (result.status === 200) {
            const data = JSON.parse(result.content);
            data.map((item) => {
                let arrayItem = { id: parseInt(item.value), name: item.text };
                arrayList.push(arrayItem);
            })
        }

        setDropPoolReasonList(dropPoolReasonList => arrayList);
        //fonksiyonu direkt çağıracak bölümler için return ediyoruz
        return arrayList;

    }

    //Not listesi işlemleri
    const getReminderReasonList = async () => {
        const result = await GetMemberNoteTitlesByStatus(MemberFormOperationReasonType["Reminder"]);
        let arrayList = [];

        if (result.status === 200) {
            const data = JSON.parse(result.content);
            data.map((item) => {
                let arrayItem = { id: parseInt(item.value), name: item.text };
                arrayList.push(arrayItem);
            })
        }

        setReminderReasonList(reminderReasonList => arrayList);
        //fonksiyonu direkt çağıracak bölümler için return ediyoruz
        return arrayList;

    }

    //Öğrenci istekleri listesi işlemleri
    const getMemberDemandTypeList = async () => {
        const result = await GetMemberDemandTypes();
        let arrayList = [];

        if (result.status === 200) {
            const data = JSON.parse(result.content);
            data.map((item) => {
                let arrayItem = { id: parseInt(item.value), name: item.text };
                arrayList.push(arrayItem);
            })
        }

        setMemberDemandTypeList(memberDemandTypeList => arrayList);
        //fonksiyonu direkt çağıracak bölümler için return ediyoruz
        return arrayList;

    }

    //Öğrenci transfer durum listesi işlemleri
    const getTransferStatuses = async () => {
        const result = await GetTransferStatuses();
        let arrayList = [];

        if (result.status === 200) {
            const data = JSON.parse(result.content);
            data.map((item) => {
                let arrayItem = { id: parseInt(item.value), name: item.text };
                arrayList.push(arrayItem);
            })
        }

        setTransferStatusList(transferStatusList => arrayList);
        //fonksiyonu direkt çağıracak bölümler için return ediyoruz
        return arrayList;

    }

    //Ürün listesi işlemleri
    const getProductSelectList = async () => {
        const result = await GetProductSelectList();
        let arrayList = [];

        if (result.status === 200) {
            const data = JSON.parse(result.content);
            data.map((item) => {
                let arrayItem = { id: item.value, name: item.text };
                arrayList.push(arrayItem);
            })
        }

        setProductList(productList => arrayList);
        //fonksiyonu direkt çağıracak bölümler için return ediyoruz
        return arrayList;

    }

    //Satış tipi listesi işlemleri
    const getSalesTypeList = async () => {
        const result = await GetSalesTypes();
        let arrayList = [];

        if (result.status === 200) {
            const data = JSON.parse(result.content);
            data.map((item) => {
                let arrayItem = { id: parseInt(item.value), name: item.text };
                arrayList.push(arrayItem);
            })
        }

        setSalesTypeList(salesTypeList => arrayList);
        //fonksiyonu direkt çağıracak bölümler için return ediyoruz
        return arrayList;

    }

    //Ödeme tipi listesi işlemleri
    const getPaymentTypeList = async () => {
        const result = await GetPaymentTypes();
        let arrayList = [];

        if (result.status === 200) {
            const data = JSON.parse(result.content);
            data.map((item) => {
                let arrayItem = { id: parseInt(item.value), name: item.text };
                arrayList.push(arrayItem);
            })
        }

        setPaymentTypeList(paymentTypeList => arrayList);
        //fonksiyonu direkt çağıracak bölümler için return ediyoruz
        return arrayList;

    }

    //Kaynak tipi listesi işlemleri
    const getSourceTypeList = async () => {
        const result = await GetSourceTypes();
        let arrayList = [];

        if (result.status === 200) {
            const data = JSON.parse(result.content);
            data.map((item) => {
                let arrayItem = { id: parseInt(item.value), name: item.text };
                arrayList.push(arrayItem);
            })
        }

        setSourceTypeList(sourceTypeList => arrayList);
        //fonksiyonu direkt çağıracak bölümler için return ediyoruz
        return arrayList;

    }

    //Egzersiz tipi listesi işlemleri
    const getExerciseTypeList = async () => {
        const result = await GetExerciseTypes();
        let arrayList = [];

        if (result.status === 200) {
            const data = JSON.parse(result.content);
            data.map((item) => {
                let arrayItem = { id: parseInt(item.value), name: item.text };
                arrayList.push(arrayItem);
            })
        }

        setExerciseTypeList(exerciseTypeList => arrayList);
        //fonksiyonu direkt çağıracak bölümler için return ediyoruz
        return arrayList;

    }



    //Yerel şirket listesi işlemleri
    const getAllLocalBrandCompanyList = async () => {
        const result = await GetAllBrandLocalCompanies();
        let arrayList = [];

        if (result.status === 200) {
            const data = JSON.parse(result.content);
            data.map((item) => {
                let arrayItem = { id: parseInt(item.id), name: item.name };
                arrayList.push(arrayItem);
            })
        }

        setLocalCompanyList(localCompanyList => arrayList);
        //fonksiyonu direkt çağıracak bölümler için return ediyoruz
        return arrayList;

    }

    //Şirketlerin detayları getiriliyor.
    const getLocalBrandCompanyDetails = async () => {

        const result = await GetBrandLocalCompanyDetails();
        let arrayList = [];

        if(result.status === 200){
            let resultContent = JSON.parse(result.content);
            arrayList = resultContent;
        }

        setLocalCompanyDetails(localCompanyDetails => arrayList);
        return arrayList;
    }

    //Tatil günleri getiriliyor.
    const getHolidayList = async () => {
        const result = await GetHolidayList()
        let arrayList = [];

        if (result.status === 200) {
            let resultContent = JSON.parse(result.content);
            arrayList = resultContent;
        }

        setHolidayList(holidayList => arrayList);
        return arrayList;
    }

    useEffect(() => {
        getBrands();
        getTeamLeads();
        getEmployeeTypes();
        getEmployeeStatus();
        getMemberFormStatus();
        getMemberResource();
        getEmployees();
        getLogCategories();
        getTeachers();
        getTeachersWithJosiah();
        getProfessions();
        getTags();
        getNoteTitles();
        getDropPoolReasonList();
        getReminderReasonList();
        getMemberDemandTypeList();
        getTransferStatuses();
        getProductSelectList();
        getSalesTypeList();
        getPaymentTypeList();
        getSourceTypeList();
        getExerciseTypeList();
        getAllLocalBrandCompanyList();
        getWorkingTimeList();
        getLocalBrandCompanyDetails();
        getHolidayList();
    }, [])

    useEffect(() => {
        getTeacherWithShifts(employee.TimeZone);
    }, []);

    return {
        brandList, teamLeadList, employeeTypeList, employeeStatusList, memberFormStatusList,
        memberResourceList, employeeList, logCategoryList, teacherList, teacherListWithJosiah, teacherListWithShifts,
        professionList, tagList, noteTitleList, dropPoolReasonList, reminderReasonList,
        memberDemandTypeList, transferStatusList, productList, salesTypeList, paymentTypeList,
        sourceTypeList, localCompanyList, exerciseTypeList, workingTimeList, localCompanyDetails,
        holidayList,

        getBrands, getTeamLeads, getEmployeeTypes, getEmployeeStatus, getMemberFormStatus,
        getMemberResource, getEmployees, getLogCategories, getTeachers, getTeachersWithJosiah, getTeacherWithShifts, getProfessions,
        getTags, getNoteTitles, getDropPoolReasonList, getReminderReasonList, getMemberDemandTypeList,
        getTransferStatuses, getProductSelectList, getSalesTypeList, getPaymentTypeList, getSourceTypeList,
        getAllLocalBrandCompanyList, getExerciseTypeList, getWorkingTimeList, getLocalBrandCompanyDetails,
        getHolidayList
    }
}

export default usePrimeData