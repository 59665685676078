import { useState, useEffect } from 'react';
import {GetMemberBrandId} from "../../services/Member/MemberService";

const useMemberBrand = (memberId) => {

    const [memberBrand, setMemberBrand] = useState(-1);

    useEffect(() => {
        const getMemberBrandId = async () => {
            const result = await GetMemberBrandId(memberId);
            if (result.status === 200) {
                let status = parseInt(result.content);
                setMemberBrand(status);
            }
        };
        getMemberBrandId();
    }, [memberId]);

    return memberBrand;
};

export default useMemberBrand;