import {Loading, NoRecordsFound, PageTitle} from "../../components";
import { useLocalization } from "../../hooks/Utils/useLocalization";
import { useEffect, useState } from "react";
import { UseCaseTable } from "./components";
import {UseCaseListService} from "../../services/UseCase/UseCaseService";

const UseCaseList = () => {

    const strings = useLocalization();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const getUseCaseList = async () => {

        const result = await UseCaseListService();

        if(result.status === 200) {
            const resultContent = JSON.parse(result.content);

            setData(data => resultContent);
            setLoading(loading => false);
        }
    }

    useEffect(() => {
        getUseCaseList();
    }, []);

    return(
        <>
            <PageTitle title={strings.sidebar.use_case.list} />
            {loading && (
                <Loading />
            )}
            {!loading && (
                <>
                    {data.length > 0 && (
                        <UseCaseTable data={data} />
                    )}

                    {data.length === 0 && (
                        <NoRecordsFound />
                    )}
                </>
            )}
        </>
    )
}

export default UseCaseList;