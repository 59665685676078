import {useEffect, useRef, useState} from "react";
import {Dropdown, Input} from "../../../../components";
import {useLocalization} from "../../../../hooks/Utils/useLocalization";
import {LanguageList} from "../../../../consts/Constants";
import {findDifferentElements} from "../../../../components/Utility";

const TranslationRowEditable = ({ item, translations, setTranslations, isClickedUpdateButton }) => {

    const strings = useLocalization();

    const languageErrorRef = useRef();
    const titleTranslationErrorRef = useRef();
    const titleInformationErrorRef = useRef();

    const firstLanguage = { id: -1, name: strings.detail_search_titles.choose_language };
    const [languageList, setLanguageList] = useState([firstLanguage, ...LanguageList]);

    const [selectedTitleTranslationLanguage, setSelectedTitleTranslationLanguage] = useState(firstLanguage);

    //Başlık alanı değişikliği kontrol eder ve translations array'ini günceller
    const handleTitleChange = (event) => {
        const updatedTranslations = translations.map((translation) =>
            translation.id === item.id
                ? { ...translation, title: event.target.value }
                : translation
        );
        setTranslations(updatedTranslations);
    };

    //Bilgi alanı değişikliği kontrol eder ve translations array'ini günceller
    const handleInformationChange = (event) => {
        const updatedTranslations = translations.map((translation) =>
            translation.id === item.id
                ? { ...translation, information: event.target.value }
                : translation
        );
        setTranslations(updatedTranslations);
    };

    //Dil dropdown'ında ki değişikliği kontrol eder ve translations array'ini günceller
    const handleTranslationLanguageChange = (itemId, newLanguageId) => {
        const updatedTranslations = translations.map(translation =>
            translation.id === itemId
                ? { ...translation, languageId: newLanguageId }
                : translation
        );

        setTranslations(updatedTranslations);
    };

    //İlgili veriyi translations listesinden siler
    const removeTranslation = (languageId) => {
        const updatedTranslations = translations.filter(item => item.languageId !== languageId);
        setTranslations(updatedTranslations);
    }

    //Inputların ve dil listesinin validasyonlarını kontrol eder.
    const validate = () =>  {

        let valid = true;

        languageErrorRef.current.innerText = '';
        titleTranslationErrorRef.current.innerText = '';
        titleInformationErrorRef.current.innerText = '';

        if(selectedTitleTranslationLanguage.id === -1) {
            valid = false;
            languageErrorRef.current.innerText = strings.errors.language_null_error;
        }
        if(item.title.length === 0) {
            valid = false;
            titleTranslationErrorRef.current.innerText = strings.errors.translation_title_null_error;
        }
        if(item.information.length === 0) {
            valid = false;
            titleInformationErrorRef.current.innerText = strings.errors.information_null_error;
        }

        return valid;
    }

    //Sayfa ilk yüklendiğinde seçili languageId'ye göre titleTranslations state'ini günceller ve ekranda seçili görünmesini sağlar.
    useEffect(() => {
        setSelectedTitleTranslationLanguage(selectedTitleTranslationLanguage=>  languageList.find(language => language.id === item.languageId));
    }, []);

    //Seçili dil değiştikçe translations dizisini günceller
    useEffect(() => {
        handleTranslationLanguageChange(item.id, selectedTitleTranslationLanguage.id);
    }, [selectedTitleTranslationLanguage]);

    /*
        Buradaki amaç seçili dilleri dinamik olarak güncelemektir.
        Örneğin; translations listesinde olan diller Türkçe ve Azerice ise dil seçme
        listesine sadece arapça gönderiyoruz.

        Adımları aşağıda anlatılmıştır.
    */
    useEffect(() => {
        let translationLanguageIds = [];
        let languageListIds= [];

        //Translations dizisinden gelen languageId'ler bir array'e atılıyor.
        translations.map((translation) => {
            translationLanguageIds.push(translation.languageId);
        })

        //Statik dil listesinden gelen id'ler bir array'e atılıyor
        LanguageList.map((language) => {
            languageListIds.push(language.id);
        })

        //Bu iki array arasındaki farklılıklar bulunuyor.
        const differences = findDifferentElements(translationLanguageIds, languageListIds);

        //Farklılık yoksa tüm diller kullanıcıya gösteriliyor.
        if(differences.length === 0) {
            setLanguageList([firstLanguage, ...LanguageList.filter(language => language.id === selectedTitleTranslationLanguage.id)])
        }
        //Farklılık varsa önce seçili dil ve farklı olan diller bir arraye atılıyor
        //Bu arrayde eşleşen değerler kullanıcının önüne çıkarılır
        else {
            const tmpDifferenceArr = [selectedTitleTranslationLanguage.id, ...differences];
            const matchingLanguages = LanguageList.filter(language => tmpDifferenceArr.includes(language.id));
            setLanguageList([firstLanguage, ...matchingLanguages]);
        }
    }, [translations, selectedTitleTranslationLanguage]);

    useEffect(() => {
        //Güncelle butonuna basıldıktan sonra validasyonları kontrol etmeye başlar ve hata mesajları gösterilir
        isClickedUpdateButton && validate();
    }, [item, isClickedUpdateButton, translations]);

    return (
        <>
            <div className="flex flex-col xl:flex-row items-center justify-start xl:items-start gap-3 px-5 max-xs:px-2 transition-max-height duration-500">
                <div className="flex flex-col pb-0 mt-5 w-full">
                    <div className="flex flex-col pb-0 ">
                        <label>
                            {strings.form_info.language}
                        </label>
                        <Dropdown
                            data={languageList || []}
                            selectedData={selectedTitleTranslationLanguage}
                            setSelectedData={setSelectedTitleTranslationLanguage}
                            classnames="md:max-w-full"
                        />
                        <span ref={languageErrorRef} className="text-danger field-validation-valid ml-2 mt-1"></span>
                    </div>
                </div>
                <div className="flex flex-col pb-0 mt-5 w-full">
                    <label htmlFor="title-translations">
                        {strings.form_info.title_translation}
                    </label>
                    <div className="flex flex-col pb-0 ">
                        <Input
                            id="title-translations"
                            type="text"
                            classnames="md:max-w-full"
                            value={item.title}
                            onChange={(e) => {handleTitleChange(e)}}
                        />
                        <span ref={titleTranslationErrorRef} className="text-danger field-validation-valid ml-2 mt-1"></span>
                    </div>
                </div>
                <div className="flex flex-col pb-0 mt-5 w-full">
                    <label htmlFor="title-translation-information">
                        {strings.form_info.information}
                    </label>
                    <div className="flex flex-col pb-0 ">
                        <Input
                            id="title-translation-information"
                            type="text"
                            classnames="md:max-w-full"
                            value={item.information}
                            onChange={(e) => handleInformationChange(e)}
                        />
                        <span ref={titleInformationErrorRef} className="text-danger field-validation-valid ml-2 mt-1"></span>
                    </div>
                </div>
                <div className="flex gap-1">
                    <button
                        type="button"
                        className="flex-shrink-0 xl:mb-2 mt-2 group inline-flex items-center justify-center bg-transparent border border-red text-red w-8 h-8 rounded-[3px] relative xl:mt-[52px]"
                        onClick={() => removeTranslation(item.languageId)}
                    >
                        <span className="material-symbols-outlined text-[18px]">delete</span>
                        <div className="tooltip-top">
                            <p>{strings.button.delete}</p>
                        </div>
                    </button>
                </div>
            </div>
        </>
    )
}

export default TranslationRowEditable;