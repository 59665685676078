import {NoRecordsFound} from "../../../../components";
import {useLocalization} from "../../../../hooks/Utils/useLocalization";
import {UseCaseHistoryTableRow} from "../index";

const UseCaseHistoryTable = ({ data }) => {

    const strings = useLocalization();

    return(
        <div className="mx-auto px-4 my-4">
            <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
                <div className="inline-block min-w-full rounded-lg overflow-hidden">
                    <table className="min-w-full leading-normal text-header">
                        <thead>
                        <tr>
                            <th className="th min-w-max">{strings.member.member_detail.terms.student_name}</th>
                            <th className="th min-w-max">{strings.table.use_case_title}</th>
                            <th className="th min-w-max">{strings.table.date}</th>
                            <th className="th min-w-max">{strings.table.operation}</th>
                        </tr>
                        </thead>
                        <tbody>
                        { data.length > 0 ?
                            (
                                data.map((history,index) => (
                                    <UseCaseHistoryTableRow
                                        key={index}
                                        history={history}
                                    />
                                ))
                            )
                            :
                            (
                                <tr className="bg-white hover:bg-[#f0f0f0]">
                                    <td colSpan="4">
                                        <NoRecordsFound />
                                    </td>
                                </tr>
                            )
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    );
}

export default UseCaseHistoryTable;