import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { CopyToClipboard, showCenteredAlertTwoButtons, showTopAlert, SquareButton } from "../../../../components";
import {
    addTimeZoneToFullDate,
    getBrandNameById,
    getLogoByBrandId,
    isItSpecified,
    maskPhoneNumber
} from "../../../../components/Utility";
import {MemberApplicationStatus, ServiceErrorMessages} from "../../../../consts/Constants";
import { ClickLogs } from "../../../../consts/Logs";
import useAnalytics from "../../../../hooks/Log/useAnalytics";
import useAuth from "../../../../hooks/Log/useAuth";
import { useLocalization } from "../../../../hooks/Utils/useLocalization";
import { url } from "../../../../routes/utility";
import { UpdateCandidateAfterWhatsAppContact } from "../../../../services/Member/MemberPoolService";
import { applicationStatusColorPicker } from "../../../Member/components/Common/GetPerson";
import classNames from "classnames";

const CandidatePoolReportTableRow = (props) => {

    const { member, setModal, setModalContent, showWhatsappReturnButton } = props;

    const strings = useLocalization();
    const navigate = useNavigate();
    const analytics = useAnalytics();
    const { employee } = useAuth();
    const notSpecified = strings.errors.it_is_not_specified;

    const updateCandidateAfterWhatsAppContact = async () => {
        const model = {
            memberId: member.memberId,
            currentEmployeeId: employee.EmployeeId,
        }

        const result = await UpdateCandidateAfterWhatsAppContact(model);
        if (result.status === 200) {
            analytics.useAnalytics(ClickLogs.WHATSAPP_RETURN_BUTTON_CLICKED, { memberId: member.memberId });
            showTopAlert(strings.alert.top_alert.success + strings.member.candidate_pool.redirect_member_detail, "success");
            setTimeout(() => navigate(url("member.detail.profile", { memberId: member.memberId })), 1000);
        } else {
            if(result.content === ServiceErrorMessages.MEMBER_NOT_FOUND)
                showTopAlert(strings.report.candidate_reports.returned_from_whatsapp_error_messages.member_not_found,"error");
            else if(result.content === ServiceErrorMessages.BRAND_CANNOT_BE_ZERO)
                showTopAlert(strings.member.candidate.brand_id_error,"error");
            else if(result.content === ServiceErrorMessages.MEMBER_ALREADY_HAS_ACTIVE_SALES)
                showTopAlert(strings.member.candidate.member_has_already_active_sales,"error");
            else if(result.content === ServiceErrorMessages.EMPLOYEE_NOT_FOUND)
                showTopAlert(strings.report.candidate_reports.returned_from_whatsapp_error_messages.employee_not_found,"error");
            else if(result.content === ServiceErrorMessages.EMPLOYEE_STATUS_MUST_BE_ACTIVE)
                showTopAlert(strings.report.candidate_reports.returned_from_whatsapp_error_messages.employee_status_must_be_active,"error");
            else if(result.content === ServiceErrorMessages.SALESPERSON_NOT_ASSIGNED)
                showTopAlert(strings.report.candidate_reports.returned_from_whatsapp_error_messages.salesperson_not_assigned,"error");
            else
                showTopAlert(strings.errors.an_error_occurred,"error");
        }
    }

    return (
        <tr className="bg-white hover:bg-[#f0f0f0]">
            <td className="td align-middle">
                <div className="group relative max-w-max ml-6">
                    <img src={getLogoByBrandId(member.brand.id)} width="43" height="48" alt="" />
                    <div className="tooltip-top">
                        <p>{getBrandNameById(member.brand.id)}</p>
                    </div>
                </div>
            </td>
            <td className="td align-middle">
                <div className="flex items-center">
                    <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">person</span>
                    <Link
                        to={url("member.detail.profile", { memberId: member?.memberId })}
                        className="font-bold min-w-max text-blue hover:underline"
                        target="_blank"
                    >
                        {member.fullName}
                    </Link>
                </div>
                <div className="flex items-center">
                    <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">call</span>
                    <div className="flex items-center gap-0.5">
                        <p>{member.phone ? maskPhoneNumber(member.phone) : notSpecified}</p>
                        <CopyToClipboard text={member.phone} className="-mb-1" iconClassName="!text-[13px]" />
                    </div>
                </div>
                <div className="flex items-center">
                    <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">mail</span>
                    <p>{isItSpecified(member.email, notSpecified)}</p>
                </div>
            </td>
            <td className="td align-middle">
                <button
                    type="button"
                    className={`badge-color border-none focus:border-none hover:border-none min-w-[200px] my-1 ${MemberApplicationStatus.NewRegistration !== member.applicationStatus ? 'cursor-pointer' : 'cursor-default'}  ${applicationStatusColorPicker(member.applicationStatus)} uppercase`}
                    onClick={() => {
                        if (MemberApplicationStatus.NewRegistration !== member.applicationStatus) {
                            setModal(true); setModalContent({ memberId: member.memberId, element: "log" })
                        }
                    }}
                >
                    <p>{member.applicationStatus}</p>
                </button>
                <div className="flex items-center max-w-max">
                    <div className="relative group">
                        <span
                            className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
                            equalizer
                        </span>
                        {member?.level && (
                            <div className="tooltip-top">
                                {strings.member.member_detail.terms.level}
                            </div>
                        )}
                    </div>
                    <p>{isItSpecified(member.level, notSpecified)}</p>
                </div>
                <div className="flex items-center max-w-max">
                    <div className="relative group">
                        <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
                            language
                        </span>
                        {member?.reasonToLearnEnglish && (
                            <div className="tooltip-top">
                                {strings.form_info.use_case}
                            </div>
                        )}
                    </div>
                    <div className="relative group">
                        <button
                            type="button"
                            className={classNames("min-w-max", {
                                "text-blue hover:underline font-bold": member?.reasonToLearnEnglish !== "Belirtilmedi",
                                "cursor-text": !member?.reasonToLearnEnglish !== "Belirtilmedi"
                            })}
                            onClick={() => {
                                if (member?.reasonToLearnEnglish !== "Belirtilmedi") {
                                    setModal(true);
                                    setModalContent({ memberId: member.memberId, element: "reasonToLearnEnglish" });
                                }
                            }}
                        >
                            <p className="mb-1">{isItSpecified(member?.reasonToLearnEnglish, notSpecified)}</p>
                        </button>
                        {member?.reasonToLearnEnglish !== "Belirtilmedi" &&
                            <div className="tooltip-top">
                                {strings.member.member_detail.profile.click_for_see_all_reasons}
                            </div>
                        }
                    </div>
                </div>
                {member?.utmSource && (
                    <div className="flex items-center max-w-max">
                        <div className="relative group">
                            <span
                                className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
                                ads_click
                            </span>
                            {member?.utmSource && (
                                <div className="tooltip-top">
                                    Utm Source
                                </div>
                            )}
                        </div>
                        <p className="mb-1">{member.utmSource}</p>
                    </div>
                )}
                {member?.landingPage && (
                    <div className="flex items-center max-w-max">
                        <div className="relative group">
                            <span
                                className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
                                link
                            </span>
                            {member?.landingPage && (
                                <div className="tooltip-top">
                                    Landing Page
                                </div>
                            )}
                        </div>
                        <p className="mb-1.5">{member.landingPage}</p>
                    </div>
                )}
                {member?.utmMedium && (
                    <div className="flex items-center max-w-max">
                        <div className="relative group">
                            <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
                                travel_explore
                            </span>
                            {member?.utmMedium && (
                                <div className="tooltip-top">
                                    Utm Medium
                                </div>
                            )}
                        </div>
                        <p className="mb-1">{member.utmMedium}</p>
                    </div>
                )}
                {member?.utmContent && (
                    <div className="flex items-center max-w-max">
                        <div className="relative group">
                            <span
                                className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
                                note_stack
                            </span>
                            {member?.utmContent && (
                                <div className="tooltip-top">
                                    Utm Content
                                </div>
                            )}
                        </div>
                        <p className="mb-1">{member.utmContent}</p>
                    </div>
                )}
            </td>
            <td className="td align-middle">
                <div className="badge max-w-[340px] my-1 text-center min-w-max">
                    <p><b>{strings.table.apply_date} </b>{addTimeZoneToFullDate(member.beginDate, employee.TimeZone)}</p>
                </div>
                <div className="badge max-w-[340px] my-1 text-center min-w-max">
                    <p><b>{strings.table.update_date} </b>{addTimeZoneToFullDate(member.endDate, employee.TimeZone)}</p>
                </div>
            </td>

            {showWhatsappReturnButton && (
                <td className="td align-middle">
                    <SquareButton
                        classnames="bg-green"
                        iconName="done"
                        tooltip={strings.member.candidate_pool.whatsapp}
                        action={() => (
                            showCenteredAlertTwoButtons(
                                strings.alert.centered_alert_two_buttons.title,
                                strings.member.candidate_pool.transfer_to_candidate_confirmation,
                                strings.button.confirm,
                                strings.button.cancel,
                                updateCandidateAfterWhatsAppContact
                            ))
                        }
                    />
                </td>
            )}
        </tr>
    )
}

export default CandidatePoolReportTableRow