import React, { useState, useEffect } from "react";
import { Dropdown, showTopAlert } from "../../../../../../components";
import { getMemberDeviceOS } from "../../../../../../components/Utility";
import { useLocalization } from "../../../../../../hooks/Utils/useLocalization";
import { GetAppLessonTypes } from "../../../../../../services/Member/MemberAppLessonTypeService";
import { GetMemberDevices } from "../../../../../../services/Member/MemberDeviceService";
import { CreateMemberLessonApp, GetApplicationsByBrandId } from "../../../../../../services/Member/MemberLessonAppService";
import { ApplicationName, DeviceOSName } from "../../../../../../consts/Constants";
import useAnalytics from "../../../../../../hooks/Log/useAnalytics";
import { ComponentViewLogs } from "../../../../../../consts/Logs";

const AddCallTypeModal = (props) => {

  const { closeButton, memberId, sendRequestToEndpoint, brandId } = props;
  const strings = useLocalization();
  const analytics = useAnalytics();

  //Servisten brandId'ye göre gelen uygulama listesi
  const [applications, setApplications] = useState([]);
  const firstApplication = { id: -1, name: strings.member.member_detail.others.choose_application };
  const applicationList = [firstApplication, ...applications];

  const [appLessonTypeArray, setAppLessonTypeArray] = useState([]);
  const firstAppLessonType = { id: -1, name: strings.member.member_detail.others.choose_substructure };
  //Servisten gelen listeyi dropdown'a göre değiştirdik
  let tmpAppLessonTypeList = appLessonTypeArray.map(appLesson => ({id: appLesson.id, name: appLesson.displayName}));
  const appLessonTypeList = [firstAppLessonType, ...tmpAppLessonTypeList];

  const [deviceArray, setDeviceArray] = useState([]);
  const firstDevice = { id: -1, name: strings.member.member_detail.others.choose_device };
  
  const [application, setApplication] = useState(firstApplication);
  const [appLessonType, setAppLessonType] = useState(firstAppLessonType);
  const [device, setDevice] = useState(firstDevice);
  const [deviceList, setDeviceList] = useState([]);
  
  //Kullanıcının markasına göre uygulamalar gelecek.
  const getApplicationsByBrandId = async (brandId) => {
    const result = await GetApplicationsByBrandId(brandId);

    let arrayList = [];

    if (result.status === 200) {
        const data = JSON.parse(result.content);
        
        data.map((item) => {
            let arrayItem = { id: item.value, name: item.text };
            arrayList.push(arrayItem);
        })
    }
  
    setApplications(applications => arrayList);
  }

  //Uygulama seçildiğinde uygualamaya göre altyapı seçeneklerini getiren fonksiyon
  const getAppLessonTypes = async (brandAppId) => {
    const result = await GetAppLessonTypes(brandAppId);

    if(result.status === 200){
      let resultContent = JSON.parse(result.content);

      setAppLessonTypeArray(appLessonTypeArray => resultContent);
    }
  }

  //Kullanıcı ID'sine göre kullanıcının cihazlarını getiren fonksiyon
  const getMemberDevices = async (memberId) => {
    const result = await GetMemberDevices(memberId);
    if(result.status === 200){
      let resultContent = JSON.parse(result.content);
      setDeviceArray(deviceArray => resultContent);
    }
  }

  //Uygulama seçilince altyapıyı getirir
  useEffect(() => {
    if(application !== -1){
      getAppLessonTypes(application.id);
    }
  
    let tmpDeviceList = [];

    //Seçilen uygulama eğer web ise kullanıcının cihazlarından sadece deviceOs'u web olanı yazdırıyoruz.
    //Seçilen uygulama web değil ise deviceOs'u web olanlar dışındakileri yazdırıyoruz.
    if(+application.id === ApplicationName.AzerWebCall || +application.id === ApplicationName.AlingWebCall || +application.id === ApplicationName.KoWebCall){
      let tmpWebDeviceList = deviceArray.filter(device => device.deviceOS === DeviceOSName.Web);
      tmpDeviceList = tmpWebDeviceList.map(device => ({id: device.id, name: device.deviceName ? getMemberDeviceOS(device.deviceOS) + " / " + device.deviceName : getMemberDeviceOS(device.deviceOS)}))
    } else {
      let tmpMobileDeviceList = deviceArray.filter(device => device.deviceOS !== DeviceOSName.Web);
      tmpDeviceList = tmpMobileDeviceList.map(device => ({id: device.id, name: device.deviceName ? getMemberDeviceOS(device.deviceOS) + " / " + device.deviceName : getMemberDeviceOS(device.deviceOS)}))
    }

    setDeviceList([firstDevice, ...tmpDeviceList]);
  }, [application])
  
  
  //Sayfa ilk yüklendiğinde kullanıcının cihazlarını getirir
  useEffect(() => {
    if(memberId){
      getApplicationsByBrandId(brandId);
      getMemberDevices(memberId);
      //LOGGED
      analytics.useAnalytics(ComponentViewLogs.ADD_CALL_TYPE_COMPONENT, { memberId: memberId });
    }
  }, [])

  const submitHandle = async e => {

    e.preventDefault();

    let valid = true;

    if(application.id === -1 || appLessonType.id === -1 || device.id === -1){
      valid = false;
    }

    if(valid){
      const model = {
        brandAppId: application.id,
        appLessonTypeId: appLessonType.id,
        memberId: memberId,
        memberDeviceId: device.id
      };

      const result = await CreateMemberLessonApp(model);

      if(result.status === 200){
        showTopAlert(strings.member.member_detail.others.call_type_success, "success");
        setTimeout(() => closeButton(), 3000);
        setTimeout(() => sendRequestToEndpoint(), 3000);
      } else {
        let duplicateMessage = 'The member has an active device that belongs to this member device id!';

        if(result.content === duplicateMessage){
          showTopAlert(strings.member.member_detail.others.duplicate_error_message, "error");  
        } else {
          showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
        }
      }

    } else {
      showTopAlert(strings.alert.top_alert.inform, "warning");
    }    
  };

  return (
    <>
      <div className="flex justify-center">
        <p className="font-bold text-[15px] mb-2">
          {strings.member.member_detail.others.add_call_type}
        </p>
      </div>
      <form onSubmit={submitHandle} className="flex flex-col text-start gap-5">
        <div className="flex flex-col px-[10px] pb-0 w-full">
          <label>
            {strings.member.member_detail.others.application}  <span className="text-red">*</span>
          </label>
          <Dropdown
            classnames="md:max-w-full"
            data={applicationList}
            selectedData={application}
            setSelectedData={setApplication}
          />
        </div>
        <div className="flex flex-col px-[10px] pb-0 w-full">
          <label>
            {strings.member.member_detail.others.substructure}  <span className="text-red">*</span>
          </label>
          <Dropdown
            classnames="md:max-w-full"
            isDisabled = {application.id === -1 && true}
            data={appLessonTypeList}
            selectedData={appLessonType}
            setSelectedData={setAppLessonType}
          />
        </div>
        <div className="flex flex-col px-[10px] pb-0 w-full">
          <label>
            {strings.member.member_detail.others.device}  <span className="text-red">*</span>
          </label>
          <Dropdown
            classnames="md:max-w-full"
            data={deviceList}
            selectedData={device}
            setSelectedData={setDevice}
          />
        </div>
        <div className="flex gap-3 justify-center">
          <button
              type="submit"
              className="button search-button gap-3 text-white bg-blue border-blue focus:border-blue px-10"
          >
              {strings.button.save_upper}
          </button>
          <button
              type="button"
              onClick={closeButton}
              className="button search-button gap-3 text-white bg-red border-red focus:border-red px-10"
          >
              {strings.button.cancel_upper}
          </button>
        </div>
      </form>
    </>
  );
};

export default AddCallTypeModal;
