import { GetAllProductivityPerson, GetAllSalesPerson } from "../../../../services/Employee/EmployeeService";

//Satış personellerini alıyoruz
export const getSalesPerson = async (brandId, firstItem, setList) => {

    const result = await GetAllSalesPerson(brandId);
    let arrayList = [];

    if (result.status === 200) {
        const data = JSON.parse(result.content);
        data.map((item) => {
            let arrayItem = { id: item.value, name: item.text };
            arrayList.push(arrayItem);
        })
        setList([firstItem, ...arrayList])
    }

}

//Verimlilik personellerini alıyoruz
export const getProductivityPerson = async (brandId, firstItem, setList) => {
    const result = await GetAllProductivityPerson(brandId);
    let arrayList = [];

    if (result.status === 200) {
        const data = JSON.parse(result.content);
        data.map((item) => {
            let arrayItem = { id: item.value, name: item.text };
            arrayList.push(arrayItem);
        })
        setList([firstItem, ...arrayList])
    }

}

export const applicationStatusColorPicker = (applicationStatus) => {
    switch (applicationStatus) {
        case "Havuza Bırakıldı":
            return 'bg-red';
        case "Hatırlatma Kuruldu":
            return 'bg-blue'
        case "Yeni Kayıt":
            return "bg-green"
        case "Demo Kayıt":
            return "bg-warning"
        default:
            return "bg-md-gray text-base-text"
    }
}