import classNames from "classnames";
import { Link } from "react-router-dom";
import { getBrandNameById, getLogoByBrandId } from "../../../../components/Utility";
import { useLocalization } from "../../../../hooks/Utils/useLocalization";
import { url } from "../../../../routes/utility";

const SalesmanReportTableRow = (props) => {

    const { employee, brandId, setModal, setEmployeeId, setSource } = props;
    const strings = useLocalization();

    const handleClickCount = (source) => {
        setEmployeeId(employee.employeeId);
        setSource(source);
        setModal(true);
    }

    return (
        <>
            <tr className="bg-white hover:bg-[#f0f0f0]">
                <td className="td align-middle">
                    <div className="relative group max-w-max ml-6">
                        <img src={getLogoByBrandId(brandId)} width="43" height="48" />
                        <div className="tooltip-top">
                            <p>{getBrandNameById(brandId)}</p>
                        </div>
                    </div>
                </td>
                <td className="td align-middle">
                    <div className="max-w-[320px] flex items-center gap-1">
                        <span className="material-symbols-outlined flex-shrink-0 text-[19px]">
                            person
                        </span>
                        <Link
                            to={url("employee.detail", { employeeId: employee.employeeId })}
                            className="font-bold text-blue font-bold hover:underline focus:underline min-w-max"
                        >
                            {employee.employeeName}
                        </Link>
                    </div>
                </td>
                <td className="td align-middle max-2xl:text-center">
                    <p className={classNames("font-bold", {
                        "text-green": employee.employeeStatus,
                        "text-red": !employee.employeeStatus
                    })}>{employee.employeeStatus ? strings.form_info.active : strings.form_info.pasive}</p>
                </td>
                <td className="td align-middle max-2xl:text-center">
                    <button
                        type="button"
                        onClick={() => employee.candidateCount > 0 && handleClickCount("candidate-count")}
                    >
                        <p
                            className={classNames("", {
                                "hover:underline text-blue font-bold" : employee.candidateCount > 0,
                                "cursor-default" : employee.candidateCount === 0
                            })}
                        >
                            {employee.candidateCount}
                        </p>
                    </button>
                </td>
                <td className="td align-middle max-2xl:text-center">
                    <button
                        type="button"
                        onClick={() => employee.getPoolCount > 0 && handleClickCount("pool-count")}
                    >
                        <p
                            className={classNames("", {
                                "hover:underline text-blue font-bold": employee.getPoolCount > 0,
                                "cursor-default": employee.getPoolCount === 0
                            })}
                        >
                            {employee.getPoolCount}
                        </p>
                    </button>
                </td>
                <td className="td align-middle max-2xl:text-center">
                    <button
                        type="button"
                        onClick={() => employee.dropPoolCount > 0 && handleClickCount("drop-pool-count")}
                    >
                        <p
                            className={classNames("", {
                                "hover:underline text-blue font-bold": employee.dropPoolCount > 0,
                                "cursor-default": employee.dropPoolCount === 0
                            })}
                        >
                            {employee.dropPoolCount}
                        </p>
                    </button>
                </td>
                <td className="td align-middle max-2xl:text-center">
                    <button
                        type="button"
                        onClick={() => employee.reminderCount > 0 && handleClickCount("reminder-count")}
                    >
                        <p
                            className={classNames("", {
                                "hover:underline text-blue font-bold": employee.reminderCount > 0,
                                "cursor-default": employee.reminderCount === 0
                            })}
                        >
                            {employee.reminderCount}
                        </p>
                    </button>
                </td>
            </tr>
        </>
    );
}

export default SalesmanReportTableRow