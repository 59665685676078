//Butonlara özel rol tanımlandığında kontroller bu fonksiyon tarafından yapılıyor
import {buttonRights} from "./ButtonRights";

export const getButtonPermission = (role) => {
    let buttonRole = [];
    let group = buttonRights.find(grp => grp.group === role.group);

    if (group) {
        group.buttons.map(btn => {
            if (btn.name === role.name) {
                buttonRole = btn.roles;
            }
        })
    }

    return buttonRole;
}