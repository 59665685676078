import {useEffect, useState} from "react";
import {GetPhoneNumberByMemberId} from "../../services/Member/MemberService";

const useMemberPhoneNumber = (memberId) => {

    const [memberPhoneNumber, setMemberPhoneNumber] = useState("");

    const getMemberPhoneNumber = async (memberId) => {

        const result = await GetPhoneNumberByMemberId(memberId);

        if(result.status === 200) {
            let resultContent = JSON.parse(result.content);
            setMemberPhoneNumber(memberPhoneNumber => resultContent);
        }
    }

    useEffect(() => {
        if(memberId) {
            getMemberPhoneNumber(memberId);
        }
    }, [memberId]);

    return memberPhoneNumber;
}

export default useMemberPhoneNumber;