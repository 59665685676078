import { fetchApi } from '../index';

export const MemberLogFilter = async (model) => {
    
    const url = '/prime_log/v2/log/filter/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}