export const ScreenViewLogs = Object.freeze(
    {
        '/dashboard': 'Dashboard_Ekranini_Goruntuledi',
        '/member/student': 'Ogrenciler_Ekranini_Goruntuledi',
        '/member/candidate': 'Ogrenci_Adaylari_Ekranini_Goruntuledi',
        '/member/candidatepool': 'Aday_Havuzu_Ekranini_Goruntuledi',
        '/member/addcandidate': 'Havuza_Aday_Ekleme_Ekranini_Goruntuledi',
        '/member/detail/calltypes/:id' : 'Arama_Tipleri_Ekranini_Goruntuledi',
        '/member/detail/movebrand/:id': 'Marka_Tasi_Ekranini_Goruntuledi',
        '/accounting': 'Cari_Kart_Ekranini_Goruntuledi',
        '/accounting/currentcard': 'Cari_Kart_Ekranini_Goruntuledi',
        '/accounting/invoice': 'Faturalar_Ekranini_Goruntuledi',
        '/accounting/payment': 'Odemeler_Ekranini Goruntuledi',
        '/accounting/campaign': 'Kampanyalar_Ekranina_Girdi',
        '/accounting/addcampaign': 'Kampanya_Ekleme_Ekranina_Girdi',
        '/accounting/discount': 'Indirim_Kodlari_Ekranini_Goruntuledi',
        '/teacher/program': 'Egitmen_Programi_Ekranini_Goruntuledi',
        '/task': 'Gorevler_Ekranini_Goruntuledi',
        '/ticket': 'Talepler_Ekranini_Goruntuledi',
        '/reminder': 'Hatirlatmalar_Ekranini_Goruntuledi',
        '/employee': 'Kullanicilar_Ekranina_Girdi',
        '/employee/list': 'Kullanicilar_Ekranina_Girdi',
        '/employee/create': 'Kullanici_Ekleme_Ekranina_Girdi',
        '/report': 'Aday_Havuzu_Raporlari_Goruntulendi',
        '/report/candidatepoolreport': 'Aday_Havuzu_Raporlari_Goruntulendi',
        '/report/salesmanreport': 'Satis_Temsilci_Raporlari_Ekranini_Goruntuledi'
    }
);

export const ClickLogs = Object.freeze(
    {
        'TASK_NOTIFICATION_CLICKED': 'Gorevler_Bildirimlerine_Tiklandı',
        'TICKET_NOTIFICATION_CLICKED': 'Talepler_Bildirimlerine_Tiklandi',
        'REMINDER_NOTIFICATION_CLICKED': 'Hatirlatmalar_Bildirimlerine_Tiklandi',
        'CALL_TEST_BUTTON_CLICKED': 'Arama_Testi_Butonuna_Tikladi',
        //E-Posta, WhatsApp, SMS
        'WHATSAPP_RETURN_BUTTON_CLICKED': 'Whatsapp_Donus_Butonuna_Tikladi',
        'EMAIL_WAS_SEND': 'Mail_Gonderildi',
        'EMAIL_WAS_NOT_SEND': 'Mail_Gonderilemedi',
        'EMAIL_SEND_ON_WHATSAPP': 'Mail_Whatsapp_Uzerinden_Gonderildi',
        'SMS_WAS_SEND': 'Sms_Gonderildi',
        'SMS_WAS_SEND_ON_WHATSAPP': 'Sms_Whatsapp_Uzerinden_Gonderildi',
        'OFFER_MAIL_WAS_SEND': 'Teklif_Maili_Gonderildi',
        'OFFER_WAS_SEND_ON_WHATSAPP': 'Teklif_Whatsapp_Uzerinden_Gonderildi',
        //Demo
        'DEMO_TERM_OPENED' : 'Demo_Kaydi_Acildi'
    }
);


export const ComponentViewLogs = Object.freeze(
    {
        //Member Detail -> Call Type
        'ADD_CALL_TYPE_COMPONENT' : 'Arama_Tipi_Ekleme_Ekranini_Goruntuledi',
        //Term -> Choose Call Type
        'CHOOSE_CALL_TYPE_COMPONENT' : 'Arama_Tipi_Secme_Ekranini_Goruntuledi',
        //Unit -> Update Unit
        'UPDATE_UNIT_COMPONENT': 'Unite_Guncelleme_Ekranini_Goruntuledi'
    }
);