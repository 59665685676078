import {useLocalization} from "../../../../hooks/Utils/useLocalization";
import UseCaseTableRow from "./UseCaseTableRow";
import {NoRecordsFound} from "../../../../components";

const UseCaseTable = ({ data }) => {

    const strings = useLocalization();

    return (
        <div className="mx-auto px-4 my-4">
            <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
                <div className="inline-block min-w-full rounded-lg overflow-hidden">
                    <table className="min-w-full leading-normal text-header">
                        <thead>
                            <tr>
                                <th className="th sm:w-[80%] min-w-max">{strings.table.title}</th>
                                <th className="th sm:w-[5%] min-w-max">{strings.form_info.status}</th>
                                <th className="th sm:w-[10%] min-w-max">{strings.table.operation}</th>
                            </tr>
                        </thead>
                        <tbody>
                        { data.length > 0 ?
                            (
                                data.map((useCase,index) => (
                                    <UseCaseTableRow
                                        key={index}
                                        useCase={useCase}
                                    />
                                ))
                            )
                            :
                            (
                                <tr className="bg-white hover:bg-[#f0f0f0]">
                                    <td colSpan="3">
                                        <NoRecordsFound />
                                    </td>
                                </tr>
                            )
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    );
}

export default UseCaseTable;