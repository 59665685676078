import {FormNote} from "../index";

const CandidateNotesModal = (props) => {

    const { memberId } = props;

    return (
        <FormNote outsideMemberId={memberId} />
    )
}

export default CandidateNotesModal;