import React from "react";
import { NoRecordsFound } from "../../../../../../components";
import { addTimeZoneToFullDate, isItSpecified } from "../../../../../../components/Utility";
import { useLocalization } from "../../../../../../hooks/Utils/useLocalization";
import useAuth from "../../../../../../hooks/Log/useAuth";

const FormNotesTable = ({ data }) => {
  const strings = useLocalization();
  const { employee } = useAuth();

  return (
    <table className="min-w-full leading-normal text-header">
      <thead>
        <tr>
          <th className="th"><p>{strings.table.candidate_note}</p></th>
          <th className="th"><p className="min-w-max">{strings.table.performing_person}</p></th>
          <th className="th"><p className="min-w-max">{strings.table.operation_date}</p></th>
        </tr>
      </thead>
      <tbody>
        {data.length > 0 ? (
          data.map((form, index) => (
            <tr key={index} className="bg-white hover:bg-[#f0f0f0]">
              <td className="td h-[60px] align-middle">
                <p className="max-w-max">{isItSpecified(form.formNote, strings.errors.it_is_not_specified)}</p>
              </td>
              <td className="td h-[60px] align-middle">
                <p className="min-w-max">{form.employeeName}</p>
              </td>
              <td className="td h-[60px] align-middle">
                <p className="min-w-max">{addTimeZoneToFullDate(form.formDate, employee.TimeZone)}</p>
              </td>
            </tr>
          ))
        ) : (
          <tr className="bg-white hover:bg-[#f0f0f0]">
            <td className="td" colSpan={3}>
              <NoRecordsFound />
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default FormNotesTable;
