import { getLanguageKeyBySelectedLanguage } from '../../components/Utility';
import { fetchApi } from '../index';

export const GetProductTitleByBrandId = async (brandId) => {

    const url = '/member/v2/product/get_product_title_by_brand_id/';

    const payload = {
        brandId: brandId
    };

    return await fetchApi(url, payload);
}

export const GetSalesProducts = async (productCategoryId, brandId, language) => {

    const url = '/member/v1/product/sales_product_options/';

    const payload = {
        productCategoryId: productCategoryId,
        brandId: brandId,
        lang: getLanguageKeyBySelectedLanguage(language)
    };

    return await fetchApi(url, payload);
    
}

export const GetSalesProductsV2 = async (productCategoryId, brandId, language) => {

    const url = '/member/v2/product/sales_product_options/';

    const payload = {
        productCategoryId: productCategoryId,
        brandId: brandId,
        lang: getLanguageKeyBySelectedLanguage(language)
    };

    return await fetchApi(url, payload);
    
}

export const GetSalesProductsV3 = async (model) => {

    const url = '/member/v3/product/sales_product_options/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
    
}

export const GetSalesProductDetails = async (model) => {

    const url = '/member/v1/product/sales_product_details/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
    
}

export const GetCampaignDetailsForSales = async (model) => {

    const url = '/member/v1/campaign/get_campaign_details_for_sales/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
    
}

export const GetCampaignTypeDetailsForSales = async (productId, salesTypeId) => {

    const url = '/member/v1/campaign/get_campaign_type_details_for_sales/';

    const payload = {
        productId: productId,
        salesTypeId: salesTypeId
    };

    return await fetchApi(url, payload);
    
}


export const GetDiscountCodeDetailsByProductId = async (productId) => {

    const url = '/member/v1/discount_code/get_discount_code_details_by_product_id/';

    const payload = {
        productId: productId
    };

    return await fetchApi(url, payload);
    
}

export const GetDiscountsByMemberId = async (model) => {

    const url = '/member/v1/campaign/get_discounts_by_member_id/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
    
}


export const CalculateAdditionalProductPrice = async (model) => {

    const url = '/member/v1/product_price/calculate_additional_product_price/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
    
}

export const CalculateGiveOfferProductPrice = async (model) => {

    const url = '/member/v1/product_price/calculate_give_offer_product_price/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
    
}

export const CalculateDiscountedPriceOfGiveOfferPackage = async (model) => {

    const url = '/member/v1/product_price/calculate_discounted_price_of_give_offer_package/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
    
}

export const CalculatePriceByDiscountRate = async (model) => {

    const url = '/member/v1/product_price/calculate_price_by_discount_rate/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
    
}

//Ek hizmet satın alma sayfasında pakete ait bilgileri getiren servis.
export const GetAdditionalServiceProducts = async (model) => {

    const url = '/member/v1/product/get_additional_service_products/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

//Ek hizmet satın alma sayfasında pakete ait içeriklerin bilgisini(fiyat, kdv...) getiren servis.
export const GetAdditionalServiceProductDetails = async (model) => {

    const url = '/member/v1/product_price/calculate_additional_product_price/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

//Teklif Gönder ve Satış işlemleri sayfasında ürün kategorilerini listeler
export const GetProductCategorySelectList = async (brandId) => {
    
    const url = '/member/v1/product_category/get_product_category_select_list/';

    const payload = {
        lang: getLanguageKeyBySelectedLanguage(brandId)
    };

    return await fetchApi(url, payload);
}

export const GetProductDetailsToChangePackage = async (model) => {

    const url = '/member/v1/product/product_details_to_change_package/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

//Ders erteleme haklarını getiren servis
export const GetPostponeRightProductsService = async (model) => {

    const url = '/member/v1/product/get_products_for_additional/';

    const payload = {
        ...model
    }

    return await fetchApi(url, payload);
}