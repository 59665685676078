import useDataLayer from "../Log/useDataLayer";
import {CustomEventDescriptions, CustomEvents} from "../../consts/DataLayer";
import {showCenteredAlertTwoButtonsWithParameters, showCenteredHtmlAlert} from "../../components";
import {useLocalization} from "./useLocalization";
import {Click2Call} from "../../services/Alotech/AlotechService";

const useClick2Call = (phoneNumber, fullName, alotechSessionKey) => {

    const strings = useLocalization();
    const dataLayer = useDataLayer();

    const handleClick2Call = () => {
        if (phoneNumber.length > 0) {
            // DATA LAYER LOGGED
            dataLayer.useCustomEvents(CustomEvents.EMPLOYEE_EVENTS, CustomEventDescriptions.CLICK_2_CALL);

            showCenteredAlertTwoButtonsWithParameters(
                strings.alert.centered_alert_two_buttons.title_confirm,
                strings.member.member_detail.profile.call_question.replace("#memberName#", fullName),
                strings.button.confirm,
                strings.button.cancel,
                click2Call,
                [alotechSessionKey, phoneNumber]
            );
        }
    };

    const click2Call = async () => {
        let formattedPhoneNumber = phoneNumber.split("+9")[1];
        const result = await Click2Call(alotechSessionKey, formattedPhoneNumber);

        // DATA LAYER LOGGED
        dataLayer.useCustomEvents(CustomEvents.EMPLOYEE_EVENTS, CustomEventDescriptions.CLICK_2_CALL_APPROVED);

        if (result.success) {
            showCenteredHtmlAlert(
                strings.alert.title.information,
                strings.member.member_detail.profile.click2call_success,
                strings.alert.centered_alert_two_buttons.confirm_text,
                "success"
            );
        } else {
            showCenteredHtmlAlert(
                strings.alert.title.information,
                strings.member.member_detail.profile.click2call_fail,
                strings.alert.centered_alert_two_buttons.confirm_text,
                "error"
            );
        }
    };

    return { handleClick2Call, click2Call };
}

export default useClick2Call;