import React from 'react'
import {useLocalization} from '../../../hooks/Utils/useLocalization';
import {getLogoByBrandId, getBrandNameById, isItSpecified, ISOStringToDate} from "../../../components/Utility";
import {Link} from 'react-router-dom';
import {url} from '../../../routes/utility';
import useAuth from '../../../hooks/Log/useAuth';

const ReminderTableRow = (props) => {

    const strings = useLocalization();
    const { employee } = useAuth();
    const {reminder} = props;

    return (
        <tr className="bg-white hover:bg-[#f0f0f0]">
            <td className="td align-middle">
                <div className='relative group max-w-max ml-6'>
                    <img src={getLogoByBrandId(reminder.brand.id)} width="43" height="48" alt=""/>
                    <div className="tooltip-top">
                        <p>{getBrandNameById(reminder.brand.id)}</p>
                    </div>
                </div>
            </td>
            <td className="td align-middle">
                <div className="flex items-center">
                    <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
                        person
                    </span>
                    <Link
                        to={url("member.detail.profile", {memberId: reminder.candidate.id})}
                        target="_blank"
                        className="font-bold text-blue hover:underline focus:underline"
                    >
                        <p className='min-w-max'>{isItSpecified(reminder.candidate.fullName, strings.errors.no_name)}</p>
                    </Link>
                </div>
            </td>
            <td className="td align-middle">
                <p className='min-w-[200px]'>{reminder.reasonForReminding}</p>
            </td>
            <td className="td align-middle">
                <p className='min-w-[200px]'>{isItSpecified(reminder.note, strings.errors.it_is_not_specified)}</p>
            </td>
            <td className="td align-middle">
                <p>{reminder.employeeName}</p>
            </td>
            <td className="td align-middle">
                <p className='min-w-max'>{ISOStringToDate(reminder.callDate, employee?.TimeZone)}</p>
            </td>
        </tr>
    )
}

export default ReminderTableRow