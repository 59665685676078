
//Member Detail sayfası için başlıkları ve path'leri içeren bölüm

//status değeri [1, 2, 3] olanlar her zaman listede görünecek
//status değeri [1, 2] olanlar memberStatus 1'e ve 2'ye eşit olduğunda listede görünecek
//status değeri [1] olanlar memberStatus 2'e eşit olduğunda listede görünecek
//path'i butona tıklandığında aktif olması için tutuyoruz
//pathName'i butona tıklandığında istediğimiz yere gitmesi için tutuyoruz.
//roles adında bir array gönderirsek sadece array içerisinde bulunan roller ilgili menüyü görebilir, gönderilmediği zaman herkese açık olur.
export const SalesOperations = [
    {
        tr: 'Demo Kayıt',
        eng: 'Demo Kayıt',
        path: "/member/detail/demo",
        pathName: "member.detail.demo",
        status: [1, 2, 3],
    },
    {
        tr: 'İlk Kayıt',
        eng: 'İlk Kayıt',
        path: "/member/detail/registration",
        pathName: "member.detail.registration",
        state: { registrationType: 1 },
        status: [1, 2],
    },
    {
        tr: 'İkinci Kayıt',
        eng: 'İkinci Kayıt',
        path: "/member/detail/registration",
        pathName: "member.detail.registration",
        state: { registrationType: 2 },
        status: [2],
        generalRoles: [1, 2, 3, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21]
    },
    {
        tr: 'Saatlik İndirim',
        eng: 'Saatlik İndirim',
        path: "/member/detail/hourlydiscount",
        pathName: "member.detail.hourlydiscount",
        status: [1, 2, 3],
    },
    {
        tr: 'Yeniden Dene',
        eng: 'Yeniden Dene',
        path: "/member/detail/registration",
        pathName: "member.detail.registration",
        state: { registrationType: 4 },
        status: [3],
        generalRoles: [1, 2, 3, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21]
    }
];

export const CandidateOperations = [
    {
        tr: 'Hatırlatma Kur',
        eng: 'Hatırlatma Kur',
        path: "/member/detail/setreminder",
        pathName: "member.detail.setreminder",
        status: [1]
    },
    {
        tr: 'Havuza Bırak',
        eng: 'Havuza Bırak',
        path: "/member/detail/droppool",
        pathName: "member.detail.droppool",
        status: [1],
        generalRoles: [4, 5, 6]
    }
];

export const StudentOperations = [
    {
        tr: 'Hatırlatma Kur',
        eng: 'Hatırlatma Kur',
        path: "/member/detail/setreminder",
        pathName: "member.detail.setreminder",
        status: [2, 3]
    }
]

export const References = [
    {
        tr: 'Referans Oluştur',
        eng: 'Referans Oluştur',
        path: "/member/detail/createreference",
        pathName: "member.detail.createreference",
        status: [1, 2, 3]
    },
    {
        tr: 'Kumbara İşlemleri',
        eng: 'Kumbara İşlemleri',
        path: "/member/detail/moneybox",
        pathName: "member.detail.moneybox",
        status: [1, 2, 3]
    },
    {
        tr: 'Referans Veren Üye Ekle',
        eng: 'Referans Veren Üye Ekle',
        path: "/member/detail/addreferrer",
        pathName: "member.detail.addreferrer",
        status: [1]
    },
    {
        tr: 'Referans Verdiği Üyeler',
        eng: 'Referans Verdiği Üyeler',
        path: "/member/detail/referenced",
        pathName: "member.detail.referenced",
        status: [1, 2, 3]
    }
];

export const InformOperations = [
    {
        tr: 'Teklif Gönder',
        eng: 'Teklif Gönder',
        path: "/member/detail/sendoffer",
        pathName: "member.detail.sendoffer",
        status: [1, 2, 3]
    },
    {
        tr: 'E-Posta Gönder',
        eng: 'E-Posta Gönder',
        path: "/member/detail/sendemail",
        pathName: "member.detail.sendemail",
        status: [1, 2, 3]
    },
    {
        tr: 'SMS Gönder',
        eng: 'SMS Gönder',
        path: "/member/detail/sendsms",
        pathName: "member.detail.sendsms",
        status: [1, 2, 3]
    }
];

export const Tasks = [
    {
        tr: 'Mevcut Görevler',
        eng: 'Mevcut Görevler',
        path: "/member/detail/currenttasks",
        pathName: "member.detail.currenttasks",
        status: [1, 2, 3]
    },
    {
        tr: 'Geçmiş Görevler',
        eng: 'Geçmiş Görevler',
        path: "/member/detail/completedtasks",
        pathName: "member.detail.completedtasks",
        status: [1, 2, 3]
    }
];

export const Operations = [
    {
        tr: 'Görev Notları',
        eng: 'Görev Notları',
        path: "/member/detail/tasknote",
        pathName: "member.detail.tasknote",
        status: [1, 2, 3],
        poolRoles: [1, 2, 3]
    },
    {
        tr: 'Notlar',
        eng: 'Notlar',
        path: "/member/detail/membernote",
        pathName: "member.detail.membernote",
        status: [1, 2, 3]
    },
    {
        tr: 'Aday Notları',
        eng: 'Aday Notları',
        path: "/member/detail/formnote",
        pathName: "member.detail.formnote",
        status: [1, 2, 3]
    },
    {
        tr: 'Loglar',
        eng: 'Loglar',
        path: "/member/detail/log",
        pathName: "member.detail.log",
        status: [1, 2, 3]
    },
    {
        tr: 'Eski Sistem Notları',
        eng: 'Eski Sistem Notları',
        path: "/member/detail/oldsystemnote",
        pathName: "member.detail.oldsystemnote",
        status: [1, 2, 3]
    }
];

export const Accounting = [
    {
        tr: 'Ödemeler',
        eng: 'Ödemeler',
        path: "/member/detail/payments",
        pathName: "member.detail.payments",
        status: [1, 2, 3]
    },
    {
        tr: 'Cari Kart',
        eng: 'Cari Kart',
        path: "/member/detail/currentcard",
        pathName: "member.detail.currentcard",
        status: [1, 2, 3]
    },
    {
        tr: 'Faturalar',
        eng: 'Faturalar',
        path: "/member/detail/invoices",
        pathName: "member.detail.invoices",
        status: [1, 2, 3]
    }
];

export const Others = [
    {
        tr: 'Kullanıcılar',
        eng: 'Kullanıcılar',
        path: "/member/detail/employees",
        pathName: "member.detail.employees",
        status: [1, 2, 3],
    },
    {
        tr: 'Talepler',
        eng: 'Talepler',
        path: "/member/detail/tickets",
        pathName: "member.detail.tickets",
        status: [1, 2, 3]
    },
    {
        tr: 'Arama Tipleri',
        eng: 'Arama Tipleri',
        path: "/member/detail/calltypes",
        pathName: "member.detail.calltypes",
        status: [1, 2, 3]
    },
    {
        tr: 'İletişim Bilgileri',
        eng: 'İletişim Bilgileri',
        path: "/member/detail/contact",
        pathName: "member.detail.contact",
        status: [1, 2, 3],
        poolRoles: [1, 2, 3]
    },
    {
        tr: 'Öğrenci Talepi Ekle',
        eng: 'Öğrenci Talepi Ekle',
        path: "/member/detail/addrequest",
        pathName: "member.detail.addrequest",
        status: [2, 3],
        poolRoles: [1, 2, 3]
    },
    {
        tr: 'Transfer Durumu Ekle',
        eng: 'Transfer Durumu Ekle',
        path: "/member/detail/addtransfer",
        pathName: "member.detail.addtransfer",
        status: [2, 3],
        poolRoles: [1, 2, 3]
    },
    {
        tr: 'Ders Kaydı Onay İzni',
        eng: 'Ders Kaydı Onay İzni',
        path: "/member/detail/lessonrecord",
        pathName: "member.detail.lessonrecord",
        status: [1, 2, 3],
        poolRoles: [1, 2, 3]
    },
    {
        tr: 'Marka Taşıma',
        eng: 'Marka Taşıma',
        path: "/member/detail/movebrand",
        pathName: "member.detail.movebrand",
        status: [1],
        poolRoles: [1, 2, 3]
    }
];

//Member Detail sayfası için başlıkları ve path'leri içeren bölüm