import {useLocalization} from "../../../../hooks/Utils/useLocalization";
import {DetailSearchLayout, Dropdown} from "../../../../components";
import {useEffect, useRef, useState} from "react";
import {addTimeZone} from "../../../../components/Utility";
import useAuth from "../../../../hooks/Log/useAuth";

const AllTeacherProgramDetailSearch = (props) => {

    const strings = useLocalization();
    const { changeDetail, loading, allProgramDataLength } = props;
    const { employee } = useAuth();

    const firstVisit = useRef(true);

    const firstRange = { id: -1, name: strings.teacher.all_teacher_program.select_time_range, beginTime: "", endTime: "" }
    const [selectedRange, setSelectedRange] = useState(firstRange);

    const firstGender = { id: -1, name: strings.form_info.choose_gender };
    const [selectedGender, setSelectedGender] = useState(firstGender);

    const firstListOption = {id: -1, name: strings.form_info.all_teacher_list};
    const [selectedListOption, setSelectedListOption] = useState(firstListOption);

    const ranges = (timeZone) => {
        return [
            firstRange,
            {id: 1, name: `${addTimeZone("03:00", timeZone)} - ${addTimeZone("08:00", timeZone)}`, beginTime: "03:00", endTime: "08:00"},
            {id: 2, name: `${addTimeZone("09:00", timeZone)} - ${addTimeZone("14:00", timeZone)}`, beginTime: "09:00", endTime: "14:00"},
            {id: 4, name: `${addTimeZone("13:00", timeZone)} - ${addTimeZone("21:00", timeZone)}`, beginTime: "13:00", endTime: "21:00"},
            {id: 3, name: `${addTimeZone("15:00", timeZone)} - ${addTimeZone("20:00", timeZone)}`, beginTime: "15:00", endTime: "20:00"},
            {id: 5, name: `${addTimeZone("16:00", timeZone)} - ${addTimeZone("21:00", timeZone)}`, beginTime: "16:00", endTime: "21:00"},
        ]
    }

    const genders = [
        firstGender,
        { id: 0, name: strings.form_info.male},
        { id: 1, name: strings.form_info.female},
    ]

    const listOptions = [
        firstListOption,
        {id: 0, name: strings.form_info.has_free_lesson_list}
    ]

    useEffect(() => {
        //Sayfa ilk kez yüklenmiyorsa işlemi yap
        //Servise birden çok gez gitmesini engelliyoruz
        if (!firstVisit.current) {
            changeDetail?.({
                beginTime: selectedRange.beginTime,
                endTime: selectedRange.endTime,
                gender: firstGender.id
            });
            setSelectedGender(selectedGender => firstGender);
            firstVisit.current = false;
        }
    }, [selectedRange])

    useEffect(() => {
        //Sayfa ilk kez yüklenmiyorsa işlemi yap
        //Servise birden çok gez gitmesini engelliyoruz
        if (!firstVisit.current) {
            changeDetail?.({
                gender: selectedGender.id
            });
            firstVisit.current = false;
        }
    }, [selectedGender])

    useEffect(() => {
        //Sayfa ilk kez yüklenmiyorsa işlemi yap
        //Servise birden çok gez gitmesini engelliyoruz
        if (!firstVisit.current) {
            changeDetail?.({
                listOption: selectedListOption.id
            });
            firstVisit.current = false;
        }
    }, [selectedListOption])

    useEffect(() => {
        //Sayfa yüklendikten sonra false yaparak her değişiklikte
        //servisin ziyaret edilmesini sağlıyoruz
        firstVisit.current = false;
    }, [])

    return(
        <DetailSearchLayout
            open={true}
            isDisabled={loading}
            children={
                <div className="!flex gap-x-4 gap-y-5 max-md-lg:justify-center flex-wrap relative">
                    <Dropdown
                        data={ranges(employee.TimeZone) || false}
                        selectedData={selectedRange}
                        setSelectedData={setSelectedRange}
                        isDisabled={loading}
                    />
                    <Dropdown
                        data={genders}
                        selectedData={selectedGender}
                        setSelectedData={setSelectedGender}
                        isDisabled={loading || allProgramDataLength === 0}
                        tooltip={selectedRange.id === -1 && strings.detail_search_titles.first_choose_time_range}
                    />
                    <Dropdown
                        data={listOptions}
                        selectedData={selectedListOption}
                        setSelectedData={setSelectedListOption}
                        isDisabled={loading || allProgramDataLength === 0}
                        tooltip={selectedRange.id === -1 && strings.detail_search_titles.first_choose_time_range}
                    />
                </div>
            }
        />
    )
}

export default AllTeacherProgramDetailSearch;