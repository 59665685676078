import React from "react";
import { useRef, useEffect, useState } from "react";
import { DetailSearchLayout, Dropdown, DropdownSearch } from "../../../../../../components";
import { getddMMyyyyDate, getDateWithDayDifference } from '../../../../../../components/Utility'
import { useLocalization } from "../../../../../../hooks/Utils/useLocalization";
import usePrimeData from "../../../../../../hooks/Utils/usePrimeData";
import DatePicker from 'react-datepicker';
import { DateRanges } from "../../../../../../consts/Constants";

const FormNotesDetailSearch = ({changeDetail}) => {
    
    const strings = useLocalization();
    const primeData = usePrimeData();

    //Bu bölüm belirli tarih aralığı seçmek için kullanılan değişkenlerin/fonksiyonların olduğu bölüm
    const datePickerRef = useRef();

    const [beginDate, setBeginDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const handleDateRange = (dates) => {
      const [start, end] = dates;
      setBeginDate(start);
      setEndDate(end);
      if (start !== null && end !== null) {
          let tmpDateRange = { id: 8, name: getddMMyyyyDate(start) + " - " + getddMMyyyyDate(end) };
          dateRangeList.push(tmpDateRange);
          changeDetail?.({ beginDate: getddMMyyyyDate(start), endDate: getddMMyyyyDate(end) });
          setBeginDate(null);
          setEndDate(null);
          setDateRange(dateRange => dateRangeList[8]);
          datePickerRef.current.classList.add("hidden");
      }
    };
    //Bu bölüm belirli tarih aralığı seçmek için kullanılan değişkenlerin/fonksiyonların olduğu bölüm
  
    const firstDateRange = { id: -1, name: strings.detail_search_titles.date_range };
    const dateRangeList = [firstDateRange, ...DateRanges];

    const firstEmployee = { id: -1, name: strings.detail_search_titles.employees };
    const employeeList = [firstEmployee, ...primeData.employeeList];
  
    const [dateRange, setDateRange] = useState(firstDateRange);
    const [employee, setEmployee] = useState(firstEmployee);
  
    const firstVisit = useRef(true)

    useEffect(() => {
      //Sayfa ilk kez yüklenmiyorsa işlemi yap
      //Servise birden çok gez gitmesini engelliyoruz
      if (!firstVisit.current) {
          let todayDate = new Date();
          let tmpBeginDate = '';
          let tmpEndDate = '';

          datePickerRef.current.classList.add("hidden");

          switch (dateRange.id) {
              case 1:
                  //Bugün seçildiyse 
                  tmpBeginDate = getddMMyyyyDate(todayDate);
                  tmpEndDate = tmpBeginDate;
                  break;
              case 2:
                  //Dün seçildiyse 
                  tmpBeginDate = getddMMyyyyDate(getDateWithDayDifference(todayDate, 1));
                  tmpEndDate = tmpBeginDate;
                  break;
              case 3:
                  //Son 7 gün seçildiyse 
                  tmpEndDate = getddMMyyyyDate(todayDate);
                  tmpBeginDate = getddMMyyyyDate(getDateWithDayDifference(todayDate, 6));
                  break;
              case 4:
                  //Son 30 gün seçildiyse 
                  tmpEndDate = getddMMyyyyDate(todayDate);
                  tmpBeginDate = getddMMyyyyDate(getDateWithDayDifference(todayDate, 30));
                  break;
              case 5:
                  //Bu ay seçildiyse 
                  tmpBeginDate = getddMMyyyyDate(new Date(todayDate.getFullYear(), todayDate.getMonth(), 1));
                  tmpEndDate = getddMMyyyyDate(new Date(todayDate.getFullYear(), todayDate.getMonth() + 1, 0));
                  break;
              case 6:
                  //Geçen ay seçildiyse 
                  tmpBeginDate = getddMMyyyyDate(new Date(todayDate.getFullYear(), todayDate.getMonth() - 1, 1));
                  tmpEndDate = getddMMyyyyDate(new Date(todayDate.getFullYear(), todayDate.getMonth(), 0));
                  break;
              case 7:
                  //Tarih aralığı seçildiyse 
                  datePickerRef.current.classList.remove('hidden');
                  break;
          }

          (dateRange.id < 7) && changeDetail?.({ beginDate: tmpBeginDate, endDate: tmpEndDate });

          firstVisit.current = false;
      }
    }, [dateRange])
  
    useEffect(() => {
      //Sayfa ilk kez yüklenmiyorsa işlemi yap
      //Servise birden çok gez gitmesini engelliyoruz
      if (!firstVisit.current) {
        changeDetail?.({ employee: employee?.id });
        firstVisit.current = false;
      }
    }, [employee]);
  
    useEffect(() => {
      //Sayfa yüklendikten sonra false yaparak her değişiklikte
      //servisin ziyaret edilmesini sağlıyoruz
      firstVisit.current = false;
    }, []);
  
    return (
      <>
        <DetailSearchLayout
          children={
            <div className="!flex gap-x-4 gap-y-5 max-md-lg:justify-center flex-wrap relative">
              <Dropdown
                data={dateRangeList}
                selectedData={dateRange}
                setSelectedData={setDateRange}
              />
              <div ref={datePickerRef} className='absolute top-[75px] z-10 hidden'>
                  <button 
                    className='absolute button search-button !h-[25px] !p-2 top-[-28px] bg-red text-white focus:!border-none right-0'
                    onClick={() => datePickerRef.current.classList.add("hidden")}
                  >
                    X
                  </button>
                  <DatePicker
                      selected={beginDate}
                      onChange={handleDateRange}
                      onClickOutside={() => datePickerRef.current.classList.add("hidden")}
                      startDate={beginDate}
                      endDate={endDate}
                      selectsRange
                      inline
                  />
              </div>
              <DropdownSearch 
                  inputPlaceholder={strings.detail_search_titles.search_employee}
                  title={strings.detail_search_titles.employees}
                  data={employeeList}
                  selectedData={employee}
                  setSelectedData={setEmployee}
              />
            </div>
          }
        />
      </>
    );
}

export default FormNotesDetailSearch