import { fetchApi } from '../index';

//SMS
export const GetSmsTemplateList = async (brandId) => {

    const url = '/member/v1/sms_template/get_sms_template_list/';

    const payload = {
        brandId: brandId
    };

    return await fetchApi(url, payload);
}

export const GetSmsTemplateContentByIdAndMemberId = async (smsTemplateId, memberId) => {

    const url = '/member/v2/sms_template/get_sms_template_content_by_id_and_member_id/';

    const payload = {
        smsTemplateId: smsTemplateId,
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const GetSmsTemplateContentByBrandIdAndType = async (model) => {

    const url = '/member/v1/sms_template/get_sms_template_content_by_brand_id_and_type/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

//SMS gönderen servis.
export const SendSmsModel = async (model) => {

    const url = '/prime_sms/v1/sms/send/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

//Email template listesi
export const GetEmailsList = async (brandId) => {

    const url = '/member/v1/mail_template/get_emails_list/';

    const payload = {
        brandId: brandId
    };

    return await fetchApi(url, payload);
}

//Email template listesi mail subject ile birlikte getiriliyor
export const GetEmailsListWithSubject = async (brandId) => {

    const url = '/member/v1/mail_template/get_mail_template_list_with_subject/';

    const payload = {
        brandId: brandId
    };

    return await fetchApi(url, payload);
}

export const GetMailTemplateContentById = async (mailTemplateId, memberId) => {

    const url = '/member/v2/mail_template/get_mail_template_content_by_id/';

    const payload = {
        mailTemplateId: mailTemplateId,
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const GetMailTemplateJsonByMemberOrEmployeeIdAndMailType = async (model) => {

    const url = '/member/v1/mail_template/get_mail_template_json_by_member_or_employee_id_and_mail_type/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const GetMailTemplateByMemberOrEmployeeIdAndMailType = async (model) => {

    const url = '/member/v2/mail_template/get_mail_template_by_member_or_employee_id_and_mail_type/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

//E-Mail gönderen servis
export const SendEmailModel = async (model) => {

    const url = '/email/send_email/'

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}