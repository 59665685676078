import {MainItems, UseCaseItems} from "../../../consts/menu/SidebarMenu";
import { useLocalization } from "../../../hooks/Utils/useLocalization";
import { NavLink, useLocation } from "react-router-dom";
import { url } from "../../../routes/utility";
import classNames from "classnames";
import useMobileView from "../../../hooks/Utils/useMobileView";
import {getButtonPermission} from "../../../rights/Utility";
import useAuth from "../../../hooks/Log/useAuth";

const UseCaseOperations = () => {

    const strings = useLocalization();
    const location = useLocation();
    const path = location?.pathname;
    const { isMobileView } = useMobileView();
    const { employeeTypeId, checkPermission } = useAuth();

    const roles = getButtonPermission({ group: "useCasePrime", name: "useCasePrimeAuthority" });
    const permission = checkPermission(roles, employeeTypeId);

    const USE_CASE_ITEMS = UseCaseItems(strings);

    return (
        <>
            {permission && (
                <div>
                    <div className="header text-section">
                        <h4>{strings.sidebar.use_case.title}</h4>
                        <span className="material-symbols-outlined ml-1 font-bold hidden">
                          more_horiz
                        </span>
                    </div>
                    {USE_CASE_ITEMS.map((item, index) => (
                        <NavLink
                            key={index}
                            to={url(item.pathName)}
                            className={({ isActive }) =>
                                classNames("sidebar-buttons group", {
                                    "active-button": isActive,
                                    "justify-end": !isMobileView
                                })
                            }
                            onClick={() => {
                                if (path === item.path) {
                                    window.location.href = `${item.path}`;
                                }
                            }}
                        >
                          <span className="material-symbols-outlined mr-3 flex-shrink-0 h-6 w-6">
                            {item.icon}
                          </span>
                            <span className="toggled w-full">{item.title}</span>
                        </NavLink>
                    ))}
                </div>
            )}

            {!permission && (<></>)}
        </>
    )
};

export default UseCaseOperations;
