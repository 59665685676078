import React, { useEffect, useState } from 'react';
import { Loading, PageTitle, Pagination, SearchBar, SearchCount, showTopAlert } from '../../components';
import NotAuthorized from '../../components/NotAuthorized';
import { stringToDate } from '../../components/Utility';
import useAuth from '../../hooks/Log/useAuth';
import { useLocalization } from '../../hooks/Utils/useLocalization';
import { DiscountCodeFilter } from '../../services/Accounting/DiscountCodeService';
import { DiscountCodeTable } from './components';
import DiscountCodeDetailSearch from './components/DiscountCode/DiscountCodeDetailSearch';

const DiscountCode = () => {
  
  const strings = useLocalization();
  const { employeeTypeId, checkPermission, getPermission } = useAuth();
  const roles = getPermission();
  const permission = checkPermission(roles, employeeTypeId);

  const limit = parseInt(process.env.REACT_APP_TABLE_ROW_LIMIT);
  const [start, setStart] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const [detailSearch, setDetailSearch] = useState({
    beginDate: null,
    endDate: null,
    brandId: -1,
    status: -1,
    useStatus: -1,
    searchText: ''
  });

  //Arama verileri değiştiğinde değerleri sıfırlıyoruz
  const resetValue = () => {
    setStart(start => 0);
    setCurrentPage(currentPage => 1);
    setLoading(loading => true);
  }

  //input değişiklikleri bu alandan yönetiliyor
  const changeValue = (value) => {
    setDetailSearch({ ...detailSearch, searchText: value });
    resetValue();
  }

  //select değişiklikleri bu alandan yönetiliyor
  const changeDetail = (values) => {
    setDetailSearch({ ...detailSearch, ...values });
    resetValue();
  }

  const getDiscountCode = async () => {
    const model = {
      start: start,
      length: limit,
      beginDate: detailSearch.beginDate ? stringToDate(detailSearch.beginDate) : null,
      endDate: detailSearch.endDate ? stringToDate(detailSearch.endDate, 23, 59, 59) : null,
      brandId: detailSearch.brandId,
      status: detailSearch.status,
      usingStatus: detailSearch.useStatus,
      searchText: detailSearch.searchText.length !== 0 ? detailSearch.searchText : null
    }
    
    const result = await DiscountCodeFilter(model);

    if(result.status === 200){
      let resultContent = JSON.parse(result.content);

      setData(data => resultContent.data);
      setTotalRecord(totalRecord => resultContent.recordsTotal);
      setLoading(loading => false);
    } else {
      showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
    }
  }

  //sayfa değiştikçe bilgileri yeniden çağırıyoruz
  useEffect(() => {
    if (totalRecord !== 0) {
      getDiscountCode();
    }
  }, [start])

  useEffect(() => {

    let abortController = new AbortController();

    // Kullanıcının sayfa görüntüleme izni yoksa servise gitmeyip performans kaybını önlüyoruz
    permission && getDiscountCode();

    return () => {
      abortController.abort();
    }
  }, [detailSearch, permission])

  return (
    <>
      {permission && (
        <>
          <DiscountCodeDetailSearch 
            changeDetail={changeDetail} 
            loading={loading}
          />
          <SearchBar 
            changeValue={changeValue} 
            placeHolder={strings.search_bar.search_discount_code} 
            isDisabled={loading}
          />
          {!loading && <SearchCount count={totalRecord} />}
        </>
      )}
      <PageTitle title={strings.sidebar.accounting.discount_codes} />
      {permission ? (
        loading ?
        (
            <Loading />
        )
        :
        (
          <>
              <DiscountCodeTable data={data} />
              <Pagination
                totalCount={totalRecord}
                limit={limit}
                start={start}
                setStart={setStart}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                setLoading={setLoading}
                loadScreen={true}
              />
          </>
        )
      ) : (<NotAuthorized />)}
      
    </>
  )
}

export default DiscountCode
