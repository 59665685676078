import { fetchApi } from '../index';

export const GetAllMemberFormList = async (memberId) => {

    const url = '/member/v1/member_form/member_form_list/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const GetMemberType = async (memberId) => {

    const url = '/member/v1/member/get_member_type/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const MemberFormNotesFilter = async (model) => {
    
    const url = '/member/v2/member_form/form_notes_filter/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const GetMemberNoteTitlesByStatus = async (reasonStatus) => {
    const url = '/member/v1/member_note_title/get_member_note_titles_by_status/';

    const payload = {
        reasonStatus: reasonStatus
    };

    return await fetchApi(url, payload);
}

export const SetDropPool = async (model) => {
    const url = '/member/v1/member_note_title/set_drop_pool/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const SetReminderService = async (model) => {
    const url = '/member/v2/member_form/set_reminder/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const DeleteReminderService = async (model) => {
    const url = '/member/v2/member_form/delete_reminder/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const GetReminderListByMemberId = async (memberId) => {
    const url = '/member/v1/member_form/get_reminder_list/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const UpdateReminder = async (model) => {
    const url = '/member/v1/member_form/update_reminder/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const PostponeReminder = async (model) => {
    const url = '/member/v1/member_form/postpone_reminder/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}