import {Accordion} from "../../../index";
import {
    isItSpecified,
    ISOStringToDate,
} from "../../../../../../components/Utility";
import {Loading, Modal, NoRecordsFound, TextBadge} from "../../../../../../components";
import {SquareButtonWithoutLink} from "../../../../../../components/Buttons";
import ReminderUpdateModal from "./ReminderUpdateModal";
import React, {useState} from "react";
import {useLocalization} from "../../../../../../hooks/Utils/useLocalization";
import useAuth from "../../../../../../hooks/Log/useAuth";
import classNames from "classnames";
import {getDateWithTimeZoneOffset} from "../../../../../../utils";

const ReminderAccordion = ({type, isOpen = true, reminderList, loading}) => {

    const strings = useLocalization();

    const {employee} = useAuth();
    const notSpecified = strings.errors.it_is_not_specified;
    // const dataLayer = useDataLayer();

    const [modal, setModal] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [modalData, setModalData] = useState({});

    const handleUpdateReminder = (reminderData) => {
        /* Modalı açma ve içerisine veri gönderme işlemleri */
        setModal(true);
        setModalContent({element: "reminderupdate"});
        setModalData({
            memberFormActivityId: reminderData?.memberFormActivityId,
            callDate: reminderData?.callDate,
            note: reminderData?.note,
            reminderReasonId: reminderData?.reminderReasonId,
            reminderReason: reminderData?.reminderReason
        });
    }

    const getReminderStatusByDate = (callDate) => {
        const utcDate = getDateWithTimeZoneOffset(employee?.TimeZone);
        const compareDate = new Date(callDate);

        return utcDate < compareDate;
    }

    // const handleDeleteReminder = async (reminderId) => {
    //     //Eğer reminderId ile ilgili bir sorun varsa hata verip işlemi yapmıyoruz
    //     if (typeof reminderId === "undefined") {
    //         showTopAlert(strings.errors.an_error_occurred, "error");
    //         return;
    //     }
    //
    //     const model = {
    //         memberFormActivityId: reminderId,
    //         createUserId: employee.EmployeeId,
    //     }
    //
    //     //Hata yoksa işleme devam edip servise gidiyoruz
    //     const result = await DeleteReminderService(model);
    //
    //     if (200 === result.status) {
    //         showTopAlert(strings.member.member_detail.candidate_operations.reminder_delete_success_message, "success");
    //
    //         //DATA LAYER LOGGED
    //         //TODO:Buraya dataLayer için uygun olan log atılacak
    //         dataLayer.useCustomEvents(CustomEvents.EMPLOYEE_EVENTS, CustomEventDescriptions.SET_REMINDER);
    //
    //         setTimeout(() => {
    //             window.location.reload()
    //         }, 1500);
    //     } else {
    //         if (result.content === ServiceErrorMessages.MEMBER_FORM_NOT_FOUND_ERROR) {
    //             showTopAlert(strings.member.member_detail.candidate_operations.member_form_not_found_error, "error");
    //         } else if (result.content === ServiceErrorMessages.CANNOT_DELETE_PAST_REMINDER_ERROR) {
    //             showTopAlert(strings.member.member_detail.candidate_operations.cannot_delete_past_reminder_error, "error");
    //         } else {
    //             showTopAlert(strings.errors.an_error_occurred, "error");
    //         }
    //     }
    // }

    const closeButton = () => {
        setModal(false);
        setTimeout(() => {
            window.location.reload()
        }, 1500);
    }

    return (
        <>
            <Accordion
                title={
                    <>
                        {type === "passive" && strings.member.member_detail.candidate_operations.past_date_reminders}
                        {type === "active" &&
                            <span className="flex gap-2">
                                {strings.member.member_detail.candidate_operations.active_reminders}
                                {reminderList.length > 0 &&
                                    <TextBadge
                                        className={classNames("text-white h-6 max-w-max -mt-0.5", {
                                            "bg-green" : getReminderStatusByDate(reminderList[0]?.callDate),
                                            "bg-red " : !getReminderStatusByDate(reminderList[0]?.callDate)
                                        })}
                                    >
                                        <span className="min-w-max">
                                            {getReminderStatusByDate(reminderList[0]?.callDate)
                                                ? strings.member.member_detail.candidate_operations.active_reminder
                                                : strings.member.member_detail.candidate_operations.passive_reminder
                                            }
                                        </span>
                                    </TextBadge>
                                }
                            </span>
                        }
                    </>
                }
                outSideClose={false}
                isOpen={isOpen}
                content={
                    <>
                        <div className="overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
                            <div className="inline-block min-w-full rounded-lg overflow-hidden">
                                <table className="min-w-full leading-normal text-header">
                                    <thead>
                                        <tr>
                                            <th className="th">
                                                <p className="min-w-max">{strings.member.member_detail.candidate_operations.postpone_date}</p>
                                            </th>
                                            <th className="th">
                                                <p className="min-w-max">{strings.member.member_detail.candidate_operations.postpone_time}</p>
                                            </th>
                                            <th className="th">
                                                <p className="min-w-max">{strings.member.member_detail.candidate_operations.reminder_reason}</p>
                                            </th>
                                            <th className="th">
                                                <p className="min-w-max">{strings.member.member_detail.candidate_operations.note}</p>
                                            </th>
                                            <th className="th">
                                                <p className="min-w-max">{strings.table.performing_person}</p>
                                            </th>
                                            <th className="th">
                                                <p className="min-w-max">
                                                    {type === "active" &&  strings.table.operation_date}
                                                    {type === "passive" &&  strings.table.update_date.replace(":", "")}
                                                </p>
                                            </th>
                                            {type === "active" &&
                                                <th className="th">
                                                    <p className="min-w-max">{strings.table.operation}</p>
                                                </th>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {loading && (
                                        <tr className="bg-white hover:bg-[#f0f0f0]">
                                            <td colSpan={type === "active" ? 7 : 6}>
                                                <Loading/>
                                            </td>
                                        </tr>
                                    )}

                                    {!loading && (
                                        <>
                                            {reminderList.length > 0 && (
                                                reminderList.map((reminder, index) => {
                                                    const callDateWithTimeZone = ISOStringToDate(reminder?.callDate, employee?.TimeZone);
                                                    const callDate = callDateWithTimeZone.split("/")[0].replace(" ", "");
                                                    const callTime = callDateWithTimeZone.split("/")[1].replace(" ", "");

                                                    return (
                                                        <tr
                                                            key={index}
                                                            //Hatırlatmalardan içinde bulunduğumuz güne ait olanların arka plan rengi değiştiriliyor.
                                                            className="bg-white hover:bg-[#f0f0f0]"
                                                        >
                                                            <td className="td align-middle h-[61.5px]">
                                                                <p className="min-w-max">{callDate}</p>
                                                            </td>
                                                            <td className="td align-middle">
                                                                <p className="min-w-max">{callTime}</p>
                                                            </td>
                                                            <td className="td align-middle">
                                                                <p className="min-w-[200px]">{reminder?.reminderReason}</p>
                                                            </td>
                                                            <td className="td align-middle">
                                                                <p className="min-w-[200px]">
                                                                    {/*
                                                                            Burada yapılmak istenen hatırlatma kurulurken kullanıcının görmeyeceği şekilde
                                                                            hatırlatma nedeni not kısmına basılıyor. İlgili alanı kullanıcıya gösterirken
                                                                            default olarak verilen değerer
                                                                        */}
                                                                    {isItSpecified(
                                                                        reminder?.note?.replace(`${reminder?.reminderReason}`, "").replace(" -", ""), notSpecified
                                                                    )}
                                                                </p>
                                                            </td>
                                                            <td className="td align-middle">
                                                                <p className="min-w-max">{reminder?.createUserName}</p>
                                                            </td>
                                                            <td className="td align-middle">
                                                                <p className="min-w-max">
                                                                    {type === "active" && ISOStringToDate(reminder?.createDate, employee?.TimeZone)}
                                                                    {type === "passive" && ISOStringToDate(reminder?.updateDate, employee?.TimeZone)}
                                                                </p>
                                                            </td>
                                                            {type === "active" &&
                                                                <td className="td align-middle">
                                                                    <div className="flex gap-1">
                                                                        <SquareButtonWithoutLink
                                                                            classnames="bg-blue"
                                                                            iconName="edit"
                                                                            tooltipClassnames="tooltip-top"
                                                                            tooltip={strings.button.update}
                                                                            action={() => {
                                                                                handleUpdateReminder(reminder);
                                                                            }}
                                                                        />
                                                                        {/* Silme işlemi kaldırıldı fakat kodları duruyor. */}
                                                                        {/*<SquareButtonWithoutLink*/}
                                                                        {/*    classnames="bg-red"*/}
                                                                        {/*    iconName="delete"*/}
                                                                        {/*    tooltipClassnames="tooltip-top"*/}
                                                                        {/*    tooltip={strings.button.delete}*/}
                                                                        {/*    action={() => {*/}
                                                                        {/*        showCenteredAlertTwoButtonsWithParameters(*/}
                                                                        {/*            strings.alert.centered_alert_two_buttons.title_confirm,*/}
                                                                        {/*            strings.member.member_detail.candidate_operations.reminder_delete_confirm_message,*/}
                                                                        {/*            strings.button.confirm,*/}
                                                                        {/*            strings.button.cancel,*/}
                                                                        {/*            handleDeleteReminder,*/}
                                                                        {/*            [reminder?.memberFormActivityId]*/}
                                                                        {/*        )*/}
                                                                        {/*    }}*/}
                                                                        {/*/>*/}
                                                                    </div>
                                                                </td>
                                                            }
                                                        </tr>
                                                    )
                                                })
                                            )
                                            }

                                            {reminderList.length === 0 && (
                                                <tr className="bg-white hover:bg-[#f0f0f0]">
                                                    <td colSpan={type === "active" ? 7 : 6}>
                                                        <NoRecordsFound/>
                                                    </td>
                                                </tr>
                                            )}
                                        </>
                                    )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </>
                }
            />

            {(Object.keys(modalContent).length !== 0 && (
                <Modal
                    modal={modal}
                    setModal={setModal}
                    classes={{modal: "h-[650px] max-w-[820px]"}}
                >
                    {modalContent.element === "reminderupdate" && (
                        <ReminderUpdateModal
                            modalData={modalData}
                            closeButton={closeButton}
                        />
                    )}
                </Modal>
            ))}
        </>
    );
}

export default ReminderAccordion;