import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Loading, PageTitle } from "../../../../../components";
import { useLocalization } from "../../../../../hooks/Utils/useLocalization";
import { GetMemberDetailTaskList } from "../../../../../services/Task/TaskService";
import TaskTableRow from "./TaskTableRow";
import useAuth from "../../../../../hooks/Log/useAuth";

const CompletedTasks = () => {

  const strings = useLocalization();

  const { memberId } = useParams() || false;
  const { employee } = useAuth() || false;

  const [taskList, setTaskList] = useState([]);
  const [loading, setLoading] = useState(true);

  const getCompletedAndPassiveTaskList = async (memberId, employeeId) => {

    const result = await GetMemberDetailTaskList(memberId, employeeId,false);

    if (result.status === 200) {
      //Geçmiş görevlerin sıralamasını yeniden eskiye doğru çeviriyoruz
      setTaskList(taskList => JSON.parse(result.content).reverse());
      setLoading(loading => false);
    }
  }

  useEffect(() => {
    if (memberId && employee) {
      getCompletedAndPassiveTaskList(memberId, employee.EmployeeId);
    }
  }, [memberId])

  return (
    <>
      <PageTitle title={strings.member.member_detail.header.content.completed_tasks} />
      {loading ? (
        <Loading />
      ) : (
        <div className="overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
        <div className="inline-block min-w-full rounded-lg overflow-hidden">
          <table className="min-w-full leading-normal text-header">
            <thead>
              <tr>
                <th className="th">{strings.table.operation_informations}</th>
                <th className="th">{strings.table.task_information}</th>
                <th className="th">{strings.table.operation_person}</th>
              </tr>
            </thead>
            <tbody>
              {taskList.length > 0 ?
                taskList.map((task, index) => (
                  <TaskTableRow key={index} data={task} isComplete={true} />
                ))
                : (
                  <tr>
                    <td colSpan="3" className="py-4 text-center">
                      {strings.member.member_detail.tasks.not_found_completed_task}
                    </td>
                  </tr>
                )
              }
            </tbody>
          </table>
        </div>
      </div>
      )}
    </>
  );
};

export default CompletedTasks;
