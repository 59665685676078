import { fetchApi } from '../index';

export const GetHolidayList = async () => {
    
    const url = '/member/v1/holiday/list_all/';

    const payload = {

    };

    return await fetchApi(url, payload);
}