import React, { useEffect, useState } from 'react'
import { useLocalization } from '../../../../../hooks/Utils/useLocalization'
import { GetReferenceDetail } from '../../../../../services/Member/MemberReferenceService';

const ReferenceInfo = ({ memberId, refresh = false }) => {
    const strings = useLocalization();
    const [detail, setDetail] = useState({});

    const getReferenceDetail = async (memberId) => {
        const result = await GetReferenceDetail(memberId);

        if (result.status === 200) {
            setDetail(detail => JSON.parse(result.content));
        }
    }

    useEffect(() => {
        if (refresh) {
            if (memberId) {
                getReferenceDetail(memberId);
            }
        }
    }, [refresh])

    useEffect(() => {
        if (memberId) {
            getReferenceDetail(memberId);
        }
    }, [])

    return (
        <div className="lg:w-[35%] flex flex-col gap-5">
            <div className="flex gap-3 items-center shadow-content rounded-md py-4 px-2 sm:px-4">
                <span className="material-symbols-outlined text-[26px] sm:text-[28px] text-green">
                    sell
                </span>
                <div>
                    <p className="text-[#8d9498]">
                        {strings.member.member_detail.create_reference.reference_code}
                    </p>
                    <p className="text-[20px]">{detail.referenceCode && detail.referenceCode}</p>
                </div>
            </div>
            <div className="flex gap-3 items-center shadow-content rounded-md py-4 px-2 sm:px-4">
                <span className="material-symbols-outlined text-[26px] sm:text-[28px] text-blue">
                    manage_accounts
                </span>
                <div>
                    <p className="text-[#8d9498]">
                        {strings.member.member_detail.create_reference.referring_member}
                    </p>
                    <p className="text-[20px]">{detail.referrerName ? detail.referrerName : "-"}</p>
                </div>
            </div>
            <div className="flex gap-3 items-center shadow-content rounded-md py-4 px-2 sm:px-4">
                <span className="material-symbols-outlined text-[26px] sm:text-[28px] text-warning">
                    data_usage
                </span>
                <div>
                    <p className="text-[#8d9498]">
                        {
                            strings.member.member_detail.create_reference
                                .total_discount_right
                        }
                    </p>
                    <p className="text-[20px]">{detail.totalDiscount} %</p>
                </div>
            </div>
        </div>
    )
}

export default ReferenceInfo