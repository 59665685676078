import React, { useEffect, useState } from 'react'
import { useLocalization } from '../../../../hooks/Utils/useLocalization';
import { MemberPoolLogs } from '../../../../services/Member/MemberPoolService';
import { NoRecordsFound, showTopAlert } from '../../../../components';
import Pagination from '../../../../components/Pagination';
import { addTimeZoneToFullDate, isItSpecified } from '../../../../components/Utility';
import useAuth from '../../../../hooks/Log/useAuth';

const CandidatePoolLogModal = (props) => {
    
    const strings = useLocalization();
    const notSpecified = strings.errors.it_is_not_specified;
    
    const { memberId, classes } = props;
    const { employee } = useAuth();

    const limit = 5;
    const [start, setStart] = useState(0);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);

    const [totalRecord, setTotalRecord] = useState(0);
    const [data, setData] = useState([]);

    //sayfa değiştikçe bilgileri yeniden çağırıyoruz
    useEffect(() => {
        if (totalRecord !== 0) {
            getMemberPoolLogs();
        }
    }, [start])

    const getMemberPoolLogs = async () => {

        const result = await MemberPoolLogs(memberId);
        if (result.status === 200) {
            let resultContent = JSON.parse(result.content);

            setData(data => resultContent.data);
            setTotalRecord(totalRecord => resultContent.recordsTotal);
            setLoading(loading => false);
        } else {
            showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
        }
    }

    useEffect(() => {

        let abortController = new AbortController();

        getMemberPoolLogs();

        return () => {
            abortController.abort();
        }
    }, [])

    return (
        <div className={`animate-fadeIn w-full ${classes}`}>
            <div className="text-[13px] text-start">
                <div className="flex justify-center mb-1">
                    <p className="font-bold text-[15px]">{strings.member.candidate_pool.log.page_title}</p>
                </div>
                <div className="overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
                    <div className="inline-block min-w-full rounded-lg">
                        <table className="min-w-full leading-normal text-header">
                            <thead>
                                <tr>
                                    <th className="th">
                                        <p className='min-w-max'>{strings.member.candidate_pool.log.td_title1}</p>
                                    </th>
                                    <th className="th">
                                        <p className='min-w-max'>{strings.member.candidate_pool.log.td_title2}</p>
                                    </th>
                                    <th className="th">
                                        <p className='min-w-max'>{strings.member.candidate_pool.log.td_title3}</p>
                                    </th>
                                    <th className="th">
                                        <p className='min-w-max'>{strings.member.candidate_pool.log.td_title4}</p>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.length > 0 ? (
                                        data.map((log, index) => (
                                            <tr key={index}>
                                                <td className="td h-[60px] align-middle">
                                                    <p>{log.employeeName}</p>
                                                </td>
                                                <td className="td h-[60px] align-middle">
                                                    <p>{isItSpecified(log.formNoteTitle, notSpecified)}</p>
                                                </td>
                                                <td className="td h-[60px] align-middle">
                                                    <p className=''>{log.formNote}</p>
                                                </td>
                                                <td className="td h-[60px] align-middle">
                                                    <p>{addTimeZoneToFullDate(log.formDate, employee.TimeZone)}</p>
                                                </td>
                                            </tr>
                                        )
                                    ))
                                    :
                                    (   
                                        <tr className='bg-white hover:bg-[#f0f0f0]'>
                                            <td colSpan={4}>
                                                <NoRecordsFound />
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                        <Pagination
                            totalCount={totalRecord}
                            limit={limit}
                            start={start}
                            setStart={setStart}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            setLoading={setLoading}
                            loadScreen={true}
                        />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default CandidatePoolLogModal