import { useEffect, useRef, useState } from "react";
import DatePicker from 'react-datepicker';
import { useSelector } from "react-redux";
import { DetailSearchLayout, Dropdown, DropdownSearch } from "../../../../components";
import { getDateWithDayDifference, getddMMyyyyDate } from '../../../../components/Utility';
import { DateRangesV2, EmployeeStatusType, Job, MemberResourceList } from "../../../../consts/Constants";
import useAuth from "../../../../hooks/Log/useAuth";
import { useLocalization } from "../../../../hooks/Utils/useLocalization";
import usePrimeData from "../../../../hooks/Utils/usePrimeData";
import { getSalesPerson } from "../Common/GetPerson";

const CandidateDetailSearch = ({ changeDetail, loading }) => {

    const strings = useLocalization();
    const primeData = usePrimeData();
    const language = useSelector(state => state.localizationStore.language);
    const { employee }  = useAuth();

    const employeeTypeId = parseInt(employee.EmployeeTypeId);
    const firstVisit = useRef(true);
    //Bu bölüm belirli tarih aralığı seçmek için kullanılan değişkenlerin/fonksiyonların olduğu bölüm
    const datePickerRef = useRef();

    const [reminderBeginDate, setReminderBeginDate] = useState(null);
    const [reminderEndDate, setReminderEndDate] = useState(null);

    const isSalesEmployee =
        employeeTypeId === EmployeeStatusType["Satış Temsilcisi"] ||
        employeeTypeId === EmployeeStatusType["Satış Takım Lideri"] ||
        employeeTypeId === EmployeeStatusType["Satış Takım Lideri Yardımcısı"];

    const handleReminderDateRange = (dates) => {
        const [start, end] = dates;
        setReminderBeginDate(start);
        setReminderEndDate(end);
        if (start !== null && end !== null) {
            let tmpDateRange = { id: 6, name: getddMMyyyyDate(start) + " - " + getddMMyyyyDate(end) };
            dateRangeList.push(tmpDateRange);
            changeDetail?.({ reminderBeginDate: getddMMyyyyDate(start), reminderEndDate: getddMMyyyyDate(end) });
            setReminderBeginDate(null);
            setReminderEndDate(null);
            setReminderDateRange(reminderDateRange => dateRangeList[6]);
            datePickerRef.current.classList.add("hidden");
        }

    };
    //Bu bölüm belirli tarih aralığı seçmek için kullanılan değişkenlerin/fonksiyonların olduğu bölüm

    const firstDateRange = { id: -1, name: strings.detail_search_titles.date_range_last_note };
    const dateRangeList = [firstDateRange, ...DateRangesV2];

    const firstReminderDateRange = { id: -1, name: strings.detail_search_titles.date_range_reminder };
    const reminderDateRangeList = [firstReminderDateRange, ...DateRangesV2];

    const firstBrand = { id: -1, name: strings.detail_search_titles.brands };
    const brandList = [firstBrand, ...primeData.brandList]

    const firstMemberFormStatus = { id: -1, name: strings.detail_search_titles.status };
    const memberFormStatusList = [firstMemberFormStatus, ...primeData.memberFormStatusList];

    const firstSales = { id: null, name: strings.detail_search_titles.sales };

    const firstProfession = { id: -1, name: strings.detail_search_titles.jobs };
    const jobList = Job.map(job => ({ id: job.id, name: job[language] }));    
    const professionList = [firstProfession, ...jobList];

    const firstMemberResource = { id: -1, name: strings.detail_search_titles.resources };
    const resourceList = MemberResourceList.map(resource => ({ id: resource.id, name: resource[language] }));
    const memberResourceList = [firstMemberResource, ...resourceList];

    const firstReminderReason = { id: -1, name: strings.detail_search_titles.reminder_reasons };
    const reminderReasonList = [firstReminderReason, ...primeData.reminderReasonList];

    const dropPoolReasonTitle = { id: -1, name: strings.detail_search_titles.reminder_drop_pool };
    const dropPoolReasonList = [dropPoolReasonTitle, ...primeData.dropPoolReasonList];

    const [reminderDateRange, setReminderDateRange] = useState(firstReminderDateRange);
    const [brand, setBrand] = useState(firstBrand)
    const [status, setStatus] = useState(firstMemberFormStatus)
    const [sales, setSales] = useState(firstSales);
    const [salesList, setSalesList] = useState([]);
    const [profession, setProfession] = useState(firstProfession);
    const [memberResource, setMemberResource] = useState(firstMemberResource);
    const [reminderReason, setReminderReason] = useState(firstReminderReason);
    const [dropPoolReason, setDropPoolReason] = useState(dropPoolReasonTitle);

    useEffect(() => {  
        if (firstVisit.current) {
            if(isSalesEmployee) {
                //Eğer kullanıcı Satış temsilcisi ise hatırlatma tarih aralığında Bugün'ü otomatik seçili getiriyoruz.
                setReminderDateRange(reminderDateRange => dateRangeList[1]);
            } else {
                firstVisit.current = false;
            }
            getSalesPerson(brand.id, firstSales, setSalesList);
        }
    }, [])

    useEffect(() => {
        //Sayfa ilk kez yüklenmiyorsa işlemi yap
        //Servise birden çok gez gitmesini engelliyoruz
        if (!firstVisit.current) {
            let todayDate = new Date();
            let tmpBeginDate = '';
            let tmpEndDate = '';

            datePickerRef.current.classList.add("hidden");

            switch (reminderDateRange.id) {
                case 1:
                    //Bugün seçildiyse 
                    tmpBeginDate = getddMMyyyyDate(todayDate);
                    tmpEndDate = tmpBeginDate;
                    break;
                case 2:
                    //Dün seçildiyse 
                    tmpBeginDate = getddMMyyyyDate(getDateWithDayDifference(todayDate, 1));
                    tmpEndDate = tmpBeginDate;
                    break;
                case 3:
                    //Geçmiş seçildiyse 
                    tmpBeginDate = getddMMyyyyDate(new Date(0));
                    tmpEndDate = getddMMyyyyDate(new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 1));
                    break;
                case 4:
                    //Gelecek seçildiyse 
                    tmpBeginDate = getddMMyyyyDate(new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() + 1));
                    tmpEndDate = getddMMyyyyDate(new Date(todayDate.getFullYear() + 50, todayDate.getMonth(), 0));
                    break;
                case 5:
                    //Tarih aralığı seçildiyse 
                    datePickerRef.current.classList.remove('hidden');
                    break;
            }

            (reminderDateRange.id < 5) && changeDetail?.({ reminderBeginDate: tmpBeginDate, reminderEndDate: tmpEndDate });

            firstVisit.current = false;
        }
    }, [reminderDateRange])

    useEffect(() => {
        //Sayfa ilk kez yüklenmiyorsa işlemi yap
        //Servise birden çok gez gitmesini engelliyoruz
        if (!firstVisit.current) {

            //Marka değişince seçili satış ve verimlilik personelini sıfırlıyoruz
            setSales(sales => firstSales);

            //Marka değiştiği için yeni marka ile satış bve verimlilik personeli servisine istek atıyoruz
            getSalesPerson(brand.id, firstSales, setSalesList);

            //Satış personeli getirme metotu 0 ile çalıştığı için id 0 gelirse -1 gönderiyoruz.
            changeDetail?.({ brandId: brand?.id === 0 ? -1 : brand?.id });
            firstVisit.current = false;
        }
    }, [brand])

    useEffect(() => {
        //Sayfa ilk kez yüklenmiyorsa işlemi yap
        //Servise birden çok gez gitmesini engelliyoruz
        if (!firstVisit.current) {
            changeDetail?.({ status: status?.id });
            firstVisit.current = false;
        }
    }, [status])

    useEffect(() => {
        //Sayfa ilk kez yüklenmiyorsa işlemi yap
        //Servise birden çok gez gitmesini engelliyoruz
        if (!firstVisit.current) {
            changeDetail?.({ salesPersonId: sales.id });
            firstVisit.current = false;
        }
    }, [sales])

    useEffect(() => {
        //Sayfa ilk kez yüklenmiyorsa işlemi yap
        //Servise birden çok gez gitmesini engelliyoruz
        if (!firstVisit.current) {
            changeDetail?.({ professionId: profession.id });
            firstVisit.current = false;
        }
    }, [profession])

    useEffect(() => {
        //Sayfa ilk kez yüklenmiyorsa işlemi yap
        //Servise birden çok gez gitmesini engelliyoruz
        if (!firstVisit.current) {
            changeDetail?.({ utmSource: memberResource?.id });
            firstVisit.current = false;
        }
    }, [memberResource])

    useEffect(() => {
        //Sayfa ilk kez yüklenmiyorsa işlemi yap
        //Servise birden çok gez gitmesini engelliyoruz
        if (!firstVisit.current) {
            changeDetail?.({ reminderReasonId: reminderReason?.id });
            firstVisit.current = false;
        }
    }, [reminderReason])

    useEffect(() => {
        //Sayfa ilk kez yüklenmiyorsa işlemi yap
        //Servise birden çok gez gitmesini engelliyoruz
        if (!firstVisit.current) {
            changeDetail?.({ dropPoolReasonId: dropPoolReason?.id });
            firstVisit.current = false;
        }
    }, [dropPoolReason])

    useEffect(() => {
        if(brandList.length > 1) {
          const selectedBrand = brandList.find(brand => brand.id === parseInt(employee.BrandId))
          setBrand(brand => selectedBrand)
        }
      }, [primeData.brandList])

      useEffect(() => {
        // Eğer kullanıcı Satış temsilcisi ise hatırlatma tarih aralığı Bugün olarak otomatik seçildikten sonra
        // öğrenci listenin açılmasını engelliyoruz.
        if(isSalesEmployee && reminderDateRange === dateRangeList[1]) {
            firstVisit.current = false;
        }
      }, [reminderDateRange])

    return (
        <>
            <DetailSearchLayout
                isDisabled={loading}
                children={
                    <div className="flex gap-x-4 gap-y-5 max-md-lg:justify-center flex-wrap">
                        <Dropdown
                            data={reminderDateRangeList}
                            selectedData={reminderDateRange}
                            setSelectedData={setReminderDateRange}
                            isDisabled={loading}
                        /> 
                        <div ref={datePickerRef} id="dateref" className='absolute top-[165px] hidden z-20'>
                            <button className='absolute button search-button !h-[25px] !p-2 top-[-28px] bg-red text-white focus:!border-none right-0'
                                onClick={() => datePickerRef.current.classList.add("hidden")}>X</button>
                            <DatePicker
                                selected={reminderBeginDate}
                                onChange={handleReminderDateRange}
                                onClickOutside={() => datePickerRef.current.classList.add("hidden")}
                                startDate={reminderBeginDate}
                                endDate={reminderEndDate}
                                selectsRange
                                inline
                            />
                        </div>
                        <Dropdown
                            data={brandList}
                            selectedData={brand}
                            setSelectedData={setBrand}
                            isDisabled={loading}
                        />
                        <Dropdown
                            data={memberFormStatusList}
                            selectedData={status}
                            setSelectedData={setStatus}
                            isDisabled={loading}
                        />
                        <Dropdown
                            data={salesList}
                            selectedData={sales}
                            setSelectedData={setSales}
                            tooltip={brand.id === -1 && strings.detail_search_titles.first_choose_brand}
                            isDisabled={brand.id === -1 ? true : loading}
                        />
                        <DropdownSearch
                            inputPlaceholder={strings.member.member_detail.profile.search_job}
                            data={professionList}
                            selectedData={profession}
                            setSelectedData={setProfession}
                            isDisabled={loading}
                        />
                        <Dropdown
                            data={memberResourceList}
                            selectedData={memberResource}
                            setSelectedData={setMemberResource}
                            isDisabled={loading}
                        />
                        <DropdownSearch
                            inputPlaceholder={strings.member.candidate.search_reminder_reason}
                            data={reminderReasonList}
                            selectedData={reminderReason}
                            setSelectedData={setReminderReason}
                            isDisabled={loading}                    
                        />
                        <DropdownSearch
                            inputPlaceholder={strings.member.candidate.search_drop_pool_reason}
                            data={dropPoolReasonList}
                            selectedData={dropPoolReason}
                            setSelectedData={setDropPoolReason}
                            isDisabled={loading}
                        />
                    </div>
                }
            />
        </>
    )
}

export default CandidateDetailSearch