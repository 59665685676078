import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Input, Modal, NoRecordsFound, PageTitle, showCenteredAlertTwoButtons, showTopAlert, SquareButton, Dropdown, showCenteredAlertTwoButtonsWithParameters, Select, Loading } from "../../../../../../components";
import { useLocalization } from "../../../../../../hooks/Utils/useLocalization";
import { ChangeMemberContactStatus, GetMemberContactData, SetPrimaryPhoneNumberOrEmail, UpdateContact } from "../../../../../../services/Member/MemberContactService";
import AddContactInformationModal from "./AddContactInformationModal";
import ReactIntlTelInput from 'react-intl-tel-input-v2';
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { ContactType } from "../../../../../../consts/Constants";
import {
  validPhoneNumber,
  validateEmail,
  isItSpecified,
  deleteSpacesInThePhoneNumber
} from "../../../../../../components/Utility";
import { SquareButtonWithoutLink } from "../../../../../../components/Buttons";
import ContactPermission from "./ContactPermission";
import useDataLayer from "../../../../../../hooks/Log/useDataLayer";
import {CustomEventDescriptions, CustomEvents} from "../../../../../../consts/DataLayer";

const ContactInformations = () => {

  const strings = useLocalization();
  const dataLayer = useDataLayer();
  const { memberId } = useParams() || false;

  const firstContactType = { id: -1, name: strings.member.member_detail.others.choose_contact_type };
  const contactTypeList = [firstContactType, ...ContactType];
  const [contactType, setContactType] = useState(firstContactType);

  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const [isDisabled, setIsDisabled] = useState([]);

  const [loading, setLoading] = useState(true);
  const [phoneNumberValue, setphoneNumberValue] = useState({ iso2: 'tr', dialCode: '90', phone: '' });
  const [contactTypeId, setContactTypeId] = useState(-1);
  const [contactId, setContactId] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const [address, setAddress] = useState(null);
  const [note, setNote] = useState(null);

  let inputProps = { placeholder: strings.employee.create_employee.enter_your_phone_number };
  const intlTelOpts = { preferredCountries: ['tr', 'az', 'sa'] };

  const closeButton = () => {
    setModal(false);
    getMemberContactData(memberId);
  }

  useEffect(() => {
    data.forEach(() => {
      setIsDisabled(prevState => [...prevState, true])
    })
  }, [data])

  //Mevcut iletişim bilgilerini getiriyoruz.
  const getMemberContactData = async (memberId) => {
    const result = await GetMemberContactData(memberId);
    if (result.status === 200) {
      setData(data => JSON.parse(result.content));
      setLoading(loading => false);
    }
  }

  const phoneNumberHandle = val => {
    if (val.dialCode !== phoneNumberValue.dialCode) {
      setphoneNumberValue({ iso2: val.iso2, dialCode: val.dialCode, phone: '' });
    } else {
      setphoneNumberValue(val);
    }
  };

  //Telefon numarası değiştiğinde adress değişkenine yeni telefon numarası ekliyoruz
  useEffect(() => {
    const formattedPhoneNumber = deleteSpacesInThePhoneNumber(phoneNumberValue.phone, phoneNumberValue.dialCode);
    phoneNumberValue.phone !== '' && setAddress(formattedPhoneNumber);
  }, [phoneNumberValue])

  //Eğer email değişirse address değişkenine yeni emaili ekliyoruz
  useEffect(() => {
    setAddress(emailValue);
  }, [emailValue])

  useEffect(() => {
    if (memberId) {
      getMemberContactData(memberId);
    }
  }, [])

  const handleDisabled = (index) => {
    return isDisabled[index];
  }

  const setPrimaryContact = async (contactTypeId, memberContactId, address) => {
    let valid = true;
    let error = '';

    const model = {
      address: address,
      contactTypeId: contactTypeId,
      memberId: memberId,
      memberContactId: memberContactId,
    };

    if (address.length === 0) {
      valid = false;
      error = strings.errors.address_null_error;
    }

    if (contactTypeId !== 1 && contactTypeId !== 2 && contactTypeId !== 3) {
      valid = false;
      error = strings.errors.contact_type_null_error;
    }

    if (valid) {
      const result = await SetPrimaryPhoneNumberOrEmail(model);
      if (result.status === 200) {
        getMemberContactData(memberId);
        showTopAlert(strings.member.member_detail.others.set_primary_updated, "success");
      } else {
        showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
      }

    } else {
      showTopAlert(error, "warning");
    }

  }

  const changeStatus = async (memberContactId, status) => {

    const model = {
      memberContactId: memberContactId,
      status: status
    };

    const result = await ChangeMemberContactStatus(model);
    if (result.status === 200) {
      getMemberContactData(memberId);
      showTopAlert(strings.member.member_detail.others.change_status_success, "success");
    } else {
      showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
    }

  }

  const sendToEndpoint = async () => {
    let valid = true;
    let error = '';

    const model = {
      address: address,
      contactTypeId: contactType,
      note: note,
      memberId: memberId,
      memberContactId: contactId,
    };

    if (contactType === -1) {
      valid = false;
      error = strings.alert.top_alert.inform;
    }

    if (contactType === 1 || contactType === 3) {
      if (!validPhoneNumber(address)) {
        valid = false;
        error = strings.errors.phone_number_format_error;
      }
    }

    if (contactType === 2) {
      if (!validateEmail(address)) {
        valid = false;
        error = strings.errors.email_format_error;
      }
    }

    if (valid) {
      const result = await UpdateContact(model);
      if (result.status === 200) {
        setIsDisabled([]);
        setAddress("");
        setEmailValue("");
        setphoneNumberValue({ iso2: 'tr', dialCode: '90', phone: '' });
        setContactTypeId(0);
        setContactId("");
        setContactType(firstContactType);
        getMemberContactData(memberId);

        //DATA LAYER LOGGED
        dataLayer.useCustomEvents(CustomEvents.EMPLOYEE_EVENTS, CustomEventDescriptions.CONTACT_INFORMATION_UPDATED);

        showTopAlert(strings.member.member_detail.others.contact_information_updated, "success");
      } else {
        showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
      }

    } else {
      showTopAlert(error, "warning");
    }
  }

  return (
    <>
      <PageTitle
        title={strings.member.member_detail.header.content.contact_informations}
      />

      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="mt-5 flex justify-end px-5">
            <div className="flex gap-3">
              <button
                onClick={() => {
                  setModal(true);
                  setModalContent({ element: "contactPermission" });
                }}
                className="button search-button gap-3 text-white bg-blue border-blue focus:border-blue"
              >
                <span className="material-symbols-outlined">done</span>
                <p>{strings.member.member_detail.others.contact_permission}</p>
              </button>
              <button
                onClick={() => {
                  setModal(true);
                  setModalContent({ element: "addContactInformation" });
                }}
                className="button search-button gap-3 text-white bg-green border-green focus:border-green"
              >
                <span className="material-symbols-outlined">add</span>
                <p>{strings.member.member_detail.others.add_contact_information}</p>
              </button>
            </div>
          </div>

          <div className="overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
            <div className="inline-block min-w-full rounded-lg overflow-hidden">
              <table className="min-w-full leading-normal text-header">
                <thead>
                  <tr>
                    <th className="th">
                      <p>{strings.table.contact_information}</p>
                    </th>
                    <th className="th">
                      <p>{strings.table.contact_type}</p>
                    </th>
                    <th className="th">
                      {strings.member.member_detail.candidate_operations.note}
                    </th>
                    <th className="th">
                      <p>{strings.table.is_first_address}</p>
                    </th>
                    <th className="th">
                      <p>{strings.table.operation}</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.length !== 0 ? (data.map((contact, index) => {
                    return (
                      <tr key={index} className="bg-white">
                        <td className="td align-middle">
                          <div className="!w-[300px]">
                            {( contactType === 1 || contactType === 3 ) ?
                              isDisabled[index] ? (
                                <div className="!h-[50px] flex items-center w-full">{contact.address}</div>
                              ) :
                                (
                                  <ReactIntlTelInput
                                    className="form-input w-full md:max-w-[480px]"
                                    inputStyle={{ color: 'green' }}
                                    inputProps={inputProps}
                                    intlTelOpts={intlTelOpts}
                                    value={phoneNumberValue}
                                    containerStyle={{ width: '480px' }}
                                    onChange={phoneNumberHandle}
                                    type="text"
                                    name="phone"
                                  />

                                ) : isDisabled[index] ? (
                                  <div className="!h-[50px] flex items-center w-full">{contact.address}</div>
                                ) : (
                                <Input
                                  id="contact_information"
                                  classnames="!h-[50px]"
                                  defaultValue={contactTypeId !== contactType ? "" : contact.address}
                                  isDisabled={handleDisabled(index)}
                                  onChange={(e) => setEmailValue(e.target.value)}
                                />
                              )}
                          </div>
                        </td>
                        <td className="td align-middle">
                          {isDisabled[index] ? (
                            <p className="!w-[160px]">
                              {contact.contactTypeId === 1 && strings.member.member_detail.others.phone}
                              {contact.contactTypeId === 2 && strings.member.member_detail.others.email}
                              {contact.contactTypeId === 3 && strings.member.member_detail.others.whatsapp}
                            </p>
                          ) : (
                            <Select
                              classnames="!w-[160px]"
                              options={contactTypeList}
                              selectedData={contactType}
                              setSelectedData={setContactType}
                            />
                          )}
                        </td>
                        <td className="td align-middle">
                          {isDisabled[index] ? (
                            <div className="!h-[50px] flex items-center w-[225px]">{isItSpecified(contact.note, strings.errors.it_is_not_specified)}</div>
                          ) : (
                            <Input
                              id="note"
                              classnames="!h-[50px] w-[225px]"
                              defaultValue={contact.note}
                              isDisabled={handleDisabled(index)}
                              onChange={(e) => setNote(e.target.value)}
                            />
                          )}

                        </td>
                        <td className="td align-middle">
                          <p className={`${contact.isContactAddress ? "font-bold" : "font-normal"}`}>{contact.isContactAddress ? strings.member.member_detail.others.first : strings.member.member_detail.others.other}</p>
                        </td>
                        <td className="td align-middle">
                          <div className="min-w-max">
                            <div className="flex gap-1">
                              {/* Eğer birincil yöntem değilse */}
                              {isDisabled[index] === true && isDisabled.every((val) => val === true) && (
                                <>
                                  <SquareButtonWithoutLink
                                    classnames="bg-blue"
                                    isDisabled={contact.isContactAddress}
                                    iconName="edit"
                                    tooltipClassnames="tooltip-top"
                                    action={() => {
                                      setIsDisabled(
                                        prevState => {
                                          const newState = [...prevState]
                                          newState[index] = false;
                                          return newState;
                                        }
                                      );
                                      if (contact.contactTypeId === 1 || contact.contactTypeId === 3) {
                                        let parsePhone = parsePhoneNumberFromString(contact.address);
                                        setphoneNumberValue({ iso2: parsePhone.country, dialCode: parsePhone.countryCallingCode, phone: parsePhone.nationalNumber })
                                      } else if (contact.contactTypeId === 2) {
                                        setEmailValue(contact.address);
                                      }
                                      setContactTypeId(contact.contactTypeId);
                                      setContactId(contact.id);
                                      let selectedContactType = contactTypeList.find(type => type.id === contact.contactTypeId);
                                      setContactType(selectedContactType);
                                    }
                                    }
                                    tooltip={contact.isContactAddress ? strings.errors.not_allowed : strings.button.update}
                                  />
                                  <SquareButtonWithoutLink
                                    classnames="bg-green"
                                    isDisabled={contact.isContactAddress}
                                    iconName="autorenew"
                                    tooltip={contact.isContactAddress ? strings.errors.not_allowed : strings.member.member_detail.others.set_first}
                                    tooltipClassnames="tooltip-top"
                                    action={() => showCenteredAlertTwoButtonsWithParameters(
                                      strings.alert.centered_alert_two_buttons.title,
                                      strings.alert.centered_alert_two_buttons.save_question,
                                      strings.button.confirm,
                                      strings.alert.centered_alert_two_buttons.cancel_text,
                                      setPrimaryContact,
                                      [contact.contactTypeId, contact.id, contact.address]
                                    )}
                                  />
                                  {( contact.status === 1 || contact.status === 2 ) ?
                                    <SquareButtonWithoutLink
                                      classnames="bg-red"
                                      isDisabled={contact.isContactAddress}
                                      iconName="do_not_disturb_on"
                                      tooltip={contact.isContactAddress ? strings.errors.not_allowed : strings.member.member_detail.others.set_able}
                                      tooltipClassnames="tooltip-top"
                                      isPermissionButton={false}
                                      action={() => showCenteredAlertTwoButtonsWithParameters(
                                        strings.alert.centered_alert_two_buttons.title,
                                        strings.alert.centered_alert_two_buttons.save_question,
                                        strings.button.confirm,
                                        strings.alert.centered_alert_two_buttons.cancel_text,
                                        changeStatus,
                                        [contact.id, 0]
                                      )}
                                    /> :
                                    <SquareButtonWithoutLink
                                      classnames="bg-warning"
                                      isDisabled={contact.isContactAddress}
                                      iconName="undo"
                                      tooltip={contact.isContactAddress ? strings.errors.not_allowed : strings.member.member_detail.others.set_disable}
                                      tooltipClassnames="tooltip-top"
                                      isPermissionButton={false}
                                      action={() => showCenteredAlertTwoButtonsWithParameters(
                                        strings.alert.centered_alert_two_buttons.title,
                                        strings.alert.centered_alert_two_buttons.save_question,
                                        strings.button.confirm,
                                        strings.alert.centered_alert_two_buttons.cancel_text,
                                        changeStatus,
                                        [contact.id, 1]
                                      )}
                                    />

                                  }
                                </>
                              )}
                              {/*Edit butonuna basılırsa*/}
                              {isDisabled[index] === false && (
                                <>
                                  <SquareButton
                                    classnames="bg-blue"
                                    iconName="check_circle"
                                    tooltip={strings.button.confirm}
                                    action={() => showCenteredAlertTwoButtons(
                                      strings.alert.centered_alert_two_buttons.title,
                                      strings.alert.centered_alert_two_buttons.save_question,
                                      strings.button.confirm,
                                      strings.alert.centered_alert_two_buttons.cancel_text,
                                      sendToEndpoint
                                    )}
                                    isPermissionButton={false}
                                  />
                                  <SquareButton
                                    classnames="bg-red"
                                    iconName="cancel"
                                    action={() => {
                                      setIsDisabled(
                                        prevState => {
                                          const newState = [...prevState]
                                          newState[index] = true;
                                          return newState;
                                        }
                                      );
                                      setAddress("");
                                      setEmailValue("");
                                      setphoneNumberValue({ iso2: 'tr', dialCode: '90', phone: '' });
                                      setContactTypeId(0);
                                      setContactId("");
                                      setContactType(firstContactType);
                                    }}
                                    tooltip={strings.button.cancel}
                                    isPermissionButton={false}
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    )
                  })
                  ) : (
                    <tr className="bg-white hover:bg-[#f0f0f0]">
                      <td className="td" colSpan={5}>
                        <NoRecordsFound />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
      {(Object.keys(modalContent).length !== 0) && (
        <Modal modal={modal} setModal={setModal} classes={{ modal: "h-[400px] max-w-[600px]" }}>
          {modalContent.element === "contactPermission" && <ContactPermission memberId={memberId} closeButton={closeButton} />}
          {modalContent.element === "addContactInformation" && <AddContactInformationModal memberId={memberId} closeButton={closeButton} />}
        </Modal>
      )}
    </>
  );
};

export default ContactInformations;
