import React, { useEffect, useState } from 'react';
import {ContentCount, Loading} from '../../components';
import { useLocalization } from '../../hooks/Utils/useLocalization';
import {GetTeacherNameByTeacherId, GetTeacherProgram} from '../../services/Teacher/TeacherService';
import {ProgramTable, TeacherProgramDetailSearch} from './components';
import useAuth from '../../hooks/Log/useAuth';
import { addTimeZone } from '../../components/Utility';
import Absent from "./components/Absent/Absent";

const TeacherProgram = () => {

  const strings = useLocalization();
  const { employee } = useAuth();

  const [scheduleData, setScheduleData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sendToEndpoint, setSendToEndpoint] = useState(false);
  const [isShowContent, setIsShowContent] = useState(false);
  const [teacherName, setTeacherName] = useState("");
  const [isCrashed, setIsCrashed] = useState(false);
  const [detailSearch, setDetailSearch] = useState({
    teacherId: -1,
    teacherName: ''
  });

  //select değişiklikleri bu alandan yönetiliyor
  const changeDetail = (values) => {
    setDetailSearch({ ...detailSearch, ...values });
  }

  const getProgram = async (teacherId) => {

    const result = await GetTeacherProgram(teacherId);

    let tmpScheduleData = [];

    if (result.status === 200) {
      let resultContent = JSON.parse(result.content);

      resultContent?.schedules?.map((schedule) => {
        let tmpScheduleTime = addTimeZone(schedule.time, employee.TimeZone);
        tmpScheduleData.push({ time: tmpScheduleTime, day: schedule.day, status: schedule.status });
      });

      setIsShowContent(isShowContent => true);
      setSendToEndpoint(sendToEndpoint => false);
      setScheduleData(scheduleData => tmpScheduleData);
    } else {
      setIsCrashed(isCrashed => true);
    }

    setLoading(loading => false);
  }

  const getTeacherNameById = async (teacherId) => {
    const result = await GetTeacherNameByTeacherId(teacherId);

    if(result.status === 200) {
      setTeacherName(teacherName => result.content);
    } else {
      setTeacherName(teacherName => detailSearch.teacherName);
    }
  }

  const sendRequestToEndpoint = () => {
    getProgram(detailSearch.teacherId)
  };

  useEffect(() => {
    if(sendToEndpoint) {
      setLoading(true)
      sendRequestToEndpoint();
    }
  }, [sendToEndpoint])

  useEffect(() => {
    if(detailSearch.teacherId !== -1) {
     getTeacherNameById(detailSearch.teacherId);
    }
  }, [detailSearch.teacherId]);

  return (
    <>
      <TeacherProgramDetailSearch
        changeDetail={changeDetail}
        setSendToEndpoint={setSendToEndpoint}
        setIsShowContent={setIsShowContent}
        loading={loading}
      />

      {/* Eğitmen Devamsızlıkları */}

      <Absent
        teacherId={detailSearch.teacherId}
        sendToEndpoint={sendToEndpoint}
        isShowContent={isShowContent}
      />

      <p className={`my-2 text-[14px] min-h-[35px] md:leading-[35px] text-base-text bg-card px-5 rounded-md flex flex-col md:flex-row md:justify-between max-md:mb-20`}>
        <span className='font-bold'>{strings.sidebar.teacher.teacher_program}</span>
        {(scheduleData.length > 0) && <span>{teacherName} - {detailSearch.teacherId}</span>}
      </p>
      {loading ? (
        <Loading />
      ) : (
        <>
          {isCrashed && (
              <div className='py-4 min-w-full text-center'>{strings.errors.an_error_occured_refresh_page}</div>
          )}

          {!isCrashed && (
              (scheduleData.length > 0) ? (
              <>
                <ContentCount
                  classnames="scale-y-0"
                  blueText={strings.teacher.teacher_program.lesson_text}
                  whiteText={strings.teacher.teacher_program.empty_text}
                  warningText={strings.teacher.teacher_program.blocked_text}
                  purpleText={strings.teacher.teacher_program.holiday_text}
                  redText={strings.teacher.teacher_program.temporary_blocked_text}
                  dangerText={strings.teacher.teacher_program.missed_call_text}
                />
                <ProgramTable scheduleData={scheduleData} teacherId={detailSearch.teacherId} sendRequestToEndpoint={sendRequestToEndpoint} />
              </>
            ) : (!isShowContent) ? (
              <div className='py-4 min-w-full text-center'>{strings.teacher.teacher_program.select_teacher_to_see}</div>
            ) : (
              <div className="py-4 min-w-full text-center">{strings.teacher.teacher_program.shift_not_found}</div>
            )
          )}
        </>
      )}
    </>
  )
};

export default TeacherProgram;