import { fetchApi } from "../FetchApi";

export const MemberNotesFromClickivo = async (model) => {

    const url = '/member/v1/member_note/get_member_note_from_clickivo/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}