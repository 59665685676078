import React, { useState, useEffect } from 'react'
import { useLocalization } from '../../../../hooks/Utils/useLocalization';
import { BreakList, DeleteBreak } from '../../../../services/Teacher/TeacherService';
import { showCenteredAlertTwoButtons, showTopAlert } from '../../../../components';
import { addTimeZone, calculateEndTime } from '../../../../components/Utility';
import useAuth from '../../../../hooks/Log/useAuth';

const TemproraryBlockedLesson = (props) => {

  const { day, time, teacherId, sendRequestToEndpoint } = props;
  const strings = useLocalization();
  const { employee } = useAuth(); 

  const [breakData, setBreakData] = useState({})

  let firstTemproraryBlockedDay = -1;
  for (let i = 0; i < 7; i++) {
      if (document.getElementById(`${time}_${i}_temprorary_blocked`)) {
          firstTemproraryBlockedDay = i;
          break;
      }
  }

  //Eğitmenin blok listesini getirir.
  const getBreakList = async (teacherId) => {

      const result = await BreakList(teacherId);

      if (result.status === 200) {
          let resultContent = JSON.parse(result.content);

          const filteredResult = resultContent.find((item) => item.status === 1 && item.blockType === 6 && addTimeZone(item.blockTime, employee.TimeZone) === time);
          setBreakData(breakData => filteredResult);
      }
  }

  const deleteBlock = async () => {

      let [hour, minute] = breakData?.blockTime.split(':');

      const model = {
          teacherId: parseInt(teacherId),
          beginTime: `${hour}:${minute}`,
          endTime: calculateEndTime(breakData?.blockTime, breakData?.duration),
          endDate: breakData?.blockEndDate,
          day: day,
          status: breakData?.blockType,
          duration: breakData?.duration,
          blockTypeStatus: breakData?.blockType
      }

      const result = await DeleteBreak(model);

      if(result.status === 200){
          showTopAlert(strings.teacher.add_break.temporary_block_delete_success_message, "success");
          sendRequestToEndpoint();
      } else {
          showTopAlert(strings.errors.an_error_occurred, "error");
      }
  }

  useEffect(() => {
      getBreakList(teacherId)
  }, [teacherId])

  return (
      <>
          <div className="temprorary-blocked-lesson min-h-[60px] -m-[1px] flex gap-1">
              <>
                  {firstTemproraryBlockedDay === day && (breakData && Object.keys(breakData).length > 0) && (
                      <>
                          <button
                              className="flex-shrink-0 group inline-flex items-center justify-center bg-transparent border border-white bg-card text-base-text search-button w-5 h-5 rounded-[3px] relative"
                              onClick={() => {
                                  showCenteredAlertTwoButtons(
                                      strings.alert.centered_alert_two_buttons.title_confirm,
                                      strings.teacher.add_break.temporary_block_delete_confirm_question,
                                      strings.button.confirm,
                                      strings.button.cancel,
                                      deleteBlock
                                  );
                              }} 
                          >
                              <span className="material-symbols-outlined text-white text-[14px]">delete</span>
                          </button>
                          <p className='text-white'>{strings.teacher.teacher_program.temporary_blocked}</p>
                      </>
                  )}
              </>
          </div>
      </>
  )
};

export default TemproraryBlockedLesson;