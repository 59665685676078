import { createSlice } from "@reduxjs/toolkit";
import {alotechCookieName} from "../consts/Constants";
import { setCookie, getCookie, removeCookie } from 'ko-basic-cookie';

export const AlotechSlice = createSlice({
    name: 'alotech',
    initialState: { alotechToken: getCookie(alotechCookieName) || "" },
    reducers: {
        alotechLogin: (state, action) => {
            state.alotechToken = action.payload.alotechToken;
            setCookie(alotechCookieName, state.alotechToken, 1);
        },
        alotechLogout: (state, action) => {
            state.alotechToken = '';
            removeCookie(alotechCookieName);
        },
    },
})

export const { alotechLogin, alotechLogout } = AlotechSlice.actions

export default AlotechSlice.reducer