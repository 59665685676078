import React, { useState, useEffect } from 'react';
import { PageTitle, Pagination, SearchBar, ContentCount, Loading, showTopAlert } from '../../components';
import {getddMMyyyyDate, stringToDate} from '../../components/Utility'
import { useLocalization } from '../../hooks/Utils/useLocalization';
import { PaymentFilter } from '../../services/Accounting/PaymentService';
import { PaymentDetailSearch } from './components';
import PaymentTable from './components/Payment/PaymentTable';
import useAuth from '../../hooks/Log/useAuth';
import NotAuthorized from '../../components/NotAuthorized';

const Payment = () => {

    const strings = useLocalization();

    const { getPermission, checkPermission, employeeTypeId } = useAuth();
    const roles = getPermission();
    const permission = checkPermission(roles, employeeTypeId);

    const limit = parseInt(process.env.REACT_APP_TABLE_ROW_LIMIT);
    const [start, setStart] = useState(0);
    const [totalRecord, setTotalRecord] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);

    const [data, setData] = useState([]);
    const [succeedPaymentCount, setSucceedPaymentCount] = useState(null);
    const [failedPaymentCount, setFailedPaymentCount] = useState(null);

    const [detailSearch, setDetailSearch] = useState({
        beginDate: getddMMyyyyDate(new Date()),
        endDate: getddMMyyyyDate(new Date()),
        status: -1,
        searchText: ''
    })

    //Arama verileri değiştiğinde değerleri sıfırlıyoruz
    const resetValue = () => {
        setStart(start => 0);
        setCurrentPage(currentPage => 1);
        setLoading(loading => true);
    }

    //select değişiklikleri bu alandan yönetiliyor
    const changeDetail = (values) => {
        setDetailSearch({ ...detailSearch, ...values });
        resetValue();
    }

    //input değişiklikleri bu alandan yönetiliyor
    const changeValue = (value) => {
        setDetailSearch({ ...detailSearch, searchText: value });
        resetValue();
    }

    const getPayment = async () => {
        const model = {
            start: start,
            length: limit,
            accountingStatusType: detailSearch.status,
            beginDate: detailSearch.beginDate ? stringToDate(detailSearch.beginDate, 0, 0, 0) : null,
            endDate: detailSearch.endDate ? stringToDate(detailSearch.endDate, 23, 59, 59) : null,
            memberName: detailSearch.searchText.length !== 0 ? detailSearch.searchText : null
        }

        const result = await PaymentFilter(model);

        if (result.status === 200) {
            let resultContent = JSON.parse(result.content);

            setData(data => resultContent.data);
            setTotalRecord(totalRecord => resultContent.recordsTotal);
            setSucceedPaymentCount(succeedPaymentCount => resultContent.succeedPaymentCount);
            setFailedPaymentCount(failedPaymentCount => resultContent.failedPaymentCount);
            setLoading(loading => false);
        } else {
            showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
        }
    }

    //sayfa değiştikçe bilgileri yeniden çağırıyoruz
    useEffect(() => {
        if (totalRecord !== 0) {
            getPayment();
        }
    }, [start])

    useEffect(() => {

        let abortController = new AbortController();

        // Kullanıcının sayfa görüntüleme izni yoksa servise gitmeyip performans kaybını önlüyoruz
        permission && getPayment();

        return () => {
            abortController.abort();
        }
    }, [detailSearch, permission])

    return (
        <>
            {permission && (
                <>
                    <PaymentDetailSearch
                        changeDetail={changeDetail}
                        loading={loading}
                    />
                    <SearchBar
                        changeValue={changeValue}
                        placeHolder={strings.search_bar.search_payment}
                        isDisabled={loading}
                    />
                    {!loading &&
                        <ContentCount
                            greenText={strings.accounting.payment.payment_success_count}
                            greenCount={succeedPaymentCount}
                            redText={strings.accounting.payment.payment_error_count}
                            redCount={failedPaymentCount}
                        />
                    }
                </>
            )}
            <PageTitle title={strings.sidebar.accounting.payments} />
            {checkPermission(roles, employeeTypeId) ? (
                loading ?
                    (
                        <Loading />
                    )
                    :
                    (
                        <>
                            <PaymentTable data={data} />
                            <Pagination
                                totalCount={totalRecord}
                                limit={limit}
                                start={start}
                                setStart={setStart}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                setLoading={setLoading}
                                loadScreen={true}
                            />
                        </>
                    )
            ) : (<NotAuthorized />)}

        </>
    );
}

export default Payment;