import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import {
    DropdownSearch,
    LoadingSpinner,
    showCenteredAlertTwoButtons,
    showTopAlert
} from '../../../../../../../../components';
import { useLocalization } from '../../../../../../../../hooks/Utils/useLocalization';
import { CheckUnitUpdateDailyBook, GetBookUnits, GetTermUnits, UpdateTermScheduleBook } from '../../../../../../../../services/Term/TermService';
import {ComponentViewLogs} from "../../../../../../../../consts/Logs";
import useAnalytics from "../../../../../../../../hooks/Log/useAnalytics";
import useAuth from "../../../../../../../../hooks/Log/useAuth";
import {ServiceErrorMessages} from "../../../../../../../../consts/Constants";

const UpdateUnitModal = (props) => {

  const { termId, memberId, setModal, setModalContent, setModalSize, closeButton } = props;

  const strings = useLocalization();
  const { employee } = useAuth();
  const analytics = useAnalytics();

  const [books, setBooks] = useState([]);
  const firstBook = { id: -1, name: strings.member.member_detail.terms.operation_modal.books };
  const bookList = [firstBook, ...books];

  const [units, setUnits] = useState([]);
  const firstUnit = { id: -1, name: strings.member.member_detail.terms.operation_modal.units };
  const unitList = [firstUnit, ...units];
  
  const [book, setBook] = useState(firstBook);
  const [unit, setUnit] = useState(firstUnit);
  const [isOnlyToday, setIsOnlyToday] = useState(false);
  const [termScheduleBookId, setTermScheduleBookId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showWarningMessage, setShowWarningMessage] = useState(false)

  const getTermUnits = async (termId) => {

    const result = await GetTermUnits(termId);

    if(result.status === 200){
        let resultContent = JSON.parse(result.content);

        let arrayList = [];

        resultContent?.bookList.map((item) => {
            arrayList.push({ id: item.value, name: item.text });
        });

        setBooks(books => arrayList);
        setTermScheduleBookId(termScheduleBookId => resultContent.firstTermScheduleBookId);
    }
  }
  
  const getBookUnits = async (bookId) => {

    const result = await GetBookUnits(bookId);

    if(result.status === 200){
        let resultContent = JSON.parse(result.content);
        let arrayList = [];

        resultContent?.map((item) => {
            arrayList.push({ id: item.id, name: item.title });
        });

        setUnits(units => arrayList);
    }
  }

  const checkIsValid = () => {

    let valid = true;

    if(book.id === -1){
        valid = false;
        showTopAlert(strings.alert.top_alert.inform, "warning");
    }

    if(unit.id === -1){
        valid = false;
        showTopAlert(strings.alert.top_alert.inform, "warning");
    }

    if(valid){
        showCenteredAlertTwoButtons(
            strings.alert.centered_alert_two_buttons.title_confirm,
            strings.member.member_detail.terms.operation_modal.update_unit_question,
            strings.button.confirm,
            strings.alert.centered_alert_two_buttons.cancel_text,
            sendToEndpoint
        );
    }
  }
  
  const sendToEndpoint = async () => {
    setLoading(loading => true);

    const model = {
        termId: termId,
        termScheduleBookId : termScheduleBookId,
        onlyTodayUpdate : isOnlyToday,
        bookUnitId : unit.id,
        employeeId: employee.EmployeeId
    };

    const result = await UpdateTermScheduleBook(model);

    if(result.status === 200){
        showTopAlert(strings.member.member_detail.terms.operation_modal.update_unit_success, "success");
        setTimeout(() => {setModal(true); setModalContent({ element: "units" }); setModalSize({ size: "middle" });}, 3000);
    } else {

        if(result.content === ServiceErrorMessages.NOT_IN_TIME_RANGE) {
            showTopAlert(strings.member.member_detail.terms.operation_modal.update_unit_error_message, "error");
        } else {
            showTopAlert(strings.errors.an_error_occurred, "error");
        }
    }

    setLoading(loading => false);
  }
  
  const checkUnitUpdateDailyBook = async () => {

    const result = await CheckUnitUpdateDailyBook(unit.id);

    // Eğer servisten true cevabı gelirse bir uyarı mesajı çıkartıyoruz.
    if(JSON.parse(result).status === 200) {
        if(JSON.parse(JSON.parse(result).content)) {
            setShowWarningMessage(false)
        } else {
            setShowWarningMessage(true)
            showTopAlert(strings.member.member_detail.terms.operation_modal.no_lesson_book_error_message, "warning");
        }
    }
  }

  useEffect(() => {
    if(termId){
        getTermUnits(termId);
    }

    //LOGGED
    analytics.useAnalytics(ComponentViewLogs.UPDATE_UNIT_COMPONENT, {memberId: memberId});
  }, [])

  useEffect(() => {
    if(book.id !== -1){
        getBookUnits(book.id);
    }

    setUnit(firstUnit);
  }, [book])

  useEffect(() => {
    if(unit.id !== -1 ) {
        checkUnitUpdateDailyBook(unit.id)
    }
  },[unit])

  return (
    <>
        <div className='flex flex-col text-start gap-5 h-[500px]'>
            <div className="flex justify-center gap-1">
                <p className="font-bold text-[15px]">{strings.member.member_detail.terms.update_unit}</p>
            </div>
            <div className="flex flex-col px-[10px] pb-0 w-full">
                <label className='mb-2 font-bold'>
                    {strings.member.member_detail.terms.operation_modal.book} <span className="text-red">*</span>
                </label>
                <DropdownSearch
                    inputPlaceholder={strings.member.member_detail.terms.operation_modal.search_book}
                    classnames="md:max-w-full"
                    data={bookList}
                    selectedData={book}
                    setSelectedData={setBook}
                />
            </div>
            <div className="flex flex-col px-[10px] pb-0 w-full">
                <label className='mb-2 font-bold'>
                    {strings.member.member_detail.terms.operation_modal.unit} <span className="text-red">*</span>
                </label>
                <DropdownSearch
                    inputPlaceholder={strings.member.member_detail.terms.operation_modal.search_unit}
                    classnames="md:max-w-full"
                    isDisabled={book.id === -1}
                    data={unitList}
                    selectedData={unit}
                    setSelectedData={setUnit}
                />
            </div>
            {/*<div className="flex flex-col px-[10px] pb-0 w-full">*/}
            {/*    <label className='mb-2 font-bold'>{strings.member.member_detail.terms.operation_modal.change_only_today}</label>*/}
            {/*    <div className="flex justify-start items-center px-2">*/}
            {/*        <input*/}
            {/*            type="checkbox"*/}
            {/*            id="isActive1"*/}
            {/*            className="hidden"*/}
            {/*            onClick={() => setIsOnlyToday(isOnlyToday => !isOnlyToday)}*/}
            {/*        />*/}
            {/*        <label*/}
            {/*            htmlFor="isActive1"*/}
            {/*            className={`${handleCheckbox(isOnlyToday)} mr-2 w-5 h-5 rounded-[3px] cursor-pointer duration-500 flex items-center justify-center`}*/}
            {/*        >*/}
            {/*        <span className={classNames('text-inherit text-[20px] material-symbols-outlined animate-fadeIn font-bold', { 'hidden' : !isOnlyToday})}>*/}
            {/*            done*/}
            {/*        </span>*/}
            {/*        </label>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="flex gap-3 justify-center relative z-0">
                <button 
                    className={classNames("group button search-button gap-3 text-white bg-blue border-blue focus:border-blue mt-3 px-10 w-[130px]", {
                        "opacity-70 hover:opacity-70 cursor-not-allowed" : loading
                    })}
                    disabled={loading}
                    onClick={() => checkIsValid()}
                >
                    {loading && <LoadingSpinner />}
                    {!loading && strings.button.save_upper}
                </button>
                <button
                    type="button"
                    className={classNames("button search-button gap-3 text-white bg-red border-red focus:border-red mt-3 px-10", {
                        "opacity-70 cursor-not-allowed" : loading
                    })}
                    onClick={closeButton}
                    disabled={loading}
                >
                    {strings.button.cancel_upper}</button>
            </div>
            {showWarningMessage && unit.id !== -1 &&
                <blockquote className="border-l-4 border-warning p-4 mb-4 bg-warning/10 rounded-md flex gap-2 items-end">
                    <span class="material-symbols-outlined text-[30px]">
                        warning 
                    </span>
                    <p className='-translate-y-[5px]'><span className='font-bold'>{strings.member.member_detail.candidate_operations.note}: </span>{strings.member.member_detail.terms.operation_modal.no_lesson_book_error_message}</p>
                </blockquote>
            }
            
        </div>
    </>
  )
};

export default UpdateUnitModal;