import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { PageTitle } from "../../../../../components";
import useAuth from "../../../../../hooks/Log/useAuth";
import { useLocalization } from "../../../../../hooks/Utils/useLocalization";
import { url } from "../../../../../routes/utility";
import { GetReferencedMembers } from "../../../../../services/Member/MemberReferenceService";
import ReferenceInfo from "./ReferenceInfo";
import { ISOStringToDate } from "../../../../../components/Utility";

const ReferencedMember = () => {
  const { memberId } = useParams() || false;
  const [referencedMembers, setReferencedMembers] = useState({});
  const { employee } = useAuth();

  const strings = useLocalization();

  const getReferencedMembers = async (memberId) => {
    const result = await GetReferencedMembers(memberId);
    
    if (result.status === 200) {
      setReferencedMembers(referencedMembers => JSON.parse(result.content));
    }
  }

  const getReferenceStatus = (referenceType) => {
    switch (referenceType) {
      case 1:
        return strings.member.member_detail.referenced.referenced_status_active;
      case 2:
        return strings.member.member_detail.referenced.referenced_status_paid;
      default:
        return strings.member.member_detail.referenced.referenced_status_passive;
    }
  }

  useEffect(() => {
    if (memberId) {
      getReferencedMembers(memberId);
    }
  }, [])

  return (
    <>
      <PageTitle title={strings.member.member_detail.referenced.title} />
      <div className="py-3 flex flex-col lg:flex-row gap-4 md:gap-16">
        <ReferenceInfo memberId={memberId} />
        <div className="lg:w-[65%]">
          <div className="overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
            <div className="inline-block min-w-full rounded-lg overflow-hidden">
              <table className="min-w-full leading-normal text-header mb-1 overflow-hidden">
                <thead>
                  <tr>
                    <th className="th bg-card text-base-text">
                      {strings.table.member_informations}
                    </th>
                    <th className="th bg-card text-base-text">
                      {strings.table.date}
                    </th>
                    <th className="th bg-card text-base-text">
                      {strings.form_info.status}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(referencedMembers).length > 0 ? (
                    referencedMembers.map((member, index) => (
                      <tr key={index} className="bg-white hover:bg-[#f0f0f0]">
                        <td className="td align-middle">
                          <div className="min-w-[120px]">
                            <Link
                              to={url("member.detail.profile", { memberId: member.memberId })}
                              target="_blank"
                              className="py-3 font-bold text-blue hover:underline focus:underline"
                            >
                              {member.fullname}
                            </Link>
                          </div>
                        </td>
                        <td className="td">
                          <p className="py-3 min-w-[122px]">{ISOStringToDate(member.referenceDate, parseInt(employee.TimeZone))}</p>
                        </td>
                        <td className="td">
                          <p className="py-3 min-w-[122px]">{getReferenceStatus(member.referenceStatusType)}</p>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="bg-white hover:bg-[#f0f0f0]">
                      <td className="td" colSpan={3}>
                        <p className="py-3 min-w-full text-center">
                          {
                            strings.member.member_detail.referenced
                              .no_referenced_message
                          }
                        </p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReferencedMember;
