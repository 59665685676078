import { fetchApi } from '../index';

export const GetAppLessonTypes = async (brandAppId) => {
    
    const url = '/member/v1/app_lesson_type/get_app_lesson_types_by_brand_app_id/';

    const payload = {
        brandAppId: brandAppId
    };

    return await fetchApi(url, payload);
}