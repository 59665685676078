import React from 'react';
import {Link} from 'react-router-dom';
import {url} from '../../../routes/utility';
import {
    getBrandNameById,
    getLogoByBrandId,
    isItSpecified,
    ISOStringToDate,
    maskPhoneNumber
} from '../../../components/Utility';
import useAuth from '../../../hooks/Log/useAuth';
import {useLocalization} from '../../../hooks/Utils/useLocalization';
import {CopyToClipboard, SquareButton} from '../../../components';
import {useSelector} from "react-redux";
import useClick2Call from "../../../hooks/Utils/useClick2Call";
import classNames from "classnames";
import {Brands} from "../../../consts/Constants";

const TaskTableRow = (props) => {
  
  const { task, setModal, setModalData } = props;
  const strings = useLocalization();
  const { employee } = useAuth();
  const alotechSessionKey = useSelector(state => state.alotechStore.alotechToken);
  const { handleClick2Call } = useClick2Call(task?.member.phoneNumber, task?.member.fullName, alotechSessionKey);
  const isDisabledStartTask = task.status === 0 || task.status === 3;

  const fillModalData = () => {
    setModalData({
        memberId: task.member.id,
        memberName: task.member.fullName,
        taskId: task.id,
        taskMessage: task.taskMessage,
        showAiAnalysis: task?.isTaskCompatibleToAiAnalysis
    })
  };

  return (
      <tr className="bg-white hover:bg-[#f0f0f0]">
            <td className='td align-middle'>
                <div className="relative group max-w-max ml-6">
                    <img src={getLogoByBrandId(task.brand.id)} width="43" height="48" />
                    <div className="tooltip-top">
                        <p>{getBrandNameById(task.brand.id)}</p>
                    </div>
                </div>
            </td>
            <td className="td">
                <div className="flex items-center mt-[6px] gap-1">
                    <span className="material-symbols-outlined flex-shrink-0 text-[19px]">
                        person
                    </span>
                    <Link
                        to={url("member.detail.profile", {memberId: task.member.id})}
                        target="_blank"
                        className="font-bold text-blue hover:underline focus:underline"
                    >
                        {task.member.fullName}
                    </Link>
                    <div
                        className="px-[15px] py-[4px] inline-flex justify-center items-center text-[11px] text-white bg-green min-w-max rounded-full">
                        {task.taskType}
                    </div>
                </div>
                <hr className="my-2"/>
                <p className='min-w-[400px]'>
                    <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px] align-top">
                        comment
                    </span>
                    <span>{task.taskMessage}</span>
                </p>
                <div className="flex items-center mt-[6px]">
                    <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
                        calendar_month
                    </span>
                    <p>{task.createUser} - {ISOStringToDate(task.createDate, parseInt(employee.TimeZone))}</p>
                </div>
                <div className="flex items-center mt-[6px] gap-1">
                    <div className="relative group">
                        <button
                            type="button"
                            className={classNames("material-symbols-outlined mt-1", {
                                "text-[14px] cursor-pointer text-white bg-green rounded-full p-[3px]"
                                    : (alotechSessionKey.length > 0 && Brands.KO === task.brand.id),
                                "text-[19px] cursor-default"
                                    : !(alotechSessionKey.length > 0 && Brands.KO === task.brand.id)
                            })}
                            onClick={() => (alotechSessionKey.length > 0 && Brands.KO === task.brand.id) && handleClick2Call()}
                        >
                            call
                        </button>
                        {alotechSessionKey.length > 0 && Brands.KO === task.brand.id &&
                            <div className="tooltip-top">{strings.member.member_detail.profile.call}</div>
                        }
                    </div>
                    <div className="flex items-center gap-0.5">
                        <p className="min-w-max">{maskPhoneNumber(task.member.phoneNumber)}</p>
                        <CopyToClipboard text={task.member.phoneNumber} className="-mb-1" iconClassName="!text-[13px]"/>
                    </div>
                </div>
            </td>
            <td className="td align-middle">
                <div className="badge my-1 w-[320px]">
                    <p>
                        <b>{strings.table.operation_person_upper}: </b>{isItSpecified(task.taskUser.fullName, strings.errors.it_is_not_specified)}
                    </p>
                </div>
                <div className="badge my-1 w-[320px]">
                    {/* Görev bitiş tarihine time zone ekleyip sadece tarihi aldık. Saati çıkardık */}
                    <p>
                        <b>{strings.member.member_detail.tasks.task_date_upper}: </b>{ISOStringToDate(task.dueDate, employee.TimeZone).split("/")[0]}</p>
                </div>
                <div className="badge my-1 w-[320px]">
                    <p><b>{strings.member.member_detail.tasks.postpone_task_count_upper}: </b>{task.postponeCount}</p>
                </div>
            </td>
            <td className="td align-middle">
                <SquareButton 
                    classnames={`bg-blue ${isDisabledStartTask ? "opacity-50 !cursor-not-allowed hover:opacity-50" : ""}`}
                    iconName="play_arrow"
                    iconClassnames={`text-[22px] ${isDisabledStartTask ? "opacity-50 !cursor-not-allowed" : ""}`}
                    tooltip={isDisabledStartTask ? strings.errors.not_allowed : strings.member.member_detail.tasks.start_task}
                    isPermissonButton={false}
                    action={() => { 
                        if(!isDisabledStartTask){
                            setModal(true); fillModalData();
                        }
                    }}
                />
            </td>
        </tr>
  )
}

export default TaskTableRow