import React, { useEffect, useState } from "react";
import { useLocalization } from "../../../../../hooks/Utils/useLocalization";
import { Loading, Modal, PageTitle } from "../../../../../components";
import TaskTableRow from "./TaskTableRow";
import StartTaskModal from "./StartTaskModal";
import { useParams } from "react-router-dom";
import { GetMemberDetailTaskList } from "../../../../../services/Task/TaskService";
import useAuth from "../../../../../hooks/Log/useAuth";

const CurrentTasks = () => {
  const strings = useLocalization();

  const { memberId } = useParams() || false;
  const { employee } = useAuth() || false;

  const [taskList, setTaskList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState({});

  const getActiveAndPostponedTaskList = async (memberId, employeeId) => {

    const result = await GetMemberDetailTaskList(memberId, employeeId, true);

    if (result.status === 200) {
      setTaskList((taskList) => JSON.parse(result.content));
      setLoading(loading => false);
    }
  };

  const closeTaskOperations = () => {
    setTimeout(() => setModal(false), 3000);
    getActiveAndPostponedTaskList(memberId);
  };

  const sendRequest = () => {
    getActiveAndPostponedTaskList(memberId);
  };

  useEffect(() => {
    if (memberId && employee) {
      getActiveAndPostponedTaskList(memberId, employee.EmployeeId);
    }
  }, []);

  return (
    <>
      <PageTitle
        title={strings.member.member_detail.header.content.active_tasks}
      />
      {loading ? (
        <Loading />
      ) : (
        <div className="overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
          <div className="inline-block min-w-full rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal text-header">
              <thead>
                <tr>
                  <th className="th">{strings.table.operation_informations}</th>
                  <th className="th">{strings.table.task_information}</th>
                  <th className="th">{strings.table.operation_person}</th>
                  <th className="th">{strings.table.operation}</th>
                </tr>
              </thead>
              <tbody>
                {taskList.length > 0 ? (
                  taskList.map((task, index) => (
                    <TaskTableRow
                      key={index}
                      data={task}
                      setModal={setModal}
                      setModalData={setModalData}
                    />
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" className="py-4 text-center">
                      {strings.member.member_detail.tasks.not_found_active_task}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}

      <Modal
        modal={modal}
        setModal={setModal}
        classes={{ modal: "h-[90vh] max-w-[1140px]", content: "max-md:!mr-0" }}
      >
        <StartTaskModal
          data={modalData}
          closeTask={closeTaskOperations}
          sendRequest={sendRequest}
          classes="!h-[85vh]"
        />
      </Modal>
    </>
  );
};

export default CurrentTasks;
