import {useLocalization} from "../../../../hooks/Utils/useLocalization";
import {useEffect, useState} from "react";
import {GetReasonToLearnEnglishes} from "../../../../services/Member/MemberService";
import {Loading} from "../../../../components";
import classNames from "classnames";
import {getUseCaseById} from "../../../../components/Utility";

const ReasonToLearnEnglishModal = ({ memberId }) => {

    const strings = useLocalization();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        GetReasonToLearnEnglishes(memberId)
            .then(result => {
                if(result.status === 200) {
                    setData(JSON.parse(result.content));
                }
                setLoading(false);
            })
            .catch()
    }, [])

    return (
        <div className="flex flex-col gap-2">
            <h3 className="font-bold text-center">{strings.member.member_detail.profile.all_reason_to_learn_english}</h3>
            {loading && <Loading />}

            {!loading &&
                <ul>
                    {data.map((useCase, index) => (

                        <div key={index}>
                            <li
                                className="py-1 leading-5 ml-3 list-disc"
                            >
                                {getUseCaseById(useCase.reasonToLearnEnglishId)}
                            </li>
                            {data.length - 1 !== index &&
                                <hr className="my-1 border border-md-gray" />
                            }
                        </div>
                    ))}
                </ul>
            }
        </div>
    )
}

export default ReasonToLearnEnglishModal;