import React, { useState } from 'react'
import { TermStatusType } from '../../../../../../../consts/Constants';
import { useLocalization } from '../../../../../../../hooks/Utils/useLocalization';

const TermButton = (props) => {

    const { termStatus, setSelectedTermButtons, selectedTermButtons } = props;
    const strings = useLocalization();

    const backgroundColor = (termStatus) => {
        switch (termStatus) {
            case TermStatusType.Active:
                return 'bg-green border-green';
            case TermStatusType.Future:
                return 'bg-blue border-blue';
            case TermStatusType.Passive:
                return 'bg-red border-red';
            case TermStatusType.Pending:
                return 'bg-warning border-warning';
            case TermStatusType.Deleted:
                return 'bg-dark-text border-dark-text';
            default:
                return ''
        }
    }

    const buttonText = (termStatus) => {
        switch (termStatus) {
            case TermStatusType.Active:
                return strings.member.member_detail.terms.active_term;
            case TermStatusType.Future:
                return strings.member.member_detail.terms.future_term;
            case TermStatusType.Passive:
                return strings.member.member_detail.terms.passive_term;
            case TermStatusType.Pending:
                return strings.member.member_detail.terms.pending_term;
            case TermStatusType.Deleted:
                return strings.member.member_detail.terms.deleted_term;
            default:
                return '';
        }
    }

    return (
        <button
            className={`${backgroundColor(termStatus)} relative md:flex-shrink-0 w-full md:w-[246px] rounded-full h-10 flex items-center justify-center text-white cursor-pointer search-button`}
            onClick={() => setSelectedTermButtons(termStatus) }
        >
            {buttonText(termStatus)}
            {selectedTermButtons === termStatus && (
                <span className="absolute right-4 top-0 bottom-0 flex items-center material-symbols-outlined text-white cursor-pointer animate-fadeIn">
                    check
                </span>
            )}
        </button>
    )
};

export default TermButton;