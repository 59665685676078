import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {InvoicePreviewModal} from "../../..";
import {
    Loading,
    Modal,
    NoRecordsFound,
    PageTitle,
    showCenteredAlertTwoButtonsWithParameters,
    showTopAlert,
    SquareButton
} from "../../../../../../components";
import {addTimeZoneToFullDate2, isItSpecified} from '../../../../../../components/Utility';
import useAuth from '../../../../../../hooks/Log/useAuth';
import {useLocalization} from '../../../../../../hooks/Utils/useLocalization';
import {DeleteInvoice, ListInvoice, UndoDeleteInvoice} from '../../../../../../services/Member/MemberInvoiceService';
import {SquareButtonWithoutLink} from "../../../../../../components/Buttons";
import UpdatePaymentDateModal from "./UpdatePaymentDateModal";

const Invoices = () => {

  const strings = useLocalization();
  const { employee } = useAuth();
  const { memberId } = useParams() || false;

  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [invoiceId, setInvoiceId] = useState("");
  const [paymentUpdateModalData, setPaymentUpdateModalData] = useState("");

  const notSpecified = strings.errors.it_is_not_specified;
  const smallModal = "h-[400px] max-w-[550px]";
  const largeModal = "h-[90vh] max-w-[1140px]";

  const getInvoices = async (memberId) => {
    setLoading(true);

    const result = await ListInvoice(memberId);

    if(result.status === 200){
        let resultContent = JSON.parse(result.content);
        setData(data => resultContent);
    } else
        showTopAlert(strings.errors.an_error_occurred, "error");

      setLoading(false);
  }

  const sendRequestToGetInvoicesEndpoint = () => {
      memberId && getInvoices(memberId);
  }

  const deleteInvoice = async (invoiceId) => {
    
    const result = await DeleteInvoice(invoiceId);

    if(result.status === 200){
        sendRequestToGetInvoicesEndpoint();
        showTopAlert(strings.member.member_detail.accounting.invoice_deleted, "success");
    } else{
        showTopAlert(strings.errors.an_error_occurred, "error");
    }
  }

  const undoDeleteInvoice = async (invoiceId) => {

    const result = await UndoDeleteInvoice(invoiceId);

    if(result.status === 200){
        sendRequestToGetInvoicesEndpoint();
        showTopAlert(strings.member.member_detail.accounting.undo_delete_success, "success");
    } else {
        showTopAlert(strings.errors.an_error_occurred, "error");
    }
  }

  const closeButton = () => {
    setModal(false);
  }

  const handleOpenInvoicePreviewModal = (invoiceId) => {
      setModal(true);
      setModalContent( { element: "preview", size: "large" } );
      setInvoiceId(invoiceId);
  }

  const handleOpenUpdatePaymentDateModal = (paymentId, paymentDate) => {
      setModal(true);
      setModalContent( { element: "updatePayment", size: "small" } );
      setPaymentUpdateModalData({
          paymentId: paymentId,
          paymentDate: addTimeZoneToFullDate2(paymentDate, employee?.TimeZone)
      });
  }

  useEffect(() => {
    sendRequestToGetInvoicesEndpoint();
  }, [])

  return (
    <>
        <PageTitle title={strings.member.member_detail.header.content.invoices} />
        
        {loading ? (    
            <Loading />
        ) : (
            <div className="mt-5 overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
                <div className="inline-block min-w-full rounded-lg overflow-hidden">
                    <table className="min-w-full leading-normal text-header">
                        <thead>
                            <tr>
                                <th className="th">
                                    {strings.table.current_informations}
                                </th>
                                <th className="th">
                                    <p>{strings.table.order_invoice_informations}</p>
                                </th>
                                <th className="th">
                                    <p>{strings.member.member_detail.accounting.edit_payment_date}</p>
                                </th>
                                <th className="th">
                                    <p>{strings.table.created_date}</p>
                                </th>
                                <th className="th">
                                    {strings.table.operation}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        {data.length > 0 ? (
                            data.map((invoice, index) => (
                                    <tr key={index} className="bg-white hover:bg-[#f0f0f0]">
                                        <td className='td'>
                                            <div className="ml-3 min-w-max">
                                                <p>
                                                    <strong>{strings.form_info.full_name} / {strings.form_info.company_title}: </strong>{invoice.name}
                                                </p>
                                                <p>
                                                    <strong>{strings.form_info.email}: </strong>{isItSpecified(invoice.email, notSpecified)}
                                                </p>
                                                <p>
                                                    <strong>{strings.form_info.tax_office}: </strong>{isItSpecified(invoice.taxOffice, notSpecified)}
                                                </p>
                                                <p>
                                                    <strong>{strings.form_info.tax_number}: </strong>{isItSpecified(invoice.taxNumber, notSpecified)}
                                                </p>
                                                <p>
                                                    <strong>{strings.table.address}: </strong>{isItSpecified(invoice.address, notSpecified)}
                                                </p>
                                            </div>
                                        </td>
                                        <td className="td align-middle">
                                            <p className="font-bold min-w-max">{invoice.packageName}</p>
                                        </td>
                                        <td className="td align-middle">
                                            <p className="min-w-max">{addTimeZoneToFullDate2(invoice.paymentDate, employee.TimeZone)}</p>
                                        </td>
                                        <td className="td align-middle">
                                            <p className="min-w-max">{addTimeZoneToFullDate2(invoice.invoiceDate, employee.TimeZone)}</p>
                                        </td>
                                        <td className="td align-middle">
                                            <div className='flex gap-1'>
                                                {/* <SquareButton 
                                                    classnames="bg-blue"
                                                    iconName="edit"
                                                    tooltip={strings.button.edit}
                                                    action={() => { setModal(true); setModalContent( { element: "edit" } )}}
                                                /> */}
                                                <SquareButton
                                                    classnames="bg-green"
                                                    iconName="print"
                                                    tooltip={strings.member.member_detail.accounting.invoice_preview}
                                                    action={() => handleOpenInvoicePreviewModal(invoice.id)}
                                                />
                                                <SquareButtonWithoutLink
                                                    classnames="bg-blue"
                                                    iconName="edit"
                                                    tooltipClassnames="tooltip-top"
                                                    tooltip={strings.button.update_payment_date}
                                                    action={() => handleOpenUpdatePaymentDateModal(invoice.paymentId, invoice.paymentDate)}
                                                />
                                                <SquareButton
                                                    classnames="bg-red"
                                                    iconName={invoice.status === 1 ? 'delete' : 'undo'}
                                                    tooltip={strings.button.delete}
                                                    action={() => {
                                                        if (invoice.status === 1) {
                                                            showCenteredAlertTwoButtonsWithParameters(
                                                                strings.alert.centered_alert_two_buttons.title_confirm,
                                                                strings.member.member_detail.accounting.invoice_delete_question,
                                                                strings.button.confirm,
                                                                strings.button.cancel,
                                                                deleteInvoice,
                                                                [invoice.id]
                                                            );
                                                        } else {
                                                            showCenteredAlertTwoButtonsWithParameters(
                                                                strings.alert.centered_alert_two_buttons.title_confirm,
                                                                strings.member.member_detail.accounting.undo_delete_question,
                                                                strings.button.confirm,
                                                                strings.button.cancel,
                                                                undoDeleteInvoice,
                                                                [invoice.id]
                                                            );
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr className="bg-white hover:bg-[#f0f0f0]">
                                    <td colSpan={5}>
                                        <NoRecordsFound />
                                    </td>
                                </tr>         
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        )}
        {(Object.keys(modalContent).length !== 0) && (
            <Modal
                modal={modal}
                setModal={setModal}
                classes={{
                    modal:
                        modalContent.size === 'small'
                            ? smallModal
                            : largeModal
                }}
            >
                {modalContent.element === "preview"
                    &&
                        <InvoicePreviewModal
                            classes="!h-[80vh]"
                            invoiceId={invoiceId}
                            closeButton={closeButton}
                        />
                }
                {modalContent.element === "updatePayment"
                    && <UpdatePaymentDateModal
                            paymentUpdateModalData={paymentUpdateModalData}
                            sendRequestToGetInvoicesEndpoint={sendRequestToGetInvoicesEndpoint}
                            closeButton={closeButton}
                        />
                }
                {/* {modalContent.element === "edit" && <EditInvoiceModal closeButton={closeButton}/> } */}
            </Modal>
        )}
    </>
  )
};

export default Invoices;