import classNames from "classnames";
import React, {useEffect, useRef, useState} from "react";
import OutsideClickHandler from "react-outside-click-handler";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {closeDropdown, CopyToClipboard, Modal, toggleDropdown} from "../../../../../components";
import {addTimeZone, checkIfAnyValueNotEmpty} from "../../../../../components/Utility";
import {SalesType, TermStatusType} from "../../../../../consts/Constants";
import useAuth from "../../../../../hooks/Log/useAuth";
import {useLocalization} from "../../../../../hooks/Utils/useLocalization";
import {url} from "../../../../../routes/utility";
import {GetStudentAvailability} from "../../../../../services/International/InternationalService";
import Accordion from "../../Common/Accordion";
import {
  MemberTeacherMessageModal,
  OperationDropdown,
  TermButton,
  TermContent,
  UnitsModal,
  UpdateUnitModal
} from "./components";

const Term = (props) => {

  const strings = useLocalization();
  const { studentNo, terms, memberId, sendRequestToEndPoint, memberPoolPermission } = props;
  const { employee } = useAuth();
  const language = useSelector(state => state.localizationStore.language);
  const notSpecified = strings.errors.it_is_not_specified;

  //Gruplanan dönemler aktif, pasif olarak değişkenlere atanıyor
  const passiveTerms = terms.filter((term) => term.status === TermStatusType.Passive) || [];
  const activeTerms = terms.filter((term) => term.status === TermStatusType.Active) || [];
  const futureTerms = terms.filter((term) => term.status === TermStatusType.Future) || [];
  const deletedTerms = terms.filter((term) => term.status === TermStatusType.Deleted) || [];
  const pendingTerms = terms.filter((term) => term.status === TermStatusType.Pending) ||[];
  
  const firstVisit = useRef(true);
  const dropdownRef = useRef();
  const dropdownArrowRef = useRef();

  const [isOpen, setIsOpen] = useState(false);
  const [isClickOutside, setIsClickOutside] = useState(false);
  const [firstTerm, setFirstTerm] = useState({});
  const [selectedTermButtons, setSelectedTermButtons] = useState(-1);
  const [termId, setTermId] = useState("");
  const [productId, setProductId] = useState("");
  const [passiveIndex, setPassiveIndex] = useState(0);
  const [pendingIndex, setPendingIndex] = useState(0);

  const [modal, setModal] = useState();
  const [modalContent, setModalContent] = useState({});
  const [modalSize, setModalSize] = useState({});

  const [termScheduleTitle, setTermScheduleTitle] = useState("");
  const [selectedTermInformation, setSelectedTermInformation] = useState({});
  const [futureTermTitle, setFutureTermTitle] = useState("");
  const [studentAvailability, setStudentAvailability] = useState("");

  const smallModal = "h-[400px] max-w-[620px]";
  const middleModal = "h-[600px] max-w-[820px]";
  const largeModal = "h-[90vh] max-w-[1440px]";

  //Sadece aktif dönemlerde öğrencinin eğitmenin listesinde olup olmadığını getiren fonksiyon
  const getStudentAvailabilityInfo = async (studentNo) => {
    const result = await GetStudentAvailability(studentNo);

    if (result.status === 200) {
      let resultContent = JSON.parse(result.content);

      //Servisten gelen objenin değerleri boş ise state'i false dolu ise state'i true olarak güncelliyoruz.
      setStudentAvailability(studentAvailability => checkIfAnyValueNotEmpty(resultContent));
    }
  }

  const getStudentAvailabilityText = (studentAvailability, firstTerm) => {
    if(studentAvailability && firstTerm.hasLessonToday) {
      return strings.member.member_detail.terms.on_teacher_list;
    } else if (studentAvailability === ""){
      return strings.loading
    } else if (!firstTerm.hasLessonToday) {
      return strings.member.member_detail.terms.no_lesson_today
    }
    else {
      return strings.member.member_detail.terms.no_teacher_list;
    }
  }

  //Başlıkta yazacak ilk dönem ismi alınıyor. Aktif dönem varsa ilk o, yoksa gelecek dönem
  //yoksa geçmiş dönem, yoksa silinmiş dönemdeki isim alınacak
  //Ayrıca ilk hangi dönem varsa o dönem seçili geliyor
  useEffect(() => {
    let _firstTerm = {};
    let _selectedTermButton = 0;
    if (Object.keys(terms).length > 0) {
      if (activeTerms.length > 0) {
        _firstTerm = activeTerms[0];
        _selectedTermButton = TermStatusType.Active;
      } else if (futureTerms.length > 0) {
        _firstTerm = futureTerms[0];
        _selectedTermButton = TermStatusType.Future;
      } else if (passiveTerms.length > 0) {
        _firstTerm = passiveTerms[0];
        _selectedTermButton = TermStatusType.Passive;
      } else if (deletedTerms.length > 0) {
        _firstTerm = deletedTerms[0];
        _selectedTermButton = TermStatusType.Deleted;
      } else if (pendingTerms.length > 0) {
        _firstTerm = pendingTerms[0];
        _selectedTermButton = TermStatusType.Pending;
      }
      setFirstTerm(firstTerm => _firstTerm);
      setSelectedTermButtons(selectedTermButtons => _selectedTermButton);
    }

  }, [terms])

  useEffect(() => {
    if (Object.keys(terms).length > 0) {
      if (activeTerms.length > 0 && selectedTermButtons === TermStatusType.Active) {
        setTermId(activeTerms[0].termId);
        setProductId(activeTerms[0].productId);
      }
      if (futureTerms.length > 0 && selectedTermButtons === TermStatusType.Future) {
        setTermId(futureTerms[0].termId);
        setProductId(futureTerms[0].productId);
      }
      if (passiveTerms.length > 0 && selectedTermButtons === TermStatusType.Passive) {
        setTermId(passiveTerms[passiveIndex].termId);
        setProductId(passiveTerms[passiveIndex].productId);
      }
      if (pendingTerms.length > 0 && selectedTermButtons === TermStatusType.Pending) {
        setTermId(pendingTerms[pendingIndex].termId);
        setProductId(pendingTerms[pendingIndex].productId);
      }
    }
  }, [selectedTermButtons, passiveIndex, pendingIndex])

  const termSalesType = Object.keys(firstTerm).length > 0 && SalesType.find(type => type.id === firstTerm.salesType);
  const salesType = Object.keys(termSalesType).length > 0 && termSalesType[language]

  const getAutoDemoTermTitleByStatus = (isAutoDemoTerm) => {
    return isAutoDemoTerm
        ? " / " + strings.member.member_detail.terms.automatic_demo
        : "";
  }

  const firstTermTitle = Object.keys(firstTerm).length > 0
    ? " - " + firstTerm.productName + " / " + firstTerm.teacherName + " - " + (firstTerm.lessonTime ? addTimeZone(firstTerm.lessonTime, employee.TimeZone) : notSpecified) + " / " + salesType + getAutoDemoTermTitleByStatus(firstTerm?.isAutoDemoTerm)
    : "";

  // Seçili dönemin bilgilerini bir objeye atıyoruz.
  useEffect(() => {
    const selectedTerm = terms.find(term => term.termId === termId);
    setSelectedTermInformation(selectedTermInformation => selectedTerm);
  }, [terms, termId]);


  // Seçili dönem eğer uzatma ise bu dönemin başlığını değiştiriyoruz.
  // Pasif ve silinmiş için bu durum geçerli değil sadece gelecek dönemde kontrol yapılıyor
  useEffect(() => {
    if(selectedTermInformation) {
      const tmpFutureTermTitle = selectedTermInformation.status === TermStatusType.Future
          ? " - " + selectedTermInformation.productName + " / " + selectedTermInformation.teacherName + " - " + (selectedTermInformation.lessonTime ? addTimeZone(selectedTermInformation.lessonTime, employee.TimeZone) : notSpecified) + " / " + strings.member.member_detail.terms.extend
          : "";

      setFutureTermTitle(futureTermTitle => tmpFutureTermTitle);
    }
  }, [selectedTermInformation]);

  const closeButton = () => {
    setModal(false);
  }

  const isPassiveOperationDropdown = () => {
    if (
        activeTerms.length === 0 &&
        passiveTerms.length === 0 &&
        futureTerms.length === 0 &&
        deletedTerms.length > 0 &&
        pendingTerms.length === 0
    ) {
      return true;
    }
    return false;
  }

  // Öğrencinin term statuslarına göre butonların görünme durumu kontrol edilecektir.
  const termStatuses = [
    { type: TermStatusType.Active, terms: activeTerms },
    { type: TermStatusType.Future, terms: futureTerms },
    { type: TermStatusType.Passive, terms: passiveTerms },
    { type: TermStatusType.Pending, terms: pendingTerms },
    { type: TermStatusType.Deleted, terms: deletedTerms }
  ];

  useEffect(() => {
    if (isClickOutside) {
      closeDropdown(dropdownRef, dropdownArrowRef);
    }

    setIsClickOutside(false);
  }, [isClickOutside])

  useEffect(() => {
    closeDropdown(dropdownRef, dropdownArrowRef)
  }, [isOpen])

  useEffect(() => {
    if (Object.keys(firstTerm).length !== 0) {
      setTermScheduleTitle(termScheduleTitle => studentNo + " - " + firstTerm.productName);
    }
  }, [firstTerm])

  useEffect(() => {
      if(studentNo && selectedTermButtons === TermStatusType.Active) {
        getStudentAvailabilityInfo(studentNo);
      }
  }, [studentNo, selectedTermButtons, isOpen, firstTerm]);

  return (
      <>
        <OutsideClickHandler onOutsideClick={() => setIsClickOutside(true)}>
          <div className="relative mt-3">
            <div
                className={classNames("px-2 sm:px-5 py-4 cursor-pointer text-dark-text xs:h-[75px] bg-card rounded-t-md flex justify-between items-center mx-2 xs:mx-4", { 
                  'rounded-b-md': !isOpen,
                  '!bg-[#e5e5e5]': selectedTermInformation?.isFrozenTerm && selectedTermInformation?.status === TermStatusType.Active,
                })}>
              <div
                  className="relative flex gap-2 sm:gap-4 items-center w-full"
              >
                <button
                    onClick={() => toggleDropdown(dropdownRef, dropdownArrowRef)}
                    className={classNames(
                        'bg-card-button rounded-full py-3 sm:px-5 px-3 flex items-center justify-center gap-1 relative',
                        { 'pointer-events-none !bg-transparent': isPassiveOperationDropdown() },
                        { 'pointer-events-none !bg-transparent': selectedTermButtons === TermStatusType.Deleted },
                        { 'pointer-events-none !bg-transparent': selectedTermButtons === TermStatusType.Passive },
                        { '!bg-[#d5d5d5]': selectedTermInformation?.isFrozenTerm && selectedTermInformation?.status === TermStatusType.Active },
                    )}
                >
                  <div className="group relative flex items-center">
                  <span
                      className={classNames(
                          "material-symbols-outlined text-[13px] cursor-pointer",
                          { 'text-[#c9c9c9]': isPassiveOperationDropdown() },
                          { 'text-[#c9c9c9]': selectedTermButtons === TermStatusType.Deleted },
                          { 'text-[#c9c9c9]': selectedTermButtons === TermStatusType.Passive },
                      )}
                  >
                    settings
                  </span>
                    <div className="tooltip">
                      <p>{strings.member.member_detail.header.operations}</p>
                    </div>
                  </div>
                  <div
                      ref={dropdownArrowRef}
                      className={classNames(
                          'dropdown-arrow transition-transform duration-300',
                          { 'border-t-[#c9c9c9]': isPassiveOperationDropdown() },
                          { 'border-t-[#c9c9c9]': selectedTermButtons === TermStatusType.Deleted },
                          { 'border-t-[#c9c9c9]': selectedTermButtons === TermStatusType.Passive })
                      }
                  >
                  </div>
                </button>
                <div
                    ref={dropdownRef}
                    className={`absolute mb-[114.5px] ${selectedTermButtons === TermStatusType.Passive ? 'mb-[79px]' : ''} z-10 bg-white rounded origin-bottom animate-fadeIn scale-y-0 transition-transform duration-200 shadow-dropdownContent`}
                >
                  <OperationDropdown
                      setModal={setModal}
                      setModalContent={setModalContent}
                      setModalSize={setModalSize}
                      setIsClickOutside={setIsClickOutside}
                      memberId={memberId}
                      terms={terms}
                      termId={termId}
                      productId={productId}
                      selectedTermButtons={selectedTermButtons}
                      termSalesType={termSalesType}
                      studentNo={studentNo}
                      termScheduleTitle={termScheduleTitle}
                      sendRequestToEndPoint = { sendRequestToEndPoint }
                      memberPoolPermission={memberPoolPermission}
                  />
                </div>
                <div
                    className="w-full h-[75px] flex items-center"
                    onClick={() => {
                      setIsOpen(!isOpen);
                      firstVisit.current = false;
                    }}
                >
                  {selectedTermButtons === TermStatusType.Active && (
                      <div className="flex items-center">
                      <span className={classNames("rounded-full w-3 h-3 shadow-circle mr-1 transition-colors duration-300 group relative", {
                        "bg-dark-gray" : studentAvailability === "" || !firstTerm.hasLessonToday,
                        "bg-green": studentAvailability,
                        "bg-red": !studentAvailability
                      })}>
                        <div className="tooltip-top">
                          {getStudentAvailabilityText(studentAvailability, firstTerm)}
                        </div>
                      </span>
                        <span className="mr-1">-</span>
                      </div>
                  )}
                  <p>
                    <span>
                      <span className="mr-[2px]">{studentNo}</span>
                      <CopyToClipboard text={studentNo} iconClassName="!text-[12px]"/>
                    </span>
                    {selectedTermInformation?.status === TermStatusType.Future ? futureTermTitle : firstTermTitle}
                    {(selectedTermInformation?.isFrozenTerm && selectedTermInformation?.status === TermStatusType.Active) && 
                      <span className="font-bold"> ({strings.member.member_detail.terms.frozen_lesson})</span>
                    }
                  </p>
                </div>
              </div>

              {!isOpen ? (
                    <span
                        onClick={() => {
                          setIsOpen(true);
                          firstVisit.current = false;
                        }}
                        className="material-symbols-outlined font-bold text-base cursor-pointer"
                    >
                      add
                    </span>
                  )
                  :
                  (
                    <span
                        onClick={() => {
                          setIsOpen(false);
                          firstVisit.current = false;
                        }}
                        className="material-symbols-outlined font-bold text-base cursor-pointer"
                    >
                      remove
                    </span>
                  )
              }
            </div>

            <div
                className={`${isOpen ? 'max-h-max py-8 border' : 'max-h-0'} mx-2 xs:mx-4 px-4 gap-x-4 gap-y-5 flex-wrap max-md-lg:justify-center border-[#ebebeb] rounded-b-md animate-fadeIn transition-max-height duration-500`}
            >
              <div className={`${!isOpen ? 'animate-fadeOut' : ''} ${firstVisit.current ? 'hidden' : ''} relative xl:w-full flex flex-col xl:flex-row gap-5`}>
                <div className="flex flex-col gap-3 md:max-w-max">
                  {termStatuses.map(({ type, terms }) => (
                    terms.length > 0 && (
                      <TermButton 
                        key={type} 
                        termStatus={type} 
                        selectedTermButtons={selectedTermButtons} 
                        setSelectedTermButtons={setSelectedTermButtons} 
                      />
                    )
                  ))}
                </div>
                <div className={classNames(`flex flex-col gap-5, ${
                  passiveTerms.length > 0 ? 'xl:w-[calc(100%-264px)] 2xl:w-full' : 'w-full'
                }`)}>
                  {selectedTermButtons === TermStatusType.Active && activeTerms.map((term, index) => (
                      <TermContent
                          key={index}
                          isEditable={memberPoolPermission}
                          isActiveTeacherTimeChangeButton={true}
                          isAutoDemo={firstTerm?.isAutoDemoTerm}
                          isActiveLessonAddDeleteButton={true}
                          termDetail={term}
                          studentNo={studentNo}
                      />
                  ))}
                  {selectedTermButtons === TermStatusType.Future && futureTerms.map((term, index) => (
                      <TermContent
                          key={index}
                          isEditable={memberPoolPermission}
                          isActiveLessonAddDeleteButton={true}
                          isActiveTeacherTimeChangeButton={false}
                          activeTermEndDate={activeTerms[0]?.endDate}
                          termDetail={term}
                          studentNo={studentNo}
                      />
                  ))}
                  {selectedTermButtons === TermStatusType.Passive && (
                      <div className="-mt-5">
                        {passiveTerms.map((term, index) => {
                          const _termSalesType = SalesType.find(type => type.id === term.salesType);
                          const _salesType = Object.keys(_termSalesType).length > 0 && language === "tr" ? _termSalesType.tr : _termSalesType.sales;

                          return (
                              <div key={index} onClick={() => setPassiveIndex(index)}>
                                <Accordion
                                    title={`${studentNo} - ${term.productName} / ${term.teacherName ? term.teacherName : notSpecified} - ${term.lessonTime ? addTimeZone(term.lessonTime, employee.TimeZone) : notSpecified} / ${_salesType}`}
                                    outSideClose={false}
                                    isOpen={index === 0 ? true : ''}
                                    content={
                                      <TermContent
                                          isEditable={false}
                                          termDetail={term}
                                          studentNo={studentNo}
                                     />
                                    }
                                    iconContent={
                                      <Link
                                          to={url("member.termschedule", { termId: term.termId })}
                                          className="material-symbols-outlined cursor-pointer"
                                          target="_blank"
                                      >
                                        calendar_month
                                      </Link>
                                    }
                                />
                              </div>
                          )
                        })}
                      </div>
                  )}
                  {selectedTermButtons === TermStatusType.Pending && (
                      <div className="-mt-5">
                        {pendingTerms.map((term, index) => {
                          const _termSalesType = SalesType.find(type => type.id === term.salesType);
                          const _salesType = Object.keys(_termSalesType).length > 0 && language === "tr" ? _termSalesType.tr : _termSalesType.sales;

                          return (
                              <div key={index} onClick={() => setPendingIndex(index)}>
                                <Accordion
                                    title={`${studentNo} - ${term.productName} / ${term.teacherName ? term.teacherName : notSpecified} - ${term.lessonTime ? addTimeZone(term.lessonTime, employee.TimeZone) : notSpecified} / ${_salesType}`}
                                    outSideClose={false}
                                    isOpen={index === 0 ? true : ''}
                                    content={
                                      <TermContent
                                          isEditable={false}
                                          termDetail={term}
                                          studentNo={studentNo}
                                          isActiveLessonAddDeleteButton={true}
                                      />
                                    }
                                    iconContent={
                                      <Link
                                          to={url("member.termschedule", { termId: term.termId })}
                                          className="material-symbols-outlined cursor-pointer"
                                          target="_blank"
                                      >
                                        calendar_month
                                      </Link>
                                    }
                                />
                              </div>
                          )
                        })}
                      </div>
                  )}
                  {selectedTermButtons === TermStatusType.Deleted && (
                      <div className="-mt-5">
                        {deletedTerms.map((term, index) => {
                          const _termSalesType = SalesType.find(type => type.id === term.salesType);
                          const _salesType = Object.keys(_termSalesType).length > 0 && language === "tr" ? _termSalesType.tr : _termSalesType.sales;
                          return (
                              <Accordion
                                 key={index}
                                 title={`${studentNo} - ${term.productName} / ${term.teacherName ? term.teacherName : notSpecified} - ${term.lessonTime ? addTimeZone(term.lessonTime, employee.TimeZone) : notSpecified} / ${_salesType}`}
                                 outSideClose={false}
                                 isOpen={index === 0 ? true : ''}
                                 content={
                                   <>
                                     <TermContent
                                         isEditable={false}
                                         termDetail={term}
                                         studentNo={studentNo}
                                     />
                                   </>
                                 }
                              />
                          )
                        })}
                      </div>
                  )}

                </div>
              </div>
            </div>
          </div>
        </OutsideClickHandler>

        {
          (Object.keys(modalContent).length !== 0 && (
              <Modal
                  modal={modal}
                  setModal={setModal}
                  classes={{
                    modal: modalSize.size === 'small' ? smallModal : modalSize.size === 'middle' ? middleModal : largeModal
                  }}
              >
                {modalContent.element === "units" && modalSize.size === "middle" && <UnitsModal termId={termId} setModal={setModal} setModalSize={setModalSize} setModalContent={setModalContent} closeButton={closeButton} />}
                {modalContent.element === "updateunit" && modalSize.size === "middle" && <UpdateUnitModal termId={termId} memberId={memberId} setModal={setModal} setModalSize={setModalSize} setModalContent={setModalContent} closeButton={closeButton} />}
                {modalContent.element === "memberteachermessage" && modalSize.size === "large" && <MemberTeacherMessageModal studentNo={studentNo} memberId={memberId} setModal={setModal} setModalSize={setModalSize} setModalContent={setModalContent} closeButton={closeButton} classes="!h-[85vh] pr-3 overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto" />}
              </Modal>
          ))
        }

        {/* TermContent componentinde bulunan modalları bir üst componente taşıyarak modal'ın üste çıkmasını sağladık */}
        <div id="modal-container"></div>
      </>
  );
};

export default Term;