import { fetchApi } from '../index';

export const AddTransferStatus = async (model) => {

    const url = '/member/v1/member_transfer_status/add_transfer_status/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const GetTransferStatusList = async (memberId) => {

    const url = '/member/v1/member_transfer_status/get_transfer_status_list/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const DeleteTransferStatus = async (transferStatusId) => {

    const url = '/member/v1/member_transfer_status/delete/';

    const payload = {
        transferStatusId: transferStatusId
    };

    return await fetchApi(url, payload);
}