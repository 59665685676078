import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Loading, NoRecordsFound, PageTitle } from "../../../../../../components";
import { useLocalization } from "../../../../../../hooks/Utils/useLocalization";
import { GetMemberTickets } from "../../../../../../services/Member/MemberTicketService";
import TicketAccordion from "./TicketAccordion";

const Tickets = () => {
  const { memberId } = useParams() || false;

  const [ticketList, setTicketList] = useState([]);
  const [loading, setLoading] = useState(true);

  const strings = useLocalization();

  const getMemberTickets = async (memberId) => {
    const result = await GetMemberTickets(memberId);
    if (result.status === 200) {
      let resultContent = JSON.parse(result.content);
      
      setTicketList(resultContent);
      setLoading(loading => false);
    }
  };

  useEffect(() => {
    if (memberId) {
      getMemberTickets(memberId);
    }
  }, []);

  return (
    <>
      <PageTitle title={strings.member.member_detail.header.content.tickets} />
      {loading ? (
        <Loading />
      ) : (
        <>
          {ticketList.length === 0 ? (
            <NoRecordsFound />
          ) : (
            ticketList.map((ticket, index) => (
              <TicketAccordion key={index} ticket={ticket} />
            ))
          )}
        </>
      )}
    </>
  );
};

export default Tickets;
