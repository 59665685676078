import React from "react";
import {useLocalization} from "../../../../hooks/Utils/useLocalization";

const IconDescriptions = () => {

    const strings = useLocalization();

    return (
        <>
            <div className="flex flex-col w-full items-end px-4">
                <div className="max-xl:pt-0 max-xl:pb-3 mb-4 flex gap-3 sm:gap-x-5 max-xl:px-4 max-xl:mx-2 max-md:flex-col max-md:float-right">
                    <div className="flex gap-1 items-center">
                        <span className="material-symbols-outlined text-green font-bold text-[18px]">check</span>
                        <span className="text-green">{strings.teacher.all_teacher_program.no_lesson}</span>
                    </div>
                    <div className="flex gap-1 items-center">
                        <span className="material-symbols-outlined text-red font-bold text-[18px]">close</span>
                        <span className="text-red">{strings.teacher.all_teacher_program.has_lesson}</span>
                    </div>
                    <div className="flex gap-1 items-center">
                        <span className="material-symbols-outlined text-danger font-bold text-[16px]">block</span>
                        <span className="text-danger">{strings.teacher.all_teacher_program.blocked}</span>
                    </div>
                </div>
            </div>
            <hr className="mb-4" />
        </>
    );
}

export default IconDescriptions;