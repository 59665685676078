import {useLocalization} from "../../../../hooks/Utils/useLocalization";
import React, {useEffect, useRef, useState} from "react";
import {v4 as uuid4} from "uuid";
import {Dropdown, Input} from "../../../../components";
import {ApplicationList} from "../../../../consts/Constants";
import {handleCheckbox} from "../../../../components/Utility";
import classNames from "classnames";

const ApplicationRowEditable = ({ item, applications, setApplications, isClickedUpdateButton }) => {
    const strings = useLocalization();

    const applicationErrorRef = useRef();
    const webPathErrorRef = useRef();
    const mobilePathErrorRef = useRef();

    const firstApplication = { id: -1, name: strings.detail_search_titles.choose_application };
    const [application, setApplication] = useState(item.applicationId);

    const [paidMember, setPaidMember] = useState(item.paidMember);
    const [nonPaidMember, setNonPaidMember] = useState(item.nonPaidMember);
    const [demoMember, setDemoMember] = useState(item.demoMember);

    //Seçili uygulama alanı değişikliği kontrol eder ve translations array'ini günceller
    const handleApplicationChange = (selectedApplication) => {
        const updatedApplications = applications.map((application) =>
            application.id === item.id
                ? { ...application, applicationId: selectedApplication.id }
                : application
        );
        setApplications(updatedApplications);
    };

    //Web path alanı değişikliği kontrol eder ve translations array'ini günceller
    const handleWebPathChange = (event) => {
        const updatedApplications = applications.map((application) =>
            application.id === item.id
                ? { ...application, webPath: event.target.value }
                : application
        );
        setApplications(updatedApplications);
    };

    //Mobile path alanı değişikliği kontrol eder ve translations array'ini günceller
    const handleMobilePathChange = (event) => {
        const updatedApplications = applications.map((application) =>
            application.id === item.id
                ? { ...application, mobilePath: event.target.value }
                : application
        );
        setApplications(updatedApplications);
    };

    //Ödeme yapmış kullanıcı alanındaki değişikliği kontrol eder ve translations array'ini günceller
    const handlePaidMemberChange = (paidMember) => {
        const updatedApplications = applications.map((application) =>
            application.id === item.id
                ? { ...application, paidMember: paidMember }
                : application
        );
        setApplications(updatedApplications);
    };

    //Ödeme yapmamış kullanıcı alanındaki değişikliği kontrol eder ve translations array'ini günceller
    const handleNonPaidMemberChange = (nonPaidMember) => {
        const updatedApplications = applications.map((application) =>
            application.id === item.id
                ? { ...application, nonPaidMember: nonPaidMember }
                : application
        );
        setApplications(updatedApplications);
    };

    const handleDemoMemberChange = (demoMember) => {
        const updatedApplications = applications.map((application) =>
            application.id === item.id
                ? { ...application, demoMember: demoMember }
                : application
        );
        setApplications(updatedApplications);
    };

    const removeApplicationRow = (applicationId) => {
        const updatedApplications = applications.filter(item => item.applicationId !== applicationId);
        setApplications(updatedApplications);
    }

    const validate = () =>  {
        let valid = true;

        applicationErrorRef.current.innerText = '';
        webPathErrorRef.current.innerText = '';
        mobilePathErrorRef.current.innerText = '';

        if(item.applicationId === -1 || item.applicationId.id === -1) {
            valid = false;
            applicationErrorRef.current.innerText = strings.errors.application_null_error;
        }
        if(item.webPath.length === 0) {
            valid = false;
            webPathErrorRef.current.innerText = strings.errors.web_path_null_error;
        }
        if(item.mobilePath.length === 0) {
            valid = false;
            mobilePathErrorRef.current.innerText = strings.errors.mobile_path_null_error;
        }

        return valid;
    }

    useEffect(() => {
        handleApplicationChange(application);
    }, [application]);

    useEffect(() => {
        handlePaidMemberChange(paidMember);
    }, [paidMember]);

    useEffect(() => {
        handleNonPaidMemberChange(nonPaidMember);
    }, [nonPaidMember]);

    useEffect(() => {
        handleDemoMemberChange(demoMember);
    }, [demoMember]);

    useEffect(() => {
        //Güncelle butonuna basıldıktan sonra validasyonları kontrol etmeye başlar ve hata mesajları gösterilir
        isClickedUpdateButton && validate()
    }, [item, applications, isClickedUpdateButton]);

    return (
        <>
            <div
                className="flex flex-col xl:flex-row items-center justify-start xl:items-start gap-3 px-5 max-xs:px-2"
            >
                <div className="flex flex-col pb-0 mt-5 w-full">
                    <div className="flex flex-col pb-0 ">
                        <label>
                            {strings.member.member_detail.others.application}
                        </label>
                        <Dropdown
                            data={[firstApplication, ...ApplicationList]}
                            selectedData={application}
                            setSelectedData={setApplication}
                            classnames="md:max-w-full"
                        />
                        <span ref={applicationErrorRef} className="text-danger field-validation-valid"></span>
                    </div>
                </div>
                <div className="flex flex-col pb-0 mt-5 w-full">
                    <label htmlFor="web-path">
                        {strings.form_info.web_path}
                    </label>
                    <div className="flex flex-col pb-0 ">
                        <Input
                            id="web-path"
                            type="text"
                            classnames="md:max-w-full"
                            value={item.webPath}
                            onChange={(e) => handleWebPathChange(e)}
                        />
                        <span ref={webPathErrorRef} className="text-danger field-validation-valid"></span>
                    </div>
                </div>
                <div className="flex flex-col pb-0 mt-5 w-full">
                    <label htmlFor="mobile-path">
                        {strings.form_info.mobile_path}
                    </label>
                    <div className="flex flex-col pb-0 ">
                        <Input
                            id="mobile-path"
                            type="text"
                            classnames="md:max-w-full"
                            value={item.mobilePath}
                            onChange={(e) => handleMobilePathChange(e)}
                        />
                        <span ref={mobilePathErrorRef} className="text-danger field-validation-valid "></span>
                    </div>
                </div>
                <div className="flex flex-col pb-0 mt-5 w-full">
                    <label className="min-w-max">{strings.form_info.paid_member}</label>
                    <div className="flex justify-start items-center w-full p-2">
                        <input
                            id={`${item.id}-paid-member`}
                            type="checkbox"
                            className="hidden"
                            onClick={() => setPaidMember(isCorporate => !paidMember)}
                        />
                        <label
                            htmlFor={`${item.id}-paid-member`}
                            className={`${handleCheckbox(paidMember)} mr-2 w-6 h-6 rounded-[3px] cursor-pointer duration-500 flex items-center justify-center`}
                        >
                            <span
                                className={classNames('text-inherit text-[20px] material-symbols-outlined animate-fadeIn font-bold', {'hidden': !paidMember})}>
                                done
                            </span>
                        </label>
                    </div>
                </div>
                <div className="flex flex-col pb-0 mt-5 w-full">
                    <label className="min-w-max">{strings.form_info.non_paid_member}</label>
                    <div className="flex justify-start items-center w-full p-2">
                        <input
                            id={`${item.id}-non-paid-member`}
                            type="checkbox"
                            className="hidden"
                            onClick={() => setNonPaidMember(isCorporate => !nonPaidMember)}
                        />
                        <label
                            htmlFor={`${item.id}-non-paid-member`}
                            className={`${handleCheckbox(nonPaidMember)} mr-2 w-6 h-6 rounded-[3px] cursor-pointer duration-500 flex items-center justify-center`}
                        >
                            <span
                                className={classNames('text-inherit text-[20px] material-symbols-outlined animate-fadeIn font-bold', {'hidden': !nonPaidMember})}>
                                done
                            </span>
                        </label>
                    </div>
                </div>
                <div className="flex flex-col pb-0 mt-5 w-full">
                    <label className="min-w-max">{strings.form_info.demo_member}</label>
                    <div className="flex justify-start items-center w-full p-2">
                        <input
                            id={`${item.id}-demo-member`}
                            type="checkbox"
                            className="hidden"
                            onClick={() => setDemoMember(demoMember => !demoMember)}
                        />
                        <label
                            htmlFor={`${item.id}-demo-member`}
                            className={`${handleCheckbox(demoMember)} mr-2 w-6 h-6 rounded-[3px] cursor-pointer duration-500 flex items-center justify-center`}
                        >
                            <span
                                className={classNames('text-inherit text-[20px] material-symbols-outlined animate-fadeIn font-bold', {'hidden': !demoMember})}>
                                done
                            </span>
                        </label>
                    </div>
                </div>
                <div className="flex gap-1">
                    <button
                        type="button"
                        className={classNames("flex-shrink-0 xl:mb-2 mt-2 group inline-flex items-center justify-center bg-transparent border border-red text-red w-8 h-8 rounded-[3px] relative xl:mt-[52px]", {
                            "opacity-70 cursor-not-allowed": applications.length === 1,
                            "cursor-pointer": applications.length !== 1
                        })}
                        disabled={applications.length === 1}
                        onClick={() => removeApplicationRow(item.applicationId)}
                    >
                        <span
                            className="material-symbols-outlined text-[18px]"
                        >
                            delete
                        </span>
                        <div className="tooltip-top opacity-100">
                            {applications.length === 1 && <p>{strings.use_case.update.at_least_one_application}</p>}
                            {applications.length !== 1 && <p>{strings.button.delete}</p>}
                        </div>
                    </button>
                </div>
            </div>
        </>
    )
}

export default ApplicationRowEditable;