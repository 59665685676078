import { nanoid } from "nanoid";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Dropdown, Loading, PageTitle, showCenteredAlertTwoButtonsWithParameters, showTopAlert } from "../../../../../components";
import { useLocalization } from "../../../../../hooks/Utils/useLocalization";
import usePrimeData from "../../../../../hooks/Utils/usePrimeData";
import { AddDemand, DeleteDemand, GetDemandList, UndoDeleteDemand } from "../../../../../services/Member/MemberDemandService";
import { showCenteredAlertTwoButtons } from "../../../../../components";

const AddStudentRequest = () => {

  const strings = useLocalization();
  const primeData = usePrimeData();
  const { memberId } = useParams() || false;

  const firstRequest = { id: -1, name: strings.member.member_detail.others.choose_request };
  const requestList = [firstRequest, ...primeData.memberDemandTypeList];

  const [requestData, setRequestData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [request, setRequest] = useState(firstRequest);

  const sendToEndpoint = async () => {
    const model = {
      memberId: memberId,
      memberDemandTypeId: request.id
    }

    const result = await AddDemand(model);
    if (result.status === 200) {
      showTopAlert(strings.alert.top_alert.success, "success");
      getDemandList(memberId);
    } else {
      showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
    }
  };

  const getDemandList = async (memberId) => {
    const result = await GetDemandList(memberId);
    if (result.status === 200) {
      setRequestData(requestData => JSON.parse(result.content));
      setLoading(loading => false);
    }
  }

  const deleteRequest = async (demandId) => {
    if (demandId) {
      const result = await DeleteDemand(demandId);
      if (result.status === 200) {
        showTopAlert(strings.alert.top_alert.success, "success");
        getDemandList(memberId);
      }
    }
  };

  const undoDeleteRequest = async (demandId) => {
    if (demandId) {
      const result = await UndoDeleteDemand(demandId);
      if (result.status === 200) {
        showTopAlert(strings.alert.top_alert.success, "success");
        getDemandList(memberId);
      }
    }
  };

  useEffect(() => {
    if (memberId) {
      getDemandList(memberId);
    }
  }, [])

  return (
    <>
      <PageTitle
        title={strings.member.member_detail.header.content.add_student_request}
      />
      <div className="mt-5 px-2 sm:px-5 flex w-full flex-col items-center">
        <form className="flex flex-col px-[10px] pb-0 w-full">
          <label htmlFor="member_demand_type">
            {strings.detail_search_titles.requests}
          </label>
          <Dropdown
            classnames="md:max-w-full"
            data={requestList}
            selectedData={request}
            setSelectedData={setRequest}
          />
        </form>
        <button
          onClick={() => {
            request.id === -1 ?
              showTopAlert(strings.member.member_detail.others.request_error, "warning")
              :
              showCenteredAlertTwoButtons(
                strings.alert.centered_alert_two_buttons.title,
                strings.alert.centered_alert_two_buttons.save_question,
                strings.button.confirm,
                strings.alert.centered_alert_two_buttons.cancel_text,
                sendToEndpoint
              )
          }}
          className="button search-button gap-3 text-white bg-blue border-blue px-10 mt-5 mx-auto"
        >
          {strings.button.add_upper}
        </button>
        <p className="font-bold mt-7">
          {strings.member.member_detail.others.request_history}
        </p>
        {loading ? (
          <Loading />
        ) : (
          <table className="mt-5">
          <thead>
            <tr>
              <th className="th">{strings.table.ticket}</th>
              <th className="th w-[200px]">{strings.member.member_detail.header.operations}</th>
            </tr>
          </thead>
          <tbody>
            {requestData.length !== 0 ? (
              requestData.map((request, index) => (
                <tr key={index} className="bg-white hover:bg-[#f0f0f0]">
                  <td className="td align-middle">
                    <p>{request.description}</p>
                  </td>
                  <td className="td align-middle">
                    {request.status === 1 ?
                      (
                        <button onClick={() => showCenteredAlertTwoButtonsWithParameters(
                          strings.alert.centered_alert_two_buttons.title,
                          strings.alert.centered_alert_two_buttons.save_question,
                          strings.button.confirm,
                          strings.alert.centered_alert_two_buttons.cancel_text,
                          deleteRequest,
                          [request.id]
                        )
                        }
                          className="inline-flex items-center justify-center bg-red text-white w-11 my-2 h-11 rounded-[3px] relative group search-button"
                        >
                          <span className="material-symbols-outlined text-[18px]">
                            delete
                          </span>
                          <div className="tooltip-top">
                            <p>{strings.button.delete}</p>
                          </div>
                        </button>
                      )
                      : (
                        <button
                          onClick={() => showCenteredAlertTwoButtonsWithParameters(
                            strings.alert.centered_alert_two_buttons.title,
                            strings.alert.centered_alert_two_buttons.save_question,
                            strings.button.confirm,
                            strings.alert.centered_alert_two_buttons.cancel_text,
                            undoDeleteRequest,
                            [request.id]
                          )}
                          className="inline-flex items-center justify-center bg-red text-white w-11 my-2 h-11 rounded-[3px] relative group search-button"
                        >
                          <span className="material-symbols-outlined text-[18px]">
                            undo
                          </span>
                          <div className="tooltip-top">
                            <p>{strings.button.undo}</p>
                          </div>
                        </button>
                      )
                    }
                  </td>
                </tr>
              ))
            ) : (
              <tr className="bg-white hover:bg-[#f0f0f0]">
                <td className="td" colSpan={4}>
                  <p className="py-3 min-w-full text-center">
                    {strings.member.member_detail.others.no_request}
                  </p>
                </td>
              </tr>
            )}
          </tbody>
          </table>
        )}
      </div>
    </>
  );
};

export default AddStudentRequest;
