import {useLocalization} from "../../hooks/Utils/useLocalization";
import {Loading, PageTitle, Pagination, SearchBar, SearchCount, showTopAlert} from "../../components";
import {useEffect, useState} from "react";
import {stringToDate} from "../../components/Utility";
import {UseCaseHistoryListDetailSearch, UseCaseHistoryTable} from "./components";
import {UseCaseHistoryListService} from "../../services/UseCaseHistory/UseCaseHistoryService";

const UseCaseHistoryList = () => {

    const strings = useLocalization();
    const limit = parseInt(process.env.REACT_APP_TABLE_ROW_LIMIT);

    const [start, setStart] = useState(0);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecord, setTotalRecord] = useState(0);
    const [data, setData] = useState([]);

    const [detailSearch, setDetailSearch] = useState({
        beginDate: null,
        endDate: null,
        searchText: null
    })

    //Arama verileri değiştiğinde değerleri sıfırlıyoruz
    const resetValue = () => {
        setStart(start => 0);
        setCurrentPage(currentPage => 1);
        setLoading(loading => true);
    }

    //select değişiklikleri bu alandan yönetiliyor
    const changeDetail = (values) => {
        setDetailSearch({ ...detailSearch, ...values });
        resetValue();
    }

    //input değişiklikleri bu alandan yönetiliyor
    const changeValue = (value) => {
        setDetailSearch({ ...detailSearch, searchText: value });
        resetValue();
    }

    //sayfa değiştikçe bilgileri yeniden çağırıyoruz
    useEffect(() => {
        if (totalRecord !== 0) {
            getHistoryList();
        }
    }, [start])

    const getHistoryList = async () => {
        const model = {
            start: start,
            length: limit,
            beginDate: detailSearch.beginDate ? stringToDate(detailSearch.beginDate) : null,
            endDate: detailSearch.endDate ? stringToDate(detailSearch.endDate, 23, 59, 59) : null,
            searchText: detailSearch.searchText
        }
        const result = await UseCaseHistoryListService(model);

        if (result.status === 200) {
            let resultContent = JSON.parse(result.content);
            setData(data => resultContent.data);
            setTotalRecord(totalRecord => resultContent.recordsTotal);
            setLoading(loading => false);
        } else {
            showTopAlert(strings.errors.an_error_occurred, "error");
            setLoading(loading => false);
        }
    }

    useEffect(() => {

        let abortController = new AbortController();

        getHistoryList();

        return () => {
            abortController.abort();
        }
    }, [detailSearch])

    return (
        <>
            <UseCaseHistoryListDetailSearch
                changeDetail={changeDetail}
                loading={loading}
            />
            <SearchBar
                changeValue={changeValue}
                placeHolder={strings.history.list.search_placeholder}
                isDisabled={loading}
            />
            {!loading && <SearchCount count={totalRecord} />}

            <PageTitle title={strings.sidebar.history.list} />
            {loading && (
                <Loading />
            )}
            {!loading && (
                <>
                    <UseCaseHistoryTable data={data} />
                    <Pagination
                        totalCount={totalRecord}
                        limit={limit}
                        start={start}
                        setStart={setStart}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        setLoading={setLoading}
                        loadScreen={true}
                    />
                </>
            )}
        </>
    )
}

export default UseCaseHistoryList;