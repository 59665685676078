import {useLocalization} from "../../../../../hooks/Utils/useLocalization";
import classNames from "classnames";
import React, {useEffect, useState} from "react";
import {formatPrice} from "../../../../../components/Utility";
import {GetBankPosInstallmentByBankPosId} from "../../../../../services/Bank/BankService";

const Installment = (props) => {

    const { state, setState, totalPrice, totalPriceWithoutFormat, currencySymbol, bankPosId, isOneShot } = props;
    const strings = useLocalization();

    const [installmentInfo, setInstallmentInfo] = useState({});

    //Taksitler listeleniyor
    const listInstallment = (maxInstallment = 12, minInstallment = 2, bonusInstallment = 0, totalPrice, currencySymbol) => {
        let installmentButtonList = [];
        for (let installment = minInstallment; installment <= maxInstallment; installment++) {
            installmentButtonList.push(
                <button
                    key={installment}
                    className={classNames('installment-container', { 'bg-[#DCEAFC] border-blue text-blue': state === (installment - bonusInstallment) })}
                    onClick={() => setState(installment - bonusInstallment)}
                >
                    <p className="font-bold">{installment + " " + strings.member.member_detail.sales_operations.payment_option}</p>
                    <p className="text-[12px]">{currencySymbol + " " + formatPrice(totalPrice/installment)}</p>
                </button>
            )
        }

        return installmentButtonList;
    }

    useEffect(() => {
        GetBankPosInstallmentByBankPosId(bankPosId)
            .then(result => {
                if(result.status === 200)
                    setInstallmentInfo(JSON.parse(result.content));
                else
                    setInstallmentInfo({});
            })
            .catch()
    }, [bankPosId]);

    return (
        <div className="flex flex-col gap-1">
            <p className="font-bold">{strings.member.member_detail.sales_operations.payment_options}</p>
            <div className="flex gap-y-1 flex-wrap">
                <button
                    className={classNames('installment-container', {'bg-[#DCEAFC] border-blue text-blue': state === 0})}
                    onClick={() => setState(0)}
                >
                    <p className="font-bold">{strings.member.member_detail.sales_operations.one_shot}</p>
                    <p className="text-[12px]">{totalPrice + " " + currencySymbol}</p>
                </button>

                {!isOneShot &&
                    listInstallment(
                        installmentInfo?.maxInstallment,
                        installmentInfo?.minInstallment,
                        installmentInfo?.bonusInstallment,
                        totalPriceWithoutFormat,
                        currencySymbol
                    )
                }
            </div>
        </div>
    )
}

export default Installment;