import {Dropdown, Input} from "../../../../../components";
import {ApplicationList} from "../../../../../consts/Constants";
import {handleCheckbox} from "../../../../../components/Utility";
import classNames from "classnames";
import React from "react";
import {useLocalization} from "../../../../../hooks/Utils/useLocalization";

const ApplicationRowReadonly = (props) => {

    const { applications, setApplications, item } = props;
    const strings = useLocalization();

    // Eklenen application row'un silinmesi için kullanılan state.
    const removeApplication = (id) => {
        const updatedApplications = applications.filter(item => item.id !== id);
        setApplications(updatedApplications);
    }

    return (
        <>
            <div
                className="flex flex-col xl:flex-row items-center justify-start xl:items-start gap-3 px-5 max-xs:px-2"
            >
                <div className="flex flex-col pb-0 mt-5 w-full">
                    <div className="flex flex-col pb-0 ">
                        <label>
                            {strings.member.member_detail.others.application}
                        </label>
                        <Dropdown
                            data={ApplicationList}
                            selectedData={item.applicationId}
                            classnames="md:max-w-full"
                            isDisabled={true}
                        />
                    </div>
                </div>
                <div className="flex flex-col pb-0 mt-5 w-full">
                    <label htmlFor="web-path">
                        {strings.form_info.web_path}
                    </label>
                    <div className="flex flex-col pb-0 ">
                        <Input
                            id="web-path"
                            type="text"
                            classnames="md:max-w-full"
                            value={item.webPath}
                            isDisabled={true}
                        />
                    </div>
                </div>
                <div className="flex flex-col pb-0 mt-5 w-full">
                    <label htmlFor="mobile-path">
                        {strings.form_info.mobile_path}
                    </label>
                    <div className="flex flex-col pb-0 ">
                        <Input
                            id="mobile-path"
                            type="text"
                            classnames="md:max-w-full"
                            value={item.mobilePath}
                            isDisabled={true}
                        />
                    </div>
                </div>
                <div className="flex flex-col pb-0 mt-5 w-full">
                    <label>{strings.form_info.paid_member}</label>
                    <div className="flex justify-start items-center w-full p-2">
                        <input
                            id="paid-member"
                            type="checkbox"
                            className="hidden"
                            disabled={true}
                        />
                        <label
                            htmlFor="paid-member"
                            className={`${handleCheckbox(item.paidMember)} opacity-70 mr-2 w-6 h-6 rounded-[3px] cursor-default duration-500 flex items-center justify-center`}
                        >
                            <span
                                className={classNames('text-inherit text-[20px] material-symbols-outlined animate-fadeIn font-bold', {'hidden': !item.paidMember})}>
                                done
                            </span>
                        </label>
                    </div>
                </div>
                <div className="flex flex-col pb-0 mt-5 w-full">
                    <label>{strings.form_info.non_paid_member}</label>
                    <div className="flex justify-start items-center w-full p-2">
                        <input
                            id="non-paid-member"
                            type="checkbox"
                            className="hidden"
                            disabled={true}
                        />
                        <label
                            htmlFor="non-paid-member"
                            className={`${handleCheckbox(item.nonPaidMember)} opacity-70 mr-2 w-6 h-6 rounded-[3px] cursor-default duration-500 flex items-center justify-center`}
                        >
                            <span
                                className={classNames('text-inherit text-[20px] material-symbols-outlined animate-fadeIn font-bold', {'hidden': !item.nonPaidMember})}>
                                done
                            </span>
                        </label>
                    </div>
                </div>
                <div className="flex flex-col pb-0 mt-5 w-full">
                    <label>{strings.form_info.demo_member}</label>
                    <div className="flex justify-start items-center w-full p-2">
                        <input
                            id="demo-member"
                            type="checkbox"
                            className="hidden"
                            disabled={true}
                        />
                        <label
                            htmlFor="non-paid-member"
                            className={`${handleCheckbox(item.demoMember)} opacity-70 mr-2 w-6 h-6 rounded-[3px] cursor-default duration-500 flex items-center justify-center`}
                        >
                            <span
                                className={classNames('text-inherit text-[20px] material-symbols-outlined animate-fadeIn font-bold', {'hidden': !item.demoMember})}>
                                done
                            </span>
                        </label>
                    </div>
                </div>
                <div className="flex gap-1">
                    <button
                        type="button"
                        className="flex-shrink-0 xl:mb-2 mt-2 group inline-flex items-center justify-center bg-transparent border border-red text-red w-8 h-8 rounded-[3px] relative xl:mt-[52px]"
                        onClick={() => removeApplication(item.id)}
                    >
                        <span className="material-symbols-outlined text-[18px]">delete</span>
                        <div className="tooltip-top">
                            <p>{strings.button.delete}</p>
                        </div>
                    </button>
                </div>
            </div>
        </>
    )
}

export default ApplicationRowReadonly;