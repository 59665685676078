import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DefaultImage } from "../../assets/svg";
import { Dropdown, DropdownMultiSelect, Input, PageTitle, showTopAlert } from "../../components";
import { createParameterObject, handleCheckbox } from "../../components/Utility";
import { ParameterTypes, RequestTypes } from "../../consts/Constants";
import { useLocalization } from "../../hooks/Utils/useLocalization";
import { url } from "../../routes/utility";
import { CreateUseCaseService } from "../../services/UseCase/UseCaseService";
import { AddApplicationRow, AddTranslationRow, ApplicationRowReadonly, TranslationRowReadonly } from "./components";

const CreateUseCase = () => {

    const strings = useLocalization();
    const navigate = useNavigate();

    //Genel bilgiler alanındaki hataları göstermek için ref'ler.
    const titleEngErrorRef = useRef();
    const promptErrorRef = useRef();

    //Uygulamalar alanındaki hataları göstermek için ref.
    const applicationsErrorRef = useRef();

    //Endpoint alanındaki hataları göstermek için ref'ler.
    const endpointErrorRef = useRef();
    const endpointTestErrorRef = useRef();
    const requestTypeErrorRef = useRef();
    const parametersErrorRef = useRef();

    const [translations, setTranslations] = useState([]);
    const [isShowAddTranslationRow, setIsShowAddTranslationRow] = useState(true);

    const [generalInformations, setGeneralInformations] = useState({
        title: "",
        imageUrl: "",
        prompt: "",
    });
    const [applications, setApplications] = useState([]);
    const [endpointInformations, setEndpointInformations] = useState({
        endpoint: "",
        endpointTest: "",
        requestType: -1,
        requestBody: ""
    });

    const [isClickedCreateButton, setIsClickedCreateButton] = useState(false);

    const changeGeneralInformations = (values) => {
        setGeneralInformations({ ...generalInformations, ...values });
    }

    const changeEndpointInformations = (values) => {
        setEndpointInformations({ ...endpointInformations, ...values });
    }

    const firstRequestType = { id: -1, name: strings.detail_search_titles.choose_request_type };
    const [requestType, setRequestType] = useState(firstRequestType);

    const [parameters, setParameters] = useState([]);
    const [isHaveParameter, setIsHaveParameter] = useState(false);

    //Genel Bilgiler alanı için validasyon yapılıyor.
    const validateGeneralInformations = () => {

        let valid = true;

        titleEngErrorRef.current.innerText = "";
        promptErrorRef.current.innerText = "";

        if(generalInformations.title.length === 0) {
            valid = false;
            titleEngErrorRef.current.innerText = strings.errors.title_eng_null_error;
        }
        if(generalInformations.prompt.length === 0) {
            valid = false;
            promptErrorRef.current.innerText = strings.errors.prompt_null_error;
        }

        return valid;
    }

    //Uygulamalar alanı için validation yapılıyor.
    const validateApplications = () => {

        let valid = true;
        applicationsErrorRef.current.innerText = '';

        if(applications.length === 0) {
            valid = false;
            applicationsErrorRef.current.innerText = strings.errors.applications_null_error;
        }

        return valid;
    }

    const validateEndpointInformations = () => {

        let valid = true;
        requestTypeErrorRef.current.innerText = "";
        if(isHaveParameter) {
            parametersErrorRef.current.innerText = "";
            endpointErrorRef.current.innerText = "";
            endpointTestErrorRef.current.innerText = ""
        }

        if((endpointInformations.endpoint.length !== 0 || endpointInformations.endpointTest.length !== 0) && (endpointInformations.requestType === -1)) {
            valid = false;
            requestTypeErrorRef.current.innerText = strings.errors.request_type_null_error;
        }
        if(isHaveParameter) {

            if(parameters.length === 0) {
                valid = false;
                parametersErrorRef.current.innerText = strings.errors.parameters_null_error;
            }
            if(endpointInformations.endpoint.length === 0) {
                valid = false;
                endpointErrorRef.current.innerText = strings.errors.endpoint_null_error;
            }
            if(endpointInformations.endpointTest.length === 0) {
                valid = false;
                endpointTestErrorRef.current.innerText = strings.errors.endpoint_test_null_error;
            }
        }

        return valid;
    }

    const submitHandle = async (e) => {
        e.preventDefault();
        setIsClickedCreateButton(true);

        if(validateEndpointInformations() && validateApplications() && validateGeneralInformations()) {

            const formattedApplications = applications.map(item => {
                const { id, ...rest } = item;
                return rest;
            });

            const useCaseContent = generalInformations.imageUrl.length > 0 ? [{ contentTypeId: 1, content: generalInformations.imageUrl }] : []

            let model = {
                title: generalInformations.title,
                aiUseCaseApplications: formattedApplications,
                aiUseCaseEndpointUrl: endpointInformations.endpoint,
                aiUseCaseEndpointTestUrl: endpointInformations.endpointTest,
                aIUseCaseEndpointRequestType: endpointInformations.requestType,
                aIUseCaseEndpointRequestBody: endpointInformations.requestBody !== "" ? JSON.stringify(endpointInformations.requestBody) : endpointInformations.requestBody,
                aiUseCaseTranslations: translations,
                aiUseCasePrompt: generalInformations.prompt,
                aiUseCaseContents: useCaseContent
            }

            const result = await CreateUseCaseService(model);

            if(result.status === 200) {
                showTopAlert(strings.use_case.create.success_message, "success");
                navigate(url("usecase.list"));
            } else {
                showTopAlert(strings.errors.an_error_occurred, "error");
            }
        }
    }

    // Applications array'i ters çeviriliyor ve kullanıcının eklediği son uygulamanın en üste gelmesi sağlanıyor.
    useEffect(() => {
        const reversedTmpArr = [...applications].reverse();
        setApplications(applications => reversedTmpArr);
    }, [applications.length]);

    /* Endpoint */

    //Request Body oluşturuluyor
    useEffect(() => {
        if(parameters.length === 0){
            changeEndpointInformations({ requestBody: "" });
        } else {
            changeEndpointInformations({ requestBody: createParameterObject(parameters) });
        }
    }, [parameters])

    //Request type dropdown'ından veri alınıyor
    useEffect(() => {
        changeEndpointInformations({ requestType: requestType.id })
    }, [requestType]);

    useEffect(() => {
        if(!isHaveParameter) {
            setParameters(parameters => []);
            changeEndpointInformations({ requestBody: "" })
        }
    }, [isHaveParameter])
    /* Endpoint */

    useEffect(() => {
        isClickedCreateButton && validateGeneralInformations();
    }, [isClickedCreateButton, generalInformations]);

    useEffect(() => {
        isClickedCreateButton && validateApplications();
    }, [isClickedCreateButton, applications]);

    useEffect(() => {
        isClickedCreateButton &&  validateEndpointInformations()
    }, [isClickedCreateButton, endpointInformations, isHaveParameter, parameters]);

    return (
        <>
            <PageTitle title={strings.sidebar.use_case.create} />
            <div className="h-[100px] rounded-md mb-8">
                <div className="flex justify-center">
                    <div className="relative mt-8 flex flex-col items-center">
                        <img
                            className="w-[100px] h-[100px] shadow-profilePhoto rounded-full border-[2px]"
                            src={!generalInformations.imageUrl ? DefaultImage : generalInformations.imageUrl}
                            alt=""
                        />
                        <p className="text-lg font-bold text-center">{strings.form_info.application_image}</p>
                    </div>
                </div>
            </div>
            <div className="p-5 max-xs:px-2">
                {/* General Informations */}
                <h5 className="font-bold text-[15px] pl-3 my-3">
                    {strings.form_info.general_informations}
                </h5>
                <hr />
                <form onSubmit={submitHandle}>
                    <div className="flex flex-col lg:flex-row lg:gap-x-5 lg:justify-between">
                        <div className="mt-5 w-full lg:w-[32.5%]">
                            <div className="flex flex-col px-[10px] pb-0">
                                <label htmlFor="title-eng">
                                    {strings.form_info.title_english} <span className="text-red">*</span>
                                </label>
                                <Input
                                    name="title-eng"
                                    type="text"
                                    onChange={(e) => changeGeneralInformations({ title: e.target.value })}
                                    classnames="md:max-w-[480px]"
                                />
                                <span ref={titleEngErrorRef} className="text-danger field-validation-valid ml-2 mt-1"></span>
                            </div>
                        </div>
                        <div className="mt-5 w-full lg:w-[32.5%]">
                            <div className="flex flex-col px-[10px] pb-0">
                                <label htmlFor="application-image" className="min-w-max">{strings.form_info.application_image_url}</label>
                                <Input
                                    name="application-image"
                                    type="text"
                                    onChange={(e) => changeGeneralInformations({ imageUrl: e.target.value })}
                                    classnames="md:max-w-[480px]"
                                />
                            </div>
                        </div>
                        <div className="mt-5 w-full lg:w-[32.5%]">
                        </div>
                    </div>
                    <div className="flex flex-col px-[10px] pb-0 w-full my-5">
                        <div className="flex gap-2 items-end">
                            <label htmlFor="prompt">{strings.form_info.prompt} <span className="text-red">*</span></label>
                            <div className="relative group">
                                <span className="material-symbols-outlined cursor-pointer">info</span>
                                <div className="tooltip-top !-top-[75px]">
                                    <p>{strings.use_case.create.prompt_tooltip_text}</p>
                                </div>
                            </div>
                        </div>
                        <textarea
                            name="prompt"
                            className={`border rounded-lg outline-0 focus:border-blue py-3 px-2 w-full`}
                            rows="5"
                            onChange={(e) => changeGeneralInformations({ prompt: e.target.value })}
                        ></textarea>
                        <span ref={promptErrorRef} className="text-danger field-validation-valid ml-2 mt-1"></span>
                    </div>
                    {/* General Informations */}

                    {/* Applications */}
                    <div className="flex items-center gap-2">
                        <h5 className="font-bold text-[15px] pl-3 my-3">
                            {strings.form_info.application_title}
                        </h5>
                        <span ref={applicationsErrorRef} className="text-danger field-validation-valid"></span>
                    </div>
                    <hr />

                    {/* Yeni Uygulama eklemek için kullanılan component */}
                    <AddApplicationRow
                        applications={applications}
                        setApplications={setApplications}
                    />

                    {/* Eklenen uygulamaların listelenmesi için kullanılan component */}
                    {applications.map((application, index) => (
                        <ApplicationRowReadonly
                            key={index}
                            item={application}
                            applications={applications}
                            setApplications={setApplications}
                        />
                    ))}
                    {/* Applications */}

                    {/* Endpoint */}
                    <h5 className="font-bold text-[15px] pl-3 my-3 mt-5">
                        <p>{strings.form_info.endpoint_title}</p>
                    </h5>
                    <hr />
                    <div className="px-3">
                        <div className="flex flex-col lg:flex-row lg:gap-x-5 lg:justify-between">
                            <div className="mt-5 w-full lg:w-[32.5%]">
                                <div className="flex flex-col px-[10px] pb-0">
                                    <label htmlFor="endpoint">
                                        {strings.form_info.endpoint}
                                        {isHaveParameter && (
                                            <span className="text-red"> *</span>
                                        )}
                                    </label>
                                    <Input
                                        name="endpoint"
                                        type="text"
                                        onChange={(e) => changeEndpointInformations({ endpoint: e.target.value })}
                                        classnames="md:max-w-[480px]"
                                    />
                                    <span ref={endpointErrorRef} className="text-danger field-validation-valid ml-2 mt-1"></span>
                                </div>
                            </div>
                            <div className="mt-5 w-full lg:w-[32.5%]">
                                <div className="flex flex-col px-[10px] pb-0">
                                    <label htmlFor="endpoint-test">
                                        {strings.form_info.endpoint_test}
                                        {isHaveParameter && (
                                            <span className="text-red"> *</span>
                                        )}
                                    </label>
                                    <Input
                                        name="endpoint-test"
                                        type="text"
                                        onChange={(e) => changeEndpointInformations({ endpointTest: e.target.value })}
                                        classnames="md:max-w-[480px]"
                                    />
                                    <span ref={endpointTestErrorRef} className="text-danger field-validation-valid ml-2 mt-1"></span>
                                </div>
                            </div>
                            <div className="mt-5 w-full lg:w-[32.5%]">
                                <div className="flex flex-col px-[10px] pb-0">
                                    <label htmlFor="endpoint">
                                        {strings.form_info.request_type}
                                        {((endpointInformations.endpoint.length > 0 || endpointInformations.endpointTest.length > 0) || (isHaveParameter)) && (
                                            <span className="text-red"> *</span>
                                        )}
                                    </label>
                                    <Dropdown
                                        data={[firstRequestType, ...RequestTypes]}
                                        selectedData={requestType}
                                        setSelectedData={setRequestType}
                                        classnames="md:max-w-[480px]"
                                    />
                                    <span ref={requestTypeErrorRef} className="text-danger field-validation-valid ml-2 mt-1"></span>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col lg:flex-row lg:gap-x-5 lg:justify-between">
                            <div className="mt-5 w-full lg:w-[32.5%]">
                                <div className="flex flex-col px-[10px] pb-0">
                                    <label htmlFor="endpoint">{strings.form_info.is_have_parameter}</label>
                                    <div className="flex justify-center w-[60px]">
                                        <input
                                            id="is-have-parameter"
                                            type="checkbox"
                                            className="hidden"
                                            onClick={() => setIsHaveParameter(!isHaveParameter)}
                                        />
                                        <label
                                            htmlFor="is-have-parameter"
                                            className={`${handleCheckbox(isHaveParameter)} mr-2 w-6 h-6 rounded-[3px] cursor-pointer duration-500 flex items-center justify-center`}
                                        >
                                    <span className={classNames('text-inherit text-[25px] material-symbols-outlined animate-fadeIn font-bold', { 'hidden' : !isHaveParameter})}>
                                      done
                                    </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 w-full lg:w-[32.5%]">
                                {isHaveParameter && (
                                    <div className="flex flex-col px-[10px] pb-0">
                                        <label htmlFor="endpoint">
                                            {strings.form_info.parameters}
                                            {isHaveParameter && (
                                                <span className="text-red"> *</span>
                                            )}
                                        </label>
                                        <DropdownMultiSelect
                                            randomId="parameterTypesMS"
                                            title={strings.detail_search_titles.parameters}
                                            data={ParameterTypes || []}
                                            selectedData={parameters}
                                            setSelectedData={setParameters}
                                            classnames="md:max-w-[480px]"
                                            inputPlaceholder={strings.detail_search_titles.search_parameters}
                                        />
                                        <span ref={parametersErrorRef} className="text-danger field-validation-valid ml-2 mt-1"></span>
                                    </div>
                                )}
                            </div>
                            <div className="mt-5 w-full lg:w-[32.5%]"></div>
                        </div>
                        {isHaveParameter && (
                            <div className="flex flex-col px-[10px] pb-0 w-full my-5">
                                <label htmlFor="request_body">{strings.form_info.request_body}</label>
                                <textarea
                                    name="request_body"
                                    className={`border rounded-lg outline-0 focus:border-blue py-3 px-2 w-full`}
                                    rows="5"
                                    disabled={true}
                                    value={parameters.length === 0 ? "" : JSON.stringify(endpointInformations.requestBody, null, 4)}
                                ></textarea>
                            </div>
                        )}
                    </div>
                    {/* Endpoint */}

                    {/* Translations */}
                    <h5 className="font-bold text-[15px] pl-3 my-3">
                        <p>{strings.form_info.title_translations}</p>
                    </h5>
                    <hr />
                    {/* Yeni uygulama eklenirken kullanılan component */}
                    {isShowAddTranslationRow && (
                        <AddTranslationRow
                            translations={translations}
                            setTranslations={setTranslations}
                            setIsShowAddTranslationRow={setIsShowAddTranslationRow}
                        />
                    )}
                    {/* Eklenen uygulamalar listelenirken kullanılan component */}
                    {translations.map((translation, index) => (
                        <TranslationRowReadonly
                            key={index}
                            item={translation}
                            translations={translations}
                            setTranslations={setTranslations}
                        />
                    ))}
                    {/* Translations */}

                    <button
                        type="submit"
                        className="button search-button text-white bg-blue border-blue mt-7 mx-auto px-7 mb-5"
                    >
                        {strings.button.create}
                    </button>
                </form>
            </div>
        </>
    )
}

export default CreateUseCase;