import React from 'react'
import { CopyToClipboard, NoRecordsFound, showTopAlert } from '../../../../../../components'
import { maskPhoneNumber } from '../../../../../../components/Utility';
import { useLocalization } from '../../../../../../hooks/Utils/useLocalization';

const SelectReferenceModal = (props) => {
    const { data, setSelectedReference, closeModal } = props;
    const strings = useLocalization();

    return (
        <div className="mx-auto px-4">
            <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
                <div className="inline-block min-w-full rounded-lg overflow-hidden">
                    <table className="min-w-full leading-normal text-header">
                        <thead>
                            <tr>
                                <th className="th">{strings.table.name}</th>
                                <th className="th">{strings.form_info.phone_number}</th>
                                <th className="th">{strings.member.member_detail.others.email}</th>
                                <th className="th"><p className='min-w-max'>{strings.member.candidate_pool.log.td_title1}</p></th>
                                <th className="th"><p className='min-w-max'>{strings.detail_search_titles.productivity}</p></th>
                                <th className="th">{strings.table.operation}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.length > 0 && (
                                <>
                                    {data.map((item, index) => (
                                        <tr key={index} className="bg-white hover:bg-[#f0f0f0]">
                                            <td className="td align-middle py-4"><p className='min-w-max'>{item.fullName || strings.errors.it_is_not_specified}</p></td>
                                            <td className="td align-middle py-4">
                                                <div className='flex items-center gap-1'>
                                                    <div className='min-w-max'>{maskPhoneNumber(item.phoneNumber)}</div>
                                                    <CopyToClipboard text={item.phoneNumber} className="-mb-1" iconClassName="!text-[13px]" />
                                                </div>
                                            </td>
                                            <td className="td align-middle py-4"><p className='min-w-max'>{item.email || strings.errors.it_is_not_specified}</p></td>
                                            <td className="td align-middle py-4"><p className='min-w-max'>{item.productivityPersonFullName || strings.errors.it_is_not_specified}</p></td>
                                            <td className="td align-middle py-4"><p className='min-w-max'>{item.salesPersonFullName || strings.errors.it_is_not_specified}</p></td>
                                            <td className="td align-middle py-4 max-w-[170px]">
                                                <button
                                                    className='font-bold hover:text-blue hover:underline transition-colors duration-300'
                                                    onClick={() => {
                                                        setSelectedReference(selectedReference => item);
                                                        showTopAlert(strings.member.member_detail.create_reference.select_reference_success_message, "info");
                                                        closeModal();
                                                    }}
                                                >
                                                    {strings.button.choose_upper}</button>
                                            </td>
                                        </tr>
                                    ))}
                                </>
                            )}

                            {data?.length === 0 && (
                                <tr className="bg-white hover:bg-[#f0f0f0]">
                                    <td colSpan="6">
                                        <NoRecordsFound />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default SelectReferenceModal