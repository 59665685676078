import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import DatePicker from 'react-datepicker';
import { DetailSearchLayout, Dropdown } from "../../../../components";
import { getddMMyyyyDate } from '../../../../components/Utility';
import { useLocalization } from "../../../../hooks/Utils/useLocalization";
import usePrimeData from "../../../../hooks/Utils/usePrimeData";

const SalesmanReportDetailSearch = (props) => {

    const { changeDetail, loading, employee, employeeTypeId, selectedDate, setSelectedDate, permission } = props

    const strings = useLocalization();
    const primeData = usePrimeData();

    const [isOpen, setIsOpen] = useState(false);
    const [canChangeBrand, setCanChangeBrand] = useState(false);
    const [brand, setBrand] = useState({})

    const brandList = primeData.brandList

    const firstVisit = useRef(true);

    useEffect(() => {
        //Sayfa ilk kez yüklenmiyorsa işlemi yap
        //Servise birden çok gez gitmesini engelliyoruz
        if (!firstVisit.current) {

            changeDetail?.({ brandId: brand?.id });
            firstVisit.current = false;
        }
    }, [brand])

    useEffect(() => {
        //Sayfa ilk kez yüklenmiyorsa işlemi yap
        //Servise birden çok gez gitmesini engelliyoruz
        if (!firstVisit.current) {
            changeDetail?.({ selectedDate: selectedDate && getddMMyyyyDate(selectedDate) });
            firstVisit.current = false;
        }
    }, [selectedDate])

    useEffect(() => {
        if (firstVisit.current) {
            firstVisit.current = false;
        }
        //Başlangıçta bugünün seçili gelmesini sağlıyoruz
        setSelectedDate(selectedDate => new Date())

        // Başlangıçta kullanıcının kendi markasının geçili gelmesini sağlıyoruz
        // let tmpBrand = brandList.find(brand => brand.id === parseInt(employee.BrandId))
        setBrand(brand => parseInt(employee.BrandId))
        changeDetail?.({ brandId: parseInt(employee.BrandId) });

        const allowedEmployeeTypeIds = [1, 2, 3, 18];

        allowedEmployeeTypeIds.includes(employeeTypeId)
            ? setCanChangeBrand(canChangeBrand => true)
            : setCanChangeBrand(canChangeBrand => false)
    }, [])

    return (
        <>
            {permission && (
                <DetailSearchLayout
                    isDisabled={loading}
                    open={true}
                    children={
                        <div className="flex gap-x-4 gap-y-5 max-md-lg:justify-center flex-wrap">
                            <div className="relative w-full md:max-w-[246px]">
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    className="form-input w-full md:max-w-[246px]"
                                    selected={selectedDate}
                                    onChange={(date) => {
                                        setSelectedDate(date);
                                        setIsOpen(false);
                                    }}
                                    placeholderText={
                                        strings.form_info.date_placeholder
                                    }
                                    onClickOutside={() => setIsOpen(false)}
                                    open={isOpen}
                                    onFocus={() => setIsOpen(true)}
                                    maxDate={new Date()}
                                    disabled={loading}
                                />
                                <button
                                    type="button"
                                    onClick={() => setIsOpen(!isOpen)}
                                    disabled={!canChangeBrand ? true : loading}
                                >
                                    <span className={classNames("material-symbols-outlined absolute right-6 top-2 text-lg", {
                                        "!cursor-default": (!canChangeBrand || loading)
                                    })}>
                                        calendar_today
                                    </span>
                                </button>
                            </div>
                            <Dropdown
                                data={brandList}
                                selectedData={brand}
                                setSelectedData={setBrand}
                                isDisabled={!canChangeBrand ? true : loading}
                                tooltip={!canChangeBrand && strings.errors.not_authorized}
                            />
                        </div>
                    }
                />
            )}
        </>
    )
}

export default SalesmanReportDetailSearch