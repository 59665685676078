import {Modal, NoRecordsFound} from "../../../../components";
import {useLocalization} from "../../../../hooks/Utils/useLocalization";
import SalesmanReportTableRow from "./SalesmanReportTableRow";
import React, {useState} from "react";
import MemberListModal from "./MemberListModal";

const SalesmanReportTable = (props) => {

  const { employeeReports, brandId, selectedDate } = props

  const strings = useLocalization();

  const [modal, setModal] = useState(false);
  const [employeeId, setEmployeeId] = useState("");
  const [source, setSource] = useState("");

  return (
      <>
        <div className="mx-auto px-4 my-4">
          <div
            className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
            <div className="inline-block min-w-full rounded-lg overflow-hidden">
              <table className="min-w-full leading-normal text-header">
                <thead>
                  <tr>
                    <th className="th">
                      <p className="ml-6">{strings.table.brand}</p>
                    </th>
                    <th className="th">
                      <p className="min-w-max">
                        {strings.table.employee_informations}
                      </p>
                    </th>
                    <th className="th">
                      <p className="min-w-max">
                        {strings.table.employee_status}
                      </p>
                    </th>
                    <th className="th">
                      <p className="min-w-max">
                        {strings.report.total_candidate_count}
                      </p>
                    </th>
                    <th className="th">
                      <p className="min-w-max">
                        {strings.report.number_of_get_candidate}
                      </p>
                    </th>
                    <th className="th">
                      <p className="min-w-max">
                        {strings.report.number_of_pool_drop}
                      </p>
                    </th>
                    <th className="th">
                      <p className="min-w-max">
                        {strings.report.reminder_count}
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {employeeReports.length > 0 ?
                    (
                      employeeReports.map((employee, index) => (
                        <SalesmanReportTableRow
                          key={index}
                          employee={employee}
                          brandId={brandId}
                          setModal={setModal}
                          setSource={setSource}
                          setEmployeeId={setEmployeeId}
                        />
                      ))
                    )
                    :
                    (
                      <tr className="bg-white hover:bg-[#f0f0f0]">
                        <td colSpan="7">
                          <NoRecordsFound />
                        </td>
                      </tr>
                    )
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <Modal
            modal={modal}
            setModal={setModal}
            classes={{ modal: "h-[450px] max-w-[620px]" }}
        >
          <MemberListModal
              source={source}
              selectedDate={selectedDate}
              employeeId={employeeId}
          />
        </Modal>

      </>
  );
}

export default SalesmanReportTable