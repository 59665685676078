const Select = (props) => {

  const classname = typeof props.classnames === "undefined" ? '' : props.classnames;
  return (
    <>
      <select
        id={props.id}
        ref={props.reference}
        value={props.selectedData}
        disabled={props.isDisabled}
        className={`form-input ${classname}`}
        onChange={(e) => {
          props.setSelectedData(parseInt(e.target.value))
        }}
      >
        {props.options &&
          props.options.map((option, index) => (
            <option key={index} value={option.id}>
              {option.name}
            </option>
          ))}
      </select>
    </>
  );
};

export default Select;
