import { useEffect, useRef, useState } from "react";
import { DetailSearchLayout, Dropdown, DropdownSearch, SwitchButton } from "../../../../components";
import { GeneralStatusTypes, LessonCountSelect, LessonDurationType, ProductDayCount } from "../../../../consts/Constants";
import useAuth from "../../../../hooks/Log/useAuth";
import { useLocalization } from "../../../../hooks/Utils/useLocalization";
import usePrimeData from "../../../../hooks/Utils/usePrimeData";
import { getProductivityPerson, getSalesPerson } from "../Common/GetPerson";

const StudentDetailSearch = ({ changeDetail, loading }) => {

  const primeData = usePrimeData();
  const strings = useLocalization();
  const { employee }  = useAuth();

  //Kalan ders sayısı için dropdown öğeleri
  const firstVisit = useRef(true);

  const firstBrand = { id: -1, name: strings.detail_search_titles.brands };
  const brandList = [firstBrand, ...primeData.brandList];

  const firstStatusType = { id: -1, name: strings.detail_search_titles.students };
  const statusTypeList = [firstStatusType, ...GeneralStatusTypes];

  const firstTeacher = { id: -1, name: strings.detail_search_titles.teachers };
  const teacherList = [firstTeacher, ...primeData.teacherListWithJosiah];

  const firstSales = { id: null, name: strings.detail_search_titles.sales };
  const firstProductivity = { id: null, name: strings.detail_search_titles.all_productivity };

  const firstLessonCount = { id: -1, name: strings.detail_search_titles.lesson_count };
  const lessonCountList = [firstLessonCount, ...LessonCountSelect];

  const firstProductDayCount = { id: null, name: strings.detail_search_titles.product_day_count };
  const productDayCountList = [firstProductDayCount, ...ProductDayCount];

  const firstLessonDurationType = { id: null, name: strings.detail_search_titles.lesson_duration };
  const lessonDurationTypeList = [firstLessonDurationType, ...LessonDurationType];

  const [brand, setBrand] = useState(firstBrand);
  const [status, setStatus] = useState(firstStatusType);
  const [teacher, setTeacher] = useState(firstTeacher);

  const [sales, setSales] = useState(firstSales);
  const [salesList, setSalesList] = useState([]);
  const [productivity, setProductivity] = useState(firstProductivity);
  const [productivityList, setProductivityList] = useState([]);
  const [lessonCount, setLessonCount] = useState(firstLessonCount);
  const [lessonDurationType, setLessonDurationType] = useState(firstLessonDurationType);
  const [productDayCount, setProductDayCount] = useState(firstProductDayCount);

  const [isSubstituteTeacher, setIsSubstituteTeacher] = useState(false);

  useEffect(() => {
    if (firstVisit.current) {
      getSalesPerson(brand.id, firstSales, setSalesList);
      getProductivityPerson(brand.id, firstProductivity, setProductivityList);
      firstVisit.current = false;
    }

  }, [])

  useEffect(() => {
    //Sayfa ilk kez yüklenmiyorsa işlemi yap
    //Servise birden çok gez gitmesini engelliyoruz
    if (!firstVisit.current) {
      //Marka değişince seçili satış ve verimlilik personelini sıfırlıyoruz
      setSales(sales => firstSales);
      setProductivity(productivity => firstProductivity);

      //Marka değiştiği için yeni marka ile satış bve verimlilik personeli servisine istek atıyoruz
      getSalesPerson(brand.id, firstSales, setSalesList);
      getProductivityPerson(brand.id, firstProductivity, setProductivityList);

      changeDetail?.({ brandId: brand?.id });
      firstVisit.current = false;
    }
  }, [brand])

  useEffect(() => {
    //Sayfa ilk kez yüklenmiyorsa işlemi yap
    //Servise birden çok gez gitmesini engelliyoruz
    if (!firstVisit.current) {
      changeDetail?.({ status: status.id });
      firstVisit.current = false;
    }
  }, [status])

  useEffect(() => {
    //Sayfa ilk kez yüklenmiyorsa işlemi yap
    //Servise birden çok gez gitmesini engelliyoruz
    if (!firstVisit.current) {
      changeDetail?.({ teacherId: teacher.id });
      firstVisit.current = false;
    }
  }, [teacher])

  useEffect(() => {
    //Sayfa ilk kez yüklenmiyorsa işlemi yap
    //Servise birden çok gez gitmesini engelliyoruz
    if (!firstVisit.current) {
      changeDetail?.({ salesPersonId: sales.id });
      firstVisit.current = false;
    }
  }, [sales])

  useEffect(() => {
    //Sayfa ilk kez yüklenmiyorsa işlemi yap
    //Servise birden çok gez gitmesini engelliyoruz
    if (!firstVisit.current) {
      changeDetail?.({ productivityPersonId: productivity.id });
      firstVisit.current = false;
    }
  }, [productivity])

  useEffect(() => {
    //Sayfa ilk kez yüklenmiyorsa işlemi yap
    //Servise birden çok gez gitmesini engelliyoruz
    if (!firstVisit.current) {
      changeDetail?.({ isSubstituteTeacher: isSubstituteTeacher });
      firstVisit.current = false;
    }
  }, [isSubstituteTeacher])

  useEffect(() => {
    //Sayfa ilk kez yüklenmiyorsa işlemi yap
    //Servise birden çok gez gitmesini engelliyoruz
    if (!firstVisit.current) {
      changeDetail?.({ lessonCountSelectIndex: lessonCount.id });
      firstVisit.current = false;
    }
  }, [lessonCount])

  useEffect(() => {
    //Sayfa ilk kez yüklenmiyorsa işlemi yap
    //Servise birden çok gez gitmesini engelliyoruz
    if (!firstVisit.current) {
      changeDetail?.({ lessonDurationType: lessonDurationType.id });
      firstVisit.current = false;
    }
  }, [lessonDurationType])

  useEffect(() => {
    //Sayfa ilk kez yüklenmiyorsa işlemi yap
    //Servise birden çok gez gitmesini engelliyoruz
    if (!firstVisit.current) {
      changeDetail?.({ productDayCount: productDayCount.id });
      firstVisit.current = false;
    }
  }, [productDayCount])

  useEffect(() => {
    if(brandList.length > 1) {
      const selectedBrand = brandList.find(brand => brand.id === parseInt(employee.BrandId))
      setBrand(brand => selectedBrand)
    }
  }, [primeData.brandList])

  return (
    <>
      <DetailSearchLayout
        isDisabled={loading}
        children={
          <div className="flex gap-x-4 gap-y-5 max-md-lg:justify-center flex-wrap">
            <Dropdown
              title={strings.detail_search_titles.brands}
              data={brandList}
              selectedData={brand}
              setSelectedData={setBrand}
              isDisabled={loading}
            />
            <Dropdown
              title={strings.detail_search_titles.students}
              data={statusTypeList}
              selectedData={status}
              setSelectedData={setStatus}
              isDisabled={loading}
            />
            <DropdownSearch
              inputPlaceholder={strings.detail_search_titles.search_teacher}
              title={strings.detail_search_titles.types}
              data={teacherList}
              selectedData={teacher}
              setSelectedData={setTeacher}
              isDisabled={loading}
            />
            <DropdownSearch
              title={strings.detail_search_titles.sales}
              inputPlaceholder={strings.detail_search_titles.search_sales}
              data={salesList}
              selectedData={sales}
              setSelectedData={setSales}
              isDisabled={brand.id === -1 ? true : loading}
              tooltip={brand.id === -1 && strings.detail_search_titles.first_choose_brand}
            />
            <DropdownSearch
              title={strings.detail_search_titles.all_productivity}
              inputPlaceholder={strings.detail_search_titles.search_productivity}
              data={productivityList}
              selectedData={productivity}
              setSelectedData={setProductivity}
              isDisabled={brand.id === -1 ? true : loading}
              tooltip={brand.id === -1 && strings.detail_search_titles.first_choose_brand}
            />
            <Dropdown
              title={strings.detail_search_titles.all_productivity}
              data={lessonCountList}
              selectedData={lessonCount}
              setSelectedData={setLessonCount}
              isDisabled={loading}
            />
            <Dropdown
              title={strings.detail_search_titles.all_product_day_counts}
              data={productDayCountList}
              selectedData={productDayCount}
              setSelectedData={setProductDayCount}
              isDisabled={loading}
            />
            <Dropdown
              title={strings.detail_search_titles.all_lesson_durations}
              data={lessonDurationTypeList}
              selectedData={lessonDurationType}
              setSelectedData={setLessonDurationType}
              isDisabled={loading}
            />

            <SwitchButton
              state={isSubstituteTeacher}
              setState={setIsSubstituteTeacher}
              text={{ open: strings.detail_search_titles.add_teacher, close: strings.detail_search_titles.remove_teacher }}
              loading={loading}
            />
          </div>
        }
      />
    </>
  );
};

export default StudentDetailSearch;
