import { fetchApi } from '../index';

//Takvimde listelenecek derslerin bilgisini getiren servis.
export const TermScheduleList = async (studentNo) => {

    const url = '/member/v1/term_schedule/list/';

    const payload = {
        studentNo: studentNo
    };

    return await fetchApi(url, payload);
}

export const TermScheduleListByTermId = async (termId) => {

    const url = '/member/v1/term_schedule/list_by_term_id/';

    const payload = {
        termId: termId
    };

    return await fetchApi(url, payload);   
}

export const TermScheduleListByMonthAndYear = async (model) => {
    const url = '/member/v2/term_schedule/get_term_schedule_list_by_month_and_year/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);   
}

export const UpdateIsFridayAndLessonFreeTalk = async (model) => {

    const url = '/member/v1/term_schedule/update_is_friday_and_lesson_free_talk/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);   
}