import { fetchApi } from '../index';

export const LogService = async (event, createUserId, employeeId, memberId, termId) => {
  
    const url = '/prime_log/v1/log/create/';

    const payload = employeeId ? 
            {
                description: event,
                sourceType: 2,
                userType: 1,
                createUser: createUserId,
                employeeId: employeeId,
            }
        : memberId ?
            {
                description: event,
                sourceType: 2,
                userType: 1,
                createUser: createUserId,
                memberId: memberId
            }
        : termId ?
            {
                description: event,
                sourceType: 2,
                userType: 1,
                createUser: createUserId,
                termId: termId
            }
        :
            {
                description: event,
                sourceType: 2,
                userType: 1,
                createUser: createUserId,
            };

    return await fetchApi(url, payload);
};