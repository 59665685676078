import classNames from 'classnames';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Loading, PageTitle, showTopAlert } from '../../../../../components'
import { PermissionTypes } from '../../../../../components/Utility';
import { useLocalization } from '../../../../../hooks/Utils/useLocalization';
import { GetMemberGeneralPermissions } from '../../../../../services/Permission/PermissionService';

const LessonRecord = () => {

    const strings = useLocalization();
    const { memberId } = useParams() || false;

    const [loading, setLoading] = useState(true);
    const [permissions, setPermissions] = useState([])

    const getMemberGeneralPermissions = async (memberId) => {
        const result = await GetMemberGeneralPermissions(memberId);
        if (result.status === 200) {
            const resultContent = JSON.parse(result.content);
            setPermissions(permissions => resultContent)
        } else {
            showTopAlert(strings.errors.an_error_occurred, "error");
        }
        setLoading(loading => false);
    };

    useEffect(() => {
        memberId && getMemberGeneralPermissions(memberId);
    }, []);

    return (
        <>
            <div className="overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
                <PageTitle title={strings.member.member_detail.others.lesson_record_permission} />
                <div className="inline-block min-w-full rounded-lg overflow-hidden">
                    {loading ? (
                        <Loading />
                    ) : (
                        <table className="min-w-full leading-normal text-header">
                            <thead>
                                <tr>
                                    <th className="th">{strings.member.member_detail.others.permission_type}</th>
                                    <th className="th w-[170px]">{strings.member.member_detail.others.permission_status}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {PermissionTypes.map((permissionType, index) => (
                                    permissionType.id === 2 &&
                                    <tr key={index} className="bg-white hover:bg-[#f0f0f0]">
                                        <td className="td align-middle py-4">
                                            {permissionType.name}
                                        </td>
                                        <td className="td align-middle py-4">
                                            <div
                                                className={classNames("mr-2 w-5 h-5 flex-shrink-0 rounded-[3px] duration-500 flex items-center justify-center", {
                                                    "bg-green border border-green text-white": !permissions.some(permission => permission === permissionType.id),
                                                    "border border-[#aaaaaa]": permissions.some(permission => permission === permissionType.id)
                                                })}
                                            >
                                                <span className={classNames('text-inherit text-[20px] material-symbols-outlined animate-fadeIn font-bold', { 'hidden': permissions.some(permission => permission === permissionType.id) })}>
                                                    done
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </>
    )
}

export default LessonRecord