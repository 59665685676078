import {Dropdown, Input} from "../../../../../components";
import {LanguageList} from "../../../../../consts/Constants";
import {useLocalization} from "../../../../../hooks/Utils/useLocalization";

const TranslationRowReadonly = (props) => {

    const { item, translations, setTranslations } = props;
    const strings = useLocalization();

    const removeTranslation = (languageId) => {
        const updatedTranslations = translations.filter(item => item.languageId !== languageId);
        setTranslations(updatedTranslations);
    }

    return(
        <div className="flex flex-col xl:flex-row items-center gap-3 px-5 max-xs:px-2 max-h-screen transition-max-height duration-1000">
            <div className="flex flex-col pb-0 mt-5 w-full">
                <div className="flex flex-col pb-0">
                    <label>{strings.form_info.language}</label>
                    <Dropdown
                        data={LanguageList}
                        selectedData={item.languageId}
                        classnames="md:max-w-full"
                        isDisabled={true}
                    />
                </div>
            </div>
            <div className="flex flex-col pb-0 mt-5 w-full">
                <label htmlFor="title-translations">{strings.form_info.title_translation}</label>
                <div className="flex xs:gap-10">
                    <Input
                        id="title-translations"
                        type="text"
                        classnames="md:max-w-full"
                        value={item.title}
                        isDisabled={true}
                    />
                </div>
            </div>
            <div className="flex flex-col pb-0 mt-5 w-full">
                <label htmlFor="title-translation-information">{strings.form_info.information}</label>
                <div className="flex xs:gap-10">
                    <Input
                        id="title-translation-information"
                        type="text"
                        classnames="md:max-w-full"
                        value={item.information}
                        isDisabled={true}
                    />
                </div>
            </div>
            <div className="flex gap-1">
                <button
                    type="button"
                    className="flex-shrink-0 xl:mb-2 mt-2 group inline-flex items-center justify-center bg-transparent border border-red text-red w-8 h-8 rounded-[3px] relative xl:mt-[52px]"
                    onClick={() => removeTranslation(item.languageId)}
                >
                    <span className="material-symbols-outlined text-[18px]">delete</span>
                    <div className="tooltip-top">
                        <p>{strings.button.delete}</p>
                    </div>
                </button>
            </div>
        </div>

    )
}

export default TranslationRowReadonly;