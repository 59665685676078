import { useRef } from "react";
import { useLocalization } from "../../../../../hooks/Utils/useLocalization";
import { Input, showTopAlert } from "../../../../../components";
import { useState } from "react";
import ReferenceInfo from "./ReferenceInfo";
import { useParams } from "react-router-dom";
import { AddReferrer } from "../../../../../services/Member/MemberReferenceService";
import useDataLayer from "../../../../../hooks/Log/useDataLayer";
import {CustomEventDescriptions, CustomEvents} from "../../../../../consts/DataLayer";
import {ServiceErrorMessages} from "../../../../../consts/Constants";
import useAuth from "../../../../../hooks/Log/useAuth";

const AddReferrerMember = () => {

  const strings = useLocalization();
  const dataLayer = useDataLayer();

  const { memberId } = useParams() || false;
  const { employee } = useAuth();
  const [refreshInfo, setRefreshInfo] = useState(false);

  const referenceCodeErrorRef = useRef();
  const [referenceCode, setReferenceCode] = useState("");

  const submitHandle = async (e) => {
    e.preventDefault();

    let valid = true;
    referenceCodeErrorRef.current.innerText = "";

    if (referenceCode === "") {
      valid = false;
    }

    if (!memberId) {
      valid = false;
    }

    if (valid) {
      const result = await AddReferrer(memberId, referenceCode, employee?.EmployeeId);

      if (result.status === 200) {
        setRefreshInfo(refreshInfo => true);
        setReferenceCode(referenceCode => "");
        showTopAlert(strings.member.member_detail.add_refferrer.success_message, "success");

        //DATA LAYER LOGGED
        dataLayer.useCustomEvents(CustomEvents.EMPLOYEE_EVENTS, CustomEventDescriptions.REFERENCER_ADDED);
      } else if (result.status === 404) {
        setRefreshInfo(refreshInfo => false);
        showTopAlert(strings.member.member_detail.add_refferrer.referrer_not_found_message, "error");
      } else {
        setRefreshInfo(refreshInfo => false);

        if(ServiceErrorMessages.REFERRER_MUST_BE_DIFFERENT_PERSON === result.content){
          showTopAlert(strings.member.member_detail.add_refferrer.reference_different_person_message, "error");
        } else{
          showTopAlert(strings.errors.an_error_occurred, "error");
        }
      }
    } else {
      referenceCodeErrorRef.current.innerText =
        referenceCode === "" ? strings.errors.reference_code_null_error : "";
    }
  };

  return (
    <>
      <div className="p-3 flex flex-col lg:flex-row gap-4">
        <ReferenceInfo memberId={memberId} refresh={refreshInfo} />
        <form onSubmit={submitHandle} className="lg:w-[65%]">
          <div className="mt-5 w-full">
            <div className="flex flex-col px-[10px] pb-0">
              <h3 className="font-bold text-[19px] mb-2 lg:mt-0">
                {strings.member.member_detail.add_refferrer.title}
              </h3>
              <label
                htmlFor="reference_code"
              >
                {strings.member.member_detail.add_refferrer.label}
              </label>
              <Input
                id="reference_code"
                type="text"
                onChange={(e) => setReferenceCode(e.target.value)}
              />
              <span
                ref={referenceCodeErrorRef}
                className="text-danger field-validation-valid px-[10px]"
              ></span>
            </div>
          </div>
          <button
            type="submit"
            className="button search-button gap-3 text-white bg-blue border-blue mt-5 mx-auto px-10"
          >
            {strings.button.save_upper}
          </button>
        </form>
      </div>
    </>
  );
};

export default AddReferrerMember;
