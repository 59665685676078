import {useEffect} from "react";
import {useParams} from "react-router-dom";
import {useLocalization} from "../../../../../hooks/Utils/useLocalization";
import {Loading} from "../../../../../components";

const Pending = () => {

    const { base64 } = useParams();
    const strings = useLocalization();

    useEffect(() => {
        if(base64.length > 0) {
            window.parent.document.getElementById("secure-payment-input").value = base64;
        }
    }, [base64])

    return (
        <div className="flex items-center justify-center mt-2">
            <Loading text={strings.member.member_detail.sales_operations.secure_payment_loading_text} />
        </div>
    )
}

export default Pending;