import React, { useEffect, useState } from 'react'
import { useLocalization } from '../hooks/Utils/useLocalization';
import { Dropdown } from './Dropdown';
import useAuth from '../hooks/Log/useAuth';
import { addTimeZone } from './Utility';

const TimeRangePicker = (props) => {

  const strings = useLocalization();
  const { startHour, setStartHour, endHour, setEndHour, rangeList, isSelectedAllRange = false, setIsSelectedAllRange  } = props;
  const { employee } = useAuth();

  const [hours, setHours] = useState([])

  const firstTimeRange = { id: -1, name: strings.detail_search_titles.hours };
  const timeRangeList = [firstTimeRange, ...hours];

  const filteredHour = hours.filter(hour => hour.id === rangeList.length - 1 ? hour.id >= startHour.id : hour.id > startHour.id);
  const filteredTimeRangeList = [firstTimeRange, ...filteredHour];

  const createRangeList = (list, timeZone) => {
    let arrayList = [];
    let count = 0;

    list.map((range) => {
      
      let tmpRange = addTimeZone(range.id, timeZone);
      let arrayItem = { id: count, name: tmpRange };
      
      arrayList.push(arrayItem);

      count++;
      
    });

    setHours(arrayList);
  }

  useEffect(()=> {
    if (rangeList.length > 0) {
      createRangeList(rangeList, employee.TimeZone);
    }
  },[rangeList])

  //Eğer başlangıçta set ediliyorsa bitiş saati listenin son elemanı seçiliyor.
  //Ama eğer bir başlangıç saati seçilmişse bitiş saati otomatik olarak bir saat sonrası set ediliyor.
  useEffect(() => {
    if (startHour.id !== -1) {
      if(isSelectedAllRange){
        setEndHour(endHour => filteredHour[filteredHour.length-1]);
        setIsSelectedAllRange(false)
      } else {
        setEndHour(endHour => filteredHour[0]);
      }
    } else {
      setEndHour(firstTimeRange);
    }
  }, [startHour])

  return (
    <>
      <div className="flex flex-row gap-3 items-center">
        <Dropdown
          data={timeRangeList}
          selectedData={startHour}
          setSelectedData={setStartHour}
          classnames="lg:w-[246px] md:max-w-full"
        />
        <p className="text-[20px]">:</p>
        <Dropdown
          data={filteredTimeRangeList}
          selectedData={endHour}
          setSelectedData={setEndHour}
          classnames="lg:w-[246px] md:max-w-full"
        />
      </div>
    </>
  )
}

export default TimeRangePicker