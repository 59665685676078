import {useLocalization} from "../../hooks/Utils/useLocalization";
import {PageTitle} from "../../components";
import {Accordion} from "../Member/components";
import React from "react";

const ReferenceDashboard = () => {

    const strings = useLocalization();

    const descriptions = [
        strings.report.reference_dashboard.information_one,
        strings.report.reference_dashboard.information_two,
        strings.report.reference_dashboard.information_three,
        strings.report.reference_dashboard.information_four,
        strings.report.reference_dashboard.information_five,
        strings.report.reference_dashboard.information_six,
        strings.report.reference_dashboard.information_seven,
    ]

    return (
        <>
            <PageTitle title={strings.sidebar.report.reference_dashboard} />

            <Accordion
                title={strings.report.reference_dashboard.information_title}
                outSideClose={true}
                isOpen={true}
                content={
                    <>
                        {descriptions.map((item, index) => (
                            <p key={index} className="mb-4">{item}</p>
                        ))}

                        <p dangerouslySetInnerHTML={{ __html: strings.report.reference_dashboard.information_eight}}></p>
                    </>

                }
            />
            <div className="flex justify-center mt-5">
                <iframe title="reference" width="1725" height="1725" src="https://lookerstudio.google.com/embed/reporting/10c6076d-bb88-4627-9cc3-0cf2c6f1460c/page/7srqD" frameBorder="0" allowFullScreen></iframe>
            </div>
        </>
    )
}

export default ReferenceDashboard;