import {
    convertSecondsToMinutesAndSeconds,
    isItSpecified,
    ISODateToReadableDateWithSeconds,
    maskPhoneNumber
} from "../../../../../components/Utility";
import React, {useEffect, useState} from "react";
import {useLocalization} from "../../../../../hooks/Utils/useLocalization";
import {GetRecordingInfo} from "../../../../../services/Alotech/AlotechService";
import {LoadingSpinner} from "../../../../../components";

const CallRecordsTableRow = ({ callInfo }) => {

    const strings = useLocalization();
    const notSpecified = strings.errors.it_is_not_specified;

    const [loading, setLoading] = useState(false);
    const [callRecord, setCallRecord] = useState("");

    //Arama durumuna göre localization ve className belirleniyor.
    const getAnswerStatusClassnames = (answerStatus) => {

        let string;
        let className;

        switch (answerStatus) {
            case "NOANSWER":
                string = strings.member.member_detail.call_records.no_answer
                className = "text-red"
                break;
            case "ANSWER":
                string = strings.member.member_detail.call_records.answer
                className = "text-green"
                break;
            case "BUSY":
                string = strings.member.member_detail.call_records.busy
                className = "text-warning"
                break;
            case "CANCEL":
                string = strings.member.member_detail.call_records.cancel
                className = "text-danger"
                break;
            case "CONGESTION":
                string = strings.member.member_detail.call_records.congestion
                className = "text-danger"
                break;
            default:
                string = isItSpecified(answerStatus, notSpecified)
                className = "text-base-text"
        }

        return {
            string: string,
            className: className
        }
    }

    const getCallRecordLink = async (callKey) => {
        setLoading(loading => true);

        const result = await GetRecordingInfo(callKey);
        setCallRecord(result.audioUrl);

        setLoading(loading => false);
    }

    useEffect(() => {
        if(callInfo?.duration > 0)
            getCallRecordLink(callInfo?.callkey);
    }, [])

    return (
        <tr className="bg-white hover:bg-[#f0f0f0] ">
            <td className="td h-[60px] align-middle">
                <p className={`${getAnswerStatusClassnames(callInfo?.disposition).className} font-bold text-[14px]`}>
                    {getAnswerStatusClassnames(callInfo?.disposition).string}
                </p>
            </td>
            <td className="td h-[60px] align-middle text-start">
                <p className="group relative max-w-max">
                    {isItSpecified(callInfo?.agent, notSpecified)}
                    <span className="tooltip-top">{maskPhoneNumber(`+9${callInfo?.callerid}`)}</span>
                </p>
            </td>
            <td className="td h-[60px] align-middle">
                <p className="min-w-max">{convertSecondsToMinutesAndSeconds(callInfo?.duration, strings)}</p>
            </td>
            <td className="td h-[60px] align-middle">
                <p className="min-w-max">{ISODateToReadableDateWithSeconds(callInfo?.calldate)}</p>
            </td>
            <td className="td h-[60px] align-middle">
                {callRecord && callRecord.length > 0 ? (
                    <audio
                        className="w-full h-[40px]"
                        controls
                    >
                        <source
                            src={callRecord}
                            type="audio/mp3"
                        />
                        Your browser does not support the audio element.
                    </audio>
                ) : (
                    <>
                        {loading && <LoadingSpinner />}

                        {!loading && (
                            <span>{strings.member.member_detail.call_records.call_record_not_found}</span>
                        )}
                    </>
                )}
            </td>
        </tr>
    )
}

export default CallRecordsTableRow;