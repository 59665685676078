import React, { useEffect, useState } from 'react'
import { ContactType } from '../../../../../../consts/Constants';
import { useLocalization } from '../../../../../../hooks/Utils/useLocalization';
import { Input, showTopAlert, Dropdown } from '../../../../../../components';
import {
  deleteSpacesInThePhoneNumber,
  removeSpaces,
  validateEmail,
  validPhoneNumber
} from '../../../../../../components/Utility';
import ReactIntlTelInput from 'react-intl-tel-input-v2';
import { PostNewContact } from '../../../../../../services/Member/MemberContactService';
import useDataLayer from "../../../../../../hooks/Log/useDataLayer";
import {CustomEventDescriptions, CustomEvents} from "../../../../../../consts/DataLayer";


const AddContactInformationModal = (props) => {

  const { memberId, closeButton } = props;
  const strings = useLocalization();
  const dataLayer = useDataLayer();

  const firstContactType = { id: -1, name: strings.member.member_detail.others.choose_contact_type };
  const contactTypeList = [firstContactType, ...ContactType];

  const [contactType, setContactType] = useState(firstContactType);
  const [phoneNumberValue, setphoneNumberValue] = useState({ iso2: 'tr', dialCode: '90', phone: '' });
  const [address, setAddress] = useState('');
  const [note, setNote] = useState('');

  let inputProps = { placeholder: phoneNumberValue.iso2 === 'tr' ? '5XX XXX XX XX' : strings.employee.create_employee.enter_your_phone_number };
  const intlTelOpts = { preferredCountries: ['tr', 'az', 'sa'] };

  const phoneNumberHandle = val => {
    if (val.dialCode !== phoneNumberValue.dialCode) {
      setphoneNumberValue({ iso2: val.iso2, dialCode: val.dialCode, phone: '' });
    } else {
      setphoneNumberValue(val);
    }
  };

  //Telefon numarası değiştiğinde adress değişkenine yeni telefon numarası ekliyoruz
  useEffect(() => {
    const formattedPhoneNumber = deleteSpacesInThePhoneNumber(phoneNumberValue.phone, phoneNumberValue.dialCode);
    phoneNumberValue.phone !== '' && setAddress(formattedPhoneNumber);
  }, [phoneNumberValue])

  //İletişim tipi değiştirildiğinde adres ve telefon bilgilerini sıfırlıyoruz
  useEffect(() => {
    setAddress('');
    setphoneNumberValue({ iso2: 'tr', dialCode: '90', phone: '' });
  }, [contactType])

  const submitHandle = async e => {
    e.preventDefault();

    let valid = true;
    let error = '';

    const model = {
      address: removeSpaces(address),
      contactTypeId: contactType.id,
      note: note,
      memberId: memberId,
      isBlackList: false,
      isApproved: false
    };

    if (contactType.id === -1) {
      valid = false;
      error = strings.alert.top_alert.inform;
    }
    if (contactType.id === 1 || contactType.id === 3) {
      if (!validPhoneNumber(address)) {
        valid = false;
        error = strings.errors.phone_number_format_error;
      }
    }
    if (contactType.id === 2) {
      if (!validateEmail(address)) {
        valid = false;
        error = strings.errors.email_format_error;
      }
    }

    if (valid) {
      const result = await PostNewContact(model);
      if (result.status === 200) {
        showTopAlert(strings.member.member_detail.others.contact_information_success, "success");

        //DATA LAYER LOGGED
        dataLayer.useCustomEvents(CustomEvents.EMPLOYEE_EVENTS, CustomEventDescriptions.CONTACT_INFORMATION_CREATED);

        setTimeout(() => closeButton(), 1500);
      } else {
        showTopAlert(strings.errors.an_error_occurred + " - " + result.content, "error");
      }

    } else {
      showTopAlert(error, "warning");
    }
  }

  return (
    <>
      <form onSubmit={submitHandle} className="flex flex-col text-start gap-5">
        <div className="flex flex-col px-[10px] pb-0 w-full">
          <label>{strings.table.contact_type}</label>
          <Dropdown
            classnames="md:max-w-full"
            data={contactTypeList}
            selectedData={contactType}
            setSelectedData={setContactType}
          />
        </div>
        {contactType.id !== -1 && (
          <>
            {(contactType.id === 1 || contactType.id === 3) ? (
              <div className="flex flex-col px-[10px] pb-0 w-full">
                <label htmlFor="member_address">{strings.form_info.phone_number}</label>
                <ReactIntlTelInput
                  className="form-input w-full"
                  inputStyle={{ color: 'green' }}
                  inputProps={inputProps}
                  intlTelOpts={intlTelOpts}
                  value={phoneNumberValue}
                  containerStyle={{ width: '480px' }}
                  onChange={phoneNumberHandle}
                  type="text"
                  name="phone"
                />
              </div>) : (
              <div className="flex flex-col px-[10px] pb-0 w-full">
                <label htmlFor="member_address">{strings.form_info.email}</label>
                <Input
                  id="member_address"
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
            )
            }
            <div className="flex flex-col px-[10px] pb-0 w-full">
              <label htmlFor="note">{strings.member.member_detail.candidate_operations.note}</label>
              <Input
                id="note"
                onChange={(e) => setNote(e.target.value)}
              />
            </div>
            <div className="flex gap-3 mt-3 justify-center">
              <button
                type='submit'
                className="button search-button gap-3 text-white bg-blue border-blue focus:border-blue px-10"
              >
                {strings.button.save_upper}
              </button>
              <button
                onClick={closeButton}
                className="button search-button gap-3 text-white bg-red border-red focus:border-red px-10"
              >
                {strings.button.cancel_upper}
              </button>
            </div>
          </>
        )}
      </form>
    </>
  )
};

export default AddContactInformationModal;