import React, { useEffect, useRef, useState } from "react";
import { useLocalization } from '../../../../../../hooks/Utils/useLocalization';
import { Input, showTopAlert, Dropdown } from "../../../../../../components";
import { CurrentCardType } from "../../../../../../consts/Constants";
import { isValidIdentityNumber, validateEmail, handleCheckbox } from "../../../../../../components/Utility";
import classNames from "classnames";
import { useSelector } from "react-redux";
import useAuth from "../../../../../../hooks/Log/useAuth";
import { CreateCurrentCard } from "../../../../../../services/Accounting/CurrentCardService";
import useAnalytics from "../../../../../../hooks/Log/useAnalytics";

const CreateCurrentCardModal = (props) => {

  const strings = useLocalization();
  const language = useSelector(state => state.localizationStore.language);
  const analytics = useAnalytics();
  const { closeButton, classes, memberId } = props;
  const { employee } = useAuth();

  const invoiceTypes = CurrentCardType.map(cct => language === "tr" ? { id: cct.id, name: cct.tr } : { id: cct.id, name: cct.type });

  //Hataları input alanının altına yazdırmak için kullanılan referanslar
  const emailErrorRef = useRef();
  const fullNameErrorRef = useRef();
  const taxNumberErrorRef = useRef();
  const invoiceTypeErrorRef = useRef();
  const addressErrorRef = useRef();
  const taxOfficeErrorRef = useRef();

  const firstInvoiceType = { id: -1, name: strings.member.member_detail.accounting.choose_invoice_type };
  const [invoiceType, setInvoiceType] = useState(firstInvoiceType);
  const invoiceTypeList = [firstInvoiceType, ...invoiceTypes];

  const [fullName, setFullName] = useState(null);
  const [email, setEmail] = useState(null);
  const [isAbroad, setIsAbroad] = useState(false);
  const [taxOffice, setTaxOffice] = useState(null);
  const [taxNumber, setTaxNumber] = useState(null);
  const [address, setAddress] = useState(null);

  const submitHandle = async (e) => {
    e.preventDefault();

    let valid = true;

    //Hataları sıfırlıyoruz
    emailErrorRef.current.innerText = '';
    fullNameErrorRef.current.innerText = '';
    invoiceTypeErrorRef.current.innerText = '';
    taxNumberErrorRef.current.innerText = '';
    addressErrorRef.current.innerText = '';

    if (invoiceType.id === 1) {
      taxOfficeErrorRef.current.innerText = '';
    }

    if (invoiceType.id === -1) {
      valid = false;
      invoiceTypeErrorRef.current.innerText = strings.errors.invoice_type_null_error;
    }

    if (fullName === null) {
      valid = false;
      fullNameErrorRef.current.innerText = strings.errors.general_null_error;
    }

    if (address === null) {
      valid = false;
      addressErrorRef.current.innerText = strings.errors.address_null_error;
    }

    if (email !== null) {
      if (!validateEmail(email)) {
        emailErrorRef.current.innerText = strings.errors.email_format_error;
        valid = false;
      }
    }

    if (!isAbroad) {
      if (invoiceType.id === 0 && taxNumber !== null && taxNumber !== '' && !isValidIdentityNumber(taxNumber)) {
        valid = false;
        taxNumberErrorRef.current.innerText = strings.errors.identity_number_format_error;
      }

      if (invoiceType.id === 1 && taxNumber === null || taxNumber === '') {
        valid = false;
        taxNumberErrorRef.current.innerText = strings.errors.tax_number_null_error;
      }

      if (invoiceType.id === 1 && taxOffice === null || taxOffice === '') {
        valid = false;
        taxOfficeErrorRef.current.innerText = strings.errors.tax_office_null_error;
      }
    }



    if (valid) {
      const model = {
        memberId: memberId,
        cardType: invoiceType.id,
        name: fullName,
        taxOffice: taxOffice !== null && taxOffice !== '' ? taxOffice : null,
        taxNumber: taxNumber !== null ? taxNumber : "11111111111",
        email: email,
        isAbroad: isAbroad,
        address: address,
        employeeId: employee.EmployeeId
      }

      const result = await CreateCurrentCard(model);
      if (result.status === 200) {
        showTopAlert(strings.member.member_detail.accounting.create_current_card_success, "success");
        setTimeout(() => closeButton(), 1500);
      } else {
        showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
      }

    }
  }

  useEffect(() => {
    if(memberId){
      //LOGGED
      analytics.useAnalytics('Cari_Kart_Olusturma_Ekranini_Goruntuledi',{ memberId: memberId });
    }
  }, [])

  return (
    <>
      <form onSubmit={submitHandle}>
        <div className="flex justify-center">
          <p className="font-bold text-[15px]">{strings.member.member_detail.accounting.create_current_card}</p>
        </div>
        <div className={`flex flex-col gap-4 mt-5 overflow-y-scroll ${classes}`}>

          <div className="flex flex-col px-[10px] pb-0 w-full">
            <label htmlFor="invoice_type">{strings.form_info.invoice_type}</label>
            <Dropdown
              classnames="md:!max-w-full"
              data={invoiceTypeList}
              selectedData={invoiceType}
              setSelectedData={setInvoiceType}
            />
            <span
              ref={invoiceTypeErrorRef}
              className="text-danger field-validation-valid px-[10px]"
            >
            </span>
          </div>

          {invoiceType.id !== -1 && (<>

            <div className="flex flex-col px-[10px] pb-0 w-full">
              <label htmlFor="firm_name">{invoiceType.id === 0 ? strings.form_info.full_name : strings.form_info.company_name}</label>
              <Input
                id="firm_name"
                onChange={(e) => setFullName(e.target.value)}
              />
              <span
                ref={fullNameErrorRef}
                className="text-danger field-validation-valid px-[10px]"
              >
              </span>
            </div>

            <div className="flex flex-col px-[10px] pb-0 w-full">
              <label htmlFor="member_email">{strings.form_info.email}</label>
              <Input
                id="member_email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <span
                ref={emailErrorRef}
                className="text-danger field-validation-valid px-[10px]"
              >
              </span>
            </div>

            <div className="flex flex-col md:flex-row text-start gap-4 w-full">
              {invoiceType.id === 1 && (<div className="flex flex-col px-[10px] pb-0 w-full">
                <label htmlFor="tax_office">{strings.form_info.tax_office}</label>
                <Input
                  id="tax_office"
                  onChange={(e) => setTaxOffice(e.target.value)}
                />
                <span
                  ref={taxOfficeErrorRef}
                  className="text-danger field-validation-valid px-[10px]"
                >
                </span>
              </div>)}
              <div className="flex flex-col px-[10px] pb-0 w-full">
                <label htmlFor="tax_number">{invoiceType.id === 0 ? strings.form_info.identity_number : strings.form_info.tax_number}</label>
                <Input
                  id="tax_number"
                  onChange={(e) => setTaxNumber(e.target.value)}
                />
                <span
                  ref={taxNumberErrorRef}
                  className="text-danger field-validation-valid px-[10px]"
                >
                </span>
              </div>
            </div>

            <div className="flex flex-col md:flex-row text-start gap-4 w-full">
              <div className="flex flex-col px-[10px] pb-0 w-full">
                <label htmlFor="member_address">{strings.table.address}</label>
                <textarea
                  id="member_address"
                  type="text"
                  className="border rounded-lg outline-0 focus:border-blue py-3 px-2 w-full"
                  rows="2"
                  onChange={(e) => setAddress(e.target.value)}
                />
                <span
                  ref={addressErrorRef}
                  className="text-danger field-validation-valid px-[10px]"
                >
                </span>
              </div>
              <div className="flex flex-col px-[10px] pb-0  w-2/6">
                <label className="min-w-max" htmlFor="is_abroad">{strings.form_info.is_abroad_taxpayer}</label>
                <div className="h-[45px] flex justify-start items-center px-2">
                  <input
                    type="checkbox"
                    id="isActive1"
                    className="hidden"
                    onClick={() => setIsAbroad(isAbroad => !isAbroad)}
                  />
                  <label
                    htmlFor="isActive1"
                    className={`${handleCheckbox(isAbroad)} mr-2 w-5 h-5 rounded-[3px] cursor-pointer duration-500 flex items-center justify-center`}
                  >
                    <span className={classNames('text-inherit text-[20px] material-symbols-outlined animate-fadeIn font-bold', { 'hidden': !isAbroad })}>
                      done
                    </span>
                  </label>
                </div>
              </div>
            </div>

          </>)}


        </div>
        {invoiceType.id !== -1 && (<div className="flex gap-3 justify-center">
          <button
            type="submit"
            className="group button search-button gap-3 text-white bg-blue border-blue focus:border-blue mt-7 px-10"
          >
            {strings.button.save_upper}
          </button>
          <button
            className="button search-button gap-3 text-white bg-red border-red focus:border-red mt-7 px-10"
            onClick={closeButton}
          >
            {strings.button.cancel_upper}
          </button>

        </div>)}
      </form>
    </>
  );
};

export default CreateCurrentCardModal;