import { useLocalization } from "../../../../hooks/Utils/useLocalization"
import { StudentTableRow } from "..";
import { NoRecordsFound } from "../../../../components";
import React from "react";

const StudentTable = ({ data, isFirstRequest }) => {

    const strings = useLocalization()

    return (
        <>
            {isFirstRequest && (
                <div className="mx-auto px-4 my-4">
                <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
                    <div className="inline-block min-w-full rounded-lg overflow-hidden">
                        <table className="min-w-full leading-normal text-header">
                            <thead>
                                <tr>
                                    <th className="th">
                                        <p className="ml-6">{strings.table.brand}</p>
                                    </th>
                                    <th className="th">
                                        {strings.table.student_informations}
                                    </th>
                                    <th className="th">
                                        {strings.table.users}
                                    </th>
                                    <th className="th">
                                        {strings.table.education_informations}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.length > 0 ?
                                    (
                                        data.map((member, index) => (
                                            <StudentTableRow
                                                key={index}
                                                member={member}
                                            />
                                        ))
                                    )
                                    :
                                    (
                                      <tr className="bg-white hover:bg-[#f0f0f0]">
                                        <td colSpan="4">
                                            <NoRecordsFound />
                                        </td>
                                      </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            )}

            {!isFirstRequest && (
                <div className="py-4 min-w-full text-center">{strings.table.filter_for_result}</div>
            )}
        </>
    )
}

export default StudentTable