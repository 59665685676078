import { fetchApi } from '../index';

export const GetMemberLessonAppList = async (memberId) => {
    
    const url = '/member/v1/member_lesson_app/get_member_lesson_app_list/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const CreateMemberLessonApp = async (model) => {

    const url = '/member/v1/member_lesson_app/create_member_lesson_app/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const ChangeMemberLessonAppStatus = async (memberLessonAppId) => {

    const url = '/member/v2/member_lesson_app/change_status_to_active_or_passive/';

    const payload = {
        memberLessonAppId: memberLessonAppId
    };

    return await fetchApi(url, payload);
}

export const DeleteMemberLessonApp = async (memberLessonAppId) => {

    const url = '/member/v2/member_lesson_app/change_status_to_deleted/';

    const payload = {
        memberLessonAppId: memberLessonAppId
    };

    return await fetchApi(url, payload);
}

export const GetApplicationsByBrandId = async (brandId) => {
    
    const url = '/member/v1/application/get_applications_by_brand_id/';

    const payload = {
        brandId: brandId
    };

    return await fetchApi(url, payload);
}