import {createElement} from "react";
import classNames from "classnames";
import {getBrandColorByBrandId, getBrandNameById} from "./Utility";
import {MemberStatus} from "../consts/Constants";
import {useLocalization} from "../hooks/Utils/useLocalization";

export const TextBadge = ({ children, className = "bg-light-gray h-6 max-w-max ", ...props }) => {

    return createElement('span', {
        ...props,
        className: classNames("inline-flex cursor-default items-center rounded-[15px]  px-2 py-1 text-[13px] font-medium border", {
            [className]: !!className
        })
    }, children)
}

export const BrandBadge = ({ brandId }) => {

    return (
        <span className={`${getBrandColorByBrandId(brandId)} h-6 cursor-default max-w-max border inline-flex items-center rounded-full text-white px-2 py-1 text-[13px] font-medium`}>
            {getBrandNameById(brandId)}
        </span>
    )
}

export const MemberStatusBadge = ({ memberStatus }) => {

    const strings = useLocalization();

    const getStatusTextByMemberStatus = (memberStatus) => {
        switch (memberStatus){
            case MemberStatus.Student:
                return strings.member.member_detail.profile.active_student;
            case MemberStatus.Passive:
                return strings.member.member_detail.profile.passive_student;
            case MemberStatus.Candidate:
                return strings.member.member_detail.profile.candidate_student;
        }
    }

    const getBackgroundByMemberStatus = (memberStatus) => {
        switch (memberStatus){
            case MemberStatus.Student:
                return "bg-green"
            case MemberStatus.Passive:
                return "bg-red"
            case MemberStatus.Candidate:
                return "bg-warning"
        }
    }

    return (
        <span className={`${getBackgroundByMemberStatus(memberStatus)} h-6 border max-w-max cursor-default inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 font-medium`}>
          <svg
              className={`fill-white opacity-60 h-1.5 w-1.5`}
              viewBox="0 0 6 6"
              aria-hidden="true"
          >
            <circle cx="3" cy="3" r="3"/>
          </svg>
          <p className="text-white">{getStatusTextByMemberStatus(memberStatus)}</p>
        </span>
    )
}

export const CorporateBadge = ({ firmName, showFirmName = true, className }) => {

    const strings = useLocalization();

    return (
        <span className={classNames("h-6 max-w-max cursor-default flex items-center gap-2 rounded-full bg-light-gray px-3 py-2 border", {
            [className]: !!className
        })}>
            <span className="font-semibold">{strings.member.member_detail.profile.corporate}</span>
            {showFirmName && (
                <>
                    <svg width="2" height="2" aria-hidden="true" className="fill-slate-900">
                        <circle cx="1" cy="1" r="1"></circle>
                    </svg>
                    <span className="font-medium">
                        <span>
                            {firmName.length === 0 && strings.errors.it_is_not_specified_firm}
                            {firmName.length > 0 && firmName}
                        </span>
                    </span>
                </>
            )}
        </span>
    )
}