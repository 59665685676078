import {useEffect, useState} from "react";
import {GetEmailByMemberId} from "../../services/Member/MemberService";

const useMemberEmail = (memberId) => {

    const [memberEmail, setMemberEmail] = useState("");

    const getMemberEmail = async (memberId) => {
        const result = await GetEmailByMemberId(memberId);

        if(result.status === 200){
            setMemberEmail(currentEmail => result.content);
        }
    }

    useEffect(() => {
        if(memberId.length > 0)
            getMemberEmail(memberId);
    }, [memberId]);

    return memberEmail;
}

export default useMemberEmail;