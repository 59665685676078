import React, { useState } from "react";
import { Modal, NoRecordsFound } from "../../../../components";
import { useLocalization } from "../../../../hooks/Utils/useLocalization";
import CurrentCardTableRow from "./CurrentCardTableRow";
import ShowInvoiceModal from "./ShowInvoiceModal";

const CurrentCardTable = ({ data }) => {

  const strings = useLocalization();

  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const [currentCardId, setCurrentCardId] = useState("");
  const [name, setName] = useState("");

  const closeButton = () => {
    setModal(modal => false);
  }

  return (
    <>
      <div className="mx-auto px-4 mb-4">
        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
          <div className="inline-block min-w-full rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal text-header">
              <thead>
                <tr>
                  <th className="th"><p className="ml-6">{strings.table.brand}</p></th>
                  <th className="th"><p className="min-w-max">{strings.table.current_card_information}</p></th>
                  <th className="th">{strings.table.contact_information}</th>
                  <th className="th">{strings.table.payment_informations}</th>
                  <th className="th">{strings.table.operation}</th>
                </tr>
              </thead>
              <tbody>
                { data.length > 0 ? 
                  (
                    data.map((card, index) => (
                      <CurrentCardTableRow 
                        key={index} 
                        card={card}
                        setModal={setModal} 
                        setModalContent={setModalContent}
                        setCurrentCardId={setCurrentCardId}
                        setName={setName}
                      />
                    ))
                  ) 
                  :
                  (
                    <tr className="bg-white hover:bg-[#f0f0f0]">
                        <td colSpan="5">
                            <NoRecordsFound />
                        </td>
                    </tr>
                  ) 
                }
              </tbody>
            </table>

            {(Object.keys(modalContent).length !== 0 && (
              <Modal modal={modal} setModal={setModal} classes={{ modal: "h-[90vh] max-w-[1140px]" }}>
                {/* {modalContent.element === "edit" && <EditCurrentCardModal closeButton={closeButton} />} */}
                {modalContent.element === "invoice" && <ShowInvoiceModal currentCardId={currentCardId} name={name} closeButton={closeButton} />}
              </Modal>  
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default CurrentCardTable;
