import React from "react";
import { MainItems } from "../../../consts/menu/SidebarMenu";
import { useLocalization } from "../../../hooks/Utils/useLocalization";
import { NavLink, useLocation } from "react-router-dom";
import { url } from "../../../routes/utility";
import classNames from "classnames";
import useMobileView from "../../../hooks/Utils/useMobileView";

const MainOperations = ({ isSidebarOpen }) => {

    const strings = useLocalization();
    const location = useLocation();
    const path = location?.pathname;
    const { isMobileView } = useMobileView();

    const MAIN_ITEMS = MainItems(strings);

    return MAIN_ITEMS.map((item, index) => (
        <NavLink
            key={index}
            to={url(item.pathName)}
            className={({ isActive }) =>
                classNames("sidebar-buttons group", {
                    "active-button": isActive,
                    "justify-end": !isMobileView,
                    "relative overflow-hidden": item.isIcon
                })
            }
            onClick={() => {
                if (path === item.path) {
                    window.location.href = `${item.path}`;
                }
            }}
        >
            {({ isActive }) => (
                <>
                    <span className="material-symbols-outlined mr-3 flex-shrink-0 h-6 w-6">
                        {item.icon}
                    </span>
                    <span className="toggled w-full">{item.title}</span>
                    {(item.isIcon && isSidebarOpen) && (
                        <>
                            <div
                                className={classNames("h-6 w-20 -rotate-[50deg] absolute -right-11 bottom-7 rounded-[3px]", {
                                    "bg-[#295287]": isActive,
                                    "bg-[#d1d1d1]": !isActive
                                })}
                            >
                            </div>
                            <div
                                className={classNames("h-6 w-20 -rotate-45 absolute -right-11 bottom-8 rounded-[3px]", {
                                    "bg-light-gray": isActive,
                                    "bg-blue": !isActive
                                })}
                            >
                                <span
                                    className={classNames("material-symbols-outlined  rotate-45 text-[18px] ml-[4px] mt-[3px]", {
                                        "text-blue": isActive,
                                        "text-white": !isActive
                                    })}
                                >
                                    new_releases
                                </span>
                            </div>
                        </>
                    )}
                </>
            )}
        </NavLink>
    ));
};

export default MainOperations;
