import classNames from "classnames";
import { nanoid } from 'nanoid';
import { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import { Dropdown, DropdownMultiSelect, DropdownSearch, Input, PageTitle, showTopAlert } from '../../components';
import NotAuthorized from "../../components/NotAuthorized";
import { checkNumber, convertToTurkishUpper, getddMMyyyyDate, handleCheckbox, isValueBetweenZeroToHundred, stringToDate } from '../../components/Utility';
import {
    CampaignMarketDetailTypes,
    CampaignMemberType,
    CampaignPopulationType,
    CampaignType,
    CampaignTypes,
    CombinedDiscount,
    PaymentOptions
} from '../../consts/Constants';
import useAuth from '../../hooks/Log/useAuth';
import { useLocalization } from '../../hooks/Utils/useLocalization';
import usePrimeData from "../../hooks/Utils/usePrimeData";
import { url } from '../../routes/utility';
import { CheckCampaignCodeExist, CreateCampaign, CreateGiftPackageCampaignCode } from '../../services/Accounting/CampaignService';
import { GetBankListByBrandId } from '../../services/Bank/BankService';
import { GetProductTitleByBrandId } from '../../services/Product/ProductService';
import {useSelector} from "react-redux";

const AddCampaign = () => {

    const strings = useLocalization();
    const primeData = usePrimeData();
    const navigate = useNavigate();
    const language = useSelector(state => state.localizationStore.language);
    
    const { employee, checkPermission, getPermission, employeeTypeId } = useAuth();
    const roles = getPermission();
    const permisson = checkPermission(roles, employeeTypeId);

    const [step, setStep] = useState(1);
    const [isMemberQuatoActive, setIsMemberQuatoActive] = useState(false);
    const [isGiftPackageActive, setIsGiftPackageActive] = useState(false);

    const step1TopRef = useRef();
    const step2TopRef = useRef();
    const step3TopRef = useRef();
    const createCampaignRef = useRef();
    const clickCampaignRef = useRef();

    //Kampanya oluşturmak için hangi adımdaki sayfanın açılacağını belirliyor
    const [campaignModel, setCampaignModel] = useState({
        brandId: null,
        name: "",
        description: "",
        campaignCode: "",
        marketDetailType: 0,
        //Burası konuşulacak
        campaignType: null,
        campaignUsageType: 0,
        campaignContent: {},
        memberQuato: null,
        startDate: null,
        endDate: null,
        discountRate: "",
        productIds: [],
        giftCampaignModel: isGiftPackageActive ? {} : null,
        employeeId: employee.EmployeeId
    });

    //select değişiklikleri bu alandan yönetiliyor
    const changeCampaignModel = (values) => {
        setCampaignModel({ ...campaignModel, ...values });
    }

    //STEP 1

    //Hata mesajları için ref'ler.
    const brandErrorRef = useRef();
    const campaignCodeErrorRef = useRef();
    const campaignTitleErrorRef = useRef();
    const campaignTypeErrorRef = useRef();
    const startEndDateErrorRef = useRef();
    const descriptionErrorRef = useRef();
    const memberQuatoErrorRef = useRef();
    const datePickerRef = useRef();
    const discountRateErrorRef = useRef();

    const firstBrand = { id: -1, name: strings.detail_search_titles.brands };
    const [brand, setBrand] = useState(firstBrand);
    const brandList = [firstBrand, ...primeData.brandList];

    const firstCampaignType = { id: -1, name: strings.accounting.add_campaign.campaign_types};
    const [campaignType, setCampaignType] = useState(firstCampaignType);
    const campaignTypeList = [firstCampaignType, ...CampaignType];

    const [campaignBeginDate, setCampaignBeginDate] = useState(null);
    const [campaignEndDate, setCampaignEndDate] = useState(null);
    const [campaignDateRange, setCampaignDateRange] = useState("");
    const [campaignCode, setCampaginCode] = useState("");
    const [campaignTitle, setCampaignTitle] = useState("");

    const [memberQuato, setMemberQuato] = useState(null);
    const [description, setDescription] = useState("");
    const [packages, setPackages] = useState([]);
    const [packagesKey, setPackagesKey] = useState(nanoid());
    const [productList, setProductList] = useState([]);
    const [discountRate, setDiscountRate] = useState("");
    const [bankList, setBankList] = useState([]);
    const [campaignCodeExistStatus, setCampaignCodeExistStatus] = useState(false);

    const marketDetailTypeList = CampaignMarketDetailTypes.map(marketDetail => {
        return { id: marketDetail.id, name: marketDetail[language] };
    });
    const [marketDetailType, setMarketDetailType] = useState(marketDetailTypeList[0]);

    //kampanya için başlangıç ve tarih aralığı seçmek için kullanılan bölüm
    const handleCampaignDateRange = (dates) => {
        const [start, end] = dates;
        setCampaignBeginDate(start);
        setCampaignEndDate(end);
        if (start !== null && end !== null) {
            let tmpDateRange = getddMMyyyyDate(start) + " - " + getddMMyyyyDate(end);
            setCampaignDateRange(tmpDateRange);
            datePickerRef.current.classList.add("hidden");
        }
    };

    //Kampanya oluşutulurken aynı kampanya kodunun olup olmadığını kontrol ediyoruz.
    const checkCampaignCodeExist = async (campaignCode) => {
        
        const result = await CheckCampaignCodeExist(campaignCode);
        
        if(result.status === 200) {
            setCampaignCodeExistStatus(campaignCodeExistStatus => JSON.parse(result.content));
        }
    }

    //Markalara ait ürünleri getiriyoruz
    const getProductTitleByBrandId = async (brandId) => {
        const result = await GetProductTitleByBrandId(brandId);

        if (result.status === 200) {
            let resultContent = JSON.parse(result.content);

            let arrayList = [];

            resultContent.map((item) => {
                let arrayItem = { id: item.value, name: item.text };
                arrayList.push(arrayItem);
            });

            if (arrayList.length > 0) {
                setProductList(arrayList);
            }
        }
    }

    //Banka pos listesi getiriliyor
    const getBankList = async (brandId) => {
        const result = await GetBankListByBrandId(brandId);

        let arrayList = [];
        if (result.status === 200) {
            const data = JSON.parse(result.content);
            data.map((item) => {
                let arrayItem = { id: parseInt(item.id), name: item.name + " / " + item.type };
                arrayList.push(arrayItem);
            })
        }
        setBankList(arrayList);
    }

    useEffect(() => {
        checkCampaignCodeExist(campaignCode);
    }, [campaignCode]) 

    useEffect(() => {
        if (brand.id !== -1) {
            getBankList(brand.id);
            getProductTitleByBrandId(brand.id);
            setPackagesKey(nanoid());
        }
    }, [brand])

    useEffect(() => {
        if(!isMemberQuatoActive){
            setMemberQuato(isMemberQuatoActive => null);
        }
    }, [isMemberQuatoActive])

    useEffect(() => {
        if(brandList.length > 1) {
          const selectedBrand = brandList.find(brand => brand.id === parseInt(employee.BrandId))
          setBrand(brand => selectedBrand)
        }
    }, [primeData.brandList])

    //STEP 1

    //STEP 2

    //Hata mesajları için ref'ler.
    const channelErrorRef = useRef();
    const memberTypeErrorRef = useRef();
    const memberErrorRef = useRef();
    const salesTypeErrorRef = useRef();
    const bankErrorRef = useRef();
    const paymentOptionErrorRef = useRef();

    const channelList = primeData.sourceTypeList;
    const memberTypeList = [...CampaignMemberType];
    const populationTypeList = [...CampaignPopulationType];
    const salesTypeList = primeData.salesTypeList;
    const combinedDiscountList = [...CombinedDiscount];

    const [bank, setBank] = useState([]);

    const [paymentOption, setPaymentOption] = useState([]);
    const paymentOptionsList = [...PaymentOptions];

    const [channel, setChannel] = useState([]);
    const [memberType, setMemberType] = useState([]);
    const [member, setMember] = useState([]);
    const [salesType, setSalesType] = useState([]);
    const [combinedDiscount, setCombinedDiscount] = useState([]);

    //STEP 2

    //STEP 3

    //Hata mesajları için ref'ler.
    const giftPackageErrorRef = useRef();
    const giftStartEndDateErrorRef = useRef();
    const giftDiscountRateErrorRef = useRef();
    const memberCountErrorRef = useRef();

    const [additionalLesson, setAdditionalLesson] = useState(null);
    const [postponeLesson, setPostponeLesson] = useState(null);
    const [teacherTimeChange, setTeacherTimeChange] = useState(null);
    const [missedCall, setMissedCall] = useState(null);
    const [giftExercise, setGiftExercise] = useState([]);
    const [giftCampaignCode, setGiftCampaignCode] = useState("");
    const [giftPackage, setGiftPackage] = useState({id: -1, name: strings.detail_search_titles.packages});
    const [giftDiscountRate, setGiftDiscountRate] = useState("");
    const [memberCount, setMemberCount] = useState(null);
    const giftExerciseList = primeData.exerciseTypeList;

    //Hediye paketi için başlangıç ve tarih aralığı seçmek için kullanılan bölüm
    const [giftPackageBeginDate, setGiftPackageBeginDate] = useState(null);
    const [giftPackageEndDate, setGiftPackageEndDate] = useState(null);
    const [giftPackageDateRange, setGiftPackageDateRange] = useState(null);

    const [isClickButton, setIsClickButton] = useState(false);
    const [isCreateCampaignClick, setIsCreateCampaignClick] = useState(false);  

    const datePickerRef2 = useRef();

    const handleGiftPackageDateRange = (dates) => {
        const [start, end] = dates;
        setGiftPackageBeginDate(start);
        setGiftPackageEndDate(end);
        if (start !== null && end !== null) {
            let tmpDateRange = getddMMyyyyDate(start) + " - " + getddMMyyyyDate(end);
            setGiftPackageDateRange(tmpDateRange);
            datePickerRef2.current.classList.add("hidden");
        }
    };

    const createGiftPackageCampaignCode = async (employeeId) => {
        const result = await CreateGiftPackageCampaignCode(employeeId);

        if(result.status === 200){
            setGiftCampaignCode(result.content);
        }
    }

    const createCampaign = async (model) => {
        const result = await CreateCampaign(model);
        if(result.status === 200){
            showTopAlert(strings.accounting.add_campaign.success_message, "success");
            setTimeout(() => {
                model.campaignType === CampaignTypes.Other
                    ? navigate(url("accounting.discount"))
                    : navigate(url("accounting.campaign"))
            }, 2000);
        } else {
            showTopAlert(strings.errors.an_error_occurred, "error");
            clearPage();
            setStep(step => 1);
            step1TopRef.current.focus();
        }
    }


    const setCampaign = (validSteps) => {

        let step1Valid = true;
        let step2Valid = true;
        let step3Valid = true;

        if(validSteps.includes(1) || validSteps.includes(2) || validSteps.includes(3)){

            brandErrorRef.current.innerText = "";
            campaignCodeErrorRef.current.innerText = "";
            campaignTitleErrorRef.current.innerText = "";
            campaignTypeErrorRef.current.innerText = "";
            startEndDateErrorRef.current.innerText = "";
            descriptionErrorRef.current.innerText = "";
            memberQuatoErrorRef.current.innerText = "";
            discountRateErrorRef.current.innerText = "";

            if (brand.id === -1) {
                step1Valid = false;
                brandErrorRef.current.innerText = strings.errors.brand_null_error;
            }

            if (campaignCode.length === 0) {
                step1Valid = false;
                campaignCodeErrorRef.current.innerText = strings.errors.campaign_code_null_error;
            }

            if (campaignTitle.length === 0) {
                step1Valid = false;
                campaignTitleErrorRef.current.innerText = strings.errors.campaign_title_null_error;
            }

            if (campaignType.id === -1) {
                step1Valid = false;
                campaignTypeErrorRef.current.innerText = strings.errors.campaign_type_null_error;
            }

            if (campaignBeginDate === null || campaignEndDate === null) {
                step1Valid = false;
                startEndDateErrorRef.current.innerText = strings.errors.start_end_date_null_error;
            }

            if (isMemberQuatoActive && memberQuato === null) {
                step1Valid = false;
                memberQuatoErrorRef.current.innerText = strings.errors.limited_member_null_error;
            }

            if (!isValueBetweenZeroToHundred(discountRate) && discountRate !== "") {
                step1Valid = false;
                discountRateErrorRef.current.innerText = strings.errors.rate_from_zero_to_hundred_error;
            }

            if (description.length === 0) {
                step1Valid = false;
                descriptionErrorRef.current.innerText = strings.errors.description_null_error;
            }

            if(step1Valid && step !== 3){
                setStep(step => 2);
            }
        }

        if(validSteps.includes(2) || validSteps.includes(3)){          

            channelErrorRef.current.innerText = "";
            memberTypeErrorRef.current.innerText = "";
            memberErrorRef.current.innerText = "";
            salesTypeErrorRef.current.innerText = "";
            bankErrorRef.current.innerText = "";
            paymentOptionErrorRef.current.innerText = "";

            if (channel.length === 0) {
                step2Valid = false;
                channelErrorRef.current.innerText = strings.errors.brand_null_error;
            }
    
            if (memberType.length === 0) {
                step2Valid = false;
                memberTypeErrorRef.current.innerText = strings.errors.member_type_null_error;
            }
    
            if (member.length === 0) {
                step2Valid = false;
                memberErrorRef.current.innerText = strings.errors.member_null_error;
            }
    
            if (salesType.length === 0) {
                step2Valid = false;
                salesTypeErrorRef.current.innerText = strings.errors.sales_type_null_error;
            }
    
            if (bank.length === 0) {
                step2Valid = false;
                bankErrorRef.current.innerText = strings.errors.bank_null_error;
            }
    
            if (paymentOption.length === 0) {
                step2Valid = false;
                paymentOptionErrorRef.current.innerText = strings.errors.payment_options_null_error;
            }

            if(!step1Valid){
                step1TopRef.current.scrollIntoView({ behavior: 'smooth' });
            }

            if(step1Valid && step2Valid){
                setStep(step => 3);
            }
        }

        if(validSteps.includes(3)){
            giftPackageErrorRef.current.innerText = "";
            giftStartEndDateErrorRef.current.innerText = "";
            giftDiscountRateErrorRef.current.innerText = "";
            memberCountErrorRef.current.innerText = "";
    
            if (isGiftPackageActive) {
                if (giftPackage.id === -1) {
                    step3Valid = false;
                    giftPackageErrorRef.current.innerText = strings.errors.gift_package_null_error;
                }
    
                if (giftPackageBeginDate === null || giftPackageEndDate === null) {
                    step3Valid = false;
                    giftStartEndDateErrorRef.current.innerText = strings.errors.start_end_date_null_error;
                }
    
                if (giftDiscountRate === "") {
                    step3Valid = false;
                    giftDiscountRateErrorRef.current.innerText = strings.errors.gift_package_discount_null_error;
                }
    
                if (!isValueBetweenZeroToHundred(giftDiscountRate)) {
                    step3Valid = false;
                    giftDiscountRateErrorRef.current.innerText = strings.errors.rate_from_zero_to_hundred_error;
                }
    
                if (memberCount === null) {
                    step3Valid = false;
                    memberCountErrorRef.current.innerText = strings.errors.member_count_null_error;
                }
            }

            if(!step1Valid){
                step1TopRef.current.scrollIntoView({ behavior: 'smooth' });
            } else if(!step2Valid) {
                step2TopRef.current.scrollIntoView({ behavior: 'smooth' });
            }

            if(step1Valid && step2Valid && step3Valid){
                setIsClickButton(isClickButton => true);
                changeCampaignModel({  
                    brandId: brand.id,
                    name: campaignTitle,
                    campaignType: campaignType.id,
                    description: description,
                    marketDetailType: campaignType.id === CampaignTypes.Campaign ? marketDetailType.id : -1,
                    campaignCode: convertToTurkishUpper(campaignCode),
                    // campaignUsageType: productList.length !== 0 ? 1 : 0,
                    campaignUsageType: campaignType.id === 2 ? 0 : productList.length !== 0 ? 1 : 0,
                    memberQuato: memberQuato ? parseInt(memberQuato) : 10000,
                    startDate: stringToDate(getddMMyyyyDate(campaignBeginDate)),
                    endDate: stringToDate(getddMMyyyyDate(campaignEndDate), 23, 59, 59),
                    discountRate: parseFloat(discountRate.includes(",") ? discountRate.replace(",", ".") : discountRate),
                    productIds: packages,
                    campaignContent: JSON.stringify({
                        "SourceType": channel,
                        "CampaingAccountingType": memberType,
                        "DiscountMemberType": member,
                        "SalesType": salesType,
                        "BankTax": [
                            {
                                "BankId": bank,
                                "MaxTax": paymentOption
                            }
                        ],
                        "CombinedDiscount": combinedDiscount,
                        "Product": packages,
                        "AdditionalRight": [
                            {
                                "RightType": "Postpone",
                                "RightCount": postponeLesson !== null ? parseInt(postponeLesson) : 0
                            },
                            {
                                "RightType": "Missed",
                                "RightCount": missedCall !== null ? parseInt(missedCall) : 0
                            },
                            {
                                "RightType": "AdditionalLesson",
                                "RightCount": additionalLesson !== null ? parseInt(additionalLesson) : 0
                            },
                            {
                                "RightType": "TeacherHourChange",
                                "RightCount": teacherTimeChange !== null ? parseInt(teacherTimeChange) : 0
                            }
                        ],
                        "GiftExercise": giftExercise,
                        ...(isGiftPackageActive && {
                            "GiftPackage": {
                                "ProductId": giftPackage.id,
                                "Quantity": parseInt(memberCount),
                                "BeginDate": giftPackageBeginDate && stringToDate(getddMMyyyyDate(giftPackageBeginDate)),
                                "EndDate": giftPackageEndDate && stringToDate(getddMMyyyyDate(giftPackageEndDate), 23, 59, 59),
                                "DiscountRate": parseInt(giftDiscountRate),
                                "CampaignCode": giftCampaignCode
                            }
                        })
                    }),
                    ...(isGiftPackageActive && {
                        giftCampaignModel: {
                            "giftProductId": giftPackage.id,
                            "giftStartDate": giftPackageBeginDate && stringToDate(getddMMyyyyDate(giftPackageBeginDate)),
                            "giftEndDate": giftPackageEndDate && stringToDate(getddMMyyyyDate(giftPackageEndDate), 23, 59, 59),
                            "giftDiscountRate": parseInt(giftDiscountRate),
                            "giftCampaignCode": giftCampaignCode,
                            "giftMemberQuato": parseInt(memberCount)
                        }
                    }) 
                });

            }
        }

    }

    const handleSubmit = () => {
        createCampaign(campaignModel);
        setIsCreateCampaignClick(isCreateCampaignClick => true);
    }

    const clearPage = () => {
        setBrand(brand => firstBrand);
        setCampaignBeginDate(campaignBeginDate => null);
        setCampaignEndDate(campaignEndDate => null);
        setCampaignDateRange(campaignDateRange => "");
        setCampaginCode(campaignCode => "");
        setCampaignTitle(campaignTitle => "");
        setMemberQuato(memberQuato => null);
        setIsMemberQuatoActive(isMemberQuatoActive => false);
        setDescription(description => "");
        setPackages(packages => []);
        setProductList(productList => []);
        setDiscountRate(discountRate => "");
        setBank(bank => []);
        setChannel(channel => []);
        setMemberType(memberType => []);
        setMember(member => []);
        setSalesType(salesType => []);
        setCombinedDiscount(combinedDiscount => []);
        setPaymentOption(paymentOption => []);
        setAdditionalLesson(additionalLesson => null);
        setPostponeLesson(postponeLesson => null);
        setTeacherTimeChange(teacherTimeChange => null);
        setMissedCall(missedCall => null);
        setGiftExercise(giftExercise => []);
        setIsGiftPackageActive(isGiftPackageActive => false);
        setGiftCampaignCode(giftCampaignCode => "");
        setGiftPackage({id: -1, name: strings.detail_search_titles.packages});
        setGiftDiscountRate(giftDiscountRate => "");
        setMemberCount(memberCount => null);
        setIsClickButton(isClickButton => false);
    }

    useEffect(() => {
        if(step === 2){
            step3TopRef.current.scrollIntoView({ behavior: 'smooth' });
        } 
        if(step === 3){
            createCampaignRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        if(isClickButton){
            clickCampaignRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [step, isClickButton])

    //Gift Package alanı tiki kaldırılırsa state'leri sıfırlar
    useEffect(() => {
        if(!isGiftPackageActive){
            setGiftPackage({id: -1, name: strings.detail_search_titles.packages});
            setGiftPackageBeginDate(null);
            setGiftPackageEndDate(null);
            setGiftPackageDateRange(null);
            setGiftDiscountRate("");
            setMemberCount(null);
        }

        isGiftPackageActive && createGiftPackageCampaignCode(employee.EmployeeId);
    }, [isGiftPackageActive])

    //STEP 3
    return (
        <>
            <PageTitle title={strings.sidebar.accounting.add_campaign} />
            {permisson ? (
                <div className="mt-5 lg:px-[10px] flex flex-col gap-2 max-w-[1150px] w-full sm:w-10/12 mx-auto">
                    {
                        (step === 1 || step === 2 || step === 3) && (
                            <div className="w-full relative">
                                <p tabIndex={-1} ref={step1TopRef}></p>
                                <h5 className="font-bold text-[15px] pl-3 mb-3">{strings.accounting.add_campaign.campaign_informations}</h5>
                                <hr />
                                <div className='mb-8'>
                                    <div className="flex flex-col lg:flex-row lg:justify-between">
                                        <div className="mt-5 w-full lg:w-[32.5%] flex flex-col px-[10px] pb-0">
                                            <label className="text-[13px] font-bold mb-2"
                                                   htmlFor="brand">{strings.table.brand} <span
                                                className="text-red">*</span></label>
                                            <Dropdown
                                                title={strings.detail_search_titles.brands}
                                                data={brandList}
                                                selectedData={brand}
                                                setSelectedData={setBrand}
                                                classnames="md:!max-w-full"
                                                isDisabled={isClickButton ? true : false}
                                            />
                                            <span ref={brandErrorRef}
                                                  className="text-danger field-validation-valid px-[10px]"></span>
                                        </div>
                                        <div className="mt-5 w-full lg:w-[32.5%]">
                                            <div className="flex flex-col px-[10px] pb-0">
                                                <label className="text-[13px] font-bold mb-2"
                                                       htmlFor="campaign_code">{strings.table.campaign_code} <span
                                                    className="text-red">*</span></label>
                                                <div className="relative group">
                                                    <Input
                                                        id="campaign_code"
                                                        type="text"
                                                        onChange={(e) => setCampaginCode(e.target.value)}
                                                        value={campaignCode}
                                                        isDisabled={isClickButton ? true : false}
                                                    />
                                                    {campaignCodeExistStatus && <div
                                                        className="tooltip-top">{strings.accounting.add_campaign.campaign_code_exist}</div>}
                                                </div>
                                            </div>
                                            <span ref={campaignCodeErrorRef}
                                                  className="text-danger field-validation-valid px-[10px]"></span>
                                        </div>
                                        <div className="mt-5 w-full lg:w-[32.5%]">
                                            <div className="flex flex-col px-[10px] pb-0">
                                                <label className="text-[13px] font-bold mb-2"
                                                       htmlFor="campaign_title">{strings.accounting.add_campaign.campaign_title}
                                                    <span className="text-red">*</span></label>
                                                <Input
                                                    id="campaign_title"
                                                    type="text"
                                                    onChange={(e) => setCampaignTitle(e.target.value)}
                                                    value={campaignTitle}
                                                    isDisabled={isClickButton ? true : false}
                                                />
                                            </div>
                                            <span ref={campaignTitleErrorRef}
                                                  className="text-danger field-validation-valid px-[10px]"></span>
                                        </div>
                                    </div>
                                    <div className="flex flex-col lg:flex-row lg:gap-x-3">
                                        <div className="mt-5 w-full lg:w-[32.5%]">
                                            <div className="flex flex-col px-[10px] pb-0">
                                                <label className="text-[13px] font-bold mb-2" htmlFor="discount_rate">
                                                    {strings.accounting.add_campaign.campaign_type} <span
                                                    className="text-red">*</span>
                                                </label>
                                                <Dropdown
                                                    title={strings.accounting.add_campaign.campaign_types}
                                                    data={campaignTypeList}
                                                    selectedData={campaignType}
                                                    setSelectedData={setCampaignType}
                                                    classnames="md:!max-w-full"
                                                    isDisabled={isClickButton ? true : false}
                                                />
                                                <span ref={campaignTypeErrorRef}
                                                      className="text-danger field-validation-valid px-[10px]"></span>
                                            </div>
                                        </div>
                                        <div className="mt-5 w-full lg:w-[32.5%] relative">
                                            <div className="flex flex-col px-[10px] pb-0">
                                                <label className="text-[13px] font-bold mb-2" htmlFor="start_end_date">
                                                    {strings.accounting.add_campaign.choose_start_end_date} <span
                                                    className="text-red">*</span>
                                                </label>
                                                <div className="relative">
                                                    <Input
                                                        id="start_end_date"
                                                        type="text"
                                                        isReadOnly={true}
                                                        value={campaignDateRange}
                                                        action={() => {
                                                            datePickerRef.current.classList.remove("hidden")
                                                            startEndDateErrorRef.current.innerText = "";
                                                        }}
                                                        isDisabled={isClickButton ? true : false}
                                                    />
                                                    <span ref={startEndDateErrorRef}
                                                          className="text-danger field-validation-valid"></span>
                                                    <button type="button"
                                                            onClick={() => datePickerRef.current.classList.remove("hidden")}>
                                                        <span
                                                            className="material-symbols-outlined absolute right-6 top-2 text-lg">
                                                            calendar_today
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div ref={datePickerRef}
                                                 className='absolute top-[105px] hidden z-10 animate-fadeIn duration-300'>
                                                <button
                                                    className='absolute button search-button !h-[25px] !p-2 top-[-28px] bg-red text-white focus:!border-none right-0'
                                                    onClick={() => datePickerRef.current.classList.add("hidden")}
                                                >
                                                    X
                                                </button>
                                                <DatePicker
                                                    minDate={new Date()}
                                                    selected={campaignBeginDate}
                                                    onChange={handleCampaignDateRange}
                                                    onClickOutside={() => datePickerRef.current.classList.add("hidden")}
                                                    startDate={campaignBeginDate}
                                                    endDate={campaignEndDate}
                                                    selectsRange
                                                    inline
                                                />
                                            </div>
                                        </div>
                                        <div className="mt-5 w-full lg:w-[32.5%]">
                                            <div className="flex flex-col px-[10px] pb-0 relative">
                                                <div className="flex items-center absolute ">
                                                    <Input
                                                        type="checkbox"
                                                        id="isActive"
                                                        classnames="hidden peer"
                                                        isDisabled={isClickButton ? true : false}
                                                        action={() => {
                                                            setIsMemberQuatoActive(!isMemberQuatoActive)
                                                            memberQuatoErrorRef.current.innerText = "";
                                                        }}
                                                    />
                                                    <label
                                                        htmlFor="isActive"
                                                        className={`${handleCheckbox(isMemberQuatoActive)} ${isClickButton ? 'bg-[#f5f5f5] focus:border-[#ebedf2]' : ''} mr-2 w-5 h-5 rounded-[3px] cursor-pointer duration-500 flex items-center justify-center`}
                                                    >
                                                        <span
                                                            className={classNames('text-inherit text-[20px] material-symbols-outlined animate-fadeIn font-bold', {'hidden': !isMemberQuatoActive}, {'bg-green border-green !opacity-70 cursor-not-allowed rounded-md': isMemberQuatoActive && isClickButton})}>
                                                            done
                                                        </span>
                                                    </label>
                                                </div>
                                                <label className="text-[13px] font-bold mb-2 ml-7"
                                                       htmlFor="member_quato">{strings.accounting.add_campaign.limited_member} {isMemberQuatoActive &&
                                                    <span className="text-red">*</span>}</label>
                                                <Input
                                                    id="member_quato"
                                                    type="text"
                                                    value={memberQuato !== null ? memberQuato : ""}
                                                    onKeyDown={(e) => !checkNumber(e) && e.preventDefault()}
                                                    isDisabled={isMemberQuatoActive ? isClickButton ? true : false : true}
                                                    onChange={(e) => setMemberQuato(e.target.value)}
                                                />
                                                <span ref={memberQuatoErrorRef}
                                                      className="text-danger field-validation-valid"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col lg:flex-row lg:gap-x-3">
                                        <div className='mt-5 w-full lg:w-[32.5%]'>
                                            <div className="flex flex-col px-[10px] pb-0">
                                                <div className='flex gap-2 items-end'>
                                                    <label className="text-[13px] font-bold mb-2"
                                                           htmlFor="discount_rate">{strings.accounting.add_campaign.discount_rate} (%)</label>
                                                    <div className='relative group'>
                                                        <span className="material-symbols-outlined cursor-pointer">
                                                            info
                                                        </span>
                                                        <div className="tooltip-top !-top-[122px]">
                                                            <p className='mb-2'>{strings.accounting.add_campaign.discount_rate_tooltip_1}</p>
                                                            <p>{strings.accounting.add_campaign.discount_rate_tooltip_2}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <Input
                                                    id="discount_rate"
                                                    type="text"
                                                    value={discountRate ? discountRate : ""}
                                                    // onKeyDown={(e) => !checkNumber(e) && e.preventDefault()}
                                                    onChange={(e) => setDiscountRate(e.target.value)}
                                                    isDisabled={isClickButton}
                                                />
                                                <span ref={discountRateErrorRef}
                                                      className="text-danger field-validation-valid px-[10px]"></span>
                                            </div>
                                        </div>
                                        <div className="mt-5 w-full lg:w-[66%] flex flex-col px-[10px] pb-0">
                                            <label className="text-[13px] font-bold mb-2"
                                                   htmlFor="packages">{strings.detail_search_titles.packages}</label>
                                            <DropdownMultiSelect
                                                uKey={packagesKey}
                                                title={strings.detail_search_titles.packages}
                                                //Servisten gelen veriler boş array yerine eklenecektir.
                                                data={productList || []}
                                                selectedData={packages}
                                                setSelectedData={setPackages}
                                                randomId="productListMS"
                                                classnames="md:!max-w-full"
                                                inputPlaceholder={strings.detail_search_titles.search_package}
                                                isDisabled={isClickButton ? true : (brand.id === -1)}
                                                tooltip={brand.id === -1 && strings.detail_search_titles.first_choose_brand}
                                            />
                                        </div>
                                    </div>
                                    {CampaignTypes.Campaign === campaignType.id &&
                                        <div className="flex flex-col lg:flex-row lg:gap-x-3">
                                        <div className='mt-5 w-full lg:w-[32.5%]'>
                                            <div className="flex flex-col px-[10px] pb-0">
                                                <div className='flex gap-2 items-end'>
                                                    <label className="text-[13px] font-bold mb-2"
                                                           htmlFor="market_detail_type">{strings.accounting.add_campaign.market_detail_type}</label>
                                                </div>
                                                <Dropdown
                                                    data={marketDetailTypeList}
                                                    selectedData={marketDetailType}
                                                    setSelectedData={setMarketDetailType}
                                                    isDisabled={isClickButton}
                                                    classnames="md:!max-w-full"
                                                />
                                                <span ref={discountRateErrorRef}
                                                      className="text-danger field-validation-valid px-[10px]"></span>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    <div className="flex flex-col px-[10px] pb-0 mt-5 w-full">
                                        <label className="font-bold mb-2" htmlFor="description">
                                            {strings.form_info.description} <span className="text-red">*</span>
                                        </label>
                                        <textarea
                                            id="description"
                                            className="border rounded-lg outline-0 focus:border-blue py-3 px-2 w-full"
                                            type="text"
                                            rows="2"
                                            value={description}
                                            disabled={isClickButton ? true : false}
                                            onChange={(e) => setDescription(e.target.value)}
                                        ></textarea>
                                        <span ref={descriptionErrorRef}
                                              className="text-danger field-validation-valid"></span>
                                    </div>
                                </div>
                                {step === 1 && (<div className="mt-7 mb-5 flex gap-3 justify-center">
                                    <button
                                        type='button'
                                        disabled={campaignCodeExistStatus}
                                        className={classNames('button search-button text-white bg-blue border-blue focus:!border-blue px-10', {
                                            "opacity-70": campaignCodeExistStatus
                                        })}
                                        onClick={() => {
                                            !campaignCodeExistStatus && setCampaign([1]);
                                        }}
                                    >
                                        <p>{strings.button.continue}</p>
                                    </button>
                                </div>)}
                            </div>
                        )
                    }
                    <p tabIndex={-1} ref={step2TopRef} className='h-0'></p>
                    {
                        (step === 2 || step === 3) && (
                            <div className="w-full relative">
                                <h5 className="font-bold text-[15px] pl-3 my-3">{strings.table.member_informations}</h5>
                                <hr/>
                                <div className='mb-8'>
                                    <div className="flex flex-col lg:flex-row lg:gap-x-3">
                                        <div className="mt-5 w-full lg:w-[32.5%] flex flex-col px-[10px] pb-0">
                                            <label className="text-[13px] font-bold mb-2"
                                                   htmlFor="brand">{strings.detail_search_titles.channels} <span
                                                className="text-red">*</span></label>
                                            <DropdownMultiSelect
                                                title={strings.detail_search_titles.channels}
                                                //Servisten gelen veriler boş array yerine eklenecektir.
                                                data={channelList}
                                                selectedData={channel}
                                                setSelectedData={setChannel}
                                                randomId="channelListMS"
                                                classnames="md:!max-w-full"
                                                inputPlaceholder={strings.detail_search_titles.search_channel}
                                                isDisabled={isClickButton ? true : false}
                                            />
                                            <span ref={channelErrorRef}
                                                  className="text-danger field-validation-valid"></span>
                                        </div>
                                        <div className="mt-5 w-full lg:w-[32.5%] flex flex-col px-[10px] pb-0">
                                            <label className="text-[13px] font-bold mb-2"
                                                   htmlFor="member_type">{strings.detail_search_titles.member_types} <span
                                                className="text-red">*</span></label>
                                            <DropdownMultiSelect
                                                title={strings.detail_search_titles.member_types}
                                                //Servisten gelen veriler boş array yerine eklenecektir.
                                                data={memberTypeList}
                                                selectedData={memberType}
                                                randomId="memberTypeMS"
                                                setSelectedData={setMemberType}
                                                classnames="md:!max-w-full"
                                                inputPlaceholder={strings.detail_search_titles.search_member_type}
                                                isDisabled={isClickButton ? true : false}
                                            />
                                            <span ref={memberTypeErrorRef} className="text-danger field-validation-valid"></span>
                                        </div>
                                        <div className="mt-5 w-full lg:w-[32.5%] flex flex-col px-[10px] pb-0">
                                            <label className="text-[13px] font-bold mb-2" htmlFor="member">{strings.detail_search_titles.members} <span className="text-red">*</span></label>
                                            <DropdownMultiSelect
                                                title={strings.detail_search_titles.members}
                                                //Servisten gelen veriler boş array yerine eklenecektir.
                                                data={populationTypeList}
                                                selectedData={member}
                                                setSelectedData={setMember}
                                                randomId="memberMS"
                                                classnames="md:!max-w-full"
                                                inputPlaceholder={strings.detail_search_titles.search_member}
                                                isDisabled={isClickButton ? true : false}
                                            />
                                            <span ref={memberErrorRef} className="text-danger field-validation-valid"></span>
                                        </div>
                                    </div>
                                    <div className="flex flex-col lg:flex-row lg:gap-x-3">
                                        <div className="mt-5 w-full lg:w-[32.5%] flex flex-col px-[10px] pb-0">
                                            <label className="text-[13px] font-bold mb-2" htmlFor="brand">{strings.detail_search_titles.sales_types} <span className="text-red">*</span></label>
                                            <DropdownMultiSelect
                                                title={strings.detail_search_titles.sales_types}
                                                //Servisten gelen veriler boş array yerine eklenecektir.
                                                data={salesTypeList}
                                                selectedData={salesType}
                                                setSelectedData={setSalesType}
                                                randomId="salesTypeMS"
                                                classnames="md:!max-w-full"
                                                inputPlaceholder={strings.detail_search_titles.search_sales_type}
                                                isDisabled={isClickButton ? true : false}
                                            />
                                            <span ref={salesTypeErrorRef} className="text-danger field-validation-valid"></span>
                                        </div>
                                        <div className="mt-5 w-full lg:w-[32.5%] flex flex-col px-[10px] pb-0">
                                            <label className="text-[13px] font-bold mb-2">{strings.detail_search_titles.banks} <span className="text-red">*</span></label>
                                            <DropdownMultiSelect
                                                title={strings.detail_search_titles.banks}
                                                //Servisten gelen veriler boş array yerine eklenecektir.
                                                data={bankList || []}
                                                selectedData={bank}
                                                setSelectedData={setBank}
                                                randomId="bankListMS"
                                                classnames="md:!max-w-full"
                                                inputPlaceholder={strings.detail_search_titles.banks}
                                                isDisabled={isClickButton ? true : false}
                                            />
                                            <span ref={bankErrorRef} className="text-danger field-validation-valid"></span>
                                        </div>
                                        <div className="mt-5 w-full lg:w-[32.5%] flex flex-col px-[10px] pb-0">
                                            <label className="text-[13px] font-bold mb-2">{strings.detail_search_titles.payment_options} <span className="text-red">*</span></label>
                                            <DropdownMultiSelect
                                                title={strings.detail_search_titles.payment_options}
                                                //Servisten gelen veriler boş array yerine eklenecektir.
                                                data={paymentOptionsList || []}
                                                selectedData={paymentOption}
                                                setSelectedData={setPaymentOption}
                                                randomId="paymentOptionListMS"
                                                classnames="md:!max-w-full"
                                                inputPlaceholder={strings.detail_search_titles.payment_options}
                                                isDisabled={isClickButton ? true : false}
                                            />
                                            <span ref={paymentOptionErrorRef} className="text-danger field-validation-valid"></span>
                                        </div>
                                    </div>
                                    <div className="flex flex-col lg:flex-row lg:gap-x-3">
                                        <div className="mt-5 w-full lg:w-[32.5%] flex flex-col px-[10px] pb-0">
                                            <label className="text-[13px] font-bold mb-2">{strings.detail_search_titles.avaliable_discounts}</label>
                                            <DropdownMultiSelect
                                                title={strings.detail_search_titles.discounts}
                                                //Servisten gelen veriler boş array yerine eklenecektir.
                                                data={combinedDiscountList || []}
                                                selectedData={combinedDiscount}
                                                setSelectedData={setCombinedDiscount}
                                                randomId="combinedDiscountMS"
                                                classnames="md:!max-w-full"
                                                inputPlaceholder={strings.detail_search_titles.search_discount}
                                                isDisabled={isClickButton ? true : false}
                                            />
                                        </div>
                                    </div>
                                    {step === 2 && (<div className="mt-7 mb-5 flex gap-3 justify-center">
                                        <button
                                            type='button'
                                            className="button search-button text-white bg-blue border-blue focus:!border-blue px-10"
                                            onClick={() => {
                                                setCampaign([1, 2]); 
                                            }}
                                        >
                                            <p>{strings.button.continue}</p>
                                        </button>
                                    </div>)}
                                </div>
                            </div>
                        )
                    }
                    <p tabIndex={-1} ref={step3TopRef} className='h-0'></p>
                    {
                        step === 3 && (
                            <div className="w-full relative">
                                <h5 className="font-bold text-[15px] pl-3 mb-3">{strings.accounting.add_campaign.selected_packages}</h5>
                                <hr />
                                <div className='mb-16'>
                                    <div className="flex flex-col lg:flex-row lg:justify-between">
                                        <div className="mt-5 w-full lg:w-[32.5%]">
                                            <div className="flex flex-col px-[10px] pb-0">
                                                <label className="text-[13px] font-bold mb-2" htmlFor="addditional_lesson_count">{strings.accounting.add_campaign.additional_lesson_count}</label>
                                                <Input
                                                    id="addditional_lesson_count"
                                                    type="text"
                                                    value={additionalLesson !== null && additionalLesson !== 0 ? additionalLesson : ""}
                                                    onKeyDown={(e) => !checkNumber(e) && e.preventDefault()}
                                                    onChange={(e) => setAdditionalLesson(e.target.value)}
                                                    isDisabled={isClickButton ? true : false}
                                                />
                                            </div>
                                        </div>
                                        <div className="mt-5 w-full lg:w-[32.5%]">
                                            <div className="flex flex-col px-[10px] pb-0">
                                                <label className="text-[13px] font-bold mb-2" htmlFor="postpone_lesson">{strings.accounting.add_campaign.postpone_lesson}</label>
                                                <Input
                                                    id="postpone_lesson"
                                                    type="text"
                                                    value={postponeLesson !== null && postponeLesson !== 0 ? postponeLesson : ""}
                                                    onKeyDown={(e) => !checkNumber(e) && e.preventDefault()}
                                                    onChange={(e) => setPostponeLesson(e.target.value)}
                                                    isDisabled={isClickButton ? true : false}
                                                />
                                            </div>
                                        </div>
                                        <div className="mt-5 w-full lg:w-[32.5%]">
                                            <div className="flex flex-col px-[10px] pb-0">
                                                <label className="text-[13px] font-bold mb-2" htmlFor="missed_call">{strings.accounting.add_campaign.missed_call}</label>
                                                <Input
                                                    id="missed_call"
                                                    type="text"
                                                    value={missedCall !== null && missedCall !== 0 ? missedCall : ""}
                                                    onKeyDown={(e) => !checkNumber(e) && e.preventDefault()}
                                                    onChange={(e) => setMissedCall(e.target.value)}
                                                    isDisabled={isClickButton ? true : false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col lg:flex-row lg:justify-between">
                                        <div className="mt-5 w-full lg:w-[32.5%]">
                                            <div className="flex flex-col px-[10px] pb-0">
                                                <label className="text-[13px] font-bold mb-2" htmlFor="teacher_time_change">{strings.member.member_detail.terms.teacher_time_change}</label>
                                                <Input
                                                    id="teacher_time_change"
                                                    type="text"
                                                    value={teacherTimeChange !== null && teacherTimeChange !== 0 ? teacherTimeChange : ""}
                                                    onKeyDown={(e) => !checkNumber(e) && e.preventDefault()}
                                                    onChange={(e) => setTeacherTimeChange(e.target.value)}
                                                    isDisabled={isClickButton ? true : false}
                                                />
                                            </div>
                                        </div>
                                        <div className="mt-5 w-full lg:w-[32.5%]">
                                            <div className="flex flex-col px-[10px] pb-0">
                                                <label className="text-[13px] font-bold mb-2" htmlFor="gift_exercise">{strings.detail_search_titles.gift_exercise}</label>
                                                <DropdownMultiSelect
                                                    title={strings.detail_search_titles.gift_exercise}
                                                    //Servisten gelen veriler boş array yerine eklenecektir.
                                                    data={giftExerciseList || []}
                                                    selectedData={giftExercise}
                                                    setSelectedData={setGiftExercise}
                                                    randomId={nanoid()}
                                                    classnames="md:!max-w-full"
                                                    inputPlaceholder={strings.detail_search_titles.search_gift_exercise}
                                                    isDisabled={isClickButton ? true : false}
                                                />
                                            </div>
                                        </div>
                                        <div className="mt-5 w-full lg:w-[32.5%]"></div>
                                    </div>
                                </div>
                                <div className='flex gap-2'>
                                    <h5 className="font-bold text-[15px] pl-3 mb-3">{strings.accounting.add_campaign.gift_package}</h5>
                                    <Input
                                        type="checkbox"
                                        id="isGiftPackageActive"
                                        classnames="hidden peer"
                                        isDisabled={isClickButton ? true : false}
                                        action={() => setIsGiftPackageActive(!isGiftPackageActive)}
                                    />
                                    <label
                                        htmlFor="isGiftPackageActive"
                                        className={`${handleCheckbox(isGiftPackageActive)} ${isClickButton ? 'bg-[#f5f5f5] focus:border-[#ebedf2]' : ''} mr-2 w-5 h-5 rounded-[3px] cursor-pointer duration-500 flex items-center justify-center`}
                                    >
                                        <span className={classNames('text-inherit text-[20px] material-symbols-outlined animate-fadeIn font-bold', { 'hidden': !isGiftPackageActive }, {'bg-green border-green !opacity-70 cursor-not-allowed rounded-md' : isGiftPackageActive && isClickButton})}>
                                            done
                                        </span>
                                    </label>
                                </div>
                                <hr />
                                <div className='mb-8'>
                                    <div className="flex flex-col lg:flex-row lg:justify-between">
                                        <div className="flex flex-col px-[10px] pb-0 mt-5 w-full lg:w-[32.5%]">
                                            <label className="text-[13px] font-bold mb-2" htmlFor="gift_packages">{strings.detail_search_titles.gift_packages} {isGiftPackageActive && <span className="text-red">*</span>}</label>
                                            <DropdownSearch 
                                                inputPlaceholder={strings.detail_search_titles.gift_packages}
                                                selectedData={giftPackage}
                                                setSelectedData={setGiftPackage}
                                                data={productList || []}
                                                classnames="md:!max-w-full"
                                                isDisabled={!isGiftPackageActive ? true : (isClickButton ? true : false)}
                                            />
                                            <span ref={giftPackageErrorRef} className="text-danger field-validation-valid px-[10px]"></span>
                                        </div>
                                        <div className="mt-5 w-full lg:w-[32.5%] relative">
                                            <div className="flex flex-col px-[10px] pb-0">
                                                <label className="text-[13px] font-bold mb-2" htmlFor="start_end_date">
                                                    {strings.accounting.add_campaign.choose_start_end_date} {isGiftPackageActive && <span className="text-red">*</span>}
                                                </label>
                                                <Input
                                                    id="start_end_date"
                                                    type="text"
                                                    isReadOnly={true}
                                                    value={giftPackageDateRange !== null ? giftPackageDateRange : ""}
                                                    action={() => datePickerRef2.current.classList.remove("hidden")}
                                                    isDisabled={!isGiftPackageActive ? true : (isClickButton ? true : false)}
                                                />
                                                <span ref={giftStartEndDateErrorRef} className="text-danger field-validation-valid px-[10px]"></span>
                                            </div>
                                            <div ref={datePickerRef2} className='absolute top-[105px] hidden z-10 animate-fadeIn duration-300'>
                                                <button
                                                    className='absolute button search-button !h-[25px] !p-2 top-[-28px] bg-red text-white focus:!border-none right-0'
                                                    onClick={() => datePickerRef2.current.classList.add("hidden")}
                                                >
                                                    X
                                                </button>
                                                <DatePicker
                                                    minDate={new Date()}
                                                    selected={giftPackageBeginDate}
                                                    onChange={handleGiftPackageDateRange}
                                                    onClickOutside={() => datePickerRef2.current.classList.add("hidden")}
                                                    startDate={giftPackageBeginDate}
                                                    endDate={giftPackageEndDate}
                                                    selectsRange
                                                    inline
                                                />
                                            </div>
                                        </div>
                                        <div className="mt-5 w-full lg:w-[32.5%]">
                                            <div className="flex flex-col px-[10px] pb-0">
                                                <label className="text-[13px] font-bold mb-2" htmlFor="gift_discount_rate">
                                                    {strings.accounting.add_campaign.gift_package_discount_rate} (%) {isGiftPackageActive && <span className="text-red">*</span>}
                                                </label>
                                                <Input
                                                    id="gift_discount_rate"
                                                    type="text"
                                                    value={giftDiscountRate ? giftDiscountRate : ""}
                                                    onKeyDown={(e) => !checkNumber(e) && e.preventDefault()}
                                                    isDisabled={!isGiftPackageActive ? true : (isClickButton ? true : false)}
                                                    onChange={(e) => setGiftDiscountRate(e.target.value)}
                                                />
                                                <span ref={giftDiscountRateErrorRef} className="text-danger field-validation-valid px-[10px]"></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-5 w-full lg:w-[32.5%]">
                                        <div className="flex flex-col px-[10px] pb-0">
                                            <label className="text-[13px] font-bold mb-2" htmlFor="member_count">
                                                {strings.accounting.add_campaign.member_count} {isGiftPackageActive && <span className="text-red">*</span>}
                                            </label>
                                            <Input
                                                id="member_count"
                                                type="text"
                                                onKeyDown={(e) => !checkNumber(e) && e.preventDefault()}
                                                value={memberCount !== null && memberCount !== 0 ? memberCount : ""}
                                                isDisabled={!isGiftPackageActive ? true : (isClickButton ? true : false)}
                                                onChange={(e) => setMemberCount(e.target.value)}
                                            />
                                            <span ref={memberCountErrorRef} className="text-danger field-validation-valid px-[10px]"></span>
                                        </div>
                                    </div>
                                </div>
                                {isClickButton && ( 
                                    <div className="relative shadow-info px-5 py-3 rounded-md my-2">
                                        <p>{strings.accounting.add_campaign.add_campaign_question}</p>
                                        <div className="h-full bg-warning absolute left-0 top-0 w-[5px] rounded-l-md">
                                        </div>
                                    </div>
                                )}
                                <p tabIndex={-1} ref={createCampaignRef} className='h-0'></p>
                                <div className="mt-7 mb-5 flex gap-3 justify-center">
                                    {!isClickButton ? (
                                        <button
                                            type='button'
                                            className="button search-button text-white bg-blue border-blue focus:!border-blue px-7"
                                            onClick={() => {
                                                setCampaign([1, 2, 3]);
                                                //step1TopRef.current.focus(); 
                                            }}
                                        >
                                            <p>{strings.button.continue}</p>
                                        </button>
                                    ) : (
                                        <button
                                            type='button'
                                            className={`${isCreateCampaignClick ? 'cursor-not-allowed opacity-70 hover:opacity-70' : ''} button search-button text-white bg-blue border-blue focus:!border-blue px-7`}
                                            disabled={isCreateCampaignClick ? true : false}
                                            onClick={handleSubmit}
                                        >
                                            <p className={`${isCreateCampaignClick ? 'opacity-70 hover:opacity-70' : ''}`}>{strings.button.create_campaign}</p>
                                        </button>
                                    )}
                                </div>
                                <p tabIndex={-1} ref={clickCampaignRef} className='h-0'></p>
                            </div>
                        )
                    }
                </div>
            ) : (<NotAuthorized />)}
        </>
    )
}

export default AddCampaign;