export const Notes = Object.freeze(
    {
        "SEND_SMS_NOTE": "{SmsTitle} başlıklı SMS {consultantName} tarafından gönderildi",
        "SEND_SMS_NOTE_WHATSAPP": "{SmsTitle} başlıklı WhatsApp mesajı {consultantName} tarafından gönderildi",
        "SEND_EMAIL_NOTE": "{MailTitle} başlıklı e-posta {consultantName} tarafından gönderildi",
        "SEND_EMAIL_NOTE_WHATSAPP": "{MailTitle} başlıklı e-posta WhatsApp mesajı olarak {consultantName} tarafından gönderildi.",
        "SEND_OFFER_MAIL": "Teklif gönder e-postası {consultantName} tarafından gönderildi. {content}",
        "SEND_OFFER_MAIL_WHATSAPP": "Teklif gönder e-postası WhatsApp mesajı olarak {consultantName} tarafından gönderildi. {content}",
        "CREATE_REFERENCE_NOTE": "{consultantName} adlı kullanıcı {referenceMemberName} adlı öğrenciyi {refererMemberName} adlı öğrencinin referanslarına ekledi."
    }
)

export const NoteCategories = Object.freeze(
    {
         "STUDENT_PAGE_REFERENCE": 43, // Öğrenci sayfası referans
    }
)