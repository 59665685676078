export const EmployeeDimensionProperties = Object.freeze(
    {
        "EMPLOYEE_EMAIL": "employee_email"
    }
);

export const CustomEvents = Object.freeze(
    {
        "EMPLOYEE_EVENTS": "employee_events"
    }
)

export const CustomEventDescriptions = Object.freeze(
    {
        //user_events
        "GET_STUDENT_FROM_POOL": "Havuzdan_Oğrenci_Cekildi",
        "DROP_STUDENT_TO_POOL": "Havuza_Oğrenci_Bırakıldı",
        "TAKE_NOTE_ON_STUDENT_PROFILE": "Öğrenci_Kaydına_Not_Bırakıldı",
        "SET_REMINDER": "Hatirlatma_Kuruldu",
        "REFERENCER_ADDED": "Referans_Veren_Uye_Eklendi",
        "OFFER_SENDED": "Teklif_Gonderildi",
        "EMAIL_SENDED": "Eposta_Gonderildi",
        "WP_SENDED": "Whatsapp_Gonderildi",
        "SMS_SENDED": "Sms_Gonderildi",
        "DEMO_PACKAGE_CREATED": "Demo_Paket_Olusturuldu",
        "FOURTY_EIGHT_DISCOUNT_CREATED": "Kirk_Sekiz_Saat_Indirimi_Olusturuldu",
        "CONTACT_INFORMATION_CREATED": "Iletisim_Bilgisi_Olusturuldu",
        "CONTACT_INFORMATION_UPDATED": "Iletisim_Bilgisi_Guncellendi",
        "BRAND_MOVED": "Marka_Tasindi",
        "FIRST_SALE_COMPLETED": "Ilk_Kayit_Ile_Donem_Olusturuldu",
        "EXTEND_PACKAGE_COMPLETED": "Paket_Uzatma_Yapildi",
        "CHANGE_PACKAGE_COMPLETED": "Paket_Degisikligi_Yapildi",
        "SECOND_REGISTRATION_COMPLETED": "Ikinci_Kayit_Yapildi",
        "TRY_AGAIN_PACKAGE_COMPLETED": "Yeniden_Dene_Yapildi",
        "START_TASK": "Göreve_Basla_Butonuna_Tiklandi",
        "POSTPONE_TASK": "Görev_Ertelendi",
        "CLOSE_TASK": "Görev_Kapatildi",
        "CLICK_2_CALL": "Click_2_Call_Tiklandi",
        "CLICK_2_CALL_APPROVED": "Click_2_Call_Onaylandi",
    }
)