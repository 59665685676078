import { fetchApi } from '../index';

export const TicketFilter = async (model) => {

    const url = '/prime_ticket/v2/ticket/get_filtered_tickets/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const GetTicketDetail = async (ticketId) => {

    const url = '/prime_ticket/v2/ticket/detail/';

    const payload = {
        ticketId: ticketId
    };

    return await fetchApi(url, payload);
}

export const GetAnswerContent = async (answerId) => {

    const url = '/prime_ticket/v1/ticket/get_answer_content/';

    const payload = {
        answerId: answerId
    };

    return await fetchApi(url, payload);
}

export const UpdateAnswer = async (model) => {

    const url = '/prime_ticket/v2/ticket/update_answer/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const GetNotAnswerCount = async () => {

    const url = '/prime_ticket/v2/ticket/get_not_answer_count/';

    const payload = {

    };

    return await fetchApi(url, payload);
}

export const GetUnansweredTicket = async () => {
    
    const url = '/prime_ticket/v2/ticket/get_un_answered/';

    const payload = {

    };

    return await fetchApi(url, payload);
}