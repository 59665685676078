import {useLocalization} from "../../../hooks/Utils/useLocalization";
import {CopyToClipboard, Modal, showCenteredAlertTwoButtons, showTopAlert, SquareButton} from "../../../components";
import {
  dateStringToReadableDateWithoutTime,
  getBrandNameById,
  getLogoByBrandId,
  maskPhoneNumber
} from "../../../components/Utility";
import {url} from "../../../routes/utility";
import {DefaultProfile} from "../../../assets/img";
import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {EmployeeChangeDistrubute, EmployeeSendMembersToPool} from "../../../services/Employee/EmployeeService";
import useAuth from "../../../hooks/Log/useAuth";
import Portal from "../../../components/Portal";
import DistributeStudentModal from "../DistributeStudentModal";
import useAnalytics from "../../../hooks/Log/useAnalytics";
import classNames from "classnames";
import {EmployeeStatusType, GeneralStatusType, ServiceErrorMessages} from "../../../consts/Constants";

const EmployeeTableRow = (props) => {

  const strings = useLocalization();
  
  const { listedEmployee, sendRequestToEndpoint } = props;

  const { employee }  = useAuth();
  const analytics = useAnalytics();

  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const [profilePicture, setProfilePicture] = useState(null);
  const [distributeStatus, setDistrubuteStatus] = useState(listedEmployee.distributeStatus && listedEmployee.distributeStatus.isDistributed ? true : false);
  const [employeeMemberStatus, setEmployeeMemberStatus] = useState(listedEmployee.employeeMemberStatus ? true : false);

  //Satış, verimlilik danışmanları ve referansatör
  const enableDistrubute = ((
    listedEmployee.employeeType.id === 4 ||
    listedEmployee.employeeType.id === 5 ||
    listedEmployee.employeeType.id === 6 ||
    listedEmployee.employeeType.id === 7 ||
    listedEmployee.employeeType.id === 8 ||
    listedEmployee.employeeType.id === 9 ||
    listedEmployee.employeeType.id === 10 ||
    listedEmployee.employeeType.id === 16) && listedEmployee.status);

  //Satış danışmanları için öğrenci havuza bırak açma/kapatma butonu
  const enableSendPool = ((
    listedEmployee.employeeType.id === 4 ||
    listedEmployee.employeeType.id === 5 ||
    listedEmployee.employeeType.id === 6) && listedEmployee.employeeMemberStatus);

  //Satış, verimlilik danışmanları ve referansatör için öğrenciler dağıt butonu aktiflik durumu
  const enableMemberDistribute = ((
    listedEmployee.employeeType.id === 4 ||
    listedEmployee.employeeType.id === 5 ||
    listedEmployee.employeeType.id === 6 ||
    listedEmployee.employeeType.id === 7 ||
    listedEmployee.employeeType.id === 8 ||
    listedEmployee.employeeType.id === 9 ||
    listedEmployee.employeeType.id === 10 ||
    listedEmployee.employeeType.id === 16)
      && listedEmployee.employeeMemberStatus
      && listedEmployee.status !== 2
      && (employee.EmployeeId === listedEmployee.managerId
          || employee.EmployeeId === listedEmployee.managerAssistantId
          || +employee.EmployeeTypeId === EmployeeStatusType.Yazılımcı
          || +employee.EmployeeTypeId === EmployeeStatusType.Yönetici));

  //Verimlilik/referanstör için dağıtıma aç/kapat
  const changeDistribute = async () => {
    const employeeId = listedEmployee.employeeId;
    const willOpen = !distributeStatus;

    const result = await EmployeeChangeDistrubute(willOpen, employeeId);

    if (result.status === 200) {
      setDistrubuteStatus(distributeStatus => !distributeStatus);
      showTopAlert(strings.employee.employee_list.change_distribute_success, "success");
    } else {
      showTopAlert(strings.errors.an_error_occurred, "error");
    }

  }

  //Satış danışmanları üzerindeki adayların aday havuzuna gönderilmesi
  const sendMembersToPool = async () => {
    const employeeId = listedEmployee.employeeId;

    const result = await EmployeeSendMembersToPool(employeeId);

    if (result.status === 200) {
      setEmployeeMemberStatus(employeeMemberStatus => false);
      showTopAlert(strings.employee.employee_list.drop_pool_success, "success");
    } else {

      if(result.content === ServiceErrorMessages.NO_MEMBERS_WERE_FOUND)
        showTopAlert(
            strings.employee.employee_list.push_employees_to_pool_error_message
                .replace("#employeeName#", listedEmployee?.fullName),
            "error"
        );
      else
        showTopAlert(strings.errors.an_error_occurred, "error");
    }

  }

  //"Öğrenci Hesabı Oluştur" butonu için servis isteği atılıyor.
  // const createMemberAccountForEmployee = async () => {
  //     const model = {
  //       brandId: listedEmployee.brand.id,
  //       phoneNumber: listedEmployee.corporatePhone,
  //       //Eski sistemdeki gibi default olarak gönderiliyor.
  //       password: "1234",
  //       registerType: 3,
  //       fullName: listedEmployee.fullName,
  //       email: listedEmployee.email,
  //       timezone: parseInt(listedEmployee.timeZone),
  //       countryIso2: parsePhoneNumber(listedEmployee.corporatePhone).country
  //     };
  //
  //     const result = await RegisterEmployeeAsMember(model);
  //
  //     if(result.status === 200){
  //       let resultContent = JSON.parse(result.content);
  //
  //       if(resultContent.isItAlreadyRegistered){
  //         showTopAlert(strings.employee.employee_list.already_registerd_employee, "warning");
  //       } else{
  //         //LOGGED
  //         analytics.useAnalytics("Kullanici_Ogrenci_Hesabi_Olusturma", { employeeId: listedEmployee.employeeId })
  //         showTopAlert(strings.employee.employee_list.employee_member_account_success, "success");
  //       }
  //     } else {
  //       showTopAlert(strings.errors.an_error_occurred, "error");
  //     }
  // }

  // const handleCreateAccount = () => {
  //   if(isValidPhoneNumber(listedEmployee.corporatePhone)){
  //     showCenteredAlertTwoButtons(
  //       strings.employee.employee_list.sure,
  //       strings.employee.employee_list.confirm_modal_text,
  //       strings.button.confirm,
  //       strings.button.cancel,
  //       createMemberAccountForEmployee
  //     );
  //   } else {
  //     showTopAlert(strings.errors.phone_number_not_found, "error");
  //   }
  // }

  const closeButton = () => {
    setModal(modal => false);
  }

  useEffect(() => {
    let fileDir = "employee";
    const tmpProfilePicture = listedEmployee.profilePicture ? process.env.REACT_APP_PROFILE_URL + "/" + fileDir + "/" + listedEmployee.profilePicture + "?" + new Date().getTime() : DefaultProfile;
    setProfilePicture(profilePicture => tmpProfilePicture);
  },[])

  return (
    <>
    <tr className="bg-white hover:bg-[#f0f0f0]">
      <td className="td align-middle">
        <div className="relative group max-w-max ml-6">
          <img src={getLogoByBrandId(listedEmployee.brand.id)} width="43" height="48" />
          <div className="tooltip-top">
            <p>{getBrandNameById(listedEmployee.brand.id)}</p>
          </div>
        </div>
      </td>
      <td className="td w-32 align-middle">
        <div className="w-full flex justify-center">
          <img
              src={profilePicture}
              className={classNames("!w-12 !h-12 rounded-full border-[2px]", {
                "border-green": listedEmployee.status === GeneralStatusType.Active,
                "border-red": listedEmployee.status === GeneralStatusType.Passive
              })}
          />
        </div>
      </td>
      <td className="td">
        <div className="max-w-[320px]">
          <div className="flex items-center mt-[6px]">
            <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
              person
            </span>
            <Link
                className="font-bold text-blue hover:underline focus:underline min-w-max"
                to={url("employee.detail", { employeeId: listedEmployee?.employeeId })}
            >
              {listedEmployee.fullName}
            </Link>
          </div>
          <hr className="my-2" />
          <div className="flex items-center">
            <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
              sell
            </span>
            <p className="min-w-max">{listedEmployee.employeeType.title}</p>
          </div>
          <div className="flex items-center">
            <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
              calendar_month
            </span>
            <p>{dateStringToReadableDateWithoutTime(listedEmployee.beginDate)}</p>
          </div>
        </div>
      </td>
      <td className="td">
        <div className="badge mb-2 flex items-center max-w-[320px]">
          <span className="material-symbols-outlined mr-2 text-[19px]">call</span>
          <div className="flex items-center gap-0.5">
            <p>{maskPhoneNumber(listedEmployee.corporatePhone)}</p>
            <CopyToClipboard text={listedEmployee.corporatePhone} className="-mb-1" iconClassName="!text-[13px]"/>
          </div>
        </div>
        <div className="badge flex items-center  max-w-[320px]">
          <span className="material-symbols-outlined mr-2 text-[19px]">mail</span>
          {listedEmployee.corporateEmail}
        </div>
      </td>
      <td className="td align-middle">
        <div className="flex gap-1 flex-wrap min-w-[200px]">
          <SquareButton
            //role={{ group: "employee", name: "autoLogin" }}
            classnames="bg-blue opacity-70 hover:opacity-70 !cursor-not-allowed"
            iconClassnames="opacity-70 hover:opacity-70 !cursor-not-allowed"
            iconName="link"
            //tooltip={strings.employee.employee_list.login}
            tooltip={strings.errors.not_allowed}
          />
          <SquareButton
            classnames="bg-warning opacity-70 hover:opacity-70 !cursor-not-allowed"
            iconClassnames="opacity-70 hover:opacity-70 !cursor-not-allowed"
            iconName="add"
            //tooltip={strings.employee.employee_list.create_account}
            tooltip={strings.errors.not_allowed}
            //action={handleCreateAccount}
          />
          <SquareButton
            role={{ group: "employee", name: "edit" }}
            classnames="bg-green"
            iconName="edit"
            tooltip={strings.button.update}
            to={url("employee.edit", { employeeId: listedEmployee.employeeId })}
          />
          <SquareButton
            classnames="bg-blue"
            iconName="contact_emergency"
            tooltip={strings.employee.employee_list.detail}
            to={url("employee.detail", { employeeId: listedEmployee?.employeeId })}
          />
          <SquareButton
            classnames={`bg-warning ${(enableSendPool && employeeMemberStatus) ? "" : "opacity-70 hover:opacity-70 !cursor-not-allowed"}`}
            iconClassnames={(enableSendPool && employeeMemberStatus) ? "" : "opacity-70 hover:opacity-70 !cursor-not-allowed"}
            iconName="database"
            tooltip={(enableSendPool && employeeMemberStatus) ? strings.employee.employee_list.drop_member_pool : strings.errors.not_allowed}
            action={() =>
              (enableSendPool && employeeMemberStatus) && showCenteredAlertTwoButtons(
                strings.employee.employee_list.sure,
                strings.employee.employee_list.confirm_drop_pool,
                strings.button.confirm,
                strings.button.cancel,
                sendMembersToPool
              )
            }
          />
          <SquareButton
            classnames={`bg-warning ${(enableMemberDistribute && employeeMemberStatus) ? "" : "opacity-70 hover:opacity-70 !cursor-not-allowed"}`}
            iconClassnames={(enableMemberDistribute && employeeMemberStatus) ? "" : "opacity-70 hover:opacity-70 !cursor-not-allowed"}
            iconName="keyboard_double_arrow_down"
            tooltip={(enableMemberDistribute && employeeMemberStatus) ? strings.employee.employee_list.distribute_students : strings.errors.not_allowed}
            action={() => { 
              if(enableMemberDistribute && employeeMemberStatus){
                setModal(modal => true); 
                setModalContent({ element: "distribute" });
              }
            }}
          />
          <SquareButton
            role={{ group: "employee", name: "enableDistribute" }}
            classnames={`${distributeStatus ? "bg-green" : "bg-red"} ${enableDistrubute ? "" : "opacity-70 hover:opacity-70 !cursor-not-allowed"}`}
            iconClassnames={enableDistrubute ? "" : "opacity-70 hover:opacity-70 !cursor-not-allowed"}
            iconName={`${distributeStatus ? "pause" : "play_arrow"}`}
            tooltip={enableDistrubute ?
              distributeStatus ? strings.employee.employee_list.close_distribute : strings.employee.employee_list.open_distribute
              : strings.errors.not_allowed}
            action={() => {
              if (enableDistrubute) {
                showCenteredAlertTwoButtons(
                  strings.employee.employee_list.sure,
                  distributeStatus ? strings.employee.employee_list.confirm_close_distribute : strings.employee.employee_list.confirm_open_distribute,
                  strings.button.confirm,
                  strings.button.cancel,
                  changeDistribute
                )
              }
            }}
          />
        </div>
      </td>
    </tr>

    <Portal container={document.getElementById('employee-modal-container')}>
      {(Object.keys(modalContent).length !== 0 && (
          <Modal modal={modal} setModal={setModal} classes={{ modal: "h-[450px] max-w-[620px]" }}>
              {modalContent.element === "distribute" && 
                <DistributeStudentModal 
                  employeeId={listedEmployee.employeeId}
                  employeeTypeId={listedEmployee.employeeType.id}
                  sendRequestToEndpoint={sendRequestToEndpoint} 
                  closeButton={closeButton} 
                />
              }
          </Modal>  
      ))}
    </Portal>
    </>
  );
}

export default EmployeeTableRow;