import { fetchApi } from '../index';

export const CreateOrder = async (model) => {

    const url = '/member/v1/payment/create_order/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const AddPayment = async (model) => {

    const url = '/member/v1/payment/create/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const ApprovePayment = async (paymentId, createUserId) => {

    const url = '/member/v1/payment/approve/';

    const payload = {
        paymentId: paymentId,
        createUserId: createUserId
    };

    return await fetchApi(url, payload);
}

export const ApproveOrder = async (orderId) => {

    const url = '/member/v1/payment/approve_order/';

    const payload = {
        orderId: orderId
    };

    return await fetchApi(url, payload);
}

export const GetBankListById = async (model) => {

    const url = '/virtual_pos/v1/bank/get-bank-list-by-id/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const DeletePayment = async (paymentId) => {

    const url = '/member/v1/payment/delete/';

    const payload = {
        paymentId: paymentId
    };

    return await fetchApi(url, payload);
}

export const UndoDeletePayment = async (paymentId) => {
    
    const url = '/member/v1/payment/undo_delete/';

    const payload = {
        paymentId: paymentId
    };

    return await fetchApi(url, payload);
}

export const CancelPayment = async (paymentId) => {
    
    const url = '/member/v1/payment/cancel/';

    const payload = {
        paymentId: paymentId
    };

    return await fetchApi(url, payload);
}

//Havale/EFT ile ödeme yapılarak kayıt açılıyor
export const PayPackageByTransfer = async (model) => {
    const url = '/member/v1/payment/pay_package_by_transfer/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);    
}

//Kredi kartı ile ödeme yapılarak kayıt açılıyor
export const PayPackageByCreditCard = async (model) => {
    const url = '/member/v1/payment/pay_package_by_credit_card/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);    
}

//Kredi kartı ile 3D ödeme yapmak için kullanılan servis
export const PayPackageBy3DCreditCard = async (model) => {
    const url = '/member/v1/payment/pay_package_by_3d_credit_card/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const GetDiscountDetailsByOrderId = async (orderId) => {
    
    const url = '/member/v1/payment/get_discount_details_by_order_id/';

    const payload = {
        orderId: orderId
    };

    return await fetchApi(url, payload);
}