import { useEffect } from "react";
import { useLocation } from "react-router-dom"
import { ScreenViewLogs } from "../../consts/Logs";
import { decodeToken } from "../../components/Utility";
import { getCookie } from "ko-basic-cookie";
import { cookieName } from "../../consts/Constants";
import { LogService } from "../../services/Log/LogService";

const useScreenViewLogs = () => {

    const location = useLocation();
    const token = getCookie(cookieName);
    const employee = token && decodeToken(token);

    const getValueAndId = (pathname) => {
        const hasId = Object.keys(ScreenViewLogs).find(key => key === pathname);

        if(hasId === undefined) {
            const pathParts = pathname.split('/');
            const id = pathParts[pathParts.length - 1];

            const pathArray = pathParts.slice(1, pathParts.length - 1);
            let result = `/${pathArray.join('/')}/:id`;

            const value = ScreenViewLogs[result];

            return { id, value }
    
        } else {
            const value = ScreenViewLogs[pathname];
            return { value };
        }
    }
    
    useEffect(() => {
        const { id, value } = getValueAndId(location.pathname);

        if (id && value) {
            LogService(value, employee.EmployeeId, undefined, id, undefined);
        } else if(value) {
            LogService(value, employee.EmployeeId, undefined, undefined, undefined);
        }
    }, [location])
}

export default useScreenViewLogs;