import React from 'react';
import classNames from 'classnames';

const PageStep = (props) => {

  const  { step, stepCount } = props;

  const stepButtons = [];

  for (let i = 1; i <= stepCount; i++) {
    const isActive = i <= step;

    stepButtons.push(
      <li key={i} className={classNames('package-select-button', {
        'package-select-button-active': isActive,
        'before:line-between': i !== 1,
        'before:line-between-active': isActive,
      })}>{i}</li>
    );
  }

  return (
    <div>
      <ul className="flex flex-row sm:flex-col gap-8 sm:gap-16">
        {stepButtons}
      </ul>
    </div>
  )
};

export default PageStep;