import { fetchApi } from '../index';

/**
 * Login işlemleri bu servis aracılığı ile yapılıyor
 * @param {string} email - Çalışan e-mail adresi
 * @param {string} password - Çalışan şifre.
 */
export const EmployeeLogin = async (email, password) => {

    const url = '/employee/v1/employee_account/sign_in/';

    const payload = {
        email: email,
        password: password
    };

    return await fetchApi(url, payload);
}

/**
 * Eğer bir çalışan işten ayrıldıysa durumu güncelleniyor.
 * Token cookie'de kayıtlı olduğundan bu güncelleme anlaşılmayabilir.
 * Bu fonksiyon ile çalışan her ana sayfaya geldiğinde durum kontrolü yapılıyor.
 * @param {guid} EmployeeId - Çalışan id.
 */
export const CheckEmployeeStatus = async (employeeId) => {

    const url = '/employee/v1/employee/get_status_by_employee_id/';

    const payload = {
        employeeId: employeeId
    };

    return await fetchApi(url, payload);
}

/**
 * Şifremi unuttum işlemleri bu servis aracılığı ile başlatılıyor
 * @param {string} email - Çalışan e-mail adresi
 */
export const EmployeeForgotPassword = async (email) => {

    const url = '/employee/v1/employee_account/forgot_password/';

    const payload = {
        email: email
    };

    return await fetchApi(url, payload);
}

/**
 * Şifremi unuttum işlemleri bu servis aracılığı ile başlatılıyor
 * @param {string} employeeId - Çalışan Id
 * @param {number} enteredCode - Girilen kod
 */
export const EmployeeVerifyCode = async (employeeId, enteredCode) => {

    const url = '/employee/v1/employee_account/verify_code/';

    const payload = {
        employeeId: employeeId,
        enteredCode: parseInt(enteredCode)
    };

    return await fetchApi(url, payload);
}

/**
 * Şifremi unuttum işlemleri bu servis aracılığı ile başlatılıyor
 * @param {string} employeeId - Çalışan Id
 * @param {number} enteredCode - Girilen kod
 */
export const EmployeeUpdatePassword = async (employeeId, updatePassword) => {

    const url = '/employee/v1/employee_account/update_password/';

    const payload = {
        employeeId: employeeId,
        updatePassword: updatePassword
    };

    return await fetchApi(url, payload);
}