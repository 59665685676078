import React, {useEffect, useState} from "react";
import {CheckIsDemoStudent, GetMemberAiSalesAnalysis} from "../../../../../../services/Member/MemberProfileService";
import {useLocalization} from "../../../../../../hooks/Utils/useLocalization";
import {Loading} from "../../../../../../components";

const MemberSalesAiAnalysis = ({ memberId }) => {

    const strings = useLocalization();

    const [loading, setLoading] = useState(false);
    const [showAiAnalysis, setShowAiAnalysis] = useState(null);
    const [salesAnalysis, setSalesAnalysis] = useState("");

    const getMemberAiSalesAnalysis = (memberId) => {
        GetMemberAiSalesAnalysis(memberId)
            .then(result => {
                if(result.status === 200) {
                    setSalesAnalysis(result.content);
                }

                setLoading(false);
            })
            .catch();
    }

    useEffect(() => {
        if(memberId)
            CheckIsDemoStudent(memberId)
                .then(result => {
                    if(result.status === 200) {
                        const tempShowAiAnalysis = JSON.parse(result.content) ?? false;
                        setShowAiAnalysis(tempShowAiAnalysis);

                        if(tempShowAiAnalysis){
                            setLoading(true);
                            getMemberAiSalesAnalysis(memberId);
                        }
                    }

                })
                .catch()
    }, [memberId]);

    return (
        showAiAnalysis &&
            <div className="mt-5">
                <div className="flex flex-col px-[10px] pb-0">
                    <div className='flex items-center gap-1.5'>
                        <span className="material-symbols-outlined font-bold text-[20px]">smart_toy</span>
                        <label className="text-[13px] font-bold mt-2">
                            {strings.member.member_detail.profile.sales_analysis.title}
                        </label>
                    </div>

                    {loading && <Loading/>}

                    {!loading &&
                        <>
                            {salesAnalysis.length === 0 &&
                                <p className="text-center my-5">
                                    {strings.member.member_detail.profile.sales_analysis.sales_analysis_not_found}
                                </p>
                          }

                          {salesAnalysis.length > 0 &&
                              <textarea
                                  readOnly={true}
                                  value={salesAnalysis || ""}
                                  className="border rounded-lg outline-0 focus:border-blue py-3 px-2 w-full"
                                  rows="4"
                              >
                              </textarea>
                          }
                      </>
                  }
                </div>
            </div>
    )
}

export default MemberSalesAiAnalysis;