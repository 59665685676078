import {PageTitle} from "../../components";
import {useLocalization} from "../../hooks/Utils/useLocalization";
import {Accordion} from "../Member/components";
import React from "react";

const NPSReport = () => {

    const strings = useLocalization();

    return (
        <>
            <PageTitle title={strings.sidebar.report.nps} />

            <Accordion
                title={strings.report.nps.information_title}
                outSideClose={true}
                isOpen={true}
                content={
                    <div>
                        <p className="mb-4">{strings.report.nps.information_one}</p>

                        <p className="mb-4">{strings.report.nps.information_two}</p>

                        <blockquote className="italic border-l-4 p-4 mb-4 bg-gray-100 rounded-md">
                            {strings.report.nps.information_three}
                        </blockquote>

                        <p className="mb-4">{strings.report.nps.information_four}</p>

                        <p className="mb-4">{strings.report.nps.information_five}</p>

                        <p dangerouslySetInnerHTML={{ __html: strings.report.nps.information_six}}></p>
                    </div>

                }
            />
            <div className="flex justify-center mt-5">
                <iframe title="nps" width="1725" height="1725" src="https://lookerstudio.google.com/embed/reporting/7580b9ba-8bf9-4bf9-8190-89be198d2ee4/page/JDZcD" frameBorder="0" allowFullScreen></iframe>
            </div>
        </>
    )
}

export default NPSReport;