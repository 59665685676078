import tr from 'date-fns/locale/tr';
import React, { useEffect, useState } from "react";
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Dropdown, PageTitle, showTopAlert } from "../../../../../components";
import { Brands, ServiceErrorMessages } from "../../../../../consts/Constants";
import { useLocalization } from "../../../../../hooks/Utils/useLocalization";
import { AddHourlyDiscount, GetHourlyDiscountRates } from "../../../../../services/Sales/SalesService";
import useDataLayer from "../../../../../hooks/Log/useDataLayer";
import { CustomEventDescriptions, CustomEvents } from "../../../../../consts/DataLayer";
import { url } from "../../../../../routes/utility";
import useAuth from "../../../../../hooks/Log/useAuth";
import classNames from "classnames";
import { getLanguageTypeByLanguageKey } from "../../../../../components/Utility";
import useMemberBrand from "../../../../../hooks/Member/useMemberBrand";

const HourlyDiscount = () => {

  registerLocale('tr', tr)

  const strings = useLocalization();
  const { memberId } = useParams();
  const { employee } = useAuth();

  const dataLayer = useDataLayer();
  const language = useSelector(state => state.localizationStore.language);
  const navigate = useNavigate();
  const memberBrandId = useMemberBrand(memberId) || -1;

  const firstDiscountType = { id: -1, name: strings.member.member_detail.sales_operations.choose_discount_type };
  const [discountType, setDiscountType] = useState(firstDiscountType);
  const [discountTypeList, setDiscountTypeList] = useState([]);
  const [loading, setLoading] = useState(false);

  const submitHandle = async e => {
    e.preventDefault();
    setLoading(true);

    let valid = true;

    if (discountType.id === -1) {
      valid = false;
    }

    if (valid) {
      const model = {
        memberId: memberId,
        discountMinute: discountType.id,
        createUserId: employee?.EmployeeId
      };

      const result = await AddHourlyDiscount(model);

      if (result.status === 200) {
        showTopAlert(strings.member.member_detail.sales_operations.discount_success_message, "success");

        //DATA LAYER LOGGED
        dataLayer.useCustomEvents(CustomEvents.EMPLOYEE_EVENTS, CustomEventDescriptions.FOURTY_EIGHT_DISCOUNT_CREATED);

        navigate(url("member.detail.profile", { memberId: memberId }));
      } else {
        if (ServiceErrorMessages.ACTIVE_DISCOUNT_ALREADY_EXIST === result.content) {
          showTopAlert(strings.errors.active_discount_exist, "error");
        } else {
          showTopAlert(strings.errors.an_error_occurred, "error");
        }
      }
    } else {
      showTopAlert(strings.alert.top_alert.inform, "warning");
    }

    setLoading(false);
  };

  useEffect(() => {
    if (memberBrandId !== -1)
      GetHourlyDiscountRates(getLanguageTypeByLanguageKey(language))
        .then(result => {
          if (result.status === 200) {
            const resultContent = JSON.parse(result.content);
            let tempDiscountTypeList = [firstDiscountType];

            if (memberBrandId === Brands.KO) {
              resultContent.forEach(result => {
                tempDiscountTypeList.push({
                  id: result.minute,
                  name: result.title + " - %" + result.discountRate
                });
              });
            } else {
              const fortyEightDiscountMinute = 2880;
              const specificItem = resultContent.find(result => result.minute === fortyEightDiscountMinute);
              if (specificItem) {
                tempDiscountTypeList.push({
                  id: specificItem.minute,
                  name: specificItem.title + " - %" + specificItem.discountRate
                });
              }
            }

            setDiscountTypeList(tempDiscountTypeList);
          }
        })
        .catch()
  }, [language, memberBrandId]);

  return (
    <>
      <PageTitle title={strings.member.member_detail.header.content.fourty_eight_hours_discount} />
      <form onSubmit={submitHandle}>
        <div className="flex flex-col lg:flex-row lg:justify-between">
          <div className="mt-5 w-full">
          </div>
          <div className="mt-5 w-full">
            <div className="flex flex-col px-[10px] pb-0">
              <label htmlFor="discount_type">
                {strings.member.member_detail.sales_operations.discount_type}
              </label>
              <Dropdown
                classnames="md:max-w-full"
                data={discountTypeList}
                selectedData={discountType}
                setSelectedData={setDiscountType}
              />
            </div>
          </div>
          <div className="mt-5 w-full">
          </div>
        </div>
        <button
          type="submit"
          className={classNames("button search-button gap-3 text-white bg-blue border-blue mt-7 mx-auto px-10", {
            "opacity-70 cursor-not-allowed hover:opacity-70": loading
          })}
        >
          {loading && <div className="inline-block h-4 w-4 animate-spin rounded-full border-[3px] border-solid border-current border-r-transparent align-[-0.25em] motion-reduce:animate-[spin_1.5s_linear_infinite]"></div>}
          {!loading && strings.member.member_detail.sales_operations.send_code}
        </button>
      </form>
    </>
  );
};

export default HourlyDiscount;
