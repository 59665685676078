import {ISOStringToDate} from "../../../../components/Utility";
import {SquareButton} from "../../../../components";
import {url} from "../../../../routes/utility";
import {useLocalization} from "../../../../hooks/Utils/useLocalization";
import useAuth from "../../../../hooks/Log/useAuth";
import {Link} from "react-router-dom";

const UseCaseHistoryTableRow = ({ history }) => {

    const strings = useLocalization();
    const { employee } = useAuth();
    
    return (
        <tr className="bg-white hover:bg-[#f0f0f0]">
            <td className="td align-middle">
                <Link
                    to={url("member.detail.profile", { memberId: history.memberId })}
                    target="_blank"
                    className="font-bold text-blue hover:underline focus:underline  min-w-max"
                >
                    <p className="min-w-max">{history.memberName}</p>
                </Link>
            </td>
            <td className="td align-middle">
                {history.aiUseCaseTranslationTitle}
            </td>
            <td className="td align-middle">
                <p className="min-w-max">{ISOStringToDate(history.aiUseCaseSessionCreateDate, employee.TimeZone)}</p>
            </td>
            <td className="td align-middle">
                <SquareButton
                    classnames="bg-warning"
                    iconName="chat"
                    to={url("history.detail")}
                    tooltip={strings.button.detail}
                    state={{ id: history.aiUseCaseSessionId, memberName: history.memberName, memberId: history.memberId }}
                />
            </td>
        </tr>
    )
}

export default UseCaseHistoryTableRow;