import {useEffect, useState} from "react";
import {GetEmailByMemberId, GetMemberName} from "../../services/Member/MemberService";

const useMemberName = (memberId) => {

    const [memberName, setMemberName] = useState("");

    const getMemberName = async (memberId) => {
        const result = await GetMemberName(memberId);

        if (result.status === 200) {
            setMemberName(memberName => result.content);
        }
    }

    useEffect(() => {
        if(memberId.length > 0)
            getMemberName(memberId);
    }, [memberId]);

    return memberName;
}

export default useMemberName;