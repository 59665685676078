import {useLocalization} from "../../hooks/Utils/useLocalization";
import {PageTitle} from "../../components";
import {Accordion} from "../Member/components";
import React from "react";

const DemoDashboard = () => {

    const strings = useLocalization();

    return (
        <>
            <PageTitle title={strings.sidebar.report.demo_dashboard} />

            <Accordion
                title={strings.report.demo_dashboard.information_title}
                outSideClose={true}
                isOpen={true}
                content={<p dangerouslySetInnerHTML={{ __html: strings.report.demo_dashboard.information_one}}></p>}
            />
            <div className="flex justify-center mt-5">
                <iframe title="demo-dashboard" width="1725" height="1725" src="https://lookerstudio.google.com/embed/reporting/18b8a55c-b68d-4a07-a24f-bf36e1125c6e/page/mYduD" allowFullScreen></iframe>
            </div>
        </>
    )
}

export default DemoDashboard;