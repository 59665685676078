import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {
    Button,
    Dropdown, DropdownSearch, LoadingSpinner,
    PageTitle,
    showCenteredAlertTwoButtons,
    showCenteredHtmlAlert,
    showTopAlert
} from "../../../../../components";
import {getFromBetween, removeHtmlTags} from "../../../../../components/Utility";
import {ClickLogs} from "../../../../../consts/Logs";
import {Notes} from "../../../../../consts/Notes";
import {useLocalization} from "../../../../../hooks/Utils/useLocalization";
import {
    GetSmsTemplateContentByIdAndMemberId,
    GetSmsTemplateList,
    SendSmsModel
} from "../../../../../services/Information/InformationService";
import useAnalytics from "../../../../../hooks/Log/useAnalytics";
import useMemberBrand from "../../../../../hooks/Member/useMemberBrand";
import useMemberPhoneNumber from "../../../../../hooks/Member/useMemberPhoneNumber";
import useCreateMemberNote from "../../../../../hooks/Log/useCreateMemberNote";
import useAuth from "../../../../../hooks/Log/useAuth";
import useDataLayer from "../../../../../hooks/Log/useDataLayer";
import {CustomEventDescriptions, CustomEvents} from "../../../../../consts/DataLayer";

const SendSms = () => {

    const strings = useLocalization();

    /* Hooks */
    const {memberId} = useParams() || false;
    const {employee} = useAuth();
    const analytics = useAnalytics();
    const dataLayer = useDataLayer();
    const memberNote = useCreateMemberNote();
    const memberBrandId = useMemberBrand(memberId);
    const memberPhoneNumber = useMemberPhoneNumber(memberId);
    /* Hooks */

    const language = useSelector(state => state.localizationStore.language);

    const topRef = useRef();

    const firstSmsType = {id: -1, name: strings.member.member_detail.inform_operations.sms_titles}
    const [smsType, setSmsType] = useState(firstSmsType);

    const [smsList, setSmsList] = useState([]);

    const [tempContent, setTempContent] = useState(null);
    const [content, setContent] = useState(null);

    const [tempWhatsappContent, setTempWhatsappContent] = useState(null);
    const [whatsappContent, setWhatsappContent] = useState(null);

    const [params, setParams] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);
    const [showParameter, setShowParameter] = useState(false);
    const [sendSmsButtonLoading, setSendSmsButtonLoading] = useState(false);

    //Brand'a ait sms listesini alıyoruz
    const getSmsTemplateList = async (memberBrandId) => {
        const result = await GetSmsTemplateList(memberBrandId);

        let arrayList = [];

        if (result.status === 200) {
            const data = JSON.parse(result.content);
            data.map((item) => {
                let arrayItem = {id: parseInt(item.value), name: item.text};
                arrayList.push(arrayItem);
            })
        }

        setSmsList([firstSmsType, ...arrayList]);
    }

    //Seçilen sms tipine göre içeriği getiriyoruz
    const getSmsTemplateContentByIdAndMemberId = async (contentId, memberId) => {
        setShowParameter(showParameter => false)
        const result = await GetSmsTemplateContentByIdAndMemberId(contentId, memberId);
        if (result.status === 200) {
            let resultContent = JSON.parse(result.content);

            setTempContent(tempContent => resultContent.description);
            setTempWhatsappContent(tempWhatsappContent => resultContent.whatsappContent);
            setShowParameter(showParameter => true)
        }
    }

    const resetStates = () => {
        setSmsType(emailType => firstSmsType);
        setTempWhatsappContent(tempWhatsappContent => null);
        setTempContent(tempContent => null);
    }

    const sendToEndpoint = async () => {

        let valid = true;
        let errorList = '';

        params.map(p => {
            let inputValue = document.getElementById(p.input).value;
            if (inputValue === "") {
                valid = false;
                errorList += p.label + strings.errors.input_field_null_error + "<br />";
            }
        })

        if (valid) {
            setSendSmsButtonLoading(sendSmsButtonLoading => true);
            let smsContent = document.getElementById("contentDiv").innerText;

            const model = {
                receivers: [memberPhoneNumber],
                message: smsContent,
                //Bu alan PHP serviste dolduruluyor.
                userApiKey: ""
            };

            const result = await SendSmsModel(model);

            if (result.status === 200) {
                resetStates();
                topRef.current.focus();

                //LOGGED
                analytics.useAnalytics(ClickLogs.SMS_WAS_SEND, {memberId: memberId});

                //DATA LAYER LOGGED
                dataLayer.useCustomEvents(CustomEvents.EMPLOYEE_EVENTS, CustomEventDescriptions.SMS_SENDED);

                //NOTED
                memberNote.useCreateMemberNote(
                    memberId,
                    Notes.SEND_SMS_NOTE
                        .replace("{consultantName}", employee?.FullName)
                        .replace("{SmsTitle}", smsType.name),
                    43 //default
                );

                showTopAlert(strings.member.member_detail.inform_operations.sms_success_message, 'success');
            } else {
                showTopAlert(strings.errors.an_error_occurred, "error");
            }

            setSendSmsButtonLoading(sendSmsButtonLoading => false);
        } else {
            showCenteredHtmlAlert(strings.errors.an_error_occurred, errorList, "error");
        }
    }

    const sendWhatsAppMessage = () => {
        let valid = true;
        let errorList = '';

        params.map(p => {
            let inputValue = document.getElementById(p.input).value;
            if (inputValue === "") {
                valid = false;
                errorList += p.label + strings.errors.input_field_null_error + "<br />";
            }
        })

        if (valid) {
            /*
              WP template'i getirilip HTML taglarinden arındılıyor.
              Base URL ile birleştirilip WP ekranı yan sekmede açılıyor
            */
            let cleanedWhatsappContent = removeHtmlTags(whatsappContent);
            let whatsappApiUrl = process.env.REACT_APP_WHATSAPP_API_URL + `/?phone=${memberPhoneNumber}&text=${cleanedWhatsappContent}`;

            resetStates();
            topRef.current.focus();

            //LOGGED
            analytics.useAnalytics(ClickLogs.SMS_WAS_SEND_ON_WHATSAPP, {memberId: memberId});

            //DATA LAYER LOGGED
            dataLayer.useCustomEvents(CustomEvents.EMPLOYEE_EVENTS, CustomEventDescriptions.WP_SENDED);

            //NOTED
            memberNote.useCreateMemberNote(
                memberId,
                Notes.SEND_SMS_NOTE_WHATSAPP
                    .replace("{consultantName}", employee?.FullName)
                    .replace("{SmsTitle}", smsType.name),
                43 //default
            );

            //WhatsApp ekranı yeni sekmede açılıyor
            window.open(whatsappApiUrl);
        }
    }

    const changeSpan = async (value, span, spanwp) => {
        document.getElementById(span).innerText = value;
        if (document.getElementById(spanwp))
            document.getElementById(spanwp).innerText = value;
    }

    const handleButtonDisable = () => {
        let valid = true;

        params.map(p => {
            let inputValue = document.getElementById(p.input).value;
            if (inputValue.length === 0) {
                valid = false;
            }
        })

        setIsDisabled(isDisabled => !valid)
    }

    useEffect(() => {
        if (tempContent !== null) {
            //Content içerisinde özel karakterler ile ayrılmış herhangi bir parametre var mı?
            //Özel karekter tipi şu şekilde {{{span:İngilizce isim#Türkçe isim}}}

            let parameters = getFromBetween.get(tempContent, '{{{', '}}}');
            let cntnt = tempContent;
            let wpcntnt = tempWhatsappContent;
            //Eğer parametre var ise
            if (parameters.length > 0) {
                setIsDisabled(disabled => true)
                //Span isimleri ve gösterilecek isimler
                let paramArray = [];
                //Eğer belirtilen tipte bir dizi geliyorsa
                parameters.map((p, index) => {
                    let param = p.split(":");
                    let paramName = param[1].split("#");
                    paramArray.push({
                        span: "span_" + param[0] + index,
                        span_wp: "wp_span_" + param[0] + index,
                        input: "input_" + param[0] + index,
                        label: language === "tr" ? paramName[1] : paramName[0]
                    });
                    cntnt = cntnt.replace('{{{' + parameters[index] + '}}}', '<span id="span_' + param[0] + index + '"></span>');

                    if (tempWhatsappContent) {
                        wpcntnt = wpcntnt.replace('{{{' + parameters[index] + '}}}', '<span id="wp_span_' + param[0] + index + '"></span>');
                    }
                });
                setParams(paramArray);
            }
            setContent(cntnt);
            setWhatsappContent(wpcntnt);
        }
    }, [tempContent, tempWhatsappContent, language])

    //Seçilen başlığıa ait içerik getiriliyor
    useEffect(() => {
        if (smsType.id !== -1 && memberId) {
            /*
              Mail ve WP templatelerini getirmeden önce disabled durumu sıfırlanıyor.
              Bu durumun nedeni template değiştikçe butonların doğru davranması içindir.
            */
            setIsDisabled(isDisabled => false);
            getSmsTemplateContentByIdAndMemberId(smsType.id, memberId);
        } else {
            //SMS type default seçildiğinde buton tekrar disable yapılıyor.
            setIsDisabled(isDisabled => true);
            setContent(null);
            setTempContent(null);
        }

        if (smsType.id === -1)
            setParams([]);
    }, [smsType])

    //Markaya ait içerik listesi alınıyor
    useEffect(() => {
        if (memberBrandId !== 0) {
            getSmsTemplateList(memberBrandId);
        }
    }, [memberBrandId])


    return (
        <>
            <PageTitle title={strings.member.member_detail.header.content.send_sms}/>
            <p tabIndex={-1} ref={topRef} className="h-0"></p>
            <div className="flex flex-col px-[10px] pb-0 mt-5">
                <label className="font-bold mb-2">
                    {strings.member.member_detail.inform_operations.sms_title}
                </label>
                <DropdownSearch
                    inputPlaceholder={strings.detail_search_titles.search_sms}
                    classnames="md:max-w-full"
                    data={smsList}
                    selectedData={smsType}
                    setSelectedData={setSmsType}
                />
            </div>
            {smsType.id !== -1 &&
                <div className="flex flex-col px-[10px] pb-0 mt-5">
                    {params.length > 0 && (
                        <>
                            <p className="text-[13px] font-bold mb-2">{strings.member.member_detail.inform_operations.parameter}</p>
                            <hr/>
                            <div className="flex flex-row gap-4 my-3">
                                {params.map((p, index) => (
                                    <div key={index} className="flex flex-col w-full">
                                        <label className="font-bold mb-2">
                                            {p.label}
                                        </label>
                                        <input
                                            id={p.input}
                                            type="text"
                                            className="form-input w-full placeholder:!text-[13px]"
                                            onChange={(e) => {
                                                changeSpan(e.target.value, p.span, p.span_wp);
                                                handleButtonDisable();
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                        </>
                    )}

                    {content && (
                        <>
                            <p className="text-[13px] font-bold mb-2">{strings.member.member_detail.inform_operations.sms_content}</p>
                            <div
                                id="contentDiv"
                                className="w-full h-[100px] overflow-y-scroll"
                                dangerouslySetInnerHTML={{__html: content !== null ? content : ""}}
                            />
                        </>
                    )}

                    {/*
                        DecodeURI ile url encoded ile gelen templateler kullanıcıya gösterilirken decode ediliyor.
                    */}
                    {whatsappContent && (
                        <div className="mt-5">
                            <p className="text-[13px] font-bold mb-2">{strings.member.member_detail.inform_operations.whatsapp_content}</p>
                            <div
                                id="whatsappContentDiv"
                                className="w-full h-[100px] overflow-y-scroll"
                                dangerouslySetInnerHTML={{__html: whatsappContent !== null ? decodeURI(whatsappContent) : ""}}
                            />
                        </div>
                    )}
                </div>
            }

            <div className="flex justify-center gap-2">
                <Button
                    text={sendSmsButtonLoading ? <LoadingSpinner /> : strings.button.send_sms}
                    disabled={isDisabled || !tempContent || sendSmsButtonLoading}
                    type="button"
                    classnames={`bg-blue w-[177px] border-blue mt-5 !px-10 
                        ${(isDisabled || !showParameter || sendSmsButtonLoading) ? "opacity-70 hover:opacity-70 cursor-not-allowed" : ''} 
                    `}
                    action={() => {
                        showCenteredAlertTwoButtons(
                            strings.alert.centered_alert_two_buttons.title_confirm,
                            strings.member.member_detail.inform_operations.send_sms_question,
                            strings.button.confirm,
                            strings.button.cancel,
                            sendToEndpoint
                        );
                    }}
                />
                <Button
                    text={strings.button.send_from_whatsapp_button}
                    disabled={isDisabled || !tempWhatsappContent}
                    type="button"
                    classnames={`bg-blue w-[177px] border-blue mt-5 !px-10 ${(isDisabled || !tempWhatsappContent) ? "opacity-70 hover:opacity-70 cursor-not-allowed" : ''} `}
                    action={() => {
                        showCenteredAlertTwoButtons(
                            strings.alert.centered_alert_two_buttons.title_confirm,
                            strings.member.member_detail.inform_operations.send_whatsapp_message,
                            strings.button.confirm,
                            strings.button.cancel,
                            sendWhatsAppMessage
                        );
                    }}
                />
            </div>
        </>
    );
};

export default SendSms;
