import React from "react";
import { useLocalization } from "../../../../hooks/Utils/useLocalization";
import { NoRecordsFound } from "../../../../components";
import InvoiceTableRow from "./InvoiceTableRow";

const InvoiceTable = ({ data }) => {

  const strings = useLocalization();
    
  return (
    <>
      <div className="container mx-auto px-4 mb-4">
        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
          <div className="inline-block min-w-full rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal text-header">
              <thead>
                <tr>
                  <th className="th">{strings.table.invoice_number}</th>
                  <th className="th min-w-[170px]">{strings.table.person_informations}</th>
                  <th className="th min-w-[200px]">{strings.table.payment_informations}</th>
                  <th className="th w-[170px]">{strings.table.total_price}</th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 ? 
                    (
                        data.map((invoice, index) => (
                            <InvoiceTableRow 
                                key={index}
                                invoice={invoice}
                            />
                        ))
                    )
                    :
                    (
                      <tr className="bg-white hover:bg-[#f0f0f0]">
                        <td colSpan="4">
                            <NoRecordsFound />
                        </td>
                      </tr>
                    )
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoiceTable;
