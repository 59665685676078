import {useLocalization} from "../../../../../hooks/Utils/useLocalization";
import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import CallRecordsDetailSearch from "./CallRecordsDetailSearch";
import CallRecordsTable from "./CallRecordsTable";
import {dateToStringWithTime, getDateWithDayDifference} from "../../../../../components/Utility";
import {GetCallRecords} from "../../../../../services/Alotech/AlotechService";
import {Loading, MemberInformations, PageTitle, SearchCount} from "../../../../../components";
import useMemberBrand from "../../../../../hooks/Member/useMemberBrand";
import {Brands} from "../../../../../consts/Constants";
import {url} from "../../../../../routes/utility";
import useMemberPhoneNumber from "../../../../../hooks/Member/useMemberPhoneNumber";

const CallRecords = () => {

    const strings = useLocalization();
    const navigate = useNavigate();
    const { memberId } = useParams() || false;
    const memberBrandId = useMemberBrand(memberId);
    const memberPhoneNumber = useMemberPhoneNumber(memberId);

    const [loading, setLoading] = useState(false);
    const [totalRecord, setTotalRecord] = useState(0);
    const [data, setData] = useState([]);
    const [errorStatus, setErrorStatus] = useState(false);

    // Son 30 Gün Seçeneği Seçili getiriliyor
    const [detailSearch, setDetailSearch] = useState({
        beginDate: dateToStringWithTime(getDateWithDayDifference(new Date(), 30).setHours(0, 0, 0, 0)),
        endDate: dateToStringWithTime(new Date().setHours(23, 59, 59, 59)),
        corporateEmail: ""
    });

    //dropdown değişiklikleri bu alandan yönetiliyor
    const changeDetail = (values) => {
        setDetailSearch({ ...detailSearch, ...values });
    };

    //Öğreciye ait arama bilgileri getiriliyor.
    const getCallRecords = async (detailSearch, memberPhoneNumber) => {
        setLoading(loading => true);

        try {
            const model = {
                startDate: detailSearch.beginDate,
                finishDate: detailSearch.endDate,
                agentUsername : detailSearch.corporateEmail,
                memberPhoneNumber : memberPhoneNumber
            };

            const result = await GetCallRecords(model);

            if(result.success){
                setData(result?.CallList.sort(sortByPropertyDesc('calldate')));
                setTotalRecord(result?.CallList.length);
            } else {
                setErrorStatus(true);
            }
        } catch (e) {
            setErrorStatus(true);
        }

        setLoading(false);
    }

    const sortByPropertyDesc = (property) => {
        return function (a, b) {
            const aProp = property === 'calldate'
                ? new Date(a[property])
                : a[property];

            const bProp = property === 'calldate'
                ? new Date(b[property])
                : b[property];

            if (bProp < aProp) {
                return -1;
            } else if (bProp > aProp) {
                return 1;
            } else {
                return 0;
            }
        };
    }

    useEffect(() => {

        let abortController = new AbortController();

        if(memberPhoneNumber.length > 0)
            getCallRecords(detailSearch, memberPhoneNumber);

        return () => {
            abortController.abort();
        }

    }, [detailSearch, memberPhoneNumber])

    //KO olmayan markalardan sayfaya girmeye çalışan olursa sayfadan atıyoruz.
    useEffect(() => {
        if(memberBrandId !== -1 && memberBrandId !== Brands.KO)
            navigate(url("member.detail.profile", { memberId: memberId }));
    }, [memberBrandId]);

    return (
        <>
            <CallRecordsDetailSearch
                changeDetail={changeDetail}
                loading={loading}
            />

            <div className="overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
                <div className="inline-block min-w-full rounded-lg overflow-hidden py-6">
                    {!loading && <SearchCount count={totalRecord} />}

                    <PageTitle title={strings.member.member_detail.header.call_records} />

                    <MemberInformations memberId={memberId} />

                    {loading && <Loading />}

                    {!loading && <CallRecordsTable data={data} errorStatus={errorStatus} />}
                </div>
            </div>
        </>
    );
}


export default CallRecords;