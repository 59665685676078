import React, { useEffect, useState } from 'react'
import MemberTeacherMessageDetailSearch from './MemberTeacherMessageDetailSearch'
import { Dropdown, Loading, NoRecordsFound, Pagination, SquareButton, showCenteredAlertTwoButtonsWithParameters, showTopAlert } from '../../../../../../../../../components';
import { useLocalization } from '../../../../../../../../../hooks/Utils/useLocalization';
import { ISOStringToDate, ISOStringToDateWithTime, stringToDate } from '../../../../../../../../../components/Utility';
import { AddMemberTeacherMessage, MemberTeacherMessageFilter, UpdateMemberTeacherMessageStatus } from '../../../../../../../../../services/Member/MemberTeacherMessageService';
import { TeacherMessageType } from '../../../../../../../../../consts/Constants';
import useAuth from '../../../../../../../../../hooks/Log/useAuth';

const MemberTeacherMessageModal = ({ studentNo, memberId, classes }) => {
    const strings = useLocalization();
    const { employee } = useAuth();

    const [detailSearch, setDetailSearch] = useState({
        beginDate: null,
        endDate: null,
        teacherMesageType: -1
    });

    const limit = parseInt(process.env.REACT_APP_TABLE_ROW_LIMIT);
    const [start, setStart] = useState(0);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecord, setTotalRecord] = useState(0);

    const [message, setMessage] = useState(null);

    const firstTeacherMessageType = { id: 2, name: strings.member.member_detail.terms.operation_modal.member_teacher_message.message_type_1 };
    const [teacherMessageType, setTeacherMessageType] = useState(firstTeacherMessageType);
    const teacherMessageTypeList = [firstTeacherMessageType,
        { id: 1, name: strings.member.member_detail.terms.operation_modal.member_teacher_message.message_type_2 },
        { id: 3, name: strings.member.member_detail.terms.operation_modal.member_teacher_message.message_type_3 }
    ];

    const [data, setData] = useState([]);

    const getMemberTeacherMessages = async () => {
        const model = {
            start: start,
            length: limit,
            studentNo: studentNo,
            beginDate: detailSearch.beginDate ? stringToDate(detailSearch.beginDate) : null,
            endDate: detailSearch.endDate ? stringToDate(detailSearch.endDate, 23, 59, 59) : null,
            teacherMessageType: detailSearch.teacherMesageType
        }

        const result = await MemberTeacherMessageFilter(model);

        if (result.status === 200) {
            let resultContent = JSON.parse(result.content);
            setData(data => resultContent.data);
            setTotalRecord(totalRecord => resultContent.recordsTotal);
            setLoading(loading => false);
        } else {
            showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
        }
    }

    useEffect(() => {

        let abortController = new AbortController();

        getMemberTeacherMessages();

        return () => {
            abortController.abort();
        }
    }, [detailSearch])

    const sendRequestToEndpoint = () => {
        getMemberTeacherMessages();
    }

    //Arama verileri değiştiğinde değerleri sıfırlıyoruz
    const resetValue = () => {
        setStart(start => 0);
        setCurrentPage(currentPage => 1);
    }

    //select değişiklikleri bu alandan yönetiliyor
    const changeDetail = (values) => {
        setDetailSearch({ ...detailSearch, ...values });
        resetValue();
    }

    //Mesaj durumunu değiştiriyoruz
    const updateMessageStatus = async (messageId, status) => {
        if (messageId) {

            let model = {
                id: messageId,
                status: status === 1 ? 0 : 1,
                createUserId: employee.EmployeeId,
                memberId: memberId
            };

            const result = await UpdateMemberTeacherMessageStatus(model);

            if (result.status === 200) {
                if (status === 0) {

                    showTopAlert(strings.member.member_detail.terms.operation_modal.member_teacher_message.active_success_message, "success");
                }
                if (status === 1) {
                    showTopAlert(strings.member.member_detail.terms.operation_modal.member_teacher_message.passive_success_message, "success");
                }
                sendRequestToEndpoint();
                //setTimeout(() => { window.location.reload() }, 2000);
            }
        }
    }

    const submitHandle = async (e) => {
        e.preventDefault();
        let valid = true;

        if (!message || message == "") {
            valid = false;
        }
        if (teacherMessageType.id < 1 && teacherMessageType.id > 2) {
            valid = false;
        }

        if (valid) {
            let model = {
                studentNo: studentNo,
                message: message.replace(/['"]/g, '`'),
                teacherMessageType: teacherMessageType.id,
                memberId: memberId,
                createUserId: employee.EmployeeId
            };

            const result = await AddMemberTeacherMessage(model);

            if (result.status === 200) {
                showTopAlert(strings.member.member_detail.terms.operation_modal.member_teacher_message.success, "success");
                setTeacherMessageType(firstTeacherMessageType);
                setMessage(null);
                sendRequestToEndpoint();
            } else {
                showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
            }

        } else {
            showTopAlert(strings.errors.an_error_occurred, "error");
        }

        

    }

    return (
        <div className={`animate-fadeIn w-full ${classes}`}>
            <div className="text-[13px] text-start">
                <div className="flex justify-center mb-1">
                    <p className="font-bold text-[15px]">{strings.member.member_detail.terms.member_teacher_message}</p>
                </div>

                <form onSubmit={submitHandle} className="mb-5">
                    <div className="flex flex-col">
                        <label className='mt-2'>
                            {strings.member.member_detail.terms.operation_modal.member_teacher_message.title_2}
                        </label>
                        <Dropdown
                            classnames="md:max-w-full"
                            data={teacherMessageTypeList}
                            selectedData={teacherMessageType}
                            setSelectedData={setTeacherMessageType}
                        />

                        <label className='mt-2' htmlFor="demo_minute">
                            {strings.member.member_detail.terms.operation_modal.member_teacher_message.title_1}
                        </label>
                        <textarea
                            id="note"
                            name="note"
                            value={message || ""}
                            onChange={(e) => setMessage(e.target.value)}
                            className="border rounded-lg outline-0 focus:border-blue py-3 px-2 w-full mt-2"
                            type="text"
                            rows="2"
                        ></textarea>
                        <div className="flex gap-3 justify-center">
                            <button
                                type="submit"
                                className="group button search-button gap-3 text-white bg-blue border-blue focus:border-blue mt-7 px-10"
                            >
                                {strings.button.save_upper}
                            </button>
                        </div>
                    </div>
                </form>

                <MemberTeacherMessageDetailSearch
                    changeDetail={changeDetail}
                    loading={loading}
                />

                {loading ?
                    (
                        <Loading />
                    )
                    :
                    (
                        <div className="overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
                            <div className="inline-block min-w-full rounded-lg pt-8 pb-2">
                                <table className="min-w-full leading-normal text-header">
                                    <thead>
                                        <tr>
                                            <th className="th">
                                                <p className='min-w-max'>{strings.member.member_detail.terms.operation_modal.member_teacher_message.title_1}</p>
                                            </th>
                                            <th className="th">
                                                <p className='min-w-max'>{strings.member.member_detail.terms.operation_modal.member_teacher_message.title_2}</p>
                                            </th>
                                            <th className="th">
                                                <p className='min-w-max'>{strings.member.member_detail.terms.operation_modal.member_teacher_message.title_3}</p>
                                            </th>
                                            <th className="th">
                                                <p className="min-w-max">{strings.member.member_detail.terms.operation_modal.member_teacher_message.title_4}</p>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.length > 0 ?
                                            (
                                                data.map((message, index) => (
                                                    <tr key={index}>
                                                        <td className="td h-[60px] align-middle">
                                                            <p className='max-w-[500px]'>{message.message}</p>
                                                        </td>
                                                        <td className="td h-[60px] align-middle">
                                                            <p>{TeacherMessageType.find(mt => message.teacherMessageType == mt.id).name}</p>
                                                        </td>
                                                        <td className="td h-[60px] align-middle">
                                                            <p>{ISOStringToDate(message.messageDate, employee.TimeZone)}</p>
                                                        </td>
                                                        <td className="td h-[60px] align-middle">
                                                            <SquareButton
                                                                iconName={message.status === 1 ? 'check_circle' : 'cancel'}
                                                                classnames={`${message.status === 1 ? 'bg-green border-green' : 'bg-red border-red'} transition-color duration-500`
                                                                }
                                                                tooltip={message.status === 1 ? strings.form_info.active : strings.form_info.pasive}
                                                                action={() => {
                                                                    showCenteredAlertTwoButtonsWithParameters(
                                                                        strings.alert.centered_alert_two_buttons.title,
                                                                        message.status === 1
                                                                            ? strings.member.member_detail.terms.operation_modal.member_teacher_message.passive_question
                                                                            : strings.member.member_detail.terms.operation_modal.member_teacher_message.active_question,
                                                                        strings.button.confirm,
                                                                        strings.alert.centered_alert_two_buttons.cancel_text,
                                                                        updateMessageStatus,
                                                                        [message.id, message.status]
                                                                    )
                                                                }}
                                                            />
                                                        </td>
                                                    </tr>
                                                )
                                                )
                                            )
                                            :
                                            (
                                                <tr className='bg-white hover:bg-[#f0f0f0]'>
                                                    <td colSpan={6}>
                                                        <NoRecordsFound />
                                                    </td>
                                                </tr>
                                            )
                                        }


                                    </tbody>
                                </table>
                                <Pagination
                                    totalCount={totalRecord}
                                    limit={limit}
                                    start={start}
                                    setStart={setStart}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    setLoading={setLoading}
                                    loadScreen={true}
                                />
                            </div>

                        </div>
                    )}
            </div>
        </div>
    )
}

export default MemberTeacherMessageModal