import { fetchApi } from '../index';

export const CurrentCardFilter = async (model) => {
    
    const url = '/member/v2/current_card/filter/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const GetCurrentCardList = async (memberId) => {
    
    const url = '/member/v1/current_card/list/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const CreateCurrentCard = async (model) => {
    
    const url = '/member/v1/current_card/create/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const UpdateCurrentCard = async (model) => {
    
    const url = '/member/v1/current_card/update/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const DeleteCurrentCard = async (model) => {
    
    const url = '/member/v1/current_card/delete/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const UndoDeleteCurrentCard = async (model) => {
    
    const url = '/member/v1/current_card/undo_delete/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const GetCurrentCardById = async (currentCardId) => {
    
    const url = '/member/v1/current_card/get_current_card_by_id/';

    const payload = {
        currentCardId: currentCardId
    };

    return await fetchApi(url, payload);
}

export const GetCurrentCardSelectListByMemberId = async (memberId) => {
    
    const url = '/member/v1/current_card/get_current_card_select_list_by_member_id/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

//Cari kart ID'sine göre, ilgili cari kart ile kesilmiş fatura listesini getirir.
export const GetInvoicesById = async (currentCardId) => {

    const url = '/member/v1/current_card/get_with_invoices_by_id/';

    const payload = {
        currentCardId: currentCardId
    };

    return await fetchApi(url, payload);
}

