import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Dropdown, Input, PageTitle, showTopAlert } from "../../../../../components";
import { handleCheckbox, addTimeZoneToFullDate} from "../../../../../components/Utility";
import useAuth from "../../../../../hooks/Log/useAuth";
import { useLocalization } from "../../../../../hooks/Utils/useLocalization";
import { GetMoneyBoxOperationDetails, GetTotalMoneyBoxRate, MoneyBoxOperations } from "../../../../../services/Member/MemberReferenceService";
import ReferenceInfo from "./ReferenceInfo";

const Moneybox = () => {
  const strings = useLocalization();
  const { memberId } = useParams() || false;
  const { employee } = useAuth();

  //select componenti için option'a gönderilecek veriler
  const staticStatusData = [
    {
      id: 0,
      name: strings.member.member_detail.moneybox.use_moneybox_discount,
    },
    {
      id: 1,
      name: strings.member.member_detail.moneybox.add_moneybox_discount,
    },
  ];

  const discountRef = useRef();
  const descriptionErrorRef = useRef();
  const statusErrorRef = useRef();
  const rateErrorRef = useRef();

  const firstStatus = { id: -1, name: strings.employee.create_employee.choose_status };
  const statusList = [firstStatus, ...staticStatusData];
  const [status, setStatus] = useState(firstStatus);
  const [totalMoneyBoxRate, setTotalMoneyBoxRate] = useState(0);

  //statik rate verilerini oluşturmak için
  const createRateData = () => {
    const dataArray = [];

    //Eğer kullanma açılacaksa member a ait toplam indirim oranı kadar oluşturulacak
    const maxRate = status.id === 0 ? 100 : totalMoneyBoxRate;

    let lastItem = { id: 1, name: 0 };
    while (lastItem.name < maxRate) {
      const newItem = { id: lastItem.id + 1, name: lastItem.name + 5 };
      dataArray.push(newItem);
      lastItem = newItem;
    }

    return dataArray;
  }

  const rateData = createRateData();
  const firstDiscountRate = { id: -1, name: strings.member.member_detail.moneybox.choose_discount_rate };
  const discountRateList = [firstDiscountRate, ...rateData];

  const [refreshInfo, setRefreshInfo] = useState(false);
  const [discountRate, setDiscountRate] = useState(firstDiscountRate);
  const [description, setDescription] = useState("");
  const [isSendInformation, setIsSendInformation] = useState(false);
  const [moneyBoxOperationList, setMoneyBoxOperationList] = useState([]);

  //Kumbara işlemlerine ait veriler bu fonksiyon aracılığı ile çekiliyor
  const getMoneyBoxOperationDetails = async (memberId) => {
    const result = await GetMoneyBoxOperationDetails(memberId);
    if (result.status === 200) {
      let operationsList = JSON.parse(result.content);
      if (operationsList.length > 0) {
        setMoneyBoxOperationList(moneyBoxOperationList => operationsList);
      }
    }
  }

  //Member a ait toplam kumbara indirimini getiriyoruz
  const getTotalMoneyBoxRate = async (memberId) => {
    const result = await GetTotalMoneyBoxRate(memberId);
    if (result.status === 200) {
      setTotalMoneyBoxRate(totalMoneyBoxRate => parseInt(result.content));
    }
  }

  // Moneybox tip açıklamalarını döndüren fonksiyon
  const getMoneyBoxTypeByTypeId = (moneyboxTypeId) => {
    switch (moneyboxTypeId) {
      case 0:
        return strings.member.member_detail.moneybox.self
      case 1:
        return strings.member.member_detail.moneybox.reference
      case 2:
        return strings.member.member_detail.moneybox.family
    }
  }

  //Eğer durum tipi değiştirilirse rate oranının sıfırlanması gerekiyor.
  //sıfırlanmazsa arka planda olmayan oranlar servise gidebilir
  useEffect(() => {
    setDiscountRate(firstDiscountRate);
    statusErrorRef.current.innerText = "";
    rateErrorRef.current.innerText = "";
  }, [status])

  useEffect(() => {
    statusErrorRef.current.innerText = "";
    rateErrorRef.current.innerText = "";
  }, [discountRate])


  const submitHandle = async (e) => {
    e.preventDefault();
    statusErrorRef.current.innerText = "";
    descriptionErrorRef.current.innerText = "";
    rateErrorRef.current.innerText = "";

    setRefreshInfo(refreshInfo => false);

    let valid = true;

    descriptionErrorRef.current.innerText = "";

    if (status.id === -1) {
      valid = false;
      statusErrorRef.current.innerText = strings.errors.status_null_error;
    }

    if (status.id === 1 && totalMoneyBoxRate === 0) {
      valid = false;
      rateErrorRef.current.innerText = strings.errors.zero_rate_error;
    }

    if (status.id !== -1 && discountRate.id === -1) {
      valid = false;
      rateErrorRef.current.innerText = strings.errors.rate_null_error;
    }

    if (description === "") {
      valid = false;
      descriptionErrorRef.current.innerText = strings.errors.description_null_error;
    }

    if (status.id === 0 && parseInt(discountRate.name) < 0 && parseInt(discountRate.name) > 100) {
      valid = false;
      rateErrorRef.current.innerText = strings.errors.rate_from_zero_to_hundred_error;
    }

    if (status.id === 1 && totalMoneyBoxRate < parseInt(discountRate.name)) {
      valid = false;
      rateErrorRef.current.innerText = strings.errors.rate_greater_than_total_rate_error;
    }

    if (valid) {
      const model = {
        memberId: memberId,
        moneyBoxActivityType: status.id,
        rate: parseInt(discountRate.name),
        description: description,
        isSendInformation: isSendInformation,
      };

      const result = await MoneyBoxOperations(model);

      if (result.status === 200) {
        setStatus(firstStatus);
        setDiscountRate(firstDiscountRate);
        discountRef.current.value = "";
        setIsSendInformation(false);
        setRefreshInfo(refreshInfo => true);
        getMoneyBoxOperationDetails(memberId);
        getTotalMoneyBoxRate(memberId);
        showTopAlert(
          strings.member.member_detail.moneybox.success_message,
          "success"
        );
      }

    }
  };

  useEffect(() => {
    if (memberId) {
      getMoneyBoxOperationDetails(memberId);
      getTotalMoneyBoxRate(memberId);
    }
  }, [])

  return (
    <>
      <PageTitle title={strings.member.member_detail.moneybox.title} />
      <div className="p-3 flex flex-col lg:flex-row gap-4">
        <ReferenceInfo memberId={memberId} refresh={refreshInfo} />
        <div className="lg:w-[65%]">
          <form
            onSubmit={submitHandle}
            className="max-w-xs lg:max-w-lg mx-auto"
          >
            <div className="w-full">
              <div className="flex flex-col px-[10px] pb-0">
                <label>
                  {strings.form_info.status}{" "}
                </label>
                <Dropdown
                  classnames="md:max-w-full"
                  data={statusList}
                  selectedData={status}
                  setSelectedData={setStatus}
                />
                <span
                  ref={statusErrorRef}
                  className="text-danger field-validation-valid px-[10px]"
                ></span>
              </div>
            </div>
            <div className="mt-5 w-full">
              <div className="flex flex-col px-[10px] pb-0">
                <label>
                  {strings.form_info.rate}
                </label>
                <Dropdown
                  classnames="md:max-w-full"
                  //Eğer member a ait indirim kodu yoksa ve indirim kullan seçilirse bu alan sidable yapılıyor
                  isDisabled={status.id === -1 || (status.id === 1 && totalMoneyBoxRate === 0) && true}
                  data={discountRateList}
                  selectedData={discountRate}
                  setSelectedData={setDiscountRate}
                />
                <span
                  ref={rateErrorRef}
                  className="text-danger field-validation-valid px-[10px]"
                ></span>
              </div>
            </div>
            <div className="mt-5 w-full">
              <div className="flex flex-col px-[10px] pb-0">
                <label htmlFor="description">
                  {strings.form_info.description}{" "}
                </label>
                <Input
                  ref={discountRef}
                  id="description"
                  type="text"
                  onChange={(e) => setDescription(e.target.value)}
                />
                <span
                  ref={descriptionErrorRef}
                  className="text-danger field-validation-valid px-[10px]"
                ></span>
              </div>
            </div>
            <div className="flex justify-end items-center mt-3 mr-3">
              <label className="flex justify-start items-start">
                <input
                  type="checkbox"
                  id="isActive1"
                  className="hidden"
                  onClick={() => setIsSendInformation(isSendInformation => !isSendInformation)}
                />
                <label
                  htmlFor="isActive1"
                  className={`${handleCheckbox(isSendInformation)} mr-2 w-5 h-5 rounded-[3px] cursor-pointer duration-500 flex items-center justify-center`}
                >
                  <span className={classNames('text-inherit text-[20px] material-symbols-outlined animate-fadeIn font-bold', { 'hidden' : !isSendInformation})}>
                    done
                  </span>
                </label>
                <p>{strings.member.member_detail.moneybox.inform_student}</p>
              </label>
            </div>
            <button
              type="submit"
              className="button search-button gap-3 text-white bg-blue border-blue mt-7 mx-auto px-10"
            >
              {strings.button.save_upper}
            </button>
          </form>
        </div>
      </div>
      {moneyBoxOperationList.length !== 0 && (
        <div className="overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
          <div className="inline-block min-w-full rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal text-header">
              <thead>
                <tr>
                  <th className="th">{strings.form_info.status}</th>
                  <th className="th">{strings.form_info.rate}</th>
                  <th className="th"><p className="min-w-max">{strings.form_info.moneybox_type}</p></th>
                  <th className="th">{strings.form_info.description}</th>
                  <th className="th ">{strings.table.date}</th>
                </tr>
              </thead>
              <tbody>
                {moneyBoxOperationList.length > 0 &&
                  moneyBoxOperationList.map((data, index) => (
                      <tr key={index} className="bg-white hover:bg-[#f0f0f0]">
                        <td className="td">
                          <p className="py-3 min-w-[83px]">{data.moneyBoxActivityType === 0 ? strings.member.member_detail.moneybox.earned_title : strings.member.member_detail.moneybox.used_title}</p>
                        </td>
                        <td className="td">
                          <p className="py-3">{data.rate}</p>
                        </td>
                        <td className="td">
                          <p className="py-3">{getMoneyBoxTypeByTypeId(data.moneyBoxType)}</p>
                        </td>
                        <td className="td">
                          <p className="py-3 min-w-[240px]">{data.description}</p>
                        </td>
                        <td className="td">
                          <p className="py-3 min-w-[122px]">{addTimeZoneToFullDate(data.createDate, parseInt(employee.TimeZone))}</p>
                        </td>
                      </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default Moneybox;