import React, { useEffect, useRef, useState } from 'react'
import {Dropdown, DropdownMultiSelect, Input, Loading, LoadingSpinner, showTopAlert} from '../../components';
import { useLocalization } from '../../hooks/Utils/useLocalization';
import { DistributeMembers, GetTotalMemberRecords } from '../../services/Employee/EmployeeService';
import { GetSameTeamEmployees } from '../../services/Employee/EmployeeService';
import { checkNumber } from '../../components/Utility';
import classNames from "classnames";
import {EmployeeStatusType} from "../../consts/Constants";

const DistributeStudentModal = (props) => {

  const strings = useLocalization();
  const { employeeId, employeeTypeId, sendRequestToEndpoint, closeButton } = props;

  const studentCountErrorRef = useRef();
  const employeesErrorRef = useRef();

  const [allStudentCount, setAllStudentCount] = useState(0);
  const [passiveStudentCount, setPassiveStudentCount] = useState(0);
  const [activeStudentCount, setActiveStudentCount] = useState(0);
  const [candidateCount, setCandidateCount] = useState(0);

  const candidateButtonRole = (
      employeeTypeId === EmployeeStatusType["Verimlilik Temsilcisi"] ||
      employeeTypeId === EmployeeStatusType["Verimlilik Takım Lideri"] ||
      employeeTypeId === EmployeeStatusType["Verimlilik Takım Lideri Yardımcısı"] ||
      employeeTypeId === EmployeeStatusType["Kurumsal Destek Temsilcisi"] ||
      employeeTypeId === EmployeeStatusType.Referansatör
  )

  const GeneralStatusTypesWithCount = [
    { id: 0, name: `Pasif Öğrenciler (${passiveStudentCount})` },
    { id: 1, name: `Aktif Öğrenciler (${activeStudentCount})` },
    { id: 2, name: `Adaylar (${candidateCount})` },
  ];

  const firstStatusType = { id: -1, name: allStudentCount === 0 ? strings.detail_search_titles.students : `${strings.detail_search_titles.students} (${allStudentCount})`};
  const tmpGeneralStatusType = candidateButtonRole
      ? GeneralStatusTypesWithCount
      : GeneralStatusTypesWithCount.filter(item => item.id !== 2);
  const statusTypeList = [firstStatusType, ...tmpGeneralStatusType];

  const [status, setStatus] = useState(firstStatusType);
  const [countData, setCountData] = useState([]);
  const [studentCount, setStudentCount] = useState(0);
  const [currentStudentCount, setCurrentStudentCount] = useState(0);
  const [employees, setEmployees] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);

  const [pageLoading, setPageLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const getMemberCount = async (employeeId) => {

    const result = await GetTotalMemberRecords(employeeId);

    if(result.status === 200){
      let resultContent = JSON.parse(result.content);
      setCountData(candidateCount => resultContent);

      let tmpAllMemberCount = resultContent.find(result => result.status === -1);
      setAllStudentCount(allStudentCount => tmpAllMemberCount.memberCount);

      let tmpPassiveMemberCount = resultContent.find(result => result.status === 0);
      setPassiveStudentCount(passiveStudentCount => tmpPassiveMemberCount.memberCount);

      let tmpActiveMemberCount = resultContent.find(result => result.status === 1);
      setActiveStudentCount(activeStudentCount => tmpActiveMemberCount.memberCount);

      if(candidateButtonRole){
        let tmpCandidateCount = resultContent.find(result => result.status === 2);
        setCandidateCount(candidateCount => tmpCandidateCount.memberCount);
      }
    }

    setPageLoading(pageLoading => false);
  }

  const getSameTeamEmployees = async (employeeId) => {

    const result = await GetSameTeamEmployees(employeeId);

    let arrayList = [];

    if(result.status === 200){
      let resultContent = JSON.parse(result.content);

      resultContent?.map((item) => {
        arrayList.push({id: item.value, name: item.text});
      })
    }
    
    setEmployeeList(employeeList => arrayList);
  }

  const handleChange = (e) => {
    
    if(parseInt(e.target.value) > currentStudentCount){
      setStudentCount(studentCount => currentStudentCount);
    } else {
      setStudentCount(studentCount => e.target.value);
    }
  }

  const distributeStudents = async () => {

    let valid = true;

    studentCountErrorRef.current.innerText = '';
    employeesErrorRef.current.innerText = '';

    if(studentCount === 0){
      valid = false;
      studentCountErrorRef.current.innerText = strings.employee.employee_list.student_count_error;
    }
    if(employees.length === 0){
      valid = false;
      employeesErrorRef.current.innerText = strings.employee.employee_list.employees_list_error;
    }

    if(valid){
      setLoading(true);
      let model = {
        employeesIds: employees,
        distributedEmployeeId: employeeId,
        status: status.id,
        count: parseInt(studentCount)
      };

      const result = await DistributeMembers(model);
      if(result.status === 200){
        showTopAlert(strings.employee.employee_list.member_distribute_success, "success");
        sendRequestToEndpoint();
        setTimeout(() => {
          closeButton();
          window.location.reload();
          setLoading(loading => false);
        }, 2000);
      } else {
        setLoading(loading => false);
        showTopAlert(strings.errors.an_error_occurred, "error");
      }
    }
  }

  useEffect(() => {
      if(countData.length > 0){
        const tmpCount = countData.find(result => result.status === status.id);

        setCurrentStudentCount(currentStudentCount => tmpCount.memberCount);
        setStudentCount(studentCount => tmpCount.memberCount);
      }
  }, [status.id, countData])

  useEffect(() => {
    if(employeeId){
      getSameTeamEmployees(employeeId);
      getMemberCount(employeeId);
    }
  }, [])
  
  return (
    <div className="animate-fadeIn w-full text-[13px] text-start ">
      <div className="flex justify-center mb-1">
        <p className="font-bold text-[15px]">{strings.employee.employee_list.distribute_students}</p>
      </div>

      {pageLoading && <Loading />}

      {!pageLoading && (
          <>
            <div className="flex flex-col text-start gap-4">
              <div className="flex flex-col w-full px-[10px] pb-0">
                <label className="text-[13px] font-bold mb-2">
                  {strings.employee.employee_list.select_student}
                </label>
                <Dropdown
                    title={strings.detail_search_titles.students}
                    classnames="md:max-w-full"
                    data={statusTypeList}
                    selectedData={status}
                    setSelectedData={setStatus}
                    isDisabled={loading}
                />
              </div>
              <div className="flex flex-col w-full px-[10px] pb-0">
                <label className="text-[13px] font-bold mb-2 cursor-pointer" htmlFor="student_count">
                  {strings.employee.employee_list.distributed_student_count}
                </label>
                <Input
                    id="student_count"
                    type="text"
                    value={studentCount}
                    onKeyDown={(e) => !checkNumber(e) && e.preventDefault()}
                    onChange={(e) => handleChange(e)}
                    isDisabled={loading}
                />
                <span ref={studentCountErrorRef} id="tax_number_error"
                      className="text-danger field-validation-valid px-[10px]"></span>
              </div>
              <div className="flex flex-col w-full px-[10px] pb-0">
                <label className="text-[13px] font-bold mb-2">
                  {strings.employee.employee_list.select_employee} <span className="text-red">*</span>
                </label>
                <DropdownMultiSelect
                    title={strings.detail_search_titles.employees}
                    data={employeeList}
                    selectedData={employees}
                    setSelectedData={setEmployees}
                    randomId="employeeListMS"
                    classnames="md:!max-w-full"
                    inputPlaceholder={strings.detail_search_titles.search_employee}
                    isDisabled={loading}
                />
                <span ref={employeesErrorRef} id="tax_number_error"
                      className="text-danger field-validation-valid px-[10px]"></span>
              </div>
            </div>
            <div className="flex gap-3 justify-center">
              <button
                  type='button'
                  className={classNames("button search-button gap-3 text-white bg-blue border-blue mt-7 px-10 w-[130px]", {
                    "opacity-70 hover:opacity-70 cursor-not-allowed": loading
                  })}
                  onClick={distributeStudents}
                  disabled={loading}
              >
                {loading && <LoadingSpinner/>}
                {!loading && strings.button.save_upper}
              </button>
              <button
                  type='button'
                  className={classNames("button search-button gap-3 text-white bg-red border-red focus:border-red mt-7 px-10", {
                    "opacity-70 cursor-not-allowed": loading
                  })}
                  onClick={closeButton}
                  disabled={loading}
              >
                {strings.button.cancel_upper}
              </button>
            </div>
          </>
      )}
    </div>
  )
}

export default DistributeStudentModal;