import {useLocalization} from "../../../../../hooks/Utils/useLocalization";
import React, {useEffect, useRef, useState} from "react";
import classNames from "classnames";
import {Dropdown, Input} from "../../../../../components";
import {ApplicationList} from "../../../../../consts/Constants";
import { v4 as uuid4 } from 'uuid';
import {handleCheckbox} from "../../../../../components/Utility";

const AddApplicationRow = ({applications, setApplications}) => {

    const strings = useLocalization();

    // Uygulama eklenirken hata mesajlarının gösterilmesi için oluşturulan ref'ler.
    const applicationErrorRef = useRef();
    const webPathErrorRef = useRef();
    const mobilePathErrorRef = useRef();

    const firstApplication = { id: -1, name: strings.detail_search_titles.choose_application };
    const [application, setApplication] = useState(firstApplication);

    const [webPath, setWebPath] = useState("");
    const [mobilePath, setMobilePath] = useState("");
    const [paidMember, setPaidMember] = useState(false);
    const [nonPaidMember, setNonPaidMember] = useState(false);
    const [demoMember, setDemoMember] = useState(false);

    const [isClickedAddButton, setIsClickedAddButton] = useState(false);

    const validate = () =>  {
        let valid = true;

        applicationErrorRef.current.innerText = '';
        webPathErrorRef.current.innerText = '';
        mobilePathErrorRef.current.innerText = '';

        if(application.id === -1) {
            valid = false;
            applicationErrorRef.current.innerText = strings.errors.application_null_error;
        }
        if(webPath.length === 0) {
            valid = false;
            webPathErrorRef.current.innerText = strings.errors.web_path_null_error;
        }
        if(mobilePath.length === 0) {
            valid = false;
            mobilePathErrorRef.current.innerText = strings.errors.mobile_path_null_error;
        }

        return valid;
    }

    const addApplication = () => {
            // Validate() fonksiyonunun çalışacağı zamanı ayarlamak için ekleme butonuna basıldığı state'te tutuluyor.
            setIsClickedAddButton(isClickedAddButton => true);

            if(validate()) {
                // Yeni bir application objesi oluşturuluyor
                const model = {
                    id: uuid4(),
                    applicationId: application.id,
                    webPath: webPath,
                    mobilePath: mobilePath,
                    paidMember: paidMember,
                    nonPaidMember: nonPaidMember,
                    demoMember: demoMember
                };

                // Uygulamayı applications dizisine ekleyerek state'i güncelleniyor
                setApplications([...applications, model]);

                // Stateler işlem bittikten sonra sıfırlanıyor
                setApplication(application => firstApplication);
                setMobilePath(mobilePath => "");
                setWebPath(webPath => "");
                setPaidMember(paidMember => false);
                setNonPaidMember(nonPaidMember => false);
                setIsClickedAddButton(isClickedAddButton => false);
                setDemoMember(demoMember => false);
            }
    }

    useEffect(() => {
        isClickedAddButton && validate();
    }, [application, webPath, mobilePath]);

    return (
        <>
            <div
                className="flex flex-col xl:flex-row items-center justify-start xl:items-start gap-3 px-5 max-xs:px-2"
            >
                <div className="flex flex-col pb-0 mt-5 w-full">
                    <div className="flex flex-col pb-0 ">
                        <label>
                            {strings.member.member_detail.others.application}
                        </label>
                        <Dropdown
                            data={[firstApplication, ...ApplicationList]}
                            selectedData={application}
                            setSelectedData={setApplication}
                            classnames="md:max-w-full"
                        />
                        <span ref={applicationErrorRef} className="text-danger field-validation-valid"></span>
                    </div>
                </div>
                <div className="flex flex-col pb-0 mt-5 w-full">
                    <label htmlFor="web-path">
                        {strings.form_info.web_path}
                    </label>
                    <div className="flex flex-col pb-0 ">
                        <Input
                            id="web-path"
                            type="text"
                            classnames="md:max-w-full"
                            value={webPath}
                            onChange={(e) => setWebPath(e.target.value)}
                        />
                        <span ref={webPathErrorRef} className="text-danger field-validation-valid"></span>
                    </div>
                </div>
                <div className="flex flex-col pb-0 mt-5 w-full">
                    <label htmlFor="mobile-path">
                        {strings.form_info.mobile_path}
                    </label>
                    <div className="flex flex-col pb-0 ">
                        <Input
                            id="mobile-path"
                            type="text"
                            classnames="md:max-w-full"
                            value={mobilePath}
                            onChange={(e) => setMobilePath(e.target.value)}
                        />
                        <span ref={mobilePathErrorRef} className="text-danger field-validation-valid "></span>
                    </div>
                </div>
                <div className="flex flex-col pb-0 mt-5 w-full">
                    <label className="min-w-max">{strings.form_info.paid_member}</label>
                    <div className="flex justify-start items-center w-full p-2">
                        <input
                            type="checkbox"
                            id="paid-member"
                            className="hidden"
                            onClick={() => setPaidMember(isCorporate => !paidMember)}
                        />
                        <label
                            htmlFor="paid-member"
                            className={`${handleCheckbox(paidMember)} mr-2 w-6 h-6 rounded-[3px] cursor-pointer duration-500 flex items-center justify-center`}
                        >
                            <span
                                className={classNames('text-inherit text-[20px] material-symbols-outlined animate-fadeIn font-bold', {'hidden': !paidMember})}>
                                done
                            </span>
                        </label>
                    </div>
                </div>
                <div className="flex flex-col pb-0 mt-5 w-full">
                    <label className="min-w-max">{strings.form_info.non_paid_member}</label>
                    <div className="flex justify-start items-center w-full p-2">
                        <input
                            type="checkbox"
                            id="non-paid-member"
                            className="hidden"
                            onClick={() => setNonPaidMember(isCorporate => !nonPaidMember)}
                        />
                        <label
                            htmlFor="non-paid-member"
                            className={`${handleCheckbox(nonPaidMember)} mr-2 w-6 h-6 rounded-[3px] cursor-pointer duration-500 flex items-center justify-center`}
                        >
                            <span
                                className={classNames('text-inherit text-[20px] material-symbols-outlined animate-fadeIn font-bold', {'hidden': !nonPaidMember})}>
                                done
                            </span>
                        </label>
                    </div>
                </div>
                <div className="flex flex-col pb-0 mt-5 w-full">
                    <label className="min-w-max">{strings.form_info.demo_member}</label>
                    <div className="flex justify-start items-center w-full p-2">
                        <input
                            type="checkbox"
                            id="demo-member"
                            className="hidden"
                            onClick={() => setDemoMember(demoMember => !demoMember)}
                        />
                        <label
                            htmlFor="demo-member"
                            className={`${handleCheckbox(demoMember)} mr-2 w-6 h-6 rounded-[3px] cursor-pointer duration-500 flex items-center justify-center`}
                        >
                            <span
                                className={classNames('text-inherit text-[20px] material-symbols-outlined animate-fadeIn font-bold', {'hidden': !demoMember})}>
                                done
                            </span>
                        </label>
                    </div>
                </div>
                <div className="flex gap-1">
                    <button
                        type="button"
                        className="flex-shrink-0 xl:mb-2 mt-2 group inline-flex items-center justify-center bg-transparent border border-blue text-blue w-8 h-8 rounded-[3px] relative xl:mt-[52px]"
                        onClick={addApplication}
                    >
                        <span className="material-symbols-outlined text-[18px]">add</span>
                        <div className="tooltip-top">
                            <p>{strings.button.add}</p>
                        </div>
                    </button>
                </div>
            </div>
        </>
    )
}

export default AddApplicationRow;