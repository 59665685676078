import {useLocalization} from "../../../../hooks/Utils/useLocalization";
import {useState} from "react";
import CandidatePoolTableRow from "../CandidatePool/CandidatePoolTableRow";
import {Modal, NoRecordsFound} from "../../../../components";
import CandidatePoolScoreModal from "../CandidatePool/CandidatePoolScoreModal";
import CandidatePoolLogModal from "../CandidatePool/CandidatePoolLogModal";
import {NewCandidateTableRow, ReasonToLearnEnglishModal} from "../index";

const NewCandidateTable = ({ data }) => {

    const strings = useLocalization();
    const [modal, setModal] = useState(false);
    const [modalContent, setModalContent] = useState({});

    return (
        <div className="mx-auto px-4 my-4">

            <div
                className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
                <div className="inline-block min-w-full rounded-lg overflow-hidden">
                    <table className="min-w-full leading-normal text-header">
                        <thead>
                            <tr>
                                <th className="th">
                                    <p className="ml-6">{strings.table.brand}</p>
                                </th>
                                <th className="th">
                                    {strings.table.candidate_informations}
                                </th>
                                <th className="th">
                                    {strings.table.application_informations}
                                </th>
                                <th className="th">
                                    {strings.table.operation_informations}
                                </th>
                                <th className="th">
                                    {strings.table.score}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.length > 0 ?
                                (
                                    data.map((member, index) => (
                                        <NewCandidateTableRow
                                            key={index}
                                            member={member}
                                            setModal={setModal}
                                            setModalContent={setModalContent}
                                        />
                                    ))
                                )
                                :
                                (
                                    <tr className="bg-white hover:bg-[#f0f0f0]">
                                        <td colSpan="5">
                                            <NoRecordsFound />
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                    {(Object.keys(modalContent).length !== 0) && (
                        <Modal modal={modal} setModal={setModal} classes={
                            {
                                modal: `${modalContent.element === "reasonToLearnEnglish" ? "h-[420px] max-w-[350px]" : "h-[90vh] max-w-[1140px]"}`,
                                content: `${modalContent.element === "reasonToLearnEnglish" ? "" : "overflow-y-scroll !mr-0"}`,
                            }}
                        >
                            {modalContent.element === "score" && <CandidatePoolScoreModal memberId={modalContent.memberId} classes="!h-[85vh] pr-3"/>}
                            {modalContent.element === "log" && <CandidatePoolLogModal memberId={modalContent.memberId} classes="!h-[85vh] pr-3"/>}
                            {modalContent.element === "reasonToLearnEnglish" && <ReasonToLearnEnglishModal memberId={modalContent.memberId} />}
                        </Modal>
                    )}
                </div>
            </div>
        </div>
    )
}

export default NewCandidateTable;