import React, {useEffect, useState} from "react";
import {
  DropdownSearch,
  Loading,
  showCenteredAlertTwoButtonsWithParameters,
  showTopAlert,
  TimeRangePicker
} from "../../../../../../../components";
import {NormalButton, SquareButtonWithoutLink} from "../../../../../../../components/Buttons";
import {GenderType, ServiceErrorMessages, SourceType} from "../../../../../../../consts/Constants";
import {addTimeZone, adjustTimeWithTimeZone, removeSpaces} from "../../../../../../../components/Utility";
import useAuth from "../../../../../../../hooks/Log/useAuth";
import {useLocalization} from "../../../../../../../hooks/Utils/useLocalization";
import usePrimeData from "../../../../../../../hooks/Utils/usePrimeData";
import {
  GetLessonDurationByTermId,
  TeacherFreeTimeByRange,
  TeacherLessonTimeChange,
  TeacherLessonTimeChangeList
} from "../../../../../../../services/Term/TermService";

const TeacherTimeChangeModal = (props) => {
  
  const strings = useLocalization();
  const { termId, closeButton, sendRequestToLessonTimeAndTeacherEndpoint, lessonInfo } = props;
  const { employee } = useAuth();
  const primeData = usePrimeData();

  const firstTeacher = { id: -1, name: strings.member.member_detail.terms.modal.select_teacher };
  const teacherList = [firstTeacher, ...primeData.teacherListWithShifts];
  const rangeList = primeData.workingTimeList;
  const title = strings.member.member_detail.terms.modal.current_lesson_information + ": " + lessonInfo;

  const firstTimeRange = { id: -1, name: strings.detail_search_titles.hours };
  const [teacher, setTeacher] = useState(firstTeacher);
  const [startHour, setStartHour] = useState(firstTimeRange);
  const [endHour, setEndHour] = useState(firstTimeRange);
  const [changeData, setChangeData] = useState({});
  const [freeTimeList, setFreeTimeList] = useState([]);
  const [search, setSearch] = useState(false);
  const [isSelectedAllRange, setIsSelectedAllRange] = useState(true)

  const firstTeacherSandyTimeRange = { id: -1, name: strings.form_info.choose_time };
  const [selectedTeacherSandyTime, setSelectedTeacherSandyTime] = useState(firstTeacherSandyTimeRange);
  const [teacherSandyTimeOptions, setTeacherSandyTimeOptions] = useState([]);
  const [lessonDuration, setLessonDuration] = useState(0);

  const [isRequestSend, setIsRequestSend] = useState(false);

  //Eğitmen/Saat değişikliği için üye uygun mu değil mi? Kontrol yapılıyor
  const teacherLessonTimeChangeList = async (termId, sourceType) => {
    const result = await TeacherLessonTimeChangeList(termId, sourceType);

    if (result.status === 200) {
      const data = JSON.parse(result.content);
      setChangeData(changeData => data);
    } else {
      //Eğitmen/Saat değişikliği başarısız ise
      showTopAlert(strings.errors.an_error_occurred, "error");
  }
  }

  //Eğitmen saat değişikliği için uygun liste talep ediliyort
  const getTeacherFreeTimeByRange = async () => {

    let beginTime = rangeList[startHour.id].name;
    let endTime = rangeList[endHour.id].name;

    let model = {
      termId: termId,
      beginTime: beginTime + ":00",
      endTime: endTime + ":00",
      teacherId: teacher.id !== -1 ? teacher.id : 0
    }

    const result = await TeacherFreeTimeByRange(model);

    if (result.status === 200) {
      const data = JSON.parse(result.content);
      setFreeTimeList(freeTimeList => data.teacherFreeLessonTimeItems);
      setSearch(search => true);
    }
  }

  const changeTeacherTime = (teacherId, teacherName, lessonTime, termId) => {

    let _lessonTime = lessonTime.length === 5 ? addTimeZone(lessonTime, employee.TimeZone) : addTimeZone(lessonTime.substring(0, 5), employee.TimeZone)
    let modalMessage = strings.member.member_detail.terms.modal.teacher_time_change_question;
    modalMessage = modalMessage.replace(/#teacher#/g, teacherName);
    modalMessage = modalMessage.replace(/#time#/g, _lessonTime);

    showCenteredAlertTwoButtonsWithParameters(
      strings.alert.centered_alert_two_buttons.title_confirm,
      modalMessage,
      strings.button.confirm,
      strings.button.cancel,
      sendToEndPoint,
      [teacherId, lessonTime, termId]
    )
  }

  const sendToEndPoint = async (teacherId, lessonTime, termId) => {
    setIsRequestSend(isRequestSend => true);

    let model = {
      teacherId: teacherId,
      lessonTime: lessonTime,
      termId: termId,
      createUserId: employee.EmployeeId
    }

    const result = await TeacherLessonTimeChange(model);

    if (result.status === 200) {
      showTopAlert(strings.member.member_detail.terms.modal.teacher_time_change_success, "success");
      sendRequestToLessonTimeAndTeacherEndpoint();
      setTimeout(() => {closeButton(); window.location.reload();}, 3000);
    } else {

      if(result.content === ServiceErrorMessages.ESD_NOT_IN_TIME_INTERVAL) {
        showTopAlert(strings.errors.esd_time_frame_error, "error");
      } else if(result.content === ServiceErrorMessages.ESD_TWO_HOURS_ERROR) {
        showTopAlert(strings.errors.esd_two_hour_error, "error");
      } else {
        showTopAlert(strings.errors.an_error_occurred, "error");
      }

      setIsRequestSend(isRequestSend => false);
    }
  }

  const createTeacherSandyTimeOptions = (lessonTime) => {
    // İki saat diliminde kullanılacak saat dilimlerini tanımlar
    const timeRanges = [
      { startTime: "06:00", endTime: "11:00" },
      { startTime: "12:00", endTime: "17:00" },
      { startTime: "18:00", endTime: "00:00" },
    ];

    // Sonuçları saklamak için bir dizi oluşturur
    const lessonTimes = [];

    // Her saat dilimi için saatleri hesapla ve diziye ekler
    timeRanges.forEach((timeRange) => {
      const startHour = parseInt(timeRange.startTime.split(':')[0]);
      const startMinute = parseInt(timeRange.startTime.split(':')[1]);
      const endHour = parseInt(timeRange.endTime.split(':')[0]);
      const endMinute = parseInt(timeRange.endTime.split(':')[1]);

      const startMinutes = startHour * 60 + startMinute;
      let endMinutes = endHour * 60 + endMinute;

      // Gün dönümü kontrolü yapar
      if (endMinutes < startMinutes) {
        // 24 saat ekler
        endMinutes += 24 * 60;
      }

      for (let i = startMinutes; i < endMinutes; i += 10) {
        if (i + lessonTime <= endMinutes) {
          const hour = Math.floor(i / 60);
          const minute = i % 60;
          const formattedHour = hour.toString().padStart(2, '0');
          const formattedMinute = minute.toString().padStart(2, '0');
          const formattedTime = `${formattedHour}:${formattedMinute}`;
          lessonTimes.push({ id: formattedTime, name: formattedTime });
        }
      }
    });

    return lessonTimes;
  }

  const getLessonDurationByTermId = async (termId) => {
    const result = await GetLessonDurationByTermId(termId);

    if(result.status === 200) {
      setLessonDuration(lessonDuration => +result.content);
    }
  }

  //Bir eğitmen seçildiğinde başlangıç ve bitiş saatlerini default olarak 06:00 ile 00:00 olarak seçiliyor.
  useEffect(() => {
    if(teacher.id === -1) {
      setStartHour(startHour => firstTimeRange)
    } else {
      setStartHour({ id: 0, name: "06:00" })
    }
    setIsSelectedAllRange(true)
  },[teacher])

  useEffect(() => {
    if (termId) {
      teacherLessonTimeChangeList(termId, SourceType.Crm);
      getLessonDurationByTermId(termId);
    }
  }, [termId])

  useEffect(() => {
    if(lessonDuration !== 0) {
      let tmpTeacherSandyTimeOptions = createTeacherSandyTimeOptions(lessonDuration);
      setTeacherSandyTimeOptions(teacherSandyTimeOptions => [firstTeacherSandyTimeRange, ...tmpTeacherSandyTimeOptions]);
    }
  }, [lessonDuration]);

  useEffect(() => {
    let studentLessonTime = removeSpaces(lessonInfo.split("/")[1]);
    let selectedObject = teacherSandyTimeOptions.find(option => option.name === studentLessonTime);

    setSelectedTeacherSandyTime(selectedTeacherSandyTime => selectedObject ? selectedObject : selectedTeacherSandyTime);
  }, [lessonInfo, teacherSandyTimeOptions]);

  return (
    <>
      {Object.keys(changeData).length > 0 ? (
        changeData.isAvailableToChange ? (
          <>
            <p className="font-bold my-2 text-[14px] min-h-[35px] md:leading-[35px] text-base-text bg-card px-5 rounded-md">{title} / {lessonDuration} {strings.table.minute}</p>
            <form className="flex flex-col lg:flex-row text-start gap-4 mt-3">
              <div className="flex flex-col w-full px-[10px] pb-0">
                <label>{strings.member.member_detail.terms.modal.select_teacher}</label>
                <DropdownSearch
                  classnames="md:max-w-full"
                  data={teacherList}
                  selectedData={teacher}
                  setSelectedData={setTeacher}
                  inputPlaceholder={strings.detail_search_titles.search_teacher}
                />
              </div>
              <div className="flex flex-col w-full px-[10px] pb-0">
                <label>
                  {strings.member.member_detail.terms.modal.select_start_end_hour}
                </label>
                <TimeRangePicker
                  rangeList={primeData.workingTimeList}
                  startHour={startHour}
                  setStartHour={setStartHour}
                  endHour={endHour}
                  setEndHour={setEndHour}
                  isSelectedAllRange={isSelectedAllRange}
                  setIsSelectedAllRange={setIsSelectedAllRange}
                />
              </div>
              <div className="flex justify-center w-full lg:w-1/3">
                <NormalButton
                  classnames="bg-blue border-blue"
                  isDisabled={(startHour.id === - 1 || endHour.id === -1)}
                  buttonText={strings.button.list_upper}
                  action={getTeacherFreeTimeByRange}
                  tooltip={(startHour.id === - 1 || endHour.id === -1) ? strings.member.member_detail.terms.modal.choose_start_end_hour : ''}
                />
              </div>
            </form>
            {!search && (
              <p className="my-4 font-bold mt-7 flex justify-center">
                {strings.member.member_detail.terms.modal.teacher_time_change_message}
              </p>
            )}
            <div className="pb-4 overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto mt-3">
              <div className="min-w-full rounded-lg">
                  <div className="min-w-full rounded-lg">
                    {search && (
                      <table className="min-w-full leading-normal text-header">
                        <thead className="table table-fixed w-full">
                          <tr className="">
                            <th className="th max-sm:text-center">{strings.form_info.gender}</th>
                            <th className="th max-sm:text-center"><p className="min-w-max">{strings.table.teacher_name}</p></th>
                            <th className="th max-sm:text-center">{strings.table.hour}</th>
                            <th className="th max-sm:text-center">{strings.member.member_detail.header.operations}</th>
                          </tr>
                        </thead>
                          <tbody className="block sm:flex sm:flex-col overflow-y-scroll h-[22vh] md:h-[40vh] lg:h-[55vh] w-full">
                              <tr className="hover:bg-blue/20 bg-blue/10 sm:table table-fixed w-full flex justify-between">
                                <td className="td">
                                  <div
                                      className="badge-color px-5 my-1 bg-dark-gray text-white min-w-max !max-w-[75px] text-center h-[30.5px]"
                                  >
                                    <div className="mt-2">
                                      <hr className="border-base-text" />
                                    </div>
                                  </div>
                                </td>
                                <td className="td">
                                  <p className="py-3 min-w-max">Teacher Sandy</p>
                                </td>
                                <td className="td">
                                  <DropdownSearch
                                      data={teacherSandyTimeOptions || []}
                                      selectedData={selectedTeacherSandyTime}
                                      setSelectedData={setSelectedTeacherSandyTime}
                                      inputPlaceholder={strings.detail_search_titles.search_time}
                                      classnames="md:max-w-[120px]"
                                      heightClassnames="max-h-[150px]"
                                  />
                                </td>
                                <td className="td">
                                  <div className="flex items-center gap-3">
                                    <SquareButtonWithoutLink
                                        classnames="bg-blue"
                                        iconName="check_circle"
                                        action={() => changeTeacherTime(+process.env.REACT_APP_TEACHER_SANDY_ID , "Teacher Sandy", adjustTimeWithTimeZone(selectedTeacherSandyTime.name, employee.TimeZone), termId)}
                                        isDisabled={selectedTeacherSandyTime.id === -1 || isRequestSend}
                                    />
                                    <div className="group relative">
                                      <span className="material-symbols-outlined cursor-pointer">info</span>
                                      <div className="tooltip -bottom-[88px] teacher_sandy_text">
                                        {strings.member.member_detail.terms.modal.teacher_sandy_text}
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              {freeTimeList.length > 0 && freeTimeList.map((time, index) => (
                                <tr key={index} className="bg-white hover:bg-[#f0f0f0] sm:table table-fixed w-full flex justify-between">
                                  <td className="td">
                                    <div
                                      className={`badge-color my-1 px-5 ${time.genderType === GenderType.Male ? "bg-blue" : time.genderType === GenderType.Female ? "bg-red" : "bg-green"} text-white min-w-max !max-w-[75px] text-center`}
                                    >
                                      <p>{time.genderType === GenderType.Male ? strings.form_info.male : time.genderType === GenderType.Female ? strings.form_info.female : strings.form_info.undefined}</p>
                                    </div>
                                  </td>
                                  <td className="td">
                                    <p className="py-3 min-w-max">{time.teacherName}</p>
                                  </td>
                                  <td className="td">
                                    <p className="py-3">{time.lessonTime.length === 5 ? addTimeZone(time.lessonTime, employee.TimeZone) : addTimeZone(time.lessonTime.substring(0, 5), employee.TimeZone)}</p>
                                  </td>
                                  <td className="td">
                                    <SquareButtonWithoutLink
                                      classnames="bg-blue"
                                      iconName="check_circle"
                                      action={() => changeTeacherTime(time.teacherId, time.teacherName, time.lessonTime, termId)}
                                      isDisabled={isRequestSend}
                                    />
                                  </td>
                                </tr>
                              ))}
                        </tbody>
                      </table>
                    )}
                  </div>
              </div>
            </div>
          </>
        ) : (
          <div className="flex flex-col items-center gap-8">
            <h1 className="text-[18px] font-bold">{strings.member.member_detail.terms.modal.no_right_title}</h1>
            <p className="text-[14px] font-bold">{strings.member.member_detail.terms.modal.check_title}</p>
            {changeData.teacherLessonTimeChangeRightCount > 0 ? (
              <>
                <p><span className="font-bold">1.</span> {strings.member.member_detail.terms.modal.not_avaliable_time_range}</p>
              </>
            ) : (
              <>
                <p><span className="font-bold">1.</span> {strings.member.member_detail.terms.modal.not_right_for_change}</p>
              </>
            )}
            {changeData.activeFreezeHistory && (<p><span className="font-bold">2.</span> {strings.member.member_detail.terms.modal.lesson_freeze}</p>)}
          </div>
        )
      ) : (
        <div className="mt-[40px]">
          <Loading />
        </div>
      )}
    </>
  );
};

export default TeacherTimeChangeModal;
