import { useEffect, useState } from "react";
import { Loading, PageTitle, SearchBar, SearchCount, showTopAlert } from "../../components";
import NotAuthorized from "../../components/NotAuthorized";
import Pagination from "../../components/Pagination";
import {
    fullDateToShortDate,
    getddMMyyyyDate, stringToDate
} from "../../components/Utility";
import useAuth from "../../hooks/Log/useAuth";
import { useLocalization } from "../../hooks/Utils/useLocalization";
import { GetNewMemberForm } from "../../services/Member/MemberCandidateService";
import { NewCandidateDetailSearch, NewCandidateTable } from "./components";

const NewCandidate = () => {

    const strings = useLocalization();
    const limit = parseInt(process.env.REACT_APP_STUDENT_TABLE_ROW_LIMIT);
    const { employee, employeeTypeId, checkPermission, getPermission } = useAuth();
    const roles = getPermission();
    const permission = checkPermission(roles, employeeTypeId);

    const [start, setStart] = useState(0);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecord, setTotalRecord] = useState(0);
    const [data, setData] = useState([]);

    const [detailSearch, setDetailSearch] = useState({
        beginDate: getddMMyyyyDate(new Date()),
        endDate: getddMMyyyyDate(new Date()),
        brandId: parseInt(employee.BrandId),
        scoreFilter: -1,
        formStatus: -1,
        searchText: ""
    });

    const getNewMemberForm = async () => {
        setLoading(loading => true);

        const model = {
            start: start,
            length: limit,
            beginDate: detailSearch.beginDate ? fullDateToShortDate(stringToDate(detailSearch.beginDate)) : null,
            endDate: detailSearch.endDate ? fullDateToShortDate(stringToDate(detailSearch.endDate, 23, 59, 59)) : null,
            brandId: detailSearch.brandId,
            scoreFilter: detailSearch.scoreFilter,
            formStatus: detailSearch.formStatus,
            searchText: detailSearch.searchText
        }

        const result = await GetNewMemberForm(model);

        if (result.status === 200) {
            let resultContent = JSON.parse(result.content);

            setData(data => resultContent.data);
            setTotalRecord(totalRecord => resultContent.recordsTotal);
        } else {
            showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
        }
        setLoading(loading => false);
    }

    //Arama verileri değiştiğinde değerleri sıfırlıyoruz
    const resetValue = () => {
        setStart(start => 0);
        setCurrentPage(currentPage => 1);
        setLoading(loading => true);
    }

    //select değişiklikleri bu alandan yönetiliyor
    const changeDetail = (values) => {
        setDetailSearch({ ...detailSearch, ...values });
        resetValue();
    }

    //input değişiklikleri bu alandan yönetiliyor
    const changeValue = (value) => {
        setDetailSearch({ ...detailSearch, searchText: value });
        resetValue();
    }

    //sayfa değiştikçe bilgileri yeniden çağırıyoruz
    useEffect(() => {
        if (totalRecord !== 0) {
            getNewMemberForm();
        }
    }, [start])

    useEffect(() => {

        let abortController = new AbortController();

        permission && getNewMemberForm();

        return () => {
            abortController.abort();
        }
    }, [detailSearch, permission])

    return (
        <>
            {permission &&
                <>
                    <NewCandidateDetailSearch
                        changeDetail={changeDetail}
                        loading={loading}
                    />
                    <SearchBar
                        changeValue={changeValue}
                        placeHolder={strings.search_bar.search_candidate}
                        handleRefresh={() => getNewMemberForm()}
                        isDisabled={loading}
                    />
                    {!loading && <SearchCount count={totalRecord} />}
                </>
            }

            <PageTitle title={strings.sidebar.member.new_candidates} />

            {permission &&
                <>
                    {loading && <Loading />}

                    {!loading &&
                        <>
                            <NewCandidateTable data={data} />
                            <Pagination
                                totalCount={totalRecord}
                                limit={limit}
                                start={start}
                                setStart={setStart}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                setLoading={setLoading}
                                loadScreen={true}
                            />
                        </>
                    }
                </>
            }

            {!permission && <NotAuthorized />}
        </>
    )
}

export default NewCandidate;