import React, { forwardRef } from 'react';
import { closePopup } from './Utility';

const Popup = forwardRef((props, ref) => {

    const { children, showCancelButton } = props;

    return (
        <div className="relative">
            <div
                ref={ref}
                className="transition-transform origin-top-right duration-300 absolute top-3 right-4 bg-white w-[550px] h-[225px] shadow-lg scale-0 z-50 p-2 rounded-[10px] "
            >
                {children}
                {showCancelButton &&
                    <button
                        type='button'
                        onClick={() => {
                            closePopup(ref);
                        }}
                        className="popup-close-button"><span className=" opacity-80 hover:opacity-100">X</span>
                    </button>
                }
            </div>
        </div>
    );
});

export default Popup;