import {LessonTime} from "../../../../components";
import React from "react";

const AbsentList = (props) => {

    const { data } = props;

    const formatDate = (date) => {
        const year = date.substring(0, 4);
        const month = date.substring(4, 6);
        const day = date.substring(6, 8);

        const formattedDate = `${day}.${month}.${year.slice(-2)}`;
        return formattedDate;
    }

    return(
        <>
            <div className="hover:overflow-x-auto min-w-full overflow-hidden">
                <div className="flex relative p-2">
                    <div className="flex mx-2 ml-auto">
                        <div className="flex w-max gap-x-2">
                            {data.map((item, index) => (
                                <LessonTime
                                    key={index}
                                    iconName="close"
                                    iconClassnames="text-red"
                                    date={formatDate(item)}
                                />
                            ))}
                        </div>
                    </div>
                    <div className="absolute top-0 right-0 h-full w-4 bg-gray-300 opacity-0 group-hover:opacity-100 transition-opacity"></div>
                </div>
            </div>
        </>
    )
}

export default AbsentList;