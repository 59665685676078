import {useLocalization} from "../../../../../hooks/Utils/useLocalization";
import React, {useEffect, useRef, useState} from "react";
import {dateToStringWithTime, getDateWithDayDifference, getddMMyyyyDate} from "../../../../../components/Utility";
import {DateRanges} from "../../../../../consts/Constants";
import {DetailSearchLayout, Dropdown, DropdownSearch} from "../../../../../components";
import DatePicker from "react-datepicker";
import {useParams} from "react-router-dom";
import useMemberBrand from "../../../../../hooks/Member/useMemberBrand";
import {GetEmployeesWithCorporateEmails} from "../../../../../services/Employee/EmployeeService";

const CallRecordsDetailSearch = ({ changeDetail, loading }) => {

    const strings = useLocalization();
    const { memberId } = useParams();
    const memberBrand = useMemberBrand(memberId);

    //Bu bölüm belirli tarih aralığı seçmek için kullanılan değişkenlerin/fonksiyonların olduğu bölüm
    const datePickerRef = useRef();
    const firstVisit = useRef(true);

    const [beginDate, setBeginDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const dateRangeList = [...DateRanges];
    // Son 30 Gün Seçeneği Seçili getiriliyor
    const [dateRange, setDateRange] = useState(dateRangeList[3]);

    const firstEmployee = { id: -1, name : strings.detail_search_titles.employees};
    const [employeeList, setEmployeeList] = useState([firstEmployee]);
    const [selectedEmployee, setSelectedEmployee] = useState(firstEmployee);

    const handleDateRange = (dates) => {
        const [start, end] = dates;
        setBeginDate(start);
        setEndDate(end);
        if (start !== null && end !== null) {
            let tmpDateRange = { id: 8, name: getddMMyyyyDate(start) + " - " + getddMMyyyyDate(end) };
            dateRangeList.push(tmpDateRange);
            changeDetail?.({
                beginDate: dateToStringWithTime(start.setHours(0, 0, 0,0)),
                endDate: dateToStringWithTime(end.setHours(23, 59, 59,59))
            });
            setBeginDate(null);
            setEndDate(null);
            setDateRange(dateRange => dateRangeList.find(d => d.id === 8));
            datePickerRef.current.classList.add("hidden");
        }
    };

    //Bu bölüm belirli tarih aralığı seçmek için kullanılan değişkenlerin/fonksiyonların olduğu bölüm

    //Kullanıcılar getiriliyor. Bu endpointşn diğerlerinden farkı kurumsal e-posta adreslerini de getirmesidir.
    const getEmployeesWithCorporateEmails = async (brandId) => {
        const result = await GetEmployeesWithCorporateEmails(brandId);

        if(result.status === 200) {
            let resultContent = JSON.parse(result.content);
            setEmployeeList(employeeList => [firstEmployee, ...resultContent]);
        }
    }

    useEffect(() => {
        //Sayfa ilk kez yüklenmiyorsa işlemi yap
        //Servise birden çok gez gitmesini engelliyoruz
        if (!firstVisit.current) {
            let todayDate = new Date();
            let tmpBeginDate = '';
            let tmpEndDate = '';

            datePickerRef.current.classList.add("hidden");

            switch (dateRange.id) {
                case 1:
                    //Bugün seçildiyse
                    tmpBeginDate = todayDate;
                    tmpEndDate = tmpBeginDate;
                    break;
                case 2:
                    //Dün seçildiyse
                    tmpBeginDate = getDateWithDayDifference(todayDate, 1);
                    tmpEndDate = tmpBeginDate;
                    break;
                case 3:
                    //Son 7 gün seçildiyse
                    tmpEndDate = todayDate;
                    tmpBeginDate = getDateWithDayDifference(todayDate, 6);
                    break;
                case 4:
                    //Son 30 gün seçildiyse
                    tmpEndDate = todayDate;
                    tmpBeginDate = getDateWithDayDifference(todayDate, 30);
                    break;
                case 5:
                    //Bu ay seçildiyse
                    tmpBeginDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1);
                    tmpEndDate = new Date(todayDate.getFullYear(), todayDate.getMonth() + 1, 0);
                    break;
                case 6:
                    //Geçen ay seçildiyse
                    tmpBeginDate = new Date(todayDate.getFullYear(), todayDate.getMonth() - 1, 1);
                    tmpEndDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), 0);
                    break;
                case 7:
                    //Tarih aralığı seçildiyse
                    datePickerRef.current.classList.remove('hidden');
                    break;
            }

            (dateRange.id < 7) && changeDetail?.(
                {
                    beginDate: dateToStringWithTime(tmpBeginDate.setHours(0,0,0,0)),
                    endDate: dateToStringWithTime(tmpEndDate.setHours(23,59,59,59))
                });

            firstVisit.current = false;
        }
    }, [dateRange])


    useEffect(() => {
        //Sayfa ilk kez yüklenmiyorsa işlemi yap
        //Servise birden çok gez gitmesini engelliyoruz
        if (!firstVisit.current) {
            changeDetail?.({ corporateEmail: selectedEmployee.corporateEmail });
            firstVisit.current = false;
        }
    }, [selectedEmployee.id])

    //Employee listesine istek atılıyor.
    useEffect(() => {
        if(memberBrand !== -1)
            getEmployeesWithCorporateEmails(memberBrand);
    }, [memberBrand])

    useEffect(() => {
        //Sayfa yüklendikten sonra false yaparak her değişiklikte
        //servisin ziyaret edilmesini sağlıyoruz
        firstVisit.current = false;
    }, [])

    return (
        <>
            <DetailSearchLayout
                open={true}
                children={
                    <div className="!flex gap-x-4 gap-y-5 max-md-lg:justify-center flex-wrap relative">
                        <Dropdown
                            data={DateRanges}
                            selectedData={dateRange}
                            setSelectedData={setDateRange}
                            loading={loading}
                        />
                        <div ref={datePickerRef} className='absolute top-[75px] z-10 hidden'>
                            <button
                                className='absolute button search-button !h-[25px] !p-2 top-[-28px] bg-red text-white focus:!border-none right-0'
                                onClick={() => datePickerRef.current.classList.add("hidden")}
                            >
                                X
                            </button>
                            <DatePicker
                                selected={beginDate}
                                onChange={handleDateRange}
                                onClickOutside={() => datePickerRef.current.classList.add("hidden")}
                                startDate={beginDate}
                                endDate={endDate}
                                selectsRange
                                inline
                            />
                        </div>

                        <DropdownSearch
                            inputPlaceholder={strings.detail_search_titles.search_employee}
                            data={employeeList || []}
                            selectedData={selectedEmployee}
                            setSelectedData={setSelectedEmployee}
                            loading={loading}
                        />
                    </div>
                }
            />
        </>
    )
}

export default CallRecordsDetailSearch;