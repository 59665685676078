import React, { useState } from 'react'
import { useLocalization } from '../../../../../../hooks/Utils/useLocalization';
import { Dropdown, Input, showTopAlert } from '../../../../../../components';
import { PaymentOptions, PaymentTypes } from '../../../../../../consts/Constants';

const EditPaymentModal = (props) => {
  
  const strings = useLocalization();  
  const { closeButton, classes } = props;

  const firstPaymentType = { id: -1, name: strings.member.member_detail.accounting.choose_payment_type };
  const firstUserDoTask = { id: -1, name: strings.member.member_detail.accounting.choose_user_do_task };
  const firstPaymentOption = {id: -1, name: strings.member.member_detail.accounting.choose_payment_option };

  const [bank, setBank] = useState(1);
  const [price, setPrice] = useState(858,60);
  const [kdvRate, setKdvRate] = useState(8);
  const [approvalCode, setApprovalCode] = useState('');
  const [paymentApproveStatus, setPaymentApproveStatus] = useState('Muhasebe Onaysız');

  const [paymentType, setPaymentType] = useState(6);
  const paymentTypeList = [firstPaymentType, ...PaymentTypes];

  const [paymentOption, setPaymentOption] = useState(3);
  const paymentOptionList = [firstPaymentOption, ...PaymentOptions];

  const [userDoTask, setUserDoTask] = useState(firstUserDoTask);
  const userList = [firstUserDoTask];
  

  const submitHandle = async e =>{
    e.preventDefault();
    //boş geçilemez kontrolü servis bağlandıktan sonra yapılacak
    const model = {
        bank: bank,
        price: price,
        kdvRate: kdvRate,
        approvalCode: approvalCode,
        paymentType: paymentType,
        paymentOption: paymentOption,
        userDoTask: userDoTask,
        approvalCode: approvalCode,
        paymentApproveStatus: paymentApproveStatus
    }

    showTopAlert(strings.member.member_detail.accounting.payment_edit_success, "success");
    //Top end alert ekrandan kaybolduktan sonra modal'ın kapanmasını sağlayan fonksiyon
    setTimeout(() => closeButton(), 3000);
  }

  return (
    <>
        <form onSubmit={submitHandle}>
            <div className="flex justify-center">
                <p className="font-bold text-[15px]">{strings.member.member_detail.accounting.edit_payment}</p>
            </div>
            <div className={`flex flex-col md:flex-row gap-4 mt-5 max-md:overflow-y-scroll max-md:hover:overflow-y-scroll ${classes}`}>
                <div className="flex flex-col text-start gap-4 w-full">
                    <div className="flex flex-col px-[10px] pb-0 gap-1">
                        <label htmlFor="bank_id">{strings.form_info.bank}</label>
                        <Input
                            id="bank_id"
                            type="text"
                            defaultValue={bank}
                            onChange={(e) => setBank(e.target.value)}   
                        />
                    </div>
                    <div className="flex flex-col px-[10px] pb-0 gap-1">
                        <label htmlFor="price">{strings.form_info.price}</label>
                        <Input
                            id="price"
                            type="text"
                            defaultValue={price}
                            onChange={(e) => setPrice(e.target.value)}   
                        />                    
                    </div>
                    <div className="flex flex-col px-[10px] pb-0 gap-1">
                        <label htmlFor="value_added_tax_status">{strings.form_info.kdv_rate}</label>
                        <Input
                            id="value_added_tax_status"
                            type="text"
                            defaultValue={kdvRate}
                            onChange={(e) => setKdvRate(e.target.value)}   
                        />  
                    </div>
                    <div className="flex flex-col px-[10px] pb-0 gap-1">
                        <label htmlFor="approval_code">{strings.form_info.approve_code}</label>
                        <Input
                            id="approval_code"
                            type="text"
                            defaultValue={approvalCode}
                            onChange={(e) => setApprovalCode(e.target.value)}   
                        /> 
                    </div>
                </div>
                <div className="flex flex-col text-start gap-[19px] w-full">
                    <div className="flex flex-col px-[10px] pb-0 gap-1">
                        <label>{strings.form_info.payment_type}</label>
                        <Dropdown 
                            classnames="md:max-w-full"
                            data={paymentTypeList}
                            selectedData={paymentType}
                            setSelectedData={setPaymentType}
                        />
                    </div>
                    <div className="flex flex-col px-[10px] pb-0 gap-1">
                        <label>{strings.member.member_detail.sales_operations.payment_option}</label>
                        <Dropdown 
                            classnames="md:max-w-full"
                            data={paymentOptionList}
                            selectedData={paymentOption}
                            setSelectedData={setPaymentOption}
                        />
                    </div>
                    <div className="flex flex-col px-[10px] pb-0 gap-1">
                        <label>{strings.member.member_detail.accounting.payment_approve_status}</label>
                        <Input
                            id="payment_approve_status"
                            type="text"
                            defaultValue={paymentApproveStatus}
                            onChange={(e) => setPaymentApproveStatus(e.target.value)}   
                        />                     
                    </div>
                    <div className="flex flex-col px-[10px] pb-0 gap-1">
                        <label>{strings.member.member_detail.accounting.user_do_task}</label>
                        <Dropdown  
                            classnames="md:max-w-full"
                            data={userList}
                            selectedData={userDoTask}
                            setSelectedData={setUserDoTask}
                        />
                    </div>
                </div>
            </div>
            <div className="flex gap-3 justify-center">
                <button type="submit" className="group button search-button gap-3 text-white bg-blue border-blue focus:border-blue mt-7 px-10">{strings.button.save_upper}</button>
                <button type="button" className="button search-button gap-3 text-white bg-red border-red focus:border-red mt-7 px-10" onClick={closeButton}>{strings.button.cancel_upper}</button>
            </div>
        </form>
    </>
  )
}

export default EditPaymentModal;