import {useEffect, useRef, useState} from 'react'
import {CandidatePoolDetailSearch, CandidatePoolTable} from './components'
import {Loading, PageTitle, SearchBar, SearchCount, showTopAlert} from '../../components'
import {useLocalization} from '../../hooks/Utils/useLocalization'
import {MemberPoolFilter} from '../../services/Member/MemberPoolService'
import {stringToDate} from '../../components/Utility'
import Pagination from '../../components/Pagination'
import useAuth from '../../hooks/Log/useAuth'
import NotAuthorized from '../../components/NotAuthorized'

const CandidatePool = () => {

    const strings = useLocalization();
    const limit = parseInt(process.env.REACT_APP_TABLE_ROW_LIMIT);

    const { employee, employeeTypeId, checkPermission, getPermission } = useAuth();
    const roles = getPermission();
    const permission = checkPermission(roles, employeeTypeId);
    const firstVisit = useRef(true);

    const [start, setStart] = useState(0);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecord, setTotalRecord] = useState(0);
    const [data, setData] = useState([]);

    const [detailSearch, setDetailSearch] = useState({
        beginDate: null,
        endDate: null,
        brandId: +employee?.BrandId,
        scoreFilter: -1,
        formStatus: -1,
        utmSource: -1,
        searchText: ''
    });

    //Arama verileri değiştiğinde değerleri sıfırlıyoruz
    const resetValue = () => {
        setStart(start => 0);
        setCurrentPage(currentPage => 1);
        setLoading(loading => true);
    }

    //select değişiklikleri bu alandan yönetiliyor
    const changeDetail = (values) => {
        setDetailSearch({ ...detailSearch, ...values });
        resetValue();
    }

    //input değişiklikleri bu alandan yönetiliyor
    const changeValue = (value) => {
        setDetailSearch({ ...detailSearch, searchText: value });
        resetValue();
    }

    //sayfa değiştikçe bilgileri yeniden çağırıyoruz
    useEffect(() => {
        if (totalRecord !== 0) {
            getMemberPool();
        }
    }, [start])

    const getMemberPool = async () => {
        setLoading(loading => true);

        const model = {
            start: start,
            length: limit,
            beginDate: detailSearch.beginDate ? stringToDate(detailSearch.beginDate) : null,
            endDate: detailSearch.endDate ? stringToDate(detailSearch.endDate, 23, 59, 59) : null,
            brandId: detailSearch.brandId,
            scoreFilter: detailSearch.scoreFilter,
            formStatus: detailSearch.formStatus,
            utmSource: detailSearch.utmSource,
            searchText: detailSearch.searchText
        }

        const result = await MemberPoolFilter(model);

        if (result.status === 200) {
            let resultContent = JSON.parse(result.content);
            setData(data => resultContent.data);
            setTotalRecord(totalRecord => resultContent.recordsTotal);
            setLoading(loading => false);
        } else {
            showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
        }
    }

    useEffect(() => {
        //Sayfa yüklendikten sonra false yaparak her değişiklikte
        //servisin ziyaret edilmesini sağlıyoruz
        firstVisit.current = false;
    }, [])

    useEffect(() => {
        let abortController = new AbortController();

        permission && getMemberPool();

        return () => {
            abortController.abort();
        }
    }, [detailSearch, permission])

    return (
        <>  
            {permission && (
                <>
                    <CandidatePoolDetailSearch 
                        changeDetail={changeDetail} 
                        loading={loading}
                    />
                    <SearchBar 
                        changeValue={changeValue} 
                        placeHolder={strings.search_bar.search_candidate}
                        handleRefresh={() => getMemberPool()}
                        isDisabled={loading}
                    />
                    {!loading && <SearchCount count={totalRecord} />}
                </>
            )}
            <PageTitle title={strings.sidebar.member.candidate_pool} />

            {permission ? (
                loading ?
                (
                    <Loading />
                )
                :
                (
                    <>
                        <CandidatePoolTable data={data}
                        />
                        <Pagination
                            totalCount={totalRecord}
                            limit={limit}
                            start={start}
                            setStart={setStart}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            setLoading={setLoading}
                            loadScreen={true}
                        />
                    </>
                )
            ) : (<NotAuthorized />)}
        </>
    )
}

export default CandidatePool