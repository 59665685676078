import React, { useEffect, useRef, useState } from "react";
import tr from "date-fns/locale/tr";
import { useLocalization } from "../../../../../../hooks/Utils/useLocalization";
import DatePicker, { registerLocale } from "react-datepicker";
import {
  Dropdown,
  LoadingSpinner,
  showTopAlert,
  TimePicker,
} from "../../../../../../components";
import usePrimeData from "../../../../../../hooks/Utils/usePrimeData";
import { fullDateToShortDateWithTime } from "../../../../../../components/Utility";
import useAuth from "../../../../../../hooks/Log/useAuth";
import { UpdateReminder } from "../../../../../../services/Member/MemberFormService";
import classNames from "classnames";
import { removeFromLocalStorage } from "../../../../../../utils";
import { StorageKeys } from "../../../../../../consts/Constants";

const ReminderUpdateModal = ({ modalData, closeButton }) => {
  registerLocale("tr", tr);
  const strings = useLocalization();
  const { employee } = useAuth();
  const primeData = usePrimeData();

  const timeRef = useRef();
  const noteRef = useRef();

  const firstReminderReason = {
    id: -1,
    name: strings.member.member_detail.candidate_operations
      .choose_reminder_reason,
  };
  const [selectedReminderReason, setSelectedReminderReason] =
    useState(firstReminderReason);
  const [selectedTime, setSelectedTime] = useState("");
  const [updateDate, setUpdateDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);
  const [note, setNote] = useState("");
  const [loading, setLoading] = useState(false);

  const submitHandle = async (e) => {
    e.preventDefault();
    let valid = true;

    timeRef.current.innerText = "";

    //Seçilen saat ve dakika alınıyor.
    let selectedHour = +selectedTime.split(":")[0];
    let selectedMinute = +selectedTime.split(":")[1];
    let postponeDate = fullDateToShortDateWithTime(
      updateDate,
      +selectedHour,
      +selectedMinute,
      0
    );

    /*
      Şuanki saat ve dakika ile seçilen saat ve dakika karşılaştırılıyor.
      Seçilen zaman şuanki zamandan küçükse kullanıcıya hata veriliyor.
    */
    if (new Date(postponeDate) <= new Date()) {
      valid = false;
      timeRef.current.innerText = strings.errors.time_picker_reminder_error;
    }

    if (valid) {
      setLoading(true);

      let model = {
        memberFormActivityId: modalData.memberFormActivityId,
        reminderReasonId: selectedReminderReason.id,
        note:
          note.length > 0
            ? `${selectedReminderReason.name} - ${note}`
            : selectedReminderReason.name,
        callDate: new Date(postponeDate).toISOString(),
        createUserId: employee?.EmployeeId,
      };

      const result = await UpdateReminder(model);

      if (result.status === 200) {
        showTopAlert(
          strings.member.member_detail.candidate_operations
            .reminder_update_success_message,
          "success"
        );

        closeButton();

        removeFromLocalStorage(
          StorageKeys.ReminderLocalStorageKey,
          model.memberFormActivityId
        );
      } else {
        showTopAlert(strings.errors.an_error_occurred, "error");
      }

      setLoading(false);
    }
  };

  //Güncellenecek alana göre ilgili satırdan gelen veriler state'lere dolduruluyor.
  useEffect(() => {
    /*
      Hatırlatma notunda seçilen hatırlatma nedeni default olarak geldiği için bu default değer,
      kullanıcıya gösterilmiyor. Bu nedenle not üzerinde düzenleme yapılıyor.
    */

    setNote(
      modalData?.note
        .replace(`${modalData?.reminderReason}`, "")
        .replace(" -", "")
    );
  }, [modalData]);

  //Güncellenecek alana göre seçili hatırlatma nedeni dolduruluyor.
  useEffect(() => {
    if (primeData?.reminderReasonList.length > 0) {
      let tmpReminderReason = primeData.reminderReasonList.find(
        (reason) => +reason.id === modalData.reminderReasonId
      );
      setSelectedReminderReason(tmpReminderReason);
    }
  }, [primeData?.reminderReasonList]);

  return (
    <>
      <div className="flex justify-center">
        <p className="font-bold text-[15px] mb-2">
          {strings.member.member_detail.candidate_operations.update_reminder}
        </p>
      </div>
      <form
        onSubmit={submitHandle}
        className="flex flex-col text-start gap-4 mt-3"
      >
        <div className="flex flex-col md:gap-0 gap-4 md:flex-row">
          <div className="flex flex-col px-[10px] pb-0 w-full">
            <label htmlFor="discount_start_date">
              {strings.member.member_detail.candidate_operations.postpone_date}{" "}
              <span className="text-red">*</span>
            </label>
            <div className="relative">
              <DatePicker
                locale="tr"
                dateFormat="dd/MM/yyyy"
                className="form-input w-full"
                selected={updateDate}
                onClickOutside={() => setIsOpen(false)}
                onChange={(date) => {
                  setUpdateDate(date);
                  setIsOpen(false);
                }}
                placeholderText={strings.form_info.date_placeholder}
                open={isOpen}
                onFocus={() => setIsOpen(true)}
                minDate={new Date()}
                readOnly={true}
              />
              <button type="button" onClick={() => setIsOpen(!isOpen)}>
                <span className="material-symbols-outlined absolute right-6 top-2 text-lg">
                  calendar_today
                </span>
              </button>
            </div>
          </div>
          <div className="flex flex-col px-[10px] pb-0 w-full">
            <label htmlFor="postpone_time">
              {strings.member.member_detail.candidate_operations.postpone_time}
              <span className="text-red">*</span>
            </label>
            {/* .setHours(0, 0, 0, 0) ile date formatından saat-dakika vs çıkarılıp sadece date
                             alanı karşılaştırılıyor. */}
            <TimePicker
              listAllHours={
                !(
                  new Date().setHours(0, 0, 0, 0) ===
                  updateDate.setHours(0, 0, 0, 0)
                )
              }
              setSelectedTime={setSelectedTime}
            />
            <span
              ref={timeRef}
              className="text-danger field-validation-valid px-[10px] mt-1"
            ></span>
          </div>
        </div>

        <div className="flex flex-col px-[10px] pb-0 w-full">
          <label htmlFor="firm_name">
            {strings.member.member_detail.candidate_operations.reminder_reason}{" "}
            <span className="text-red">*</span>
          </label>
          <Dropdown
            classnames="md:max-w-full"
            data={primeData.reminderReasonList}
            selectedData={selectedReminderReason}
            setSelectedData={setSelectedReminderReason}
          />
          <span className="text-danger field-validation-valid px-[10px]"></span>
        </div>

        <div className="flex flex-col px-[10px] pb-0 w-full">
          <label htmlFor="note">
            {strings.member.member_detail.candidate_operations.note}
          </label>
          <div className="relative">
            <textarea
              id="note"
              className="border rounded-[10px] outline-0 focus:border-blue py-3 px-2 w-full"
              rows="3"
              value={note || ""}
              onChange={(e) => setNote(e.target.value)}
              ref={noteRef}
            /> 
            <button
              className={classNames(
                "absolute bottom-3 right-2 text-white bg-base-text rounded-full h-5 w-5 inline-flex justify-center items-center group",
                {
                  hidden: !note,
                }
              )}
              type="button"
              onClick={() => {
                setNote("");
                noteRef.current.value = "";
              }}
            >
              <span className="material-symbols-outlined text-[15px]">
                close
              </span>
              <div className="tooltip-top">
                <p>{strings.button.clear}</p>
              </div>
            </button>
          </div>
        </div>

        <div className="flex gap-3 justify-center">
          <button
            type="submit"
            className={classNames(
              "button search-button gap-3 text-white bg-blue border-blue mt-7 px-10 w-[130px]",
              {
                "!opacity-70 cursor-not-allowed": loading,
              }
            )}
            disabled={loading}
          >
            {loading && <LoadingSpinner />}
            {!loading && strings.button.save_upper}
          </button>
          <button
            type="button"
            className={classNames(
              "button search-button gap-3 text-white bg-red focus:border-red border-red mt-7 px-10 w-[130px]",
              {
                "!opacity-70 cursor-not-allowed": loading,
              }
            )}
            disabled={loading}
            onClick={closeButton}
          >
            {strings.button.cancel_upper}
          </button>
        </div>
      </form>
    </>
  );
};

export default ReminderUpdateModal;
