import { NoRecordsFound } from "../../../components";
import { useLocalization } from "../../../hooks/Utils/useLocalization";
import EmployeeTableRow from "./EmployeeTableRow";

const EmployeeTable = ({ data, sendRequestToEndpoint }) => {

    const strings = useLocalization();
    
    return (
        <div className="mx-auto px-4 my-4">
            <div
                className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
                <div className="inline-block min-w-full rounded-lg overflow-hidden">
                    <table className="min-w-full leading-normal text-header">
                        <thead>
                            <tr>
                                <th className="th">
                                    <p className="ml-6">{strings.table.brand}</p>
                                </th>
                                <th className="th">
                                    <p className="min-w-max">{strings.table.profile_photo}</p>
                                </th>
                                <th className="th">
                                    {strings.table.employee_informations}
                                </th>
                                <th className="th">
                                    {strings.table.corparate_informations}
                                </th>
                                <th className="th">
                                    {strings.table.operation}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.length > 0 ? 
                                (
                                    data.map((employee, index) => (
                                        <EmployeeTableRow
                                            key={index}
                                            listedEmployee={employee}
                                            sendRequestToEndpoint={sendRequestToEndpoint}
                                        />
                                    ))
                                )
                                :
                                (
                                    <tr className="bg-white hover:bg-[#f0f0f0]">
                                        <td colSpan="5">
                                            <NoRecordsFound />
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                    
                    <div id="employee-modal-container"></div>
                </div>
            </div>
        </div>
    );
}

export default EmployeeTable