import useAuth from "./useAuth";
import {CreateMemberNote} from "../../services/Member/MemberNoteService";

const useCreateMemberNote = () => {

    const { employee } = useAuth();

    const useCreateMemberNote = (memberId, note, noteTitleId) => {

        let model = {
            memberId: memberId,
            noteTitleId: noteTitleId,
            note: note,
            createUserId: employee?.EmployeeId
        }

        CreateMemberNote(model)
            .then(e => {})
            .catch(error => {});
    };

    return { useCreateMemberNote };
}

export default useCreateMemberNote;