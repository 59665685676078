import React from 'react'
import { useLocalization } from '../../../../hooks/Utils/useLocalization';

const MissedLesson = (props) => {

  const { data, termData } = props;

  const strings = useLocalization();

  return (
    <div className="missed-lesson min-h-[60px] py-1 -m-[1px] flex gap-1">
        <div className='flex flex-col gap-1'>
          <p className='text-white'>{termData?.fullName}</p>

          <div className='flex flex-row gap-1 items-center'>
            <p className='text-white'>{data?.fullName}</p>
            <div className="bg-card badge-color !min-w-0 !py-[3px] !px-3 relative group">
              <p className='cursor-default text-base-text text-lowercase'>{strings.teacher.teacher_program.dont_call}</p>
            </div>
          </div>
        </div>
    </div>
  )
}

export default MissedLesson