import React, { useEffect, useState } from 'react'
import { Loading, NoRecordsFound, showTopAlert } from '../../../../components'
import { useLocalization } from '../../../../hooks/Utils/useLocalization';
import { GetDiscountDetailsByOrderId } from '../../../../services/Payment/PaymentService';

const AppliedDiscountModal = (props) => {

    const { orderId } = props;

    const strings = useLocalization();

    const [loading, setLoading] = useState(true);
    const [discountDetails, setDiscountDetails] = useState([])

    //Silinen ödemeleri geri alma işlemini gerçekleştiren fonksiyon.
    const getDiscountDetailsByOrderId = async (orderId) => {
        setLoading(loading => true)
        const result = await GetDiscountDetailsByOrderId(orderId);

        if (result.status === 200) {
            const resultContent = JSON.parse(result.content);
            setDiscountDetails(discountDetails => resultContent[0].discountDetails)
        } else {
            showTopAlert(strings.errors.an_error_occurred, "error");
        }
        setLoading(loading => false)
    }

    useEffect(() => {
        if (orderId) {
            getDiscountDetailsByOrderId(orderId)
        }
    }, [])

    return (
        <>
            <div className="flex justify-center">
                <p className="font-bold text-[15px]">{strings.table.applied_discounts}</p>
            </div>
            {loading ? (
                <Loading />
            ) : (
                <div className="mx-auto px-4">
                    <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
                        <div className="inline-block min-w-full rounded-lg overflow-hidden">
                            <table className="min-w-full leading-normal text-header">
                                <thead>
                                    <tr>
                                        <th className="th">{strings.table.discount_name}</th>
                                        <th className="th w-[170px]">{strings.accounting.add_campaign.discount_rate} (%)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {discountDetails?.length > 0 && (
                                        <>
                                            {discountDetails.map((discountDetail, index) => (
                                                <tr key={index} className="bg-white hover:bg-[#f0f0f0]">
                                                    <td className="td align-middle py-4">
                                                        {discountDetail.discountName}
                                                    </td>
                                                    <td className="td align-middle py-4">
                                                        % {discountDetail.discountRate}
                                                    </td>
                                                </tr>
                                            ))}
                                        </>
                                    )}

                                    {discountDetails?.length === 0 && (
                                        <tr className="bg-white hover:bg-[#f0f0f0]">
                                            <td colSpan="6">
                                                <NoRecordsFound />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default AppliedDiscountModal