import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { url } from '../routes/utility'

const AuthRoute = ({ children }) => {

    const token = useSelector(state => state.employeeStore.token);
    const location = useLocation();
    
    if (!token) return <Navigate to={url("auth.login")} state={{ pathname: location?.pathname }} />

    return children;

}

export default AuthRoute