import classNames from 'classnames';
import tr from "date-fns/locale/tr";
import React, {useEffect, useRef, useState} from 'react';
import {registerLocale} from "react-datepicker";
import {useSelector} from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';
import {Accept, AcceptLight} from '../../../../../../../assets/img';
import {
    Dropdown,
    DropdownMultiSelect,
    Input,
    LargeButton,
    Loading, Modal,
    PageStep,
    PageTitle,
    showTopAlert,
    toggleTicketAccordion
} from '../../../../../../../components';
import {
    formatPrice,
    fullDateToShortDate, generateOrderNumber,
    handleCheckbox,
    isValidIdentityNumber, parseSecurePaymentKeyToJson,
    validateEmail
} from '../../../../../../../components/Utility';
import {Brands, CurrentCardType, RegistrationType, SourceType} from '../../../../../../../consts/Constants';
import useAuth from '../../../../../../../hooks/Log/useAuth';
import {useLocalization} from '../../../../../../../hooks/Utils/useLocalization';
import useMemberStatus from '../../../../../../../hooks/Member/useMemberStatus';
import usePrimeData from '../../../../../../../hooks/Utils/usePrimeData';
import {
    CreateCurrentCard,
    GetCurrentCardSelectListByMemberId
} from '../../../../../../../services/Accounting/CurrentCardService';
import {GetBankInfos, GetBankPoses} from '../../../../../../../services/Bank/BankService';
import {GetMemberProfile} from '../../../../../../../services/Member/MemberProfileService';
import {GetMemberBrandId} from '../../../../../../../services/Member/MemberService';
import {
    PayPackageBy3DCreditCard,
    PayPackageByCreditCard,
    PayPackageByTransfer
} from '../../../../../../../services/Payment/PaymentService';
import {
    CalculatePriceByDiscountRate,
    GetCampaignTypeDetailsForSales,
    GetDiscountsByMemberId,
    GetProductCategorySelectList,
    GetProductDetailsToChangePackage,
    GetSalesProductsV2
} from '../../../../../../../services/Product/ProductService';
import {ChangePackage} from '../../../../../../../services/Sales/SalesService';
import {GetCurrentProductDetail} from '../../../../../../../services/Term/TermService';
import {Accordion, CreditCard, PriceShape} from '../../../../../components';
import Installment from "../../../Common/Installment";
import useMemberName from "../../../../../../../hooks/Member/useMemberName";

const ChangeTerm = () => {

    registerLocale("tr", tr);
    const language = useSelector(state => state.localizationStore.language);
    const location = useLocation();
    const primeData = usePrimeData();
    const { employee } = useAuth();
    const { memberId } = useParams() || false;
    const memberStatus = useMemberStatus(memberId) || false;
    const memberName = useMemberName(memberId) || "";

    const [brandId, setBrandId] = useState(0);

    const termId = location.state?.termId || false;
    const studentNo = location.state?.studentNo || null;

    const strings = useLocalization();

    const accordionRef = useRef();
    const plusSignRef = useRef();
    const minusSignRef = useRef();
    const firstVisit = useRef(true);

    const title = strings.member.member_detail.sales_operations.registiration_title + " / " + strings.member.member_detail.terms.operation_modal.change_package;
    const [step, setStep] = useState(1);
    const [isOperationsCompleted, setIsOperationsCompleted] = useState(false);

    //step 1 başlangıç
    const firstProductCategory = { id: -1, name: strings.member.member_detail.inform_operations.choose_education_package };
    const firstMonth = { id: -1, name: strings.member.member_detail.inform_operations.choose_education_time };
    const firstDayOfWeek = { id: -1, name: strings.member.member_detail.inform_operations.choose_weekly_lesson_count };
    const firstMinute = { id: -1, name: strings.member.member_detail.sales_operations.choose_daily_lesson_time };
    const firstCampaign = { id: -1, name: strings.member.member_detail.sales_operations.choose_special_campaign };

    const [isClick, setIsClick] = useState(false);

    const [productCategory, setProductCategory] = useState(firstProductCategory);
    const [productCategoryList, setProductCategoryList] = useState([]);

    const [selectedProduct, setSelectedProduct] = useState({});
    const [selectedProductPrices, setSelectedProductPrices] = useState({ totalPrice: "0.00", discountPrice: "0.00", taxPrice: "0.00", totalPriceWithoutFormat: 0, discountPriceWithoutFormat: 0, taxPriceWithoutFormat: 0 });
    const [currentProduct, setCurrentProduct] = useState({});

    const [month, setMonth] = useState(firstMonth);
    const [monthList, setMonthList] = useState([]);

    const [dayOfWeek, setDayOfWeek] = useState(firstDayOfWeek);
    const [dayOfWeekList, setDayOfWeekList] = useState([]);

    const [minute, setMinute] = useState(firstMinute);
    const [minuteList, setMinuteList] = useState([]);
    const [tempMinuteList, setTempMinuteList] = useState([]);

    const [campaign, setCampaign] = useState(firstCampaign);
    const [campaignList, setCampaignList] = useState([]);
    const [campaignListWithDetail, setCampaignListWithDetail] = useState([]);
    const [discountDiscountRate, setDiscountDiscountRate] = useState(0);
    const [campaignDiscountRate, setCampaignDiscountRate] = useState(0);

    const [discount, setDiscount] = useState([]);
    const [discountList, setDiscountList] = useState([]);
    const [discountListWithDetail, setDiscountListWithDetail] = useState([]);

    const [showProductDetail, setShowProductDetail] = useState(false)

    const [bankPosList, setBankPosList] = useState([]);
    const [bankInfoList, setBankInfoList] = useState([]);

    const [newProductId, setNewProductId] = useState("");
    const [priceToBePaid, setPriceToBePaid] = useState({ priceWithoutDiscount: "", priceWithoutDiscountAndFormat: 0, discountPrice: 0, discountRate: 0, currencySymbol: "", discountPrice: "", discountPriceWithoutFormat: 0, price: "", priceWithoutFormat: 0, })

    const [baseDiscountedPrice, setBaseDiscountedPrice] = useState(0);
    const [completePaymentTransferLoading, setCompletePaymentTransferLoading] = useState(false);
    const [completePaymentCreditCardLoading, setCompletePaymentCreditCardLoading] = useState(false);
    const [waitCreateTerm, setWaitCreateTerm] = useState(false);

    //Banka pos listesi getiriliyor
    const getBankPosList = async () => {
        const result = await GetBankPoses();

        if (result.status === 200) {
            setBankPosList(JSON.parse(result.content));
        }
    }

    //Marka listesi getiriliyor
    const getBankInfoList = async () => {
        const result = await GetBankInfos();

        if (result.status === 200) {
            setBankInfoList(JSON.parse(result.content));
        }
    }

    //Ödeme işlemi onaylıysa gelen orderID yi saklıyoruz
    const [orderId, setOrderId] = useState(null);

    //Kullanıcının brandId'sini getirir.
    const getMemberBrandId = async (memberId) => {
        const result = await GetMemberBrandId(memberId);

        if (result.status === 200) {
            let resultContent = JSON.parse(result.content);
            setBrandId(brandId => parseInt(resultContent));
        }
    }

    //Hangi paket trüründen hangi paket türüne geçiş yapılabileceğini gösteren fonksiyon
    // 1: Genel İngilizce
    // 2: İş İngilizcesi
    // 3: Kampüs - Çocuk
    // 4: Premium
    // 5: Gramer
    const productCategoryTypes = Object.freeze(
        {
            1: [1, 2, 4, 5],
            2: [2, 4, 5],
            3: [1, 2, 3, 4, 5],
            4: [4],
            5: [2, 4, 5]
        }
    )

    // Product'lardan hangilerinin dropdownda görünmesini belirleyen fonksiyon
    const getProductCategoryTypes = (productCategoryType) => {
        if (productCategoryTypes.hasOwnProperty(productCategoryType)) {
            return productCategoryTypes[productCategoryType];
        } else {
            return [];
        }
    }

    const productCategoryLevels = Object.freeze(
        {
            1: 20, // Genel İngilizce kategorisinin level'i
            2: 50, // İş İngilizcesi kategorisinin level'i
            3: 10, // Kampüs - Çocuk kategorisinin level'i
            4: 100, // Premium kategorisinin level'i
            5: 50 // Gramer kategorisinin level'i
        }
    )

    const getProductCategoryLevels = (productCategoryType) => {
        if (productCategoryLevels.hasOwnProperty(productCategoryType)) {
            return productCategoryLevels[productCategoryType];
        } else {
            return 0;
        }
    }

    //Seçilen pakete göre dakika ve ders süresi bilgileri getiriliyor
    const getProductCategoryList = async (language) => {

        let arrayList = [];

        const result = await GetProductCategorySelectList(language);

        if (result.status === 200) {
            const data = JSON.parse(result.content);

            data.map((item) => {
                // Listeden global taraf için kampüs çocuk çıkartılıyor.
                if(+brandId !== Brands.KO && +item.value === 3) return;

                let arrayItem = { id: parseInt(item.value), name: item.text };
                arrayList.push(arrayItem);
            })
        }

        const desiredIds = getProductCategoryTypes(currentProduct.productCategoryId);
        const filteredArray = arrayList.filter(item => desiredIds.includes(item.id));

        setProductCategoryList(productCategoryList => filteredArray);
    }

    //Seçilen pakete göre dakika ve ders süresi bilgileri getiriliyor
    const getSalesProducts = async (productCategoryId, brandId, language) => {

        let tmpProductMonths = [];
        let tmpProductDays = [];
        let tmpProductMinutes = [];

        const result = await GetSalesProductsV2(productCategoryId, brandId, language);

        if (result.status === 200) {
            let resultContent = JSON.parse(result.content);

            resultContent?.productMonthList.map((item) => {
                tmpProductMonths.push(item);
            })

            resultContent?.productDayList.map((item) => {
                tmpProductDays.push(item);
            })

            resultContent?.productMinuteList.map((item) => {
                tmpProductMinutes.push(item);
            })

        }

        tmpProductMonths = tmpProductMonths.filter((item) => item.value >= currentProduct.productMonthValue)
        tmpProductDays = tmpProductDays.filter((item) => item.value >= currentProduct.productDayValue)

        if (currentProduct.productDayValue < dayOfWeek.value || currentProduct.productMonthValue < month.value) {
            tmpProductMinutes = tmpProductMinutes.filter((item) => item.value >= currentProduct.productMinuteValue)
        } else if (currentProduct.productDayValue === dayOfWeek.value && currentProduct.productMonthValue === month.value) {
            if (getProductCategoryLevels(productCategory.id) > getProductCategoryLevels(currentProduct.productCategoryId)) {
                tmpProductMinutes = tmpProductMinutes.filter((item) => item.value >= currentProduct.productMinuteValue)
            } else {
                tmpProductMinutes = tmpProductMinutes.filter((item) => item.value > currentProduct.productMinuteValue)
            }
        }
        else {
            tmpProductMinutes = tmpProductMinutes.filter((item) => item.value > currentProduct.productMinuteValue)

        }

        setMonthList([firstMonth, ...tmpProductMonths]);
        setDayOfWeekList([firstDayOfWeek, ...tmpProductDays]);
        setMinuteList([firstMinute, ...tmpProductMinutes]);
        setTempMinuteList([...tmpProductMinutes]);
    }


    //Seçilen ürüne ait kampanya Varsa kampanyaları getir
    const getCampaignTypeDetailsForSales = async (productId, salesTypeId) => {

        const result = await GetCampaignTypeDetailsForSales(productId, salesTypeId);

        let arrayList = [];
        let arrayListWithDetail = [];

        if (result.status === 200) {
            const data = JSON.parse(result.content);
            data.map((item) => {
                let arrayItem = { id: item.campaignId, name: item.campaignName + " - %" + item.discountRate };
                arrayList.push(arrayItem);
                arrayListWithDetail.push(item);
            })
        }

        setCampaignList([firstCampaign, ...arrayList]);
        setCampaignListWithDetail(arrayListWithDetail);
    }

    const [campaignState, setCampaignState] = useState(false);
    const [discountState, setDiscountState] = useState(false);

    useEffect(() => {
        if (discount.length > 0) {
            setCampaignState(true);
        }
    }, [campaign.id]);

    useEffect(() => {
        if (discount.length > 0) {
            setDiscountState(true);
        } else if (discount.length === 0) {
            setDiscountState(false);
        }
    }, [discount.length])

    useEffect(() => {
        if (Object.keys(selectedProduct).length > 0 && campaignState && discountState) {
            if (campaign.id !== -1)
                showTopAlert(strings.errors.discount_reseted, "warning");
            setCampaignState(false);
            setDiscountState(false);
        }
    }, [campaignState, selectedProduct, discountState]);

    useEffect(() => {
        if (Object.keys(selectedProduct).length > 0 && campaign.id === -1)
            showTopAlert(strings.errors.total_discount_reseted, "warning");
    }, [campaign.id]);

    const clearPriceToBePaid = () => {
        let tempData = priceToBePaid;
        setPriceToBePaid({
            priceWithoutDiscount: tempData.priceWithoutDiscount,
            priceWithoutDiscountAndFormat: tempData.priceWithoutDiscountAndFormat,
            discountPrice: 0,
            discountRate: 0,
            currencySymbol: tempData.currencySymbol,
            discountPrice: tempData.priceWithoutDiscount,
            discountPriceWithoutFormat: tempData.priceWithoutDiscountAndFormat,
            price: tempData.priceWithoutDiscount,
            priceWithoutFormat: tempData.priceWithoutDiscountAndFormat
        })
    }

    const getProductDetailsToChangePackage = async () => {

        let model = {
            termId: termId,
            brandId: brandId,
            memberStatus: memberStatus,
            categoryId: productCategory.id,
            productDayCount: dayOfWeek.id,
            productMonthId: month.id,
            productMinute: minute.id,
            //Önceki paketin satış tipini gönderiyoruz.
            salesType: currentProduct?.salesType,
            oldTermAttendedCount: currentProduct?.attendedLessonCount,
            oldTermMissedCount: currentProduct?.missedLessonCount,

        }

        const result = await GetProductDetailsToChangePackage(model);

        if (result.status === 200) {
            const data = JSON.parse(result.content);
            setNewProductId(data.productId)

            let prices = { totalPrice: data?.priceDetail?.totalPrice, discountPrice: data?.priceDetail?.discountPrice, taxPrice: data?.priceDetail?.taxPrice, totalPriceWithoutFormat: data?.priceDetail?.totalPriceWithoutFormat, discountPriceWithoutFormat: data?.priceDetail?.discountPriceWithoutFormat, taxPriceWithoutFormat: data?.priceDetail?.taxPriceWithoutFormat };
            setSelectedProductPrices(prices);
            setSelectedProduct(data);
            setPriceToBePaid({
                priceWithoutDiscount: data?.productPriceDifference?.price,
                priceWithoutDiscountAndFormat: data?.productPriceDifference?.priceWithoutFormat,
                discountPrice: data?.priceDetail?.discountPriceWithoutFormat,
                discountRate: 0,
                currencySymbol: data?.productPriceDifference.currencySymbol,
                discountPrice: data?.productPriceDifference?.discountPrice,
                discountPriceWithoutFormat: data?.productPriceDifference?.discountPriceWithoutFormat,
                price: data?.productPriceDifference?.price,
                priceWithoutFormat: data?.productPriceDifference?.priceWithoutFormat
            })
            setBaseDiscountedPrice(baseDiscountedPrice => data?.productPriceDifference?.discountPriceWithoutFormat)

        }
    }

    //Seçili ürün değişince ürüne özel kampanya ve indirimleri getiriyoruz
    useEffect(() => {

        if (Object.keys(selectedProduct).length > 0) {
            getCampaignTypeDetailsForSales(selectedProduct.productId, RegistrationType.ChangePackage);
            let model = {
                memberId: memberId,
                moneyboxDiscountLimit: 100 - priceToBePaid?.discountRate,
                productId: selectedProduct.productId,
                selectedCampaignId: campaign.id === -1 ? null : campaign.id,
                salesTypeId: RegistrationType.ChangePackage
            };
            getDiscountsByMemberId(model);
        }
    }, [selectedProduct, campaign.id])

    //Kampanya seçildiğinde fiyatın güncellenmesi gerekiyor.
    const calculatePriceByDiscountRate = async (model) => {
        if (model.price !== 0) {
            const result = await CalculatePriceByDiscountRate(model);
            if (result.status === 200) {
                const data = JSON.parse(result.content);
                let tmpData = priceToBePaid;
                setPriceToBePaid({
                    priceWithoutDiscount: tmpData.priceWithoutDiscount,
                    priceWithoutDiscountAndFormat: tmpData.priceWithoutDiscountAndFormat,
                    discountPrice: data?.discountPrice,
                    discountRate: tmpData?.discountRate,
                    currencySymbol: data?.brandCurrencySymbol,
                    discountPrice: data?.discountPrice,
                    discountPriceWithoutFormat: data?.discountPriceWithoutFormat,
                    price: data?.discountPrice,
                    priceWithoutFormat: data?.discountPriceWithoutFormat
                })
            }
        }
    }

    const getCurrentProductDetail = async (termId) => {
        const result = await GetCurrentProductDetail(termId);
        if (result.status === 200) {
            const data = JSON.parse(result.content);
            setCurrentProduct(data);
        }
    }

    useEffect(() => {
        if (memberId) {
            getMemberBrandId(memberId);
            getBankPosList();
            getBankInfoList();
        }
        getCurrentProductDetail(termId)
    }, [memberId])

    useEffect(() => {
        if (language && brandId) {
            getProductCategoryList(language);
        }
    }, [language, currentProduct, brandId])

    useEffect(() => {
        //Kampanya değişirse seçili olan indirimleri sıfırlamamız gerekiyor
        setDiscount([]);

        if (campaign.id !== -1) {
            if (Object.keys(campaignListWithDetail).length > 0) {
                let selectedCampaign = campaignListWithDetail.find(c => c.campaignId === campaign.id);
                if (typeof selectedCampaign !== "undefined") {
                    setCampaignDiscountRate(selectedCampaign.discountRate);
                    // let modelDiscountRate = selectedCampaign.discountRate + discountDiscountRate
                    let modelDiscountRate = (selectedCampaign.discountRate === 0 && discountDiscountRate === 0) ?
                        priceToBePaid?.discountRate :
                        selectedCampaign.discountRate + discountDiscountRate;


                    let model = {
                        price: (selectedCampaign.discountRate === 0 && discountDiscountRate === 0) ? priceToBePaid.discountPriceWithoutFormat : baseDiscountedPrice,
                        discountRate: modelDiscountRate > 100 ? 100 : modelDiscountRate,
                        brandId: brandId
                    };

                    calculatePriceByDiscountRate(model);

                    //Özel indirim alanı yeniden seçiliyor
                    let model2 = {
                        memberId: memberId,
                        moneyboxDiscountLimit: modelDiscountRate > 0 ? 100 : 100 - modelDiscountRate,
                        productId: selectedProduct.productId,
                        selectedCampaignId: campaign.id,
                        salesTypeId: RegistrationType.ChangePackage
                    };
                    getDiscountsByMemberId(model2);
                }
            }
        } else {
            clearPriceToBePaid();
            // setSelectedProductPrices({ totalPrice: selectedProduct?.priceDetail?.totalPrice, discountPrice: selectedProduct?.priceDetail?.discountPrice, taxPrice: selectedProduct?.priceDetail?.taxPrice, totalPriceWithoutFormat: selectedProduct?.priceDetail?.totalPriceWithoutFormat, discountPriceWithoutFormat: selectedProduct?.priceDetail?.discountPriceWithoutFormat, taxPriceWithoutFormat: selectedProduct?.priceDetail?.taxPriceWithoutFormat });
            setCampaignDiscountRate(0);
            setDiscountDiscountRate(0);
        }

    }, [campaign])

    //Seçilen ürüne ait indirim varsa indirimleri getir
    const getDiscountsByMemberId = async (model) => {
        const result = await GetDiscountsByMemberId(model);
        let arrayList = [];
        let arrayListWithDetail = [];

        if (result.status === 200) {
            const data = JSON.parse(result.content);
            data.map((item) => {
                let arrayItem = { id: item.campaignId, name: item.campaignName + " - %" + item.discountRate };
                arrayList.push(arrayItem);
                arrayListWithDetail.push(item);
            })
        }


        setDiscountList([...arrayList]);
        setDiscountListWithDetail(arrayListWithDetail);
    }

    useEffect(() => {
        if (discount) {
            //İndirimler seçildiğinde bir indirim tipinden yalnızca 1 tane eklenebilmesi için
            //aşağıdaki kodu kullanıyoruz
            let discountArray = [];
            let totalDiscount = 0;

            discountListWithDetail.map(dsl => {
                discount.map(ds => {
                    if (dsl.campaignId === ds) {
                        if (dsl.campaignType !== 2) { // Eğer kampanya tipi 2 değilse bu bloğu çalıştır
                            let findCampaignType = discountArray.find(dsa => dsa.campaignType === dsl.campaignType);
                            if (typeof findCampaignType === "undefined") {
                                discountArray.push(dsl);
                                totalDiscount = totalDiscount + dsl.discountRate;
                            } else {
                                showTopAlert(strings.errors.same_campaign_type_error, "warning")
                                setDiscount([]);
                                clearPriceToBePaid();
                                totalDiscount = 0;
                            }
                        } else {
                            discountArray.push(dsl);
                            totalDiscount = totalDiscount + dsl.discountRate;
                        }
                    }
                })
            })

            //Toplam indirim oranına buradan seçilen indirimlerin oranlarını ekliyoruz
            setDiscountDiscountRate(discountDiscountRate => totalDiscount);
            let modelDiscountRate = (totalDiscount === 0 && campaignDiscountRate === 0) ?
                priceToBePaid?.discountRate :
                totalDiscount + campaignDiscountRate;

            let model = {
                price: (totalDiscount === 0 && campaignDiscountRate === 0) ? (discount.length > 0 ? priceToBePaid.discountPriceWithoutFormat : baseDiscountedPrice) : baseDiscountedPrice,
                discountRate: modelDiscountRate > 100 ? 100 : modelDiscountRate,
                brandId: brandId
            };
            if (model.price !== 0) {
                calculatePriceByDiscountRate(model);
            }
        }
    }, [discount])

    //Eğer ay değişirse aydan sonra gelen veriler sıfırlanıyor
    useEffect(() => {

        setCampaign(firstCampaign);
        setCampaignList([firstCampaign]);
        setDiscount([]);
        setDiscountList([]);
        setCampaignDiscountRate(0);
        setDiscountDiscountRate(0);

        setDayOfWeek(firstDayOfWeek);
        setMinute(firstMinute);
        setShowProductDetail(showProductDetail => false);
        setSelectedProductPrices({ totalPrice: "0.00", discountPrice: "0.00", taxPrice: "0.00", totalPriceWithoutFormat: 0, discountPriceWithoutFormat: 0, taxPriceWithoutFormat: 0 });

    }, [month])

    //2 ve 3 günlük paketlerde 40 ve üzeri dakikalarda ders olmadığı için üst değerler alınmıyor
    useEffect(() => {
        setCampaign(firstCampaign);
        setCampaignList([firstCampaign]);
        setDiscount([]);
        setDiscountList([]);
        setCampaignDiscountRate(0);
        setDiscountDiscountRate(0);


        if (dayOfWeek.id === 2 || dayOfWeek.id === 3) {
            let tmpMinute = brandId === 1 ? tempMinuteList.filter(m => m.id < 50) : tempMinuteList;
            if (minute.id === 50 && brandId === 1) {
                setMinute(firstMinute);
            }
            setMinuteList([firstMinute, ...tmpMinute]);
        } else {
            setMinuteList([firstMinute, ...tempMinuteList]);
        }

        setMinute(firstMinute);
        setMinuteList([firstMinute]);
        setShowProductDetail(showProductDetail => false);
        setSelectedProductPrices({ totalPrice: "0.00", discountPrice: "0.00", taxPrice: "0.00", totalPriceWithoutFormat: 0, discountPriceWithoutFormat: 0, taxPriceWithoutFormat: 0 });
    }, [dayOfWeek])

    //Dakika kısmı seçimi kaldırılırsa yeni paket detayları görünmez olur.
    useEffect(() => {
        if (minute.id === -1) {
            setShowProductDetail(showProductDetail => false);
        }
    }, [minute])

    //Paket seçme kısmı değiştiğinde süre kısımları sıfırlanır.
    useEffect(() => {
        //Ürün kategorisi değiştiğinde tüm dakika, gün ve ay bilgilerini sıfırlıyoruz
        setMonth(firstMonth);
        setDayOfWeek(firstDayOfWeek);
        setMinute(firstMinute);
        setMonthList([firstMonth]);
        setDayOfWeekList([firstDayOfWeek]);
        setMinuteList([firstMinute]);
        setCampaignList([firstCampaign]);
        setCampaign(firstCampaign);
        setDiscountList([]);
        setDiscount([]);
        setSelectedProductPrices({ totalPrice: "0.00", discountPrice: "0.00", taxPrice: "0.00", totalPriceWithoutFormat: 0, discountPriceWithoutFormat: 0, taxPriceWithoutFormat: 0 });
        setDiscountDiscountRate(0);
        setCampaignDiscountRate(0);

    }, [productCategory, language])

    useEffect(() => {
        if (productCategory.id !== -1) {
            setShowProductDetail(showProductDetail => false);
            getSalesProducts(productCategory.id, brandId, language)
        }
    }, [productCategory, month, dayOfWeek, currentProduct])

    useEffect(() => {
        if (productCategory.id !== -1 && month.id !== -1 && minute.id !== -1 && dayOfWeek.id !== -1) {
            setShowProductDetail(showProductDetail => true)
            getProductDetailsToChangePackage();
        } else {
            setSelectedProduct({});
        }
    }, [productCategory, month, minute, dayOfWeek])

    //step 1 son

    //step 2 başlangıç
    const hiddenInputRef = useRef();

    //Sayfa içerisindeki tab'ları kontrol eder
    //0 Kredi Kartı
    //1 Havale
    //2 Alışveriş Kredisi
    const [tab, setTab] = useState(0);
    const [modal, setModal] = useState(false);
    const [modalContent, setModalContent] = useState({});

    const [cardholderName, setCardholderName] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [cardExpiration, setCardExpiration] = useState('');
    const [cardCvv, setCardCvv] = useState('');
    const [bank, setBank] = useState(null);
    const [bankName, setBankName] = useState("");
    const [isForeignCard, setIsForeignCard] = useState(false);
    const [isOneShot, setIsOneShot] = useState(false);
    const [isRecurringPayment, setIsRecurringPayment] = useState(false);
    const [paymentOption, setPaymentOption] = useState(0);
    const [isCompletePayment, setIsCompletePayment] = useState(false);

    /* 3D Ödeme İşlemleri İçin Stateler */

    // 3D modalı açıkken arkada çalışan counter state'i sürekli gizli input'un kontrol edilmesini tutan state
    const [counter, setCounter] = useState(0);
    // 3D ödeme bilgilerinin olduğu base64 stringi tutan state
    const [securePaymentResult, setSecurePaymentResult] = useState("");
    // 3D ödeme order numarasının tutulduğu state
    const [securePaymentOrderNumber, setSecurePaymentOrderNumber] = useState("");
    // 3D ödeme linkini tutan state
    const [securePaymentBase64Content, setSecurePaymentBase64Content] = useState("");
    // 3d ödemeler için kullanılan, 3d servislerinden cevap geldiğinde tetiklenen loading
    const [securePaymentLoading, setSecurePaymentLoading] = useState(false);

    /* ödeme ve dönem işlemleri */
    /* ödeme ve dönem işlemleri */
    /* ödeme ve dönem işlemleri */

    //Banka havalesi ya da EFT ile yapılan ödemelerde servise giden fonksiyon
    const payPackageByTransfer = async () => {
        setCompletePaymentTransferLoading(completePaymentTransferLoading => true);

        //Kampanya listesini dolduracağız
        let campaignIdList = [];

        if (campaign.id !== -1) {
            campaignIdList.push(campaign.id)
        }

        if (discount.length > 0) {
            discount.map(ds => campaignIdList.push(ds));
        }
        //Kampanya listesini dolduracağız

        let model = {
            brandId: brandId,
            memberId: memberId,
            salesType: RegistrationType.ChangePackage,
            productId: newProductId,
            price: priceToBePaid.priceWithoutDiscountAndFormat,
            discountPrice: priceToBePaid.discountPriceWithoutFormat,
            discountRate: discountDiscountRate + campaignDiscountRate > 100 ? 100 : discountDiscountRate + campaignDiscountRate,
            posType: 1,
            createUser: employee.EmployeeId,
            taxValue: selectedProduct?.priceDetail?.taxRate,
            sourceType: SourceType.Crm,
            taxPrice: priceToBePaid.priceWithoutFormat - priceToBePaid.discountPriceWithoutFormat,
            totalPrice: priceToBePaid.priceWithoutFormat,
            campaignIdList: campaignIdList,
            bankInfoId: bank,
        }

        const result = await PayPackageByTransfer(model);

        if (result.status === 200) {
            setOrderId(orderId => JSON.parse(result.content))
            //Ödemeyi Tamamla butonuna tıklandıktan sonra butonun disabled olması (herhangi bir bug'dan dolayı butona iki defa tıklanıp servise 2 defa istek atmasını önlemek) için kullanıldı
            setIsCompletePayment(true)

        } else {
            showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
            setCompletePaymentTransferLoading(completePaymentTransferLoading => false);
        }

    }

    const createPayPackageModel = () => {
        //Kampanya listesini dolduracağız
        let campaignIdList = [];

        if (campaign.id !== -1) {
            campaignIdList.push(campaign.id)
        }

        if (discount.length > 0) {
            discount.map(ds => campaignIdList.push(ds));
        }

        return {
            brandId: brandId,
            memberId: memberId,
            salesType: RegistrationType.ChangePackage,
            productId: newProductId,
            price: priceToBePaid.priceWithoutDiscountAndFormat,
            discountPrice: priceToBePaid.discountPriceWithoutFormat,
            discountRate: discountDiscountRate + campaignDiscountRate > 100 ? 100 : discountDiscountRate + campaignDiscountRate,
            posType: 1,
            bankPosId: bank,
            createUser: employee.EmployeeId,
            installment: paymentOption,
            taxValue: selectedProduct?.priceDetail?.taxRate,
            sourceType: SourceType.Crm,
            taxPrice: priceToBePaid.priceWithoutFormat - priceToBePaid.discountPriceWithoutFormat,
            totalPrice: priceToBePaid.priceWithoutFormat,
            campaignIdList: campaignIdList,
            isRecurringPayment: isRecurringPayment,
            isForeignCard: isForeignCard
        };
    }

    const createCreditCardModel = () => {
        return {
            month: cardExpiration.split("/")[0],
            year: cardExpiration.split("/")[1],
            number: cardNumber,
            name: cardholderName,
            cvc: cardCvv,
            cardType: "credit",
            isSecurity: false
        };
    }

    //Banka havalesi ya da EFT ile yapılan ödemelerde servise giden fonksiyon
    const payPackageByCreditCard = async () => {

        setCompletePaymentCreditCardLoading(true);

        let model = createPayPackageModel();
        model.creditCardDetail = createCreditCardModel();

        const result = await PayPackageByCreditCard(model);

        if (result.status === 200) {
            setOrderId(JSON.parse(result.content))
            //Ödemeyi Tamamla butonuna tıklandıktan sonra butonun disabled olması (herhangi bir bug'dan dolayı butona iki defa tıklanıp servise 2 defa istek atmasını önlemek) için kullanıldı
            setIsCompletePayment(true)
        } else {
            showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
            setCompletePaymentCreditCardLoading(completePaymentCreditCardLoading => false);
        }
    }

    const payPackageWith3d = async (paymentResult) => {

        setCompletePaymentCreditCardLoading(true);

        const model = createPayPackageModel();
        model.orderNumber = securePaymentOrderNumber;
        model.virtualPosResultModel = {
            status: paymentResult?.Response === "Approved",
            statusMessage: paymentResult?.Response === "Approved" ? "" : paymentResult?.ErrorMessage,
            authCode: paymentResult?.Response === "Approved" ? paymentResult?.AuthCode : null,
            bankErrorCode: null
        };

        const result = await PayPackageBy3DCreditCard(model);
        const createdOrderId = JSON.parse(result.content);

        /*
            * Ödeme başarıyla alınır ve order kaydederken problem yaşanırsa bir problem yaşanırsa kullanıcıya bilgi veriliyor.
        */
        if(result.status !== 200 && paymentResult?.Response === "Approved"){
            showTopAlert(strings.member.member_detail.sales_operations.order_failed, "error");
            setCompletePaymentCreditCardLoading(false);
            return;
        }

        if (model.virtualPosResultModel.status) {
            setOrderId(createdOrderId);
            //Ödemeyi Tamamla butonuna tıklandıktan sonra butonun disabled olması (herhangi bir bug'dan dolayı butona iki defa tıklanıp servise 2 defa istek atmasını önlemek) için kullanıldı
            setIsCompletePayment(true)
            setStep(3);
        } else {
            showTopAlert(strings.errors.an_error_occurred + " / " + paymentResult?.ErrorMessage, "error");
        }

        setCompletePaymentCreditCardLoading(false);
    }

    const handlePayPackage = () => {
        if(bankName === "Ziraat Bankası" && paymentOption > 0) {
            // Order Id oluşturuluyor ve bir state'e kaydediliyor
            const tempMemberName = memberName !== ""
                ? memberName
                : cardholderName;
            const tempOrderNumber = generateOrderNumber(tempMemberName);
            setSecurePaymentOrderNumber(tempOrderNumber);

            const currentYear = new Date().getFullYear();
            const firstTwoDigitsOfYear = currentYear.toString().substring(0, 2);

            //Ödeme linki için base64 content oluşturuluyor
            const tempBase64Object = {
                amount: priceToBePaid.priceWithoutFormat,
                installment: paymentOption,
                cardNumber: cardNumber,
                cardMonth: cardExpiration.split("/")[0],
                cardYear: firstTwoDigitsOfYear + cardExpiration.split("/")[1],
                cardCvc: cardCvv,
                orderId: tempOrderNumber
            };

            const tempBase64Content = `Amount=${tempBase64Object.amount}&Installment=${tempBase64Object.installment}&CardNumber=${tempBase64Object.cardNumber}&Year=${tempBase64Object.cardYear}&Month=${tempBase64Object.cardMonth}&Cvv=${tempBase64Object.cardCvc}&Oid=${tempBase64Object.orderId}`;
            setSecurePaymentBase64Content(btoa(tempBase64Content));

            setModal(true);
            setModalContent({element: "3dModal"});
        } else {
            payPackageByCreditCard();
        }
    }

    //3D modalı açıldığında counter çalışmaya başlar.
    useEffect(() => {
        if(modal && Object.keys(modalContent).length > 0) {
            const interval = setInterval(() => {
                setCounter(prevCounter => prevCounter + 1);
            }, 500);

            return () => clearInterval(interval);
        }
    }, [modal, modalContent]);

    // Counter'a göre gizli input kontrol edilir
    useEffect(() => {
        if(hiddenInputRef?.current?.value?.length > 0) {
            // Modal kapatılır
            setModal(false);
            setModalContent({});

            // Gizli input bir state'e atılıyor ve boşaltılıyor.
            setSecurePaymentResult(hiddenInputRef.current.value);
            hiddenInputRef.current.value = "";

            // Sayfa loading'e sokuluyor
            setSecurePaymentLoading(true);

            // Sayfa yukarı scroll ettiriliyor
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    }, [counter]);

    useEffect(() => {
        if(securePaymentLoading && securePaymentResult.length > 0) {
            const decodedSecurePaymentResult = parseSecurePaymentKeyToJson(securePaymentResult);
            //Order numarası oluşturma işlemi bittikten sonra ödeme 3d order oluşumu için servise gidiliyor
            payPackageWith3d(decodedSecurePaymentResult)
                .then()
                .catch();
        }
    }, [securePaymentLoading, securePaymentResult]);

    const changePackage = async () => {
        setCompletePaymentTransferLoading(completePaymentTransferLoading => true);
        setCompletePaymentCreditCardLoading(completePaymentCreditCardLoading => true);
        let beginDate = new Date();

        if (beginDate.getDay() === 5) { // Eğer bugün Cuma ise
            beginDate.setDate(beginDate.getDate() + 3); // 3 gün ekleyerek Pazartesi gününü elde eder
        }

        let model = {
            termId: termId,
            orderId: orderId,
            createUserId: employee.EmployeeId,
            newProductId: newProductId,
            studentNo: studentNo,
            memberStatus: memberStatus,
            beginDate: fullDateToShortDate(beginDate),
            newProductTotalLessonCount: selectedProduct?.productPriceDifference?.totalLessonCount
        }

        const result = await ChangePackage(model);

        if (result.status === 200) {
            showTopAlert(strings.member.member_detail.sales_operations.order_success, "success");
            setStep(3);
        } else {
            showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
        }

        setCompletePaymentTransferLoading(completePaymentTransferLoading => false);
        setCompletePaymentCreditCardLoading(completePaymentCreditCardLoading => false);
    }

    /* ödeme ve dönem işlemleri */
    /* ödeme ve dönem işlemleri */
    /* ödeme ve dönem işlemleri */
    useEffect(() => {
        if (orderId !== null) {
            changePackage();
        }
    }, [orderId])

    //Tekrarlı ödeme seçildiğinde otomatik olarak taksit seçeneğini 2 olarak belirler
    useEffect(() => {
        if (isRecurringPayment) {
            setPaymentOption(1);
        } else {
            setPaymentOption(0);
        }
    }, [isRecurringPayment])

    //Tab değiştiğinde seçili bankayı ve taksit sayısını sıfırlıyor
    useEffect(() => {
        if (firstVisit.current === false) {
            setBank(null);
            if (tab !== 0) {
                setPaymentOption(null);
            }
            if (tab === 0) {
                setPaymentOption(0);
            }
        }
    }, [tab])
    //step 2 son

    //Step 3 Başlangıç
    const invoiceTypes = CurrentCardType.map(cct => ({ id: cct.id, name: cct[language] }));

    //Hataları input alanının altına yazdırmak için kullanılan referanslar
    const emailErrorRef = useRef();
    const fullNameErrorRef = useRef();
    const taxNumberErrorRef = useRef();
    const invoiceTypeErrorRef = useRef();
    const addressErrorRef = useRef();
    const taxOfficeErrorRef = useRef();

    const firstInvoiceType = { id: -1, name: strings.member.member_detail.accounting.choose_invoice_type };
    const [invoiceType, setInvoiceType] = useState(firstInvoiceType);
    const invoiceTypeList = [firstInvoiceType, ...invoiceTypes];

    const [fullName, setFullName] = useState(null);
    const [email, setEmail] = useState(null);
    const [isAbroad, setIsAbroad] = useState(false);
    const [taxOffice, setTaxOffice] = useState(null);
    const [taxNumber, setTaxNumber] = useState(null);
    const [address, setAddress] = useState(null);
    const [currentCardList, setCurrentCardList] = useState([]);
    const [waitCreatePersonalInformation, setWaitCreatePersonalInformation] = useState(false);

    const submitInformationHandle = async (e) => {
        e.preventDefault();

        let valid = true;

        //Hataları sıfırlıyoruz
        emailErrorRef.current.innerText = '';
        fullNameErrorRef.current.innerText = '';
        invoiceTypeErrorRef.current.innerText = '';
        taxNumberErrorRef.current.innerText = '';
        addressErrorRef.current.innerText = '';

        if (invoiceType.id === 1) {
            taxOfficeErrorRef.current.innerText = '';
        }

        if (invoiceType.id === -1) {
            valid = false;
            invoiceTypeErrorRef.current.innerText = strings.errors.invoice_type_null_error;
        }

        if (fullName === null) {
            valid = false;
            fullNameErrorRef.current.innerText = strings.errors.general_null_error;
        }

        if (address === null) {
            valid = false;
            addressErrorRef.current.innerText = strings.errors.address_null_error;
        }

        if (email !== null) {
            if (!validateEmail(email)) {
                emailErrorRef.current.innerText = strings.errors.email_format_error;
                valid = false;
            }
        }

        if (!isAbroad) {
            if (invoiceType.id === 0 && taxNumber !== null && taxNumber !== '' && !isValidIdentityNumber(taxNumber)) {
                valid = false;
                taxNumberErrorRef.current.innerText = strings.errors.identity_number_format_error;
            }

            if (invoiceType.id === 1 && taxNumber === null || taxNumber === '') {
                valid = false;
                taxNumberErrorRef.current.innerText = strings.errors.tax_number_null_error;
            }

            if (invoiceType.id === 1 && taxOffice === null || taxOffice === '') {
                valid = false;
                taxOfficeErrorRef.current.innerText = strings.errors.tax_office_null_error;
            }
        }

        if (valid) {

            setWaitCreatePersonalInformation(waitCreatePersonalInformation => true);

            const model = {
                memberId: memberId,
                cardType: invoiceType.id,
                name: fullName,
                taxOffice: taxOffice !== null && taxOffice !== '' ? taxOffice : null,
                taxNumber: taxNumber !== null ? taxNumber : "11111111111",
                email: email,
                isAbroad: isAbroad,
                address: address,
                employeeId: employee.EmployeeId
            }

            const result = await CreateCurrentCard(model);
            if (result.status === 200) {
                showTopAlert(strings.member.member_detail.sales_operations.registration_success, "success");
                setIsOperationsCompleted(isOperationsCompleted => true);
                setTimeout(() => window.location.href = "/member/detail/profile/" + memberId, 1000);
            } else {
                showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
                setWaitCreatePersonalInformation(waitCreatePersonalInformation => false);
            }

        }
    }

    const submitInformationWithoutHandle = () => {
        setWaitCreatePersonalInformation(waitCreatePersonalInformation => true)
        showTopAlert(strings.member.member_detail.sales_operations.registration_success, "success");
        setIsOperationsCompleted(isOperationsCompleted => true);
        setTimeout(() => window.location.href = "/member/detail/profile/" + memberId, 1000);
    }

    //Member Profile bilgileri getiriliyor
    const getMemberProfile = async (memberId) => {

        const result = await GetMemberProfile(memberId);
        if (result.status === 200) {
            let resultContent = JSON.parse(result.content);

            setFullName(fullName => resultContent.fullname);
            setEmail(email => resultContent.email);
        } else {
            showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
        }
    }

    //Cari kartlar için aşağıdaki değişkenleri tutuyoruz
    const firstCurrentCard = { id: -1, name: strings.member.member_detail.accounting.choose_previous_current_card };
    const [selectedCurrentCard, setSelectedCurrentCard] = useState(firstCurrentCard);
    const [addNewCurrentCard, setAddNewCurrentCard] = useState(false);

    useEffect(() => {
        if (selectedCurrentCard !== -1) {
            setAddNewCurrentCard(false);
        }
    }, [selectedCurrentCard])

    //Eski cari kartları varsa onları listeliyoruz
    const getCurrentCardSelectListByMemberId = async (memberId) => {
        const result = await GetCurrentCardSelectListByMemberId(memberId);

        let arrayList = [];

        if (result.status === 200) {
            const data = JSON.parse(result.content);
            data.map((item) => {
                let arrayItem = { id: item.value, name: item.text };
                arrayList.push(arrayItem);
            })
        }

        setCurrentCardList([firstCurrentCard, ...arrayList]);
    }

    useEffect(() => {
        if (step === 3) {
            getCurrentCardSelectListByMemberId(memberId);
            getMemberProfile(memberId);
        }
    }, [step])
    //Step 3 Son

    useEffect(() => {

        const handleBeforeUnload = (e) => {
            try {
                if (/www\/(signin|signout)/i.test(window.location.href)) {
                    return;
                } else if (!isOperationsCompleted) {
                    e.preventDefault();
                    e.returnValue = 'reload / leave site?';
                }
            } catch (error) {
                console.error(error);
            }
        };

        if (step === 2 || step === 3) {
            window.addEventListener('beforeunload', handleBeforeUnload);
        }

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [step, isOperationsCompleted]);

    return (
        <>
            <div className="mt-5 lg:px-[10px] flex flex-col sm:flex-row gap-4 lg:gap-16 max-w-[1150px] mx-auto">
                <PageStep step={step} stepCount="3" />
                {step === 1 && (
                    <div className="w-full">
                        <p className="my-2 text-[14px] xl:h-[35px] xl:leading-[35px] py-2 xl:py-0 text-base-text bg-card px-5 rounded-md gap-2 flex flex-col xl:flex-row xl:justify-between">
                            <span className="font-bold">{title}</span>
                            {currentProduct && (
                                <span><span className="font-bold">{strings.member.member_detail.terms.operation_modal.current_package}:</span> {currentProduct.productName}</span>
                            )}
                        </p>
                        <div className="shadow-content border mt-5 rounded-[5px]">
                            <div className="bg-blue p-5 pb-[50px] rounded-t-[5px] flex flex-col gap-3">
                                <Dropdown
                                    classnames="rounded md:max-w-full"
                                    data={[firstProductCategory, ...productCategoryList]}
                                    selectedData={productCategory}
                                    setSelectedData={setProductCategory}
                                />
                                <div className="flex flex-col md:flex-row gap-3 md:gap-1">
                                    <Dropdown
                                        classnames="rounded md:max-w-full"
                                        dark={true}
                                        data={monthList}
                                        selectedData={month}
                                        setSelectedData={setMonth}
                                        isDisabled={productCategory.id === -1 && true}
                                    />
                                    <Dropdown
                                        classnames="rounded md:max-w-full"
                                        dark={true}
                                        data={dayOfWeekList}
                                        selectedData={dayOfWeek}
                                        setSelectedData={setDayOfWeek}
                                        isDisabled={(productCategory.id === -1 || month.id === -1) && true}
                                    />
                                    <Dropdown
                                        classnames="rounded md:max-w-full"
                                        dark={true}
                                        data={minuteList}
                                        selectedData={minute}
                                        setSelectedData={setMinute}
                                        isDisabled={(productCategory.id === -1 || month.id === -1 || dayOfWeek.id === -1) && true}
                                    />
                                </div>
                            </div>
                            <div className="relative -mt-8">
                                <div
                                    className="px-5 py-4 cursor-pointer text-dark-text bg-card rounded-t-md rounded-b-md flex justify-between items-center mx-5 shadow-paymentAccordion"
                                    onClick={() => {
                                        toggleTicketAccordion(accordionRef, plusSignRef, minusSignRef)
                                        setIsClick(isClick => true);
                                    }}                            >
                                    <div
                                        id="dropdown_section_01"
                                        className="relative flex flex-col gap-2"
                                    >
                                        <div className="flex items-center">
                                            <span className="material-symbols-outlined text-[28px] pr-4">
                                                tune
                                            </span>
                                            <p>{strings.member.member_detail.sales_operations.discount_campaign_apply}</p>
                                        </div>
                                    </div>
                                    <span
                                        ref={plusSignRef}
                                        className="material-symbols-outlined font-bold text-base cursor-pointer"
                                    >
                                        add
                                    </span>
                                    <span
                                        ref={minusSignRef}
                                        className="material-symbols-outlined font-bold text-base hidden cursor-pointer"
                                    >
                                        remove
                                    </span>
                                </div>
                                <div
                                    ref={accordionRef}
                                    className="max-h-0 mx-5 px-4 gap-x-4 gap-y-5 flex-wrap max-md-lg:justify-center border-[#ebebeb] rounded-b-md animate-fadeOut transition-max-height duration-500 shadow-paymentAccordion"
                                >
                                    <div className={`${!isClick ? 'hidden' : ''} flex flex-col md:flex-row gap-3 w-full`}>
                                        <Dropdown
                                            classnames="md:max-w-full"
                                            data={campaignList}
                                            selectedData={campaign}
                                            setSelectedData={setCampaign}
                                            isDisabled={(campaignList.length === 1 && campaignList[0].id === -1) ? true : false}
                                            tooltip={(campaignList.length === 1 && campaignList[0].id === -1 && dayOfWeek.id !== -1) ? strings.errors.campaign_not_found : ""}
                                        />
                                        <DropdownMultiSelect
                                            showChooseAll="notShow"
                                            title={strings.detail_search_titles.discounts}
                                            data={discountList || []}
                                            selectedData={discount}
                                            setSelectedData={setDiscount}
                                            randomId="discountListMS"
                                            classnames="md:!max-w-full"
                                            inputPlaceholder={strings.detail_search_titles.search_discount}
                                            isDisabled={discountList.length === 0 ? true : false}
                                            tooltip={(discountList.length === 0 && dayOfWeek.id !== -1) ? strings.errors.discount_not_found : ""}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="flex flex-col xl:flex-row xl:justify-between mx-5 items-center my-10 gap-10 xl:gap-2 ">
                                    <ul className="flex flex-col gap-3">
                                        <li className="flex gap-2 2xl:gap-3">
                                            <img className="w-5 h-5" src={AcceptLight} alt="" />
                                            <p className="text-[rgba(108,117,125,0.5)]">
                                                <span className="font-bold">{strings.member.member_detail.sales_operations.no_call_right}: {currentProduct?.missedRightCount}</span>
                                            </p>
                                        </li>
                                        <li className="flex gap-2 2xl:gap-3">
                                            <img className="w-5 h-5" src={AcceptLight} alt="" />
                                            <p className="text-[rgba(108,117,125,0.5)]">
                                                <span className="font-bold">{strings.member.member_detail.terms.postpone_lesson_right}: {currentProduct?.postponeRightCount}</span>
                                            </p>
                                        </li>
                                        <li className="flex gap-2 2xl:gap-3">
                                            <img className="w-5 h-5" src={AcceptLight} alt="" />
                                            <p className="text-[rgba(108,117,125,0.5)]">
                                                <span className="font-bold">{strings.member.member_detail.sales_operations.freeze_lesson_right}: {currentProduct?.freezeRightCount}</span>
                                            </p>
                                        </li>
                                        <li className="flex gap-2 2xl:gap-3">
                                            <img className="w-5 h-5" src={AcceptLight} alt="" />
                                            <p className="text-[rgba(108,117,125,0.5)]">
                                                <span className="font-bold">{strings.member.member_detail.terms.teacher_time_change_right}: {currentProduct?.teacherHourChangeRightCount}</span>
                                            </p>
                                        </li>
                                    </ul>
                                    <div className="min-w-max">
                                        <div className="w-[230px] h-[180px] flex flex-col justify-center items-center">
                                            <PriceShape color="rgba(21,114,232,0.5)" children={
                                                <div className="w-full h-full flex flex-col items-center justify-center text-center">
                                                    <p className="font-bold text-[rgba(21,114,232,0.5)] text-[31px]">
                                                        <span>{Object.keys(currentProduct).length > 0 ? currentProduct?.productPriceResultModel?.totalPrice + " " + currentProduct?.productPriceResultModel?.currencySymbol : "0.00"} </span>
                                                    </p>
                                                    <p className="font-bold text-[14px] text-[rgba(139,0,139,0.5)]">
                                                        % 0 {strings.member.member_detail.sales_operations.discount}
                                                    </p>
                                                </div>
                                            } />

                                        </div>
                                    </div>
                                    <ul className="flex flex-col gap-3 min-w-[242px]">
                                        <li className="flex gap-2 2xl:gap-3">
                                            <img className="w-5 h-5" src={AcceptLight} alt="" />
                                            <p className="text-[rgba(108,117,125,0.5)]">
                                                <span className="font-bold">{strings.member.member_detail.sales_operations.max_payment_option_count}: {currentProduct?.productPriceResultModel?.maxInstallment}</span>
                                            </p>
                                        </li>
                                        <li className="flex gap-2 2xl:gap-3">
                                            <img className="w-5 h-5" src={AcceptLight} alt="" />
                                            <p className="text-[rgba(108,117,125,0.5)]">
                                                <span className="font-bold">{strings.member.member_detail.sales_operations.discount_price}: {currentProduct?.productPriceResultModel?.priceWithTax} {currentProduct?.productPriceResultModel?.currencySymbol}</span>
                                            </p>
                                        </li>
                                        <li className="flex gap-2 2xl:gap-3">
                                            <img className="w-5 h-5" src={AcceptLight} alt="" />
                                            <p className="text-[rgba(108,117,125,0.5)]">
                                                <span className="font-bold">{strings.member.member_detail.sales_operations.no_discount_price}: {currentProduct?.productPriceResultModel?.discountPrice} {currentProduct?.productPriceResultModel?.currencySymbol}</span>
                                            </p>
                                        </li>
                                        <li className="flex gap-2 2xl:gap-3">
                                            <img className="w-5 h-5" src={AcceptLight} alt="" />
                                            <p className="text-[rgba(108,117,125,0.5)]">
                                                <span className="font-bold">{strings.member.member_detail.sales_operations.kdv}: {currentProduct?.productPriceResultModel?.taxPrice} {currentProduct?.productPriceResultModel?.currencySymbol}</span>
                                            </p>
                                        </li>
                                    </ul>
                                </div>

                                <ul className="max-w-max mx-auto -mt-3 flex flex-col gap-1">
                                    <li className='text-[rgba(73,80,87,0.5)]'> <span className="font-bold">{strings.member.member_detail.terms.previous_package_name}:</span> {currentProduct?.productName}</li>
                                    <li className='text-[rgba(73,80,87,0.5)]'> <span className="font-bold">{strings.member.member_detail.terms.previous_package_lesson_count}:</span> {currentProduct?.totalLessonCount}</li>
                                    <li className='text-[rgba(73,80,87,0.5)]'> <span className="font-bold">{strings.member.member_detail.terms.previous_package_used_lesson_count}:</span> {currentProduct?.attendedLessonCount}</li>
                                    <li className='text-[rgba(73,80,87,0.5)]'> <span className="font-bold">{strings.member.member_detail.terms.previous_package_missed_lesson_count}:</span> {currentProduct?.missedLessonCount}</li>
                                    <li className='text-[rgba(73,80,87,0.5)]'> <span className="font-bold">{strings.member.member_detail.terms.previous_package_remaining_lesson_count}:</span> {currentProduct?.remainingLessonCount}</li>
                                </ul>
                            </div>
                            {showProductDetail && (
                                <>
                                    <hr className='m-5' />
                                    <div className="flex flex-col xl:flex-row xl:justify-between mx-5 items-center my-10 gap-10 xl:gap-2">
                                        <ul className="flex flex-col gap-3">
                                            <li className="flex gap-2 2xl:gap-3">
                                                <img className="w-5 h-5" src={Accept} alt="" />
                                                <p className="text-[#6c757d]">
                                                    <span className="font-bold">{strings.member.member_detail.sales_operations.no_call_right}: {selectedProduct?.missedRightCount}</span>
                                                </p>
                                            </li>
                                            <li className="flex gap-2 2xl:gap-3">
                                                <img className="w-5 h-5" src={Accept} alt="" />
                                                <p className="text-[#6c757d]">
                                                    <span className="font-bold">{strings.member.member_detail.terms.postpone_lesson_right}: {selectedProduct?.postponeRightCount}</span>
                                                </p>
                                            </li>
                                            <li className="flex gap-2 2xl:gap-3">
                                                <img className="w-5 h-5" src={Accept} alt="" />
                                                <p className="text-[#6c757d]">
                                                    <span className="font-bold">{strings.member.member_detail.sales_operations.freeze_lesson_right}: {selectedProduct?.freezeRightCount}</span>
                                                </p>
                                            </li>
                                            <li className="flex gap-2 2xl:gap-3">
                                                <img className="w-5 h-5" src={Accept} alt="" />
                                                <p className="text-[#6c757d]">
                                                    <span className="font-bold">{strings.member.member_detail.terms.teacher_time_change_right}: {selectedProduct?.teacherHourChangeRightCount}</span>
                                                </p>
                                            </li>
                                        </ul>
                                        <div className="min-w-max">
                                            <div className="w-[230px] h-[180px] flex flex-col justify-center items-center">
                                                <PriceShape children={
                                                    <div className="w-full h-full flex flex-col items-center justify-center text-center">
                                                        <p className="font-bold text-blue text-[31px] relative">
                                                            <span>{Object.keys(selectedProduct).length > 0 ? selectedProductPrices.totalPrice + " " + selectedProduct?.priceDetail?.currencySymbol : "0.00"} </span>
                                                            <span className="absolute inset-0 w-full h-0.5 bg-blue top-1/2 transform -translate-y-1/2"></span>
                                                        </p>
                                                        <p className="font-bold text-blue text-[31px]">
                                                            <span>{priceToBePaid.price + " " + priceToBePaid.currencySymbol}</span>
                                                        </p>
                                                        <p className="font-bold text-[14px] text-[#8b008b]">
                                                            {/* % {Object.keys(selectedProduct).length > 0 ? selectedProduct.priceDetail?.discountRate : "0"} {strings.member.member_detail.sales_operations.discount} */}
                                                            % {Object.keys(selectedProduct).length > 0 ? (discountDiscountRate + campaignDiscountRate) > 100 ? 100 : (discountDiscountRate + campaignDiscountRate) : "0"} {strings.member.member_detail.sales_operations.discount}
                                                        </p>
                                                    </div>
                                                } />

                                            </div>
                                        </div>
                                        <ul className="flex flex-col gap-3 min-w-[242px]">
                                            <li className="flex gap-2 2xl:gap-3">
                                                <img className="w-5 h-5" src={Accept} alt="" />
                                                <p className="text-[#6c757d]">
                                                    <span className="font-bold">{strings.member.member_detail.sales_operations.max_payment_option_count}: {selectedProduct?.priceDetail?.maxInstallment}</span>
                                                </p>
                                            </li>
                                            <li className="flex gap-2 2xl:gap-3">
                                                <img className="w-5 h-5" src={Accept} alt="" />
                                                <p className="text-[#6c757d]">
                                                    <span className="font-bold">{strings.member.member_detail.sales_operations.discount_price}: {selectedProduct?.priceDetail?.priceWithTax} {selectedProduct?.priceDetail?.currencySymbol}</span>
                                                </p>
                                            </li>
                                            <li className="flex gap-2 2xl:gap-3">
                                                <img className="w-5 h-5" src={Accept} alt="" />
                                                <p className="text-[#6c757d]">
                                                    <span className="font-bold">{strings.member.member_detail.sales_operations.no_discount_price}: {selectedProductPrices?.discountPrice} {selectedProduct?.priceDetail?.currencySymbol}</span>
                                                </p>
                                            </li>
                                            <li className="flex gap-2 2xl:gap-3">
                                                <img className="w-5 h-5" src={Accept} alt="" />
                                                <p className="text-[#6c757d]">
                                                    <span className="font-bold">{strings.member.member_detail.sales_operations.kdv}: {selectedProductPrices?.taxPrice} {selectedProduct?.priceDetail?.currencySymbol}</span>
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                    {priceToBePaid.priceWithoutFormat > 0 &&
                                        <ul className="max-w-max mx-auto -mt-5 mb-3 flex flex-col gap-1">
                                        <li className='font-bold text-[16px]'>
                                            {strings.member.member_detail.sales_operations.max_installment_title
                                                .replace("#price#", formatPrice((priceToBePaid.priceWithoutFormat / selectedProduct?.priceDetail?.maxInstallment), selectedProduct?.priceDetail?.currencySymbol))
                                                .replace("#maxInstallment#", selectedProduct?.priceDetail?.maxInstallment)
                                            }
                                        </li>
                                        </ul>
                                    }
                                    <ul className={classNames("max-w-max mx-auto flex flex-col gap-1", {
                                        "mb-3": priceToBePaid.priceWithoutDiscount === priceToBePaid.price
                                    })}>
                                        <li> <span className="font-bold">{strings.member.member_detail.terms.new_package_name}:</span> {selectedProduct?.productName}</li>
                                        <li> <span className="font-bold">{strings.member.member_detail.terms.new_package_lesson_count}:</span> {selectedProduct?.productPriceDifference?.totalLessonCount}</li>
                                    </ul>
                                    {priceToBePaid.priceWithoutDiscount !== priceToBePaid.price && (
                                        <>
                                            <hr className='mx-5 mt-4' />
                                            <div className='flex mx-5 py-4 pr-3'>
                                                <p className='font-bold text-end w-full'>{strings.member.member_detail.terms.operation_modal.package_price_difference}:</p>
                                                <p className='w-[120px] text-end'>{priceToBePaid.priceWithoutDiscount + " " + priceToBePaid.currencySymbol}</p>
                                            </div>
                                        </>
                                    )}
                                    <hr className='mx-5' />
                                    <div className='flex mx-5 py-4 bg-[#f0f0f0] pr-3'>
                                        <p className='font-bold text-end w-full'>{strings.table.total_payment}:</p>
                                        <p className='w-[120px] text-end'>{priceToBePaid.price + " " + priceToBePaid.currencySymbol}</p>
                                    </div>
                                    <hr className='mx-5' />
                                </>
                            )}

                            {
                                !(productCategory.id !== -1 && month.id !== -1 && dayOfWeek.id !== -1 && minute.id !== -1) || priceToBePaid.priceWithoutFormat <= 0 && (
                                    <p className="text-[#ff0000] mx-auto w-[calc(100%-40px)] mt-3">{strings.member.member_detail.terms.operation_modal.minus_package_price_error}</p>
                                )
                            }
                            <button
                                className={classNames('button gap-3 font-bold text-blue border-blue mb-7 mt-3 rounded mx-auto w-[calc(100%-40px)] bg-blue/20 transition-colors duration-500', { 'hover:!bg-blue !bg-white hover:text-white focus:text-white focus:!bg-blue': ((productCategory.id !== -1 && month.id !== -1 && dayOfWeek.id !== -1 && minute.id !== -1) || priceToBePaid.priceWithoutFormat) && priceToBePaid.priceWithoutFormat >= 0 })}
                                disabled={!(productCategory.id !== -1 && month.id !== -1 && dayOfWeek.id !== -1 && minute.id !== -1) || priceToBePaid.priceWithoutFormat <= 0 }
                                onClick={() => setStep(2)}
                            >
                                {strings.button.pay}
                            </button>
                        </div>
                    </div>
                )}
                {step === 2 && (
                    <>
                        <div className="w-full relative sm:w-[calc(100%-114px)]">
                            {(!completePaymentCreditCardLoading || !completePaymentTransferLoading) &&
                                <button
                                    className="absolute top-[-90px] sm:top-[14px] right-4 flex items-center hover:text-blue focus:text-blue"
                                    onClick={() => setStep(1)}
                                >
                                    <span id="back" className="material-symbols-outlined cursor-pointer">
                                        arrow_back_ios
                                    </span>
                                    <label className="cursor-pointer font-bold"
                                           htmlFor="back">{strings.pagination.previous}</label>
                                </button>
                            }
                            {/* <h3 className="font-bold text-[19px]">{strings.member.member_detail.sales_operations.payment_operations}</h3> */}
                            <PageTitle title={strings.member.member_detail.sales_operations.payment_operations}/>
                            <ul id="tabs" className="flex gap-x-5 sm:gap-x-10 flex-wrap">
                                <li className={classNames('member-nav-button', {'member-nav-button-active': tab === 0})}>
                                    <button
                                        onClick={() => setTab(0)}>{strings.member.member_detail.sales_operations.credit_card}</button>
                                </li>
                                <li className={classNames('member-nav-button', {'member-nav-button-active': tab === 1})}>
                                    <button
                                        onClick={() => setTab(1)}>{strings.member.member_detail.sales_operations.transfer}</button>
                                </li>
                                {/*
                            TODO: KREDİ EKRANLARI EKLENİNCE AÇILACAK
                            //KREDİ ÖDEMELERİ BU BÖLÜMDEN YAPILIYOR.
                            //BU BÖLÜM HER ŞEY YAYINA GİRDİKTEN SONRA ÖMER İLE ÇALIŞILARAK YAPILACAK

                            <li className={classNames('member-nav-button', { 'member-nav-button-active': tab === 2 })}>
                                <button onClick={() => setTab(2)}>{strings.member.member_detail.sales_operations.shopping_credit}</button>
                            </li>
                            */}
                            </ul>

                            <input
                                id="secure-payment-input"
                                ref={hiddenInputRef}
                                className="hidden"
                            />

                            <div id="tab-contents">
                                {tab === 0 && (
                                    <CreditCard
                                        cardNumber={cardNumber}
                                        cardholderName={cardholderName}
                                        cardExpiration={cardExpiration}
                                        cardCvv={cardCvv}
                                        setCardNumber={setCardNumber}
                                        setCardholderName={setCardholderName}
                                        setCardExpiration={setCardExpiration}
                                        setCardCvv={setCardCvv}
                                        setBank={setBank}
                                        setBankName={setBankName}
                                        bankPosList={bankPosList}
                                        setIsOneShot={setIsOneShot}
                                        setIsForeignCard={setIsForeignCard}
                                    />
                                )}
                            </div>
                            <div className="flex flex-col gap-5 mt-5">
                                <div className="flex items-center gap-2">
                                    <p className="text-[22px]">{priceToBePaid.price !== "" ? priceToBePaid.price + " " + priceToBePaid.currencySymbol : "0.00"}</p>
                                    <div className="group relative flex">
                                        <span className="material-symbols-outlined cursor-pointer">
                                            info
                                        </span>
                                        <div className="tooltip-top">
                                            <p>{strings.member.member_detail.sales_operations.kdv_include}</p>
                                        </div>
                                    </div>
                                </div>
                                {tab === 2 && (
                                    <div id="shopping_credit" className="relative">
                                        <Accordion
                                            outSideClose={true}
                                            title="Fibabank"
                                            button={
                                                <LargeButton
                                                    iconName="done"
                                                    iconClassnames="bg-green border-green"
                                                    iconText={strings.member.member_detail.sales_operations.complete_order}
                                                />
                                            }
                                            content={
                                                <>
                                                    <div
                                                        className="w-full overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
                                                        <table className="min-w-full leading-normal text-header">
                                                            <thead>
                                                            <tr>
                                                                <th className="th text-center"><p
                                                                    className="min-w-max">{strings.member.member_detail.sales_operations.payment_option}</p>
                                                                </th>
                                                                <th className="th text-center"><p
                                                                    className="min-w-max">{strings.table.monthly_income} (₺)</p>
                                                                </th>
                                                                <th className="th text-center"><p
                                                                    className="min-w-max">{strings.table.monthly_total_cost_rate}</p>
                                                                </th>
                                                                <th className="th text-center"><p
                                                                    className="min-w-max">{strings.table.yearly_total_cost_rate}</p>
                                                                </th>
                                                                <th className="th text-center"><p
                                                                    className="min-w-max">{strings.table.total_payment} (₺)</p>
                                                                </th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr className="bg-white hover:bg-[#f0f0f0]">
                                                                <td className="td text-center">10</td>
                                                                <td className="td text-center">10</td>
                                                                <td className="td text-center">10</td>
                                                                <td className="td text-center">10</td>
                                                                <td className="td text-center">10</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div id='show-button'></div>

                                                </>
                                            }
                                        />
                                    </div>
                                )}
                                {/*

                            //BU BÖLÜM TEKRARLI ÖDEME YAPILABİLMESİ İÇİN KULLANILIYOR.
                            //İLK YAYINDAN SONRA ÖMER İLE ÇALIŞARAK BURASI DÜZENLENİLMELİ
                            TODO:TEKRARLI ÖDEME

                            tab === 0 && (
                                <label className="flex justify-start items-start max-w-max">
                                <input
                                    id="reccuring_payment"
                                    type="checkbox"
                                    className="hidden"
                                    onClick={() => setIsRecurringPayment(isRecurringPayment => !isRecurringPayment)}
                                />
                                <label
                                    htmlFor="reccuring_payment"
                                    className={`${handleCheckbox(isRecurringPayment)} mr-2 w-5 h-5 flex-shrink-0 rounded-[3px] cursor-pointer duration-500 flex items-center justify-center`}
                                >
                                    <span className={classNames('text-inherit text-[20px] material-symbols-outlined animate-fadeIn font-bold', { 'hidden': !isRecurringPayment })}>
                                    done
                                    </span>
                                </label>
                                <p className="font-bold">{strings.member.member_detail.sales_operations.recurring_payment}</p>
                            </label>
                            )*/}
                                {tab === 1 && (
                                    <div className="flex flex-col gap-1">
                                        <p className="font-bold">{strings.member.member_detail.sales_operations.bank_options}</p>
                                        <div className="flex gap-y-1 flex-wrap">
                                            {/* Eğer havale bölümü seçiliyse banka listesi veriliyor */}
                                            {bankInfoList && bankInfoList.map((bankInfo, index) => (
                                                <button key={index}
                                                        className={classNames('bank-container', {'border-blue': bankInfo.bankPosId === bank})}
                                                        onClick={() => setBank(bankInfo.bankPosId)}>
                                                    <div className="group relative flex">
                                                        <img src={process.env.REACT_APP_PROFILE_URL + bankInfo.image}
                                                             className="cursor-pointer h-full"/>
                                                        <div
                                                            className="tooltip-top -top-[60px]">{primeData.localCompanyList.find(lc => lc.id === bankInfo.brandLocalCompanyId).name}</div>
                                                    </div>
                                                </button>
                                            ))}
                                            {/* Eğer havale bölümü seçiliyse pos listesi veriliyor */}
                                        </div>
                                    </div>
                                )}
                                {tab === 0 && (
                                    Object.keys(selectedProduct).length > 0 &&
                                    <Installment
                                        setState={setPaymentOption}
                                        state={paymentOption}
                                        totalPrice={priceToBePaid?.price}
                                        totalPriceWithoutFormat={priceToBePaid?.priceWithoutFormat}
                                        currencySymbol={priceToBePaid?.currencySymbol}
                                        bankPosId={bank}
                                        isOneShot={isOneShot}
                                    />
                                )}
                                {tab === 0 && (
                                    <button
                                        className={`${(isCompletePayment || completePaymentCreditCardLoading || !cardNumber || !cardholderName || !cardExpiration || !cardCvv || bank === null ? true : isCompletePayment) ? 'bg-blue/20 hover:!bg-blue/20 hover:!text-blue' : ''} hover:bg-blue hover:text-white focus:text-white focus:bg-blue button gap-3 font-bold text-blue border-blue my-5 rounded mx-auto w-full transition-colors duration-500`}
                                        disabled={isCompletePayment || completePaymentCreditCardLoading || !cardNumber || !cardholderName || !cardExpiration || !cardCvv || bank === null ? true : isCompletePayment}
                                        onClick={handlePayPackage}
                                    >
                                        {completePaymentCreditCardLoading && (
                                            <Loading
                                                text={strings.processing_upper}
                                                className="mt-0"
                                            />
                                        )}

                                        {!completePaymentCreditCardLoading && strings.button.complete_payment}
                                    </button>
                                )}
                                {tab === 1 && (
                                    <button
                                        className={`${(bank === null || completePaymentTransferLoading) ? 'bg-blue/20 hover:!bg-blue/20 hover:!text-blue' : isCompletePayment} hover:bg-blue hover:text-white focus:text-white focus:bg-blue button gap-3 font-bold text-blue border-blue my-5 rounded mx-auto w-full transition-colors duration-500`}
                                        disabled={(bank === null || completePaymentTransferLoading) ? true : isCompletePayment}
                                        onClick={payPackageByTransfer}
                                    >
                                        {completePaymentTransferLoading && (
                                            <Loading
                                                text={strings.processing_upper}
                                                className="mt-0"
                                            />
                                        )}

                                        {!completePaymentTransferLoading && strings.button.complete_payment}
                                    </button>
                                )}
                            </div>
                        </div>

                        {(Object.keys(modalContent).length !== 0 && (
                            <Modal modal={modal} setModal={setModal} classes={{modal: "h-[600px] max-w-[600px]"}} closable={false} >
                                {modalContent.element === "3dModal" &&
                                    <iframe
                                        id="secure-payment-iframe"
                                        src={`${process.env.REACT_APP_SECURE_PAYMENT_URL}${securePaymentBase64Content}`}
                                        className="w-full h-[550px]"
                                    />
                                }
                            </Modal>
                        ))}
                    </>
                )}
                {step === 3 && (
                    <div className="w-full">
                        {/* <h3 className="font-bold text-[19px]">{strings.table.person_informations}</h3> */}
                        <PageTitle title={strings.table.person_informations} />
                        {currentCardList.length > 0 && (
                            <div className="flex flex-row w-full gap-4">
                                <Dropdown
                                    classnames="md:max-w-full"
                                    data={currentCardList}
                                    selectedData={selectedCurrentCard}
                                    setSelectedData={setSelectedCurrentCard}
                                />
                                {selectedCurrentCard.id === -1 && <button
                                    className="button search-button text-white bg-blue border-blue mx-auto w-[250px]"
                                    type="button"
                                    onClick={() => setAddNewCurrentCard(true)}>
                                    {strings.member.member_detail.accounting.add_new_current_card}
                                </button>}
                            </div>)}
                        {selectedCurrentCard.id === -1 && addNewCurrentCard && <form onSubmit={submitInformationHandle}>
                            <div className={`flex flex-col gap-4 mt-5`}>

                                <div className="flex flex-col px-[10px] pb-0 w-full">
                                    <label htmlFor="invoice_type">{strings.form_info.invoice_type}</label>
                                    <Dropdown
                                        classnames="md:!max-w-full"
                                        data={invoiceTypeList}
                                        selectedData={invoiceType}
                                        setSelectedData={setInvoiceType}
                                    />
                                    <span
                                        ref={invoiceTypeErrorRef}
                                        className="text-danger field-validation-valid px-[10px]"
                                    >
                                    </span>
                                </div>

                                {invoiceType.id !== -1 && (<>

                                    <div className="flex flex-col px-[10px] pb-0 w-full">
                                        <label htmlFor="firm_name">{invoiceType.id === 0 ? strings.form_info.full_name : strings.form_info.company_name}</label>
                                        <Input
                                            id="firm_name"
                                            value={invoiceType.id === 0 ? fullName : ""}
                                            onChange={(e) => setFullName(e.target.value)}
                                        />
                                        <span
                                            ref={fullNameErrorRef}
                                            className="text-danger field-validation-valid px-[10px]"
                                        >
                                        </span>
                                    </div>

                                    <div className="flex flex-col px-[10px] pb-0 w-full">
                                        <label htmlFor="member_email">{strings.form_info.email}</label>
                                        <Input
                                            id="member_email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        <span
                                            ref={emailErrorRef}
                                            className="text-danger field-validation-valid px-[10px]"
                                        >
                                        </span>
                                    </div>

                                    <div className="flex flex-col md:flex-row text-start gap-4 w-full">
                                        {invoiceType.id === 1 && (<div className="flex flex-col px-[10px] pb-0 w-full">
                                            <label htmlFor="tax_office">{strings.form_info.tax_office}</label>
                                            <Input
                                                id="tax_office"
                                                onChange={(e) => setTaxOffice(e.target.value)}
                                            />
                                            <span
                                                ref={taxOfficeErrorRef}
                                                className="text-danger field-validation-valid px-[10px]"
                                            >
                                            </span>
                                        </div>)}
                                        <div className="flex flex-col px-[10px] pb-0 w-full">
                                            <label htmlFor="tax_number">{invoiceType.id === 0 ? strings.form_info.identity_number : strings.form_info.tax_number}</label>
                                            <Input
                                                id="tax_number"
                                                onChange={(e) => setTaxNumber(e.target.value)}
                                            />
                                            <span
                                                ref={taxNumberErrorRef}
                                                className="text-danger field-validation-valid px-[10px]"
                                            >
                                            </span>
                                        </div>
                                    </div>

                                    <div className="flex flex-col md:flex-row text-start gap-4 w-full">
                                        <div className="flex flex-col px-[10px] pb-0 w-full">
                                            <label htmlFor="member_address">{strings.table.address}</label>
                                            <textarea
                                                id="member_address"
                                                type="text"
                                                className="border rounded-lg outline-0 focus:border-blue py-3 px-2 w-full"
                                                rows="2"
                                                onChange={(e) => setAddress(e.target.value)}
                                            />
                                            <span
                                                ref={addressErrorRef}
                                                className="text-danger field-validation-valid px-[10px]"
                                            >
                                            </span>
                                        </div>
                                        <div className="flex flex-col px-[10px] pb-0  w-2/6">
                                            <label className="min-w-max" htmlFor="is_abroad">{strings.form_info.is_abroad_taxpayer}</label>
                                            <div className="h-[45px] flex justify-start items-center px-2">
                                                <input
                                                    type="checkbox"
                                                    id="isActive1"
                                                    className="hidden"
                                                    onClick={() => setIsAbroad(isAbroad => !isAbroad)}
                                                />
                                                <label
                                                    htmlFor="isActive1"
                                                    className={`${handleCheckbox(isAbroad)} mr-2 w-5 h-5 rounded-[3px] cursor-pointer duration-500 flex items-center justify-center`}
                                                >
                                                    <span className={classNames('text-inherit text-[20px] material-symbols-outlined animate-fadeIn font-bold', { 'hidden': !isAbroad })}>
                                                        done
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                </>)}

                            </div>
                            {invoiceType.id !== -1 && (<div className="flex gap-3 justify-center">
                                <button
                                    type="submit"
                                    disabled={waitCreatePersonalInformation}
                                    className={`${waitCreatePersonalInformation ? 'bg-blue/20 hover:!bg-blue/20 hover:!text-blue' : ''} button gap-3 font-bold text-blue border-blue my-5 rounded mx-auto w-[calc(100%-40px)] transition-colors duration-500 hover:bg-blue hover:text-white focus:text-white focus:bg-blue`}
                                >
                                    {strings.button.save_and_complete_upper}
                                </button>

                            </div>)}
                        </form>}

                        {selectedCurrentCard.id !== -1 && <div className="flex gap-3 justify-center">
                            <button
                                type="button"
                                disabled={waitCreatePersonalInformation}
                                className={`${waitCreatePersonalInformation ? 'bg-blue/20 hover:!bg-blue/20 hover:!text-blue' : ''} button gap-3 font-bold text-blue border-blue my-5 rounded mx-auto w-[calc(100%-40px)] transition-colors duration-500 hover:bg-blue hover:text-white focus:text-white focus:bg-blue`}
                                onClick={submitInformationWithoutHandle}
                            >
                                {strings.button.save_and_complete_upper}
                            </button>
                        </div>}

                    </div>
                )}
            </div>
        </>
    )
};

export default ChangeTerm;