import {RadioGroup} from '@headlessui/react'
import classNames from "classnames";

const Radio = (props) => {

    const { state, setState, data } = props;

    const CheckIcon = (props) => {
        return (
            <svg viewBox="0 0 24 24" fill="none" {...props}>
                <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2"/>
                <path
                    d="M7 13l3 3 7-7"
                    stroke="#fff"
                    strokeWidth={1.5}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )
    }

    return (
        <div className="w-full px-4 py-5">
            <div className="mx-auto w-full max-w-md">
                <RadioGroup value={state} onChange={setState}>
                    <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
                    <div className="space-y-2">
                        {data.map((item) => (
                            <RadioGroup.Option
                                key={item.id}
                                value={item.id}
                                className={({active, checked}) =>
                                    classNames("relative outline-none flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none", {
                                        "bg-blue text-white" : checked,
                                        "bg-white": !checked
                                    })
                                }
                            >
                                {({checked}) => (
                                    <>
                                        <div className="flex w-full items-center justify-between">
                                            <div className="flex items-center">
                                                <div className="text-sm">
                                                    <RadioGroup.Label
                                                        as="p"
                                                        className={classNames("font-medium", {
                                                            "text-white" : checked,
                                                            "text-gray-900": !checked
                                                        })}
                                                    >
                                                        {item.name}
                                                    </RadioGroup.Label>
                                                    {/*<RadioGroup.Description*/}
                                                    {/*    as="span"*/}
                                                    {/*    className={classNames("", {*/}
                                                    {/*        "text-sky-100" : checked,*/}
                                                    {/*        "text-gray-500": !checked*/}
                                                    {/*    })}*/}
                                                    {/*>*/}
                                                    {/*    <span>*/}
                                                    {/*      {plan.ram}/{plan.cpus}*/}
                                                    {/*    </span>{' '}*/}
                                                    {/*    <span aria-hidden="true">&middot;</span>{' '}*/}
                                                    {/*    <span>{plan.disk}</span>*/}
                                                    {/*</RadioGroup.Description>*/}
                                                </div>
                                            </div>
                                            {checked && (
                                                <div className="shrink-0 text-white">
                                                    <CheckIcon className="w-6 h-6" />
                                                </div>
                                            )}
                                        </div>
                                    </>
                                )}
                            </RadioGroup.Option>
                        ))}
                    </div>
                </RadioGroup>
            </div>
        </div>
    )
}

export default Radio;
