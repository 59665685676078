import {AllProgramTableRow} from "../index";
import React, {useState} from "react";
import {useLocalization} from "../../../../hooks/Utils/useLocalization";
import log from "../../../Member/components/MemberDetail/Operations/Log/Log";
import useAuth from "../../../../hooks/Log/useAuth";
import {addTimeZone} from "../../../../components/Utility";
import classNames from "classnames";

const AllProgramTable = ({allProgramData}) => {

    const { employee } = useAuth();

    const [focusedTeacher, setFocusedTeacher] = useState(null);

    const LessonStatuses = Object.freeze(
        {
            "Empty": 0,
            "Lesson": 1,
            "TempOrBreak": 2
        }
    )

    const handleInstructorClick = (teacherId) => {
        if (focusedTeacher === teacherId) {
            setFocusedTeacher(null);
        } else {
            setFocusedTeacher(teacherId);
        }
    };

    const maxHours = Math.max(...allProgramData.map(item => item.scheduleInformations.length));

    return (
        <div className="mx-auto">
            <div className="overflow-x-auto">
                <div className="max-h-[500px] 2xl:max-h-[600px] overflow-y-scroll overflow-x-scroll">
                    <table className="table-fixed">
                        <thead className="sticky top-0 bg-white">
                            <tr>
                                {allProgramData.map((item, index) => (
                                    <th
                                        key={index}
                                        className={classNames("w-[125px] text-center cursor-pointer align-bottom border-r", {
                                            "bg-male": item.gender === 0,
                                            "bg-female": item.gender === 1,
                                        })}
                                        onClick={() => handleInstructorClick(item.teacherId)}
                                    >
                                        <p className="flex justify-center items-center p-1 h-[50px] text-white">{item.teacherName}</p>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                        {[...Array(maxHours)].map((_, hourIndex) => (
                            <tr key={hourIndex} className="">
                                {allProgramData?.map((item, index) => (
                                    <td
                                        key={index}
                                        className={classNames("p-2 text-center border", {
                                            "bg-light-gray": (focusedTeacher === item.teacherId || !item?.scheduleInformations[hourIndex]?.time),
                                            "border-t-0": hourIndex === 0,
                                        })}
                                    >
                                        {/*
                                            * Öğrencinin dersi varsa tik işareti,
                                                         dersi yoksa çarpı işareti,
                                                         dersi boşsa takvim işareti koyuluyor.
                                            * Öğrencinin dersi varsa ders günleri yanına yazılıyor.
                                        */}
                                        <div className={classNames("flex justify-between",
                                        //     {
                                        //     "justify-center ml-0.5" : item?.scheduleInformations[hourIndex]?.lessonStatus === LessonStatuses.Empty,
                                        //     "justify-between" : item?.scheduleInformations[hourIndex]?.lessonStatus !== LessonStatuses.Empty,
                                        // }
                                        )}>
                                            {(item?.scheduleInformations[hourIndex]?.lessonStatus === LessonStatuses.TempOrBreak || item?.scheduleInformations[hourIndex]?.lessonStatus === LessonStatuses.Empty) && (
                                                <span className="w-[18px]"></span>
                                            )}
                                            {item?.scheduleInformations[hourIndex]?.lessonStatus === LessonStatuses.Lesson && (
                                                <span
                                                    className={classNames("rounded-full w-[18px] h-[18px] flex justify-center items-center text-white text-[12px]", {
                                                        "bg-green": item?.scheduleInformations[hourIndex]?.totalFilledLessonDateCount === 5,
                                                        "bg-warning": item?.scheduleInformations[hourIndex]?.totalFilledLessonDateCount === 3,
                                                        "bg-[#8CABFF]": item?.scheduleInformations[hourIndex]?.totalFilledLessonDateCount === 2,
                                                    })}
                                                >
                                                    {item?.scheduleInformations[hourIndex]?.totalFilledLessonDateCount}
                                                </span>
                                            )}
                                            {item?.scheduleInformations[hourIndex]?.time && addTimeZone(item?.scheduleInformations[hourIndex]?.time, employee.TimeZone)}
                                            {item?.scheduleInformations[hourIndex]?.lessonStatus === LessonStatuses.Lesson && (
                                                <span className="material-symbols-outlined text-red font-bold text-[16px]">close</span>
                                            )}
                                            {item?.scheduleInformations[hourIndex]?.lessonStatus === LessonStatuses.TempOrBreak && (
                                                <span className="material-symbols-outlined font-bold text-danger text-[16px]">block</span>
                                            )}
                                            {item?.scheduleInformations[hourIndex]?.lessonStatus === LessonStatuses.Empty && (
                                                <span className="material-symbols-outlined text-green font-bold text-[16px]">check</span>
                                            )}
                                        </div>
                                    </td>
                                ))}
                            </tr>
                        ))}

                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    )

}

export default AllProgramTable;