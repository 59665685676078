import React from "react";
import { useSelector } from "react-redux";
import { NoRecordsFound } from "../../../../../../components";
import { addTimeZoneToFullDate, isItSpecified } from "../../../../../../components/Utility";
import useAuth from "../../../../../../hooks/Log/useAuth";
import { useLocalization } from "../../../../../../hooks/Utils/useLocalization";

const TaskNotesTable = ({ data }) => {
  const strings = useLocalization();
  const { employee } = useAuth();

  const language = useSelector(state => state.localizationStore.language);

  const getTaskTitleTypeById = (taskTitleTypeId, language) => {
      switch (taskTitleTypeId) {
          case 1:
              return {
                  tr: "Yedek Eğitmen",
                  en: "Yedek Eğitmen",
                  ar: "Yedek Eğitmen",
                  az: "Yedek Eğitmen"
              }[language];
          case 2:
              return {
                  tr: "Seviye Testine Katılmadı",
                  en: "Seviye Testine Katılmadı",
                  ar: "Seviye Testine Katılmadı",
                  az: "Seviye Testine Katılmadı"
              }[language];
          case 3:
              return {
                  tr: "Başlangıç Araması",
                  en: "Başlangıç Araması",
                  ar: "Başlangıç Araması",
                  az: "Başlangıç Araması"
              }[language];
          case 4:
              return {
                  tr: "Tanışma Araması",
                  en: "Tanışma Araması",
                  ar: "Tanışma Araması",
                  az: "Tanışma Araması"
              }[language];
          case 5:
              return {
                  tr: "Paket Değişikliği Araması",
                  en: "Paket Değişikliği Araması",
                  ar: "Paket Değişikliği Araması",
                  az: "Paket Değişikliği Araması"
              }[language];
          case 6:
              return {
                  tr: "Ara Değerlendirme Araması",
                  en: "Ara Değerlendirme Araması",
                  ar: "Ara Değerlendirme Araması",
                  az: "Ara Değerlendirme Araması"
              }[language];
          case 7:
              return {
                  tr: "Ders Araması İşlemleri",
                  en: "Ders Araması İşlemleri",
                  ar: "Ders Araması İşlemleri",
                  az: "Ders Araması İşlemleri"
              }[language];
          case 8:
              return {
                  tr: "İlk Derse Katılmadı",
                  en: "İlk Derse Katılmadı",
                  ar: "İlk Derse Katılmadı",
                  az: "İlk Derse Katılmadı"
              }[language];
          case 9:
              return {
                  tr: "Optimizasyon Araması",
                  en: "Optimizasyon Araması",
                  ar: "Optimizasyon Araması",
                  az: "Optimizasyon Araması"
              }[language];
          case 10:
              return {
                  tr: "Takip Araması",
                  en: "Takip Araması",
                  ar: "Takip Araması",
                  az: "Takip Araması"
              }[language];
          case 11:
              return {
                  tr: "Ünite Güncelleme",
                  en: "Ünite Güncelleme",
                  ar: "Ünite Güncelleme",
                  az: "Ünite Güncelleme"
              }[language];
          case 12:
              return {
                  tr: "Uzatma Araması",
                  en: "Uzatma Araması",
                  ar: "Uzatma Araması",
                  az: "Uzatma Araması"
              }[language];
          case 13:
              return {
                  tr: "Ek Satış Araması",
                  en: "Ek Satış Araması",
                  ar: "Ek Satış Araması",
                  az: "Ek Satış Araması"
              }[language];
          case 14:
              return {
                  tr: "Kapanış Araması",
                  en: "Kapanış Araması",
                  ar: "Kapanış Araması",
                  az: "Kapanış Araması"
              }[language];
          case 15:
              return {
                  tr: "Yeniden Dene Araması",
                  en: "Yeniden Dene Araması",
                  ar: "Yeniden Dene Araması",
                  az: "Yeniden Dene Araması"
              }[language];
          case 16:
              return {
                  tr: "Gelişim Araması",
                  en: "Gelişim Araması",
                  ar: "Gelişim Araması",
                  az: "Gelişim Araması"
              }[language];
          case 17:
              return {
                  tr: "Referans Araması",
                  en: "Referans Araması",
                  ar: "Referans Araması",
                  az: "Referans Araması"
              }[language];
          case 18:
              return {
                  tr: "Referans Ver İade Al Araması",
                  en: "Referans Ver İade Al Araması",
                  ar: "Referans Ver İade Al Araması",
                  az: "Referans Ver İade Al Araması"
              }[language];
          case 19:
              return {
                  tr: "Referans Ver İade Al Hatırlatma Araması",
                  en: "Referans Ver İade Al Hatırlatma Araması",
                  ar: "Referans Ver İade Al Hatırlatma Araması",
                  az: "Referans Ver İade Al Hatırlatma Araması"
              }[language];
          case 20:
              return {
                  tr: "Gelecek Dönem Kontrol",
                  en: "Gelecek Dönem Kontrol",
                  ar: "Gelecek Dönem Kontrol",
                  az: "Gelecek Dönem Kontrol"
              }[language];
          case 21:
              return {
                  tr: "Ders Dondurma Kontrol",
                  en: "Ders Dondurma Kontrol",
                  ar: "Ders Dondurma Kontrol",
                  az: "Ders Dondurma Kontrol"
              }[language];
          case 22:
              return {
                  tr: "Landing Page Hesap Kontrol",
                  en: "Landing Page Hesap Kontrol",
                  ar: "Landing Page Hesap Kontrol",
                  az: "Landing Page Hesap Kontrol"
              }[language];
          case 23:
              return {
                  tr: "Paket Değişikliği Dönem Kontrol",
                  en: "Paket Değişikliği Dönem Kontrol",
                  ar: "Paket Değişikliği Dönem Kontrol",
                  az: "Paket Değişikliği Dönem Kontrol"
              }[language];
      }
  }

  return (
    <table className="min-w-full leading-normal text-header">
      <thead>
        <tr>
          <th className="th"><p className="min-w-max">{strings.table.task_title}</p></th>
          <th className="th"><p className="min-w-max">{strings.table.task_message}</p></th>
          <th className="th"><p className="min-w-max">{strings.table.operation_person}</p></th>
          <th className="th"><p className="min-w-max">{strings.table.note_date}</p></th>
          <th className="th"><p className="min-w-max">{strings.table.task_due_date}</p></th>
          <th className="th"><p className="min-w-max">{strings.table.operation_date}</p></th>
        </tr>
      </thead>
      <tbody>
        {data.length > 0 ? (
          data.map((task, index) => (
              <tr key={index} className="bg-white hover:bg-[#f0f0f0]">
                  <td className="td h-[60px] align-middle">
                      <p className="min-w-max">{getTaskTitleTypeById(task.taskTitleType, language)}</p>
                  </td>
                  <td className="td h-[60px] align-middle">
                      <p className="max-w-max">{isItSpecified(task.taskMessage, strings.errors.it_is_not_specified)}</p>
                  </td>
                  <td className="td h-[60px] align-middle">
                      <p className="min-w-max">{task.employeeName}</p>
                  </td>
                  <td className="td h-[60px] align-middle">
                      <p className="min-w-max">{addTimeZoneToFullDate(task?.taskNoteDate, employee.TimeZone)}</p>
                  </td>
                  <td className="td h-[60px] align-middle">
                      <p className="min-w-max">{addTimeZoneToFullDate(task.taskDate, employee.TimeZone)}</p>
                  </td>
                  <td className="td h-[60px] align-middle">
                      <p className="min-w-max">{addTimeZoneToFullDate(task.updateDate, employee.TimeZone)}</p>
                  </td>
              </tr>
          ))
        ) : (
            <tr className="bg-white hover:bg-[#f0f0f0]">
                <td className="td" colSpan={6}>
                    <NoRecordsFound />
                </td>
            </tr>
        )}
      </tbody>
    </table>
  );
};

export default TaskNotesTable;
