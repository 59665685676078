import React from "react";
import { url } from "../../../routes/utility";
import { NavLink, useLocation } from "react-router-dom";
import { useLocalization } from "../../../hooks/Utils/useLocalization";
import { MemberOperationItems } from "../../../consts/menu/SidebarMenu";
import classNames from "classnames";
import useMobileView from "../../../hooks/Utils/useMobileView";

const MemberOperation = () => {

  const strings = useLocalization();
  const location = useLocation();
  const path = location?.pathname;
  const { isMobileView } = useMobileView();

  const MEMBER_OPERATION_ITEMS = MemberOperationItems(strings);

  return (
    <>
      <div className="header text-section">
        <h4>{strings.sidebar.member.title}</h4>
        <span className="material-symbols-outlined ml-1 font-bold hidden">
          more_horiz
        </span>
      </div>

      {MEMBER_OPERATION_ITEMS.map((item, index) => (
        <NavLink
          key={index}
          to={url(item.pathName)}
          className={({ isActive }) =>
            classNames("sidebar-buttons group", {
              "active-button": isActive,
              "!justify-end": !isMobileView
            })
          }
          onClick={() => {
            if (path === item.path) {
              window.location.href = `${item.path}`;
            }
          }}
        >
          <span className="material-symbols-outlined mr-3 flex-shrink-0 h-6 w-6">
            {item.icon}
          </span>
          <span className="toggled w-full">{item.title}</span>
        </NavLink>
      ))}
    </>
  );
};

export default MemberOperation;
