import { fetchApi } from '../index';

export const GetMemberNotificationPermission = async (model) => {

    const url = '/member/v1/member_notification_permission/get_member_notification_permissions/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const GetMemberGeneralPermissions = async (memberId) => {

    const url = '/member/v1/member_general_permission/get_member_general_permissions/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}