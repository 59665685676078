import { useLocalization } from "../../hooks/Utils/useLocalization";
import usePrimeData from "../../hooks/Utils/usePrimeData";
import { getTimezonesForCountry } from "countries-and-timezones";
import React, { useEffect, useRef, useState } from "react";
import ReactIntlTelInput from 'react-intl-tel-input-v2';
import { useNavigate } from "react-router-dom";
import '../../assets/css/intl-tel-input.css';
import {
  Dropdown,
  DropdownMultiSelect,
  Input,
  PageTitle,
  showCenteredAlert,
  showCenteredAlertTwoButtons,
  showTopAlert
} from "../../components";
import { deleteSpacesInThePhoneNumber, handleCheckbox, validateEmail, validPhoneNumber } from "../../components/Utility";
import useAuth from "../../hooks/Log/useAuth";
import { url } from "../../routes/utility";
import { AddMemberToPool } from "../../services/Member/MemberPoolService";
import NotAuthorized from "../../components/NotAuthorized";
import classNames from "classnames";
import { getButtonPermission } from "../../rights/Utility";
import { Brands, UseCaseList, LevelList } from "../../consts/Constants";
import { useSelector } from "react-redux";

const AddCandidate = () => {

  const primeData = usePrimeData();
  const strings = useLocalization();
  const navigate = useNavigate();
  const language = useSelector(state => state.localizationStore.language);

  const { employee, employeeTypeId, checkPermission, getPermission } = useAuth();
  const roles = getPermission();
  const permission = checkPermission(roles, employeeTypeId);

  const corporateRoles = getButtonPermission({ group: "addCandidatePool", name: "isCorporateButton" });
  const corporatePermission = checkPermission(corporateRoles, employeeTypeId);

  const emailErrorRef = useRef();
  const phoneNumberErrorRef = useRef();
  const brandErrorRef = useRef();

  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumberValue, setphoneNumberValue] = useState({ iso2: 'tr', dialCode: '90', phone: '' });
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('');
  const [selectedBrand, setSelectedBrand] = useState({ id: -1, name: strings.form_info.choose_brand });
  const [timezone, setTimezone] = useState(180);
  const [isCorporate, setIsCorporate] = useState(false);
  const [isCorporateDisabled, setIsCorporateDisabled] = useState(true);
  const [selectedLevel, setSelectedLevel] = useState({ id: -1, name: strings.form_info.choose_level });
  const [selectedUseCases, setSelectedUseCases] = useState([]);
  const [note, setNote] = useState("");

  const [loading, setLoading] = useState(false);
  const [confirmText, setConfirmText] = useState("");

  let inputProps = { placeholder: phoneNumberValue.iso2 === 'tr' ? '5XX XXX XX XX' : strings.employee.create_employee.enter_your_phone_number };
  const intlTelOpts = { preferredCountries: ['tr', 'az', 'sa'] };

  //Bayrak gerçekten değiştirildiyse numara gitmesin diye kontrol ediyoruz. 
  const phoneNumberHandle = val => {
    if (val.dialCode !== phoneNumberValue.dialCode) {
      setphoneNumberValue({ iso2: val.iso2, dialCode: val.dialCode, phone: '' });
    } else {
      setphoneNumberValue(val);
    }
  };

  const getUpdatedInfoText = (text) => {
    const updates = [];

    if (note.trim() !== "") {
      updates.push(strings.member.add_candidate.note_updated);
    }

    if (selectedLevel.id !== -1) {
      updates.push(strings.member.add_candidate.level_updated);
    }

    if (selectedUseCases.length > 0) {
      updates.push(strings.member.add_candidate.use_case_updated);
    }

    let updatedText = text;

    if (updates.length === 0) {
      updatedText = updatedText.replace(/#noteFieldStart#.*#noteFieldEnd#/s, '');
    } else {
      let updatedInfoText;

      if (updates.length === 1) {
        updatedInfoText = updates[0];
      } else if (updates.length === 2) {
        updatedInfoText = `${updates[0]} ${strings.member.add_candidate.and} ${updates[1]}`;
      } else {
        updatedInfoText = `${updates[0]}, ${updates[1]} ${strings.member.add_candidate.and} ${updates[2]}`;
      }

      updatedText = updatedText.replace('#infoText#', updatedInfoText);

      updatedText = updatedText
          .replace('#noteFieldStart#', '')
          .replace('#noteFieldEnd#', '');
    }

    return updatedText;
  };

  useEffect(() => {
    if (note?.length > 500) {
      setNote(note => note.slice(0, 500))
    }
  }, [note])

  //Telefon numarası değiştiğinde formatlı numara ve timezone güncelleniyor
  useEffect(() => {
    const formattedPhoneNumber = deleteSpacesInThePhoneNumber(phoneNumberValue.phone, phoneNumberValue.dialCode);
    phoneNumberValue.phone !== '' && setFormattedPhoneNumber(formattedPhoneNumber);

    //Seçilen ülke için time zone alınıyor
    let selectedCountry = phoneNumberValue.iso2 && getTimezonesForCountry(phoneNumberValue.iso2.toUpperCase());
    let newTimezone = selectedCountry && selectedCountry[0].utcOffset;
    setTimezone(timezone => newTimezone);

  }, [phoneNumberValue])
  
  //Onay sonrası bilgileri end-pointe gönderem fonksiyon
  const sendToEndPoint = async () => {

    setLoading(loading => true);
    let model = {
      brandId: selectedBrand.id,
      fullName: fullName,
      email: email,
      phoneNumber: formattedPhoneNumber,
      profilePicture: 'default_profile.png',
      timeZone: timezone,
      registerType: 3,
      employeeId: employee.EmployeeId,
      isCorporate: isCorporate,
      note: note === "" ? null : note,
      levelCollectionId: selectedLevel.id === -1 ? 0 : selectedLevel.id,
      reasonToLearnEnglishIds: selectedUseCases.length === 0 ? null : selectedUseCases
    };

    const result = await AddMemberToPool(model);

    if (result.status === 200) {

      if (!isCorporate) {
        showTopAlert(strings.member.add_candidate.success.message, "success");
        navigate(url("member.candidatepool"));
      } else {
        showTopAlert(strings.member.add_candidate.success.message_corporate, "success");
        navigate(url("member.candidate"));
      }

    } else {
      //responseType için 0 ve 1 ne ifade ediyor?
      //AlreadyInThePool = 0
      //AlreadyRegistered = 1
      const resultContent = JSON.parse(result.content);

      if (resultContent.responseType === 0) {
        showCenteredAlert(strings.alert.title.information, getUpdatedInfoText(strings.member.add_candidate.already_in_pool_modal_text), "info");
      } else if (resultContent.responseType === 1) {

        showCenteredAlertTwoButtons(
          strings.alert.centered_alert_two_buttons.title_confirm,
          getUpdatedInfoText(strings.member.add_candidate.already_registered_pool_modal_text),
          strings.alert.centered_alert_two_buttons.confirm_text,
          strings.alert.centered_alert_two_buttons.cancel_text,
          async () => {
            navigate(url("member.detail.profile", { memberId: resultContent.memberId }));
          }
        );

      } else if (resultContent.responseType === 2) {

        let message = resultContent.message + " " + strings.member.add_candidate.click_okey_for_profile_root;

        showCenteredAlertTwoButtons(
          strings.alert.centered_alert_two_buttons.title_confirm,
          message,
          strings.alert.centered_alert_two_buttons.confirm_text,
          strings.alert.centered_alert_two_buttons.cancel_text,
          async () => {
            navigate(url("member.detail.profile", { memberId: resultContent.memberId }));
          }
        );
      }
    }

    setLoading(loading => false);
  }

  const submitHandle = async e => {
    e.preventDefault()
    let valid = true;

    //tüm hataları sıfırla
    emailErrorRef.current.innerText = '';
    phoneNumberErrorRef.current.innerText = '';
    brandErrorRef.current.innerText = '';

    if (email.length > 0) {
      if (!validateEmail(email)) {
        valid = false;
      }
    }

    if (!validPhoneNumber(formattedPhoneNumber)) {
      valid = false;
    }

    if (selectedBrand.id === -1) {
      valid = false;
    }

    //Eğer hata yoksa
    if (valid) {
      showCenteredAlertTwoButtons(
        strings.alert.centered_alert_two_buttons.title_confirm,
        confirmText,
        strings.button.confirm,
        strings.alert.centered_alert_two_buttons.cancel_text,
        sendToEndPoint
      );
    } else {
      //Hataları gösteriyoruz
      emailErrorRef.current.innerText = (email.length > 0 && !validateEmail(email)) ? strings.errors.email_format_error : '';
      phoneNumberErrorRef.current.innerText = !validPhoneNumber(formattedPhoneNumber) ? strings.errors.phone_number_null_error : '';
      brandErrorRef.current.innerText = selectedBrand.id === -1 ? strings.errors.brand_null_error : '';
    }
  }

  useEffect(() => {
    isCorporate
      ? setConfirmText(approveText => strings.member.add_candidate.confirm_model_text_corporate)
      : setConfirmText(approveText => strings.member.add_candidate.confirm_modal_text)
  }, [isCorporate]);

  useEffect(() => {
    setIsCorporate(isCorporate => false);
    if (selectedBrand.id === Brands.KO)
      setIsCorporateDisabled(isCorporateDisabled => false);
    else
      setIsCorporateDisabled(isCorporateDisabled => true);
  }, [selectedBrand.id])

  return (
    <>
      <PageTitle title={strings.sidebar.member.add_candidate_to_pool} />

      {permission && (
        <form className="max-w-xs lg:max-w-md mx-auto" onSubmit={submitHandle}>
          <div className="w-full mt-2">
            <div className="flex flex-col px-[10px] pb-0 w-full">
              <label htmlFor="fullname">{strings.form_info.full_name} </label>
              <Input
                id="fullname"
                type="text"
                classnames="mb-4"
                onChange={(e) => setFullName(fullName => e.target.value)}
              />
            </div>
          </div>

          <div className="w-full mt-2">
            <div className="flex flex-col px-[10px] pb-0">
              <label htmlFor="email">{strings.form_info.email}</label>
              <Input
                id="email"
                type="email"
                placeholder="example@example.com"
                onChange={(e) => setEmail(email => e.target.value)}
              />
            </div>
            <span ref={emailErrorRef} id="name_error" className="text-danger field-validation-valid px-[10px]"></span>
          </div>

          <div className="w-full mt-2">
            <div className="flex flex-col px-[10px] pb-0">
              <label htmlFor="phone">{strings.form_info.phone_number} <span className="text-red">*</span></label>
              <ReactIntlTelInput
                className="form-input w-full md:max-w-[480px]"
                inputStyle={{ color: 'green' }}
                inputProps={inputProps}
                intlTelOpts={intlTelOpts}
                value={phoneNumberValue}
                onChange={phoneNumberHandle}
                type="text"
                name="phone"
              />
            </div>
            <span ref={phoneNumberErrorRef} id="name_error" className="text-danger field-validation-valid px-[10px]"></span>
          </div>

          <div className="w-full mt-2">
            <div id="dropdown_section" className="flex flex-col px-[10px]">
              <label className="text-[13px]" htmlFor="brand">{strings.table.brand} <span className="text-red">*</span></label>
              <Dropdown
                title={strings.detail_search_titles.brands}
                data={[{ id: -1, name: strings.form_info.choose_brand }, ...primeData.brandList]}
                selectedData={selectedBrand}
                setSelectedData={setSelectedBrand}
                classnames="md:!max-w-[480px]"
              />
            </div>
            <span ref={brandErrorRef} id="name_error" className="text-danger field-validation-valid px-[10px]"></span>
          </div>

          <div className="w-full mt-2">
            <div id="dropdown_section" className="flex flex-col px-[10px] pb-0">
              <label className="text-[13px]" htmlFor="level">{strings.member.member_detail.terms.level}</label>
              <Dropdown
                title={strings.detail_search_titles.brands}
                data={[{ id: -1, name: strings.form_info.choose_level }, ...LevelList.map(level => ({ id: level.id, name: level[language] }))]}
                selectedData={selectedLevel}
                setSelectedData={setSelectedLevel}
                classnames="md:!max-w-[480px] mb-2"
              />
            </div>
          </div>

          <div className="w-full mt-2">
            <div id="dropdown_section" className="flex flex-col px-[10px] pb-0">
              <label className="text-[13px]" htmlFor="useCase">{strings.form_info.use_case}</label>
              <DropdownMultiSelect
                title={strings.detail_search_titles.use_case}
                data={UseCaseList.map(useCase => ({ id: useCase.id, name: useCase[language] }))}
                selectedData={selectedUseCases}
                setSelectedData={setSelectedUseCases}
                randomId="useCaseListMS"
                classnames="md:!max-w-full mb-2"
                inputPlaceholder={strings.detail_search_titles.use_case}
                isDisabled={loading}
              />
            </div>
          </div>

          <div className="w-full mt-2">
            <div className="flex flex-col px-[10px] pb-0">
              <label className="text-[13px]" htmlFor="note">
                {strings.member.member_detail.candidate_operations.note}
              </label>
              <div className="relative">
                <textarea
                  id="note"
                  className="border rounded-lg outline-0 focus:border-blue py-3 px-2 w-full"
                  rows="2"
                  value={note || ""}
                  onChange={(e) => setNote(e.target.value)}
                />
                <p className={classNames("absolute -bottom-4 right-0", {
                  "text-red": note?.length >= 490
                })}>
                  {note?.length ? note?.length : 0}<span className="font-bold">/500</span>
                </p>
              </div>
            </div>
          </div>

          <div className="flex mt-5 flex-col sm:flex-row">
            {corporatePermission && (
              <div className="w-full mt-2">
                <div className="flex flex-col px-[10px]">
                  <label className="text-[13px]">{strings.member.add_candidate.check_corporate}</label>
                  <div className="w-[60px]">
                    <input
                      id="isActive1"
                      type="checkbox"
                      className="hidden"
                      disabled={true}
                    />
                    <label
                      id="campaign_button1"
                      htmlFor="isActive1"
                      className={`${handleCheckbox(isCorporate)} ${isCorporateDisabled ? "cursor-not-allowed" : "cursor-pointer"} relative group mr-2 w-6 h-6 rounded-[3px] duration-500 flex items-center justify-center`}
                      onClick={() => {
                        !isCorporateDisabled && setIsCorporate(isCorporate => !isCorporate);
                      }}
                    >
                      <span className={classNames('text-inherit text-[20px] material-symbols-outlined animate-fadeIn font-bold', { 'hidden': !isCorporate })}>
                        done
                      </span>
                      {isCorporateDisabled && (
                        <div className={`tooltip-top ${selectedBrand.id === Brands.AZ || selectedBrand.id === Brands.AL ? "!-top-[80px]" : ""}`}>
                          {selectedBrand.id === Brands.AZ || selectedBrand.id === Brands.AL
                            ? strings.member.add_candidate.only_avaliable_for_ko
                            : strings.detail_search_titles.first_choose_brand
                          }
                        </div>
                      )}
                    </label>
                  </div>
                </div>
              </div>
            )}

            <div className="w-full mt-4 sm:mt-2">
              <div className="flex flex-col px-[10px] pb-0">
                <label className="text-[13px]">{strings.form_info.timezone}</label>
                <div className="mb-4">{timezone}</div>
              </div>
            </div>
          </div>

          <button
            type="submit"
            className="button search-button gap-3 text-white bg-blue border-blue mt-7 mx-auto px-10"
            disabled={loading}
          >
            {!loading && strings.button.save_upper}
            {loading && <div className="inline-block h-4 w-4 animate-spin rounded-full border-[3px] border-solid border-current border-r-transparent align-[-0.25em] motion-reduce:animate-[spin_1.5s_linear_infinite]"></div>}
          </button>
        </form>
      )}

      {!permission && (
        <NotAuthorized />
      )}
    </>
  )
}

export default AddCandidate