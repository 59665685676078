import { fetchApi } from '../index';

export const UseCaseListService = async () => {
    const url = '/ai_prime/ai_use_case/list/';

    const payload = {};

    return await fetchApi(url, payload);
}

export const CreateUseCaseService = async (model) => {
    const url = '/ai_prime/ai_use_case/add_range/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const GetUseCaseByIdService = async (useCaseId) => {
    const url = '/ai_prime/ai_use_case/get_by_id/';

    const payload = {
        useCaseId: useCaseId
    };

    return await fetchApi(url, payload);
}

export const ChangeUseCaseStatusService = async (useCaseId) => {
    const url = '/ai_prime/ai_use_case/change_status/';

    const payload = {
        useCaseId: useCaseId
    };

    return await fetchApi(url, payload);
}

export const UpdateUseCaseService = async (model) => {
    const url = '/ai_prime/ai_use_case/update_range/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}