import {fetchApi} from "../FetchApi";

export const MemberNotesFilter = async (model) => {

    const url = '/member/v1/member_note/member_notes_by_filter/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const CreateMemberNote = async (model) => {

    const url = '/member/v1/member_note/create/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}