import React, { useEffect, useState } from 'react';
import { AlphabetBar, Loading, PageTitle, Pagination, SearchBar, SearchCount, showTopAlert } from '../../components';
import NotAuthorized from '../../components/NotAuthorized';
import useAuth from '../../hooks/Log/useAuth';
import { useLocalization } from '../../hooks/Utils/useLocalization';
import { CurrentCardFilter } from '../../services/Accounting/CurrentCardService';
import { CurrentCardDetailSearch, CurrentCardTable } from './components';

const CurrentCard = () => {
  
  const strings = useLocalization();

  const { employee, employeeTypeId, checkPermission, getPermission } = useAuth();
  const roles = getPermission();
  const permission = checkPermission(roles, employeeTypeId);
    
  const limit = parseInt(process.env.REACT_APP_TABLE_ROW_LIMIT);
  const [start, setStart] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const [detailSearch, setDetailSearch] = useState({
    brandId: -1,
    searchText: '',
    letter: ''
  });

  //Arama verileri değiştiğinde değerleri sıfırlıyoruz
  const resetValue = () => {
    setStart(start => 0);
    setCurrentPage(currentPage => 1);
    setLoading(loading => true);
  }

  //select değişiklikleri bu alandan yönetiliyor
  const changeDetail = (values) => {
    setDetailSearch({ ...detailSearch, ...values });
    resetValue();
  }

  //input değişiklikleri bu alandan yönetiliyor
  const changeValue = (value) => {
    setDetailSearch({ ...detailSearch, searchText: value });
    resetValue();
  }

  //alfabe ile arama kısmındaki değişiklikler buradan yönetiliyor
  const changeLetter = (value) => {
    setDetailSearch({ ...detailSearch, letter: value })
  }

  const getCurrentCard = async () => {
    const model = {
      employeeId: employee.EmployeeId,
      start: start,
      length: limit,
      brandId: detailSearch.brandId,
      firstLetter: detailSearch.letter.length !== 0 ? detailSearch.letter :  null,
      currentCardName: detailSearch.searchText
    }

    const result = await CurrentCardFilter(model);

    if(result.status === 200){
      let resultContent = JSON.parse(result.content);

      setData(data => resultContent.data);
      setTotalRecord(totalRecord => resultContent.recordsTotal);
      setLoading(loading => false);
    } else {
      showTopAlert(strings.errors.an_error_occurred, "error");
    }
  }

  //sayfa değiştikçe bilgileri yeniden çağırıyoruz
  useEffect(() => {
    if (totalRecord !== 0) {
      getCurrentCard();
    }
  }, [start])

  useEffect(() => {

    let abortController = new AbortController();

    // Kullanıcının sayfa görüntüleme izni yoksa servise gitmeyip performans kaybını önlüyoruz
    permission && getCurrentCard();

    return () => {
        abortController.abort();
    }
  }, [detailSearch, permission])

  return (
    <>  
        {permission && (
          <>
            <CurrentCardDetailSearch 
              changeDetail={changeDetail} 
              loading={loading}
            />
            <SearchBar 
              changeValue={changeValue} 
              placeHolder={strings.search_bar.search_current_card} 
              isDisabled={loading}
            />
            <AlphabetBar changeLetter={changeLetter} detailSearch={detailSearch} />
            {!loading && <SearchCount count={totalRecord} />}
          </>
        )}
        <PageTitle title={strings.sidebar.accounting.current_card} />
        {permission ? (
          loading ? 
          (
            <Loading />
          )
          :
          (
            <>
              <CurrentCardTable data={data} />
              <Pagination 
                totalCount={totalRecord}
                limit={limit}
                start={start}
                setStart={setStart}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                setLoading={setLoading}
                loadScreen={true}
              />
            </>
          )
        ) : (<NotAuthorized />)}
    </>
  )
}

export default CurrentCard;