import { fetchApi } from "../FetchApi";

export const MemberPoolReport = async (model) => {

    const url = '/member/v2/report/member_pool_report/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}