import { fetchApi } from '../index';

export const GetAndUpdateLessonCorrections = async (model) => {

    const url = '/member/v1/lesson_correction/get_and_update_lesson_corrections/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}