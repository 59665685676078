import React, { useEffect, useRef, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { closeTicketAccordion, toggleTicketAccordion } from '../../../../../../components';
import { addTimeZoneToFullDate } from '../../../../../../components/Utility';
import useAuth from '../../../../../../hooks/Log/useAuth';
import { useLocalization } from '../../../../../../hooks/Utils/useLocalization';

const TicketAccordion = (props) => {

    const strings = useLocalization();
    const { ticket } = props;
    const { employee } = useAuth();

    const accordionRef = useRef();
    const plusSignRef = useRef();
    const minusSignRef = useRef();
    
    const [isOutside, setIsOutside] = useState(false);
    const [isClick, setIsClick] = useState(false);

    useEffect(() => {
        if(isOutside){
            closeTicketAccordion(accordionRef, plusSignRef, minusSignRef);
        }
    }, [isOutside])

    return (
        <>
            <OutsideClickHandler onOutsideClick={() => setIsOutside(true)}>
                <div className="relative mt-7">
                    <div
                        onClick={() => {
                            toggleTicketAccordion(accordionRef, plusSignRef, minusSignRef)
                            setIsOutside(isOutside => false);
                            setIsClick(isClick => true);
                        }}
                        className="px-5 py-4 cursor-pointer text-dark-text bg-card rounded-t-md rounded-b-md flex justify-between items-center mx-2 xs:mx-4"
                    >
                    <div className="relative flex flex-col gap-2">
                        <p>{ticket.message}</p>
                        <p className="font-bold">{addTimeZoneToFullDate(ticket.messageDate, parseInt(employee.TimeZone))}</p>
                    </div>
                    <span
                        ref={plusSignRef}
                        className="material-symbols-outlined font-bold text-base cursor-pointer"
                    >
                        add
                    </span>
                    <span
                        ref={minusSignRef}
                        className="material-symbols-outlined font-bold text-base hidden cursor-pointer"
                    >
                        remove
                    </span>
                    </div>
                    <div
                        ref={accordionRef}
                        className="max-h-0 mx-2 xs:mx-4 px-4 gap-x-4 gap-y-5 flex-wrap max-md-lg:justify-center  border-[#ebebeb] rounded-b-md animate-fadeOut transition-max-height duration-500"
                    >
                    <div className={`${!isClick ? 'hidden' : ''}`}>
                        {ticket.answer === null ? (
                            <p className="flex justify-center">
                            {strings.member.member_detail.tickets.not_answered}
                            </p>
                        ) : (
                            <div className="relative flex flex-col gap-2">
                            <p dangerouslySetInnerHTML={{ __html: ticket.answer.replace(/\n/g, '<br>') }} />
                            <p className="font-bold">{addTimeZoneToFullDate(ticket.answeredDate, parseInt(employee.TimeZone))}</p>
                            </div>
                        )}
                    </div>
                    </div>
                </div>
            </OutsideClickHandler>
        </>
    )
}

export default TicketAccordion