import {useLocalization} from "../../hooks/Utils/useLocalization";
import {Link, useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import {Loading} from "../../components";
import {UseCaseHistorySessionLogListService} from "../../services/UseCaseHistory/UseCaseHistoryService";
import {url} from "../../routes/utility";
import {ISOStringToDate} from "../../components/Utility";
import useAuth from "../../hooks/Log/useAuth";
import classNames from "classnames";
import {AIUseCaseRoles} from "../../consts/Constants";

const UseCaseHistoryDetail = () => {

    const strings = useLocalization();
    const location = useLocation();
    const { employee } = useAuth();

    const aiUseCaseSessionId = location?.state?.id || false;
    const memberId = location?.state.memberId || false;
    const memberName = location?.state?.memberName || false;

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const getUseCaseHistorySessionLogList = async (aiUseCaseSessionId) => {

        const result = await UseCaseHistorySessionLogListService(aiUseCaseSessionId);

        if(result.status === 200) {
            let resultContent = JSON.parse(result.content);

            setData(data => resultContent);
            setLoading(loading => false);
        }
    }

    const getTitleByRoleId = (roleId) => {
        switch (roleId) {
            case AIUseCaseRoles.System:
                return strings.history.detail.system;
            case AIUseCaseRoles.Assistant:
                return strings.history.detail.assistant;
            case AIUseCaseRoles.Member:
                return memberName;
            default:
                return "";
        }
    }

    useEffect(() => {
        aiUseCaseSessionId && getUseCaseHistorySessionLogList(aiUseCaseSessionId);
    }, [aiUseCaseSessionId]);

    return (
        <>
            {loading && (
                <Loading />
            )}
            <div className="rounded-md bg-white max-xs:px-2 text-[13px] h-full">
                <div className="flex justify-between items-center relative">
                    <Link
                        to={url("member.detail.profile", { memberId: memberId })}
                        target="_blank"
                        className="font-bold text-blue hover:underline focus:underline  min-w-max"
                    >
                        {memberName}
                    </Link>
                    <Link
                        to={url("history.list")}
                        className="bg-card border border-[#e8e8e8] hover:bg-[#f3f3f3] gap-2 h-10 w-28 flex flex-row items-center justify-center rounded-full"
                    >
                        <span className="material-symbols-outlined">
                          keyboard_return
                        </span>
                        <p>{strings.button.go_back}</p>
                    </Link>
                </div>
                <hr className="my-4 -mx-2 md:-mx-5" />
                <div className="h-[500px] p-5 overflow-y-scroll">
                    {data.map((message, index) => (
                        <div
                            key={index}
                            className={classNames("max-w-[95%] md:max-w-[85%]", {
                                "ml-auto" : message.aiUseCaseRoleId === AIUseCaseRoles.Member
                            })}
                        >
                            <div
                                className={classNames("block max-w-max relative p-3 rounded-lg mt-2", {
                                   "message-box-right bg-blue ml-auto" : message.aiUseCaseRoleId === AIUseCaseRoles.Member,
                                   "message-box-left bg-[#f1f1f1]" : message.aiUseCaseRoleId !== AIUseCaseRoles.Member
                                })}
                            >
                                <p
                                    className={classNames("text-[11px] mb-1", {
                                        "text-white" : message.aiUseCaseRoleId === AIUseCaseRoles.Member,
                                        "text-base-text": message.aiUseCaseRoleId !== AIUseCaseRoles.Member
                                    })}
                                >
                                    {getTitleByRoleId(message.aiUseCaseRoleId)}
                                </p>
                                <p
                                    className={classNames("", {
                                        "text-white" : message.aiUseCaseRoleId === AIUseCaseRoles.Member,
                                        "text-base-text": message.aiUseCaseRoleId !== AIUseCaseRoles.Member
                                    })}
                                >
                                    {message.contentText}
                                </p>
                            </div>
                            <span
                                className={classNames("text-[11px] text-base-text flex mt-1 mx-0", {
                                    "justify-end" : message.aiUseCaseRoleId === AIUseCaseRoles.Member
                                })}
                            >
                                {ISOStringToDate(message.createDate, employee.TimeZone)}
                            </span>
                        </div>
                    ))}
                </div>
            </div>

        </>
    )
}

export default UseCaseHistoryDetail