import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import {useLocalization} from '../../../../../hooks/Utils/useLocalization';
import {Input} from '../../../../../components';
import {checkNumber, formatCreditCardNumber, formatExpirationDate} from '../../../../../components/Utility';
import {AmericanExpress, Mastercard, Troy, Visa} from '../../../../../assets/img';
import BinChecker from "./BinChecker";

const CreditCard = (props) => {

  const {
      cardNumber,
      cardholderName,
      cardExpiration,
      cardCvv,
      setCardNumber,
      setCardholderName,
      setCardExpiration,
      setCardCvv,
      bankPosList,
      setBank,
      setBankName,
      setIsOneShot,
      setIsForeignCard
  } = props;
  const strings = useLocalization();

  const [flipped, setFlipped] = useState(false);
  const [cardImage, setCardImage] = useState(null);
  const [cardColor, setCardColor] = useState("greydark");
  const [cardLightColor, setCardLightColor] = useState("grey");
  const [cardAssociation, setCardAssociation] = useState(null);

  useEffect(() => {
    const determineCardType = () => {
      if (cardAssociation === "VISA") {
        setCardImage(Visa);
        setCardColor("visa");
        setCardLightColor("visalight");
      } else if (cardAssociation === "MASTER_CARD") {
        setCardImage(Mastercard);
        setCardColor("mastercard");
        setCardLightColor("mastercardlight");
      } else if (cardAssociation === "AMERICAN_EXPRESS") {
        setCardImage(AmericanExpress);
        setCardColor("amex");
        setCardLightColor("amexlight");
      } else if(cardAssociation === "TROY"){
        setCardImage(Troy);
        setCardColor("troy");
        setCardLightColor("troylight");
      } else {
        setCardImage(null);
        setCardColor("greydark");
        setCardLightColor("grey");
      }
    };

    determineCardType(cardNumber);
  }, [cardAssociation]);

  return (
    <>
        <div id="credit_card">
            <div
                className="mt-2 flex flex-col lg:flex-row w-full max-sm:gap-8 gap-5 2xl:gap-10 "
            >
                <div className="card-container w-full  max-sm:px-2 max-sm:mx-auto">
                <div onClick={() => setFlipped(!flipped)} className={classNames('creditcard', {'flipped' : flipped})} >
                    {/* <!-- Front of Credit Card --> */}
                    <div className="front">
                        <div id="ccsingle">
                            <img className='-mt-2' src={cardImage} width="90" alt="" />
                        </div>
                        <svg version="1.1" id="cardfront"                                        
                            x="0px"
                            y="0px" viewBox="0 0 750 471"
                            //style="enable-background:new 0 0 750 471;"
                        >
                            <g id="Front">
                                <g id="CardBackground">
                                    <g id="Page-1_1_">
                                        <g id="amex_1_">
                                            <path id="Rectangle-1_1_"
                                                className={cardLightColor}
                                                d="M40,0h670c22.1,0,40,17.9,40,40v391c0,22.1-17.9,40-40,40H40c-22.1,0-40-17.9-40-40V40C0,17.9,17.9,0,40,0z">
                                            </path>
                                        </g>
                                    </g>
                                    <path 
                                        className={cardColor}
                                        d="M750,431V193.2c-217.6-57.5-556.4-13.5-750,24.9V431c0,22.1,17.9,40,40,40h670C732.1,471,750,453.1,750,431z">
                                    </path>
                                </g>
                                <text 
                                    id="svgnumber"
                                    transform="matrix(1 0 0 1 60.106 295.0121)"
                                    className="st2 st3 st4"
                                >
                                    {cardNumber === '' ? '**** **** **** ****' : formatCreditCardNumber(cardNumber)}
                                </text>
                                <text 
                                    id="svgname"
                                    transform="matrix(1 0 0 1 54.1064 428.1723)"
                                    className="st2 st5 st6"
                                >
                                    {cardholderName === '' ? strings.form_info.full_name.toLocaleUpperCase() : cardholderName.toLocaleUpperCase()}
                                </text>
                                <text transform="matrix(1 0 0 1 54.1074 389.8793)"
                                    className="st7 st5 st8">{strings.member.member_detail.sales_operations.card_name}</text>
                                <text transform="matrix(1 0 0 1 465.7754 388.8793)"
                                    className="st7 st5 st8">{strings.member.member_detail.sales_operations.card_expiration_date}</text>
                                <text transform="matrix(1 0 0 1 65.1054 241.5)"
                                    className="st7 st5 st8">{strings.member.member_detail.sales_operations.card_number}</text>
                                <g>
                                    <text
                                    id="svgexpire"
                                    transform="matrix(1 0 0 1 560.4219 433.8095)"
                                    className="st2 st5 st9"
                                    >
                                        {cardExpiration === '' ? strings.member.member_detail.sales_operations.day_year : formatExpirationDate(cardExpiration)}
                                    </text>
                                    <text
                                        transform="matrix(1 0 0 1 465.3848 417.0097)"
                                        className="st2 st10 st11">VALID</text>
                                    <text
                                        transform="matrix(1 0 0 1 465.3848 435.6762)"
                                        className="st2 st10 st11">THRU</text>
                                    <polygon className="st2"
                                        points="540.5,421 526.4,414.2 526.4,427.9">
                                    </polygon>
                                </g>
                                <g id="cchip">
                                    <g>
                                        <path className="st2"
                                            d="M168.1,143.6H82.9c-10.2,0-18.5-8.3-18.5-18.5V74.9c0-10.2,8.3-18.5,18.5-18.5h85.3c10.2,0,18.5,8.3,18.5,18.5v50.2C186.6,135.3,178.3,143.6,168.1,143.6z">
                                        </path>
                                    </g>
                                    <g>
                                        <g>
                                            <rect x="82" y="70" className="st12"
                                                width="1.5" height="60"></rect>
                                        </g>
                                        <g>
                                            <rect x="167.4" y="70" className="st12"
                                                width="1.5" height="60"></rect>
                                        </g>
                                        <g>
                                            <path className="st12"
                                                d="M125.5,130.8c-10.2,0-18.5-8.3-18.5-18.5c0-4.6,1.7-8.9,4.7-12.3c-3-3.4-4.7-7.7-4.7-12.3
                                        c0-10.2,8.3-18.5,18.5-18.5s18.5,8.3,18.5,18.5c0,4.6-1.7,8.9-4.7,12.3c3,3.4,4.7,7.7,4.7,12.3
                                        C143.9,122.5,135.7,130.8,125.5,130.8z M125.5,70.8c-9.3,0-16.9,7.6-16.9,16.9c0,4.4,1.7,8.6,4.8,11.8l0.5,0.5l-0.5,0.5
                                        c-3.1,3.2-4.8,7.4-4.8,11.8c0,9.3,7.6,16.9,16.9,16.9s16.9-7.6,16.9-16.9c0-4.4-1.7-8.6-4.8-11.8l-0.5-0.5l0.5-0.5
                                        c3.1-3.2,4.8-7.4,4.8-11.8C142.4,78.4,134.8,70.8,125.5,70.8z">
                                            </path>
                                        </g>
                                        <g>
                                            <rect x="82.8" y="82.1" className="st12"
                                                width="25.8" height="1.5">
                                            </rect>
                                        </g>
                                        <g>
                                            <rect x="82.8" y="117.9" className="st12"
                                                width="26.1" height="1.5"></rect>
                                        </g>
                                        <g>
                                            <rect x="142.4" y="82.1" className="st12"
                                                width="25.8" height="1.5"></rect>
                                        </g>
                                        <g>
                                            <rect x="142" y="117.9" className="st12"
                                                width="26.2" height="1.5">
                                            </rect>
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g id="Back">
                            </g>
                        </svg>
                    </div>
                    {/* <!-- Back of Credit Card --> */}
                    <div className="back">
                        <svg version="1.1" id="cardback"
                            x="0px"
                            y="0px" viewBox="0 0 750 471"
                            >
                            <g id="Front">
                                <line className="st0" x1="35.3" y1="10.4" x2="36.7"
                                    y2="11">
                                </line>
                            </g>
                            <g id="Back">
                                <g id="Page-1_2_">
                                    <g id="amex_2_">
                                        <path 
                                        id="Rectangle-1_2_"
                                        className={cardColor}
                                        d="M40,0h670c22.1,0,40,17.9,40,40v391c0,22.1-17.9,40-40,40H40c-22.1,0-40-17.9-40-40V40C0,17.9,17.9,0,40,0z"
                                        >
                                        </path>
                                    </g>
                                </g>
                                <rect y="61.6" className="st2" width="750" height="78">
                                </rect>
                                <g>
                                    <path className="st3" d="M701.1,249.1H48.9c-3.3,0-6-2.7-6-6v-52.5c0-3.3,2.7-6,6-6h652.1c3.3,0,6,2.7,6,6v52.5
                                C707.1,246.4,704.4,249.1,701.1,249.1z"></path>
                                    <rect x="42.9" y="198.6" className="st4"
                                        width="664.1" height="10.5"></rect>
                                    <rect x="42.9" y="224.5" className="st4"
                                        width="664.1" height="10.5"></rect>
                                    <path className="st5"
                                        d="M701.1,184.6H618h-8h-10v64.5h10h8h83.1c3.3,0,6-2.7,6-6v-52.5C707.1,187.3,704.4,184.6,701.1,184.6z">
                                    </path>
                                </g>
                                <text transform="matrix(1 0 0 1 621.999 227.2734)"
                                    id="svgsecurity" className="st6 st7">
                                    {cardCvv === '' ? '***' : cardCvv}
                                </text>
                                <g className="st8">
                                    <text
                                    transform="matrix(1 0 0 1 518.083 280.0879)"
                                    className="st9 st6 st10"
                                    >
                                    {strings.member.member_detail.sales_operations.card_security_code}
                                    </text>
                                </g>
                                <rect x="58.1" y="378.6" className="st11" width="375.5"
                                    height="13.5"></rect>
                                <rect x="58.1" y="405.6" className="st11" width="421.7"
                                    height="13.5"></rect>
                                <text transform="matrix(1 0 0 1 59.5073 228.6099)"
                                    id="svgnameback" className="st12 st13">
                                    {cardholderName === '' ? strings.form_info.full_name : cardholderName.toLocaleUpperCase()}
                                </text>
                            </g>
                        </svg>
                    </div>
                </div>
                </div>
                <form className="sm:max-w-md min-w-0">
                    <div className="flex flex-col px-[10px] pb-0 w-full">
                        <label htmlFor="name">{strings.member.member_detail.sales_operations.card_name}</label>
                        <Input
                            id="name"
                            type="text"
                            value={cardholderName}
                            onChange={(e) => setCardholderName(e.target.value)}
                            onFocus={() => setFlipped(false)}
                        />
                    </div>
                    <div className="mt-4">
                        <BinChecker
                            setFlipped={setFlipped}
                            cardNumber={cardNumber}
                            setCardNumber={setCardNumber}
                            bankPosList={bankPosList}
                            setBank={setBank}
                            setBankName={setBankName}
                            setIsOneShot={setIsOneShot}
                            setIsForeignCard={setIsForeignCard}
                            setCardAssociation={setCardAssociation}
                        />
                    </div>
                    <div className="flex flex-col">
                        <div className="mt-4 w-full flex">
                            <div className="px-[10px] pb-0 w-full">
                                <label htmlFor="expiration_date">{strings.member.member_detail.sales_operations.card_expiration_date}</label>
                            </div>
                            <div className="relative px-[10px] pb-0 w-full flex gap-1">
                                <label htmlFor="security_code"> {strings.member.member_detail.sales_operations.card_security_code}</label>
                                <div className="relative -mt-[3px] group"><span className="material-symbols-outlined cursor-pointer">info</span>
                                <div className="tooltip-top">
                                    <p>{strings.member.member_detail.sales_operations.cvv_tooltip}</p>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full flex">
                            <div className="px-[10px] pb-0 w-full">
                                <Input
                                    id="expiration_date"
                                    type="text"
                                    maxLength="5"
                                    placeholder="**/**"
                                    value={formatExpirationDate(cardExpiration)}
                                    onKeyDown={(e) => !checkNumber(e) && e.preventDefault()}
                                    onChange={(e) => setCardExpiration(e.target.value)}
                                    onFocus={() => setFlipped(false)}
                                />
                            </div>
                            <div className="px-[10px] pb-0 w-full">
                                <Input
                                    id="security_code"
                                    type="text"
                                    maxLength="4"
                                    placeholder="***"
                                    value={cardCvv}
                                    onKeyDown={(e) => !checkNumber(e) && e.preventDefault()}
                                    onChange={(e) => setCardCvv(e.target.value)}
                                    onFocus={() => setFlipped(true)}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <hr className="mt-5" />
        </div>
    </>
  )
};

export default CreditCard;