import {Dropdown, Input} from "../../../../../components";
import {LanguageList} from "../../../../../consts/Constants";
import {useEffect, useRef, useState} from "react";
import {useLocalization} from "../../../../../hooks/Utils/useLocalization";
import classNames from "classnames";

const AddTranslationRow = ({ translations, setTranslations }) => {

    const strings = useLocalization();
    const animationRef = useRef();
    const languageErrorRef = useRef();
    const titleTranslationErrorRef = useRef();
    const titleInformationErrorRef = useRef();

    const firstLanguage = { id: -1, name: strings.detail_search_titles.choose_language };
    const [languageList, setLanguageList] = useState([firstLanguage, ...LanguageList]);

    const [selectedTitleTranslationLanguage, setSelectedTitleTranslationLanguage] = useState(firstLanguage);
    const [titleTranslation, setTitleTranslation] = useState("");
    const [titleInformation, setTitleInformation] = useState("");
    const [isClickedAddButton, setIsClickedAddButton] = useState(false);

    const validate = () =>  {
        let valid = true;

        languageErrorRef.current.innerText = '';
        titleTranslationErrorRef.current.innerText = '';
        titleInformationErrorRef.current.innerText = '';

        if(selectedTitleTranslationLanguage.id === -1) {
            valid = false;
            languageErrorRef.current.innerText = strings.errors.language_null_error;
        }
        if(titleTranslation.length === 0) {
            valid = false;
            titleTranslationErrorRef.current.innerText = strings.errors.translation_title_null_error;
        }
        if(titleInformation.length === 0) {
            valid = false;
            titleInformationErrorRef.current.innerText = strings.errors.information_null_error;
        }

        return valid;
    }

    const addTitleTranslation = () => {

        setIsClickedAddButton(isClickedAddButton => true);

        if(validate()) {
            // Yeni bir translation objesi oluşturuluyor
            const model = {
                languageId: selectedTitleTranslationLanguage.id,
                title: titleTranslation,
                information: titleInformation
            };

            // Çeviriyi translations dizisine ekleyerek state'i güncelleniyor
            setTranslations([...translations, model]);

            // Stateler işlem bittikten sonra sıfırlanıyor
            setSelectedTitleTranslationLanguage(firstLanguage);
            setTitleTranslation("");
            setTitleInformation("");
            setIsClickedAddButton(isClickedAddButton => false);

        }
    }

    const handleTransitionEnd = () => {
        if (languageList.length === 1) {
            animationRef.current.classList.add('hidden');
        } else {
            animationRef.current.classList.remove('animate-fadeOut');
        }
    };

    useEffect(() => {
        isClickedAddButton && validate();
    }, [selectedTitleTranslationLanguage, titleTranslation, titleInformation]);

    useEffect(() => {
        const usedLanguageIds = translations.map(item => item.languageId);
        const newLanguageList = [firstLanguage, ...LanguageList].filter(item => !usedLanguageIds.includes(item.id));
        setLanguageList(newLanguageList);
    }, [translations]);

    useEffect(() => {
        if (languageList.length > 1) {
            animationRef.current.classList.remove('hidden');
        }
    }, [languageList]);

    return (
        <>
            <div
                ref={animationRef}
                className={classNames("flex flex-col xl:flex-row items-center justify-start xl:items-start gap-3 px-5 max-xs:px-2 transition-max-height duration-500", {
                    "max-h-0 animate-fadeOut" : languageList.length === 1,
                    "max-h-screen" : languageList.length > 1
                })}
                onTransitionEnd={handleTransitionEnd}
            >
                <div className="flex flex-col pb-0 mt-5 w-full">
                    <div className="flex flex-col pb-0 ">
                        <label>
                            {strings.form_info.language} {isClickedAddButton && <span className="text-red">*</span>}
                        </label>
                        <Dropdown
                            data={languageList}
                            selectedData={selectedTitleTranslationLanguage}
                            setSelectedData={setSelectedTitleTranslationLanguage}
                            classnames="md:max-w-full"
                        />
                        <span ref={languageErrorRef} className="text-danger field-validation-valid " ></span>
                    </div>
                </div>
                <div className="flex flex-col pb-0 mt-5 w-full">
                    <label htmlFor="title-translations">
                        {strings.form_info.title_translation} {isClickedAddButton && <span className="text-red">*</span>}
                    </label>
                    <div className="flex flex-col pb-0 ">
                        <Input
                            id="title-translations"
                            type="text"
                            classnames="md:max-w-full"
                            value={titleTranslation}
                            onChange={(e) => setTitleTranslation(e.target.value)}
                        />
                        <span ref={titleTranslationErrorRef} className="text-danger field-validation-valid " ></span>
                    </div>
                </div>
                <div className="flex flex-col pb-0 mt-5 w-full">
                    <label htmlFor="title-translation-information">
                        {strings.form_info.information} {isClickedAddButton && <span className="text-red">*</span>}
                    </label>
                    <div className="flex flex-col pb-0 ">
                        <Input
                            id="title-translation-information"
                            type="text"
                            classnames="md:max-w-full"
                            value={titleInformation}
                            onChange={(e) => setTitleInformation(e.target.value)}
                        />
                        <span ref={titleInformationErrorRef} className="text-danger field-validation-valid " ></span>
                    </div>
                </div>
                <div className="flex gap-1">
                    <button
                        type="button"
                        className="flex-shrink-0 xl:mb-2 mt-2 group inline-flex items-center justify-center bg-transparent border border-blue text-blue w-8 h-8 rounded-[3px] relative xl:mt-[52px]"
                        onClick={addTitleTranslation}
                    >
                        <span className="material-symbols-outlined text-[18px]">add</span>
                        <div className="tooltip-top">
                            <p>{strings.button.add}</p>
                        </div>
                    </button>
                </div>
            </div>
        </>
    )
}

export default AddTranslationRow;