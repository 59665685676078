import React, { useState, useRef, useEffect } from 'react';
import { Dropdown, Input, Loading, showTopAlert } from '../../../../../../../components';
import { checkNumber } from '../../../../../../../components/Utility';
import { LessonOperations } from '../../../../../../../consts/Constants';
import { useLocalization } from '../../../../../../../hooks/Utils/useLocalization';
import useAuth from '../../../../../../../hooks/Log/useAuth';
import { IsAvailableToAddLesson, LessonAdd, LessonAddDelete } from '../../../../../../../services/Term/TermService';
import { GetMemberNoteTitlesByStatus } from '../../../../../../../services/Member/MemberFormService';
import classNames from "classnames";

const LessonAddDeleteModal = (props) => {

    const { termId, memberId, currentLessonCount, sendRequestToLessonCountEndpoint, sendRequestToBeginDateAndDayEndpoint, sendRequestToEndDateAndDayEndpoint, closeButton } = props;
    const strings = useLocalization();
    const { employee } = useAuth();

    const warningRef = useRef();

    const firstOperationType = { id: -1, name: strings.member.member_detail.terms.operation_modal.lesson_operations };
    const operationTypeList = [firstOperationType, ...LessonOperations];

    const firstNoteTitle = { id: -1, name: strings.member.member_detail.terms.operation_modal.note_headers };
    const [noteTitleList, setNoteTitleList] = useState([]);

    const [lessonCount, setLessonCount] = useState(null);
    const [operationType, setOperationType] = useState(firstOperationType);
    const [noteTitle, setNoteTitle] = useState(firstNoteTitle);
    const [operationReason, setOperationReason] = useState("");
    const [defaultOperationReason, setDefaultOperationReason] = useState("");
    const [isNotAvailable, setIsNotAvailable] = useState(true);
    const [loading, setLoading] = useState(true);
    const [isRequestSend, setIsRequestSend] = useState(false);

    const getNoteTitles = async () => {
        const result = await GetMemberNoteTitlesByStatus(4);

        let arrayList = [];

        if (result.status === 200) {
            let resultContent = JSON.parse(result.content);

            resultContent?.map((note) => {
                arrayList.push({ id: note.value, name: note.text })
            });
        }

        setNoteTitleList(noteTitleList => [firstNoteTitle, ...arrayList]);
    }

    const submitHandle = async (e) => {
        e.preventDefault();
        setIsRequestSend(isRequestSend => true);

        let valid = true;

        if (lessonCount === null) {
            valid = false;
            showTopAlert(strings.alert.top_alert.inform, "warning");
        }
        if (operationType.id === -1) {
            valid = false;
            showTopAlert(strings.alert.top_alert.inform, "warning");
        }
        if (noteTitle.id === -1) {
            valid = false;
            showTopAlert(strings.alert.top_alert.inform, "warning");
        }

        if (valid) {

            if (parseInt(operationType.id) === 1) {

                const model = {
                    employeeId: employee.EmployeeId,
                    termId: termId,
                    lessonCount: parseInt(lessonCount),
                    memberNoteTitleId: +noteTitle.id,
                    description: operationReason.length > 0 ? operationReason + " " + defaultOperationReason : defaultOperationReason,
                    memberId: memberId
                };

                const result = await LessonAdd(model);

                if (result.status === 200) {
                    showTopAlert(strings.member.member_detail.terms.operation_modal.add_lesson_success, "success");

                    sendRequestToLessonCountEndpoint();
                    sendRequestToBeginDateAndDayEndpoint();
                    sendRequestToEndDateAndDayEndpoint();
                    setTimeout(() => closeButton(), 3000);
                } else {
                    setIsRequestSend(isRequestSend => false);
                    showTopAlert(strings.errors.an_error_occurred, "error");
                }

            } else {
                const model = {
                    memberId: memberId,
                    employeeId: employee.EmployeeId,
                    termId: termId,
                    lessonCount: parseInt(lessonCount),
                    memberNoteTitleId: +noteTitle.id,
                    description: operationReason.length > 0 ? operationReason + " - " + defaultOperationReason : defaultOperationReason
                };

                const result = await LessonAddDelete(model);

                if (result.status === 200) {
                    showTopAlert(strings.member.member_detail.terms.operation_modal.delete_lesson_success, "success");
                    sendRequestToLessonCountEndpoint();
                    sendRequestToBeginDateAndDayEndpoint();
                    sendRequestToEndDateAndDayEndpoint();
                    setTimeout(() => closeButton(), 3000);
                } else {
                    setIsRequestSend(isRequestSend => false);
                    showTopAlert(strings.errors.an_error_occurred, "error");
                }
            }
        } else {
            setIsRequestSend(isRequestSend => false);
        }
    }

    const isAvailableToAddLesson = async (termId) => {
        const result = await IsAvailableToAddLesson(termId)

        if (result.status === 200) {
            const resultContent = JSON.parse(result.content);
            setIsNotAvailable(isNotAvailable => resultContent.isAvailable)
            setLoading(loading => false)
        }
    }

    useEffect(() => {
        if (termId) {
            isAvailableToAddLesson(termId)
        }
        getNoteTitles();
    }, [])

    useEffect(() => {
        if (operationType.id === 0) {
            let text = `${lessonCount} adet ders silindi. Eski Ders Adeti: ${currentLessonCount} TermId: ${termId}`;
            setDefaultOperationReason(defaultOperationReason => text);
        }
        if (operationType.id === 1) {
            let text = `${lessonCount} adet ders eklendi. Eski Ders Adeti: ${currentLessonCount} TermId: ${termId}`;
            setDefaultOperationReason(defaultOperationReason => text);
        }
    }, [operationType, lessonCount]);

    return (
        <>
            {
                loading ? (
                    <>
                        <Loading />
                    </>
                ) :
                    (
                        <>
                            <div className="flex justify-center gap-1">
                                <p className="font-bold text-[15px]">{strings.member.member_detail.terms.lesson_add_delete}</p>
                            </div>
                            {
                                isNotAvailable ? (
                                    <>
                                        <p className='w-full text-center mt-3'>{strings.member.member_detail.terms.term_start_modal_error_message}</p>
                                        <button
                                            type='button'
                                            onClick={() => closeButton()}
                                            className="button search-button gap-3 text-white bg-blue border-blue mt-7 px-10 mx-auto"
                                        >
                                            {strings.button.okey_upper}
                                        </button>
                                    </>
                                ) :
                                    (
                                        <form onSubmit={submitHandle}>
                                            <div className='flex flex-col text-start gap-3 h-[500px]'>

                                                <div ref={warningRef} className='relative shadow-info px-5 py-3 rounded-md my-2'>
                                                    <p>{strings.member.member_detail.terms.operation_modal.information_message}</p>
                                                    <button type='button' className="text-[16px] font-bold absolute right-3 h-full top-0" onClick={() => warningRef.current.classList.add('hidden')}>
                                                        <span className=" opacity-80 hover:opacity-100">X</span>
                                                    </button>
                                                    <div className="h-full bg-warning absolute left-0 top-0 w-[5px] rounded-l-md"></div>
                                                </div>
                                                <div className="flex flex-col px-[10px] pb-0 w-full">
                                                    <label id="lesson_count">
                                                        {strings.member.member_detail.terms.operation_modal.lesson_count} <span className="text-red">*</span>
                                                    </label>
                                                    <Input
                                                        id="lesson_count"
                                                        type="text"
                                                        onKeyDown={(e) => !checkNumber(e) && e.preventDefault()}
                                                        onChange={(e) => setLessonCount(e.target.value)}
                                                    />
                                                </div>
                                                <div className="flex flex-col px-[10px] pb-0 w-full">
                                                    <label>
                                                        {strings.table.operation} <span className="text-red">*</span>
                                                    </label>
                                                    <Dropdown
                                                        classnames="md:max-w-full"
                                                        data={operationTypeList}
                                                        selectedData={operationType}
                                                        setSelectedData={setOperationType}
                                                    />
                                                </div>
                                                <div className="flex flex-col px-[10px] pb-0 w-full">
                                                    <label>
                                                        {strings.member.member_detail.terms.operation_modal.note_header} <span className="text-red">*</span>
                                                    </label>
                                                    <Dropdown
                                                        classnames="md:max-w-full"
                                                        data={noteTitleList}
                                                        selectedData={noteTitle}
                                                        setSelectedData={setNoteTitle}
                                                    />
                                                </div>
                                                <div className="flex flex-col px-[10px] pb-0 w-full">
                                                    <label>
                                                        {strings.member.member_detail.terms.operation_modal.operation_reason}
                                                    </label>
                                                    <textarea
                                                        id="task_note"
                                                        rows="3"
                                                        className="outline-0 py-3 px-2 border border-[#ebedf2] focus:border-blue rounded"
                                                        onChange={(e) => setOperationReason(e.target.value)}
                                                    >
                                                    </textarea>
                                                </div>
                                                <div className="flex gap-3 justify-center relative z-0">
                                                    <button
                                                        type="submit"
                                                        className={classNames("group button search-button gap-3 text-white bg-blue border-blue focus:border-blue mt-5 px-10", {
                                                            "opacity-70 hover:opacity-70 cursor-not-allowed" : isRequestSend
                                                        })}
                                                        disabled={isRequestSend}
                                                    >
                                                        {strings.button.save_upper}
                                                    </button>
                                                    <button type="button" className="button search-button gap-3 text-white bg-red border-red focus:border-red mt-5 px-10" onClick={closeButton}>{strings.button.cancel_upper}</button>
                                                </div>
                                            </div>
                                        </form>
                                    )
                            }
                        </>
                    )
            }
        </>
    )
};

export default LessonAddDeleteModal;