import React from "react";
import { ReminderTableRow } from ".";
import { useLocalization } from "../../../hooks/Utils/useLocalization";
import { NoRecordsFound } from "../../../components";

const ReminderTable = ({ data }) => {
  const strings = useLocalization();

  return (
    <div className="mx-auto px-4 my-4">
      <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
        <div className="inline-block min-w-full rounded-lg overflow-hidden">
          <table className="min-w-full leading-normal text-header">
            <thead>
              <tr>
                <th className="th"><p className="ml-6 min-w-max">{strings.table.brand}</p></th>
                <th className="th"><p className="min-w-max">{strings.table.candidate_informations}</p></th>
                <th className="th"><p className="min-w-max">{strings.member.member_detail.candidate_operations.reminder_reason}</p></th>
                <th className="th"><p className="min-w-max">{strings.member.member_detail.candidate_operations.note}</p></th>
                <th className="th"><p className="min-w-max">{strings.table.performing_person}</p></th>
                <th className="th"><p className="min-w-max">{strings.table.date}</p></th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 ? (
                data.map((reminder, index) => (
                  <ReminderTableRow 
                    key={index} 
                    reminder={reminder} 
                  />
                ))
              ) : (
                <tr className="bg-white hover:bg-[#f0f0f0]">
                  <td colSpan="6">
                    <NoRecordsFound />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ReminderTable;
