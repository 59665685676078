import React, {useEffect, useState} from "react";
import {PageTitle, Radio, showCenteredAlertTwoButtons, showTopAlert} from "../../../../../components";
import {useLocalization} from "../../../../../hooks/Utils/useLocalization";
import usePrimeData from "../../../../../hooks/Utils/usePrimeData";
import {useNavigate, useParams} from "react-router-dom";
import useMemberBrand from "../../../../../hooks/Member/useMemberBrand";
import classNames from "classnames";
import {MemberBrandChange} from "../../../../../services/Member/MemberService";
import useAuth from "../../../../../hooks/Log/useAuth";
import {url} from "../../../../../routes/utility";
import {CustomEventDescriptions, CustomEvents} from "../../../../../consts/DataLayer";
import useDataLayer from "../../../../../hooks/Log/useDataLayer";
import {ServiceErrorMessages} from "../../../../../consts/Constants";
import useMemberName from "../../../../../hooks/Member/useMemberName";
import {getBrandNameById} from "../../../../../components/Utility";

const MoveBrand = () => {

    const strings = useLocalization();
    const dataLayer = useDataLayer();
    const { memberId } = useParams();
    const { employee } = useAuth();
    const navigate = useNavigate();
    const primeData = usePrimeData();
    const memberBrand = useMemberBrand(memberId);
    const memberName = useMemberName(memberId);

    const [selectedBrand, setSelectedBrand] = useState(-1);
    const [buttonLoading, setButtonLoading] = useState(false);

    const moveBrand = async () => {
        setButtonLoading(true);

        let model = {
            memberId: memberId,
            createUserId: employee.EmployeeId,
            brandId: selectedBrand
        }

        const result = await MemberBrandChange(model);

        if(result.status === 200) {
            showTopAlert(strings.member.member_detail.others.move_brand_success_message, "success");

            //DATA LAYER LOGGED
            dataLayer.useCustomEvents(CustomEvents.EMPLOYEE_EVENTS, CustomEventDescriptions.BRAND_MOVED);

            setTimeout(() => { navigate(url("member.detail.profile", { memberId: memberId })) }, 1000);
        } else {
            if(result.content === ServiceErrorMessages.EXISTING_MEMBER_ERROR) {
                showTopAlert(
                    strings.member.member_detail.others.existing_member_error_message
                        .replace("#brand#", getBrandNameById(selectedBrand))
                        .replace("#memberName#", memberName),
                    "error"
                );
            } else {
                showTopAlert(strings.errors.an_error_occurred, "error");
            }
            setSelectedBrand(-1);
        }

        setButtonLoading(false);
    }

    const submitHandle = () => {
        showCenteredAlertTwoButtons
        (
            strings.alert.centered_alert_two_buttons.title_confirm,
            strings.alert.centered_alert_two_buttons.save_question,
            strings.button.save,
            strings.button.cancel,
            moveBrand
        )
    }

    return (
      <>
          <PageTitle title={strings.member.member_detail.header.content.move_brand} />
          <Radio
            state={selectedBrand}
            setState={setSelectedBrand}
            data={primeData.brandList.filter(brand => brand.id !== memberBrand)}
          />
          <button
              type="button"
              disabled={buttonLoading}
              className={classNames("button search-button text-white bg-blue border-blue mx-auto w-[100px] mt-5", {
                  "opacity-70 hover:opacity-70 cursor-not-allowed" : buttonLoading
              })}
              onClick={submitHandle}
          >
              {buttonLoading &&
                <div className="inline-block h-4 w-4 animate-spin rounded-full border-[3px] border-solid border-current border-r-transparent align-[-0.25em] motion-reduce:animate-[spin_1.5s_linear_infinite]"></div>
              }
              {!buttonLoading && strings.button.save_upper}
          </button>
      </>
    );
}

export default MoveBrand;