import React, { useState, useEffect } from 'react'
import classNames from 'classnames';
import { showCenteredAlertTwoButtons, showTopAlert } from '../../../../../../../../components';
import { getMemberDeviceOS, handleCheckbox, isItSpecified } from '../../../../../../../../components/Utility';
import { useLocalization } from '../../../../../../../../hooks/Utils/useLocalization';
import { CreateTermLessonApp, UpdateTermLessonApp } from '../../../../../../../../services/Term/TermService';

const CallTypeModalTableRow = (props) => {
  
  const strings = useLocalization();
  const { callType, studentNo, termId, sendRequestToEndpoint, sendRequestToCallTypeEndpoint, isRequestSend, isDisabled } = props;

  const [checkTermLessonApp, setCheckTermLessonApp] = useState(null);
  const [termLessonAppStatus, setTermLessonAppStatus] = useState(null);

  //Servisten gelen checkTermLessonApp prop'u false ise bu endpointe istek yollanır.
  //Status code 200 ise bir üst componentte'ki servise tekrar istek gönderilir ve state'ler güncellenir.
  const createTermLessonApp = async () => {

    const model = {
      studentNo: studentNo,
      memberLessonAppId: callType.id,
      termId: termId
    };

    const result = await CreateTermLessonApp(model);

    if(result.status === 200){
      showTopAlert(strings.member.member_detail.others.active_success_message, "success");
      sendRequestToEndpoint();
      sendRequestToCallTypeEndpoint();
    } else {
      showTopAlert(strings.errors.an_error_occurred, "error");
    }
  }

  //Servisten gelen checkTermLessonApp prop'u true ise bu endpointe istek yollanır.
  //Endpoint termLessonAppStatus'u aktif veya pasif duruma getirir.
  const updateTermLessonApp = async () => {

    const result = await UpdateTermLessonApp(callType.termLessonAppId);

    if(result.status === 200){
        if(termLessonAppStatus){
          showTopAlert(strings.member.member_detail.others.passive_success_message, "success");
        } else{
          showTopAlert(strings.member.member_detail.others.active_success_message, "success");
        }
        sendRequestToEndpoint();
        sendRequestToCallTypeEndpoint();
    } else {
        showTopAlert(strings.errors.an_error_occurred, "error");
    }
  }

  //Servisten gelen checkTermLessonApp değeri true ise update endpointine, false ise create endpointe istek atılır.
  const handleCallTypeStatus = () => {
    if(checkTermLessonApp){
      updateTermLessonApp();
    } else {
      createTermLessonApp();
    }
  }

  //Bir üst componentte'ki servise istek gittiğinde state'leri güncelliyoruz
  useEffect(() => {
    setCheckTermLessonApp(checkTermLessonApp => callType.checkTermLessonApp);
    setTermLessonAppStatus(termLessonAppStatus => (callType.termLessonAppStatus === "0" || callType.termLessonAppStatus === "null") ? false : true);
  }, [isRequestSend])

  return (
    <>
      <tr>
        <td className="td align-middle text-start">{callType.applicationTitle}</td>
        <td className="td align-middle text-start"><p className='min-w-max'>{callType.appLessonTypeTitle}</p></td>
        <td className="td align-middle text-start">{getMemberDeviceOS(callType.deviceOS)}</td>
        <td className="td align-middle text-start">{isItSpecified(callType.deviceName, strings.errors.it_is_not_specified)}</td>
        <td className="td align-middle text-start">
          <label
            className={`${handleCheckbox(termLessonAppStatus)} ${isDisabled ? "opacity-70 !cursor-not-allowed hover:opacity-70" : "cursor-pointer"} mr-2 w-7 h-7 rounded-[3px] duration-500 flex items-center justify-center`}
            onClick={() => {
              if(!isDisabled){
                showCenteredAlertTwoButtons(
                  strings.alert.centered_alert_two_buttons.title,
                  strings.member.member_detail.terms.modal.change_call_type_status_question,
                  strings.button.confirm,
                  strings.button.cancel,
                  handleCallTypeStatus
                );
              }
            }}         
          >
            <span className={
              classNames(
                'text-inherit text-[25px] material-symbols-outlined cursor-pointer animate-fadeIn font-bold',
                { 'hidden' : !termLessonAppStatus},
                { 'opacity-70 !cursor-not-allowed hover:opacity-70' : isDisabled}
              )
            }>
              done
            </span>
          </label>
        </td>
      </tr>
    </>
  )
}

export default CallTypeModalTableRow