import React, { useEffect, useState} from "react";
import {CopyToClipboard, SquareButton} from "../../../../components";
import { getBrandNameById, getLogoByBrandId, isItSpecified, colorForCurrentCard, maskPhoneNumber } from "../../../../components/Utility";
import { useLocalization } from "../../../../hooks/Utils/useLocalization";
import { Link } from "react-router-dom";
import { url } from "../../../../routes/utility";

const CurrentCardTableRow = (props) => {

  const strings = useLocalization();
  const { card, setModal, setModalContent, setCurrentCardId, setName } = props;

  const [cardType, setCardType] = useState(null);

  const localizationForCardType = (cardType) => {
    switch(cardType.toLocaleUpperCase()){
        case 'ŞAHIS':
            return setCardType(strings.accounting.current_card.person_upper);
        case 'KURUMSAL':
            return setCardType(strings.accounting.current_card.corporate_upper);
        case 'FIRMA':
            return setCardType(strings.accounting.current_card.firm_upper);
        default:
            return strings.errors.it_is_not_specified_upper;
    }
  }

  useEffect(() => {
    localizationForCardType(card.accountingMemberType);
  }, [card])
  
  return (
    <>
      <tr className="bg-white hover:bg-[#f0f0f0]">
        <td className="td align-top">
            <div className="relative group max-w-max ml-6">
              <img src={getLogoByBrandId(card.brand.id)} width="43" height="48" />
              <div className="tooltip-top">
                  <p>{getBrandNameById(card.brand.id)}</p>
              </div>
            </div>
        </td>
        <td className="td">
          <div className={`px-[25px] py-[6px] inline-flex justify-center items-center text-[11px] text-white rounded-full ${colorForCurrentCard(card.accountingMemberType)}`}>
            {cardType}
          </div>
          <div className="flex items-center mt-[6px]">
            <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
              person
            </span>
            <p className="font-bold min-w-max">{card.name}</p>
          </div>
          <div className="flex items-center">
            <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
              location_on
            </span>
            <p>{card.address}</p>
          </div>
        </td>
        <td className="td">
          <div className="flex items-center">
            <Link
              to={url("member.detail.profile", { memberId: card.member.id })}
              target="_blank"
              className="font-bold text-blue hover:underline focus:underline min-w-max"
            >
              {card.member.fullName}
            </Link>
          </div>
          <div className="flex items-center">
            <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
              call
            </span>
            <div className="flex items-center gap-0.5">
              <p className="min-w-max">{isItSpecified(maskPhoneNumber(card.member.phone), strings.errors.it_is_not_specified)}</p>
              <CopyToClipboard text={card.member.phone} className="-mb-1" iconClassName="!text-[13px]"/>
            </div>
          </div>
          <div className="flex items-center">
            <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
              mail
            </span>
            <p>{isItSpecified(card.member.email, strings.errors.it_is_not_specified)}</p>
          </div>
        </td>
        <td className="td">
          <div className="badge mb-2 uppercase max-w-[230px]">
            <p>
              <b>{strings.form_info.tax_office_upper}:</b> {isItSpecified(card.taxOffice, strings.errors.it_is_not_specified_upper)}
            </p>
          </div>
          <div className="badge mb-2 uppercase max-w-[230px]">
            <p className="min-w-max">
              <b>{strings.form_info.tax_number_upper} / TC:</b> {isItSpecified(card.taxNumber, strings.errors.it_is_not_specified_upper)}
            </p>
          </div>
        </td>
        <td className="td">
          <div className="flex gap-1">
            {/* <SquareButton 
              classnames="bg-green"
              iconName="edit"
              tooltip={strings.button.edit}
              action={() => { setModal(true); setModalContent({ element: "edit" }) }}
            /> */}
            <SquareButton 
              classnames="bg-blue"
              iconName="description"
              tooltip={strings.accounting.current_card.show_invoice}
              action={() => { 
                setModal(true); 
                setModalContent({ element: "invoice" }); 
                setCurrentCardId(currentCardId => card.id);
                setName(name => card.name)
              }}
            />
          </div>
        </td>
      </tr>
    </>
  );
};

export default CurrentCardTableRow;
