import classNames from "classnames";
import React, {useEffect, useRef, useState} from "react";
import {Link, Outlet, useLocation, useParams} from "react-router-dom";
import {DropdownMemberDetail, Loading} from "../../components";
import {
  Accounting,
  CandidateOperations,
  InformOperations,
  Operations,
  Others,
  References,
  SalesOperations,
  StudentOperations,
  Tasks
} from "../../consts/menu/MemberDetailMenu";
import {Brands, MemberStatus, SalesTypeNames} from "../../consts/Constants";
import {useLocalization} from "../../hooks/Utils/useLocalization";
import {url} from "../../routes/utility";
import {Term} from "./components";
import useMemberStatus from "../../hooks/Member/useMemberStatus";
import {GetMemberTerms} from "../../services/Term/TermService";
import {CheckDemoByMemberId} from "../../services/Sales/SalesService";
import {useSelector} from "react-redux";
import useMemberBrand from "../../hooks/Member/useMemberBrand";
import useMemberInPool from "../../hooks/Member/useMemberInPool";
import useAuth from "../../hooks/Log/useAuth";
import {getButtonPermission} from "../../rights/Utility";

const MemberDetailLayout = () => {

  /* Hooks */
  const strings = useLocalization();
  const { memberId } = useParams() || false;
  const location = useLocation();
  const language = useSelector(state => state.localizationStore.language);

  const memberBrandId = useMemberBrand(memberId);
  const memberStatus = useMemberStatus(memberId) || false;
  const isMemberInPool = useMemberInPool(memberId) || false;

  const { employeeTypeId, checkPermission } = useAuth();
  const memberPoolRoles = getButtonPermission({ group: "memberDetail", name: "memberDetailPoolRole" });
  const memberPoolPermission = isMemberInPool ? checkPermission(memberPoolRoles, employeeTypeId) : true;
  /* Hooks */

  const tempPathName = location.pathname;
  const pathName = tempPathName.slice(0, tempPathName.length - 37);

  const [termData, setTermData] = useState({});
  const [termLoading, setTermLoading] = useState(true);
  const [isHaveOnlyDemoPackage, setIsHaveOnlyDemoPackage] = useState(false);

  const topRef = useRef();

  const memberDetailMenu = [
    {
      isLink: true,
      title: strings.member.member_detail.header.member_profile,
      to: url("member.detail.profile", { memberId: memberId }),
      className: classNames("member-nav-button", { "member-nav-button-active": pathName === "/member/detail/profile"}),
      condition: true
    },
    {
      isLink: false,
      title: strings.member.member_detail.header.sales_operations,
      // Öğrencinin eğer demo paketi varsa ilk kayıt butonunu, demo paketinin yanında bir aktif/pasif/gelecek paket
      // veya sadece aktif/pasif/gelecek paketi var ise ikinci kayıt butonunu gösteriyoruz.
      // Bunun için bir endpointe gidip öğrencinin demo paket durumunu öğrendik ve gelen true/false değerine göre
      // kontrol sağladık
      headers: SalesOperations.filter(sales =>
          (memberStatus === MemberStatus.Student) ?
              (isHaveOnlyDemoPackage ? sales.tr !== SalesTypeNames.ExtraPackage : sales.tr !== SalesTypeNames.FirstSale)
              : sales
      ),
      memberStatus: memberStatus,
      params: { memberId: memberId },
      condition: memberPoolPermission
    },
    {
      isLink: false,
      title: strings.member.member_detail.header.student_operations,
      headers: StudentOperations,
      memberStatus: memberStatus,
      params: { memberId: memberId },
      condition: memberPoolPermission && (memberStatus === MemberStatus.Student || memberStatus === MemberStatus.Passive)
    },
    {
      isLink: false,
      title: strings.member.member_detail.header.candidate_operations,
      headers: CandidateOperations,
      memberStatus: memberStatus,
      params: { memberId: memberId },
      condition: memberPoolPermission && (memberStatus === MemberStatus.Candidate)
    },
    {
      isLink: true,
      title: strings.member.member_detail.header.applications,
      to: url("member.detail.applications", { memberId: memberId }),
      className: classNames("member-nav-button", { "member-nav-button-active": pathName === "/member/detail/applications" }),
      condition: true
    },
    {
      isLink: true,
      title: strings.member.member_detail.header.call_records,
      to: url("member.detail.callrecords", { memberId: memberId }),
      className: classNames("member-nav-button", { "member-nav-button-active": pathName === "/member/detail/callrecords" }),
      condition: memberPoolPermission && (memberBrandId === Brands.KO)
    },
    {
      isLink: false,
      title: strings.member.member_detail.header.reference,
      headers: References,
      memberStatus: memberStatus,
      params: { memberId: memberId },
      condition: memberPoolPermission
    },
    {
      isLink: false,
      title: strings.member.member_detail.header.inform_operations,
      headers: InformOperations,
      memberStatus: memberStatus,
      params: { memberId: memberId },
      condition: memberPoolPermission
    },
    {
      isLink: false,
      title: strings.member.member_detail.header.tasks,
      headers: Tasks,
      memberStatus: memberStatus,
      params: { memberId: memberId },
      condition: memberPoolPermission
    },
    {
      isLink: false,
      title: strings.member.member_detail.header.operations,
      headers: Operations,
      memberStatus: memberStatus,
      params: { memberId: memberId },
      condition: true
    },
    {
      isLink: false,
      title: strings.member.member_detail.header.accounting,
      headers: Accounting,
      memberStatus: memberStatus,
      params: { memberId: memberId },
      condition: memberPoolPermission
    },
    {
      isLink: false,
      title: strings.member.member_detail.header.other,
      headers: Others,
      memberStatus: memberStatus,
      params: { memberId: memberId },
      condition: true
    }
  ]

  //Member a ait dönem bilgileri getiriliyor
  const getMemberTerms = async (memberId, language) => {
    const result = await GetMemberTerms(memberId, language);

    if (result.status === 200) {
      //Term listei alınıyor
      let terms = JSON.parse(result.content);

      //Gelen veriyi studentNo'ya göre grupluyoruz
      const termGroup = terms.reduce((groups, item) => {
        const group = (groups[item.studentNo] || []);
        group.push(item);
        groups[item.studentNo] = group;
        return groups;
      }, {});
      
      setTermData(termGroup);
      setTermLoading(termLoading => false);
    }
  }

  // Öğrencinin sadece demo paketi var ise bu servisten true dönecek.
  // true değeri aldığımızda 'İlk Kayıt' butonunu, false değeri aldığımızda 'İkinci Kayıt' butonunu gösteriyoruz.
  const checkIsHaveOnlyDemoPackage = async (memberId) => {

    const result = await CheckDemoByMemberId(memberId);

    if(result.status === 200) {
      setIsHaveOnlyDemoPackage(isHaveOnlyDemoPackage => JSON.parse(result.content))
    }
  }

  const sendRequestToEndPoint = () => {
    if (memberId && language) {
      getMemberTerms(memberId, language);
      checkIsHaveOnlyDemoPackage(memberId);
    }
  }

  useEffect(() => {
    sendRequestToEndPoint();
  }, [memberId, language])

  useEffect(() => {
    topRef.current.focus();
  }, [location])

  return (
    <>
      <nav className="flex gap-x-5 sm:gap-x-10 flex-wrap relative z-20 mb-4 ">
        {memberDetailMenu.map((item, index) => (
            item.condition &&
              <React.Fragment key={index}>
                {item.isLink &&
                  <Link
                      to={item.to}
                      className={item.className}
                  >
                    {item.title}
                  </Link>
                }

                {!item.isLink &&
                  <DropdownMemberDetail
                    title={item.title}
                    headers={item.headers}
                    memberStatus={item.memberStatus}
                    params={item.params}
                    isMemberInPool={isMemberInPool}
                  />
                }
              </React.Fragment>
        ))}
      </nav>
      <div tabIndex={0} ref={topRef}></div>

      <Outlet />

      {Object.keys(termData).length > 0 && (
        <div className="mt-3">
          <p className="px-4 font-bold py-3">{strings.member.member_detail.header.education_packages}</p>
          <hr />
          {termLoading ? (
            <Loading />
          ) : (
            memberStatus && Object.keys(termData).length > 0 && (
              Object.keys(termData).map((studentNo, index) =>
                (
                    <Term
                        key={index}
                        studentNo={studentNo}
                        terms={termData[studentNo]}
                        memberId={memberId}
                        sendRequestToEndPoint={sendRequestToEndPoint}
                        memberPoolPermission={memberPoolPermission}
                    />
                )
              )
            )
          )}
        </div>
      )}
    </>
  );
};

export default MemberDetailLayout;
