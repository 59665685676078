import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Loading, Modal, NoRecordsFound, PageTitle } from "../../../../../../components";
import { useLocalization } from "../../../../../../hooks/Utils/useLocalization";
import { GetMemberLessonAppList } from "../../../../../../services/Member/MemberLessonAppService";
import { GetMemberBrandId } from "../../../../../../services/Member/MemberService";
import AddCallTypeModal from "./AddCallTypeModal";
import CallTypeTableRow from "./CallTypeTableRow";

const CallTypes = () => {

  const strings = useLocalization();
  const { memberId } = useParams() || false;

  const [data, setData] = useState([]);
  const [activeCallType, setActiveCallType] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [brandId, setBrandId] = useState(0);

  //Kullanıcının arama tiplerini getirir.
  const getMemberLessonAppList = async (memberId) => {
    const result = await GetMemberLessonAppList(memberId);
    
    if(result.status === 200){
      let resultContent = JSON.parse(result.content);
      setData(data => resultContent);
      let arrayList = [];
      resultContent?.map((item) => (
        arrayList.push(item.status)
      ));
      
      setActiveCallType(arrayList);
      setLoading(loading => false);
    }
  }

  //Kullanıcının brandId'sini getirir.
  const getMemberBrandId = async (memberId) => {
    const result = await GetMemberBrandId(memberId);

    if(result.status === 200){
      let resultContent = JSON.parse(result.content);
      setBrandId(brandId => +resultContent);
    }
  }

  const sendRequestToEndpoint = () => {
    getMemberLessonAppList(memberId);
  }

  const closeButton = () => {
    setModal(false);
  }

  useEffect(() => {
    sendRequestToEndpoint();
    getMemberBrandId(memberId);
  }, [])

  return (
    <>
      <PageTitle
        title={strings.member.member_detail.header.content.call_types}
      />
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="mt-5 flex justify-end px-5">
            <button
              onClick={() => setModal(true)}
              className="button search-button gap-3 text-white bg-green border-green focus:border-green"
            >
              <span className="material-symbols-outlined">add</span>
              <p>{strings.member.member_detail.others.add_call_type}</p>
            </button>
          </div>

          <div className="overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
            <div className="inline-block min-w-full rounded-lg overflow-hidden">
              <table className="min-w-full leading-normal text-header">
                <thead>
                  <tr>
                    <th className="th">{strings.member.member_detail.others.application}</th>
                    <th className="th">{strings.member.member_detail.others.substructure}</th>
                    <th className="th">{strings.member.member_detail.others.device}</th>
                    <th className="th">{strings.member.member_detail.others.app_version}</th>
                    <th className="th">{strings.member.member_detail.others.token_status}</th>
                    <th className="th">{strings.member.member_detail.others.device_name}</th>
                    <th className="th">{strings.member.member_detail.others.device_permission}</th>
                    <th className="th">{strings.form_info.status}</th>
                    <th className="th">{strings.button.delete}</th>
                    <th className="th">{strings.member.member_detail.others.call_test}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.length !== 0 ? (
                    data.map((application, index) => (
                      <CallTypeTableRow 
                        key={index}
                        index={index} 
                        application={application} 
                        activeCallType={activeCallType}
                        sendRequestToEndpoint={sendRequestToEndpoint}
                      />
                    ))
                  ) : (
                    <tr className="bg-white hover:bg-[#f0f0f0]">
                      <td colSpan={10}>
                        <NoRecordsFound />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}

    <div id="permission-modal-container"></div>

      <Modal modal={modal} setModal={setModal} classes={{ modal: "h-[400px] max-w-[600px]"}}>
          <AddCallTypeModal
            closeButton={closeButton}
            memberId={memberId}
            brandId={brandId}
            sendRequestToEndpoint={sendRequestToEndpoint}
          />
      </Modal>  
    </>
  );
};

export default CallTypes;
