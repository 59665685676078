import React, { useEffect, useState } from "react";
import { Loading, NoRecordsFound, SquareButton } from "../../../../../../../../components";
import { ISOStringToDate2 } from "../../../../../../../../components/Utility";
import { useLocalization } from "../../../../../../../../hooks/Utils/useLocalization";
import { GetTermUnits } from "../../../../../../../../services/Term/TermService";
import { SquareButtonWithoutLink } from "../../../../../../../../components/Buttons";

const UnitsModal = (props) => {
  
  const { termId, setModal, setModalContent, setModalSize, closeButton } = props;
  const strings = useLocalization();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getTermUnits = async (termId) => {
    const result = await GetTermUnits(termId);
    if (result.status === 200) {

      let resultContent = JSON.parse(result.content);
      setData((data) => resultContent.termScheduleBookSequenceList);
      setLoading((loading) => false);
    } else {
      setLoading((loading) => false);
      setError(error => result.content);
    }
  };

  useEffect(() => {
    if (termId) {
      getTermUnits(termId);
    }
  }, []);

  return (
    <>
      <div className="flex justify-center gap-1">
        <p className="font-bold text-[15px]">
          {strings.member.member_detail.terms.units}
        </p>
      </div>
      <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
        {loading ? (
          <Loading />
        ) : (
          <div className="min-w-full rounded-lg">
            <table className="min-w-full leading-normal text-header">
              <thead className="table table-fixed w-full">
                <tr>
                  <th className="th">{strings.table.date}</th>
                  <th className="th">
                    {strings.member.member_detail.terms.operation_modal.book}
                  </th>
                  <th className="th">
                    {strings.member.member_detail.terms.operation_modal.unit}
                  </th>
                  <th className="th"> 
                    {strings.table.operation}
                  </th>
                </tr>
              </thead>
              <tbody className="block overflow-y-scroll h-[45vh]">
                {data && data.length > 0 ? (
                  data.map((book, index) => (
                    <tr key={index} className="bg-white hover:bg-[#f0f0f0] sm:table table-fixed w-full flex">
                      <td className="td align-middle max-sm:flex max-sm:items-center">
                        {ISOStringToDate2(book.termScheduleDate)}
                      </td>
                      <td className="td align-middle max-sm:flex max-sm:items-center">
                        <p className="max-w-max">{book.bookUnitInformation.bookTitle}</p>
                      </td>
                      <td className="td align-middle h-[61.5px] max-sm:flex max-sm:items-center">
                        <p className="max-w-max">{book.bookUnitInformation.bookUnitTitle}</p>
                      </td>
                      <td className="td align-middle max-sm:flex max-sm:items-center">
                      {index === 0 && (
                        <SquareButtonWithoutLink
                          classnames="bg-green"
                          iconName="edit"
                          tooltip={strings.button.edit}                  
                          tooltipClassnames="tooltip"
                          action={() => {
                            setModal(true); setModalContent({ element: "updateunit" }); setModalSize({ size: "middle" }); 
                          }}
                        />
                      )}
                      </td>
                    </tr>
                  ))
                ) : (                  
                    <tr className="bg-white hover:bg-[#f0f0f0] table table-fixed w-full">
                      <td colSpan="4">
                        {error ? (
                          <div className="py-4 min-w-full text-center">{error}</div>
                        ) : (
                          <NoRecordsFound />
                        )}
                      </td>
                    </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
};

export default UnitsModal;
