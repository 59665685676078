import {useEffect, useState} from "react";
import {IsMemberInPool} from "../../services/Member/MemberPoolService";

const useMemberInPool = (memberId) => {

    const [isMemberInPool, setIsMemberInPool] = useState(false);

    useEffect(() => {
        if(memberId.length > 0)
            IsMemberInPool(memberId)
                .then(result => {
                    if(result.status === 200) {
                        setIsMemberInPool(JSON.parse(result.content));
                    }
                })
                .catch()
    }, []);

    return isMemberInPool;
}

export default useMemberInPool;