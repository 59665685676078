import React, {useEffect, useState} from 'react';
import {DetailSearchLayout, DropdownSearch} from '../../../../components';
import {useLocalization} from '../../../../hooks/Utils/useLocalization';
import classNames from "classnames";
import usePrimeData from "../../../../hooks/Utils/usePrimeData";

const TeacherProgramDetailSearch = (props) => {

    const { changeDetail, setSendToEndpoint, setIsShowContent, loading } = props;

    const strings = useLocalization();
    const primeData = usePrimeData();

    const firstTeacher = { id: -1, name: strings.form_info.choose_teacher };
    const [teacher, setTeacher] = useState(firstTeacher);

    useEffect(() => {
        changeDetail?.({ teacherId: teacher?.id, teacherName: teacher?.name });
    }, [teacher])

    return (
        <>
            <DetailSearchLayout
                isDisabled={loading}
                open={true}
                children={
                    <div className="!flex gap-x-4 gap-y-5 max-md-lg:justify-center items-center">
                        <DropdownSearch
                            title={strings.detail_search_titles.teachers}
                            inputPlaceholder={strings.detail_search_titles.search_teacher}
                            data={[firstTeacher, ...primeData.teacherListWithShifts]}
                            selectedData={teacher}
                            setSelectedData={setTeacher}
                            classnames="md:!max-w-[480px]"
                            heightClassnames="!max-h-[200px]"
                            isDisabled={loading}
                        />
                        <button
                            type="button"
                            className={classNames("button search-button text-white bg-blue border-blue w-[100px]", {
                                "opacity-70 hover:opacity-70 cursor-not-allowed" : teacher.id === -1
                            })}
                            disabled={teacher.id === -1}
                            onClick={() => {setSendToEndpoint(true); setIsShowContent(false);}}
                        >
                            {strings.button.list_upper}
                        </button>
                    </div>
                }
            />
        </>
    )
}

export default TeacherProgramDetailSearch