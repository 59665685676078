import React, { useRef, useState } from 'react'
import { useLocalization } from '../../hooks/Utils/useLocalization';
import { validateEmail } from '../../components/Utility';
import { EmployeeForgotPassword } from '../../services/Auth/AuthService';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { url } from '../../routes/utility';
import { showTopAlert } from '../../components';

const ForgotPassword = () => {

    const strings = useLocalization();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const [email, setEmail] = useState();
    const emailErrorRef = useRef();

    const emailHandle = e => {
        setEmail(email => e.target.value);
    };

    const submitHandle = async e => {
        e.preventDefault();

        //tüm hataları sıfırla
        emailErrorRef.current.innerText = '';

        const emailStatus = validateEmail(email);

        if (emailStatus) {

            const result = await EmployeeForgotPassword(email);

            //Gönderim başarılı ise
            if (result.status === 200) {
                navigate(url("auth.verifycode"), { state: { employeeId: result.content, "from": pathname } });
            } else {
                //Gönderim başarısız ise
                showTopAlert(strings.errors.an_error_occurred, "error");
            }

        } else {

            // hata varsa ekrana yaz
            emailErrorRef.current.innerText = !emailStatus ? strings.errors.email_format_error : '';
        }
    }

    return (
        <>
            <form className="w-full flex justify-center" onSubmit={submitHandle}>
                <div
                    className="w-[400px] animate-fadeIn p-[25px] md:py-[60px] rounded-[5px] block bg-white border border-[#ebecec] shadow-form mx-5 mt-5 md:mt-0">
                    <h3 className="font-bold text-center text-[19px] md:mb-[25px]">{strings.auth.forgot_password.title}</h3>
                    <div className="login-form">
                        <div className="flex flex-col px-[10px] pb-0">
                            <label className="text-[85%]" htmlFor="email" >{strings.auth.forgot_password.your_email}</label>
                            <input id="email" type="text" className="form-input" name="email" onChange={emailHandle} />
                            <span ref={emailErrorRef} className="text-danger field-validation-valid"></span>
                        </div>
                        <div className=" px-[10px] pt-[10px] md:pt-[25px]">
                            <button type="submit" className="btn btn-primary w-full">{strings.auth.forgot_password.button}</button>
                        </div>
                        <div className="flex justify-center px-3 pb-2 pt-4">
                            <Link to={url("auth.login")} type="button" className="text-blue hover:underline">{strings.auth.forgot_password.login}</Link>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default ForgotPassword