import { fetchApi } from '../index';

export const CampaignFilter = async (model) => {
    
    const url = '/member/v2/campaign/filter/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const CampaignChangeStatus = async (model) => {

    const url = '/member/v1/campaign/campaign_change_status/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

//Kampanya oluşturmak için kullanılan servis.
export const CreateCampaign = async (model) => {

    const url = '/member/v1/campaign/create_campaign/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

//Hediye paket için kod oluşturan servis.
export const CreateGiftPackageCampaignCode = async (employeeId) => {

    const url = '/member/v1/campaign/create_gift_package_campaign_code/';

    const payload = {
        employeeId: employeeId
    };

    return await fetchApi(url, payload);
}

//Kampanya kodunun olup olmadığını kontrol eden servis
export const CheckCampaignCodeExist = async (campaignCode) => {

    const url = '/member/v1/campaign/check_campaign_code_exist/';

    const payload = {
        campaignCode: campaignCode
    };

    return await fetchApi(url, payload);
}

export const GetCampaignDetailsByCampaignId = async (campaignId) => {

    const url = '/member/v1/campaign/get_campagin_details_by_campaign_id/';

    const payload = {
        campaignId: campaignId
    };

    return await fetchApi(url, payload);
}