import {useEffect, useState} from "react";
import {GetNetworkLogs} from "../../../../../../../../../services/International/InternationalService";
import {fullDateToShortDate2,fullDateToShortDate} from "../../../../../../../../../components/Utility";
import {useLocalization} from "../../../../../../../../../hooks/Utils/useLocalization";
import {Loading, NoRecordsFound} from "../../../../../../../../../components";
import {Accordion} from "../../../../../../index";
import TeacherNetworkLogTable from "./TeacherNetworkLogTable";
import {CheckAutoDemoAndMissedCallStatus} from "../../../../../../../../../services/Term/TermService";

const TeacherNetworkLog = ({ lessonDate, studentNo }) => {

    const strings = useLocalization();

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [tempStudentNo, setTempStudentNo] = useState(null);
    const [tempLessonDate, setTempLessonDate] = useState(null);

    //ID'ye göre verileri gruplayan fonksiyon.
    const groupDataById = (data) => {
        const groupedData = {};
        data.forEach(item => {
            const id = item.id;
            if (!groupedData[id]) {
                groupedData[id] = [];
            }
            groupedData[id].push(item);
        });
        return groupedData;
    }

    useEffect(() => {
        if(lessonDate && studentNo) {
            //Gelen talep otomatik demo ise MC student no ve tarihi ile gideceğiz
            let model = {lessonDate: fullDateToShortDate(lessonDate), studentNo: studentNo};

            CheckAutoDemoAndMissedCallStatus(model).then(result => {
                if(result.status === 200) {
                    let resultContent = JSON.parse(result.content);

                    // Eğer gelen değer missed call değeri taşıyorsa istek atacağımız
                    // lessonDate ve studentNo değerlerini değiştiriyoruz
                    if (resultContent.isMissedCall) {
                        const [year, month, day] = resultContent.lessonDate.split('-').map(Number);
                        setTempLessonDate (new Date(year, month - 1, day, 0, 0, 0));
                        setTempStudentNo(resultContent.studentNo);
                    } else {
                        setTempStudentNo(studentNo);
                        setTempLessonDate(lessonDate);
                    }
                }
            }).catch();

        }
    }, []);

    useEffect(() => {

        if (tempLessonDate && tempStudentNo) {
            // Yurtdışı arama logları verisi servisten getiriliyor
            GetNetworkLogs(tempStudentNo, fullDateToShortDate2(tempLessonDate))
                .then(result => {
                    if(result.status === 200) {
                        //console.log(fullDateToShortDate2(tempLessonDate), " - " ,tempStudentNo);
                        /*
                            * Servisten veri bir array içerisinde gelmediği için bu kısımda veriyi maplemek için
                            * string interpolation kullanarak bir array haline getirdik ve sonrasında JSON'a parse ettik.
                        */
                        const formattedJSON = `[${result.content}]`
                        const resultContent = JSON.parse(formattedJSON);


                        resultContent.length > 0
                            ? setData(groupDataById(resultContent))
                            : setData([]);

                        setLoading(false);
                    }
                })
                .catch()
        }

    }, [tempLessonDate, tempStudentNo]);

    return (
        <>
            {loading && <Loading />}

            {!loading &&
                <div className="h-[65vh] px-2 overflow-y-auto">
                    {data.length === 0 && <NoRecordsFound />}

                    {Object.keys(data).length > 0 &&
                        Object.values(data).map((item, index) => (
                            <Accordion
                                key={index}
                                outSideClose={false}
                                title={
                                    strings.member.member_detail.terms.operation_modal.foreign_call_logs.call
                                        .replace("#index#", (index + 1).toString())
                                }
                                content={<TeacherNetworkLogTable data={item} />}
                            />
                        ))
                    }
                </div>
            }
        </>
    )
}

export default TeacherNetworkLog;