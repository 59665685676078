import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Loading, showTopAlert } from '../../../../../../components';
import { ISOStringToDate, calcTaxPrice, formatCurrency, getCompanyLogoByName, isItSpecified } from '../../../../../../components/Utility';
import useAuth from '../../../../../../hooks/Log/useAuth';
import useMemberBrand from '../../../../../../hooks/Member/useMemberBrand';
import { useLocalization } from '../../../../../../hooks/Utils/useLocalization';
import usePrimeData from '../../../../../../hooks/Utils/usePrimeData';
import { GetInvoicePreview } from '../../../../../../services/Member/MemberInvoiceService';

const InvoicePreviewModal = (props) => {

  const { classes, invoiceId, closeButton } = props;
  const { employee } = useAuth();
  const primeData = usePrimeData();
  const { memberId } = useParams() || false;
  const memberBrandId = useMemberBrand(memberId);

  const strings = useLocalization();
  const notSpecified = strings.errors.it_is_not_specified;
  
  const [data, setData] = useState([]);
  const [invoiceDetail, setInvoiceDetail] = useState([]);
  const [loading, setLoading] = useState(true);

  const [companyDetails, setCompanyDetails] = useState({});
  
  const getInvoicePreview = async (invoiceId) => {

    const result = await GetInvoicePreview(invoiceId);

    if(result.status === 200){

        let resultContent = JSON.parse(result.content);

        setData(data => resultContent);
        setInvoiceDetail(invoiceDetail => resultContent.invoiceDetails);
        setLoading(loading => false);
    } else {
        showTopAlert(strings.errors.an_error_occurred, "error");
        setTimeout(() => closeButton(), 1000);
    }
  }

  const getLocalCompanyDetailsByName = (name) => {
    let companyDetails = primeData.localCompanyDetails;
    let companyObject = {};

    companyDetails?.map((item) => {
        if(item.name === data.companyName){
            companyObject = item;
        }
    })

    setCompanyDetails(companyDetails => companyObject);
  }

  //Her bir satıra ait toplam tutarı hesaplar.
  const calcTotalPriceForPackage = (unitPrice, taxValue, quantity) => {
    let taxPrice = memberBrandId === 1 ? calcTaxPrice(unitPrice, taxValue) : 0;
    return (parseFloat(unitPrice) + taxPrice) * quantity;
  }

  //Mal Hizmet toplam tutarını hesaplar.
  const calcTotalUnitPrice = () => {
    let result = 0.0;

    invoiceDetail.map((detail) => {
        result += (detail.quantity * parseFloat(detail.unitPrice))
    })

    return result;
  }

  //Hesaplanan KDV Gerçek tutarını hesaplar.
  const calcTotalTaxPrice = () => {
    let result = 0.0;

    memberBrandId === 1 && (
        invoiceDetail.map((detail) => {
            result += calcTaxPrice(detail.unitPrice, detail.taxValue);
        })
    )
    
    return result;
  }

  //Vergiler Dahil Toplam Tutar hesaplar.
  const calcTaxValueAddedPrice = () => {
    let totalUnitPrice = calcTotalUnitPrice();
    let totalTaxPrice = calcTotalTaxPrice();

    let result = parseFloat(totalUnitPrice) + parseFloat(totalTaxPrice);

    return formatCurrency(result);
  }

  useEffect(() => {
    if(invoiceId){
        getInvoicePreview(invoiceId);
    }
    calcTotalPriceForPackage();
    calcTotalUnitPrice();
    calcTotalTaxPrice();
  }, [])

  useEffect(() => {
    getLocalCompanyDetailsByName(data.name);
  }, [data])

  return (
    <>
        <div className="flex justify-center">
            <p className="font-bold text-[15px]">{strings.member.member_detail.accounting.invoice_preview}</p>
        </div>
        {loading ? (
            <Loading />
        ) : (
            <div className={`overflow-y-scroll ${classes}`}>
                <div className="flex flex-col gap-4 mt-5 px-[10px]">
                    <div className="flex flex-col md:flex-row gap-3 md:justify-between w-full">
                        <div>
                            <img src={getCompanyLogoByName(companyDetails.name)} width="200" alt="rf-logo" />
                            <p className="font-bold">
                                {companyDetails.name}
                            </p>
                            <p className='max-w-[400px]'>
                                {companyDetails.address}
                            </p>
                            <p><span className='font-bold'>{strings.form_info.tax_number}:</span> {companyDetails.taxNumber} ({companyDetails.taxOffice})</p>
                        </div>
                        <div>
                            <p><span className='font-bold'>{strings.form_info.dear},</span> {data.name}</p>
                            <p><span className='font-bold'>{strings.form_info.city}:</span> {data.address}</p>
                            <p><span className='font-bold'>{strings.form_info.tax_number}:</span> {isItSpecified(data.taxNumber ,notSpecified)}</p>
                            <p><span className='font-bold'>{strings.form_info.tax_office}:</span> {isItSpecified(data.taxOffice ,notSpecified)}</p>
                            <p><span className='font-bold'>{strings.form_info.invoice_date}:</span> {ISOStringToDate(data.invoiceDate, employee.TimeZone)}</p>
                            <p><span className='font-bold'>{strings.form_info.payment_date}:</span> {ISOStringToDate(data.paymentDate, employee.TimeZone)}</p>
                        </div>
                    </div>
                    <div className="overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
                        <div className="inline-block min-w-full rounded-lg overflow-hidden">
                            <table className="min-w-full leading-normal text-header">
                                <thead>
                                    <tr>
                                        <th className="th">
                                            <p>{strings.table.number}</p>
                                        </th>
                                        <th className="th">
                                            <p className='min-w-[150px]'>{strings.table.service_product_name}</p>
                                        </th>
                                        <th className="th">
                                            <p className='min-w-[200px]'>{strings.form_info.description}</p>
                                        </th>
                                        <th className="th">
                                            <p>{strings.table.amount}</p>
                                        </th>
                                        <th className="th">
                                            <p>{strings.table.unit_price}</p>
                                        </th>
                                        <th className="th">
                                            <p>{strings.form_info.kdv_rate}</p>
                                        </th>
                                        <th className="th">
                                            <p>{strings.table.total}</p>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {invoiceDetail.length > 0 && invoiceDetail.map((detail, index) => (
                                        <tr key={index} className='bg-white hover:bg-[#f0f0f0]'>
                                            <td className='td align-middle'>
                                                <p>{index + 1}</p>
                                            </td>
                                            <td className='td align-middle'>
                                                <p>{detail.productName}</p>
                                            </td>
                                            <td className='td align-middle'>
                                                <p>{detail.description}</p>
                                            </td>
                                            <td className='td align-middle'>
                                                <p>{detail.quantity}</p>
                                            </td>
                                            <td className='td align-middle'>
                                                <p>{formatCurrency(detail.unitPrice)}</p>
                                            </td>
                                            <td className='td align-middle'>
                                                <p>%{memberBrandId === 1 ? detail.taxValue : 0}</p>
                                            </td>
                                            <td className='td align-middle'>
                                                <p>{formatCurrency(calcTotalPriceForPackage(detail.unitPrice, detail.taxValue, detail.quantity))}</p>
                                            </td>
                                            
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <table className="mt-5 p-2 ml-auto max-w-[500px] pr-3">
                    <tbody>
                        <tr>
                            <td className="td">{strings.member.member_detail.accounting.goods_service_total_amount}</td>
                            <td className="td"><p className='min-w-max'>{formatCurrency(calcTotalUnitPrice())}</p></td>
                        </tr>
                        <tr>
                            <td className="td">{strings.member.member_detail.accounting.calculated_kdv_real}</td>
                            <td className="td"><p className='min-w-max'>{formatCurrency(calcTotalTaxPrice())}</p></td>
                        </tr>
                        <tr>
                            <td className="font-bold td">{strings.member.member_detail.accounting.tax_includes_total_amount}</td>
                            <td className="font-bold td"><p className='min-w-max'>{calcTaxValueAddedPrice()}</p></td>
                        </tr>
                    </tbody>
                </table>
                <p className="font-bold mt-4">{strings.member.member_detail.accounting.invoice_note}</p>
                <p>{data.description}</p>
            </div>
        )}
    </>
  )
};

export default InvoicePreviewModal;