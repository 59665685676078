import { fetchApi } from '../index';

export const GetReferenceDetail = async (memberId) => {

    const url = '/member/v1/member_reference/get_reference_detail/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const AddReferrer = async (memberId, referenceCode, createUserId) => {

    const url = '/member/v1/member_reference/add_reference_code/';

    const payload = {
        memberId: memberId,
        referenceCode: referenceCode,
        createUserId: createUserId
    };

    return await fetchApi(url, payload);
}

export const CreateReferenceService = async (model) => {

    const url = '/member/v1/member_reference/create_reference/';

    const payload = {
       ...model
    };

    return await fetchApi(url, payload);
}

export const GetReferencedMembers = async (memberId) => {

    const url = '/member/v1/member_reference/get_referenced_members/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const GetMoneyBoxOperationDetails = async (memberId) => {

    const url = '/member/v1/member_reference/get_money_box_operation_details/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const GetTotalMoneyBoxRate = async (memberId) => {

    const url = '/member/v1/member_reference/get_total_money_box_rate/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const MoneyBoxOperations = async (model) => {

    const url = '/member/v1/member_reference/money_box_operations/';

    const payload = {
       ...model
    };

    return await fetchApi(url, payload);
}

export const ReferenceMemberList = async (model) => {

    const url = '/member/v1/member_reference/reference_member_list/';

    const payload = {
       ...model
    };

    return await fetchApi(url, payload);
}