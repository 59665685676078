import {PageTitle} from "../../components";
import {Accordion} from "../Member/components";
import React from "react";
import {useLocalization} from "../../hooks/Utils/useLocalization";

const LessonAttendReport = () => {

    const strings = useLocalization();

    return(
        <>
            <PageTitle title={strings.sidebar.report.lesson_attend} />

            <Accordion
                title={strings.report.lesson_attend.information_title}
                outSideClose={true}
                isOpen={true}
                content={
                    <div>
                        <p dangerouslySetInnerHTML={{ __html: strings.report.lesson_attend.information_one}}></p>
                    </div>

                }
            />
            <div className="flex justify-center mt-5">
                <iframe title="lesson-attend" width="1725" height="1725" src="https://lookerstudio.google.com/embed/reporting/0ad9a7a8-3465-4136-ac86-dd392b3ad12f/page/3UtkD" frameBorder="0" allowFullScreen></iframe>
            </div>
        </>
    )
}

export default LessonAttendReport;