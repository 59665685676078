import { fetchApi } from '../index';

export const AddDemand = async (model) => {

    const url = '/member/v1/member_demand/add_demand/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const GetDemandList = async (memberId) => {

    const url = '/member/v1/member_demand/get_demand_list/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const DeleteDemand = async (demandId) => {

    const url = '/member/v1/member_demand/delete/';

    const payload = {
        demandId: demandId
    };

    return await fetchApi(url, payload);
}

export const UndoDeleteDemand = async (demandId) => {

    const url = '/member/v1/member_demand/undo_delete/';

    const payload = {
        demandId: demandId
    };

    return await fetchApi(url, payload);
}