import dayGridPlugin from '@fullcalendar/daygrid';
import FullCalendar from '@fullcalendar/react';
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import '../../../../../../../assets/css/full-calendar.css';
import {Loading, Modal, showTopAlert} from '../../../../../../../components';
import {ColorByLessonType, TermStatusType} from '../../../../../../../consts/Constants';
import {isItSpecified} from '../../../../../../../components/Utility';
import {useLocalization} from '../../../../../../../hooks/Utils/useLocalization';
import {url} from '../../../../../../../routes/utility';
import {
  GetTermBeginDateAndDay,
  GetTermEndDateAndDay,
  GetTermInformationByTermId,
  GetTermStudentName
} from '../../../../../../../services/Term/TermService';
import {TermScheduleListByTermId} from '../../../../../../../services/TermSchedule/TermScheduleService';
import LessonDetailModal from './LessonDetailModal';

const TermSchedule = () => {

  const strings = useLocalization();
  const navigate = useNavigate();

  const { termId } = useParams();

  const [termInformations, setTermInformations] = useState({
    productName: "",
    studentNo: "",
    memberId: "",
    termStatus: -1,
  });

  const [modal, setModal] = useState(false);
  const [data, setData] = useState([]);
  const [event, setEvent] = useState([]);
  const [loading, setLoading] = useState(true);
  const [scheduleId, setScheduleId] = useState(null);
  const [lessonDate, setLessonDate] = useState(null);
  const [lessonStatus, setLessonStatus] = useState(0);
  const [memberName, setMemberName] = useState("");
  const [termStartDate, setTermStartDate] = useState("");
  const [termEndDate, setTermEndDate] = useState("");
  const [windowWidth, setWindowWidth] = useState(0);

  // const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  // const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  //Takvim başlığındaki verileri getirir
  const getTermInformationsByTermId = async (termId) => {
    const result = await GetTermInformationByTermId(termId);
    if(result.status === 200) {
      let resultContent = JSON.parse(result.content);
      setTermInformations({
        productName: resultContent.productName,
        studentNo: resultContent.studentNo,
        memberId: resultContent.memberId,
        termStatus: resultContent.termStatus
      })
    }
  }
  //Takvim içerisindeki verileri getirir.
  const getTermScheduleListByMonthAndYear = async (model) => {

    const result = await TermScheduleListByTermId(model);

    if (result.status === 200) {
      let resultContent = JSON.parse(result.content);

      setData(data => resultContent);
    } else {
      showTopAlert(strings.errors.an_error_occurred, "error");
    }
    setLoading(loading => false);
  }

  //Öğrenci adını getirir
  const getMemberNameByTermId = async (memberId) => {

    const result = await GetTermStudentName(memberId);
    
    if (result.status === 200) {
      setMemberName(memberName => result.content);
    }
  }

  //Dönem başlangıç tarihi için servis (Gelecek dönem olan takvimlerde kullanılıyor)
  const getTermBeginDateAndDay = async (termId) => {
    const result = await GetTermBeginDateAndDay(termId);

    if (result.status === 200) {
      let resultContent = JSON.parse(result.content);
      setTermStartDate(termStartDate => resultContent.date.split("T")[0]);
    }
  }

  //Dönem bitiş tarihi için servis (Pasif dönem olan takvimlerde kullanılıyor)
  const getTermEndDateAndDay = async (termId) => {
    const result = await GetTermEndDateAndDay(termId);

    if (result.status === 200) {
      let resultContent = JSON.parse(result.content);

      setTermEndDate(termEndDate => resultContent.date.split("T")[0]);
    }
  }

  const colorForStatus = (status) => {
    switch (status) {
      case 1:
        return ColorByLessonType.Active;
      case 2:
        return ColorByLessonType.Attented;
      case 3:
        return ColorByLessonType.Missed;
      case 4:
        return ColorByLessonType.Postponed;
      case 5:
        return ColorByLessonType.Frozen;
      case 6:
        return ColorByLessonType.Holiday;
      case 7:
        return ColorByLessonType.Weekend;
      case 8:
        return ColorByLessonType.NoLesson;
      case 9:
        return ColorByLessonType.SystemPostponed;
      case 10:
        return ColorByLessonType.Deleted;
      default:
        return "ff0000";
    }
  }

  // TermStatus'e göre takvimin başlangıç tarihini belirler.
  const getInitialDateByTermStatus = (termStatus) => {
    if(termStatus !== -1) {
      switch (termStatus) {
        case TermStatusType.Passive:
          return termEndDate ? termEndDate : new Date();
        case TermStatusType.Active:
          return new Date();
        case TermStatusType.Future:
          return termStartDate ? termStartDate : new Date();
        default:
          return new Date();
      }
    }
  }

  //Missed ve Active Lesson'da modal açılma durumu endpoint tamamlanınca eklenecektir.
  const renderEventContent = (eventInfo) => {
    const status = eventInfo.event.extendedProps.status;
    const id = eventInfo.event.extendedProps.scheduleId;
    const title = eventInfo.event.title;
    
    return (
      <div
        className={`-m-2 p-2 group relative ${(status === 1 || status === 2 || status === 3) ? 'cursor-pointer' : 'cursor-default'} ${(status === 8 || status === 9) ? 'text-base-text' : 'text-white'}`}
        onClick={() => {
          if (status === 1 || status === 2 || status === 3) {
            setModal(modal => true);
            setScheduleId(scheduleId => id);
            setLessonStatus(lessonStatus => status);
            setLessonDate(lessonDate => eventInfo.event.start);
          }
        }}
      >
        <b>{title}</b>
        {( (status === 1 || status === 2) && ((windowWidth <= 1669) || (windowWidth > 1669 && title.length > 35))  ) && (
          <div className='tooltip-top'>
            <p>{title}</p>
          </div>
        )}
      </div>
    )
  }

  const calendarOptions = {
    themeSystem: 'myCustomTheme',
    initialView: 'dayGridMonth',
    firstDay: 1,
    initialDate: getInitialDateByTermStatus(termInformations.termStatus),
    locale: "tr",
    weekends: true,
    showNonCurrentDates: false,
    buttonText: {
      today: strings.member.member_detail.terms.operation_modal.today,
    },
    events: [
      ...event
    ],
    eventMouseEnter: function (eventInfo) {
      if (eventInfo.event.extendedProps.status === 1) {
        eventInfo.el.style.backgroundColor = '#2B6DC6';
        eventInfo.el.style.borderColor = '#2B6DC6';
      }
      else if (eventInfo.event.extendedProps.status === 2) {
        eventInfo.el.style.backgroundColor = '#55ab6c';
        eventInfo.el.style.borderColor = '#55ab6c';
      }
      else if (eventInfo.event.extendedProps.status === 3) {
        eventInfo.el.style.backgroundColor = '#EB444D';
        eventInfo.el.style.borderColor = '#EB444D';
      }
    },
    eventMouseLeave: function (eventInfo) {
      if (eventInfo.event.extendedProps.status === 1) {
        eventInfo.el.style.backgroundColor = ColorByLessonType.Active;
        eventInfo.el.style.borderColor = ColorByLessonType.Active;
      }
      else if (eventInfo.event.extendedProps.status === 2) {
        eventInfo.el.style.backgroundColor = ColorByLessonType.Attented;
        eventInfo.el.style.borderColor = ColorByLessonType.Attented;
      }
      else if (eventInfo.event.extendedProps.status === 3) {
        eventInfo.el.style.backgroundColor = ColorByLessonType.Missed;
        eventInfo.el.style.borderColor = ColorByLessonType.Missed;
      }
    }
  }

  const closeButton = () => {
    setModal(modal => false);
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (termId) {
      //Başlıkta yazan bilgileri getiren endpointe gidiyoruz.
      getTermInformationsByTermId(termId);
    }
  }, [termId])

  useEffect(() => {
    if(termInformations.termStatus !== -1) {
      if(termInformations.termStatus === TermStatusType.Passive){
        getTermEndDateAndDay(termId);
      } else if (termInformations.termStatus === TermStatusType.Future) {
        getTermBeginDateAndDay(termId);
      }
    }
  }, [termInformations]);

  /*
    termId, görüntülenen tarih, görüntülenen yıl değiştikçe servise gidiyoruz ve uzun loading
    süresi olmasını önlüyoruz.
  */
  useEffect(() => {

    let abortController = new AbortController();
    
    if(termId) {
      getTermScheduleListByMonthAndYear(termId);
    }

    return () => {
      abortController.abort();
    }
    
  }, [termId])

  useEffect(() => {
    termInformations.memberId.length > 0 && getMemberNameByTermId(termId);
  }, [termInformations])

  useEffect(() => {
    const notSpecified = strings.errors.it_is_not_specified;

    let arrayList = [];

    data.length > 0 && data.map((item) => {
      arrayList.push({
        scheduleId: item.scheduleId,
        title: isItSpecified(item.bookTitle, notSpecified),
        start: item.startTime.split("T")[0],
        end: item.endTime.split("T")[0],
        status: item.status,
        color: colorForStatus(item.status)
      });
      setEvent(event => arrayList);
    });
  }, [data])

  return (
    <>
      <p className="my-2 text-[14px] h-[35px] leading-[35px] text-base-text bg-card px-5 rounded-md flex flex-col md:flex-row md:justify-between max-md:mb-20">
        <span className='font-bold'>{strings.member.member_detail.terms.lesson_archive}</span>
        {memberName &&
          <span> 
            <span className='text-blue font-bold hover:opacity-70 cursor-pointer' onClick={() => navigate(url("member.detail.profile", { memberId: termInformations.memberId }))}> {memberName} </span>
            - {termInformations.studentNo} - {termInformations.productName}
          </span>
        }
      </p>
      <div className='mt-5'>
            {loading ? (
              <Loading />
            ) : (
              <FullCalendar
                plugins={[dayGridPlugin]}
                {...calendarOptions}
                eventContent={renderEventContent}
                // datesSet={(arg) => {
                //   setSelectedMonth(selectedMonth => arg.start.getMonth() + 1);
                //   setSelectedYear(selectedYear => arg.start.getFullYear());
                // }}
              />
            )}
      </div>
      <Modal modal={modal} setModal={setModal} classes={{ modal: 'h-[90vh] max-w-[1140px]', content: "max-md:!h-[85vh]" }}>
        <LessonDetailModal
          scheduleId={scheduleId}
          lessonStatus={lessonStatus}
          lessonDate={lessonDate}
          studentNo={termInformations.studentNo}
          memberId={termInformations.memberId}
          closeButton={closeButton}
        />
      </Modal>
    </>
  )
};

export default TermSchedule;
