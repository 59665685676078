import React from "react";

const ContentCount = (props) => {

  const { classnames, whiteText, greenText, whiteCount, greenCount, blueText, blueCount, redText, redCount, warningText, warningCount, purpleText, purpleCount, dangerText, dangerCount } = props;

  const classname = typeof classnames === "undefined" ? '' : classnames;

  const formattedWhiteCount = whiteCount === undefined || whiteCount === null ? '' : whiteCount;
  const formattedGreenCount = greenCount === undefined || greenCount === null ? '' : greenCount;
  const formattedBlueCount = blueCount === undefined || blueCount === null ? '' : blueCount;
  const formattedRedCount = redCount === undefined || redCount === null ? '' : redCount;
  const formattedWarningCount = warningCount === undefined || warningCount === null ? '' : warningCount;
  const formattedPurpleCount = purpleCount === undefined || purpleCount === null ? '' : purpleCount;
  const formattedDangerCount = dangerCount === undefined || dangerCount === null ? '' : dangerCount;


  return (
    <>
      <hr className={`mb-4 ${classname}`} />
      <div className="flex flex-col w-full items-end px-4">
        <div className="max-xl:pt-0 max-xl:pb-3 mb-4 flex gap-3 sm:gap-x-5 max-xl:px-4 max-xl:mx-2 max-md:flex-col max-md:float-right">
            { whiteText && (
                <div className="flex gap-2">
                  <div className="w-5 h-5 bg-white border border-base-text rounded-[3px] flex-shrink-0"></div>
                  <div dangerouslySetInnerHTML={{ __html: whiteText.replace(/#count#/g, formattedWhiteCount)}} />
                </div>
            )}
            { greenText && (
                <div className="flex gap-2">
                  <div className="w-5 h-5 bg-green rounded-[3px] flex-shrink-0"></div>
                  <div dangerouslySetInnerHTML={{ __html: greenText.replace(/#count#/g, formattedGreenCount)}} />
                </div>
            )}
            { blueText && (
                <div className="flex gap-2">
                  <div className="w-5 h-5 bg-blue rounded-[3px] flex-shrink-0"></div>
                  <div dangerouslySetInnerHTML={{ __html: blueText.replace(/#count#/g, formattedBlueCount)}} />
                </div>
            )}
            { dangerText && (
                <div className="flex gap-2">
                  <div className="w-5 h-5 bg-danger rounded-[3px] flex-shrink-0"></div>
                  <div dangerouslySetInnerHTML={{ __html: dangerText.replace(/#count#/g, formattedDangerCount)}} />
                </div>
            )}
            { purpleText && (
                <div className="flex gap-2">
                  <div className="w-5 h-5 bg-[#6861ce] rounded-[3px] flex-shrink-0"></div>
                  <div dangerouslySetInnerHTML={{ __html: purpleText.replace(/#count#/g, formattedPurpleCount)}} />
                </div>
            )}            
            { redText && (
                <div className="flex gap-2">
                  <div className="w-5 h-5 bg-red rounded-[3px] flex-shrink-0"></div>
                  <div dangerouslySetInnerHTML={{ __html: redText.replace(/#count#/g, formattedRedCount)}} />
                </div>
            )}
            { warningText && (
                <div className="flex gap-2">
                  <div className="w-5 h-5 bg-warning rounded-[3px] flex-shrink-0"></div>
                  <div dangerouslySetInnerHTML={{ __html: warningText.replace(/#count#/g, formattedWarningCount)}} />
                </div>
            )}
        </div>
      </div>
    </>
  );
};

export default ContentCount;
