import React, {forwardRef, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useLocalization} from "../../hooks/Utils/useLocalization";
import {url} from "../../routes/utility";
import {GetActiveReminders} from "../../services/Reminder/ReminderService";
import {closeInfo} from "../Toggle";
import {addTimeZone, closePopup, getLogoByBrandId, ISOStringToDate, openPopup} from "../Utility";
import useAuth from "../../hooks/Log/useAuth";
import {StorageKeys} from "../../consts/Constants";
import {addToLocalStorage, getFromLocalStorage} from "../../utils";

const NavbarReminder = forwardRef((props, ref) => {

    const { popupRef, setAlertInfo } = props;
    const strings = useLocalization();
    const { employee } = useAuth();

    const [data, setData] = useState([]);

    // Şuanki zamanı tutan state.
    const [currentTime, setCurrentTime] = useState(new Date());
    // Formatlanmış zamanı tutan state.
    const [formattedTime, setFormattedTime] = useState("");
    // Şuanki zaman ile eşleşen hatırlatmanın tutulduğu state.
    const [reminderTime, setReminderTime] = useState({});
    // LocalStorage'da tutulan verinin alınacağı state.
    const [reminderStorageData, setReminderStorageData] = useState([]);

    // Hatırlatma zamanına timeZone eklemek için kullanılan fonksiyon.
    const getReminderTimeWithTimeZone = (time) => {
        return addTimeZone(time.split("T")[1].slice(0, 5), employee.TimeZone)
    }

    const getAllRemindersFromStorage = () => {
        const storageData = getFromLocalStorage(StorageKeys.ReminderLocalStorageKey);
        setReminderStorageData(storageData);
    }

    const getActiveReminders = () => {
        GetActiveReminders(employee.EmployeeId)
            .then(result => {
                if (result.status === 200) {
                    let resultContent = JSON.parse(result.content);
                    setData(resultContent);
                }
            })
            .catch()
    }

    const clearReminderStates = () => {
        setData([]);
        setReminderTime({});
        setFormattedTime("");
    }

    //Bu useEffect her 5 saniyede bir currentDate state'ini günceller.
    //currentDate state'ini bildirim zamanı ile şuanki zamanı karşılaştırmak için kullanacağız.
    //İlk sayfaya girdiğimizde ve her 5 saniyede bir localStorage'dan verileri çekiyoruz.
    useEffect(() => {
        getAllRemindersFromStorage();

        const intervalId = setInterval(() => {
            getAllRemindersFromStorage();
            setCurrentTime(new Date());
        }, 5000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    // Bildirim zamanı ile şuanki zamanı karşılaştırıp eşleşen veriyi reminderTime adlı bir state'e atıyoruz.
    useEffect(() => {
        const tmpFormattedTime = currentTime.toLocaleTimeString('tr-TR', {
            hour: '2-digit',
            minute: '2-digit',
        });
        setFormattedTime(tmpFormattedTime);

        const tmpReminderTime
            = data.find(reminder => getReminderTimeWithTimeZone(reminder.callDate) === tmpFormattedTime);

        if (tmpReminderTime) {
            setReminderTime(tmpReminderTime);
        }
    }, [currentTime])

    /*
        * Bu useEffect'teki genel amaç;
            * Eşleşen hatırlatmaları LocalStorage'da atarak kullanıcı layout değiştirdiğinde tekrar bildirimi çaldırmamak.
        * LocalStorage'da eşleşen veri olup olmadığı kontrol ediliyor. Eşleşen veri yoksa bildirim çaldırılıyor.
        * Bildirim çaldırıldığında ilgili veri LocalStorage'a atılıyor. Bu sayede bildirimin bir defa çalıdırılması sağlanıyor.
    */
    useEffect(() => {
        if (Object.keys(reminderTime).length !== 0 && (formattedTime === getReminderTimeWithTimeZone(reminderTime.callDate))) {
            const matchedReminder = reminderStorageData.find(reminder => reminder.id === reminderTime.id) || false;

            if (!matchedReminder && !document.hidden) {
                // Eşleşen verinin localStorage'ye yazdılığı kısım
                let dbReminderData = { id: reminderTime.id, date: reminderTime.callDate };

                addToLocalStorage(StorageKeys.ReminderLocalStorageKey, dbReminderData);
                setData(data => data.filter(d => d.id !== dbReminderData.id));

                //Erteleme bölümü için içerikleri yazdığımız nesne
                const info = {
                    memberFormActivityId: reminderTime.id,
                    memberId: reminderTime.candidate.id,
                    memberFullName: reminderTime.candidate.fullName,
                    reminderContent: reminderTime.note
                }
                setAlertInfo(info);
                openPopup(popupRef);

                const audio = new Audio('/sounds/reminder.mp3');
                audio.play();

                setTimeout(() => closePopup(popupRef), 20000);
            }
        }
    }, [formattedTime, reminderTime, reminderStorageData]);

    useEffect(() => {
        //tab değişikliğini bu alandan kontrol ediyoruz
        const handleVisibilityChange = () => {
            if (!document.hidden) {
                clearReminderStates();
                getActiveReminders();
            }
        }

        document.addEventListener("visibilitychange",  handleVisibilityChange);

        return () => {
            document.removeEventListener("visibilitychange",  handleVisibilityChange);
        }
    }, []);

    useEffect(() => {
        getActiveReminders();
    }, [])

    return (
        <>
            <p className="px-4 font-bold pt-3">{strings.navbar.reminders.title}</p>
            <hr />
            <div className={`max-h-64 transition-all duration-300 py-1 ${data.length > 3 ? 'overflow-y-scroll sm:overflow-y-hidden hover:overflow-y-scroll' : ''}`}>
                {data.length > 0 ? (
                    data.map((reminder, index) => (
                        <Link
                            key={index}
                            className="text-start w-full"
                            to={url("member.detail.profile", { memberId: reminder.candidate.id })}
                            onClick={() => closeInfo(ref)}
                        >
                            {index !== 0 && <hr />}
                            <div className="px-4 flex gap-2 py-2 bg-white hover:bg-[#f0f0f0]">
                                <div className="w-10 h-10 flex shrink-0">
                                    <img src={getLogoByBrandId(reminder.brandId)} width="40" height="40" alt="KO logo" />
                                </div>
                                <div>
                                    <p className="font-bold">{reminder.candidate.fullName}</p>
                                    <p>{reminder.note}</p>
                                    <p className="text-[13px] !text-[#989898]">{ISOStringToDate(reminder.callDate, 180)}</p>
                                </div>
                            </div>
                        </Link>
                    )))
                    :
                    (
                        <button className="py-4 min-w-full text-center bg-white hover:bg-[#f0f0f0]">{strings.errors.no_reminder_found}</button>
                    )
                }
            </div>
            <hr />
            <Link
                to={url("reminder")}
                className="px-4 py-3 flex justify-between"
            >
                <p>{strings.navbar.reminders.list_all}</p>
                <span className="material-symbols-outlined text-base font-bold">
                    chevron_right
                </span>
            </Link>
        </>
    );
});

export default NavbarReminder;
