import { useRef, useState } from "react";
import { useLocalization } from "../hooks/Utils/useLocalization";
import Input from "./Input";
import classNames from "classnames";

export const SearchBar = (props) => {

    const {
        placeHolder,
        changeValue,
        isDisabled = false,
        content,
        handleRefresh = false,
        isClear = true,
        isDisabledSearchButton = false
    } = props;

    const strings = useLocalization();
    const inputRef = useRef();

    const [value, setValue] = useState("");
    const [isSearched, setIsSearched] = useState(false);

    const handleClick = () => {
        if(!(isDisabledSearchButton && value.length === 0)) {
            changeValue?.(value);
            setIsSearched(isSearched => false);
        }
    }

    const handleClear = () => {
        setValue(value => '');
        changeValue?.('');
        inputRef.current.value = '';
    }

    return (
        <div className="py-6 flex flex-col md:flex-row gap-2 xs:gap-4">
            <Input
                id="searchInput"
                ref={inputRef}
                onChange={e => { setValue(e.target.value); setIsSearched(isSearched => true); }}
                onKeyDown={e => {
                    if (e.keyCode === 13 && isSearched) {
                        handleClick();
                    }
                }}
                type="text"
                classnames="input-field h-[45px] w-full"
                placeholder={placeHolder}
                isDisabled={isDisabled}
            />
            <div className="flex gap-2 max-md:mx-auto">
                {content}
                <button
                    className={classNames("button w-[110px] search-button gap-2 text-white bg-blue border-blue", {
                        "opacity-70 pointer-events-none": isDisabled || !isSearched || (isDisabledSearchButton && value.length === 0)
                    })}
                    onClick={handleClick}
                    disabled={isDisabled || !isSearched}
                >
                    <span className="material-symbols-outlined">search</span>
                    <p>{strings.search_bar.search}</p>
                </button>
                {isClear &&
                    <button
                        onClick={handleClear}
                        className={classNames("button w-[110px] search-button gap-2 text-white bg-red border-red focus:border-red", {
                            "opacity-70 pointer-events-none": isDisabled || value.length === 0
                        })}
                        disabled={isDisabled || value.length === 0}
                    >
                        <span className="material-symbols-outlined">clear</span>
                        <p>{strings.search_bar.clear}</p>
                    </button>
                }
                {handleRefresh &&
                    <button
                        onClick={handleRefresh}
                        className={classNames("button w-[110px] search-button gap-2 text-white bg-warning border-warning focus:border-warning", {
                            "opacity-70 pointer-events-none": isDisabled
                        })}
                        disabled={isDisabled}
                    >
                        <span className="material-symbols-outlined">refresh</span>
                        <p>{strings.search_bar.refresh}</p>
                    </button>
                }
            </div>
        </div>
    );
}
