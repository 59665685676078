import { useSelector } from 'react-redux';
import { Local } from "../../localizations/Local";
import { English } from "../../localizations/English";
import { Arabic } from "../../localizations/Arabic";
import { Azerbaijani } from "../../localizations/Azerbaijani";

export function useLocalization() {
    const language = useSelector(state => state.localizationStore.language);
    
    switch (language) {
        case "en":
            return English;
        case "az":
            return Azerbaijani;
        case 'ar':
            return Arabic;
        default:
            return Local;
    }
}