import React from "react";
import {NoRecordsFound} from "../../../../../../components";
import {fullDateToISOWithTimeZone, isItSpecified} from "../../../../../../components/Utility";
import {useLocalization} from "../../../../../../hooks/Utils/useLocalization";
import useAuth from "../../../../../../hooks/Log/useAuth";

const LogTable = ({ data }) => {

  const strings = useLocalization();
  const { employee } = useAuth();
  const notSpecified = strings.errors.it_is_not_specified;

  const getSourceTypeNameBySourceId = (sourceId) => {
      switch (sourceId) {
            case 1:
                return strings.member.member_detail.operations.source_type.web_application;
            case 2:
                return strings.member.member_detail.operations.source_type.panel;
            case 3:
                return strings.member.member_detail.operations.source_type.mobile_application;
            case 4:
                return strings.member.member_detail.operations.source_type.landing_page;
            default:
                return notSpecified;
      }
  }

  return (
    <table className="min-w-full leading-normal text-header">
      <thead>
        <tr>
          <th className="th"><p className="min-w-max">{strings.table.term_product_description}</p></th>
          <th className="th"><p className="min-w-max">{strings.table.performing_person}</p></th>
          <th className="th"><p className="min-w-max">{strings.member.member_detail.accounting.source}</p></th>
          <th className="th"><p className="min-w-max">{strings.table.device_name}</p></th>
          <th className="th"><p className="min-w-max">{strings.table.operation_date}</p></th>
        </tr>
      </thead>
      <tbody>
        {data.length > 0 ? (
          data.map((log, index) => (
              <tr key={index} className="bg-white hover:bg-[#f0f0f0]">
                  <td className="td h-[60px] align-middle">
                      <p>{isItSpecified(log.description, strings.errors.it_is_not_specified)}</p>
                  </td>
                  <td className="td h-[60px] align-middle">
                      <p className="min-w-max">{log.createUser}</p>
                  </td>
                  <td className="td h-[60px] align-middle">
                      <p className="min-w-max">{getSourceTypeNameBySourceId(log.sourceType)}</p>
                  </td>
                  <td className="td h-[60px] align-middle">
                      <p className="min-w-max">{isItSpecified(log?.deviceName, notSpecified)}</p>
                  </td>
                  <td className="td h-[60px] align-middle">
                      <p className="min-w-max">{fullDateToISOWithTimeZone(log.logDate, employee.TimeZone)}</p>
                  </td>
              </tr>
          ))
        ) : (
            <tr className="bg-white hover:bg-[#f0f0f0]">
                <td className="td" colSpan={5}>
                    <NoRecordsFound/>
                </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default LogTable;
