import {useEffect, useState} from "react";
import {Loading, PageTitle, Pagination, SearchBar, SearchCount, showTopAlert} from "../../components";
import {useLocalization} from "../../hooks/Utils/useLocalization";
import {MemberStudentFilter} from "../../services/Member/MemberStudentService";
import {StudentDetailSearch, StudentTable} from "./components";
import useAuth from "../../hooks/Log/useAuth";
import {useSelector} from "react-redux";
import {areObjectsEqual, getLanguageTypeByLanguageKey} from "../../components/Utility";

const Student = () => {

    const strings = useLocalization();
    const {employee} = useAuth();

    const language = useSelector(state => state.localizationStore.language);

    const limit = parseInt(process.env.REACT_APP_STUDENT_TABLE_ROW_LIMIT);
    const [start, setStart] = useState(0);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecord, setTotalRecord] = useState(0);
    const [data, setData] = useState([]);

    const [detailSearch, setDetailSearch] = useState({
        brandId: parseInt(employee.BrandId),
        status: -1,
        teacherId: -1,
        salesPersonId: null,
        productivityPersonId: null,
        isSubstituteTeacher: false,
        lessonCountSelectIndex: -1,
        lessonDurationType: null,
        productDayCount: null,
        searchText: ""
    });

    //Detail Search objesini ilk başta tutup değişip-değilmediğini anlamak için kullanılır.
    const [tmpDetailSearch, setTmpDetailSearch] = useState({});
    //Servise gidip-gitmeme durumunu kontrol eden state
    const [sendRequest, setSendRequest] = useState();
    //Servise gidildiğinde çalışır.
    const [isFirstRequest, setIsFirstRequest] = useState(false);

    //Arama verileri değiştiğinde değerleri sıfırlıyoruz
    const resetValue = () => {
        setStart(start => 0);
        setCurrentPage(currentPage => 1);
    }

    //select değişiklikleri bu alandan yönetiliyor
    const changeDetail = (values) => {
        setDetailSearch({...detailSearch, ...values});
        resetValue();
    }

    //input değişiklikleri bu alandan yönetiliyor
    const changeValue = (value) => {
        setDetailSearch({...detailSearch, searchText: value});
        resetValue();
    }

    //sayfa değiştikçe bilgileri yeniden çağırıyoruz
    useEffect(() => {
        if (totalRecord !== 0) {
            getMemberStudent();
        }
    }, [start])

    const getMemberStudent = async (language) => {
        setLoading(loading => true);

        const model = {
            start: start,
            length: limit,
            brandId: detailSearch.brandId,
            employeeId: employee.EmployeeId,
            status: detailSearch.status,
            teacherId: detailSearch.teacherId,
            salesPersonId: detailSearch.salesPersonId,
            productivityPersonId: detailSearch.productivityPersonId,
            isSubstituteTeacher: detailSearch.isSubstituteTeacher,
            lessonCountSelectIndex: detailSearch.lessonCountSelectIndex,
            lessonDurationType: detailSearch.lessonDurationType,
            productDayCount: detailSearch.productDayCount,
            searchText: detailSearch.searchText.trim(),
            languageType: getLanguageTypeByLanguageKey(language)
        }

        const result = await MemberStudentFilter(model);

        if (result.status === 200) {
            let resultContent = JSON.parse(result.content);

            setIsFirstRequest(isFirstRequest => true);
            setData(data => resultContent.data);
            setTotalRecord(totalRecord => resultContent.recordsTotal);
            setLoading(loading => false);
        } else {
            showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
        }
    }

    useEffect(() => {

        let abortController = new AbortController();

        //Endpoint'e gitmeden loading'i true'ya çekip dil değiştiğinde de loading'in devreye girmesini sağlıyoruz
        if (sendRequest) {
            getMemberStudent(language);
        }

        return () => {
            abortController.abort();
        }
    }, [detailSearch, language, sendRequest])

    useEffect(() => {
        setTmpDetailSearch(tmpDetailSearch => detailSearch);
    }, []);

    useEffect(() => {
        if (Object.keys(tmpDetailSearch).length > 0) {
            setSendRequest(sendRequest => !areObjectsEqual(detailSearch, tmpDetailSearch));
        }
    }, [detailSearch, tmpDetailSearch])

    return (
        <>
            <StudentDetailSearch
                changeDetail={changeDetail}
                loading={loading}
            />
            <SearchBar
                changeValue={changeValue}
                placeHolder={strings.search_bar.search_student}
                handleRefresh={() => getMemberStudent(language)}
                isDisabled={loading}
            />

            {!loading && <SearchCount count={totalRecord}/>}
            <PageTitle title={strings.sidebar.member.students}/>
            {loading ?
                (
                    <Loading/>
                )
                :
                (
                    <>
                        <StudentTable
                            data={data}
                            isFirstRequest={isFirstRequest}
                        />
                        <Pagination
                            totalCount={totalRecord}
                            limit={limit}
                            start={start}
                            setStart={setStart}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            setLoading={setLoading}
                            loadScreen={true}
                        />
                    </>
                )
            }
        </>
    );
};

export default Student;
