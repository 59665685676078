import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {
    Button,
    Dropdown, DropdownSearch,
    LoadingSpinner,
    PageTitle,
    showCenteredAlertTwoButtons,
    showCenteredHtmlAlert,
    showTopAlert
} from "../../../../../components";
import {getBrandNameById, getFromBetween, removeHtmlTags} from "../../../../../components/Utility";
import {useLocalization} from "../../../../../hooks/Utils/useLocalization";
import {
    GetEmailsListWithSubject,
    GetMailTemplateContentById,
    SendEmailModel
} from "../../../../../services/Information/InformationService";
import useAnalytics from "../../../../../hooks/Log/useAnalytics";
import useEmployeeSenderEmail from "../../../../../hooks/Employee/useEmployeeSenderEmail";
import useAuth from "../../../../../hooks/Log/useAuth";
import useMemberBrand from "../../../../../hooks/Member/useMemberBrand";
import useMemberPhoneNumber from "../../../../../hooks/Member/useMemberPhoneNumber";
import {ClickLogs} from "../../../../../consts/Logs";
import {Notes} from "../../../../../consts/Notes";
import useCreateMemberNote from "../../../../../hooks/Log/useCreateMemberNote";
import useMemberEmail from "../../../../../hooks/Member/useMemberEmail";
import useDataLayer from "../../../../../hooks/Log/useDataLayer";
import {CustomEventDescriptions, CustomEvents} from "../../../../../consts/DataLayer";

const SendEmail = () => {

    const strings = useLocalization();

    /* Hooks */
    const {memberId} = useParams() || false;
    const {employee} = useAuth();
    const analytics = useAnalytics();
    const dataLayer = useDataLayer();
    const memberNote = useCreateMemberNote();
    const employeeSenderEmail = useEmployeeSenderEmail(employee?.EmployeeId);
    const memberBrandId = useMemberBrand(memberId);
    const memberPhoneNumber = useMemberPhoneNumber(memberId);
    const memberEmail = useMemberEmail(memberId);
    /* Hooks */

    const language = useSelector(state => state.localizationStore.language);

    const topRef = useRef();

    const firstEmailType = {id: -1, name: strings.member.member_detail.inform_operations.email_titles};
    const [emailType, setEmailType] = useState(firstEmailType);

    const [emailList, setEmailList] = useState([]);

    const [tempContent, setTempContent] = useState(null);
    const [content, setContent] = useState(null);

    const [tempWhatsappContent, setTempWhatsappContent] = useState(null);
    const [whatsappContent, setWhatsappContent] = useState(null);

    const [params, setParams] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);
    const [sendEmailButtonLoading, setSendEmailButtonLoading] = useState(false);

    //Brand a ait mail listesini alıyoruz
    const getEmailLists = async (memberBrandId) => {
        const result = await GetEmailsListWithSubject(memberBrandId);

        let arrayList = [];

        if (result.status === 200) {
            const data = JSON.parse(result.content);

            data.map((item) => {
                let arrayItem = {
                    id: parseInt(item.id),
                    name: item.title,
                    subject: item.subject,
                };
                arrayList.push(arrayItem);
            })
        }

        setEmailList([firstEmailType, ...arrayList]);
    }

    //Seçilen mail tipine göre içeriği getiriyoruz
    const getMailTemplateContentById = async (contentId, memberId) => {

        const result = await GetMailTemplateContentById(contentId, memberId);

        if (result.status === 200) {

            let resultContent = JSON.parse(result.content);
            setTempContent(tempContent => resultContent.mailHtml);
            setTempWhatsappContent(tempWhatsappContent => resultContent.whatsappContent);
        }
    }

    const resetStates = () => {
        setEmailType(emailType => firstEmailType);
        setTempWhatsappContent(tempWhatsappContent => null);
        setTempContent(tempContent => null);
    }

    const sendToEndpoint = async () => {

        let valid = true;
        let errorList = '';

        params.map(p => {
            let inputValue = document.getElementById(p.input).value;
            if (inputValue === "") {
                valid = false;
                errorList += p.label + strings.errors.input_field_null_error + "<br />";
            }
        })

        if (valid) {
            setSendEmailButtonLoading(sendEmailButtonLoading => true);

            let mailContent = document.getElementById("contentDiv").innerHTML;
            const model = {
                subject: emailType.subject,
                content: mailContent,
                recipients: [memberEmail],
                from: employeeSenderEmail,
                name: getBrandNameById(memberBrandId),
                emailServiceType: 2, //PostMark için enum değeri
                persistentContent: 1 //WithoutContent için enum değeri
            };

            const result = await SendEmailModel(model);

            if (result.status === 200) {
                resetStates();
                topRef.current.focus();

                //LOGGED
                analytics.useAnalytics(ClickLogs.EMAIL_WAS_SEND, {memberId: memberId});

                //DATA LAYER LOGGED
                dataLayer.useCustomEvents(CustomEvents.EMPLOYEE_EVENTS, CustomEventDescriptions.EMAIL_SENDED);

                //NOTED
                memberNote.useCreateMemberNote(
                    memberId,
                    Notes.SEND_EMAIL_NOTE
                        .replace("{consultantName}", employee?.FullName)
                        .replace("{MailTitle}", emailType.name),
                    43 //default
                );

                showTopAlert(strings.member.member_detail.inform_operations.email_success_message, 'success');
            } else {
                //LOGGED
                analytics.useAnalytics(ClickLogs.EMAIL_WAS_NOT_SEND, {memberId: memberId});

                if(memberEmail.length === 0)
                    showTopAlert(strings.member.member_detail.inform_operations.member_email_not_found, "error");
                else
                    showTopAlert(strings.errors.an_error_occurred, "error");
            }

            setSendEmailButtonLoading(sendEmailButtonLoading => false);
        } else {
            showCenteredHtmlAlert(strings.errors.an_error_occurred, errorList, "error");
        }
    }

    const sendWhatsAppMessage = () => {
        let valid = true;
        let errorList = '';

        params.map(p => {
            let inputValue = document.getElementById(p.input).value;
            if (inputValue === "") {
                valid = false;
                errorList += p.label + strings.errors.input_field_null_error + "<br />";
            }
        })

        if (valid) {
            /*
              WP template'i getirilip HTML taglarinden arındılıyor.
              Base URL ile birleştirilip WP ekranı yan sekmede açılıyor
            */
            let cleanedWhatsappContent = removeHtmlTags(whatsappContent);
            let whatsappApiUrl = process.env.REACT_APP_WHATSAPP_API_URL + `/?phone=${memberPhoneNumber}&text=${cleanedWhatsappContent}`;

            resetStates();
            topRef.current.focus();

            //LOGGED
            analytics.useAnalytics(ClickLogs.EMAIL_SEND_ON_WHATSAPP, {memberId: memberId});

            //DATA LAYER LOGGED
            dataLayer.useCustomEvents(CustomEvents.EMPLOYEE_EVENTS, CustomEventDescriptions.WP_SENDED);

            //NOTED
            memberNote.useCreateMemberNote(
                memberId,
                Notes.SEND_EMAIL_NOTE_WHATSAPP
                    .replace("{consultantName}", employee?.FullName)
                    .replace("{MailTitle}", emailType.name),
                43 //default
            );

            //WhatsApp ekranı yeni sekmede açılıyor
            window.open(whatsappApiUrl);
        }
    }

    const changeSpan = async (value, span, spanwp) => {
        document.getElementById(span).innerText = value;
        if (document.getElementById(spanwp))
            document.getElementById(spanwp).innerText = value;
    }

    const handleButtonDisable = () => {
        let valid = true;

        params.map(p => {
            let inputValue = document.getElementById(p.input).value;
            if (inputValue.length === 0) {
                valid = false;
            }
        })

        setIsDisabled(isDisabled => !valid)
    }

    useEffect(() => {
        if (tempContent !== null) {
            //Content içerisinde özel karakterler ile ayrılmış herhangi bir parametre var mı?
            //Özel karekter tipi şu şekilde {{{span:İngilizce isim#Türkçe isim}}}
            let parameters = getFromBetween.get(tempContent, '{{{', '}}}');
            //Eğer parametre var ise
            let cntnt = tempContent;
            let wpcntnt = tempWhatsappContent;
            if (parameters.length > 0) {
                setIsDisabled(disabled => true)
                //Span isimleri ve gösterilecek isimler
                let paramArray = [];
                //Eğer belirtilen tipte bir dizi geliyorsa
                parameters.map((p, index) => {
                    let param = p.split(":");
                    let paramName = param[1].split("#");
                    paramArray.push({
                        span: "span_" + param[0] + index,
                        span_wp: "wp_span_" + param[0] + index,
                        input: "input_" + param[0] + index,
                        label: language === "tr" ? paramName[1] : paramName[0]
                    });
                    cntnt = cntnt.replace('{{{' + parameters[index] + '}}}', '<span id="span_' + param[0] + index + '"></span>');

                    if (tempWhatsappContent) {
                        wpcntnt = wpcntnt.replace('{{{' + parameters[index] + '}}}', '<span id="wp_span_' + param[0] + index + '"></span>');
                    }
                });
                setParams(paramArray);
            }

            // Özel karakterleri güncelledikten sonra {{ }} içinde bir eleman varsa bu mail template'inin altında bulunan
            // tarih kısmı oluyor. Bunu şuanki yıl ile replace ediyoruz.
            let year = getFromBetween.get(cntnt, '{{', '}}');
            if(year?.length > 0) {
                cntnt = cntnt.replace('{{year}}', new Date().getFullYear());
            }
            setContent(cntnt);
            setWhatsappContent(wpcntnt);
        }
    }, [tempContent, tempWhatsappContent, language])

    //Seçilen başlığıa ait içerik getiriliyor
    useEffect(() => {
        if (emailType.id !== -1 && memberId) {
            /*
              Mail ve WP templatelerini getirmeden önce disabled durumu sıfırlanıyor.
              Bu durumun nedeni template değiştikçe butonların doğru davranması içindir.
            */
            setIsDisabled(isDisabled => false);
            getMailTemplateContentById(emailType.id, memberId);
        } else {
            //Email type default seçildiğinde buton tekrar disable yapılıyor.
            setIsDisabled(isDisabled => true);
            setContent(null);
            setTempContent(null);
        }
        setParams([]);
    }, [emailType])

    //Markaya ait içerik listesi alınıyor
    useEffect(() => {
        if (memberBrandId !== 0) {
            getEmailLists(memberBrandId);
        }
    }, [memberBrandId])

    return (
        <>
            <PageTitle title={strings.member.member_detail.header.content.send_email}/>
            <p tabIndex={-1} ref={topRef} className="h-0"></p>
            <div className="flex flex-col px-[10px] pb-0 mt-5">
                <label className="text-[13px] font-bold mb-2" htmlFor="sms_title">
                    {strings.member.member_detail.inform_operations.email_title}
                </label>
                <DropdownSearch
                    inputPlaceholder={strings.detail_search_titles.search_email_title}
                    classnames="md:max-w-full"
                    data={emailList}
                    selectedData={emailType}
                    setSelectedData={setEmailType}
                />
            </div>
            {emailType.id !== -1 &&
                <div className="flex flex-col px-[10px] pb-0 mt-5">
                    {params.length > 0 && (
                        <>
                            <p className="text-[13px] font-bold mb-2">{strings.member.member_detail.inform_operations.parameter}</p>
                            <hr/>
                            <div className="flex flex-row gap-4 my-3">
                                {params.map((p, index) => (
                                    <div key={index} className="flex flex-col w-full">
                                        <label className="font-bold mb-2">
                                            {p.label} <span className="text-red">*</span>
                                        </label>
                                        <input
                                            id={p.input}
                                            type="text"
                                            className="form-input w-full placeholder:!text-[13px]"
                                            onChange={(e) => {
                                                changeSpan(e.target.value, p.span, p.span_wp);
                                                handleButtonDisable();
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                        </>
                    )}

                    {content && (
                        <>
                            <p className="text-[13px] font-bold mb-2">{strings.member.member_detail.inform_operations.email_content}</p>
                            <div
                                id="contentDiv"
                                className="w-full h-[350px] overflow-y-scroll"
                                dangerouslySetInnerHTML={{__html: content !== null ? content : ""}}
                            />
                        </>
                    )}

                    {/*
                        DecodeURI ile url encoded ile gelen templateler kullanıcıya gösterilirken decode ediliyor.
                    */}
                    {whatsappContent && (
                        <div className="mt-5">
                            <p className="text-[13px] font-bold mb-2">{strings.member.member_detail.inform_operations.whatsapp_content}</p>
                            <div
                                id="whatsappContentDiv"
                                className="w-full h-[150px] overflow-y-scroll"
                                dangerouslySetInnerHTML={{__html: whatsappContent !== null ? decodeURI(whatsappContent) : ""}}
                            />
                        </div>
                    )}
                </div>
            }
            <div className="flex justify-center gap-2">
                <Button
                    text={sendEmailButtonLoading ? <LoadingSpinner /> : strings.button.send_email_button}
                    disabled={isDisabled || !tempContent || sendEmailButtonLoading}
                    type="button"
                    classnames={`bg-blue w-[177px] border-blue mt-5 !px-10 
                        ${(isDisabled || !tempContent || sendEmailButtonLoading) ? "opacity-70 hover:opacity-70 cursor-not-allowed" : ''} 
                    `}
                    action={() => {
                        showCenteredAlertTwoButtons(
                            strings.alert.centered_alert_two_buttons.title_confirm,
                            strings.member.member_detail.inform_operations.send_email_question,
                            strings.button.confirm,
                            strings.button.cancel,
                            sendToEndpoint
                        );
                    }}
                />
                <Button
                    text={strings.button.send_from_whatsapp_button}
                    disabled={isDisabled || !tempWhatsappContent}
                    type="button"
                    classnames={`bg-blue w-[177px] border-blue mt-5 !px-10 ${(isDisabled || !tempWhatsappContent) ? "opacity-70 hover:opacity-70 cursor-not-allowed" : ''} `}
                    action={() => {
                        showCenteredAlertTwoButtons(
                            strings.alert.centered_alert_two_buttons.title_confirm,
                            strings.member.member_detail.inform_operations.send_whatsapp_message,
                            strings.button.confirm,
                            strings.button.cancel,
                            sendWhatsAppMessage
                        );
                    }}
                />
            </div>
        </>
    );
}

export default SendEmail
