import React, { useEffect, useState } from 'react'
import { useLocalization } from '../../../../hooks/Utils/useLocalization';
import { GetTeacherScheduleTermDetail } from '../../../../services/Teacher/TeacherService';
import { LessonTime, Loading, showTopAlert } from '../../../../components';
import { iconForLessonStatus } from '../../../../components/Utility';
import { useSelector } from 'react-redux';
import { GetLessonHistory } from '../../../../services/Term/TermService';

const MemberTermDetailModal = (props) => {

  const { data, closeModal } = props;
  const strings = useLocalization();

  const language = useSelector((state) => state.localizationStore.language);

  const [termDetail, setTermDetail] = useState([]);
  const [lessonHistory, setLessonHistory] = useState([]);
  const [loading, setLoading] = useState(true);

  const getTeacherScheduleTermDetail = async (termId) => {

    const result = await GetTeacherScheduleTermDetail(termId);

    if(result.status === 200){
        let resultContent = JSON.parse(result.content);
        setTermDetail(termDetail => resultContent);
        setLoading(loading => false);
    } else {
        setTimeout(() => closeModal(), 1000);
        showTopAlert(strings.errors.an_error_occurred, "error");
    }
  };

  //Ders geçmişini getiren servis
  const getLessonHistory = async (termId) => {
    const result = await GetLessonHistory(termId);

    if (result.status === 200) {
        let resultContent = JSON.parse(result.content);

        setLessonHistory(lessonHistory => resultContent.lessonStatusItems); 
    }
  }
    
  useEffect(() => {
    if(data){
        getTeacherScheduleTermDetail(data.termId);
        getLessonHistory(data.termId);
    }
  }, []);

  return (
    <>
        {loading ? (
            <Loading />
        ) : (
            <>
                <div className="flex justify-center font-bold text-[15px]">
                    <p className="">{data?.fullName}</p>
                </div>
                <div className="flex flex-col gap-2">
                    <p><span className="font-bold">{strings.teacher.teacher_program.student_no}: </span>{data?.studentNo}</p>
                    <p><span className="font-bold">{strings.teacher.teacher_program.weekly_lesson_count}: </span>{termDetail?.productLessonDayCount}</p>
                    <p><span className="font-bold">{strings.teacher.teacher_program.lesson_hour}: </span>{data?.beginTime}</p>
                    <p><span className="font-bold">{strings.teacher.teacher_program.lesson_time}: </span>{data?.lessonDuration} {strings.table.minute}</p>
                    <p><span className="font-bold">{strings.teacher.teacher_program.remaining_lesson_count}: </span>{termDetail?.incompleteLessonCount}</p>
                </div>
                <div className="flex flex-wrap mt-5 gap-4 justify-center">
                    {lessonHistory.length > 0 && lessonHistory.map((lesson, index) => {
                        const icon = iconForLessonStatus(lesson.status, language);
                        return (
                            <LessonTime
                                key={index}
                                tooltip={icon.tooltip}
                                iconName={icon.iconName}
                                iconClassnames={icon.color}
                                date={lesson.date}
                            />
                        );
                    })}
                </div>
            </>
        )}
    </>
  )
};

export default MemberTermDetailModal