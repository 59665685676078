import { Link } from 'react-router-dom';
import { ISOStringToDate, getBrandNameById, getLogoByBrandId, isItSpecified } from '../../../components/Utility';
import useAuth from '../../../hooks/Log/useAuth';
import { useLocalization } from '../../../hooks/Utils/useLocalization';
import { url } from '../../../routes/utility';

const TicketTableRow = (props) => {
    const strings = useLocalization();
    const { ticket } = props;
    const { employee } = useAuth();
    const notSpecified = strings.errors.it_is_not_specified;

    if(ticket.member.status !== 2) {
    }

    return (
        <tr className="bg-white hover:bg-[#f0f0f0]">
            <td className="td align-middle">
                <div className="relative group max-w-max ml-6">
                    <img src={getLogoByBrandId(ticket.brand)} width="43" height="48" alt='' />
                    <div className="tooltip-top">
                        <p>{getBrandNameById(ticket.brand)}</p>
                    </div>
                </div>
            </td>
            <td className="td align-middle">
                <div className="flex items-center">
                    <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
                        person
                    </span>
                    <Link
                        to={url("member.detail.profile", { memberId: ticket.member.id })}
                        target="_blank"
                        className="font-bold text-blue hover:underline focus:underline  min-w-max">
                        {isItSpecified(ticket.member.fullName, notSpecified)}
                    </Link>
                </div>
                <div className="flex items-start">
                    <span className="material-symbols-outlined mr-1 mt-[3px] flex-shrink-0 text-[17px]">
                        mode_comment
                    </span>
                    <p className='min-w-[250px]'>{ticket.ticketMessage}</p>

                </div>
                <div className="flex items-center">
                    <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
                        {ticket.status === 1 ? "error" : "task_alt"}
                    </span>
                    <p className={`font-bold ${ticket.status === 1 ? "text-red" : "text-green"}`}>{ticket.status === 1 ? strings.detail_search_titles.not_answered : strings.detail_search_titles.answered}</p>
                </div>
            </td>
            <td className='td align-middle'>
                <p>{isItSpecified(ticket.employeeName, notSpecified)}</p>
            </td>
            <td className="td align-middle">
                <div className="badge max-w-[200px] mb-2">
                    <p>
                        <b>{strings.table.ticket_date}:</b> {ISOStringToDate(ticket.ticketDate, employee.TimeZone)}
                    </p>
                </div>
                <div className="badge max-w-[200px] mb-2">
                    <p>
                        <b>{strings.table.answer_date}:</b> {ticket.status !== 1 ? ISOStringToDate(ticket.answerDate, employee.TimeZone) : strings.member.member_detail.tickets.not_answered}
                    </p>
                </div>
            </td>
            <td className="td align-middle">
                <div className="relative group max-w-max">
                    <Link to={url("ticket.detail", { ticketId: ticket.id })} target="_blank" className="button search-button gap-2 text-white bg-blue border-blue px-5">
                        <span className="material-symbols-outlined">send</span>
                    </Link>
                    <div className="tooltip-top">
                        {strings.button.browse}
                    </div>
                </div>
            </td>
        </tr>
    )
}

export default TicketTableRow