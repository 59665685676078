import React, {useEffect, useState} from "react";
import {useLocalization} from "../../../../../../hooks/Utils/useLocalization";
import DatePicker, { registerLocale } from 'react-datepicker';
import {LoadingSpinner, showTopAlert, TimePicker} from "../../../../../../components";
import classNames from "classnames";
import tr from "date-fns/locale/tr";
import {fullDateToShortDateWithAdjustTimezone} from "../../../../../../components/Utility";
import {UpdatePaymentDate} from "../../../../../../services/Member/MemberInvoiceService";

const UpdatePaymentDateModal = (props) => {

    const { paymentUpdateModalData, sendRequestToGetInvoicesEndpoint, closeButton }  = props;

    registerLocale("tr", tr);
    const strings = useLocalization();
    
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedTime, setSelectedTime] = useState("");
    const [calendarOpen, setCalendarOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const splittedDateArr = paymentUpdateModalData.paymentDate.split( " / ");

    const submitHandle = (e) => {
        e.preventDefault();
        setLoading(true);

        const splittedSelectedTime = selectedTime.split(":");
        const paymentDate = fullDateToShortDateWithAdjustTimezone(selectedDate, +splittedSelectedTime[0], +splittedSelectedTime[1]);

        UpdatePaymentDate(paymentUpdateModalData.paymentId, paymentDate)
            .then(result => {
                if(result.status === 200) {
                    showTopAlert(strings.member.member_detail.accounting.payment_date_edited_successfully, "success");
                    closeButton();
                    sendRequestToGetInvoicesEndpoint();
                } else {
                    showTopAlert(strings.errors.an_error_occurred, "error");
                    setLoading(false);
                }
            })
            .catch()
    }

    useEffect(() => {
        if(paymentUpdateModalData.paymentDate.length > 0) {
            const [day, month, year] = splittedDateArr[0].split('.');
            setSelectedDate(new Date(`${year}-${month}-${day}`));
            setSelectedTime(splittedDateArr[1]);
        }
    }, [paymentUpdateModalData])

    return (
        <>
            <div className="flex justify-center">
                <p className="font-bold text-[15px]">{strings.button.update_payment_date}</p>
            </div>
            <form
                onSubmit={submitHandle}
                className="flex flex-col text-start gap-4 mt-3"
            >
                <div className="flex flex-col px-[10px] pb-0 w-full">
                    <label htmlFor="payment-date">
                        {strings.member.member_detail.accounting.edit_payment_date}
                    </label>
                    <div className="relative">
                        <DatePicker
                            name="payment-date"
                            locale="tr"
                            dateFormat="dd/MM/yyyy"
                            className="form-input w-full"
                            selected={selectedDate}
                            onClickOutside={() => setCalendarOpen(false)}
                            maxDate={new Date()}
                            onChange={(date) => {
                                setSelectedDate(date);
                                setCalendarOpen(false);
                            }}
                            placeholderText={strings.form_info.date_placeholder}
                            open={calendarOpen}
                            onFocus={() => setCalendarOpen(true)}
                            readOnly={true}
                        />
                        <button type="button" onClick={() => setCalendarOpen(!calendarOpen)}>
                            <span className="material-symbols-outlined absolute right-6 top-2 text-lg">
                              calendar_today
                            </span>
                        </button>
                    </div>
                </div>
                <div className="flex flex-col px-[10px] pb-0 w-full">
                    <label htmlFor="payment-time">
                        {strings.member.member_detail.accounting.edit_payment_time}
                    </label>
                    <TimePicker
                        defaultTime={splittedDateArr[1]}
                        setSelectedTime={setSelectedTime}
                    />
                </div>
                <div className="flex gap-3 justify-center">
                    <button
                        type="submit"
                        className={classNames("button search-button gap-3 text-white bg-blue border-blue mt-7 px-10 w-[130px]", {
                                "!opacity-70 cursor-not-allowed": loading,
                        })}
                        disabled={loading}
                    >
                        {loading && <LoadingSpinner/>}
                        {!loading && strings.button.save_upper}
                    </button>
                    <button
                        type="button"
                        className={classNames("button search-button gap-3 text-white bg-red focus:border-red border-red mt-7 px-10 w-[130px]", {
                            "!opacity-70 cursor-not-allowed": loading,
                        })}
                        disabled={loading}
                        onClick={closeButton}
                    >
                        {strings.button.cancel_upper}
                    </button>
                </div>
            </form>
        </>
    )
}

export default UpdatePaymentDateModal