import { fetchApi } from '../index';

export const PaymentFilter = async (model) => {
    
    const url = '/member/v2/payment/filter/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const GetOrders = async (memberId) => {
    
    const url = '/member/v1/payment/get_orders/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const ListPayments = async (memberId) => {
    
    const url = '/member/v1/payment/list/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}