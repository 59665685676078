import { NoRecordsFound } from "../../../../components";
import { useLocalization } from "../../../../hooks/Utils/useLocalization";
import DailyReportDropPoolTableRow from "./DailyReportDropPoolTableRow";

const DailyReportDropPoolTable = (props) => {

    const { data, brandId } = props

    const strings = useLocalization();

    return (
        <div className="mx-auto px-4 mb-4">
            <div
                className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
                <div className="inline-block min-w-full rounded-lg overflow-hidden">
                    <table className="min-w-full leading-normal text-header">
                        <thead>
                        <tr>
                            <th className="th">
                                <p className="ml-6">{strings.table.brand}</p>
                            </th>
                            <th className="th">
                                <p className="min-w-max">
                                    {strings.sidebar.report.member_name}
                                </p>
                            </th>
                            <th className="th">
                                <p className="min-w-max">
                                    {strings.member.candidate_pool.score.td_title2}
                                </p>
                            </th>
                            <th className="th">
                                <p className="min-w-max">
                                    {strings.member.member_detail.candidate_operations.note}
                                </p>
                            </th>
                            <th className="th">
                                <p className="min-w-max">
                                    {strings.table.operation_date}
                                </p>
                            </th>
                        </tr>
                        </thead>
                        <tbody>

                        {data.length > 0 ?
                            (
                                data.map((employee, index) => (
                                    <DailyReportDropPoolTableRow
                                            key={index}
                                            item={employee}
                                            brandId={brandId}
                                        />
                                    ))
                                )
                                :
                                (
                                    <tr className="bg-white hover:bg-[#f0f0f0]">
                                        <td colSpan="6">
                                            <NoRecordsFound />
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>

                    <div id="employee-modal-container"></div>
                </div>
            </div>
        </div>
    );
}

export default DailyReportDropPoolTable