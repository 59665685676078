import { fetchApi } from '../index';

//Hatırlama sayfasındaki verileri getiren fonksiyon
export const ReminderFilter = async (model) => {
    
    const url = '/member/v2/reminder/filter/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

//Hatırlama sayfasındaki verileri getiren fonksiyon
export const ReminderFilterV3 = async (model) => {
    
    const url = '/member/v3/reminder/filter/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

//Bildirim kısmında bugüne ait hatırlatmaları getiren fonksiyon
export const GetActiveReminders = async (employeeId) => {

    const url = '/member/v2/member_form/get_candidate_reminder_for_component/';

    const payload = {
        employeeId: employeeId
    };

    return await fetchApi(url, payload);
}

//Bildirim kısmında bugüne ait hatırlatmaların sayısını getiren fonksiyon
export const GetReminderCount = async (employeeId) => {

    const url = '/member/v2/member_form/get_candidate_reminder_count/'

    const payload = {
        employeeId: employeeId
    };

    return await fetchApi(url, payload);
}