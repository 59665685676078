import { useState, useEffect } from 'react';
import { GetMemberType } from '../../services/Member/MemberFormService';

const useMemberStatus = (memberId) => {

    const [memberStatus, setMemberStatus] = useState(null);

    useEffect(() => {
      const getMemberType = async () => {
        const result = await GetMemberType(memberId);
        if (result.status === 200) {
          let status = parseInt(result.content);
          setMemberStatus(status);
        }
      };
      getMemberType();
    }, [memberId]);
  
    return memberStatus;
};

export default useMemberStatus;