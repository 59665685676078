import React from "react";
import {getBrandNameById, getLogoByBrandId, ISOStringToDate} from "../../../../components/Utility";
import {url} from "../../../../routes/utility";
import useAuth from "../../../../hooks/Log/useAuth";
import {Link} from "react-router-dom";

const DailyReportDropPoolTableRow = (props) => {

    const { item, brandId } = props;
    const { employee } = useAuth();

    return (
        <>
            <tr className="bg-white hover:bg-[#f0f0f0]">
                <td className="td align-middle">
                    <div className="relative group max-w-max ml-6">
                        <img src={getLogoByBrandId(brandId)} width="43" height="48" alt="logo" />
                        <div className="tooltip-top">
                            <p>{getBrandNameById(brandId)}</p>
                        </div>
                    </div>
                </td>
                <td className="td align-middle">
                    <div className="max-w-[320px] flex items-center gap-1">
                        <span className="material-symbols-outlined flex-shrink-0 text-[19px]">
                            person
                        </span>
                        <Link
                            to={url("member.detail.profile", {memberId: item?.memberId})}
                            target="_blank"
                            className="font-bold min-w-max text-blue hover:underline cursor-pointer"
                         >
                            {item.memberName}
                        </Link>
                    </div>
                </td>
                <td className="td align-middle">
                    <p
                        // to={url("employee.detail", { employeeId: employee.employeeId })}
                        // className="font-bold text-blue hover:underline focus:underline min-w-max"
                        className="min-w-max"
                    >
                        {item.employeeName}
                    </p>
                </td>
                <td className="td align-middle min-w-[350px] max-w-[500px]">
                    {item.note}
                </td>
                <td className="td align-middle min-w-[350px] max-w-[500px]">
                    {ISOStringToDate(item?.date, employee?.TimeZone)}
                </td>
            </tr>
        </>
    );
}

export default DailyReportDropPoolTableRow