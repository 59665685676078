import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, DropdownSearch, Loading, showCenteredAlertTwoButtons, showTopAlert } from "../../components";
import {isItSpecified, ISOStringToDate} from "../../components/Utility";
import useAuth from "../../hooks/Log/useAuth";
import { useLocalization } from "../../hooks/Utils/useLocalization";
import { url } from "../../routes/utility";
import { GetEmployeeName } from "../../services/Employee/EmployeeService";
import { GetAnswerContent, GetTicketDetail, UpdateAnswer } from "../../services/Ticket/TicketService";

const TicketDetail = () => {
  const strings = useLocalization();
  const notSpecified = strings.errors.it_is_not_specified;

  const [loading, setLoading] = useState(true);
  const [member, setMember] = useState({});
  const [data, setData] = useState([]);
  const firstAnswer = { id: -1, name: strings.ticket.detail.select_quick_answer };
  const [answer, setAnswer] = useState(firstAnswer);
  const [answeredUser, setAnsweredUser] = useState('');
  const [answerContent, setAnswerContent] = useState('Değerli Öğrencimiz,');
  const [quickAnswers, setQuickAnswers] = useState([]);

  const { employee } = useAuth();

  const { ticketId } = useParams() || false;

  //Talep ayrıntıları getiriliyor
  const getTicketDetail = async (ticketId) => {
    const result = await GetTicketDetail(ticketId);
    if (result.status === 200) {
      let resultContent = JSON.parse(result.content);

      setMember(member => resultContent.member);
      let ticketContent = resultContent.tickets[0];
      setData(data => ticketContent);

      //Eğer daha önceden cevaplanmışsa textarea içerisinde o cevabın görünmesini sağlayacağız.
      if (ticketContent.answer !== null) {
        setAnswerContent(answerContent => ticketContent.answer);
      }
      getEmployeeName(ticketContent.answeringUser);

      //Hızlı cevaplar listesi
      let tmpQuickAnswers = resultContent.quickAnswers.map(answer => ({ id: answer.value, name: answer.text }));
      setQuickAnswers([firstAnswer, ...tmpQuickAnswers]);

      setLoading(loading => false);
    } else {
      showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
    }
  }

  //Eğer bir hazır cevap seçilirse o cevaba ait içerik geitiliyor
  const getAnswerContent = async (answerId) => {
    const result = await GetAnswerContent(answerId);
    if (result.status === 200) {
      let resultContent = JSON.parse(result.content);
      setAnswerContent(answerContent => resultContent.content);
    }
  }

  const getEmployeeName = async (employeeId) => {
    const result = await GetEmployeeName(employeeId);
    if (result.status === 200) {
      setAnsweredUser(answeredUser => result.content);
    }
  }

  //Cevap güncelleniyor
  const updateAnswer = async () => {
    const model = {
      ticketId: ticketId,
      answer: answerContent,
      answeredUser: employee.EmployeeId
    };

    const result = await UpdateAnswer(model);
    if (result.status === 200) {
      showTopAlert(strings.ticket.detail.update_success, "success");
      //Güncelleme sonrası ekrana yeni veriler bastırıyorlar ve tüm veriler sıfırlanıyor
      setLoading(loading => true);
      setAnswerContent(answerContent => 'Değerli Öğrencimiz,');
      getTicketDetail(ticketId);
    } else {
      showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
    }

  }

  //Eğer yeni bir hazır cevap seçilirse içeriğini getir
  useEffect(() => {
    if (answer.id !== -1) {
      getAnswerContent(answer.id);
    } else {
      setAnswerContent(answerContent => 'Değerli Öğrencimiz,');
    }
  }, [answer])

  useEffect(() => {

    let abortController = new AbortController();

    if (ticketId) {
      getTicketDetail(ticketId);
    }

    return () => {
      abortController.abort();
    }
  }, [ticketId])

  return (
    <>
      {loading ?
        (
          <Loading />
        )
        :
        (
          <div className="rounded-md bg-white max-xs:px-2 text-[13px] h-full">

            <div className="flex justify-between items-center relative">
              {member && <Link
                to={url("member.detail.profile", { memberId: member.id })}
                target="_blank"
                state={{ id: member.id, status: member.status }}
                className="font-bold text-blue hover:underline focus:underline  min-w-max">
                {member.fullName}
              </Link>}
              <button
                  className="bg-card border border-[#e8e8e8] hover:bg-[#f3f3f3] gap-2 h-10 w-28 flex flex-row items-center justify-center rounded-full"
                  onClick={() => window.location.href = "/ticket"}
              >
                <span className="material-symbols-outlined">
                  keyboard_return
                </span>
                <p>{strings.button.go_back}</p>
              </button>
            </div>
            <hr className="my-4 -mx-2 md:-mx-5" />
            <div className="h-[310px] p-5 overflow-y-scroll">
              <div className="max-w-[95%] md:max-w-[85%]">
                <div className="message-box-left bg-[#f1f1f1] block max-w-max relative p-3 rounded-lg">
                  <p className="text-[11px] mb-1 text-[#83848a]">{member.fullName}</p>
                  <p dangerouslySetInnerHTML={{ __html: data.message.replace(/\n/g, '<br>') }} />
                </div>
                <p className="text-[11px] text-[#83848a] mx-3 mt-1">{ISOStringToDate(data.messageDate, employee.TimeZone)}</p>
              </div>

              {data.answer !== null &&
                <div className="max-w-[95%] md:max-w-[85%] ml-auto">
                  <div
                    className="message-box-right bg-blue block max-w-max relative p-3 rounded-lg mt-2 ml-auto">
                    <p className="text-white" dangerouslySetInnerHTML={{ __html: data.answer.replace(/\n/g, '<br>') }} />
                  </div>
                  <p className="text-[11px] text-[#83848a] mx-3 mt-1 text-end">{ISOStringToDate(data.answeredDate, employee.TimeZone)}</p>
                  <p className="text-[11px] mb-1 text-[#83848a] mx-3 text-end font-bold">{isItSpecified(answeredUser, notSpecified)}</p>
                  {/*Eğer kullanıcı cevabı okumuşsa*/}
                  {data.status === 3 && (
                    <>
                      <p className="text-[11px] mb-1 text-green mx-3 text-end font-bold">{strings.ticket.detail.read_answer}</p>
                      <p className="text-[11px] mb-1 text-[#83848a] mx-3 text-end">{ISOStringToDate(data.readDate, employee.TimeZone)}</p>
                    </>
                  )}
                  {/*Eğer kullanıcı cevabı okumuşsa*/}
                </div>
              }

            </div>
            <hr className="my-4 -mx-2 md:-mx-5" />
            <DropdownSearch
              inputPlaceholder={strings.ticket.detail.search_quick_answer}
              data={quickAnswers}
              selectedData={answer}
              setSelectedData={setAnswer}
              classnames="md:max-w-[480px]"
            />
            <div className="flex flex-col md:flex-row gap-5 mt-5">
              <textarea 
                rows="5" 
                className="w-full border border-[#e8e8e8] focus:bg-[#fafafa] rounded-lg px-3 py-1 outline-none" 
                value={answerContent} 
                onChange={(e) => setAnswerContent(e.target.value)}
              >
              </textarea>
              <Button
                classnames="button search-button text-white bg-blue border-blue px-10 md:px-5 max-w-max mx-auto"
                icon="send"
                action={() => showCenteredAlertTwoButtons(
                  strings.alert.centered_alert_two_buttons.title,
                  strings.ticket.detail.sure,
                  strings.button.confirm,
                  strings.alert.centered_alert_two_buttons.cancel_text,
                  updateAnswer
                )} />
            </div>


          </div>)}
    </>
  )
}

export default TicketDetail