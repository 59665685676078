import { fetchApi } from '../index';

export const AddMemberToPool = async (model) => {

    const url = '/member/v4/member/add_candidate_to_pool/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const MemberPoolFilter = async (model) => {

    const url = '/member/v4/member/member_pool/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const MemberPoolScoreFilter = async (model) => {

    const url = '/prime_log/v1/log/filter_member_pool/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const MemberPoolLogs = async (memberId) => {

    const url = '/member/v2/member_form/get_member_pool_activity/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const UpdateCandidateAfterWhatsAppContact = async (model) => {

    const url = '/member/v1/member_pool/update_candidate_after_whatsApp_contact/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const IsMemberInPool = async (memberId) => {

    const url = '/member/v1/member_pool/is_member_in_pool/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}
