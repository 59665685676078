import { fetchApi } from '../index';

export const FindEmployeeMember = async (memberId) => {

    const url = '/member/v1/employee_member/find_employee_member/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const UpdateEmployeeMember = async (employeeId, memberId, createUserId) => {

    const url = '/member/v1/employee_member/update_employee_member/';

    const payload = {
        employeeId: employeeId,
        memberId: memberId,
        createUserId: createUserId
    };

    return await fetchApi(url, payload);
}