import {useEffect, useState} from "react";
import {GetMemberCorporateInformations} from "../../services/Member/MemberService";

const useMemberCorporateInformations = (memberId) => {

    const [memberCorporateInformations, setMemberCorporateInformations] = useState({});

    const getMemberCorporateInformations = async (memberId) => {

        const result = await GetMemberCorporateInformations(memberId);

        if(result.status === 200){
            let resultContent = JSON.parse(result.content);
            setMemberCorporateInformations(memberCorporateInformations => resultContent);
        }
    }

    useEffect(() => {
        if(memberId.length > 0) {
            getMemberCorporateInformations(memberId)
        }
    }, []);

    return memberCorporateInformations;
}

export default useMemberCorporateInformations;