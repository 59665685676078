/*
Sayfalara ve sayfalardaki buton gruplarına göre roller tanımlanıyor.
Buradaki değerlere göre kullanıcının yetkisi var ya da yok durumlarına
göre buton aktif/pasif durumu belirleniyor.
*/
export const buttonRights = [
    {
        group: "panelLogin",
        buttons: [
            { name: "login", roles: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 24] }
        ]
    },
    {
        group: "employee",
        buttons: [
            { name: "edit", roles: [1, 2, 3, 5, 8, 18] },
            { name: "enableDistribute", roles: [1, 2, 3, 18] },
            { name: "detailEmployeeId", roles: [1, 2, 3]}
        ],
    },
    {
        group: "termSalesOperations",
        buttons: [
            { name: "general", roles: [1, 2, 3, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21] }
        ]
    },
    {
        group: "discountStatusColumn",
        buttons: [
            { name: "statusColumn", roles: [1, 2, 3, 18] }
        ]
    },
    {
        group: "taskFilterAuthority",
        buttons: [
            { name: "taskUserFilterAuthority", roles: [1, 2, 3, 5, 8, 11] },
            { name: "brandFilterAuthority", roles: [1, 2, 3] },
        ]
    },
    {
        group: "useCasePrime",
        buttons: [
            { name: "useCasePrimeAuthority", roles: [1, 2, 3, 18] },
        ]
    },
    {
        group: "useCasePrimeHistory",
        buttons: [
            { name: "useCasePrimeHistoryAuthority", roles: [1, 2, 3, 18] },
        ]
    },
    {
        group: "termContent",
        buttons: [
            { name: "termIdCopy", roles: [1, 2, 3] },
            { name: "lessonAddDeleteButton", roles: [1, 2, 3] },
            { name: "additionalRights", roles: [1, 2, 3] }
        ]
    },
    {
        group: "callTypes",
        buttons: [
            { name: "statusChange", roles: [1, 2, 3] },
            { name: "deleteAction", roles: [1, 2, 3] }
        ]
    },
    {
        group: "memberDetailAccounting",
        buttons: [
            { name: "approvePayment", roles: [1, 2, 3, 14] },
            { name: "deletePayment", roles: [1, 2, 3, 14] },
            { name: "addInvoice", roles: [1, 2, 3, 14] },
            { name: "addPayment", roles: [1, 2, 3, 14] },
            { name: "completePayment", roles: [1, 2, 3, 14] },
        ]
    },
    {
        group: "addCandidatePool",
        buttons: [
            { name: "isCorporateButton", roles: [1, 2, 3, 10, 11, 12, 13, 15, 20, 24] }
        ]
    },
    {
        group: "report",
        buttons: [
            { name: "getAllContent", roles: [1, 2, 3, 5, 6, 15, 18] }
        ]
    },
    {
        group: "candidatePool",
        buttons: [
            { name: "candidatePoolMemberClick", roles: [1, 2, 3] }
        ]
    },
    {
        group: "termOperations",
        buttons: [
            { name: "memberTeacherMessage", roles: [1, 2, 3] }
        ]
    },
    {
        group: "memberDetail",
        buttons: [
            { name: "memberDetailPoolRole", roles: [1, 2, 3, 15, 18] }
        ]
    }
]