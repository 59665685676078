import { fetchApi } from '../index';

export const AddMemberTeacherMessage = async (model) => {

    const url = '/member/v1/member_teacher_message/add/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const MemberTeacherMessageFilter = async (model) => {

    const url = '/member/v1/member_teacher_message/filter/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const UpdateMemberTeacherMessageStatus = async (model) => {

    const url = '/member/v1/member_teacher_message/update_status/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}