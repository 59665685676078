import classNames from "classnames";
import { getTimezonesForCountry } from "countries-and-timezones";
import tr from 'date-fns/locale/tr';
import { useEffect, useRef, useState } from "react";
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReactIntlTelInput from 'react-intl-tel-input-v2';
import { useNavigate } from "react-router-dom";
import { NIL as NIL_UUID, v4 as uuid4 } from 'uuid';
import '../../assets/css/intl-tel-input.css';
import { DefaultProfile } from "../../assets/img";
import { Dropdown, DropdownSearch, Input, PageTitle, showCenteredHtmlAlert, showTopAlert } from "../../components";
import NotAuthorized from "../../components/NotAuthorized";
import {deleteSpacesInThePhoneNumber, handleCheckbox, validateEmail} from "../../components/Utility";
import useAuth from "../../hooks/Log/useAuth";
import { useLocalization } from "../../hooks/Utils/useLocalization";
import usePrimeData from "../../hooks/Utils/usePrimeData";
import { url } from "../../routes/utility";
import { EmployeeCreate } from "../../services/Employee/EmployeeService";

const CreateEmployee = () => {

  registerLocale('tr', tr)

  const strings = useLocalization();
  const primeData = usePrimeData();
  const navigate = useNavigate();
  const fileRef = useRef();
  const timeZoneRef = useRef();
  const [employeeId, setEmployeeId] = useState(NIL_UUID);

  //Sayfa yetkileri için bu bölüm kullanılıyor
  const { employeeTypeId, checkPermission, getPermission } = useAuth();
  const roles = getPermission();
  //Sayfa yetkileri için bu bölüm kullanılıyor

  const [avatar, setAvatar] = useState(false);
  const [avatarPicture, setAvatarPicture] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState({ id: 0, name: strings.detail_search_titles.brands });
  const [selectedEmployeeType, setSelectedEmployeeType] = useState({ id: -1, name: strings.detail_search_titles.types });
  const [selectedTeamLeader, setSelectedTeamLeader] = useState({ id: -1, name: strings.detail_search_titles.no_team_leader });
  const [selectedEmployeeStatus, setSelectedEmployeeStatus] = useState({ id: -1, name: strings.detail_search_titles.status });
  const [fullName, setFullName] = useState(null);
  const [email, setEmail] = useState(null);
  const [corporateEmail, setCorporateEmail] = useState(null);
  const [phoneNumberValue, setphoneNumberValue] = useState({ iso2: 'tr', dialCode: '90', phone: '' });
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [timezone, setTimezone] = useState(180);
  const [isOpen, setIsOpen] = useState(false);
  const [sendWithCorporateMail, setSendWithCorporateMail] = useState(true);

  let inputProps = { placeholder: phoneNumberValue.iso2 === 'tr' ? '5XX XXX XX XX' : strings.employee.create_employee.enter_your_phone_number };
  const intlTelOpts = { preferredCountries: ['tr', 'az', 'sa'] };

  const phoneNumberHandle = val => {
    if (val.dialCode !== phoneNumberValue.dialCode) {
      setphoneNumberValue({ iso2: val.iso2, dialCode: val.dialCode, phone: '' });
    } else {
      setphoneNumberValue(val);
    }
  };

  useEffect(() => {
    const formattedPhoneNumber = deleteSpacesInThePhoneNumber(phoneNumberValue.phone, phoneNumberValue.dialCode);
    phoneNumberValue.phone !== '' && setFormattedPhoneNumber(formattedPhoneNumber);

    //Seçilen ülke için time zone alınıyor
    let selectedCountry = getTimezonesForCountry(phoneNumberValue.iso2.toUpperCase());
    let newTimezone = selectedCountry[0].utcOffset;
    //timeZoneRef.current.value = newTimezone;
    setTimezone(timezone => newTimezone);

  }, [phoneNumberValue])

  //Kullanıcı için yeni bir uuid oluşturuyoruz
  useEffect(() => {
    setEmployeeId(employeeId => uuid4());
  }, [])

  const uploadImage = async (file) => {
    let fileName = employeeId;
    let fileDir = "employee";

    let url = process.env.REACT_APP_PROFILE_URL + "/_upload/upload.php?fileName=" + fileName + "&fileDir=" + fileDir;

    const formData = new FormData();
    formData.append("fileToUpload", file);
    formData.append("submit", "submit");

    const response = await fetch(url, {
      method: 'POST',
      body: formData
    });

    const result = await response.json();

    if (result.status === 200) {
      let avatar_url = process.env.REACT_APP_PROFILE_URL + "/" + fileDir + "/" + result.content + "?" + new Date().getTime();
      setAvatarPicture(avatarPicture => result.content);
      setAvatar(avatar => avatar_url)
      fileRef.current.value = "";
    } else {
      showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
      fileRef.current.value = "";
    }
  }

  const submitHandle = async e => {
    e.preventDefault()

    let valid = true
    let errorList = '';

    if (selectedBrand.id === -1) {
      valid = false;
      errorList += strings.errors.brand_null_error + "<br />";
    }

    if (selectedEmployeeType.id === -1) {
      valid = false;
      errorList += strings.errors.type_null_error + "<br />";
    }

    if (fullName === null) {
      valid = false;
      errorList += strings.errors.full_name_null_error + "<br />";
    }
    if (email === null || !validateEmail(email)) {
      valid = false;
      errorList += strings.errors.email_null_error + "<br />";
    }
    if (corporateEmail === null || !validateEmail(corporateEmail)) {
      valid = false;
      errorList += strings.errors.corporate_mail_null_error + "<br />";
    }
    if (phoneNumberValue.phone === '') {
      valid = false;
      errorList += strings.errors.corporate_phone_null_error + "<br />";
    }

    if (timezone === null) {
      valid = false;
      errorList += strings.errors.timezone_null_error + "<br />";
    }

    if (selectedEmployeeStatus.id === -1) {
      valid = false;
      errorList += strings.errors.timezone_null_error + "<br />";
    }
    if (valid) {

      let model = {
        Id: employeeId,
        Brand: selectedBrand.id,
        EmployeeType: selectedEmployeeType.id,
        Name: fullName,
        Email: email,
        CorporateEmail: corporateEmail,
        ProfilePicture: avatarPicture === null ? "default_profile.png" : avatarPicture,
        CorporatePhone: formattedPhoneNumber,
        BeginDate: startDate,
        ManagerId: selectedTeamLeader.id !== -1 ? selectedTeamLeader.id : null,
        TimeZone: timezone,
        Status: selectedEmployeeStatus.id,
        SendWithCorporateMail: sendWithCorporateMail
      };

      const result = await EmployeeCreate(model);

      if (result.status === 200) {
        showTopAlert(strings.employee.create_employee.success.message, "success");
        navigate(url("employee.list"));
      } else {
        showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
      }
    } else {
      showCenteredHtmlAlert(strings.errors.an_error_occurred, errorList, "error");
    }
  }

  return (
    <>
      <PageTitle title={strings.sidebar.employee.add_employee} />
      {checkPermission(roles, employeeTypeId) ? (
        <form onSubmit={submitHandle} className="flex flex-col gap-4 md:gap-8">
          <div className="sm:gap-4 md:gap-8 flex flex-col sm:flex-row sm:justify-center sm:items-center">
            <p className="label-add-user">
              {strings.form_info.profile_photo} <span className="text-red">*</span>
            </p>
            <div className="sm:button-add-user flex justify-center">
              <div className="relative">
                <img
                  className="w-[110px] h-[110px] shadow-profilePhoto rounded-full"
                  id="avatar"
                  src={avatar ? avatar : DefaultProfile}
                  alt=""
                />
                <div className="absolute -right-[13px] top-[10px] z-10">
                  <input
                    ref={fileRef}
                    className="hidden"
                    type="file"
                    id="fileToUpload"
                    name="formFile"
                    accept=".png, .jpg, .jpeg"
                    onChange={(e) => uploadImage(e.target.files[0])}
                  />
                  <label htmlFor="fileToUpload">
                    <div className="cursor-pointer bg-white rounded-full w-[34px] h-[34px] flex items-center justify-center shadow-editProfilePhoto hover:border hover:border-[#ddd] hover:bg-gray-100 transition-all duration-500">
                      <span className="cursor-pointer text-[20px] material-symbols-outlined animate-fadeIn font-bold">
                        edit
                      </span>
                    </div>
                  </label>
                </div>
              </div>
            </div>

          </div>
          <div className="sm:gap-4 md:gap-8 flex flex-col sm:flex-row sm:justify-center sm:items-center">
            <p className="label-add-user">
              {strings.table.brand}
            </p>
            <div className="w-full md:max-w-[480px] flex flex-col gap-2">
              <Dropdown
                data={[{ id: -1, name: strings.detail_search_titles.brands }, ...primeData.brandList]}
                selectedData={selectedBrand}
                setSelectedData={setSelectedBrand}
                classnames="md:!max-w-[480px]"
              />
            </div>
          </div>
          <div className="sm:gap-4 md:gap-8 flex flex-col sm:flex-row sm:justify-center sm:items-center">
            <p className="label-add-user">
              {strings.form_info.department} <span className="text-red">*</span>
            </p>
            <div className="w-full md:max-w-[480px] flex flex-col gap-2">
              <DropdownSearch
                inputPlaceholder={strings.detail_search_titles.search_type}
                data={[{ id: -1, name: strings.detail_search_titles.types }, ...primeData.employeeTypeList]}
                selectedData={selectedEmployeeType}
                setSelectedData={setSelectedEmployeeType}
                classnames="md:!max-w-[480px]"
              />
            </div>
          </div>
          <div className="sm:gap-4 md:gap-8 flex flex-col sm:flex-row sm:justify-center sm:items-center">
            <p className="label-add-user">
              {strings.form_info.team_leader}
            </p>
            <Dropdown
              data={[{ id: -1, name: strings.detail_search_titles.no_team_leader }, ...primeData.teamLeadList]}
              selectedData={selectedTeamLeader}
              setSelectedData={setSelectedTeamLeader}
              classnames="md:!max-w-[480px]"
            />
          </div>
          <hr />
          <div className="sm:gap-4 md:gap-8 flex flex-col sm:flex-row sm:justify-center sm:items-center">
            <p className="label-add-user">
              {strings.form_info.full_name} <span className="text-red">*</span>
            </p>
            <div className="w-full md:max-w-[480px] flex flex-col gap-2">
              <Input
                id="username"
                type="text"
                name="username"
                onChange={(e) => setFullName(e.target.value)}
                classnames="md:max-w-[480px]"
              />
            </div>
          </div>
          <div className="sm:gap-4 md:gap-8 flex flex-col sm:flex-row sm:justify-center sm:items-center">
            <p className="label-add-user">
              {strings.form_info.email} <span className="text-red">*</span>
            </p>
            <div className="w-full md:max-w-[480px] flex flex-col gap-2">
              <Input
                id="email"
                type="text"
                name="email"
                onChange={e => setEmail(e.target.value)}
                classnames="md:max-w-[480px]"
                placeholder="example@example.com"
              />
            </div>
          </div>
          <hr />
          <div className="sm:gap-4 md:gap-8 flex flex-col sm:flex-row sm:justify-center sm:items-start">
            <p className="label-add-user mt-3">
              {strings.form_info.corporate_email} <span className="text-red">*</span>
            </p>
            <div className="w-full md:max-w-[480px] flex flex-col gap-2">
              <Input
                id="corporate_email"
                type="text"
                name="corporate_email"
                onChange={e => setCorporateEmail(e.target.value)}
                classnames="md:max-w-[480px]" 
                placeholder="example@example.com"
              />
              <label className="flex justify-start items-start">
                <input
                  id="send_with_corporate_mail"
                  type="checkbox"
                  className="hidden"
                  onChange={() => setSendWithCorporateMail(!sendWithCorporateMail)}
                />
                <label
                  htmlFor="send_with_corporate_mail" 
                  className={`${handleCheckbox(sendWithCorporateMail)} mr-2 w-4 h-4 flex-shrink-0 rounded-[3px] cursor-pointer duration-500 flex items-center justify-center`}
                >
                  <span className={classNames('text-inherit text-[17px] material-symbols-outlined animate-fadeIn font-bold', { 'hidden' : !sendWithCorporateMail})}>
                    done
                  </span>
                </label>
                <p className="!font-normal text-[12px]">{strings.employee.create_employee.corporate_mail_approve}</p>
              </label>
            </div>
          </div>
          <div className="sm:gap-4 md:gap-8 flex flex-col sm:flex-row sm:justify-center sm:items-center">
            <p className="label-add-user">
              {strings.form_info.phone_number} <span className="text-red">*</span>
            </p>
            <div className="w-full md:max-w-[480px] flex flex-col gap-2">
              <ReactIntlTelInput
                className="form-input w-full md:max-w-[480px]"
                inputStyle={{ color: 'green' }}
                inputProps={inputProps}
                intlTelOpts={intlTelOpts}
                value={phoneNumberValue}
                containerStyle={{ width: '480px' }}
                onChange={phoneNumberHandle}
                type="text"
                name="phone"
              />
            </div>
          </div>
          <div className="sm:gap-4 md:gap-8 flex flex-col sm:flex-row sm:justify-center sm:items-center">
            <p className="label-add-user">
              {strings.form_info.start_date}
            </p>
            <div className="w-full md:max-w-[480px] flex flex-col gap-2 relative">
              <DatePicker
                locale="tr"
                dateFormat="dd/MM/yyyy"
                className="form-input w-full md:max-w-[480px]"
                selected={startDate}
                onChange={(date) => { setStartDate(date); setIsOpen(false) }}
                onClickOutside={() => setIsOpen(false)}
                placeholderText={strings.form_info.date_placeholder}
                open={isOpen}
                onFocus={() => setIsOpen(true)}
              />
              <button type="button" onClick={() => setIsOpen(!isOpen)}>
                <span className="material-symbols-outlined absolute right-6 top-2 text-lg">
                  calendar_today
                </span>
              </button>
            </div>
          </div>
          <div className="sm:gap-4 md:gap-8 flex flex-col sm:flex-row sm:justify-center sm:items-center">
            <p className="label-add-user">
              {strings.form_info.timezone}
            </p>
            <div className="w-full md:max-w-[480px] flex flex-col gap-2">
              <input
                ref={timeZoneRef}
                id="timezone"
                type="number"
                name="timezone"
                className={`form-input w-full md:max-w-[480px]`}
                defaultValue={timezone} />
            </div>
          </div>
          <div className="sm:gap-4 md:gap-8 flex flex-col sm:flex-row sm:justify-center sm:items-center">
            <p className="label-add-user">
              {strings.form_info.status}
            </p>
            <Dropdown
              data={primeData.employeeStatusList}
              selectedData={selectedEmployeeStatus}
              setSelectedData={setSelectedEmployeeStatus}
              classnames="md:!max-w-[480px]"
            />
          </div>
          <input type="hidden" id="employeId" name="employeeId" value={employeeId} />
          <input type="hidden" id="profilePicture" name="profilePicture" value='' />
          <button
            type="submit"
            className="button search-button text-white bg-blue border-blue mt-4 mx-auto px-10 mb-5">
            {strings.button.create}
          </button>
        </form>
      ) : (<NotAuthorized />)}
    </>
  );
};

export default CreateEmployee;
