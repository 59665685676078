import React, { forwardRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/Log/useAuth";
import { useLocalization } from "../../hooks/Utils/useLocalization";
import StartTaskModal from "../../pages/Member/components/MemberDetail/Tasks/StartTaskModal";
import { url } from "../../routes/utility";
import { GetActiveTasks } from "../../services/Task/TaskService";
import Modal from "../modal/Modal";
import Portal from "../Portal";
import { closeInfo } from "../Toggle";
import { getLogoByBrandId, ISOStringToDate } from "../Utility";

const NavbarTask = forwardRef((props, ref) => {
    
  const strings = useLocalization();
  const { employee } = useAuth();
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState({});
  
  //Kullanıcının son 5 görevini getiren fonksiyon
  const getActiveTasks = async (employeeId) => {

    const result = await GetActiveTasks(employeeId);

    if(result.status === 200){
      let resultContent = JSON.parse(result.content);
      setData(data => resultContent);
    }
  }

  const closeModal = () => {
    setModal(false);
  }

  useEffect(() => {
    getActiveTasks(employee.EmployeeId);
  }, [])

  return (
    <>
      <p className="px-4 font-bold py-3">{strings.navbar.tasks.title}</p>
      <hr />
      <div className={`max-h-64 transition-all duration-300 py-1 ${data.length > 3 ? 'overflow-y-scroll sm:overflow-y-hidden hover:overflow-y-scroll' : ''}`}>
        {data.length > 0 ? 
          (
            data.map((task, index) => (
              <button
                key={index}
                className="text-start w-full"
                onClick={() => {
                  setModalData({
                    memberId: task.member.id,
                    memberName: task.member.fullName,
                    taskId: task.taskId,
                    taskMessage: task.message,
                    showAiAnalysis: task?.isTaskCompatibleToAiAnalysis
                  });
                  setModal(true);
                  closeInfo(ref);
                }}
              >
                {index !== 0 && <hr />}
                <div className="px-4 flex gap-2 py-2 bg-white hover:bg-[#f0f0f0]">
                  <div className="w-10 h-10 flex shrink-0">
                    <img src={getLogoByBrandId(task.brandId)} width="40" height="40" alt="logo" />
                  </div>
                  <div>
                    <p className="font-bold">{task.member.fullName}</p>
                    <p>{task.message}</p>
                    <p className="text-[13px] !text-[#989898]">{ISOStringToDate(task.dueDate, employee.TimeZone)}</p>
                  </div>
                </div>
              </button>
            ))
          )
          :
          (
            <>
              <div className="py-4 min-w-full text-center bg-white hover:bg-[#f0f0f0]">{strings.errors.no_active_task_found}</div>
            </>
          )
        }
      </div>
      <hr />
      <Link
        to={url("task")}
        className="px-4 py-3 flex justify-between"
      >
        <p>{strings.navbar.tasks.list_all}</p>
        <span className="material-symbols-outlined text-base font-bold">
          chevron_right
        </span>
      </Link>

      <Portal container={document.getElementById('modal-start-task')}>
        <Modal modal={modal} setModal={setModal} classes={{ modal: "h-[90vh] max-w-[1140px]", content: "max-md:overflow-y-scroll overflow-x-hidden h-[95%]"}}>
          <StartTaskModal data={modalData} closeModal={closeModal} />
        </Modal>
      </Portal>
    </>
  );
});

export default NavbarTask;
