import {PageTitle} from "../../components";
import {Accordion} from "../Member/components";
import React from "react";
import {useLocalization} from "../../hooks/Utils/useLocalization";

const InactiveReport = () => {

    const strings = useLocalization();

    return (
        <>
            <PageTitle title={strings.sidebar.report.inactive_reports} />

            <Accordion
                title={strings.report.inactive_reports.information_title}
                outSideClose={true}
                isOpen={true}
                content={
                    <p dangerouslySetInnerHTML={{ __html: strings.report.inactive_reports.information_one}}></p>
                }
            />
            <div className="flex justify-center mt-5">
                <iframe title="reference" width="1725" height="1725" src="https://lookerstudio.google.com/embed/reporting/ef4eba6e-1e73-48b0-8575-b056c8a4a091/page/p_jbda0g7yed" frameBorder="0" allowFullScreen></iframe>
            </div>
        </>
    )
}

export default InactiveReport;