import React, { useState } from "react";
import { Modal, NoRecordsFound } from "../../../../components";
import { useLocalization } from "../../../../hooks/Utils/useLocalization";
import AppliedDiscountModal from "./AppliedDiscountModal";
import PaymentTableRow from "./PaymentTableRow";

const PaymentTable = ({ data }) => {
  
  const strings = useLocalization();  

  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState({});

  return (
    <>
      <div className="mx-auto px-4 mb-4">
        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
          <div className="inline-block min-w-full rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal text-header">
              <thead>
                <tr>
                  <th className="th sm:w-[20%]">
                    <p className="min-w-max">{strings.table.payment_status}</p>
                    </th>
                  <th className="th sm:w-[40%]">
                    <p className="min-w-max">{strings.table.person_informations}</p>
                    </th>
                  <th className="th sm:w-[40%]">
                    <p className="min-w-max">{strings.table.payment_informations}</p>
                    </th>
                  <th className="th sm:w-[40%]">
                    <p className="min-w-max">{strings.table.accounting_approve_information}</p>
                    </th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 ?  
                    data.map((payment, index) => (
                        <PaymentTableRow 
                            key={index}
                            payment={payment}
                            setModal={setModal}
                            setModalContent={setModalContent}
                        />
                    )) 
                    : 
                    (
                        <tr className="bg-white hover:bg-[#f0f0f0]">
                            <td colSpan="4">
                                <NoRecordsFound />
                            </td>
                        </tr>
                    )    
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {(Object.keys(modalContent).length !== 0 && (
          <Modal
            modal={modal}
            setModal={setModal}
            classes={{ modal: "h-[450px] max-w-[620px]" }}
          >
            {modalContent.element === "discounts" &&
              <AppliedDiscountModal orderId={modalContent.orderId} />
            }
          </Modal>
        ))}
    </>
  );
};

export default PaymentTable;
