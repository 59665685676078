export const MainItems = (strings) => [
    {
        pathName: "dashboard",
        path: "/dashboard",
        icon: "home",
        isIcon: false,
        title: strings.sidebar.home_page,
    },
    {
        pathName: "academy",
        path: "/academy",
        icon: "school",
        isIcon: true,
        title: strings.sidebar.academy.title,
    },
]

export const MemberOperationItems = (strings) => [
    {
        pathName: "member.student",
        path: "/member/student",
        icon: "person",
        isIcon: false,
        title: strings.sidebar.member.students,
    },
    {
        pathName: "member.candidate",
        path: "/member/candidate",
        icon: "person_add",
        isIcon: false,
        title: strings.sidebar.member.candidates,
    },
    {
        pathName: "member.candidatepool",
        path: "/member/candidatepool",
        icon: "group",
        isIcon: false,
        title: strings.sidebar.member.candidate_pool,
    },
    {
        pathName: "member.newcandidate",
        path: "/member/newcandidate",
        icon: "group_add",
        isIcon: false,
        title: strings.sidebar.member.new_candidates,
    },
    {
        pathName: "member.addcandidate",
        path: "/member/addcandidate",
        icon: "add_circle",
        isIcon: false,
        title: strings.sidebar.member.add_candidate_to_pool,
    },
]

export const AccountingOperationItems = (strings) => [
    {
        pathName: "accounting.currentcard",
        path: "/accounting/currentcard",
        icon: "credit_card",
        isIcon: false,
        title: strings.sidebar.accounting.current_card,
    },
    {
        pathName: "accounting.invoice",
        path: "/accounting/invoice",
        icon: "calculate",
        isIcon: false,
        title: strings.sidebar.accounting.invoices,
    },
    {
        pathName: "accounting.payment",
        path: "/accounting/payment",
        icon: "wallet",
        isIcon: false,
        title: strings.sidebar.accounting.payments,
    },
    {
        pathName: "accounting.campaign",
        path: "/accounting/campaign",
        icon: "sell",
        isIcon: false,
        title: strings.sidebar.accounting.campaigns,
    },
    {
        pathName: "accounting.addcampaign",
        path: "/accounting/addcampaign",
        icon: "add_circle",
        isIcon: false,
        title: strings.sidebar.accounting.add_campaign,
    },
    {
        pathName: "accounting.discount",
        path: "/accounting/discount",
        icon: "price_change",
        isIcon: false,
        title: strings.sidebar.accounting.discount_codes,
    },
]

export const TeacherOperationItems = (strings) => [
    {
        pathName: "teacher.program",
        path: "/teacher/program",
        icon: "hourglass_empty",
        isIcon: false,
        title: strings.sidebar.teacher.teacher_program,
    },
    {
        pathName: "teacher.allprogram",
        path: "/teacher/allprogram",
        icon: "date_range",
        isIcon: false,
        title: strings.sidebar.teacher.all_teacher_program,
    },
]

export const EmployeeOperationItems = (strings) => [
    {
        pathName: "employee.list",
        path: "/employee/list",
        icon: "person",
        isIcon: false,
        title: strings.sidebar.employee.list,
    },
    {
        pathName: "employee.create",
        path: "/employee/create",
        icon: "person_add",
        isIcon: false,
        title: strings.sidebar.employee.add_employee,
    },
]

export const ReportOperationItems = (strings) => [
    {
        pathName: "report.candidatepoolreport",
        path: "/report/candidatepoolreport",
        icon: "problem",
        isIcon: false,
        title: strings.sidebar.report.candidate_pool,
    },
    {
        pathName: "report.salesmanreport",
        path: "/report/salesmanreport",
        icon: "lab_profile",
        isIcon: false,
        title: strings.sidebar.report.salesman,
    },
    {
        pathName: "report.dailyreport",
        path: "/report/dailyreport",
        icon: "date_range",
        isIcon: false,
        title: strings.sidebar.report.daily,
    },
    {
        pathName: "report.npsreport",
        path: "/report/npsreport",
        icon: "pages",
        isIcon: false,
        title: strings.sidebar.report.nps,
    },
    {
        pathName: "report.lessonattendreport",
        path: "/report/lessonattendreport",
        icon: "menu_book",
        isIcon: false,
        title: strings.sidebar.report.lesson_attend,
    },
    {
        pathName: "report.target",
        path: "/report/target",
        icon: "target",
        isIcon: false,
        title: strings.sidebar.report.target_follow_analysis,
    },
    {
        pathName: "report.call",
        path: "/report/call",
        icon: "perm_phone_msg",
        isIcon: false,
        title: strings.sidebar.report.call_report,
    },
    {
        pathName: "report.reference",
        path: "/report/reference",
        icon: "bar_chart_4_bars",
        isIcon: false,
        title: strings.sidebar.report.reference_dashboard,
    },
    {
        pathName: "report.inactive",
        path: "/report/inactive",
        icon: "delete_history",
        isIcon: false,
        title: strings.sidebar.report.inactive_reports,
    },
    {
        pathName: "report.market",
        path: "/report/market",
        icon: "storefront",
        isIcon: false,
        title: strings.sidebar.report.market_reports,
    },
    {
        pathName: "report.demo",
        path: "/report/demo",
        icon: "dashboard",
        isIcon: false,
        title: strings.sidebar.report.demo_dashboard
    },
    {
        pathName: "report.events",
        path: "/report/events",
        icon: "whatshot",
        isIcon: false,
        title: strings.sidebar.report.panel_events
    }
]

export const ProductReportOperationItems = (strings) => [
    {
        pathName: "productreports.productreport",
        path: "/productreports/productreport",
        icon: "inventory_2",
        isIcon: false,
        title: strings.sidebar.product_report.product,
    },
]

export const UseCaseItems = (strings) => [
    {
        pathName: "usecase.list",
        path: "/usecase/list",
        icon: "psychology",
        isIcon: false,
        title: strings.sidebar.use_case.list,
    },
    {
        pathName: "usecase.create",
        path: "/usecase/create",
        icon: "add_circle",
        isIcon: false,
        title: strings.sidebar.use_case.create,
    }
]

export const HistoryItems = (strings) => [
    {
        pathName: "history.list",
        path: "/history/list",
        icon: "history",
        isIcon: false,
        title: strings.sidebar.history.list
    }
]