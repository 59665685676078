import { fetchApi } from '../index';

export const GetMemberBrandId = async (memberId) => {

    const url = '/member/v1/member/get_member_brand_id/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const GetMemberName = async (memberId) => {

    const url = '/member/v1/member/get_member_name/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const GetPhoneNumberByMemberId = async (memberId) => {
    
    const url = '/member/v1/member/get_phone_number_by_member_id/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const GetEmailByMemberId = async (memberId) => {

    const url = '/member/v1/member/get_member_email/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const UpdateMemberEmail = async (memberId, email) => {
    const url = '/member/v1/member/update_email/';

    const payload = {
        memberId: memberId,
        email: email
    };

    return await fetchApi(url, payload);
}

export const CheckMemberCorporate = async (memberId) => {
    const url = '/member/v1/member/check_member_corporate/';

    const payload = {
        memberId: memberId,
    };

    return await fetchApi(url, payload);
}

export const MemberBrandChange = async (model) => {
    const url = '/member/v2/member/member_brand_change/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const GetMemberCorporateInformations = async (memberId) => {
    const url = '/member/v1/member/get_member_corporate_informations/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const GetReasonToLearnEnglishes = async (memberId) => {
    const url = '/member/v1/member/get_reason_to_learn_englishes/'

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}