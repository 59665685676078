import {useLocalization} from "../../../../../../../../../hooks/Utils/useLocalization";
import React from "react";
import {formatForeignDateToTurkishDateWithTimezone, isItSpecified} from "../../../../../../../../../components/Utility";
import useAuth from "../../../../../../../../../hooks/Log/useAuth";

const TeacherNetworkLogTable = ({ data }) => {

    const strings = useLocalization();
    const { employee } = useAuth();

    const notSpecified = strings.errors.it_is_not_specified;

    // Servisten gelen tip değerlerinin string karşılığını döndüren fonksiyon
    const getTypeDescriptionByType = (type) => {
        switch (type)  {
            case 900:
                return strings.member.member_detail.terms.operation_modal.foreign_call_logs.call_types.started
            case 901:
                return strings.member.member_detail.terms.operation_modal.foreign_call_logs.call_types.receiving
            case 907:
                return strings.member.member_detail.terms.operation_modal.foreign_call_logs.call_types.ringing
            case 908:
                return strings.member.member_detail.terms.operation_modal.foreign_call_logs.call_types.ongoing
            case 909:
                return strings.member.member_detail.terms.operation_modal.foreign_call_logs.call_types.finished
        }
    }

    // Download ve upload değerlerinin string karşılığını (Mükemmel, iyi, kötü) döndüren fonksiyon
    const getConnectionStringsByConnectionValue = (connectionValue) => {
        if(connectionValue === 1) {
            return strings.member.member_detail.terms.operation_modal.foreign_call_logs.table_rows.excellent
        } else if (connectionValue === 2) {
            return strings.member.member_detail.terms.operation_modal.foreign_call_logs.table_rows.good
        } else if (connectionValue >= 3) {
            return strings.member.member_detail.terms.operation_modal.foreign_call_logs.table_rows.poor
        }
    }

    // Bu fonksiyon servisten gelen Down: Up: değerlerinin karşılığını çıktı verir.
    const extractDownUpValues = (text) => {
        const replacedText = text.replaceAll(" ", "");
        const regex = /Down:(\d+)Up:(\d+)/;
        const match = replacedText.match(regex);

        if (match) {
            const down = parseInt(match[1]);
            const up = parseInt(match[2]);
            return [down, up];
        } else {
            return null;
        }
    }

    // Servisten gelen not değerlerini düzenlenmek için kullanılan fonksiyon
    const handleShowNoteValue = (note) => {
        if(note.includes('Down') || note.includes('Up')){
            const downUpValues = extractDownUpValues(note);

            const downloadText = strings.member.member_detail.terms.operation_modal.foreign_call_logs.table_rows.download_speed
                .replace("#value#", getConnectionStringsByConnectionValue(downUpValues[0]));
            const uploadText = strings.member.member_detail.terms.operation_modal.foreign_call_logs.table_rows.upload_speed
                .replace("#value#", getConnectionStringsByConnectionValue(downUpValues[1]));

            return downloadText + "<br />" + uploadText;
        } else if (note.includes("call ended by remote")) {
            return strings.member.member_detail.terms.operation_modal.foreign_call_logs.table_rows.call_ended
        } else if (note.includes("missed call")) {
            return strings.member.member_detail.terms.operation_modal.foreign_call_logs.table_rows.missed_call
        } else {
            return "-";
        }
    }

    return (
        <div className="mx-auto px-4 mb-4">
            <div
                className="-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
                <div className="inline-block min-w-full rounded-lg overflow-hidden">
                    <table className="min-w-full leading-normal text-header">
                        <thead>
                        <tr>
                            <th className="th">
                                <p>{strings.member.member_detail.terms.operation_modal.foreign_call_logs.table_rows.call_type}</p>
                            </th>
                            <th className="th">
                                <p>{strings.member.member_detail.terms.operation_modal.foreign_call_logs.table_rows.caller_teacher}</p>
                            </th>
                            <th className="th">
                                <p>{strings.member.member_detail.terms.operation_modal.foreign_call_logs.table_rows.call_note}</p>
                            </th>
                            <th className="th">
                                <p>{strings.member.member_detail.terms.operation_modal.foreign_call_logs.table_rows.call_date}</p>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => {

                                /*
                                    * Eğitmen adı verinin sadece ilkinde gönderiliyor.
                                    * Bu nedenle ilk eğitmen verisi bir değişkene set edildi ve tüm satırlarda gösteriliyor.
                                */
                                const teacherValue = data[0].caller;

                                return (
                                    <tr key={index} className="bg-white hover:bg-[#f0f0f0] h-[56px]">
                                        <td className="td align-middle">
                                            {getTypeDescriptionByType(+item.type)}
                                        </td>
                                        <td className="td align-middle">
                                            {isItSpecified(teacherValue, notSpecified)}
                                        </td>
                                        <td className="td align-middle">
                                            <p dangerouslySetInnerHTML={{__html: handleShowNoteValue(item.note)}}/>
                                        </td>
                                        <td className="td align-middle">
                                            {formatForeignDateToTurkishDateWithTimezone(item.mdfyd_date, employee?.TimeZone)}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default TeacherNetworkLogTable;