import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAuth from "../../../../../hooks/Log/useAuth";
import { useLocalization } from "../../../../../hooks/Utils/useLocalization";
import { GetAllMemberFormList } from "../../../../../services/Member/MemberFormService";
import { addTimeZoneToFullDate } from "../../../../../components/Utility";
import { Loading, PageTitle } from "../../../../../components";

const Applications = () => {
  const strings = useLocalization();
  const { memberId } = useParams() || false;
  const { employee } = useAuth();

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);

  const getMemberFormList = async (memberId) => {
    const result = await GetAllMemberFormList(memberId);
    if (result.status === 200) {
      const resultContent = JSON.parse(result.content);
      setName((name) => resultContent.name);
      setPhone((phone) => resultContent.phone);
      setApplications((applications) => resultContent.applications);
      setLoading(loading => false);
    }
  };

  useEffect(() => {
    if (memberId) {
      getMemberFormList(memberId);
    }
  }, []);

  return (
    <>
      <div className="overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
        <PageTitle title={strings.member.member_detail.header.applications} />
        <div className="inline-block min-w-full rounded-lg overflow-hidden">
          {loading ? (
            <Loading />
          ) : (
            <table className="min-w-full leading-normal text-header">
              <thead>
                <tr>
                  <th className="th">
                    {
                      strings.member.member_detail.header.content
                        .contact_informations
                    }
                  </th>
                  <th className="th">{strings.table.utm_details}</th>
                  <th className="th">{strings.table.created_date}</th>
                </tr>
              </thead>
              <tbody>
                {applications.length > 0 ? (
                  applications.map((application, index) => (
                    <tr key={index} className="bg-white hover:bg-[#f0f0f0]">
                      <td className="td align-middle">
                        <div className="flex items-center">
                          <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
                            person
                          </span>
                          <p className="font-bold">{name}</p>
                        </div>
                        <div className="flex items-center">
                          <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
                            call
                          </span>
                          <p>{phone}</p>
                        </div>
                      </td>
                      <td className="td align-middle">
                        <div className="flex items-center my-1">
                          <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
                            link
                          </span>
                          <p className="font-bold">
                            Landing Page:{" "}
                            {application.landingPage
                              ? application.landingPage
                              : "-"}
                          </p>
                        </div>
                        <div className="flex items-center my-1">
                          <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
                            ads_click
                          </span>
                          <p className="font-bold">
                            Source :{" "}
                            {application.utmSource
                              ? application.utmSource
                              : "-"}
                          </p>
                        </div>
                        <div className="flex items-center my-1">
                          <span className="material-symbols-outlined mr-1 text-[19px]">
                            note_stack
                          </span>
                          <p className="font-bold">
                            Content :{" "}
                            {application.utmContent
                              ? application.utmContent
                              : "-"}
                          </p>
                        </div>
                        <div className="flex items-center my-1">
                          <span className="material-symbols-outlined mr-1 text-[19px]">
                            travel_explore
                          </span>
                          <p className="font-bold">
                            Medium :{" "}
                            {application.utmMedium
                              ? application.utmMedium
                              : "-"}
                          </p>
                        </div>
                      </td>
                      <td className="td align-middle">
                        <p>
                          {addTimeZoneToFullDate(
                            application.createdDate,
                            employee.TimeZone
                          )}
                        </p>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="bg-white hover:bg-[#f0f0f0]">
                    <td className="td" colSpan={3}>
                      <p className="py-3 min-w-full text-center">
                        {
                          strings.member.member_detail.others
                            .no_application_error
                        }
                      </p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default Applications;
