import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Dropdown, Input, showCenteredHtmlAlert, showTopAlert } from '../../../../../../components';
import { PaymentOptions } from '../../../../../../consts/Constants';
import { checkMoney, checkNumber, handleCheckbox, roundPrice } from '../../../../../../components/Utility';
import { useLocalization } from '../../../../../../hooks/Utils/useLocalization';
import usePrimeData from '../../../../../../hooks/Utils/usePrimeData';
import { AddPayment, GetBankListById } from '../../../../../../services/Payment/PaymentService';

const AddPaymentModal = (props) => {

    const strings = useLocalization();
    const primeData = usePrimeData();
    const { orderId, memberId, maxValue, closeButton, classes } = props;

    const firstCompany = { id: -1, name: strings.member.member_detail.sales_operations.choose_company };
    const firstBank = { id: -1, name: strings.member.member_detail.sales_operations.choose_bank };
    const firstPaymentType = { id: -1, name: strings.member.member_detail.accounting.choose_payment_type };
    const firstInstallment = { id: -1, name: strings.member.member_detail.accounting.choose_payment_option };
    const firstSourceType = { id: -1, name: strings.member.member_detail.accounting.choose_channel };
    const firstUser = { id: memberId, name: strings.member.member_detail.accounting.user_do_task }

    const [company, setCompany] = useState(firstCompany);
    const companyList = [firstCompany, ...primeData.localCompanyList];

    const [bank, setBank] = useState(firstBank);
    const [bankList, setBankList] = useState([]);

    const [paymentType, setPaymentType] = useState(firstPaymentType);
    const paymentTypeList = [firstPaymentType, ...primeData.paymentTypeList];

    const [installment, setInstallment] = useState(firstInstallment)
    const installmentList = [firstInstallment, ...PaymentOptions];

    const [sourceType, setSourceType] = useState(firstSourceType);
    const sourceTypeList = [firstSourceType, ...primeData.sourceTypeList];

    const [createUser, setCreateUser] = useState(firstUser);
    const createUserList = [firstUser, ...primeData.employeeList];

    const [totalPrice, setTotalPrice] = useState(roundPrice(maxValue) || null);
    const [taxRate, setTaxRate] = useState(8);
    const [isPartialPayment, setIsPartialPayment] = useState(false);
    const [approvalCode, setApprovalCode] = useState(null);
    const [bankMessage, setBankMessage] = useState('');

    const getBankListById = async () => {
        const model = {
            bankLocalCompanyId: company.id,
            paymentType: paymentType.id
        }

        const result = await GetBankListById(model);

        if (result.status === 200) {

            let arrayList = [];

            const data = JSON.parse(result.content);
            data.map((item) => {
                let arrayItem = { id: parseInt(item.id), name: item.name };
                arrayList.push(arrayItem);
            })

            setBankList([firstBank, ...arrayList]);
        }
    }

    //Şirket seçiniz olarak işaretlenirse önceki ödeme tipi ve banka seçimlerini sıfırlıyoruz
    useEffect(() => {
        if (company.id === -1) {
            setPaymentType(firstPaymentType);
            setBank(firstBank);
        }
    }, [company])

    //Ödeme tipi alanı ödeme tipi seçiniz olarak işaretlenirse önceki banka seçimini sıfırlıyoruz
    useEffect(() => {
        if (paymentType.id === -1) {
            setBank(firstBank);
        }
    }, [paymentType])

    //Eğer Ödeme tipi ve şirket seçildiyse bankaları getiriyoruz
    //Ödemeler kredi kartı, havale/eft ya da dijital kredi ile yapılmadıysa bankaları listelemeyeceğiz
    useEffect(() => {
        //Eğer banka olmayan bir seçenek seçilirse bankanın listede kalmaması için bankayı
        //İlk bankaya çekiyoruz
        setBank(firstBank);

        if (company.id !== -1 && paymentType.id !== -1 && paymentType.id < 3) {
            getBankListById();
        } else {
            setBankList([firstBank]);
        }
    }, [company, paymentType])


    const submitHandle = async e => {
        e.preventDefault();
        let valid = true;
        let errorList = '';

        if (company.id === -1) {
            valid = false;
            errorList += strings.errors.company_null_error + "<br />";
        }

        if (paymentType.id === -1) {
            valid = false;
            errorList += strings.errors.payment_type_null_error + "<br />";
        }

        if (sourceType.id === -1) {
            valid = false;
            errorList += strings.errors.source_type_null_error + "<br />";
        }

        if (paymentType.id > -1 && paymentType.id < 3 && bank.id === -1) {
            valid = false;
            errorList += strings.errors.bank_null_error + "<br />";
        }

        if (taxRate > 100 || taxRate === '') {
            valid = false;
            errorList += strings.errors.tax_rate_length_error + "<br />";
        }

        if (totalPrice === '') {
            valid = false;
            errorList += strings.errors.total_price_null_error + "<br />";
        }

        if (roundPrice(parseFloat(totalPrice)) > roundPrice(parseFloat(maxValue))) {
            valid = false;
            errorList += strings.errors.max_price_length_error + "<br />";
        }

        const model = {
            orderId: orderId,
            createUser: createUser.id,
            bankId: bank.id,
            bankStatusMessage: bankMessage,
            isPartialPayment: isPartialPayment,
            paymentType: paymentType.id,
            sourceType: sourceType.id,
            totalPrice: parseFloat(totalPrice),
            installment: installment.id === -1 ? 0 : installment.id,
            taxValue: taxRate,
            approvalCode: approvalCode
        }

        if (valid) {
            const result = await AddPayment(model);

            if (result.status === 200) {
                showTopAlert(strings.member.member_detail.accounting.add_payment_success, "success");
                //Top end alert ekrandan kaybolduktan sonra modal'ın kapanmasını sağlayan fonksiyon
                setTimeout(() => closeButton(), 1500);
            } else {
                showTopAlert(strings.errors.an_error_occurred + "/" + result.content , "error");
            }
            
        } else {
            showCenteredHtmlAlert(strings.errors.an_error_occurred, errorList, "error");
        }

    }

    return (
        <>
            <form onSubmit={submitHandle}>
                <div className="flex justify-center">
                    <p className="font-bold text-[15px]">{strings.member.member_detail.accounting.add_payment_title}</p>
                </div>
                <div className={`flex flex-col gap-4 mt-5  max-md:overflow-y-scroll max-md:hover:overflow-y-scroll ${classes}`}>
                    <div className="flex flex-col md:flex-row text-start gap-4 w-full ">
                        <div className="flex flex-col px-[10px] pb-0 gap-1 w-full">
                            <label htmlFor="bank">{strings.form_info.company} <span className="text-red">*</span></label>
                            <Dropdown
                                classnames="md:!max-w-full"
                                data={companyList}
                                selectedData={company}
                                setSelectedData={setCompany}
                            />
                        </div>
                        <div className="flex flex-col px-[10px] pb-0 gap-1 w-full">
                            <label htmlFor="payment_type">{strings.form_info.payment_type} <span className="text-red">*</span></label>
                            <Dropdown
                                classnames="md:!max-w-full"
                                data={paymentTypeList}
                                selectedData={paymentType}
                                setSelectedData={setPaymentType}
                            />
                        </div>
                        <div className="flex flex-col px-[10px] pb-0 gap-1 w-full">
                            <label htmlFor="bank">{strings.form_info.bank} <span className="text-red">*</span></label>
                            <Dropdown
                                classnames="md:!max-w-full"
                                data={bankList}
                                selectedData={bank}
                                setSelectedData={setBank}
                            />
                        </div>
                    </div>

                    {company.id !== -1 && paymentType.id !== -1 && (
                        <>
                            <div className="flex flex-col md:flex-row text-start gap-4 w-full ">
                                <div className="flex flex-col px-[10px] pb-0 gap-1 w-full">
                                    <div className='flex gap-2'>
                                        <div><strong>{strings.form_info.price}</strong><span className="text-[10px]"> /  {strings.member.member_detail.accounting.amount_note}</span></div>
                                        <div className="relative group">
                                            <span className="material-symbols-outlined text-[20px] cursor-pointer">
                                                info
                                            </span>
                                            <div className="tooltip-top !-top-[55px]">
                                                <p>{strings.member.member_detail.accounting.max_amount}: {roundPrice(maxValue)} ₺</p>
                                            </div>
                                        </div>
                                    </div>
                                    <Input
                                        id="amount"
                                        type="text"
                                        value={totalPrice}
                                        onKeyDown={(e) => !checkMoney(e) && e.preventDefault()}
                                        onChange={(e) => setTotalPrice(e.target.value)}
                                    />
                                </div>

                                <div className="flex flex-col px-[10px] pb-0 gap-1 w-full">
                                    <label htmlFor="kdv_rate">{strings.form_info.kdv_rate}</label>
                                    <Input
                                        id="kdv_rate"
                                        type="text"
                                        value={taxRate}
                                        onKeyDown={(e) => !checkNumber(e) && e.preventDefault()}
                                        onChange={(e) => setTaxRate(e.target.value)}
                                    />
                                </div>
                                <div className="flex flex-col px-[10px] pb-0 gap-1 w-full">
                                    <label htmlFor="approve_code">{strings.form_info.approve_code}</label>
                                    <Input
                                        id="approve_code"
                                        type="text"
                                        onChange={(e) => setApprovalCode(e.target.value)}
                                    />
                                </div>

                            </div>
                            <div className="flex flex-col md:flex-row text-start gap-4 w-full ">

                                <div className="flex flex-col px-[10px] pb-0 gap-1 w-full">
                                    <label htmlFor="payment_option">{strings.member.member_detail.sales_operations.payment_options}</label>
                                    <Dropdown
                                        classnames="md:!max-w-full"
                                        data={installmentList}
                                        selectedData={installment}
                                        setSelectedData={setInstallment}
                                    />
                                </div>
                                <div className="flex flex-col px-[10px] pb-0 gap-1 w-full">
                                    <label htmlFor="payment_option">{strings.member.member_detail.accounting.payment_channel} <span className="text-red">*</span></label>
                                    <Dropdown
                                        classnames="md:!max-w-full"
                                        data={sourceTypeList}
                                        selectedData={sourceType}
                                        setSelectedData={setSourceType}
                                    />
                                </div>
                                <div className="flex flex-col px-[10px] pb-0 gap-1 w-full">
                                    <label htmlFor="bank_message">{strings.member.member_detail.accounting.bank_message}</label>
                                    <Input
                                        id="bank_message"
                                        type="text"
                                        onChange={(e) => setBankMessage(e.target.value)}
                                    />
                                </div>

                            </div>
                            <div className="flex flex-col md:flex-row text-start gap-4 w-full ">
                                <div className="flex flex-col px-[10px] pb-0 gap-1 w-full">
                                    <label htmlFor="user_do_task">{strings.form_info.payment_type}</label>
                                    <Dropdown
                                        classnames="md:!max-w-full"
                                        data={createUserList}
                                        selectedData={createUser}
                                        setSelectedData={setCreateUser}
                                    />
                                </div>
                                <div className="flex flex-col px-[10px] pb-0 gap-1 w-full">
                                    <label className="mb-2 min-w-max" htmlFor="is_partial_payment">{strings.member.member_detail.accounting.partial_payment}</label>
                                    <div className="flex justify-start items-center px-2">
                                        <input
                                            type="checkbox"
                                            id="isActive1"
                                            className="hidden"
                                            onClick={() => setIsPartialPayment(isPartialPayment => !isPartialPayment)}
                                        />
                                        <label
                                            htmlFor="isActive1"
                                            className={`${handleCheckbox(isPartialPayment)} mr-2 w-5 h-5 rounded-[3px] cursor-pointer duration-500 flex items-center justify-center`}
                                        >
                                            <span className={classNames('text-inherit text-[20px] material-symbols-outlined animate-fadeIn font-bold', { 'hidden': !isPartialPayment })}>
                                                done
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div className="flex flex-col px-[10px] pb-0 gap-1 w-full">
                                </div>
                            </div>
                        </>
                    )}

                </div>
                {company.id !== -1 && paymentType.id !== -1 && (
                    <div className="flex gap-3 justify-center relative z-0">
                        <button type="submit" className="group button search-button gap-3 text-white bg-blue border-blue focus:border-blue mt-7 px-10">{strings.button.save_upper}</button>
                        <button type="button" className="button search-button gap-3 text-white bg-red border-red focus:border-red mt-7 px-10" onClick={closeButton}>{strings.button.cancel_upper}</button>
                    </div>
                )}
            </form>
        </>
    )
};

export default AddPaymentModal;