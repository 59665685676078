import { fetchApi } from '../index';

export const MemberCandidateFilter = async (model) => {

    const url = '/member/v4/member/candidate/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

//Öğrenci adayları sayfasındaki 'Adayı Getir' butonu için çalışan servis.
export const GetBestPossibleMemberFromPool = async (employeeId) => {

    const url = '/member/v2/member_pool/get_best_possible_member_from_pool/';

    const payload = {
        employeeId: employeeId
    };

    return await fetchApi(url, payload);
}

export const GetNewMemberForm = async (model) => {

    const url = '/member/v1/member/new_member_form/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}