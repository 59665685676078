import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AzFlag, SaFlag, TrFlag, UsaFlag } from '../assets/img';
import { languages } from '../localizations';
import { changeLanguage } from '../store/LocalizationSlice';
import OutsideClickHandler from 'react-outside-click-handler';
import useAuth from '../hooks/Log/useAuth';

const ChangeLanguage = (props) => {

    const { employee } = useAuth();
    
    const language = useSelector(state => state.localizationStore.language);
    const dispatch = useDispatch();

    const dropDownRef = useRef();
    const dropDownGeneralRef = useRef();

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const allowedLanguages = {
        1: ["tr", "en"],
        2: ["az", "en"],
        3: ["ar", "en"]
    };
    const activeLanguages = allowedLanguages[+employee.BrandId] || [];

    const getFlagByLanguage = (language) => {
        switch(language) {
            case 'tr':
                return TrFlag;
            case 'ar': 
                return SaFlag;
            case 'az':
                return AzFlag;
            case 'en':
                return UsaFlag;
        }
    }

    return (
        <OutsideClickHandler onOutsideClick={() => setIsDropdownOpen(isDropdownOpen => false)}>
            <div ref={dropDownGeneralRef} className="flex flex-col items-end ">
                <button id="dropdown" className="mt-0 max-w-full" onClick={() => setIsDropdownOpen(isDropdownOpen => !isDropdownOpen)} type="button">
                    <div className='flex gap-1 items-center'>
                        <img src={getFlagByLanguage(language)} className="w-[34px] h-[20px] rounded transition-colour duration-300  border-[#f5f5f5] border" />
                        <span className={`material-symbols-outlined transition-transform duration-300 text-white ${isDropdownOpen ? 'rotate-180' : ''}`}>
                            expand_more
                        </span>
                    </div>
                </button>

                {/*<!-- Dropdown menu -->*/}
                <div ref={dropDownRef} className={`w-full mt-[2px] z-10 rounded transition-all min-w-max duration-300 origin-top-right bg-blue shadow-button ${isDropdownOpen ? 'opacity-100 scale-100' : 'opacity-0 scale-0'}`}>
                    <ul className="py-1">
                        {
                            Object.entries(languages).map(([key, value]) => {
                                if (activeLanguages.includes(key)) {
                                    return (
                                        <li key={key}>
                                            <a 
                                                onClick={() => {
                                                    dispatch(changeLanguage(key));
                                                    setIsDropdownOpen(isDropdownOpen => !isDropdownOpen);
                                                }} 
                                                className={`block cursor-pointer py-1 my-1 px-2 mx-[1px] min-w-max text-start hover:bg-white hover:text-base-text text-white`}
                                            >
                                                {value}
                                            </a>
                                        </li>
                                    );
                                }
                                return null; // Eğer dil izin verilenler arasında değilse hiç oluşturulmaz
                            })
                        }
                    </ul>
                </div>

            </div>
        </OutsideClickHandler>
    )
}

export default ChangeLanguage