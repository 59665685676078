import { useEffect, useState } from "react";
import { PageTitle, Pagination, SearchBar, SearchCount, Loading, showTopAlert } from "../../components";
import { stringToDate } from "../../components/Utility";
import useAuth from "../../hooks/Log/useAuth";
import { useLocalization } from "../../hooks/Utils/useLocalization";
import { TaskFilter } from "../../services/Task/TaskService";
import { TaskTable, TaskDetailSearch } from "./components";

const Task = () => {

    const strings = useLocalization();
    const { employee } = useAuth();

    // Pagination için kullanılacak.
    const limit = parseInt(process.env.REACT_APP_TABLE_ROW_LIMIT);
    const [start, setStart] = useState(0);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecord, setTotalRecord] = useState(0);
    // Pagination için kullanılacak.
    const [data, setData] = useState([]);

    const [detailSearch, setDetailSearch] = useState({
        beginDate: null,
        endDate: null,
        brandId: parseInt(employee.BrandId),
        taskUser: null,
        taskSubject: -1,
        status: [],
        searchText: ''
    });

    //Arama verileri değiştiğinde değerleri sıfırlıyoruz
    const resetValue = () => {
        setStart(start => 0);
        setCurrentPage(currentPage => 1);
        setLoading(loading => true);
    };

    //select değişiklikleri bu alandan yönetiliyor
    const changeDetail = (values) => {
        setDetailSearch({ ...detailSearch, ...values })
        resetValue();
    };

    //input değişiklikleri bu alandan yönetiliyor
    const changeValue = (value) => {
        setDetailSearch({ ...detailSearch, searchText: value });
        resetValue();
    };

    const getTaskList = async () => {
        const model = {
            start: start,
            length: limit,
            beginDate: detailSearch.beginDate ? stringToDate(detailSearch.beginDate) : null,
            endDate: detailSearch.endDate ? stringToDate(detailSearch.endDate, 23, 59, 59) : null,
            brandId: detailSearch.brandId,
            taskUser: detailSearch.taskUser === -1 ? null : detailSearch.taskUser,
            taskSubject: detailSearch.taskSubject,
            status: detailSearch.status,
            searchText: detailSearch.searchText,
            employeeId: employee.EmployeeId
        }

        const result = await TaskFilter(model);

        if (result.status === 200) {
            let resultContent = JSON.parse(result.content);

            setData(data => resultContent.data);
            setTotalRecord(totalRecord => resultContent.recordsTotal);
            setLoading(loading => false);
        } else {
            showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
        }
    }

    //sayfa değiştikçe bilgileri yeniden çağırıyoruz
    useEffect(() => {
        if (totalRecord !== 0) {
            getTaskList();
        }
    }, [start])

    useEffect(() => {

        let abortController = new AbortController();

        getTaskList();

        return () => {
            abortController.abort();
        }
    }, [detailSearch])

    return (
        <>
            <TaskDetailSearch changeDetail={changeDetail} loading={loading} />
            <SearchBar changeValue={changeValue} placeHolder={strings.search_bar.search_task} isDisabled={loading} />
            {!loading && <SearchCount count={totalRecord} />}
            <PageTitle title={strings.navbar.tasks.title} />
            {loading ?
                (
                    <Loading />
                )
                :
                (
                    <>
                        <TaskTable data={data} />
                        <Pagination
                            totalCount={totalRecord}
                            limit={limit}
                            start={start}
                            setStart={setStart}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            setLoading={setLoading}
                            loadScreen={true}
                        />
                    </>
                )
            }
        </>
    );
}

export default Task;