import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Dropdown, Loading, PageTitle, showCenteredAlertTwoButtons, showCenteredAlertTwoButtonsWithParameters, showTopAlert } from "../../../../../components";
import { useLocalization } from "../../../../../hooks/Utils/useLocalization";
import usePrimeData from "../../../../../hooks/Utils/usePrimeData";
import { AddTransferStatus, DeleteTransferStatus, GetTransferStatusList } from "../../../../../services/Member/MemberTransferStatusService";

const AddStudentTransfer = () => {
  const strings = useLocalization();
  const primeData = usePrimeData();
  const { memberId } = useParams() || false;

  const firstTransferStatus = { id: -1, name: strings.member.member_detail.others.choose_transfer_status };
  const transferStatusList = [firstTransferStatus, ...primeData.transferStatusList];

  const [transferData, setTransferData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [transfer, setTransfer] = useState(firstTransferStatus);

  const sendToEndpoint = async () => {
    const model = {
      memberId: memberId,
      memberTransferStatusType: transfer.id
    }

    const result = await AddTransferStatus(model);
    if (result.status === 200) {
      showTopAlert(strings.alert.top_alert.success, "success");
      getTransferStatusList(memberId);
    } else {
      showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
    }
  };

  const getTransferStatusList = async (memberId) => {
    const result = await GetTransferStatusList(memberId);
    if (result.status === 200) {
      setTransferData(transferData => JSON.parse(result.content));
      setLoading(loading => false);
    }
  }

  const deleteTransferStatus = async (transferStatusId) => {
    if (transferStatusId) {
      const result = await DeleteTransferStatus(transferStatusId);
      if (result.status === 200) {
        showTopAlert(strings.alert.top_alert.success, "success");
        getTransferStatusList(memberId);
      }
    }
  };


  useEffect(() => {
    if (memberId) {
      getTransferStatusList(memberId);
    }
  }, [])

  return (
    <>
      <PageTitle
        title={strings.member.member_detail.header.content.add_student_transfer_status}
      />

      <div className="mt-5 px-2 sm:px-5 flex w-full flex-col items-center">
        <form className="flex flex-col px-[10px] pb-0 w-full">
          <label htmlFor="member_demand_type">
            {strings.detail_search_titles.transfer_status}
          </label>
          <Dropdown
            classnames="md:max-w-full"
            data={transferStatusList}
            selectedData={transfer}
            setSelectedData={setTransfer}
          />
        </form>
        <button
          onClick={() => {
            transfer.id === -1 ?
              showTopAlert(strings.member.member_detail.others.request_error, "warning")
              :
              showCenteredAlertTwoButtons(
                strings.alert.centered_alert_two_buttons.title,
                strings.alert.centered_alert_two_buttons.save_question,
                strings.button.confirm,
                strings.alert.centered_alert_two_buttons.cancel_text,
                sendToEndpoint
              )
          }
          }
          className="button search-button gap-3 text-white bg-blue border-blue px-10 mt-5 mx-auto"
        >
          {strings.button.add_upper}
        </button>
        <p className="font-bold mt-7">
          {strings.member.member_detail.others.transfer_history}
        </p>
        
        {loading ? (
          <Loading />
        ) : (
          <table className="mt-5">
            <thead>
              <tr>
                <th className="th min-w-max"><p>{strings.table.transfer_status}</p></th>
                <th className="th w-[200px]">{strings.member.member_detail.header.operations}</th>
              </tr>
            </thead>
            <tbody>
              {transferData.length !== 0 ? (
                transferData.map((transfer, index) => (
                  <tr key={index} className="bg-white hover:bg-[#f0f0f0]">
                    <td className="td align-middle">
                      <p className="h-11">{primeData.transferStatusList.find(t => t.id === transfer.memberTransferStatusType).name}</p>
                    </td>
                    <td className="td align-middle">
                      {transfer.status === 1 ?
                        <button
                          onClick={() => showCenteredAlertTwoButtonsWithParameters(
                            strings.alert.centered_alert_two_buttons.title,
                            strings.alert.centered_alert_two_buttons.save_question,
                            strings.button.confirm,
                            strings.alert.centered_alert_two_buttons.cancel_text,
                            deleteTransferStatus,
                            [transfer.id])
                          }
                          className="inline-flex items-center justify-center bg-red text-white w-11 my-2 h-11 rounded-[3px] relative group search-button"
                        >
                          <span className="material-symbols-outlined text-[18px]">
                            delete
                          </span>
                          <div className="tooltip-top">
                            <p>{strings.button.delete}</p>
                          </div>
                        </button> : <p>{strings.form_info.removed}</p>
                      }
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="bg-white hover:bg-[#f0f0f0]">
                  <td className="td" colSpan={4}>
                    <p className="py-3 min-w-full text-center">
                      {strings.member.member_detail.others.no_transfer}
                    </p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
}

export default AddStudentTransfer