import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CopyToClipboard } from "../../../../components";
import { addTimeZoneToFullDate, formatCurrency, getAccountingStatusName, getBrandNameById, getLogoByBrandId, isItSpecified, maskPhoneNumber } from "../../../../components/Utility";
import useAuth from "../../../../hooks/Log/useAuth";
import { useLocalization } from "../../../../hooks/Utils/useLocalization";
import { url } from "../../../../routes/utility";

const PaymentTableRow = (props) => {

  const strings = useLocalization();
  const { payment, setModal, setModalContent } = props;
  const { employee } = useAuth();

  const [paymentText, setPaymentText] = useState("");

  useEffect(() => {
    if (payment.installment === 1 && (payment.paymentType === 'Kredi Kartı' || payment.paymentType === 'Western Union')) {
      let tmpText = strings.member.member_detail.sales_operations.one_shot;
      setPaymentText(paymentText => tmpText);
    } else if ((payment.installment === 0 || payment.installment === 1) && payment.paymentType === 'Havale/EFT') {
      let tmpText = "";
      setPaymentText(paymentText => tmpText);
    } else {
      let tmpText = "- " + payment.installment + " " + strings.member.member_detail.sales_operations.payment_option;
      setPaymentText(paymentText => tmpText);
    }
  }, [])

  return (
    <>
      <tr className="bg-white hover:bg-[#f0f0f0]">
        <td className="td align-middle">
          <div className="max-w-[200px]">
            <div className="flex flex-col items-center">
              <div className="relative group">
                <img src={getLogoByBrandId(payment.brand.id)} width="43" height="48" />
                <div className="tooltip-top">
                  <p>{getBrandNameById(payment.brand.id)}</p>
              </div>
              </div>
            <div className="badge-color bg-blue uppercase mt-1 min-w-[143.85px]">
              <b>{payment.paymentSource}</b>
            </div>
            <div className="badge flex items-center gap-2 mt-1 min-w-[143.85px]">
              <div className={`w-5 h-5 rounded-[3px] flex-shrink-0 ${payment.status === 0 ? 'bg-red' : 'bg-green'}`}></div>
              <p className="uppercase font-bold min-w-max">{payment.paymentType}</p>
              <div className="relative group/inner align-middle flex">
                <span className="material-symbols-outlined text-[20px] cursor-pointer">info</span>
                <div className="hidden group-hover/inner:block absolute duration-300 tooltip-top-content">
                  <p>{isItSpecified(payment.statusMessage, strings.errors.it_is_not_specified)}</p>
                </div>
              </div>
            </div>
              </div>
              {
                payment.isReferencePayment && (
                  <div className='badge mt-1 bg-[#666666] text-white text-center min-w-[143.85px] max-w-max mx-auto'>
                    <p>{strings.member.member_detail.accounting.reference_payment}</p>
                  </div>
                )
              }
          </div>
        </td>
        <td className="td align-top min-w-[200px]">
          <div className="flex items-center mt-[6px]">
            <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
              person
            </span>
            <Link
              to={url("member.detail.profile", { memberId: payment.member.id })}
              target="_blank"
              className="font-bold text-blue hover:underline focus:underline min-w-max"
            >
              {payment.member.fullName}
            </Link>
          </div>
          <hr className="my-2" />
          <div className="flex items-center">
            <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
              call
            </span>
            <div className="flex items-center gap-0.5">
              <p>{maskPhoneNumber(payment.member.phone)}</p>
              <CopyToClipboard text={payment.member.phone} className="-mb-1" iconClassName="!text-[13px]" />
            </div>
          </div>
          <div className="flex items-center">
            <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
              mail
            </span>
            <p>{isItSpecified(payment.member.email, strings.errors.it_is_not_specified)}</p>
          </div>
          <div className="flex items-center">
            <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
              location_on
            </span>
            <p>{payment.member.address}</p>
          </div>
        </td>
        <td className="td align-top min-w-[330px]">
          <div className="flex items-center mt-[6px]">
            <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
              event_note
            </span>
            <p className="font-bold min-w-max">
              {payment.packageName} ({" "}<span className="text-blue">{formatCurrency(payment.totalAmount)}</span> )
            </p>
          </div>
          <hr className="my-2" />
          <div className="flex items-center">
            <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
              schedule
            </span>
            <p>{payment.packageDetail}</p>
          </div>
          {payment.bankName && 
            <div className="flex items-center">
              <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
                account_balance
              </span>
              <p>
                {payment.bankName}
              </p>
            </div>
          }
          <div className="flex items-center">
            <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
              credit_card
            </span>
            <p>
              {payment.paymentType} {paymentText}
            </p>
          </div>
          <div className="flex items-center">
            <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
              calendar_month
            </span>
            <p>{addTimeZoneToFullDate(payment.paymentDate, employee.TimeZone)}</p>
          </div>
          <div className="flex items-center">
            <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
              calculate
            </span>
            <p
              className="relative group font-bold hover:underline text-blue cursor-pointer"
              onClick={() => {
                setModal(true);
                setModalContent({ element: "discounts", orderId: payment.orderId });
              }}
            >
              {strings.table.all_applied_discounts}: <span className='font-bold' > %{payment.totalDiscountRate}</span>
              <span className="tooltip-top !-top-[33px] font-normal">
                {strings.table.click_for_show_all_discounts}
              </span>
            </p>
          </div>
        </td>
        <td className="td align-middle min-w-[200px]">
          <p className={classNames("font-bold", {
            "text-red": payment.accountingStatusType === 0,
            "text-green": payment.accountingStatusType === 1,
            "text-[#666]": payment.accountingStatusType === 2,
          })}>{getAccountingStatusName(payment.accountingStatusType)}</p>
        </td>
      </tr>
    </>
  );
};

export default PaymentTableRow;
