import classNames from "classnames";

const SwitchButton = (props) => {

    const { state, setState, text, loading = false, classnames } = props;

    return (
        <div
            className={classNames("w-full md:w-[246px] flex items-center", {
                [classnames] : [!!classnames],
                "pointer-events-none opacity-70": loading
            })}
        >
            <input
                id="isActive"
                type="checkbox"
                className="hidden peer"
                defaultChecked={state}
                onClick={(e) => setState(e.target.checked)}
            />
            <label
                htmlFor="isActive"
                id="slider"
                className="relative mr-2 w-12 h-6 shrink-0 rounded-full bg-red peer-checked:bg-green cursor-pointer duration-500 before:switch-circle"
            ></label>
            <p className="hidden peer-checked:flex animate-fadeIn">
                {text.open}
            </p>
            <p className="peer-checked:hidden animate-fadeIn">
                {text.close}
            </p>
        </div>
    )
}

export default SwitchButton;