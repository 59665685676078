import React, { useRef, useState, useEffect } from 'react'
import { DetailSearchLayout, Dropdown, DropdownSearch } from '../../../components';
import { DateRanges } from '../../../consts/Constants';
import { useLocalization } from '../../../hooks/Utils/useLocalization';
import { getddMMyyyyDate, getDateWithDayDifference } from '../../../components/Utility';
import DatePicker from 'react-datepicker';
import { getProductivityPerson, getSalesPerson } from '../../Member/components/Common/GetPerson';
import usePrimeData from '../../../hooks/Utils/usePrimeData';
import useAuth from '../../../hooks/Log/useAuth';

const ReminderDetailSearch = ({ changeDetail, loading }) => {

  const strings = useLocalization();
  const primeData = usePrimeData();
  const { employee }  = useAuth();

  //Bu bölüm belirli tarih aralığı seçmek için kullanılan değişkenlerin/fonksiyonların olduğu bölüm
  const datePickerRef = useRef();

  const firstSales = { id: null, name: strings.detail_search_titles.sales };
  const firstProductivity = { id: null, name: strings.detail_search_titles.all_productivity };

  const firstBrand = { id: -1, name: strings.detail_search_titles.brands };
  const brandList = [firstBrand, ...primeData.brandList];

  const firstReminderReason = { id: -1, name: strings.detail_search_titles.reminder_reasons };
  const reminderReasonList = [firstReminderReason, ...primeData.reminderReasonList];

  const [beginDate, setBeginDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [brand, setBrand] = useState(firstBrand);
  const [sales, setSales] = useState(firstSales);
  const [salesList, setSalesList] = useState([]);
  const [productivity, setProductivity] = useState(firstProductivity);
  const [productivityList, setProductivityList] = useState([]);
  const [reminderReason, setReminderReason] = useState(firstReminderReason);

  //Bu bölüm belirli tarih aralığı seçmek için kullanılan değişkenlerin/fonksiyonların olduğu bölüm

  const firstDateRange = { id: -1, name: strings.detail_search_titles.date_range };
  const dateRangeList = [firstDateRange, ...DateRanges];

  const [dateRange, setDateRange] = useState(firstDateRange);

  const firstVisit = useRef(true);
  const handleDateRange = (dates) => {
    const [start, end] = dates;
    setBeginDate(start);
    setEndDate(end);
    if (start !== null && end !== null) {
      let tmpDateRange = { id: 8, name: getddMMyyyyDate(start) + " - " + getddMMyyyyDate(end) };
      dateRangeList.push(tmpDateRange);
      changeDetail?.({ beginDate: getddMMyyyyDate(start), endDate: getddMMyyyyDate(end) });
      setBeginDate(null);
      setEndDate(null);
      setDateRange(dateRange => dateRangeList[8]);
      datePickerRef.current.classList.add("hidden");
    }
  };

  useEffect(() => {
    //Sayfa ilk kez yüklenmiyorsa işlemi yap
    //Servise birden çok gez gitmesini engelliyoruz
    if (!firstVisit.current) {
      let todayDate = new Date();
      let tmpBeginDate = '';
      let tmpEndDate = '';

      datePickerRef.current.classList.add("hidden");

      switch (dateRange.id) {
        case 1:
          //Bugün seçildiyse 
          tmpBeginDate = getddMMyyyyDate(todayDate);
          tmpEndDate = tmpBeginDate;
          break;
        case 2:
          //Dün seçildiyse 
          tmpBeginDate = getddMMyyyyDate(getDateWithDayDifference(todayDate, 1));
          tmpEndDate = tmpBeginDate;
          break;
        case 3:
          //Son 7 gün seçildiyse 
          tmpEndDate = getddMMyyyyDate(todayDate);
          tmpBeginDate = getddMMyyyyDate(getDateWithDayDifference(todayDate, 6));
          break;
        case 4:
          //Son 30 gün seçildiyse 
          tmpEndDate = getddMMyyyyDate(todayDate);
          tmpBeginDate = getddMMyyyyDate(getDateWithDayDifference(todayDate, 30));
          break;
        case 5:
          //Bu ay seçildiyse 
          tmpBeginDate = getddMMyyyyDate(new Date(todayDate.getFullYear(), todayDate.getMonth(), 1));
          tmpEndDate = getddMMyyyyDate(new Date(todayDate.getFullYear(), todayDate.getMonth() + 1, 0));
          break;
        case 6:
          //Geçen ay seçildiyse 
          tmpBeginDate = getddMMyyyyDate(new Date(todayDate.getFullYear(), todayDate.getMonth() - 1, 1));
          tmpEndDate = getddMMyyyyDate(new Date(todayDate.getFullYear(), todayDate.getMonth(), 0));
          break;
        case 7:
          //Tarih aralığı seçildiyse 
          datePickerRef.current.classList.remove('hidden');
          break;
      }

      (dateRange.id < 7) && changeDetail?.({ beginDate: tmpBeginDate, endDate: tmpEndDate });

      firstVisit.current = false;
    }
  }, [dateRange])

  useEffect(() => {
    //Sayfa ilk kez yüklenmiyorsa işlemi yap
    //Servise birden çok gez gitmesini engelliyoruz
    if (!firstVisit.current) {
      //Marka değişince seçili satış ve verimlilik personelini sıfırlıyoruz
      setSales(sales => firstSales);
      setProductivity(productivity => firstProductivity);

      //Marka değiştiği için yeni marka ile satış bve verimlilik personeli servisine istek atıyoruz
      getSalesPerson(brand.id, firstSales, setSalesList);
      getProductivityPerson(brand.id, firstProductivity, setProductivityList);

      changeDetail?.({ brandId: brand?.id });
      firstVisit.current = false;
    }
  }, [brand])

  useEffect(() => {
    //Sayfa ilk kez yüklenmiyorsa işlemi yap
    //Servise birden çok gez gitmesini engelliyoruz
    if (!firstVisit.current) {
      changeDetail?.({ salesPersonId: sales.id });
      firstVisit.current = false;
    }
  }, [sales])

  useEffect(() => {
    //Sayfa ilk kez yüklenmiyorsa işlemi yap
    //Servise birden çok gez gitmesini engelliyoruz
    if (!firstVisit.current) {
      changeDetail?.({ productivityPersonId: productivity.id });
      firstVisit.current = false;
    }
  }, [productivity])

  useEffect(() => {
    //Sayfa ilk kez yüklenmiyorsa işlemi yap
    //Servise birden çok gez gitmesini engelliyoruz
    if (!firstVisit.current) {
      changeDetail?.({ reminderReasonId: reminderReason?.id });
      firstVisit.current = false;
    }
  }, [reminderReason])

  useEffect(() => {
    if(brandList.length > 1) {
      const selectedBrand = brandList.find(brand => brand.id === parseInt(employee.BrandId))
      setBrand(brand => selectedBrand)
    }
}, [primeData.brandList])

  useEffect(() => {
    //Sayfa yüklendikten sonra false yaparak her değişiklikte
    //servisin ziyaret edilmesini sağlıyoruz
    firstVisit.current = false;

    if (firstVisit.current) {
      getSalesPerson(brand.id, firstSales, setSalesList);
      getProductivityPerson(brand.id, firstProductivity, setProductivityList);
      firstVisit.current = false;
    }
  }, [])

  return (
    <>
      <DetailSearchLayout
        isDisabled={loading}
        children={
          <div className="!flex gap-x-4 gap-y-5 max-md-lg:justify-center flex-wrap relative">

            <Dropdown
              title={strings.detail_search_titles.brands}
              data={brandList}
              selectedData={brand}
              setSelectedData={setBrand}
              isDisabled={loading}
            />
            <Dropdown
              data={dateRangeList}
              selectedData={dateRange}
              setSelectedData={setDateRange}
              isDisabled={loading}
            />
            <div ref={datePickerRef} className='absolute top-[75px] z-10 hidden'>
              <button className='absolute button search-button !h-[25px] !p-2 top-[-28px] bg-red text-white focus:!border-none right-0'
                onClick={() => datePickerRef.current.classList.add("hidden")}>X</button>
              <DatePicker
                selected={beginDate}
                onChange={handleDateRange}
                onClickOutside={() => datePickerRef.current.classList.add("hidden")}
                startDate={beginDate}
                endDate={endDate}
                selectsRange
                inline
              />
            </div>
            <DropdownSearch
              title={strings.detail_search_titles.sales}
              inputPlaceholder={strings.detail_search_titles.search_sales}
              data={salesList}
              selectedData={sales}
              setSelectedData={setSales}
              isDisabled={brand.id === -1 ? true : loading}
              tooltip={brand.id === -1 && strings.detail_search_titles.first_choose_brand}
            />
            <DropdownSearch
              title={strings.detail_search_titles.all_productivity}
              inputPlaceholder={strings.detail_search_titles.search_productivity}
              data={productivityList}
              selectedData={productivity}
              setSelectedData={setProductivity}
              isDisabled={brand.id === -1 ? true : loading}
              tooltip={brand.id === -1 && strings.detail_search_titles.first_choose_brand}
            />
            <DropdownSearch
              inputPlaceholder={strings.member.candidate.search_reminder_reason}
              data={reminderReasonList}
              selectedData={reminderReason}
              setSelectedData={setReminderReason}
              isDisabled={loading}
            />
          </div>
        }
      />
    </>
  )
}

export default ReminderDetailSearch