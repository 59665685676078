import {useEffect, useRef, useState} from "react"
import {Loading, showTopAlert} from "../../components"
import NotAuthorized from "../../components/NotAuthorized"
import {dateToShortDateStringWithoutTimezone, getddMMyyyyDate} from "../../components/Utility"
import useAuth from "../../hooks/Log/useAuth"
import {useLocalization} from "../../hooks/Utils/useLocalization"
import {GetEmployeeSalesReport} from "../../services/Report/ReportService"
import {SalesmanReportDetailSearch, SalesmanReportTable} from "./components"

const SalesmanReport = () => {

    const strings = useLocalization();
    const firstVisit = useRef(true);

    const [loading, setLoading] = useState(true);
    const [selectedDate, setSelectedDate] = useState(null);
    const [employeeReports, setEmployeeReports] = useState([])

    //Sayfa yetkileri için bu bölüm kullanılıyor
    const { employeeTypeId, checkPermission, getPermission, employee } = useAuth();
    const roles = getPermission();
    const permission = checkPermission(roles, employeeTypeId);
    //Sayfa yetkileri için bu bölüm kullanılıyor

    const [detailSearch, setDetailSearch] = useState({
        brandId: -1,
        selectedDate: null,
    })

    const getEmployeeSalesReport = async () => {
        setLoading(loading => true);

        let model = {
            brandId: detailSearch.brandId,
            startDate: dateToShortDateStringWithoutTimezone(selectedDate, 0, 0, 0),
            endDate: dateToShortDateStringWithoutTimezone(selectedDate, 23, 59, 59)
        }

        const result = await GetEmployeeSalesReport(model);

        if (result.status === 200) {
            let resultContent = JSON.parse(result.content);
            setEmployeeReports(employeeReports => [...resultContent.activeEmployeeReports, ...resultContent.passiveEmployeeReports])
        } else {
            showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
        }
        setLoading(loading => false);
    }

    //select değişiklikleri bu alandan yönetiliyor
    const changeDetail = (values) => {
        setDetailSearch({ ...detailSearch, ...values });
    }

    useEffect(() => {
        firstVisit.current = false;
    }, [])

    useEffect(() => {
        if(detailSearch.selectedDate) {
            getEmployeeSalesReport();
        }
    }, [employee.BrandId, detailSearch])

    return (
        <>
            <SalesmanReportDetailSearch
                changeDetail={changeDetail}
                loading={loading}
                employee={employee}
                employeeTypeId={employeeTypeId}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                permission={permission}
            />
            <div className="mt-5">
                <p className="my-2 text-[14px] h-[35px] leading-[35px] text-base-text bg-card px-5 rounded-md flex flex-row justify-between">
                    <span className='font-bold'>{strings.sidebar.report.salesman}</span>
                    {selectedDate && permission && (
                        <span><span className="font-bold">{strings.table.date}:</span> {getddMMyyyyDate(selectedDate)}</span>
                    )}
                </p>
            </div>
            {permission ? (
                loading ?
                    (
                        <Loading />
                    )
                    :
                    (
                        <>
                            <SalesmanReportTable
                                employeeReports={employeeReports}
                                brandId={detailSearch.brandId}
                                selectedDate={selectedDate}
                            />
                        </>
                    )
            ) : (<NotAuthorized />)}
        </>
    )
}

export default SalesmanReport