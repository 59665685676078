import React, { useEffect, useState } from 'react'
import { Dropdown, Input, showTopAlert, LargeButton, showCenteredHtmlAlert } from '../../../../../../components';
import { CardType, PaymentOptions, PaymentTypeName } from '../../../../../../consts/Constants';
import { useLocalization } from '../../../../../../hooks/Utils/useLocalization';
import { GetCurrentCardById, GetCurrentCardSelectListByMemberId } from '../../../../../../services/Accounting/CurrentCardService';
import { useSelector } from 'react-redux';
import usePrimeData from '../../../../../../hooks/Utils/usePrimeData';
import { GetBankListById } from '../../../../../../services/Payment/PaymentService';
import { formatCurrency, formatPriceWithDot, roundPrice } from '../../../../../../components/Utility';
import { CreateInvoice } from '../../../../../../services/Member/MemberInvoiceService';
import { useNavigate } from 'react-router-dom';
import { url } from '../../../../../../routes/utility';
import useMemberBrand from '../../../../../../hooks/Member/useMemberBrand';
import classNames from 'classnames';

const AddInvoiceModal = (props) => {

  const { memberId, closeButton, classes, orderId } = props;

  const strings = useLocalization();
  const primeData = usePrimeData();
  const memberBrandId = useMemberBrand(memberId);
  const navigate = useNavigate();
  const language = useSelector(state => state.localizationStore.language);

  const [data, setData] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false)

  const firstCurrentCard = { id: -1, name: strings.member.member_detail.accounting.choose_current_card };
  const firstCompany = { id: -1, name: strings.member.member_detail.sales_operations.choose_company };

  const [company, setCompany] = useState(firstCompany);
  const companyList = [firstCompany, ...primeData.localCompanyList];

  const [currentCard, setCurrentCard] = useState(firstCurrentCard);
  const [currentCardList, setCurrentCardList] = useState([]);
  const [selectedCurrentCard, setSelectedCurrentCard] = useState({});
  const [invoiceDescription, setInvoiceDescription] = useState(null);

  //Eski cari kartları varsa onları listeliyoruz
  const getCurrentCardSelectListByMemberId = async (memberId) => {
    const result = await GetCurrentCardSelectListByMemberId(memberId);

    let arrayList = [];

    if (result.status === 200) {
      const data = JSON.parse(result.content);
      data.map((item) => {
        let arrayItem = { id: item.value, name: item.text };
        arrayList.push(arrayItem);
      })
    }

    setCurrentCardList([firstCurrentCard, ...arrayList]);
  }

  //Cari karta ait bilgileri getiriyoruz
  //Id ye göre düzenlenmek istenen cari kartın bilgilerini alıyoruz
  const getCurrentCardById = async (currentCardId) => {
    const result = await GetCurrentCardById(currentCardId);
    if (result.status === 200) {
      setSelectedCurrentCard(JSON.parse(result.content));
    }
  }

  //Seçilen şirket ve ödeme tütüne göre bankaları getiriyoruz
  const getBankListById = async (paymentTypeId, index) => {
    const model = {
      bankLocalCompanyId: company.id,
      paymentType: paymentTypeId
    }

    const result = await GetBankListById(model);
    let arrayList = [];

    if (result.status === 200) {

      const data = JSON.parse(result.content);
      data.map((item) => {
        let arrayItem = { id: parseInt(item.id), name: item.name };
        arrayList.push(arrayItem);
      })
    }

    const tempData = [...data];
    tempData[index].bankList = arrayList;
    setData([...tempData]);
  }

  //Seçilen bankaya göre, banka adını data'ya ekliyoruz.
  const setBankName = (index, name) => {
    const tempData = [...data];
    tempData[index].bankName = name;
    setData([...tempData]);
  }

  //Seçilen ödeme türüne göre, banka adını data'ya ekliyoruz.
  const setPaymentTypeName = (index, name) => {
    const tempData = [...data];
    tempData[index].paymentTypeName = name;
    setData([...tempData]);
  }

  //Satırlardaki input'lar bu fonksiyondan kontrol edilir.
  //Input name ve value'ya göre işlem yapılır.
  const handleInputChange = (event, index, valueType = "") => {
    const { name, value } = event.target;
    const tempData = [...data];

    let tempVal = value;
    let bankName = "";
    let paymentTypeName = "";

    //Eğer select 'paymentType' ise payment type için işlem yapılır.
    //Payment Type'a göre banka listesi getirilir.
    if (valueType === "paymentType") {
      //Dijital krediden sonra banka seçmemize gerek yok. O sebeple 3'den küçük ID ler için servise gitmiyoruz
      if (tempVal !== "-1" && parseInt(value) < 3) {
        getBankListById(parseInt(value), index);
        tempVal = parseInt(value);
      }

      //Seçilen ödeme türüne göre 'ödeme türü adı' dataya eklenir.
      let idx = event.nativeEvent.target.selectedIndex;
      paymentTypeName = event.nativeEvent.target[idx].text;
      setPaymentTypeName(index, paymentTypeName);
    }

    //Eğer select 'bank' ise banka için işlem yapılır.
    if (valueType === "bank") {
      if (value !== "-1") {
        tempVal = parseInt(value);

        //Seçilen bankaya göre 'banka adı' dataya eklenir.
        let idx = event.nativeEvent.target.selectedIndex;
        bankName = event.nativeEvent.target[idx].text;
        setBankName(index, bankName);
      }
    }

    //İşlem yapılan veriler 'data' array'ine eklenir.
    tempData[index][name] = tempVal;
    setData([...tempData]);
  };

  //'Yeni Satır Ekle' butonu için çalışır.
  //Her yeni satır eklendiğinde, her satır için data arrayinde bir obje oluşturulur.
  const addRow = () => {
    setData([...data, { description: "Bireysel Eğitim Paketi", amount: '1', unitPrice: '0.00', taxRate: selectedCurrentCard.isAbroad ? '0' : '10', bank: "-1", bankName: "", bankList: [], paymentType: "-1", paymentTypeName: "", paymentOption: 1 }]);
  };

  //Satırları silen fonksiyon.
  const deleteRow = (index) => {
    const tempData = [...data];
    tempData.splice(index, 1);
    setData([...tempData]);
    setInvoiceDescription(invoiceDescription => "");
  };

  //Birim Fiyat ve KDV Oranına göre KDV tutarını hesaplar.
  const calculateTax = (amount, unitPrice, tax) => {
    return (amount * unitPrice) * (tax === 0 ? 0 : tax) / 100
  }

  //'Fatura Açıklaması Ekle' butonu için çalışır.
  //Eklenen tüm satırlar için otomatikmen açıklama ekler.
  //Banka Adı - Ödeme Türü - Taksit Sayısı alanlarını kontrol eder.
  const addInvoiceDescription = () => {

    let invoiceDescription = '';
    let dash = '';

    data.map((item, index) => {

      if (data.length - 1 !== index) {
        dash = ' - ';
      } else {
        dash = '';
      }

      if (item.paymentType === 0) {
        invoiceDescription += item.bankName + ' ' + item.paymentTypeName + ' ile ' + item.paymentOption + ' Taksit.' + dash;
      } else {
        invoiceDescription += item.bankName + ' ' + item.paymentTypeName + ' ile ödendi.' + dash;
      }
    })

    setInvoiceDescription(invoiceDescription);
  }

  //Eklenen tüm satırlar için kdv'siz tutarı hesaplar.
  const calculatePrice = () => {
    if (data.length > 0) {
      let price = 0.00;

      data.map(item => {
        price += parseFloat(item.unitPrice * item.amount);
      });

      return (<span>{formatCurrency(price)}</span>);
    }
  };

  //Eklenen tüm satırlar için kdv'li tutarı hesaplar.
  const calculateTaxPrice = () => {
    if (data.length > 0) {
      let taxPrice = 0.00;

      memberBrandId === 1 && (
        data.map(item => {
          taxPrice += parseFloat((item.unitPrice * item.amount * item.taxRate) / 100);
        })
      );

      return (<span>{formatCurrency(taxPrice)}</span>);
    }
  };

  //Eklenen tüm satırlar için kdv'li ve kdv'siz tutarın toplamını hesaplar.
  const calculateTotalPrice = () => {
    if (data.length > 0) {
      let price = 0.00;
      let taxPrice = 0.00;

      data.map(item => {
        price += parseFloat(item.unitPrice * item.amount);
        if (memberBrandId === 1) (
          taxPrice += parseFloat((item.unitPrice * item.amount * item.taxRate) / 100)
        );
      });

      return (<span>{formatCurrency(price + taxPrice)}</span>);
    }
  };

  //Servise istek atan fonksiyon
  const submitHandle = async e => {
    e.preventDefault();

    let valid = true;
    let errorList = '';

    if (currentCard.id === -1) {
      valid = false;
      errorList += strings.errors.current_card_null_error + '<br />';
    }
    if (company.id === -1) {
      valid = false;
      errorList += strings.errors.company_null_error + '<br />';
    }
    if (invoiceDescription === null) {
      valid = false;
      errorList += strings.errors.invoice_description_null_error + '<br />';
    }
    if (data.length === 0) {
      valid = false;
      errorList += strings.errors.add_invoice_row_error + '<br />';
    }

    if (valid) {
      let invoiceDetail = [];
      setButtonLoading(buttonLoading => true);

      data.length > 0 && data.map((item) => {
        let arrayItem = {
          quantity: parseInt(item.amount),
          unitPrice: parseFloat(item.unitPrice),
          taxValue: memberBrandId === 1 ? parseInt(item.taxRate) : 0,
          bankId: parseInt(item.bank),
          installment: parseInt(item.paymentOption),
          description: item.description,
          paymentType: parseInt(item.paymentType)
        }

        invoiceDetail.push(arrayItem);
      })

      const model = {
        orderId: orderId,
        accountingCurrentCardId: selectedCurrentCard.id,
        description: invoiceDescription,
        brandLocalCompanyId: company.id,
        invoiceDetails: invoiceDetail
      }

      const result = await CreateInvoice(model);

      if (result.status === 200) {
        showTopAlert(strings.member.member_detail.accounting.add_invoice_success_message, "success");
        setTimeout(() => navigate(url("member.detail.invoices", { memberId: memberId })), 1000);
      } else {
        showTopAlert(strings.errors.an_error_occurred, "error");
      }
      setButtonLoading(buttonLoading => false);
    } else {
      showCenteredHtmlAlert(strings.errors.an_error_occurred, errorList, "error");
    }
  }

  //Herhangi bir cari kart seçildiyse ve id -1 den farklı ise cari kart bilgelerini getiriyoruz
  useEffect(() => {
    //Şirket değiştiğinde ödeme tipleri değiştiğinden data nın sıfırlanması gerekiyor.
    setData([]);
  }, [company])

  //Herhangi bir cari kart seçildiyse ve id -1 den farklı ise cari kart bilgelerini getiriyoruz
  useEffect(() => {
    if (currentCard.id !== -1) {
      getCurrentCardById(currentCard.id);
    } else {
      setSelectedCurrentCard({});
      setData([]);
    }
  }, [currentCard])

  //Açılışta cari kart listelerini alıyoruz
  useEffect(() => {
    if (memberId) {
      getCurrentCardSelectListByMemberId(memberId);
    }
  }, [])

  return (
    <>
      <div className="flex justify-center">
        <p className="font-bold text-[15px]">{strings.member.member_detail.accounting.add_invoice}</p>
      </div>
      <form onSubmit={submitHandle} className='overflow-y-scroll max-h-[80vh] pr-2'>
        <div className={`flex flex-col gap-4 mt-5 ${classes}`}>
          <div className="flex flex-col px-[10px] pb-0 w-full">
            <div className='flex gap-1'>
              <label className='mb-2'>{strings.member.member_detail.header.content.current_card}</label> <span className="text-red">*</span>
            </div>
            <Dropdown
              classnames="md:max-w-full"
              data={currentCardList}
              selectedData={currentCard}
              setSelectedData={setCurrentCard}
            />
          </div>
          <div className="w-[calc(100%-20px)] md:max-w-[1060px] border rounded-lg ml-[10px] p-2">
            {Object.keys(selectedCurrentCard).length === 0 ? (
              <p>{strings.member.member_detail.accounting.current_card_area}</p>
            )
              :
              (
                <>
                  <p><span className='font-bold'>{strings.member.member_detail.accounting.current_card_type}:</span> {selectedCurrentCard.cardType === CardType.Person ? strings.member.member_detail.accounting.person : strings.member.member_detail.accounting.company}</p>
                  <p><span className='font-bold'>{strings.form_info.full_name}/{strings.form_info.company_title}:</span> {selectedCurrentCard.name}</p>
                  <p><span className='font-bold'>{strings.form_info.tax_office}:</span> {selectedCurrentCard.taxOffice}</p>
                  <p><span className='font-bold'>{strings.form_info.tax_number}:</span> {selectedCurrentCard.taxNumber}</p>
                  <p><span className='font-bold'>{strings.form_info.email}:</span> {selectedCurrentCard.email}</p>
                  <p><span className='font-bold'>{strings.table.address}:</span> {selectedCurrentCard.address}</p>
                  {selectedCurrentCard.isAbroad && <p><span className='text-red font-bold'>{strings.member.member_detail.accounting.is_abroad}</span></p>}
                </>
              )
            }
          </div>

          <div className="flex flex-col px-[10px] pb-0 w-full">
            <div className='flex gap-1'>
              <label className='mb-2'>{strings.form_info.company}</label><span className="text-red">*</span>
            </div>
            <Dropdown
              classnames="md:!max-w-full"
              data={companyList}
              selectedData={company}
              setSelectedData={setCompany}
            />
          </div>
          <LargeButton
            iconName="add_circle"
            iconClassnames="bg-blue border-blue"
            iconText={strings.member.member_detail.accounting.add_new_row}
            action={() => currentCard.id !== -1 && addRow()}
            isDisabled={company.id === -1 || currentCard.id === -1}
          />
          <div className="overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
            <div className="inline-block min-w-full rounded-lg overflow-visible">
              <table className="min-w-full leading-normal text-header">
                <thead>
                  <tr>
                    <th className="th !px-1">
                      <p>{strings.form_info.description}</p>
                    </th>
                    <th className="th !px-1">
                      <p>{strings.table.amount}</p>
                    </th>
                    <th className="th !px-1">
                      <div className='flex gap-1 mt-1'>
                        <p>{strings.table.unit_price}</p>
                        <div className="relative group">
                          <span className="material-symbols-outlined cursor-pointer text-[18px]">info</span>
                          <div className="tooltip -bottom-[50px]">
                            <p className='font-normal'>{strings.member.member_detail.accounting.amount_note}</p>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th className="th !px-1">
                      <p>{strings.form_info.kdv_rate}</p>
                    </th>
                    <th className="th !px-1">
                      <p>{strings.form_info.kdv_amount}</p>
                    </th>
                    <th className="th !px-1">
                      <p>{strings.form_info.payment_type}</p>
                    </th>
                    <th className="th !px-1">
                      <p>{strings.form_info.bank}</p>
                    </th>
                    <th className="th !px-1">
                      <p>{strings.member.member_detail.sales_operations.payment_option}</p>
                    </th>
                    <th className="th !px-1">
                      <p>{strings.button.delete}</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 ? (
                    data.map((item, index) => (
                      <tr key={index} className='bg-white hover:bg-[#f0f0f0]'>
                        <td className='td align-middle !px-1'>
                          <Input
                            type="text"
                            name="description"
                            classnames="w-max"
                            value={item.description}
                            onChange={(e) => handleInputChange(e, index)}
                          />
                        </td>
                        <td className='td align-middle !px-1'>
                          <Input
                            type="text"
                            name="amount"
                            value={item.amount}
                            onChange={(e) => handleInputChange(e, index, "number")}
                          />
                        </td>
                        <td className='td align-middle !px-1'>
                          <Input
                            type="text"
                            name="unitPrice"
                            value={item.unitPrice}
                            onChange={(e) => handleInputChange(e, index)}
                          />
                        </td>
                        <td className='td align-middle !px-1'>
                          <Input
                            type="text"
                            name="taxRate"
                            isReadOnly={memberBrandId !== 1}
                            value={memberBrandId === 1 ? item.taxRate : 0}
                            onChange={(e) => handleInputChange(e, index, "number")}
                          />
                        </td>
                        <td className='td align-middle !px-1'>
                          <Input
                            classnames="placeholder:text-base-text"
                            type="text"
                            name="taxAmount"
                            isReadOnly={true}
                            placeholder={item.unitPrice > 0 ? memberBrandId === 1 ? formatPriceWithDot(roundPrice(calculateTax(parseInt(item.amount), parseFloat(item.unitPrice), parseInt(item.taxRate))), "₺") : 0 : ""}
                          />
                        </td>
                        <td className='td align-middle !px-1'>
                          <select name="paymentType" className='form-input' value={item.paymentType} onChange={(e) => handleInputChange(e, index, "paymentType")}>
                            <option value="-1">{strings.member.member_detail.accounting.choose_payment_type}</option>
                            {PaymentTypeName.map((type, index) => (
                              <option key={index} value={type.id}>{language === "tr" ? type.tr : type.name}</option>
                            ))}
                          </select>
                        </td>
                        <td className='td align-middle !px-1'>
                          <select name="bank" className='form-input' value={item.bank} onChange={(e) => handleInputChange(e, index, "bank")}>
                            <option value="-1">{strings.member.member_detail.sales_operations.choose_bank}</option>
                            {item.bankList.length > 0 && item.bankList.map((bank, index) => (
                              <option key={index} value={bank.id}>{bank.name}</option>
                            ))}
                          </select>
                        </td>
                        <td className='td align-middle !px-1'>
                          <select name="paymentOption" className='form-input' value={item.paymentOption} onChange={(e) => handleInputChange(e, index, "number")}>
                            {PaymentOptions.map((type, index) => (
                              <option key={index} value={type.id}>{type.name}</option>
                            ))}
                          </select>
                        </td>
                        <td className='td align-middle !px-1'>
                          <div>
                            <button
                              type='button'
                              onClick={() => deleteRow(index)}
                              className="mt-1"
                            >
                              <span className='material-symbols-outlined text-blue text-[18px]'>
                                cancel
                              </span>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  )
                    :
                    (
                      <tr className='bg-white hover:bg-[#f0f0f0]'>
                        <td colSpan={10} className="py-3 text-center">{strings.member.member_detail.accounting.press_button_for_add_row}</td>
                      </tr>
                    )
                  }
                </tbody>
              </table>
            </div>
          </div>
          <div className="flex flex-col px-[10px] pb-0 w-full">
            <div className='flex gap-1'>
              <label className='mb-2' htmlFor="invoice_description">{strings.member.member_detail.accounting.invoice_description}</label><span className="text-red">*</span>
            </div>
            <textarea
              id="invoice_description"
              className="border rounded-lg outline-0 focus:border-blue py-3 px-2 w-full"
              type="text"
              rows="2"
              defaultValue={invoiceDescription}
              onChange={(e) => setInvoiceDescription(e.target.value)}
            >
            </textarea>
            <LargeButton
              iconName="add_circle"
              iconClassnames="bg-blue border-blue mt-4"
              iconText={strings.member.member_detail.accounting.add_invoice_description}
              isDisabled={data.length === 0}
              action={addInvoiceDescription}
            />
          </div>
          <div className="w-[calc(100%-20px)] md:max-w-[1400px] border rounded ml-[10px] p-2 flex gap-2 mt-2">
            <p><strong>{strings.form_info.price}:</strong> {calculatePrice()}</p>
            <p><strong>{strings.form_info.kdv_amount}:</strong> {calculateTaxPrice()}</p>
            <p><strong>{strings.table.total_price}:</strong> {calculateTotalPrice()}</p>

          </div>
        </div>
        <div className="flex gap-3 justify-center mt-4">
          <button
            disabled={buttonLoading}
            className={classNames("group button search-button gap-3 text-white bg-blue border-blue focus:border-blue px-10", {
              "opacity-70 hover:opacity-70 cursor-not-allowed": buttonLoading
            })}
          >
            {buttonLoading && (
              <div className="inline-block h-4 w-4 animate-spin rounded-full border-[3px] border-solid border-current border-r-transparent align-[-0.25em] motion-reduce:animate-[spin_1.5s_linear_infinite]"></div>
            )}
            {!buttonLoading && (
              strings.button.save_upper
            )}
          </button>
          <button className="button search-button gap-3 text-white bg-red border-red focus:border-red px-10" onClick={closeButton}>
            {strings.button.cancel_upper}
          </button>
        </div>
      </form>
    </>
  )
};

export default AddInvoiceModal;