import logo_beyaz from './logo/clickivo-prime-logo-yatay-beyaz.svg';
import ko_logo from './logo/ko-logo.svg';
import azer_logo from './logo/azeringilisce-logo.svg';
import alingliziah_logo from './logo/alingliziah-logo.svg';
import default_image from "./default_image.svg";

import error_404 from './error_404.svg';

import zoom_in from './zoom-in.svg'

import whatsapp from './whatsapp.svg'

export const LogoBeyaz = logo_beyaz;
export const KoLogo = ko_logo;
export const AzerLogo = azer_logo;
export const AlingliziahLogo = alingliziah_logo;

export const Error404 = error_404;

export const ZoomIn = zoom_in;

export const DefaultImage = default_image;

export const Whatsapp = whatsapp