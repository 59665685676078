import { useRef, useState, useEffect } from "react";
import { useLocalization } from "../hooks/Utils/useLocalization";
import classNames from "classnames";

const DetailSearchLayout = ({ children, isDisabled = false, open = false }) => {

  const strings = useLocalization();
  const childrenRef = useRef();
  const firstVisit = useRef(true);

  const [isOpen, setIsOpen] = useState(open);

  const handleTransitionEnd = () => {
    if (!isOpen) {
      childrenRef.current.classList.add('hidden');
    }
  };

  useEffect(() => {
    if (isOpen) {
      childrenRef.current.classList.remove('hidden');
    }
  }, [isOpen]);
  
  return (
    <div className="relative z-10">
      <div
        onClick={() => { 
          setIsOpen(!isOpen);
          firstVisit.current = false;
        }}
        className={classNames(
          "px-5 py-4 cursor-pointer border border-[#ebebeb] text-dark-text bg-card hover:bg-light-gray focus:bg-light-gray transition-colors rounded-t-md flex justify-between items-center", {
          "pointer-events-none opacity-70" : isDisabled,
          "rounded-b-md" : !isOpen
        })}
      >
        <div className="flex items-center">
          <span className="material-symbols-outlined text-[28px] pr-4 cursor-pointer">
            tune
          </span>
          <p>{strings.detail_search_titles.detail_search}</p>
        </div>
        {!isOpen ? (
          <span
            onClick={() => setIsOpen(true)}
            className="material-symbols-outlined font-bold text-base cursor-pointer"
          >
            add
          </span>
          )
          :
          (
            <span
              onClick={() => setIsOpen(false)}
              className="material-symbols-outlined font-bold text-base cursor-pointer"
            >
              remove
            </span>
          )
        }
      </div>

      {/* <!-- Detaylı Arama Content --> */}
      <div 
        className={`${isOpen ? 'max-h-screen py-8 border border-t-0' : 'max-h-0'} px-4 border-[#ebebeb] rounded-b-md transition-max-height duration-500`}
        onTransitionEnd={handleTransitionEnd}
      >
        <div ref={childrenRef} className={`${!isOpen && 'animate-fadeOut'} ${firstVisit.current ? 'hidden' : ''}`}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default DetailSearchLayout;
