import classNames from "classnames";
import { getTimezonesForCountry } from "countries-and-timezones";
import tr from 'date-fns/locale/tr';
import { useEffect, useRef, useState } from "react";
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReactIntlTelInput from 'react-intl-tel-input-v2';
import {useNavigate, useParams} from "react-router-dom";
import '../../assets/css/intl-tel-input.css';
import { DefaultProfile } from "../../assets/img";
import {
  Dropdown,
  DropdownSearch,
  Input,
  Loading,
  LoadingSpinner,
  PageTitle,
  showCenteredHtmlAlert,
  showTopAlert
} from "../../components";
import NotAuthorized from "../../components/NotAuthorized";
import {deleteSpacesInThePhoneNumber, handleCheckbox, validateEmail} from "../../components/Utility";
import useAnalytics from "../../hooks/Log/useAnalytics";
import useAuth from "../../hooks/Log/useAuth";
import { useLocalization } from "../../hooks/Utils/useLocalization";
import usePrimeData from "../../hooks/Utils/usePrimeData";
import { url } from "../../routes/utility";
import { EmployeeDetails, EmployeeEdit } from "../../services/Employee/EmployeeService";
import {ServiceErrorMessages} from "../../consts/Constants";

const EditEmployee = () => {

  registerLocale("tr", tr);

  const strings = useLocalization();
  const { employee } = useAuth();
  const primeData = usePrimeData();
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const { employeeId } = useParams();

  const fileRef = useRef();
  const timeZoneRef = useRef();

  //Sayfa yetkileri için bu bölüm kullanılıyor
  const { employeeTypeId, checkPermission, getPermission } = useAuth();
  const roles = getPermission();
  //Sayfa yetkileri için bu bölüm kullanılıyor

  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [detail, setDetail] = useState({});
  const [avatar, setAvatar] = useState(false);
  const [avatarPicture, setAvatarPicture] = useState(null);
  let fileDir = "employee";

  const firstBrand = { id: -1, name: strings.detail_search_titles.brands };
  const brandList = [firstBrand, ...primeData.brandList];

  const firstEmployeeType = { id: -1, name: strings.employee.create_employee.choose_employee_type };
  const employeeTypeList = [firstEmployeeType, ...primeData.employeeTypeList];

  const firstTeamLeader = { id: -1, name: strings.employee.create_employee.choose_team_leader };
  const teamLeaderList = [firstTeamLeader, ...primeData.teamLeadList];

  const firstEmployeeStatus = { id: -1, name: strings.employee.create_employee.choose_status };
  const employeeStatusList = [firstEmployeeStatus, ...primeData.employeeStatusList];

  const [selectedBrand, setSelectedBrand] = useState(firstBrand);
  const [selectedEmployeeType, setSelectedEmployeeType] = useState(firstEmployeeType);
  const [selectedTeamLeader, setSelectedTeamLeader] = useState(firstTeamLeader);
  const [selectedEmployeeStatus, setSelectedEmployeeStatus] = useState(firstEmployeeStatus);
  const [fullName, setFullName] = useState(null);
  const [email, setEmail] = useState(null);
  const [corporateEmail, setCorporateEmail] = useState(null);
  const [phoneNumberValue, setphoneNumberValue] = useState({ iso2: 'tr', dialCode: '90', phone: '' });
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState(null);
  const [oldPhoneNumber, setOldPhoneNumber] = useState(null);
  const [showPhoneNumber, setShowPhoneNumber] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [timezone, setTimezone] = useState(180);
  const [oldTimezone, setOldTimezone] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [sendWithCorporateMail, setSendWithCorporateMail] = useState(false);

  let inputProps = { placeholder: phoneNumberValue.iso2 === 'tr' ? '5XX XXX XX XX' : strings.form_info.enter_your_phone_number };
  const intlTelOpts = { preferredCountries: ['tr', 'az', 'sa'] };

  const phoneNumberHandle = val => {
    if (val.dialCode !== phoneNumberValue.dialCode) {
      setphoneNumberValue({ iso2: val.iso2, dialCode: val.dialCode, phone: '' });
    } else {
      setphoneNumberValue(val);
    }
  };

  useEffect(() => {
    const formattedPhoneNumber = deleteSpacesInThePhoneNumber(phoneNumberValue.phone, phoneNumberValue.dialCode);
    phoneNumberValue.phone !== '' && setFormattedPhoneNumber(formattedPhoneNumber);
    //Seçilen ülke için time zone alınıyor
    let selectedCountry = getTimezonesForCountry(phoneNumberValue.iso2.toUpperCase());
    //Eğer input hidden ise hata veriyor. Hatayı engellemek için kontrol eklendi.
    if (showPhoneNumber) {
      let newTimezone = selectedCountry[0].utcOffset;
      timeZoneRef.current.value = newTimezone;
      setTimezone(timezone => newTimezone);
    }
  }, [phoneNumberValue])


  const uploadImage = async (file) => {
    let fileName = employeeId;

    let url = process.env.REACT_APP_PROFILE_URL + "/_upload/upload.php?fileName=" + fileName + "&fileDir=" + fileDir;

    const formData = new FormData();
    formData.append("fileToUpload", file);
    formData.append("submit", "submit");


    const response = await fetch(url, {
      method: 'POST',
      body: formData
    });

    const result = await response.json();

    if (result.status === 200) {
      let avatar_url = process.env.REACT_APP_PROFILE_URL + "/" + fileDir + "/" + result.content + "?" + new Date().getTime();
      setAvatar(avatar => avatar_url);
      setAvatarPicture(avatarPicture => result.content);
      fileRef.current.value = "";
    } else {
      showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
      fileRef.current.value = "";
    }

  }

  const submitHandle = async e => {
    e.preventDefault()

    let valid = true
    let errorList = '';

    if (selectedBrand.id === null) {
      valid = false;
      errorList += strings.errors.brand_null_error + "<br />";
    }

    if (selectedEmployeeType.id === null) {
      valid = false;
      errorList += strings.errors.type_null_error + "<br />";
    }

    if (fullName === null) {
      valid = false;
      errorList += strings.errors.full_name_null_error + "<br />";
    }

    if (email === null || !validateEmail(email)) {
      valid = false;
      errorList += strings.errors.email_null_error + "<br />";
    }

    if (corporateEmail === null || !validateEmail(corporateEmail)) {
      valid = false;
      errorList += strings.errors.corporate_mail_null_error + "<br />";
    }

    if (showPhoneNumber) {
      if (phoneNumberValue.phone === '') {
        valid = false;
        errorList += strings.errors.phone_number_null_error + "<br />";
      }

      if (timezone === null) {
        valid = false;
        errorList += strings.errors.timezone_null_error + "<br />";
      }
    }

    if (valid && employeeId) {
      setUpdateLoading(updateLoading => true);

      const model = {
        Id: employeeId,
        Brand: selectedBrand.id,
        EmployeeType: selectedEmployeeType.id,
        Name: fullName,
        Email: email,
        CorporateEmail: corporateEmail,
        ProfilePicture: avatarPicture,
        CorporatePhone: showPhoneNumber ? formattedPhoneNumber : oldPhoneNumber,
        BeginDate: startDate,
        Status: selectedEmployeeStatus.id,
        Manager: selectedTeamLeader.id !== -1 ? selectedTeamLeader.id : null,
        TimeZone: showPhoneNumber ? timezone : oldTimezone,
        SendWithCorporateMail: sendWithCorporateMail,
        CreateUserId: employee?.EmployeeId
      }

      const result = await EmployeeEdit(model);

      if (result.status === 200) {
        showTopAlert(strings.employee.create_employee.success.update_message, "success");
        navigate(url("employee.list"));
      } else {

        if(ServiceErrorMessages.UPDATE_EMPLOYEE_PASSIVE_BUG === result.content) {
          showTopAlert(strings.employee.update_employee.passive_error_message, "error");
        } else {
          showTopAlert(strings.errors.an_error_occurred, "error");
        }

      }

      setUpdateLoading(updateLoading => false);
    } else {
      showCenteredHtmlAlert(strings.errors.an_error_occurred, errorList, "error");
    }

  }

  const getDetails = async (employeeId) => {
    const result = await EmployeeDetails(employeeId);
    let resultContent = JSON.parse(result.content);

    if (result.status === 200) {
      setDetail(detail => resultContent);
      setLoading(loading => false);
      //LOGGED
      analytics.useAnalytics('Kullanici_Guncelleme_Ekranina_Girdi', {employeeId: employeeId});
    } else {
      showTopAlert(strings.errors.an_error_occurred + " / " + resultContent, "error")
    }
  }

  useEffect(() => {
    if (Object.keys(detail).length !== 0) {
      let profilePicture = process.env.REACT_APP_PROFILE_URL + "/" + fileDir + "/" + detail.profilePicture + "?" + new Date().getTime();
      setAvatar(avatar => profilePicture);
      setAvatarPicture(avatarPicture => detail.profilePicture)
      setFullName(fullName => detail.name);
      setEmail(email => detail.email);
      setCorporateEmail(corporateEmail => detail.corporateEmail);
      setOldPhoneNumber(oldPhoneNumber => detail.corporatePhone);
      setOldTimezone(oldTimezone => detail.timeZone);
      let dateArr = detail.beginDate.replace(/\./g, "-").replace(/\//g, "-").split("-");
      setStartDate(startDate => new Date(dateArr[2], dateArr[1] - 1, dateArr[0], 12, 0, 0));
      setSendWithCorporateMail(sendWithCorporateMail => detail.sendWithCorporateMail);
      setSelectedBrand(selectedBrand => brandList.find(dt => dt.id === detail.brand.id));
      if (detail.manager !== null) {
        setSelectedTeamLeader(selectedTeamLeader => teamLeaderList.find(dt => dt.id === detail.manager));
      }
      setSelectedEmployeeType(selectedEmployeeType => employeeTypeList.find(dt => dt.id === detail.employeeType.id));
      setSelectedEmployeeStatus(selectedEmployeeStatus => employeeStatusList.find(dt => dt.id === detail.status));
    }
  }, [detail])

  useEffect(() => {
    if (employeeId.length > 0) {
      getDetails(employeeId);
    } else {
      showTopAlert(strings.errors.an_error_occurred, "error")
    }
  }, [employeeId])

  return (
    <>
      <PageTitle title={strings.page_title.edit_employee} />
      {checkPermission(roles, employeeTypeId) ? (<>
        {loading ?
          (
            <Loading />
          )
          :
          (
            <form onSubmit={submitHandle} className="flex flex-col gap-4 md:gap-8">
              <div className="sm:gap-4 md:gap-8 flex flex-col sm:flex-row sm:justify-center sm:items-center">
                <label className="label-add-user">
                  {strings.form_info.profile_photo} <span className="text-red">*</span>
                </label>
                <div className="sm:button-add-user flex justify-center">
                  <div className="relative">
                    <img
                      className="w-[110px] h-[110px] shadow-profilePhoto rounded-full"
                      id="avatar"
                      src={avatar ? avatar : DefaultProfile}
                      alt=""
                    />
                    <div className="absolute -right-[13px] top-[10px] z-10">
                      <input
                        ref={fileRef}
                        className="hidden"
                        type="file"
                        id="fileToUpload"
                        name="formFile"
                        accept=".png, .jpg, .jpeg"
                        onChange={(e) => uploadImage(e.target.files[0])}
                      />
                      <label htmlFor="fileToUpload">
                        <div className="cursor-pointer bg-white rounded-full w-[34px] h-[34px] flex items-center justify-center shadow-editProfilePhoto hover:border hover:border-[#ddd] hover:bg-gray-100 transition-all duration-500">
                          <span className="cursor-pointer text-[20px] material-symbols-outlined animate-fadeIn font-bold">
                            edit
                          </span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sm:gap-4 md:gap-8 flex flex-col sm:flex-row sm:justify-center sm:items-center">
                <label className="label-add-user">
                  {strings.table.brand} <span className="text-red">*</span>
                </label>
                <div className="w-full md:max-w-[480px] flex flex-col gap-2">
                  <Dropdown
                    data={brandList}
                    selectedData={selectedBrand}
                    setSelectedData={setSelectedBrand}
                    classnames="md:!max-w-[480px]"
                  />
                </div>
              </div>
              <div className="sm:gap-4 md:gap-8 flex flex-col sm:flex-row sm:justify-center sm:items-center">
                <label className="label-add-user">
                  {strings.form_info.department} <span className="text-red">*</span>
                </label>
                <div className="w-full md:max-w-[480px] flex flex-col gap-2">
                  <DropdownSearch
                    inputPlaceholder={strings.detail_search_titles.search_type}
                    data={primeData.employeeTypeList}
                    selectedData={selectedEmployeeType}
                    setSelectedData={setSelectedEmployeeType}
                    classnames="md:!max-w-[480px]"
                  />
                </div>
              </div>
              <div className="sm:gap-4 md:gap-8 flex flex-col sm:flex-row sm:justify-center sm:items-center">
                <label className="label-add-user">
                  {strings.form_info.team_leader} <span className="text-red">*</span>
                </label>
                <Dropdown
                  //title={strings.detail_search_titles.no_team_leader}
                  data={teamLeaderList}
                  selectedData={selectedTeamLeader}
                  setSelectedData={setSelectedTeamLeader}
                  classnames="md:!max-w-[480px]"
                />
              </div>
              <hr />
              <div className="sm:gap-4 md:gap-8 flex flex-col sm:flex-row sm:justify-center sm:items-center">
                <label className="label-add-user">
                  {strings.form_info.full_name} <span className="text-red">*</span>
                </label>
                <div className="w-full md:max-w-[480px] flex flex-col gap-2">
                  <Input
                    id="username"
                    type="text"
                    name="username"
                    onChange={(e) => setFullName(e.target.value)}
                    value={fullName || ''}
                    classnames="md:max-w-[480px]"
                  />
                </div>
              </div>
              <div className="sm:gap-4 md:gap-8 flex flex-col sm:flex-row sm:justify-center sm:items-center">
                <label className="label-add-user">
                  {strings.form_info.email} <span className="text-red">*</span>
                </label>
                <div className="w-full md:max-w-[480px] flex flex-col gap-2">
                  <Input
                    id="email"
                    type="text"
                    name="email"
                    onChange={e => setEmail(e.target.value)}
                    value={email || ''}
                    classnames="md:max-w-[480px]"
                    placeholder="example@example.com"
                  />
                </div>
              </div>
              <hr />
              <div className="sm:gap-4 md:gap-8 flex flex-col sm:flex-row sm:justify-center sm:items-start">
                <div className="label-add-user mt-3">
                  <label>{strings.form_info.corporate_email} <span className="text-red">*</span></label>
                </div>
                <div className="w-full md:max-w-[480px] flex flex-col gap-2">
                  <Input
                    id="corporate_email"
                    type="text"
                    name="corporate_email"
                    onChange={e => setCorporateEmail(e.target.value)}
                    value={corporateEmail || ''}
                    classnames="md:max-w-[480px]"
                    placeholder="example@example.com"
                  />
                  <label className="flex justify-start items-start">
                    <input
                      id="send_with_corporate_mail"
                      type="checkbox"
                      className="hidden"
                      onChange={() => setSendWithCorporateMail(!sendWithCorporateMail)}
                    />
                    <label
                      htmlFor="send_with_corporate_mail" 
                      className={`${handleCheckbox(sendWithCorporateMail)} mr-2 w-4 h-4 flex-shrink-0 rounded-[3px] cursor-pointer duration-500 flex items-center justify-center`}
                    >
                      <span className={classNames('text-inherit text-[17px] material-symbols-outlined animate-fadeIn font-bold', { 'hidden' : !sendWithCorporateMail})}>
                        done
                      </span>
                    </label>
                    <p className="!font-normal text-[12px]">{strings.employee.create_employee.corporate_mail_approve}</p>
                  </label>
                </div>
              </div>
              <div className="sm:gap-4 md:gap-8 flex flex-col sm:flex-row sm:justify-center sm:items-center relative">
                <div className="label-add-user gap-2">
                  <label>{strings.form_info.phone_number} <span className={`text-red ${!showPhoneNumber ? "hidden" : ''}`}>*</span></label>
                  <div className="group relative sm:order-first">
                    <Input
                      type="checkbox"
                      id="isActive"
                      classnames="hidden peer"
                      action={() => setShowPhoneNumber(!showPhoneNumber)}
                    />
                    <label
                      htmlFor="isActive"
                      className={`${handleCheckbox(showPhoneNumber)} w-5 h-5 rounded-[3px] cursor-pointer duration-500 flex items-center justify-center`}
                    >
                      <span className={classNames('text-inherit text-[20px] material-symbols-outlined animate-fadeIn font-bold', { 'hidden': !showPhoneNumber })}>
                        done
                      </span>
                    </label>
                    <div className="tooltip-top font-normal">
                      <p>{strings.button.update}</p>
                    </div>
                  </div>
                </div>
                <div className={`w-full md:max-w-[480px] h-[45px] leading-[45px] flex flex-col gap-2 ${showPhoneNumber ? "hidden" : ""}`}>{oldPhoneNumber || ''}</div>
                <div className={`w-full md:max-w-[480px] flex flex-col gap-2 ${!showPhoneNumber ? "hidden" : ""}`}>
                  <ReactIntlTelInput
                    className="form-input w-full md:max-w-[480px]"
                    inputStyle={{ color: 'green' }}
                    inputProps={inputProps}
                    intlTelOpts={intlTelOpts}
                    value={phoneNumberValue}
                    containerStyle={{ width: '480px' }}
                    onChange={phoneNumberHandle}
                    type="text"
                    name="phone"
                  />
                </div>
              </div>
              <div className="sm:gap-4 md:gap-8 flex flex-col sm:flex-row sm:justify-center sm:items-center">
                <label className="label-add-user">
                  {strings.form_info.start_date} <span className="text-red">*</span>
                </label>
                <div className="w-full md:max-w-[480px] flex flex-col gap-2 relative">
                  <DatePicker
                    locale="tr"
                    dateFormat="dd/MM/yyyy"
                    className="form-input w-full md:max-w-[480px]"
                    selected={startDate}
                    onChange={(date) => { setStartDate(date); setIsOpen(false) }}
                    placeholderText={strings.form_info.date_placeholder}
                    open={isOpen}
                    onFocus={() => setIsOpen(true)}
                  />
                  <button type="button" onClick={() => setIsOpen(!isOpen)}>
                    <span className="material-symbols-outlined absolute right-4 top-3 text-lg">
                      calendar_today
                    </span>
                  </button>
                </div>
              </div>
              <div className="sm:gap-4 md:gap-8 flex flex-col sm:flex-row sm:justify-center sm:items-center">
                <div className="label-add-user gap-2">
                  <label>{strings.form_info.timezone} <span className={`text-red ${!showPhoneNumber ? 'hidden' : ''}`}>*</span></label>
                </div>
                <div className={`w-full md:max-w-[480px] h-[45px] leading-[45px] flex flex-col gap-2 ${showPhoneNumber ? "hidden" : ""}`}>{oldTimezone || ''}</div>
                <div className={`w-full md:max-w-[480px] flex flex-col gap-2 ${!showPhoneNumber ? "hidden" : ""}`}>
                  <Input
                    ref={timeZoneRef}
                    type="number"
                    isReadOnly={true}
                    defaultValue={timezone}
                  />
                </div>
              </div>
              <div className="sm:gap-4 md:gap-8 flex flex-col sm:flex-row sm:justify-center sm:items-center">
                <label className="label-add-user">
                  {strings.form_info.status} <span className="text-red">*</span>
                </label>
                <Dropdown
                  data={employeeStatusList}
                  selectedData={selectedEmployeeStatus}
                  setSelectedData={setSelectedEmployeeStatus}
                  classnames="md:!max-w-[480px]"
                />
              </div>
              <hr />
              <input type="hidden" id="employeId" name="employeeId" value={employeeId} />
              <input type="hidden" id="profilePicture" name="profilePicture" value='' />
              <button
                type="submit"
                className={classNames("button search-button text-white bg-blue border-blue mt-3 mx-auto px-10 mb-5 w-[133px]", {
                  "opacity-70 hover:opacity-70 cursor-not-allowed" : updateLoading,
                })}
                disabled={updateLoading}
              >
                {updateLoading && <LoadingSpinner />}
                {!updateLoading && strings.button.update}
              </button>
            </form>
          )}</>
      ) : (<NotAuthorized />)}
    </>
  );
};

export default EditEmployee;
