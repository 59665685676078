import {useLocalization} from "../../../../hooks/Utils/useLocalization";
import {
    showCenteredAlertTwoButtons,
    showCenteredAlertTwoButtonsWithParameters, showTopAlert,
    SquareButton
} from "../../../../components";
import {handleCheckbox} from "../../../../components/Utility";
import classNames from "classnames";
import {useState} from "react";
import {url} from "../../../../routes/utility";
import {ChangeUseCaseStatusService} from "../../../../services/UseCase/UseCaseService";

const UseCaseTableRow = ({ useCase }) => {

    const strings = useLocalization();

    const [isUseCaseActive, setIsUseCaseActive] = useState(useCase.status);

    const changeUseCaseStatus = async (useCaseId) => {

        const result = await ChangeUseCaseStatusService(useCaseId);

        if(result.status === 200){
            setIsUseCaseActive(!isUseCaseActive);
            if(!isUseCaseActive){
                showTopAlert(strings.use_case.list.use_case_status_active, "success");
            } else {
                showTopAlert(strings.use_case.list.use_case_status_active, "success");
            }
        } else {
            showTopAlert(strings.errors.an_error_occurred, "error");
        }
    }

    return(
        <tr className="bg-white hover:bg-[#f0f0f0]">
            <td className="td align-middle">
                <div className="flex items-center">
                    <p className="min-w-max">{useCase.title}</p>
                </div>
            </td>
            <td className="td align-middle">
                <div className="flex justify-center w-[60px]">
                    <input
                        id="use-case-status"
                        type="checkbox"
                        className="hidden"
                    />
                    <label
                        htmlFor="use-case-status"
                        className={`${handleCheckbox(isUseCaseActive)} mr-2 w-7 h-7 rounded-[3px] cursor-pointer duration-500 flex items-center justify-center`}
                        onClick={() => {
                            showCenteredAlertTwoButtonsWithParameters(
                                strings.alert.centered_alert_two_buttons.title,
                                strings.use_case.list.change_use_case_status_question,
                                strings.button.confirm,
                                strings.button.cancel,
                                changeUseCaseStatus,
                                [useCase.id]
                            );
                        }}
                    >
                        <span className={classNames('text-inherit text-[25px] material-symbols-outlined animate-fadeIn font-bold', { 'hidden' : !isUseCaseActive})}>
                          done
                        </span>
                    </label>
                </div>
            </td>
            <td className="td align-middle">
                <SquareButton
                    classnames="bg-green"
                    iconName="edit"
                    to={url("usecase.update")}
                    tooltip={strings.button.update}
                    state={{ id: useCase.id }}
                />
            </td>
        </tr>

    )
}

export default UseCaseTableRow;