import { useEffect, useState } from 'react';
import { Loading, PageTitle, Pagination, SearchBar, SearchCount, showTopAlert } from '../../components';
import { stringToDate } from '../../components/Utility';
import useAuth from '../../hooks/Log/useAuth';
import { useLocalization } from '../../hooks/Utils/useLocalization';
import { TicketFilter } from '../../services/Ticket/TicketService';
import TicketDetailSearch from './components/TicketDetailSearch';
import TicketTable from './components/TicketTable';

const Ticket = () => {

    const strings = useLocalization();
    const { employee } = useAuth();
    const limit = parseInt(process.env.REACT_APP_TABLE_ROW_LIMIT);
    const [start, setStart] = useState(0);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecord, setTotalRecord] = useState(0);
    const [data, setData] = useState([]);

    const [detailSearch, setDetailSearch] = useState({
        beginDate: null,
        endDate: null,
        brandId: parseInt(employee.BrandId),
        status: 1, //Varsayılan olarak yanıtlanmayanlar gelecek.
        searchText: ''
    })

    //Arama verileri değiştiğinde değerleri sıfırlıyoruz
    const resetValue = () => {
        setStart(start => 0);
        setCurrentPage(currentPage => 1);
        setLoading(loading => true);
    }

    //select değişiklikleri bu alandan yönetiliyor
    const changeDetail = (values) => {
        setDetailSearch({ ...detailSearch, ...values });
        resetValue();
    }

    //input değişiklikleri bu alandan yönetiliyor
    const changeValue = (value) => {
        setDetailSearch({ ...detailSearch, searchText: value });
        resetValue();
    }

    //sayfa değiştikçe bilgileri yeniden çağırıyoruz
    useEffect(() => {
        if (totalRecord !== 0) {
            getTickets();
        }
    }, [start])

    const getTickets = async () => {
        const model = {
            start: start,
            length: limit,
            beginDate: detailSearch.beginDate ? stringToDate(detailSearch.beginDate) : null,
            endDate: detailSearch.endDate ? stringToDate(detailSearch.endDate, 23, 59, 59) : null,
            brandId: detailSearch.brandId,
            status: detailSearch.status,
            searchText: detailSearch.searchText
        }

        const result = await TicketFilter(model);
        if (result.status === 200) {
            let resultContent = JSON.parse(result.content);
            setData(data => resultContent.data);
            setTotalRecord(totalRecord => resultContent.recordsTotal);
            setLoading(loading => false);
        } else {
            showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
        }
    }

    useEffect(() => {

        let abortController = new AbortController();

        getTickets();

        return () => {
            abortController.abort();
        }
    }, [detailSearch])

    return (
        <>
            <TicketDetailSearch
                changeDetail={changeDetail}
                loading={loading}
            />
            <SearchBar
                changeValue={changeValue}
                placeHolder={strings.search_bar.search_ticket}
                isDisabled={loading}
            />
            {!loading && <SearchCount count={totalRecord} />}
            <PageTitle title={strings.navbar.tickets.title} />
            {loading ?
                (
                    <Loading />
                )
                :
                (
                    <>
                        <TicketTable data={data} />
                        <Pagination
                            totalCount={totalRecord}
                            limit={limit}
                            start={start}
                            setStart={setStart}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            setLoading={setLoading}
                            loadScreen={true}
                        />
                    </>
                )}
        </>
    )
}

export default Ticket