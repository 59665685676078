import {useEffect, useState} from "react";
import {GetEmployeeSenderEmail} from "../../services/Employee/EmployeeService";

const useEmployeeSenderEmail = (employeeId) => {

    const [employeeSenderEmail, setEmployeeSenderEmail] = useState("");

    const getEmployeeSenderEmail = async (employeeId) => {

        const result = await GetEmployeeSenderEmail(employeeId);

        if(result.status === 200) {
            setEmployeeSenderEmail(employeeSenderEmail => result.content);
        }
    }

    useEffect(() => {
        if(employeeId.length > 0)
            getEmployeeSenderEmail(employeeId);
    }, [employeeId]);

    return employeeSenderEmail;
}

export default useEmployeeSenderEmail;