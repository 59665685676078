import React from "react";
import { useState, useEffect } from "react";
import { Loading, PageTitle, Pagination, SearchCount, showTopAlert } from "../../../../../../components";
import { stringToDate } from "../../../../../../components/Utility";
import { useLocalization } from "../../../../../../hooks/Utils/useLocalization";
import { useParams } from "react-router-dom";
import { MemberTaskNotesFilter } from "../../../../../../services/Member/MemberTaskService";
import TaskNotesTable from "./TaskNoteTable";
import TaskNotesDetailSearch from "./TaskNoteDetailSearch";

const TaskNotes = () => {

  const strings = useLocalization();
  const { memberId } = useParams() || false;
  const limit = 10;
  const [start, setStart] = useState(0);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [data, setData] = useState([]);

  const [detailSearch, setDetailSearch] = useState({
    beginDate: null,
    endDate: null,
    employee: null,
    includeSystem: false
  });

  //Arama verileri değiştiğinde değerleri sıfırlıyoruz
  const resetValue = () => {
    setStart(start => 0);
    setCurrentPage(currentPage => 1);
    setLoading(loading => true);
  }

  //select değişiklikleri bu alandan yönetiliyor
  const changeDetail = (values) => {
    setDetailSearch({ ...detailSearch, ...values });
    resetValue();
  };

  useEffect(() => {
    if (totalRecord !== 0) {
      getMemberTaskNotes();
    }
  }, [start])

  const getMemberTaskNotes = async () => {
    const model = {
      memberId: memberId,
      start: start,
      length: limit,
      beginDate: detailSearch.beginDate ? stringToDate(detailSearch.beginDate) : null,
      endDate: detailSearch.endDate ? stringToDate(detailSearch.endDate, 23, 59, 59) : null,
      createUser: detailSearch.employee === -1 ? null : detailSearch.employee,
      includeSystem: detailSearch.includeSystem
    }

    const result = await MemberTaskNotesFilter(model);

    if(result.status === 200){
      let resultContent = JSON.parse(result.content);

      setData(data => resultContent.data);
      setTotalRecord(totalRecord => resultContent.recordsTotal);
      setLoading(loading => false);
    } else {
      showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
    }
  }

  useEffect(() => {

    let abortController = new AbortController();

    getMemberTaskNotes();

    return () => {
        abortController.abort();
    }
  }, [detailSearch])

  return (
    <>
      <TaskNotesDetailSearch changeDetail={changeDetail} />

      <div className="overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
        <div className="inline-block min-w-full rounded-lg overflow-hidden py-6">
          {!loading && <SearchCount count={totalRecord} />}
          <PageTitle title={strings.member.member_detail.header.content.task_notes} />
          {loading ? 
            (
              <Loading />
            )
            :
            (
              <>
                <TaskNotesTable data={data} /> 
                <Pagination 
                  totalCount={totalRecord}
                  limit={limit}
                  start={start}
                  setStart={setStart}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  setLoading={setLoading}
                  loadScreen={true}
                />
              </>
            )
          }
        </div>
      </div>
    </>
  );
};

export default TaskNotes;
