import {useEffect, useRef, useState} from "react";
import DatePicker from "react-datepicker";
import {DetailSearchLayout, Dropdown} from "../../../../components";
import {getDateWithDayDifference, getddMMyyyyDate} from "../../../../components/Utility";
import {DateRanges} from "../../../../consts/Constants";
import useAuth from "../../../../hooks/Log/useAuth";
import {useLocalization} from "../../../../hooks/Utils/useLocalization";
import usePrimeData from "../../../../hooks/Utils/usePrimeData";

const NewCandidateDetailSearch = ({changeDetail, loading}) => {

    const strings = useLocalization();
    const primeData = usePrimeData();
    const { employee }  = useAuth();

    //Bu bölüm belirli tarih aralığı seçmek için kullanılan değişkenlerin/fonksiyonların olduğu bölüm
    const datePickerRef = useRef();
    const firstVisit = useRef(true);

    const [beginDate, setBeginDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const firstDateRange = { id: -1, name: strings.detail_search_titles.date_range };
    let dateRangeList = [firstDateRange, ...DateRanges];

    const handleDateRange = (dates) => {
        const [start, end] = dates;
        setBeginDate(start);
        setEndDate(end);
        if (start !== null && end !== null) {
            let tmpDateRange = { id: 8, name: getddMMyyyyDate(start) + " - " + getddMMyyyyDate(end) };
            dateRangeList.push(tmpDateRange);
            changeDetail?.({ beginDate: getddMMyyyyDate(start), endDate: getddMMyyyyDate(end) });
            setBeginDate(null);
            setEndDate(null);
            setDateRange(dateRange => dateRangeList[8]);
            datePickerRef.current.classList.add("hidden");
        }

    };
    //Bu bölüm belirli tarih aralığı seçmek için kullanılan değişkenlerin/fonksiyonların olduğu bölüm

    //Bu bölüm skor aralığı seçmek için kullanılan değişkenlerin olduğu bölüm
    const scoreRanges = [
        { id: 1, name: '0-25' },
        { id: 2, name: '25-50' },
        { id: 3, name: '50-75' },
        { id: 4, name: '75-100' },
        { id: 5, name: '100+' },
    ]
    //Bu bölüm skor aralığı seçmek için kullanılan değişkenlerin olduğu bölüm

    const firstBrand = { id: -1, name: strings.detail_search_titles.brands };
    const brandList = [firstBrand, ...primeData.brandList];

    const firstScore = { id: -1, name: strings.detail_search_titles.scores };
    const scoreList = [firstScore, ...scoreRanges];

    const firstMemberFormStatus = { id: -1, name: strings.detail_search_titles.status };
    const memberFormStatusList = [firstMemberFormStatus, ...primeData.memberFormStatusList];

    const [dateRange, setDateRange] = useState(DateRanges.find(d => d.id === 1)); // Default today
    const [brand, setBrand] = useState(firstBrand);
    const [score, setScore] = useState(firstScore);
    const [memberFormStatus, setMemberFormStatus] = useState(firstMemberFormStatus)

    useEffect(() => {
        //Sayfa ilk kez yüklenmiyorsa işlemi yap
        //Servise birden çok gez gitmesini engelliyoruz
        if (!firstVisit.current) {
            let todayDate = new Date();
            let tmpBeginDate = '';
            let tmpEndDate = '';

            datePickerRef.current.classList.add("hidden");

            switch (dateRange.id) {
                case 1:
                    //Bugün seçildiyse
                    tmpBeginDate = getddMMyyyyDate(todayDate);
                    tmpEndDate = tmpBeginDate;
                    break;
                case 2:
                    //Dün seçildiyse
                    tmpBeginDate = getddMMyyyyDate(getDateWithDayDifference(todayDate, 1));
                    tmpEndDate = tmpBeginDate;
                    break;
                case 3:
                    //Son 7 gün seçildiyse
                    tmpEndDate = getddMMyyyyDate(todayDate);
                    tmpBeginDate = getddMMyyyyDate(getDateWithDayDifference(todayDate, 6));
                    break;
                case 4:
                    //Son 30 gün seçildiyse
                    tmpEndDate = getddMMyyyyDate(todayDate);
                    tmpBeginDate = getddMMyyyyDate(getDateWithDayDifference(todayDate, 30));
                    break;
                case 5:
                    //Bu ay seçildiyse
                    tmpBeginDate = getddMMyyyyDate(new Date(todayDate.getFullYear(), todayDate.getMonth(), 1));
                    tmpEndDate = getddMMyyyyDate(new Date(todayDate.getFullYear(), todayDate.getMonth() + 1, 0));
                    break;
                case 6:
                    //Geçen ay seçildiyse
                    tmpBeginDate = getddMMyyyyDate(new Date(todayDate.getFullYear(), todayDate.getMonth() - 1, 1));
                    tmpEndDate = getddMMyyyyDate(new Date(todayDate.getFullYear(), todayDate.getMonth(), 0));
                    break;
                case 7:
                    //Tarih aralığı seçildiyse
                    datePickerRef.current.classList.remove('hidden');
                    break;
            }

            // date range ve isMemberFormCreateDate kontrolü ilk date değiştiğinde servise birden fazla kez gidilmesini önlemek için koyuldu.
            (dateRange.id < 7 && changeDetail?.({ beginDate: tmpBeginDate, endDate: tmpEndDate }));

            firstVisit.current = false;
        }
    }, [dateRange])

    useEffect(() => {
        //Sayfa ilk kez yüklenmiyorsa işlemi yap
        //Servise birden çok gez gitmesini engelliyoruz
        if (!firstVisit.current) {
            changeDetail?.({ brandId: brand?.id });
            firstVisit.current = false;
        }
    }, [brand])

    useEffect(() => {
        //Sayfa ilk kez yüklenmiyorsa işlemi yap
        //Servise birden çok gez gitmesini engelliyoruz
        if (!firstVisit.current) {
            changeDetail?.({ scoreFilter: score.id });
            firstVisit.current = false;
        }
    }, [score])

    useEffect(() => {
        //Sayfa ilk kez yüklenmiyorsa işlemi yap
        //Servise birden çok gez gitmesini engelliyoruz
        if (!firstVisit.current) {
            changeDetail?.({ formStatus: memberFormStatus?.id });
            firstVisit.current = false;
        }
    }, [memberFormStatus])

    useEffect(() => {
        if(brandList.length > 1) {
          const selectedBrand = brandList.find(brand => brand.id === parseInt(employee.BrandId))
          setBrand(brand => selectedBrand)
        }
    }, [primeData.brandList])

    useEffect(() => {
        //Sayfa yüklendikten sonra false yaparak her değişiklikte
        //servisin ziyaret edilmesini sağlıyoruz
        firstVisit.current = false;
    }, [])

    return (
        <>
            <DetailSearchLayout
                isDisabled={loading}
                children={
                    <div className="!flex gap-x-4 gap-y-5 max-md-lg:justify-center flex-wrap relative">
                        <Dropdown
                            title={strings.detail_search_titles.date_range}
                            data={DateRanges}
                            selectedData={dateRange}
                            setSelectedData={setDateRange}
                            isDisabled={loading}
                        />
                        <div ref={datePickerRef} className='absolute top-[75px] z-10 hidden'>
                            <button className='absolute button search-button !h-[25px] !p-2 top-[-28px] bg-red text-white focus:!border-none right-0'
                                    onClick={() => datePickerRef.current.classList.add("hidden")}>X</button>
                            <DatePicker
                                selected={beginDate}
                                onChange={handleDateRange}
                                onClickOutside={() => datePickerRef.current.classList.add("hidden")}
                                startDate={beginDate}
                                endDate={endDate}
                                selectsRange
                                inline
                            />
                        </div>
                        <Dropdown
                            title={strings.detail_search_titles.brands}
                            data={brandList}
                            selectedData={brand}
                            setSelectedData={setBrand}
                            isDisabled={loading}
                        />
                        <Dropdown
                            title={strings.detail_search_titles.scores}
                            data={scoreList}
                            selectedData={score}
                            setSelectedData={setScore}
                            isDisabled={loading}
                        />
                        <Dropdown
                            title={strings.detail_search_titles.status}
                            data={memberFormStatusList}
                            selectedData={memberFormStatus}
                            setSelectedData={setMemberFormStatus}
                            isDisabled={loading}
                        />
                    </div>
                }
            />
        </>
    );
}

export default NewCandidateDetailSearch;