import {TextBadge} from "./Badges";
import React, {useEffect, useState} from "react";
import useMemberName from "../hooks/Member/useMemberName";
import useMemberEmail from "../hooks/Member/useMemberEmail";
import useMemberPhoneNumber from "../hooks/Member/useMemberPhoneNumber";
import useMemberBrand from "../hooks/Member/useMemberBrand";
import {getBrandColorByBrandId, maskPhoneNumber} from "./Utility";
import {CopyToClipboard} from "./index";

const MemberInformations = ({ memberId, viewStatus = ['name', 'phone', 'email']}) => {

    const memberBrandId = useMemberBrand(memberId);
    const memberName = useMemberName(memberId);
    const memberEmail = useMemberEmail(memberId);
    const memberPhoneNumber = useMemberPhoneNumber(memberId);

    const [views, setViews] = useState([]);

    const renderSvg = () => {
        return (
            <svg
                className={`fill-white opacity-60 mt-0.5 h-1.5 w-1.5`}
                viewBox="0 0 6 6"
                aria-hidden="true"
            >
                <circle cx="3" cy="3" r="3"/>
            </svg>
        )
    };

    //İlgili öğrencinin e-posta adresi yok ise listeden çıkarılıyor.
    useEffect(() => {
        if(viewStatus.includes('email') && (memberEmail === "Belirtilmedi" || memberEmail.length === 0)){
            setViews(viewStatus.filter(view => view !== 'email'));
        } else {
            setViews(viewStatus);
        }
    }, []);

    return(
        <TextBadge className={`${getBrandColorByBrandId(memberBrandId)} mt-1 max-sm:w-full`}>
            <div className="flex max-sm:flex-wrap gap-1.5 items-center">
                {views.map((status, index) => {
                    return (
                        <React.Fragment key={index}>
                            {status === 'name' && (
                                <p className="min-w-max text-white">{memberName}</p>
                            )}
                            {status === 'phone' && (
                                <div className="flex items-center gap-0.5">
                                    <p className="min-w-max text-white">{maskPhoneNumber(memberPhoneNumber)}</p>
                                    <CopyToClipboard
                                        text={memberPhoneNumber}
                                        className="-mb-1"
                                        iconClassName="!text-[13px] text-white"
                                    />
                                </div>
                            )}
                            {status === 'email' && (
                                <p className="text-white">{memberEmail}</p>
                            )}
                            {index !== views.length - 1 && renderSvg()}
                        </React.Fragment>
                    )
                })}
            </div>
        </TextBadge>
    );
};



export default MemberInformations;