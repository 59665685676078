import { fetchApi } from '../index';

export const MemberStudentFilter = async (model) => {

    const url = '/member/v3/member/student/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}