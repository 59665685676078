import { fetchApi } from '../index';

export const GetMemberDevices = async (memberId) => {
    
    const url = '/member/v1/member_device/get_member_devices_by_member_id/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}