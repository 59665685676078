import { Link } from "react-router-dom";
import useAuth from "../../../../hooks/Log/useAuth";
import { useLocalization } from "../../../../hooks/Utils/useLocalization";
import {
  getBrandNameById,
  getLogoByBrandId,
  isItSpecified,
  ISOStringToDate,
  maskPhoneNumber
} from "../../../../components/Utility";
import { url } from "../../../../routes/utility";
import { applicationStatusColorPicker } from "../Common/GetPerson";
import { CopyToClipboard, CorporateBadge, TextBadge } from "../../../../components";
import classNames from "classnames";
import { useEffect, useState } from "react";

const CandidateTableRow = (props) => {

  const { member, setModal, setModalContent } = props;

  const strings = useLocalization();
  const { employee } = useAuth();

  const notSpecified = strings.errors.it_is_not_specified;
  const lastNoteNotFound = strings.errors.last_note_not_found;
  const reminderDateNotFound = strings.errors.reminder_date_not_found;

  const [employeeList, setEmployeeList] = useState([]);

  const handleOpenCandidateNotesModal = () => {
    setModal(true);
    setModalContent({ memberId: member.memberId, element: "candidateNotes" });
  }

  const handleOpenReasonToLearnEnglishModal = () => {
    if (member?.reasonToLearnEnglish) {
      setModal(true);
      setModalContent({ memberId: member.memberId, element: "reasonToLearnEnglish" });
    }
  }

  useEffect(() => {
    if (member?.employees) {
      try {
        const jsonEmployeeList = JSON.parse(member?.employees);
        setEmployeeList(jsonEmployeeList);
      } catch (e) {
        setEmployeeList([]);
      }
    }
  }, []);

  return (
    <tr className="bg-white hover:bg-[#f0f0f0]">
      <td className="td align-middle">
        <div className="flex items-center flex-col">
          <div className="group relative max-w-max">
            <img src={getLogoByBrandId(member.brandId)} width="43" height="48" alt="" />
            <div className="tooltip-top">
              <p>{getBrandNameById(member.brandId)}</p>
            </div>
          </div>
          {member.corporateStatus === 1 &&
            <CorporateBadge className="mt-2 ml-0.5" showFirmName={false} />
          }
          {member.reminderReason != null &&
            <TextBadge className="bg-light-gray h-6 max-w-max mt-2">
              <button
                type="button"
                className="min-w-max cursor-pointer group relative"
                onClick={handleOpenCandidateNotesModal}
              >
                {member.reminderReason}
                <div className="tooltip-top">
                  {strings.table.show_candidate_notes}
                </div>
              </button>
            </TextBadge>
          }
        </div>
      </td>
      <td className="td align-middle">
        <div className="flex items-center">
          <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
            person
          </span>
          <Link
            to={url("member.detail.profile", { memberId: member.memberId })}
            state={{ id: member.memberId, status: member.status }}
            target="_blank"
            className="font-bold text-blue hover:underline focus:underline min-w-max">
            {member.fullName}
          </Link>
        </div>
        <div className="flex items-center">
          <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
            call
          </span>
          <div className="flex items-center gap-0.5">
            <p className="min-w-max">{maskPhoneNumber(member.phone)}</p>
            <CopyToClipboard text={member.phone} className="-mb-1" iconClassName="!text-[13px]" />
          </div>
        </div>
        <div className="flex items-center">
          <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
            mail
          </span>
          <p>{isItSpecified(member.email, notSpecified)}</p>
        </div>
      </td>
      <td className="td min-w-[170px]">
        <button
          className={`badge-color cursor-default min-w-[200px] my-1 ${applicationStatusColorPicker(member.applicationStatus)} uppercase`}>
          {member.applicationStatus}
        </button>
        <div className="flex items-center max-w-max">
          <div className="relative group">
            <span
              className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
              equalizer
            </span>
            {member?.level && (
              <div className="tooltip-top">
                {strings.member.member_detail.terms.level}
              </div>
            )}
          </div>
          <p className="mb-1">{isItSpecified(member.level, notSpecified)}</p>
        </div>
        <div className="flex items-center max-w-max">
          <div className="relative group">
            <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
              language
            </span>
            {member?.reasonToLearnEnglish && (
              <div className="tooltip-top">
                {strings.form_info.use_case}
              </div>
            )}
          </div>
          <div className="relative group">
            <button
              type="button"
              className={classNames("min-w-max", {
                "text-blue hover:underline font-bold": member?.reasonToLearnEnglish,
                "cursor-text": !member?.reasonToLearnEnglish
              })}
              onClick={() => {
                if (member?.reasonToLearnEnglish) {
                  setModal(true);
                  setModalContent({ memberId: member.memberId, element: "reasonToLearnEnglish" });
                }
              }}
            >
              <p className="mb-1">{isItSpecified(member?.reasonToLearnEnglish, notSpecified)}</p>
            </button>
            {member?.reasonToLearnEnglish &&
              <div className="tooltip-top">
                {strings.member.member_detail.profile.click_for_see_all_reasons}
              </div>
            }
          </div>
        </div>
        {member?.utmSource && (
          <div className="flex items-center max-w-max">
            <div className="relative group">
              <span
                className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
                ads_click
              </span>
              {member?.utmSource && (
                <div className="tooltip-top">
                  Utm Source
                </div>
              )}
            </div>
            <p className="mb-1">{member.utmSource}</p>
          </div>
        )}
        {member?.landingPage && (
          <div className="flex items-center max-w-max">
            <div className="relative group">
              <span
                className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
                link
              </span>
              {member?.landingPage && (
                <div className="tooltip-top">
                  Landing Page
                </div>
              )}
            </div>
            <p className="mb-1.5">{member.landingPage}</p>
          </div>
        )}
        {member?.utmMedium && (
          <div className="flex items-center max-w-max">
            <div className="relative group">
              <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
                travel_explore
              </span>
              {member?.utmMedium && (
                <div className="tooltip-top">
                  Utm Medium
                </div>
              )}
            </div>
            <p className="mb-1">{member.utmMedium}</p>
          </div>
        )}
        {member?.utmContent && (
          <div className="flex items-center max-w-max">
            <div className="relative group">
              <span
                className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
                note_stack
              </span>
              {member?.utmContent && (
                <div className="tooltip-top">
                  Utm Content
                </div>
              )}
            </div>
            <p className="mb-1">{member.utmContent}</p>
          </div>
        )}
      </td>
      <td className="td align-middle">
        <div className="badge max-w-[300px] my-1 text-center">
          <p>
            <b>{strings.table.apply_date} </b>{ISOStringToDate(member.beginDate, employee.TimeZone)}
          </p>
        </div>
        <div className="badge max-w-[300px] my-1 text-center">
          <p className="min-w-max">
            <b>{strings.table.last_note_date} </b>{member.endDate ? ISOStringToDate(member.endDate, employee.TimeZone) : lastNoteNotFound}
          </p>
        </div>
        <div className="badge max-w-[300px] my-1 text-center">
          <p className="min-w-max">
            <b>{strings.member.member_detail.tasks.postponed_date}: </b>{member.reminderDate ? ISOStringToDate(member.reminderDate, employee.TimeZone) : reminderDateNotFound}
          </p>
        </div>
      </td>
      <td className="td align-middle text-start w-[200px]">
        {employeeList.map((employee, index) => (
          <div key={index} className="badge my-1 min-w-[200px]">
            <p>
              <b>{employee.Title}:</b> {employee.FullName}
            </p>
          </div>
        ))}
      </td>
    </tr>
  );
};

export default CandidateTableRow;
