import { fetchHeader } from "./FetchHeader";

export const fetchApi = async (url, payload) => {

    const apiUrl = process.env.REACT_APP_API_URL + url;
    
    const response = await fetch(apiUrl, fetchHeader(payload));
    const json = await response.json();

    return json;
}