import React, { useState } from "react";
import CampaignTableRow from "./CampaignTableRow";
import { NoRecordsFound } from "../../../../components";
import { useLocalization } from "../../../../hooks/Utils/useLocalization";

const CampaignTable = ({ data }) => {
  
  const strings = useLocalization();

  return (
    <>
      <div className="mx-auto px-4 my-4">
        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
          <div className="inline-block min-w-full rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal text-header">
              <thead>
                <tr>
                  <th className="th w-28"><p className="ml-6">{strings.table.brand}</p></th>
                  <th className="th w-[300px]">{strings.table.campaign_code}</th>
                  <th className="th"><p className="min-w-max">{strings.table.campaign_details}</p></th>
                  <th className="th w-[300px]">{strings.table.created_date}</th>
                  <th className="th w-[200px]">{strings.form_info.status}</th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 ?
                    data.map((campaign, index) => (
                        <CampaignTableRow 
                            key={index}
                            campaign={campaign}
                        />
                    ))
                    :
                    (
                      <tr className="bg-white hover:bg-[#f0f0f0]">
                        <td colSpan="5">
                            <NoRecordsFound />
                        </td>
                      </tr>
                    )
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default CampaignTable;
