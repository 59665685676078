import React, { useEffect, useState } from "react";
import "../../../../../../../assets/css/audio-background.css";
import { ZoomIn } from "../../../../../../../assets/svg";
import { useLocalization } from "../../../../../../../hooks/Utils/useLocalization";
import { GetLessonDetail } from "../../../../../../../services/Lesson/LessonService";
import { GetAndUpdateLessonCorrections } from "../../../../../../../services/Member/MemberLessonService";

import { Accept2, Multiply } from "../../../../../../../assets/img";
import {Loading, MemberInformations, Modal, showTopAlert} from "../../../../../../../components";
import {
  addTimeZone,
  fullDateToShortDate2,
  ISOStringToDate2
} from "../../../../../../../components/Utility";
import useAuth from "../../../../../../../hooks/Log/useAuth";
import LetterNoteInformationModal from "./LetterNoteInformationModal";
import {GetTeacherCallInformationByDate} from "../../../../../../../services/International/InternationalService";
import {TermScheduleStatusType} from "../../../../../../../consts/Constants";
import TeacherNetworkLog from "./components/TeacherNetworkLog/TeacherNetworkLog";

const LessonDetailModal = (props) => {
  const { scheduleId, lessonStatus, lessonDate, studentNo, memberId, closeButton } = props;

  const strings = useLocalization();
  const { employee } = useAuth();

  const [detailModal, setDetailModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isOpenLessonResultTab, setIsOpenLessonResultTab] = useState(true);
  const [isOpenLogsTab, setIsOpenLogsTab] = useState(false);
  const [isOpenVoiceRecordTab, setIsOpenVoiceRecordTab] = useState(false);
  const [isOpenBookTab, setIsOpenBookTab] = useState(false);
  const [isOpenTeacherNetworkLogsTab, setIsOpenTeacherNetworkLogsTab] = useState(false);

  const [data, setData] = useState(false);
  const [responseError, setResponseError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [mistakeData, setMistakeData] = useState([]);
  const [correctData, setCorrectData] = useState([]);
  const [correctionLoading, setCorrectionLoading] = useState(true);
  const [callTeacherInformation, setCallTeacherInformation] = useState("");

  //Bir tab'ı açmadan önce diğer bütün tab'ların state'ini false'e çekmek için kullanıyoruz
  const closeAllTabs = () => {
    setIsOpenLessonResultTab(isOpenLessonResultTab => false);
    setIsOpenLogsTab(isOpenLogsTab => false);
    setIsOpenVoiceRecordTab(isOpenVoiceRecordTab => false);
    setIsOpenBookTab(isOpenBookTab => false);
    setIsOpenTeacherNetworkLogsTab(isOpenTeacherNetworkLogsTab => false)
  };

  //Ders detaylarını getiren fonksiyon
  const getLessonDetail = async (scheduleId) => {
    const result = await GetLessonDetail(scheduleId);

    if (result.status === 200) {
      let resultContent = JSON.parse(result.content);
      setData((data) => resultContent);
      setLoading((loading) => false);
    } else {
      showTopAlert(strings.errors.lesson_detail_not_found, "error");
      setTimeout(() => closeButton(), 2000);
    }
  };

  //GPT API aracılığıyla correction'ları güncelleyen servis
  const getAndUpdateLessonCorrections = async (mistakes, lessonResultId) => {

    const model = {
      mistakes: mistakes,
      lessonResultId: lessonResultId
    }

    const result = await GetAndUpdateLessonCorrections(model)

    if (result.status === 200) {
      let resultContent = JSON.parse(result.content)
      setCorrectionLoading(false)

      //Result'un içi boş değilse, correction'ları ekrana yazdırmak için servise yeniden istek atılıyor
      if (resultContent.length > 0) {
        getLessonDetail(scheduleId);
      }
      //Result'un içi boşsa, GPT API'den status 500 dönmüştür, ekrana hata mesajı yazdırılıyor
      else {
        setResponseError(responseError => true)
      }
    }
  }

  // Öğrencinin katıldığı dersinde eğitmenini getiren servis
  const getTeacherCallInformationByDate = async (studentNo, lessonDate) => {

    const result = await GetTeacherCallInformationByDate(studentNo, lessonDate);

    if(result.status === 200 && result.content.length > 0) {
      let resultContent = JSON.parse(result.content).teacher;
      setCallTeacherInformation(callTeacherInformation => resultContent);
    }
  }

  //Term Schedule modalda ders notları başlıklarını index'e göre yazdıran fonksiyon
  const getStatisticsTitle = (title) => {
    if (title === "Speaking Grammar Usage") {
      return strings.member.member_detail.terms.operation_modal.speaking_grammar_usage;
    } else if (title === "Speaking Vocabulary Usage") {
      return strings.member.member_detail.terms.operation_modal.speaking_vocabulary_usage;
    } else if (title === "Speaking Pronunciation") {
      return strings.member.member_detail.terms.operation_modal.speaking_pronunciation;
    } else if (title === "Listening Comprehension") {
      return strings.member.member_detail.terms.operation_modal.listening_comprehension;
    } else if (title === "Reading Vocabulary Awareness") {
      return strings.member.member_detail.terms.operation_modal.reading_vocabulary_awareness;
    } else if (title === "Reading Pronunciation") {
      return strings.member.member_detail.terms.operation_modal.reading_pronunciation;
    }
  };

  const handleLetterInfoClick = (letterNote, index) => {
    letterNote = parseInt(letterNote) > 7 ? parseInt(letterNote) : parseInt(letterNote) + 1
    let message = getMessage(letterNote, index);
    setModalMessage(message)
    setDetailModal(true);
  };

  //lessonNote'e göre not açıklamasını getiren fonksiyon
  const getMessage = (letterNote, index) => {
    const notes = ["one", "two_three", "four_five", "six_seven", "eight_nine", "ten"];
    const lessonInfo = strings.member.member_detail.terms.operation_modal.letter_note_informations;
    const lessonKeys = Object.keys(lessonInfo);

    if (index < 0 || index >= lessonKeys.length) return;

    const lessonKey = lessonKeys[index];
    const noteIndex = letterNote === 1 ? 0
      : letterNote === 2 || letterNote === 3 ? 1
        : letterNote === 4 || letterNote === 5 ? 2
          : letterNote === 6 || letterNote === 7 ? 3
            : letterNote === 8 || letterNote === 9 ? 4
              : 5;

    return lessonInfo[lessonKey][notes[noteIndex]];
  }

  const closeModal = () => {
    setDetailModal(detailModal => false);
  }

  //Yanlış ve doğru telaffuz edilen verileri filtreler
  useEffect(() => {
    if (data) {
      const tmpMistakeData = data?.corrections?.filter(item => item.mistake !== "");
      const tmpCorrectData = data?.corrections?.filter(item => item.mistake === "");

      setMistakeData(tmpMistakeData);
      setCorrectData(tmpCorrectData);
    }

  }, [data])

  useEffect(() => {
    if (scheduleId) {
      getLessonDetail(scheduleId);
    }

    //status'e göre tabların açılma durumunu kontrol eder
    if (lessonStatus === 1) {
      setIsOpenLessonResultTab(isOpenLessonResultTab => false);
      setIsOpenBookTab(isOpenBookTab => true);
    }
  }, [scheduleId]);

  //getAndUpdateLessonCorrections servisine  istek atmak için kullanılan model oluşturulur.
  useEffect(() => {
    if (data?.corrections?.length > 0) {
      let mistakeString = ""
      const filteredMistakeData = data?.corrections.filter((item) => item.correction === "")

      filteredMistakeData.map(({ mistake }, index) => (
        mistakeString += filteredMistakeData.length - 1 !== index ? mistake + "#" : mistake
      ))

      filteredMistakeData.length > 0 ? getAndUpdateLessonCorrections(mistakeString, data?.lessonResultId) : setCorrectionLoading(loading => false);
    }
  }, [data])

  useEffect(() => {
    (studentNo && lessonDate) && getTeacherCallInformationByDate(studentNo, fullDateToShortDate2(lessonDate));
  }, [studentNo, lessonDate]);

  return (
    <>
      <div className="flex flex-col text-start gap-5">
        <div className="flex flex-col items-center justify-center gap-1">
          <p className="font-bold text-[15px]">
            {strings.member.member_detail.terms.operation_modal.lesson_detail} ({ISOStringToDate2(lessonDate)})
          </p>
          <MemberInformations memberId={memberId} />
        </div>
        {loading ? (
          <Loading />
        ) : (
          <div className="w-full">
            <nav className="flex gap-x-5 sm:gap-x-10 flex-wrap mb-5">
              {lessonStatus !== TermScheduleStatusType.Active && (
                <button
                  onClick={() => {
                    closeAllTabs();
                    setIsOpenLessonResultTab(true);
                  }}
                  className={`member-nav-button ${isOpenLessonResultTab && "member-nav-button-active"}`}
                >
                  {strings.member.member_detail.terms.operation_modal.lesson_result}
                </button>
              )}
              {data && (lessonStatus !== TermScheduleStatusType.Active && data.callTimes !== null && data.callTimes.length !== 0) && (
                <button
                  onClick={() => {
                    closeAllTabs();
                    setIsOpenLogsTab(true);
                  }}
                  className={`member-nav-button ${isOpenLogsTab && "member-nav-button-active"}`}
                >
                  {strings.member.member_detail.header.content.logs}
                </button>
              )}
              {(data && lessonStatus !== TermScheduleStatusType.Active) && (
                  <button
                      onClick={() => {
                        closeAllTabs();
                        setIsOpenTeacherNetworkLogsTab(true);
                      }}
                      className={`member-nav-button ${isOpenTeacherNetworkLogsTab && "member-nav-button-active"}`}
                  >
                    {strings.member.member_detail.terms.operation_modal.foreign_call_logs.title}
                  </button>
              )}
              {data && (data.lessonRecords[0] !== "unkown" && data.lessonRecords.length !== 0) && (
                <button
                  onClick={() => {
                    closeAllTabs();
                    setIsOpenVoiceRecordTab(true);
                  }}
                  className={`member-nav-button ${isOpenVoiceRecordTab && "member-nav-button-active"}`}
                >
                  {strings.member.member_detail.terms.operation_modal.voice_record}
                </button>
              )}
              {data && data.bookContent !== null && (
                <button
                  onClick={() => {
                    closeAllTabs();
                    setIsOpenBookTab(true);
                  }}
                  className={`member-nav-button ${isOpenBookTab && "member-nav-button-active"}`}
                >
                  {strings.member.member_detail.terms.operation_modal.book}
                </button>
              )}
            </nav>

            {isOpenLessonResultTab && (
              <div className={`h-[70vh] -mt-5 pt-5 pr-3 max-2xl:overflow-y-scroll ${Object.keys(data).length > 8 ? "2xl:h-[75vh] 2xl:overflow-y-scroll" : ""}`}>
                <div className="flex flex-col md:flex-row gap-5">
                  <div className="flex flex-col gap-5 w-full">
                    {callTeacherInformation.length > 0 && (
                      <div className="flex flex-col gap-1">
                        <div className="font-bold max-w-max relative pb-2">
                          {strings.member.member_detail.terms.operation_modal.call_teacher}
                          <div className="absolute bottom-0 left-0 right-0 h-[3px] bg-blue rounded"></div>
                        </div>
                        <p>{callTeacherInformation}</p>
                      </div>
                    )}
                    <div className="flex flex-col gap-1">
                      <div className="font-bold max-w-max relative pb-2">
                        {strings.member.member_detail.terms.operation_modal.remark_date}
                        <div className="absolute bottom-0 left-0 right-0 h-[3px] bg-blue rounded"></div>
                      </div>
                      <p>{ISOStringToDate2(data?.remarkDate)}</p>
                    </div>
                    <div className="flex flex-col gap-1">
                      <div className="font-bold max-w-max relative pb-2">
                        {strings.member.member_detail.terms.operation_modal.teacher_comment_about_lesson}
                        <div className="absolute bottom-0 left-0 right-0 h-[3px] bg-blue rounded"></div>
                      </div>
                      <div dangerouslySetInnerHTML={{ __html: data?.remark?.replace(/\\n/g, '<br />') }} />
                    </div>
                  </div>
                  <div className="flex flex-col w-full">
                    <div className="font-bold max-w-max relative pb-2 mt-4 mb-2 lg:mt-0">
                      {strings.member.member_detail.terms.operation_modal.teacher_suggestion_about_lesson}
                      <div className="absolute bottom-0 left-0 right-0 h-[3px] bg-blue rounded"></div>
                    </div>
                    {lessonStatus === 3 ? (
                      <p>{strings.member.member_detail.terms.operation_modal.student_missed_lesson}</p>
                    )
                      :
                      (correctionLoading ? (
                        <Loading />
                      ) :
                        (
                          responseError ? (
                            <div className="py-4 min-w-full text-center">{strings.member.member_detail.terms.operation_modal.response_error}</div>
                          ) : (
                            <>
                              {Object.keys(mistakeData).length > 0 && (
                                <p className="font-bold my-2 px-2 bg-[#ededed] rounded-[3px]">{strings.member.member_detail.terms.operation_modal.mispronunciation}</p>
                              )}
                              {mistakeData.map(({ mistake, correction }, index) => (
                                <div key={index} className="mb-2">
                                  <div className="flex gap-2 items-center">
                                    <img
                                      src={Multiply}
                                      className="w-3 h-3"
                                      alt="multiply"
                                    />
                                    <p className="">{mistake}</p>
                                  </div>
                                  <div className="flex gap-2 items-center">
                                    <img
                                      src={Accept2}
                                      className="w-3 h-3"
                                      alt="multiply"
                                    />
                                    <p className="font-bold">{correction}</p>
                                  </div>
                                </div>
                              ))}
                              {Object.keys(correctData).length > 0 && (
                                <p className="font-bold my-2 px-2 bg-[#ededed] rounded-[3px]">{strings.member.member_detail.terms.operation_modal.correct_pronunciation}</p>
                              )}
                              {correctData.map(({ correction }, index) => (
                                <div key={index} className="mb-2">
                                  <div className="flex gap-2 items-center">
                                    <img src={Accept2} className="w-3 h-3" alt="accept" />
                                    <p>{correction}</p>
                                  </div>
                                </div>
                              ))}
                            </>
                          )
                        ))}
                  </div>
                </div>

                <div className="max-w-max flex flex-wrap gap-5 mt-5 mx-auto justify-center">
                  {data &&
                    data.lessonNotes.map(({ name, letterNote }, index) => (
                      <div key={index} className="font-bold max-w-[70px] flex flex-col justify-end text-center">
                        <p className="mb-2">{getStatisticsTitle(name)}</p>
                        <div
                          className="letter-note cursor-pointer relative group"
                        >
                          {(letterNote === "" || letterNote === '0')? (
                            <span>-</span>
                          ) : isNaN(letterNote) ? (
                            <span className="text-blue text-[30px]">{letterNote}</span>
                          ) : (
                            <div
                              className="align-bottom"
                              onClick={() => handleLetterInfoClick(letterNote, index)}
                            >
                              <span className="text-blue group-hover:text-secondary text-[18px]">{letterNote = parseInt(letterNote) > 7 ? parseInt(letterNote) : parseInt(letterNote) + 1}</span>
                              <span className="text-blue group-hover:text-secondary">/10</span>
                              <div className="tooltip-top -top-[38px] font-normal">
                                <p>{strings.member.member_detail.terms.operation_modal.click_for_lesson_detail}</p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
            {isOpenLogsTab && (
              <div className="-mt-4 pt-4 overflow-y-auto max-h-[65vh]">
                {data &&
                  data.callTimes.map((callTime, index) => (
                    <div key={index}>
                      {index === 0 && <hr className="mb-3" />}
                      <p className="font-bold ml-3 mb-3">
                        {addTimeZone(callTime, employee.TimeZone)}
                      </p>
                      <hr className="mb-3" />
                    </div>
                  ))}
              </div>
            )}
            {isOpenVoiceRecordTab && (
                <>
                    {data && data.lessonRecords.map((audio, index) => (
                      <div key={index} className="-mt-4 pt-4">
                        <audio
                            className="w-full h-[40px]"
                            controls
                            controlsList="nodownload noplaybackrate"
                            onClick={onclick}
                        >
                          <source
                              src={`http://records.clickivo.com/${fullDateToShortDate2(lessonDate)}/${audio}`}
                              type="audio/mp3"
                          />
                          Your browser does not support the audio element.
                        </audio>
                      </div>
                    ))}
                </>
            )}
            {isOpenBookTab && (
              <div className="relative h-[70vh] overflow-hidden">
                <div className="">
                  <img
                    className="w-full"
                    src={data && data.bookContent}
                    alt=""
                  />
                  <a
                    className="absolute inset-0 z-10 flex justify-center items-center"
                    target="_blank"
                    rel="noreferrer"
                    href={data && data.bookContent}
                  >
                    <img src={ZoomIn} width="64" height="64" alt="" />
                  </a>
                </div>
              </div>
            )}

            {isOpenTeacherNetworkLogsTab &&
              <TeacherNetworkLog
                lessonDate={lessonDate}
                studentNo={studentNo}
              />
            }
          </div>
        )}
      </div>

      {/* --- Modal --- */}
      <Modal modal={detailModal} setModal={setDetailModal} classes={{ modal: "h-[400px] max-w-[850px]", overlay: "-mt-[44px]" }} >
        <LetterNoteInformationModal modalMessage={modalMessage} closeModal={closeModal} />
      </Modal>
    </>
  );
};

export default LessonDetailModal;
