import { fetchApi } from '../index';

export const DiscountCodeFilter = async (model) => {
    
    const url = '/member/v2/discount_code/filter/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const ChangeDiscountCodeStatus = async (campaignId) => {

    const url = '/member/v2/campaign/toggle_discount_campaign_type_status/';

    const payload = {
        campaignId: campaignId
    };

    return await fetchApi(url, payload);
}