import React, { useState, useEffect } from 'react'
import { useLocalization } from '../../hooks/Utils/useLocalization';
import { PageTitle, Pagination, SearchBar, SearchCount, Loading, showTopAlert } from "../../components";
import { ReminderTable, ReminderDetailSearch } from "./components";
import { stringToDate } from '../../components/Utility';
import useAuth from '../../hooks/Log/useAuth';
import { ReminderFilterV3 } from '../../services/Reminder/ReminderService';

const Reminder = () => {

  const strings = useLocalization();
  const { employee } = useAuth();
  // Pagination için kullanılacak.
  const limit = parseInt(process.env.REACT_APP_TABLE_ROW_LIMIT);
  const [start, setStart] = useState(0);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(20);
  // Pagination için kullanılacak.
  const [data, setData] = useState([]);

  const [detailSearch, setDetailSearch] = useState({
    beginDate: null,
    endDate: null,
    brandId: parseInt(employee.BrandId),
    salesPersonId: null,
    productivityPersonId: null,
    reminderReasonId: -1,
    searchText: ''
  });

  //Arama verileri değiştiğinde değerleri sıfırlıyoruz
  const resetValue = () => {
    setStart(start => 0);
    setCurrentPage(currentPage => 1);
    setLoading(loading => true);
  };

  //select değişiklikleri bu alandan yönetiliyor
  const changeDetail = (values) => {
    setDetailSearch({ ...detailSearch, ...values})
    resetValue();
  };

  //input değişiklikleri bu alandan yönetiliyor
  const changeValue = (value) => {
    setDetailSearch({ ...detailSearch, searchText: value});
    resetValue();
  };

  const getReminder = async () => {
    const model = {
      start: start,
      length: limit,
      beginDate: detailSearch.beginDate ? stringToDate(detailSearch.beginDate) : null,
      endDate: detailSearch.endDate ? stringToDate(detailSearch.endDate, 23, 59, 59) : null,
      brandId: detailSearch.brandId,
      salesPersonId: detailSearch.salesPersonId,
      productivityPersonId: detailSearch.productivityPersonId,
      reminderReasonId: detailSearch.reminderReasonId,
      employeeId: employee.EmployeeId,
      searchText: detailSearch.searchText.length !== 0 ? detailSearch.searchText : null
    }

    const result = await ReminderFilterV3(model);

    if(result.status === 200){
      let resultContent = JSON.parse(result.content);

      setData(data => resultContent.data);
      setTotalRecord(totalRecord => resultContent.recordsTotal);
      setLoading(loading => false);
    } else {
      showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
    }
  }

  //sayfa değiştikçe bilgileri yeniden çağırıyoruz
  useEffect(() => {
    if (totalRecord !== 0) {
      getReminder();
    }
  }, [start])

  useEffect(() => {

      let abortController = new AbortController();

      getReminder();

      return () => {
          abortController.abort();
      }
  }, [detailSearch])

  return (
    <>
      <ReminderDetailSearch changeDetail={changeDetail} loading={loading} /> 
      <SearchBar changeValue={changeValue} placeHolder={strings.search_bar.search_remider} isDisabled={loading} />
      {!loading && <SearchCount count={totalRecord} />}
      <PageTitle title={strings.navbar.reminders.title} />
      {loading ? 
        (
          <Loading />
        )
        :
        (
          <>
            <ReminderTable data={data} />
            <Pagination
              totalCount={totalRecord}
              limit={limit}
              start={start}
              setStart={setStart}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              setLoading={setLoading}
              loadScreen={true}
            />
          </>
        )
      }
    </>
  )
}

export default Reminder;