import React, { useEffect, useRef, useState } from 'react'
import { useLocalization } from '../../hooks/Utils/useLocalization';
import { EmployeeUpdatePassword } from '../../services/Auth/AuthService';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { url } from '../../routes/utility';
import { showCenteredAlert, showTopAlert } from '../../components';

const UpdatePassword = () => {

    const strings = useLocalization();
    const navigate = useNavigate();
    const location = useLocation();
    const isFromTruePage = location.state?.from?.includes("/auth/verifycode") || false;

    const employeeId = location.state?.employeeId.replace(/["']/g, "");

    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const passwordErrorRef = useRef();
    const passwordError2Ref = useRef();

    const visibilityRef = useRef();
    const visibilityOffRef = useRef();
    const inputRef = useRef();

    const againVisibilityRef = useRef();
    const againVisibilityOffRef = useRef();
    const againInputRef = useRef();

    const checkPassword = password => {
        return (password.length >= 4);
    }

    const togglePassword = () => {
        visibilityRef.current.classList.toggle('hidden');
        visibilityOffRef.current.classList.toggle('hidden');
        inputRef.current.type = inputRef.current.type === "password" ? "text" : "password"
    }

    const toggleAgainPassword = () => {
        againVisibilityRef.current.classList.toggle('hidden');
        againVisibilityOffRef.current.classList.toggle('hidden');
        againInputRef.current.type = againInputRef.current.type === "password" ? "text" : "password"
    }

    const submitHandle = async e => {
        e.preventDefault();

        //tüm hataları sıfırla
        passwordErrorRef.current.innerText = '';
        passwordError2Ref.current.innerText = '';

        const passwordStatus = checkPassword(password);
        const password2Status = checkPassword(password2);

        if ((passwordStatus && password2Status) && (password === password2)) {

            const result = await EmployeeUpdatePassword(employeeId, password);

            //Şifre değişikliği başarılı ise
            if (result.status === 200) {
                showCenteredAlert(strings.auth.update_password.success.title, strings.auth.update_password.success.message , "success");
                navigate(url("auth.login"));
            } else {
                //Şifre değişikliği başarısız ise
                showTopAlert(strings.errors.an_error_occurred+ " / " + result.content, "error");
            }

        } else {

            if (password !== password2) {
                showTopAlert(strings.errors.passwords_cannot_be_different, "error");
            }

            // Şifre karakter sayısı ile ilgili hata varsa ekrana yaz
            passwordErrorRef.current.innerText = !passwordStatus ? strings.errors.password_error : '';
            passwordError2Ref.current.innerText = !password2Status ? strings.errors.password_error : '';


        }
    }

    //Doğru sayfadan gelinmediyse sayfadan ayrıl
    useEffect(() => {
        if(!isFromTruePage) {
            navigate(url("home"));
        }
    }, [])

    return (
        <>
            <form className="w-full flex justify-center" onSubmit={submitHandle}>
                <div
                    className="w-[400px] animate-fadeIn p-[25px] md:py-[60px] rounded-[5px] block bg-white border border-[#ebecec] shadow-form mx-5 mt-5 md:mt-0">
                    <h3 className="font-bold text-center text-[19px] md:mb-[25px]">{strings.auth.update_password.title}</h3>
                    <div className="login-form">
                        <div className="flex flex-col px-[10px] pb-2 mb-2 relative">
                            <label className="text-[85%]" htmlFor="password" >{strings.auth.update_password.your_password}</label>
                            <input ref={inputRef} id="password" type="text" className="form-input" name="password" onChange={(e) => setPassword(password => e.target.value)} />
                            <span ref={visibilityRef} onClick={togglePassword}
                                className="toggle-password mt-[11px] material-symbols-outlined absolute right-5 top-[34px] -translate-y-1/2 text-xl cursor-pointer">
                                visibility
                            </span>
                            <span ref={visibilityOffRef} onClick={togglePassword}
                                className="hidden toggle-password mt-[11px] material-symbols-outlined absolute right-5 top-[34px] -translate-y-1/2 text-xl cursor-pointer">
                                visibility_off
                            </span>
                            <span ref={passwordErrorRef} className="text-danger field-validation-valid"></span>
                        </div>
                        <div className="flex flex-col px-[10px] pb-0 relative">
                            <label className="text-[85%]" htmlFor="password2" >{strings.auth.update_password.your_password2}</label>
                            <input ref={againInputRef} id="password2" type="text" className="form-input" name="password2" onChange={(e) => setPassword2(password2 => e.target.value)} />
                            <span ref={againVisibilityRef} onClick={toggleAgainPassword}
                                className="toggle-password mt-[11px] material-symbols-outlined absolute right-5 top-[34px] -translate-y-1/2 text-xl cursor-pointer">
                                visibility
                            </span>
                            <span ref={againVisibilityOffRef} onClick={toggleAgainPassword}
                                className="hidden toggle-password mt-[11px] material-symbols-outlined absolute right-5 top-[34px] -translate-y-1/2 text-xl cursor-pointer">
                                visibility_off
                            </span>
                            <span ref={passwordError2Ref} className="text-danger field-validation-valid"></span>
                        </div>
                        <div className=" px-[10px] pt-[10px] md:pt-[25px]">
                            <button type="submit" className="btn btn-primary w-full">{strings.auth.update_password.button}</button>
                        </div>
                        <div className="flex justify-center px-3 pb-2 pt-4">
                            <Link to={url("auth.login")} type="button" className="text-blue hover:underline">{strings.auth.forgot_password.login}</Link>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default UpdatePassword