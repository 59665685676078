import classNames from 'classnames';
import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';
import {Accept} from '../../../../../../../assets/img';
import {Dropdown, DropdownSearch, LargeButton, PageStep, PageTitle} from '../../../../../../../components';
import {formatPrice, handleCheckbox} from '../../../../../../../components/Utility';
import {AdditionalRightType} from '../../../../../../../consts/Constants';
import {useLocalization} from '../../../../../../../hooks/Utils/useLocalization';
import useMemberStatus from '../../../../../../../hooks/Member/useMemberStatus';
import usePrimeData from '../../../../../../../hooks/Utils/usePrimeData';
import {GetBankInfos, GetBankPoses} from '../../../../../../../services/Bank/BankService';
import {GetMemberBrandId} from '../../../../../../../services/Member/MemberService';
import {GetAdditionalServiceProducts} from '../../../../../../../services/Product/ProductService';
import {Accordion, CreditCard, PriceShape} from '../../../../../components';

const AdditionalService = () => {


  const strings = useLocalization();
  const location = useLocation();
  const firstVisit = useRef(true);
  const language = useSelector(state => state.localizationStore.language);

  const { memberId } = useParams() || false;
  const primeData = usePrimeData();
  const memberStatus = useMemberStatus(memberId) || false;
  const productId = location.state?.productId || false;
  const termId = location.state?.termId || false;

  const [step, setStep] = useState(1);
  
  // step 1 başlangıç
  const accordionRef = useRef();
  const plusSignRef = useRef();
  const minusSignRef = useRef();

  const [isClick, setIsClick] = useState(false);

  const title = strings.member.member_detail.sales_operations.registiration_title + " / " + strings.member.member_detail.terms.operation_modal.additional_service;
  
  const firstCategory = { id: -1, name: strings.member.member_detail.terms.operation_modal.choose_category };
  const categories = AdditionalRightType.map((rightType) => ({ id: rightType.id, name: rightType[language]}));
  const categoryList = [firstCategory, ...categories];

  const firstAdditionalPackage = { id: -1, name: strings.member.member_detail.terms.operation_modal.choose_additional_service_package };
  const [additionalPackageList, setAdditionalPackageList] = useState([]);

  const [brandId, setBrandId] = useState(-1);

  const [category, setCategory] = useState(firstCategory);
  const [additionalPackage, setAdditionalPackage] = useState(firstAdditionalPackage);

  const [selectedProduct, setSelectedProduct] = useState({});

  //Kullanıcının brandId'sini getirir.
  const getMemberBrandId = async (memberId) => {

    const result = await GetMemberBrandId(memberId);

    if(result.status === 200){
      setBrandId(brandId => parseInt(result.content));
    }
  }

  //Kategoriye göre ek hizmet paketlerini getirir.
  const getAdditionalServiceProducts = async () => {
    
    const model = {
      productCategoryTitle: category.id,
      brandId: brandId,
      currentProductId: productId
    };

    const result = await GetAdditionalServiceProducts(model);
    
    let arrayList = [];

    if(result.status === 200){
      let resultContent = JSON.parse(result.content);
      
      resultContent?.map((item) => {
        arrayList.push({id: item.value, name: item.text });
      })
    }
    setAdditionalPackageList([firstAdditionalPackage, ...arrayList]);
  }

  useEffect(() => {
    if(memberId){
      getMemberBrandId(memberId);
    }
  }, []);

  useEffect(() => {
    setAdditionalPackage(firstAdditionalPackage);
    if(category.id !== -1){
      getAdditionalServiceProducts();
    }
  }, [category]);

  useEffect(() => {
    if(additionalPackage.id !== -1){
    } else {
      setSelectedProduct({});
    }
  }, [additionalPackage]);
  // step 1 son

  // step 2 başlangıç

  //Sayfa içerisindeki tab'ları kontrol eder
  //0 Kredi Kartı
  //1 Havale
  //2 Alışveriş Kredisi
  const [tab, setTab] = useState(0);

  const [cardholderName, setCardholderName] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [cardExpiration, setCardExpiration] = useState('');
  const [cardCvv, setCardCvv] = useState('');
  const [bank, setBank] = useState(0);
  const [isRecurringPayment, setIsRecurringPayment] = useState(false);
  const [paymentOption, setPaymentOption] = useState(0);

  const [bankPosList, setBankPosList] = useState({});
  const [bankInfoList, setBankInfoList] = useState({});

  //Banka pos listesi getiriliyor
  const getBankPosList = async () => {
    const result = await GetBankPoses();

    if(result.status === 200){
      let resultContent = JSON.parse(result.content);
      setBankPosList(bankPosList => resultContent);
    }
  }

  //Banka marka listesi getiriliyor
  const getBankInfoList = async () => {
    const result = await GetBankInfos();

    if(result.status === 200){
      let resultContent = JSON.parse(result.content);
      setBankInfoList(bankInfoList => resultContent);
    }
  }

  //Taksitler listeleniyor
  const listInstallment = (maxInstallment, totalPrice, currencySymbol) => {
    let installmentPrice = formatPrice(paymentOption !== 0 ? totalPrice / paymentOption : totalPrice / maxInstallment);
    let installmentButtonList = [];
    for (let installment = 2; installment <= maxInstallment; installment++) {
      installmentButtonList.push(
        <button
          key={installment}
          className={classNames('installment-container', { 'bg-[#DCEAFC] border-blue text-blue': paymentOption === installment })}
          onClick={() => setPaymentOption(installment)}
        >
          <p className="font-bold">{installment + " " + strings.member.member_detail.sales_operations.payment_option}</p>
          <p className="text-[12px]">{installment <= paymentOption && currencySymbol + "" + installmentPrice}</p>
        </button>
      )
    }
    return installmentButtonList;
  }

  useEffect(() => {
    if(memberId){
      getBankPosList();
      getBankInfoList();
    }
  }, [])

  //Tekrarlı ödeme seçildiğinde otomatik olarak taksit seçeneğini 2 olarak belirler
  useEffect(() => {
    if(isRecurringPayment){
      setPaymentOption(1);
    } else {
      setPaymentOption(0);
    }
  }, [isRecurringPayment])

  //Tab değiştiğinde seçili bankayı ve taksit sayısını sıfırlıyor
  useEffect(() => {
    if(firstVisit.current === false){
      setBank(null);
      if(tab !== 0){
        setPaymentOption(null);
      }
      if(tab === 0) {
        setPaymentOption(0);
      }
    }
  }, [tab])

  // step 2 son

  return (
    <> 
      <div className="mt-5 lg:px-[10px] flex flex-col sm:flex-row gap-4 lg:gap-16 max-w-[1150px] mx-auto">
        <PageStep step={step} stepCount="2" />
      {step === 1 && (
        <div className="w-full">
          <PageTitle title={title} />
          <div className="shadow-content border mt-5 rounded-[5px]">
            <div className="bg-blue p-5 rounded-t-[5px] flex flex-col gap-3">
              <Dropdown 
                classnames="rounded md:max-w-full"
                data={categoryList}
                selectedData={category}
                setSelectedData={setCategory}
              />
              <DropdownSearch
                inputPlaceholder={strings.member.member_detail.terms.modal.search_additional_package} 
                classnames="rounded md:max-w-full"
                isDisabled={category.id === -1 && true}
                dark={true}
                data={additionalPackageList}
                selectedData={additionalPackage}
                setSelectedData={setAdditionalPackage}
              />
            </div>
            {/* <div>
              <div className="relative -mt-8">
                <div                
                  className="px-5 py-4 cursor-pointer text-dark-text bg-card rounded-t-md rounded-b-md flex justify-between items-center mx-5 shadow-paymentAccordion"
                  onClick={() => {
                    toggleTicketAccordion(accordionRef, plusSignRef, minusSignRef)
                    setIsClick(isClick => true);
                  }}
                >
                  <div
                    id="dropdown_section_01"
                    className="relative flex flex-col gap-2"
                  >
                    <div className="flex items-center">
                      <span className="material-symbols-outlined text-[28px] pr-4">
                        tune
                      </span>
                      <p>{strings.member.member_detail.sales_operations.discount_campaign_apply}</p>
                    </div>
                  </div>
                  <span
                    ref={plusSignRef}
                    className="material-symbols-outlined font-bold text-base cursor-pointer"
                  >
                    add
                  </span>
                  <span
                    ref={minusSignRef}
                    className="material-symbols-outlined font-bold text-base hidden cursor-pointer"
                  >
                    remove
                </span>
                </div>
                <div 
                  ref={accordionRef}
                  className="max-h-0 mx-5 px-4 gap-x-4 gap-y-5 flex-wrap max-md-lg:justify-center border-[#ebebeb] rounded-b-md animate-fadeOut transition-max-height duration-500 shadow-paymentAccordion"
                  >
                  <div className={`${!isClick ? 'hidden' : ''} flex flex-col md:flex-row gap-3 w-full`}>
                    <Dropdown 
                      classnames="md:max-w-full"
                      data={campaignList}
                      selectedData={campaign}
                      setSelectedData={setCampaign}
                    />
                    <Dropdown 
                      classnames="md:max-w-full"
                      data={discountList}
                      selectedData={discount}
                      setSelectedData={setDiscount}
                    />
                  </div>
                </div>
              </div>
            </div> */}
            <div className="flex flex-col xl:flex-row xl:justify-between mx-5 items-center my-10 gap-10 xl:gap-2">
              <ul className="flex flex-col gap-3">
                <li className="flex gap-2 2xl:gap-3">
                  <img className="w-5 h-5" src={Accept} alt="" />
                  <p className="text-[#6c757d]">
                    <span className="font-bold">{strings.member.member_detail.sales_operations.max_payment_option_count}: {selectedProduct?.maxInstallment}</span>
                  </p>
                </li>
                <li className="flex gap-2 2xl:gap-3">
                  <img className="w-5 h-5" src={Accept} alt="" />
                  <p className="text-[#6c757d]">
                    <span className="font-bold">{strings.member.member_detail.sales_operations.discount_price}: {selectedProduct?.currencySymbol}{selectedProduct?.price}</span>
                  </p>
                </li>
              </ul>
              <div className="min-w-max">
                <div className="w-[230px] h-[180px] flex flex-col justify-center items-center">
                  <PriceShape 
                    children={
                      <div className="w-full h-full flex flex-col items-center justify-center text-center">
                        <p className="font-bold text-blue text-[31px]">
                          <span>{Object.keys(selectedProduct).length > 0 ? selectedProduct?.currencySymbol + "" + selectedProduct?.totalPrice : "0.00"}</span>
                        </p>
                        <p className="font-bold text-[14px] text-[#8b008b]">
                          %{Object.keys(selectedProduct).length > 0 ? selectedProduct?.discountRate : "0"} {strings.member.member_detail.sales_operations.discount}
                        </p>
                      </div>
                    } 
                  />
                  
                </div>
              </div>
              <ul className="flex flex-col gap-3 min-w-[170px]">
                <li className="flex gap-2 2xl:gap-3">
                  <img className="w-5 h-5" src={Accept} alt="" />
                  <p className="text-[#6c757d]">
                    <span className="font-bold">{strings.member.member_detail.sales_operations.no_discount_price}: {selectedProduct?.currencySymbol}{selectedProduct?.discountPrice}</span>
                  </p>
                </li>
                <li className="flex gap-2 2xl:gap-3">
                  <img className="w-5 h-5" src={Accept} alt="" />
                  <p className="text-[#6c757d]">
                    <span className="font-bold">{strings.member.member_detail.sales_operations.kdv}: {selectedProduct?.currencySymbol}{selectedProduct?.taxPrice}</span>
                  </p>
                </li>
              </ul>
            </div>
            {Object.keys(selectedProduct).length > 0 && (
              <button
                className={classNames('button gap-3 font-bold text-blue border-blue my-7 rounded mx-auto w-[calc(100%-40px)] bg-blue/20 transition-colors duration-500', {'hover:!bg-blue !bg-white hover:text-white focus:text-white focus:!bg-blue' : additionalPackage.id !== -1})}
                disabled={additionalPackage.id === -1 ? true : false}
                onClick={() => setStep(2)}
              >
                {strings.button.pay}
              </button>
            )}
          </div>
        </div>
      )}
      {step === 2 && (
        <div className="w-full relative sm:w-[calc(100%-114px)]">
          <button 
            className="absolute top-[-90px] sm:top-[14px] right-4 flex items-center hover:text-blue focus:text-blue"
            onClick={() => setStep(1)}
          >
            <span id="back" className="material-symbols-outlined cursor-pointer">
                arrow_back_ios
            </span>
            <label className="cursor-pointer font-bold" htmlFor="back">{strings.pagination.previous}</label>
          </button>
          <PageTitle title={strings.member.member_detail.sales_operations.payment_operations} />
          <ul id="tabs" className="flex gap-x-5 sm:gap-x-10 flex-wrap">
              <li className={classNames('member-nav-button', {'member-nav-button-active' : tab === 0})}>
                  <button onClick={() => setTab(0)}>{strings.member.member_detail.sales_operations.credit_card}</button>
              </li>
              <li className={classNames('member-nav-button', {'member-nav-button-active' : tab === 1})}>
                  <button onClick={() => setTab(1)}>{strings.member.member_detail.sales_operations.transfer}</button>
              </li>
              <li className={classNames('member-nav-button', {'member-nav-button-active' : tab === 2})}>
                  <button onClick={() => setTab(2)}>{strings.member.member_detail.sales_operations.shopping_credit}</button>
              </li>
          </ul>

          <div id="tab-contents">
              {tab === 0 && (
                <CreditCard 
                  cardNumber={cardNumber}
                  cardholderName={cardholderName}
                  cardExpiration={cardExpiration}
                  cardCvv={cardCvv}
                  setCardNumber={setCardNumber}
                  setCardholderName={setCardholderName}
                  setCardExpiration={setCardExpiration}
                  setCardCvv={setCardCvv}
                />
              )}
          </div>
          <div className="flex flex-col gap-5 mt-5">
              <div className="flex items-center gap-2">
                <p className="text-[22px]">{Object.keys(selectedProduct).length > 0 ? selectedProduct?.currencySymbol + "" + selectedProduct?.totalPrice : "0.00"}</p>
                <div className="group relative flex">
                    <span className="material-symbols-outlined cursor-pointer">
                      info
                    </span>
                    <div className="tooltip-top">
                        <p>{strings.member.member_detail.sales_operations.kdv_include}</p>
                    </div>
                </div>
              </div>
              {tab === 2 && (
                <div id="shopping_credit" className="relative">
                  <Accordion 
                    outSideClose={true}
                    title="Fibabank"
                    button={
                      <LargeButton 
                        iconName="done"
                        iconClassnames="bg-green border-green"
                        iconText={strings.member.member_detail.sales_operations.complete_order}
                      />
                    }
                    content={
                      <>
                        <div className="w-full overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
                          <table className="min-w-full leading-normal text-header">
                            <thead>
                              <tr>
                                <th className="th text-center"><p className="min-w-max">{strings.member.member_detail.sales_operations.payment_option}</p></th>
                                <th className="th text-center"><p className="min-w-max">{strings.table.monthly_income} (₺)</p></th>
                                <th className="th text-center"><p className="min-w-max">{strings.table.monthly_total_cost_rate}</p></th>
                                <th className="th text-center"><p className="min-w-max">{strings.table.yearly_total_cost_rate}</p></th>
                                <th className="th text-center"><p className="min-w-max">{strings.table.total_payment} (₺)</p></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="bg-white hover:bg-[#f0f0f0]">
                                <td className="td text-center">10</td>
                                <td className="td text-center">10</td>
                                <td className="td text-center">10</td>
                                <td className="td text-center">10</td>
                                <td className="td text-center">10</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div id='show-button'></div>

                      </>
                    }
                  />
                </div>
              )}
              {tab === 0 && (
                <label className="flex justify-start items-start max-w-max">
                  <input
                    type="checkbox"
                    id="isActive1"
                    className="hidden"
                    onClick={() => setIsRecurringPayment(isRecurringPayment => !isRecurringPayment)}
                  />
                  <label
                    htmlFor="isActive1"
                    className={`${handleCheckbox(isRecurringPayment)} mr-2 w-5 h-5 rounded-[3px] cursor-pointer duration-500 flex items-center justify-center`}
                  >
                    <span className={classNames('text-inherit text-[20px] material-symbols-outlined animate-fadeIn font-bold', { 'hidden' : !isRecurringPayment})}>
                      done
                    </span>
                  </label>
                  <p className="font-bold">{strings.member.member_detail.sales_operations.recurring_payment}</p>
                </label>
              )}
              {tab !== 2 && (
                <div className="flex flex-col gap-1">
                    <p className="font-bold">{strings.member.member_detail.sales_operations.bank_options}</p>
                    <div className="flex gap-y-1 flex-wrap">
                      {/* Eğer Kredi Kartı bölümü seçiliyse pos listesi veriliyor */}
                      {tab === 0 && bankPosList && bankPosList.map((bankPos, index) => (
                        <button 
                          key={index} 
                          className={classNames('bank-container', { 'border-blue' : bankPos.bankPosId === bank })}
                          onClick={() => setBank(bankPos.bankPosId)}
                        >
                          <div className='group relative flex'>
                            <img src={process.env.REACT_APP_PROFILE_URL + bankPos.image} className='cursor-pointer h-full' alt="bank" />
                            <div className="tooltip-top !-top-[55px]">{primeData.localCompanyList.find(lc => lc.id === bankPos.brandLocalCompanyId).name}</div>
                          </div>
                        </button>
                      ))}
                      {/* Eğer Kredi Kartı bölümü seçiliyse pos listesi veriliyor */}

                      {/* Eğer havale bölümü seçiliyse banka listesi veriliyor */}
                      {tab == 1 && bankInfoList && bankInfoList.map((bankInfo, index) => (
                        <button 
                          key={index} 
                          className={classNames('bank-container', { 'border-blue': bankInfo.bankPosId === bank })}
                          onClick={() => setBank(bankInfo.bankPosId)}
                        >
                          <div className="group relative flex">
                            <img src={process.env.REACT_APP_PROFILE_URL + bankInfo.image} className="cursor-pointer h-full" />
                            <div className="tooltip-top !-top-[55px]">{primeData.localCompanyList.find(lc => lc.id === bankInfo.brandLocalCompanyId).name}</div>
                          </div>
                        </button>
                      ))}
                      {/* Eğer havale bölümü seçiliyse banka listesi veriliyor */}
                    </div>
                </div>
              )}
              {tab === 0 && (
              <div className="flex flex-col gap-1">
                <p className="font-bold">{strings.member.member_detail.sales_operations.payment_options}</p>
                <div className="flex gap-y-1 flex-wrap">
                  <button
                    className={classNames('installment-container', { 'hidden': isRecurringPayment }, { 'bg-[#DCEAFC] border-blue text-blue': paymentOption === 0 })}
                    onClick={() => setPaymentOption(0)}
                  >
                    <p className="font-bold">{strings.member.member_detail.sales_operations.one_shot}</p>
                    <p className="text-[12px]">{Object.keys(selectedProduct).length > 0 ? selectedProduct?.totalPrice + " " + selectedProduct?.currencySymbol : "0.00"}</p>
                  </button>
                  {selectedProduct?.maxInstallment && listInstallment(selectedProduct?.maxInstallment, selectedProduct?.totalPriceWithoutFormat, selectedProduct?.currencySymbol)}
                </div>
              </div>
              )}
              {tab !== 2 && (
                <button
                  className={classNames('button gap-3 font-bold text-blue border-blue my-7 rounded mx-auto w-[calc(100%-40px)] bg-blue/20 transition-colors duration-500', {'hover:!bg-blue !bg-white hover:text-white focus:text-white focus:!bg-blue' : (cardNumber && cardholderName && cardExpiration && cardCvv && bank !== 0)})}
                  disabled={cardNumber && cardholderName && cardExpiration && cardCvv && bank !== 0 ? false : true}
                >
                  {strings.button.complete_payment}
                </button>
              )}
          </div>
        </div>
      )}
      </div>
    </>
  )
};

export default AdditionalService;