import {useLocalization} from "../../hooks/Utils/useLocalization";
import {useLocation, useNavigate} from "react-router-dom";
import {Dropdown, DropdownMultiSelect, Input, Loading, PageTitle, showTopAlert} from "../../components";
import {DefaultImage} from "../../assets/svg";
import {ApplicationList, LanguageList, ParameterTypes, RequestTypes} from "../../consts/Constants";
import {ApplicationRowEditable, TranslationRowEditable} from "./components";
import React, {useEffect, useRef, useState} from "react";
import { NIL as NIL_UUID, v4 as uuid4 } from 'uuid';
import {GetUseCaseByIdService, UpdateUseCaseService} from "../../services/UseCase/UseCaseService";
import {createParameterObject, GetApplicationNameByApplicationId, handleCheckbox} from "../../components/Utility";
import {url} from "../../routes/utility";
import classNames from "classnames";
import addApplicationRow from "./components/CreateUseCase/Application/AddApplicationRow";

const UpdateUseCase = () => {

    const strings = useLocalization();
    const location = useLocation();
    const navigate = useNavigate();

    // Genel bilgiler alanındaki hataları göstermek için ref'ler oluşturuluyor.
    const titleEngErrorRef = useRef();
    const promptErrorRef = useRef();

    // Endpoint alanındaki hataları göstermek için ref'ler oluşturuluyor.
    const endpointErrorRef = useRef();
    const endpointTestErrorRef = useRef();
    const requestTypeErrorRef = useRef();
    const parametersErrorRef = useRef();

    const useCaseId = location?.state?.id || false;

    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);

    const [applications, setApplications] = useState([]);
    const [applicationImageUrl, setApplicationImageUrl] = useState("");
    const [titleEng, setTitleEng] = useState("");

    const [endpoint, setEndpoint] = useState("");
    const [endpointTest, setEndpointTest] = useState("");
    const firstRequestType = { id: -1, name: strings.detail_search_titles.choose_request_type };
    const [endpointRequestType, setEndpointRequestType] = useState(firstRequestType);
    const [endpointRequestBody, setEndpointRequestBody] = useState("");
    const [parameters, setParameters] = useState([]);
    const [isHaveParameter, setIsHaveParameter] = useState(false);

    const [prompt, setPrompt] = useState("");
    const [translations, setTranslations] = useState([]);

    const [isClickedUpdateButton, setIsClickedUpdateButton] = useState(false);

    // Güncellenecek veriler servisten getiriliyor.
    const getUseCaseDataById = async (useCaseId) => {

        const result = await GetUseCaseByIdService(useCaseId);

        if(result.status === 200) {
            const resultContent = JSON.parse(result.content);

            setData(data => resultContent);
            setLoading(loading => false);
        }
    }

    // Kullanıcı yeni bir çeviri verisi eklemek istediğinde bu fonksiyon kullanılıyor
    const addTranslationRow = () => {
        // Yeni bir translation objesi oluşturuluyor
        const model = {
            id: uuid4(),
            languageId: -1,
            title: "",
            information: ""
        };

        // Çeviriyi translations dizisine ekleyerek state'i güncelleniyor
        setTranslations([...translations, model]);
    }

    // Kullanıcı yeni bir uygulama verisi eklemek istediğinde bu fonksiyon kullanılıyor
    const addApplicationRow = () => {
        const firstApplication = { id: -1, name: strings.detail_search_titles.choose_application };

        // Yeni bir translation objesi oluşturuluyor
        const model = {
            id: uuid4(),
            applicationId: firstApplication,
            webPath: "",
            mobilePath: "",
            paidMember: false,
            nonPaidMember: false,
        };

        // Uygulamayı applications dizisine ekleyerek state'i güncelleniyor
        setApplications([...applications, model]);
    }

    // Genel bilgiler ve endpoint alanının validasyonunu kontrol eden fonksiyon.
    const validate = () => {
        let valid = true;

        titleEngErrorRef.current.innerText = "";
        promptErrorRef.current.innerText = "";
        requestTypeErrorRef.current.innerText = "";

        if(isHaveParameter) {
            parametersErrorRef.current.innerText = "";
            endpointErrorRef.current.innerText = "";
            endpointTestErrorRef.current.innerText = ""
        }
        if(applications.length === 0) {
            valid = false;
        }
        if(titleEng.length === 0) {
            valid = false;
            titleEngErrorRef.current.innerText = strings.errors.title_eng_null_error;
        }
        if(prompt.length === 0) {
            valid = false;
            promptErrorRef.current.innerText = strings.errors.prompt_null_error;
        }
        if((endpoint.length !== 0 || endpointTest.length !== 0) && (endpointRequestType.id === -1)) {
            valid = false;
            requestTypeErrorRef.current.innerText = strings.errors.request_type_null_error;
        }
        if(isHaveParameter) {
            if(parameters.length === 0) {
                valid = false;
                parametersErrorRef.current.innerText = strings.errors.parameters_null_error;
            }
            if(endpoint.length === 0) {
                valid = false;

                endpointErrorRef.current.innerText = strings.errors.endpoint_null_error;
            }
            if(endpointTest.length === 0) {
                valid = false;
                endpointTestErrorRef.current.innerText = strings.errors.endpoint_test_null_error;
            }
        }

        return valid;
    }

    // Uygulamalar alanının validasyonunu kontrol eden fonksiyon.
    const validateApplications = (applications) => {

        let valid = true;

        applications.length > 0 && (
            applications.map((app) => {

                if(app.applicationId === -1 || app.applicationId.id === -1) {
                    valid = false;
                }

                if(app.webPath === ""){
                    valid = false;
                }

                if(app.mobilePath === ""){
                    valid = false;
                }
            })
        )

        return valid;
    }

    // Translations alanının validasyonunu kontrol eden fonksiyon.
    const validateTranslations = (translations) => {

        let valid = true;

        translations.length > 0 && (
            translations.map((translation) => {

                if(translation.title === ""){
                    valid = false
                }
                if(translation.information === ""){
                    valid = false;
                }
                if(translation.languageId === -1) {
                    valid = false;
                }
            })
        )

        return valid;
    }

    /*
        * Servisten gelen veri ile kullanıcının girdiği veriler karşılaştırılıyor.
        * Eğer servisten gelen verilerden farklı bir veri varsa o verinin id'si null'a çektirilerek servise bu veriyi
          DB'ye kaydetmesini söylüyoruz.
    */
    const editApplicationsArrForService = () => {
        const tmpApplicationsArr = applications.map(item => {
            const found = data?.aiUseCaseApplications.some(entry => entry.id === item.id);
            if (!found) {
                return { ...item, id: null };
            }
            return item;
        });

        return tmpApplicationsArr;
    }

    /*
        * Servistene gelen bir imageUrl var ise id'si servisten gelen ile değiştiriliyor.
        * Hiç imageUrl yok ise id null'a çekilerek servise DB'ye kaydetmesi söyleniyor.
    */
    const editContentsArrForService = () => {
        let tmpContentsArr = [];
        if(data?.aiUseCaseContents?.length === 0) {

            if(applicationImageUrl.length !== 0) {
                tmpContentsArr.push(
                    {
                        id: null,
                        contentTypeId: 1,
                        content: applicationImageUrl
                    }
                );
            }
        } else {
            tmpContentsArr.push(
                {
                    id: data?.aiUseCaseContents[0]?.id,
                    contentTypeId: data?.aiUseCaseContents[0]?.contentTypeId,
                    content: applicationImageUrl
                }
            );
        }

        return tmpContentsArr;
    }

    /*
        * Kullanıcının veri girdiği translations arrayindeki tüm verilerin id'si null'a çekiliyor.
        * Servisten gelen veri ile karşılaştırma yapılıyor ve eğer languageId'leri eşleşiyorsa null olan Id değeri
          servisteki ile değiştiriliyor.
          * Bu sayede aynı veride güncelleme var ise servise bu durum iletilmiş oluyor.
        * Servisteki veri ile eşleşmeyen verilerin id'si null kalıyor ve servise verinin DB'ye kaydedilmesi söyleniyor.
    */
    const editTranslationsArrForService = () => {
        let tmpTranslationsArr = [];
        translations?.map((translation) => {
            tmpTranslationsArr.push({id: null, languageId: translation.languageId, title: translation.title, information: translation.information });
        })

        data?.aiUseCaseTranslations?.forEach((translation) => {
            const matchingApp = tmpTranslationsArr.find((updatedTranslation) => updatedTranslation.languageId === translation.languageId);
            if (matchingApp) {
                matchingApp.id = translation.id;
            }
        });

        return tmpTranslationsArr;
    }

    const submitHandle = async (e) => {
        e.preventDefault();
        setIsClickedUpdateButton(isClickedUpdateButton => true);

        if(validate() && validateTranslations(translations) && validateApplications(applications)) {
            /*
                Servisin genel olarak çalışma mantığı şu şekilde;
                    - aiUseCaseApplications, aiUseCaseContents, aiUseCaseTranslations verileri servisten bir array olarak geliyor.
                    - Kullanıcı yeni bir veri eklemek istiyorsa servise giden yeni verinin id'si !!!"null"!!! olmalıdır.
                    - Kullanıcı bir veri silmek istiyorsa servise silenen veri gönderilmez.
            */
            const model = {
                id: useCaseId,
                title: titleEng,
                aiUseCaseEndpointId: data.aiUseCaseEndpointId,
                aiUseCaseEndpointUrl: endpoint,
                aiUseCaseEndpointTestUrl: endpointTest,
                aiUseCaseEndpointRequestType: typeof endpointRequestType === 'object' ? endpointRequestType.id : endpointRequestType,
                aiUseCaseEndpointRequestBody: Object.keys(endpointRequestBody).length > 0 ? JSON.stringify(endpointRequestBody) : endpointRequestBody,
                aiUseCasePromptId: data.aiUseCasePromptId,
                aiUseCasePrompt: prompt,
                aiUseCaseApplications: editApplicationsArrForService(),
                aiUseCaseContents: editContentsArrForService(),
                aiUseCaseTranslations: editTranslationsArrForService()
            }

            const result = await UpdateUseCaseService(model);

            if(result.status === 200) {
                showTopAlert(strings.use_case.update.success_message, "success");
                navigate(url("usecase.list"));
            } else {
                showTopAlert(strings.errors.an_error_occurred, "error");
            }
        }
    }

    //Servisten kullanıcının şuanki verisi seçiliyor
    useEffect(() => {
        useCaseId && getUseCaseDataById(useCaseId);
    }, [useCaseId]);

    /* Endpoint */

    //Seçilen parametreye göre request body güncelleniyor
    useEffect(() => {
        isHaveParameter && setEndpointRequestBody(createParameterObject(parameters));
    }, [parameters, isHaveParameter]);

    //Parametre var mı? seçili değilse ilgili state'ler sıfırlanıyor
    useEffect(() => {
        if(!isHaveParameter) {
            setParameters(parameters => []);
            setEndpointRequestBody("");
        }
    }, [isHaveParameter])
    /* Endpoint */

    useEffect(() => {
        if(Object.keys(data).length > 0) {
            //applications dizisine yazmak için servisten sadece applicationId'ler bir diziye atılıyor.
            setApplications(applications => data?.aiUseCaseApplications);

            //Servisten gelen title değeri state'e atılıyor
            setTitleEng(titleEng => data.title);

            //Servisten gelen endpoint değeri state'e atılıyor
            setEndpoint(endpoint => data.aiUseCaseEndpointUrl);

            //Servisten gelen endpointTest değeri state'e atılıyor
            setEndpointTest(endpointTest => data.aiUseCaseEndpointTestUrl);

            //Servisten gelen endpointRequestType değeri state'e atılıyor
            data.aiUseCaseEndpointRequestType ? setEndpointRequestType(endpointRequestType => data.aiUseCaseEndpointRequestType) : setEndpointRequestType(endpointRequestType => -1);;

            let jsonRequestBody = data.aiUseCaseEndpointRequestBody.length !== 0 && JSON.parse(data.aiUseCaseEndpointRequestBody);
            data.aiUseCaseEndpointRequestBody.length !== 0 ? setParameters(Object.values(jsonRequestBody)) : setParameters([]);
            Object.keys(jsonRequestBody).length === 0 ? setEndpointRequestBody(endpointRequestBody => "") : setEndpointRequestBody(endpointRequestBody => jsonRequestBody);
            Object.keys(jsonRequestBody).length > 0 && setIsHaveParameter(true);

            //Servisten gelen content(Image Url) değeri state'e atılıyor
            setApplicationImageUrl(applicationImageUrl => data.aiUseCaseContents[0]?.content ? data.aiUseCaseContents[0]?.content : "");

            //Servisten gelen prompt değeri state'e atılıyor
            setPrompt(prompt => data.aiUseCasePrompt);

            //Servisten gelen çeviriler alanı state'e atılıyor
            const modifiedTranslations = data?.aiUseCaseTranslations?.map(item => {
                const { id, languageId, title, information } = item;
                return { id, languageId, title, information };
            });

            setTranslations(translations => modifiedTranslations);
        }
    }, [data]);

    //kullanıcının doldurduğu form valid ve güncelle butonuna basıldıysa formValid state'i güncelleniyor
    useEffect(() => {
        isClickedUpdateButton && validate();
    });

    return (
        <>
            <PageTitle title={strings.use_case.update.title} />
            {loading && (
                <Loading />
            )}
            {!loading && (
                <>
                    <div className="h-[100px] rounded-md mb-8">
                        <div className="flex justify-center">
                            <div className="relative mt-8 flex flex-col items-center">
                                <img
                                    className="w-[100px] h-[100px] shadow-profilePhoto rounded-full border-[2px]"
                                    src={!applicationImageUrl ? DefaultImage : applicationImageUrl}
                                    alt=""
                                />
                                <p className="text-lg font-bold text-center">{strings.form_info.application_image}</p>
                            </div>
                        </div>
                    </div>
                    <div className="p-5 max-xs:px-2">
                        <h5 className="font-bold text-[15px] pl-3 my-3">
                            {strings.form_info.general_informations}
                        </h5>
                        <hr />
                        <form onSubmit={submitHandle}>
                            {/* Genel Bilgiler */}
                            <div className="flex flex-col lg:flex-row lg:justify-between lg:gap-x-5">
                                <div className="mt-5 w-full lg:w-[32.5%]">
                                    <div className="flex flex-col px-[10px] pb-0">
                                        <label htmlFor="title-eng">
                                            {strings.form_info.title_english} <span className="text-red">*</span>
                                        </label>
                                        <Input
                                            name="title-eng"
                                            type="text"
                                            value={titleEng}
                                            onChange={(e) => setTitleEng(e.target.value)}
                                            classnames="md:max-w-[480px]"
                                        />
                                        <span ref={titleEngErrorRef} className="text-danger field-validation-valid ml-2 mt-1"></span>
                                    </div>
                                </div>
                                <div className="mt-5 w-full lg:w-[32.5%]">
                                    <div className="flex flex-col px-[10px] pb-0">
                                        <label htmlFor="application-image" className="min-w-max">{strings.form_info.application_image_url}</label>
                                        <Input
                                            name="application-image"
                                            type="text"
                                            value={applicationImageUrl ? applicationImageUrl : ""}
                                            onChange={(e) => setApplicationImageUrl(e.target.value)}
                                            classnames="md:max-w-[480px]"
                                        />
                                    </div>
                                </div>
                                <div className="mt-5 w-full lg:w-[32.5%]">
                                </div>
                            </div>
                            <div className="flex flex-col px-[10px] pb-0 w-full my-5">
                                <div className="flex gap-2 items-end">
                                    <label htmlFor="prompt">{strings.form_info.prompt} <span className="text-red">*</span></label>
                                    <div className="relative group">
                                        <span className="material-symbols-outlined cursor-pointer">info</span>
                                        <div className="tooltip-top !-top-[75px]">
                                            <p>{strings.use_case.create.prompt_tooltip_text}</p>
                                        </div>
                                    </div>
                                </div>
                                <textarea
                                    name="prompt"
                                    className={`border rounded-lg outline-0 focus:border-blue py-3 px-2 w-full`}
                                    rows="5"
                                    value={prompt ? prompt : ""}
                                    onChange={(e) => setPrompt(e.target.value)}
                                ></textarea>
                                <span ref={promptErrorRef} className="text-danger field-validation-valid ml-2 mt-1"></span>
                            </div>
                            {/* Genel Bilgiler */}

                            {/* Uygulamalar */}
                            <h5 className="font-bold flex items-center gap-3 text-[15px] pl-3 my-3 mt-3 max-xl:mb-2">
                                {strings.form_info.application_title}
                                <button
                                    type="button"
                                    className="flex-shrink-0 xl:mb-2 mt-2 group inline-flex items-center justify-center bg-transparent border border-blue text-blue w-8 h-8 rounded-[3px] relative"
                                    onClick={addApplicationRow}
                                >
                                      <span className="material-symbols-outlined text-[18px]">
                                        add
                                      </span>
                                    <div className="tooltip-top">
                                        <p className="font-normal">{strings.use_case.update.add_new_application}</p>
                                    </div>
                                </button>
                            </h5>
                            <hr />
                            {applications.map((application, index) => (
                                <ApplicationRowEditable
                                    key={index}
                                    item={application}
                                    applications={applications}
                                    setApplications={setApplications}
                                    isClickedUpdateButton={isClickedUpdateButton}
                                />
                            ))}
                            {/* Uygulamalar */}

                            {/* Endpoint */}
                            <h5 className="font-bold text-[15px] pl-3 my-3 mt-5">
                                <p>{strings.form_info.endpoint_title}</p>
                            </h5>
                            <hr />
                            <div className="px-3">
                                <div className="flex flex-col lg:flex-row lg:gap-x-5 lg:justify-between">
                                    <div className="mt-5 w-full lg:w-[32.5%]">
                                        <div className="flex flex-col px-[10px] pb-0">
                                            <label htmlFor="endpoint">
                                                {strings.form_info.endpoint}
                                                {isHaveParameter && (
                                                    <span className="text-red"> *</span>
                                                )}
                                            </label>
                                            <Input
                                                name="endpoint"
                                                type="text"
                                                onChange={(e) => setEndpoint(endpoint => e.target.value)}
                                                value={endpoint}
                                                classnames="md:max-w-[480px]"
                                            />
                                            <span ref={endpointErrorRef} className="text-danger field-validation-valid ml-2 mt-1"></span>
                                        </div>
                                    </div>
                                    <div className="mt-5 w-full lg:w-[32.5%]">
                                        <div className="flex flex-col px-[10px] pb-0">
                                            <label htmlFor="endpoint-test">
                                                {strings.form_info.endpoint_test}
                                                {isHaveParameter && (
                                                    <span className="text-red"> *</span>
                                                )}
                                            </label>
                                            <Input
                                                name="endpoint-test"
                                                type="text"
                                                onChange={(e) => setEndpointTest(endpoint => e.target.value)}
                                                value={endpointTest}
                                                classnames="md:max-w-[480px]"
                                            />
                                            <span ref={endpointTestErrorRef} className="text-danger field-validation-valid ml-2 mt-1"></span>
                                        </div>
                                    </div>
                                    <div className="mt-5 w-full lg:w-[32.5%]">
                                        <div className="flex flex-col px-[10px] pb-0">
                                            <label htmlFor="endpoint">
                                                {strings.form_info.request_type}
                                                {((endpoint.length > 0 || endpointTest.length > 0) || (isHaveParameter)) && (
                                                    <span className="text-red"> *</span>
                                                )}
                                            </label>
                                            <Dropdown
                                                data={[firstRequestType, ...RequestTypes]}
                                                selectedData={endpointRequestType}
                                                setSelectedData={setEndpointRequestType}
                                                classnames="md:max-w-[480px]"
                                            />
                                            <span ref={requestTypeErrorRef} className="text-danger field-validation-valid ml-2 mt-1"></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col lg:flex-row lg:gap-x-5 lg:justify-between">
                                    <div className="mt-5 w-full lg:w-[32.5%]">
                                        <div className="flex flex-col px-[10px] pb-0">
                                            <label htmlFor="endpoint">{strings.form_info.is_have_parameter}</label>
                                            <div className="flex justify-center w-[60px]">
                                                <input
                                                    id="is-have-parameter"
                                                    type="checkbox"
                                                    className="hidden"
                                                    onClick={() => setIsHaveParameter(!isHaveParameter)}
                                                />
                                                <label
                                                    htmlFor="is-have-parameter"
                                                    className={`${handleCheckbox(isHaveParameter)} mr-2 w-6 h-6 rounded-[3px] cursor-pointer duration-500 flex items-center justify-center`}
                                                >
                                                    <span className={classNames('text-inherit text-[25px] material-symbols-outlined animate-fadeIn font-bold', { 'hidden' : !isHaveParameter})}>
                                                      done
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5 w-full lg:w-[32.5%]">
                                        {isHaveParameter && (
                                            <div className="flex flex-col px-[10px] pb-0">
                                                <label htmlFor="endpoint">
                                                    {strings.form_info.parameters}
                                                    {isHaveParameter && (
                                                        <span className="text-red"> *</span>
                                                    )}
                                                </label>
                                                <DropdownMultiSelect
                                                    randomId="parameterTypesMS"
                                                    title={strings.detail_search_titles.parameters}
                                                    data={ParameterTypes || []}
                                                    selectedData={parameters}
                                                    setSelectedData={setParameters}
                                                    classnames="md:max-w-[480px]"
                                                    inputPlaceholder={strings.detail_search_titles.search_parameters}
                                                />
                                                <span ref={parametersErrorRef} className="text-danger field-validation-valid ml-2 mt-1"></span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="mt-5 w-full lg:w-[32.5%]"></div>
                                </div>
                                {isHaveParameter && (
                                    <div className="flex flex-col px-[10px] pb-0 w-full my-5">
                                        <label htmlFor="request_body">{strings.form_info.request_body}</label>
                                        <textarea
                                            name="request_body"
                                            className={`border rounded-lg outline-0 focus:border-blue py-3 px-2 w-full`}
                                            rows="5"
                                            disabled={true}
                                            value={JSON.stringify(endpointRequestBody, null, 4)}
                                        ></textarea>
                                    </div>
                                )}
                            </div>
                            {/* Endpoint */}

                            {/* Başlık Çevirileri */}
                            <h5 className="font-bold flex items-center gap-3 text-[15px] pl-3 my-3 mt-3 max-xl:mb-2">
                                <p>{strings.form_info.title_translations}</p>
                                {translations.length !== LanguageList.length && (
                                    <button
                                        type="button"
                                        className="flex-shrink-0 xl:mb-2 mt-2 group inline-flex items-center justify-center bg-transparent border border-blue text-blue w-8 h-8 rounded-[3px] relative"
                                        onClick={addTranslationRow}
                                    >
                                      <span className="material-symbols-outlined text-[18px]">
                                        add
                                      </span>
                                      <div className="tooltip-top">
                                        <p className="font-normal">{strings.use_case.update.add_new_translation}</p>
                                      </div>
                                    </button>
                                )}
                            </h5>
                            <hr />
                            {translations.map((translation, index) => (
                                <TranslationRowEditable
                                    key={index}
                                    item={translation}
                                    translations={translations}
                                    setTranslations={setTranslations}
                                    isClickedUpdateButton={isClickedUpdateButton}
                                />
                            ))}
                            {translations.length === 0 && (
                                <div className="py-7 min-w-full text-center">{strings.use_case.update.translation_not_found}</div>
                            )}
                            {/* Başlık Çevirileri */}

                            <button
                                type="submit"
                                className="button search-button text-white bg-blue border-blue mt-7 mx-auto px-7 mb-5">
                                {strings.button.update}
                            </button>
                        </form>
                    </div>
                </>
            )}
        </>
    );
}

export default UpdateUseCase;