import React, { forwardRef, useEffect, useState } from "react";
import {Link, useNavigate} from "react-router-dom";
import useAuth from "../../hooks/Log/useAuth";
import { useLocalization } from "../../hooks/Utils/useLocalization";
import { url } from "../../routes/utility";
import { GetUnansweredTicket } from "../../services/Ticket/TicketService";
import { closeInfo, toggleInfo } from "../Toggle";
import { getLogoByBrandId, ISOStringToDate } from "../Utility";

const NavbarTicket = forwardRef((props, ref) => {

    const strings = useLocalization();
    const { employee } = useAuth();
    const [data, setData] = useState([]);
    const navigate = useNavigate();

    const getUnansweredTickets = async () => {
      const result = await GetUnansweredTicket();

      if(result.status === 200){
        let resultContent = JSON.parse(result.content);
        setData(data => resultContent);
      }
    }

    useEffect(() => {
      getUnansweredTickets();
    }, [])

    return (
      <>
        <p className="px-4 font-bold py-3">{strings.navbar.tickets.title}</p>
        <hr />
        <div className={`max-h-64 transition-all duration-300 py-1 ${data.length > 3 ? 'overflow-y-scroll sm:overflow-y-hidden hover:overflow-y-scroll' : ''}`}>
          {data.length > 0 ? (
            data.map((ticket, index) => (
                <Link
                  key={index} 
                  className="text-start w-full"
                  to={url("ticket.detail", { ticketId: ticket.id })}
                  onClick={() => closeInfo(ref)}
                >
                  {index !== 0 && <hr />}
                  <div className="px-4 flex gap-2 py-2 bg-white hover:bg-[#f0f0f0]">
                    <div className="w-10 h-10 flex shrink-0">
                      <img src={getLogoByBrandId(ticket.brand)} width="40" height="40" alt="KO logo" />
                    </div>
                    <div>
                      <p className="font-bold">{ticket.member.fullName}</p>
                      <p>{ticket.message}</p>
                      <p className="text-[13px] !text-[#989898]">{ISOStringToDate(ticket.ticketDate, employee.TimeZone)}</p>
                    </div>
                  </div>
                </Link>
              )))
              :
              (
                <div className="py-4 min-w-full text-center bg-white hover:bg-[#f0f0f0]">{strings.errors.no_ticket_found}</div>
              )
          }
        </div>
        <hr />
        <Link to={url("ticket")} className="px-4 py-3 flex justify-between">
          <p>{strings.navbar.tickets.list_all}</p>
          <span className="material-symbols-outlined text-base font-bold">
            chevron_right
          </span>
        </Link>
      </>
    );
  });

  export default NavbarTicket;