import { useState } from "react";
import { useLocalization } from "../../../../hooks/Utils/useLocalization"
import { Modal, NoRecordsFound } from "../../../../components";
import CandidatePoolReportTableRow from "./CandidatePoolReportTableRow";
import CandidatePoolLogModal from "../../../Member/components/CandidatePool/CandidatePoolLogModal";

const CandidatePoolReportTable = ({ data, showWhatsappReturnButton, isFirstRequest }) => {

    const strings = useLocalization();
    const [modal, setModal] = useState(false);
    const [modalContent, setModalContent] = useState({});

    return (
        <>
            {isFirstRequest && (
                <div className="mx-auto px-4 my-4">
                    <div
                        className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
                        <div className="inline-block min-w-full rounded-lg overflow-hidden">
                            <table className="min-w-full leading-normal text-header">
                                <thead>
                                    <tr>
                                        <th className="th">
                                            <p className="ml-6">{strings.table.brand}</p>
                                        </th>
                                        <th className="th">
                                            {strings.table.candidate_informations}
                                        </th>
                                        <th className="th">
                                            {strings.table.application_informations}
                                        </th>
                                        <th className="th">
                                            {strings.table.operation_informations}
                                        </th>

                                        {showWhatsappReturnButton && (
                                            <th className="th">
                                                <p className="min-w-[80px]">
                                                    {strings.table.operation}
                                                </p>
                                            </th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.length > 0 ?
                                        (
                                            data.map((member, index) => (
                                                <CandidatePoolReportTableRow
                                                    key={index}
                                                    member={member}
                                                    setModal={setModal}
                                                    setModalContent={setModalContent}
                                                    showWhatsappReturnButton={showWhatsappReturnButton}
                                                />
                                            ))
                                        )
                                        :
                                        (
                                            <tr className="bg-white hover:bg-[#f0f0f0]">
                                                <td colSpan="5">
                                                    <NoRecordsFound />
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                            {(Object.keys(modalContent).length !== 0) && (
                                <Modal modal={modal} setModal={setModal} classes={
                                    {
                                        modal: "h-[90vh] max-w-[1140px]",
                                        content: "overflow-y-scroll !mr-0"
                                    }}>
                                    {modalContent.element === "log" && <CandidatePoolLogModal memberId={modalContent.memberId} classes="!h-[85vh] pr-3" />}

                                </Modal>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {!isFirstRequest && (
                <div className="py-4 min-w-full text-center">{strings.table.filter_for_result}</div>
            )}
        </>
    )
}

export default CandidatePoolReportTable