import React, { useEffect, useState } from 'react';
import { useLocalization } from '../../hooks/Utils/useLocalization';
import { InvoiceDetailSearch, InvoiceTable } from './components';
import Pagination from '../../components/Pagination'
import { ContentCount, PageTitle, SearchBar, Loading, showTopAlert } from '../../components';
import { stringToDate } from '../../components/Utility';
import { InvoiceFilter } from '../../services/Accounting/InvoiceService';
import useAuth from '../../hooks/Log/useAuth';
import NotAuthorized from '../../components/NotAuthorized';

const Invoice = () => {

    const strings = useLocalization();
    const { getPermission, checkPermission, employeeTypeId } = useAuth();

    const roles = getPermission();
    const permission = checkPermission(roles, employeeTypeId);

    const [start, setStart] = useState(0);
    const limit = parseInt(process.env.REACT_APP_TABLE_ROW_LIMIT);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecord, setTotalRecord] = useState(0);
    
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [checkedInvoiceCount, setCheckedInvoiceCount] = useState(null);
    const [uncheckedInvoiceCount, setUncheckedInvoiceCount] = useState(null);  
    
    const [detailSearch, setDetailSearch] = useState({
        beginDate: null,
        endDate: null,
        invoiceStatus: -1,
        searchText: ''
    });

    //Arama verileri değiştiğinde değerleri sıfırlıyoruz
    const resetValue = () => {
        setStart(start => 0);
        setCurrentPage(currentPage => 1);
        setLoading(loading => true);
    }

    //select değişiklikleri bu alandan yönetiliyor
    const changeDetail = (values) => {
        setDetailSearch({ ...detailSearch, ...values });
        resetValue();
    }

    //input değişiklikleri bu alandan yönetiliyor
    const changeValue = (value) => {
        setDetailSearch({ ...detailSearch, searchText: value });
        resetValue();
    }

    const getInvoice = async () => {
        const model = {
            start: start,
            length: limit,
            beginDate: detailSearch.beginDate ? stringToDate(detailSearch.beginDate) : null,
            endDate: detailSearch.endDate ? stringToDate(detailSearch.endDate, 23, 59, 59) : null,
            status: detailSearch.invoiceStatus
        }

        const result = await InvoiceFilter(model);

        if(result.status === 200){
            let resultContent = JSON.parse(result.content);

            setData(data => resultContent.data)
            setTotalRecord(totalRecord => resultContent.recordsTotal);
            setCheckedInvoiceCount(checkedInvoiceCount => resultContent.checkedInvoiceCount);
            setUncheckedInvoiceCount(uncheckedInvoiceCount => resultContent.uncheckedInvoiceCount);
            setLoading(loading => false);
        } else {
            showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
        }
    }

    //sayfa değiştikçe bilgileri yeniden çağırıyoruz
    useEffect(() => {
        if (totalRecord !== 0) {
            getInvoice();
        }
    }, [start])

    useEffect(() => {

        let abortController = new AbortController();

        // Kullanıcının sayfa görüntüleme izni yoksa servise gitmeyip performans kaybını önlüyoru<
        permission && getInvoice();

        return () => {
            abortController.abort();
        }
    }, [detailSearch, permission])

    return (
        <>
            {permission && (
                <>
                    <InvoiceDetailSearch 
                        changeDetail={changeDetail} 
                        loading={loading}
                    />
                    <SearchBar 
                        changeValue={changeValue} 
                        placeHolder={strings.search_bar.search_invoice}  
                        isDisabled={loading}
                    />
                    {!loading && 
                        <ContentCount
                            greenText={strings.accounting.invoice.invoice_success_count}
                            greenCount={checkedInvoiceCount}
                            redText={strings.accounting.invoice.invoice_error_count}
                            redCount={uncheckedInvoiceCount}  
                        />
                    }
                </>
            )}
            <PageTitle title={strings.sidebar.accounting.invoices} />
            {permission ? (
                loading ? 
                (
                    <Loading />
                )
                :
                (
                    <>
                        <InvoiceTable data={data} />
                        <Pagination 
                            totalCount={totalRecord}
                            limit={limit}
                            start={start}
                            setStart={setStart}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            setLoading={setLoading}
                            loadScreen={true}
                        />
                    </>
                )
            ) : (<NotAuthorized />)}
        </>
    )
}

export default Invoice