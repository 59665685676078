import NoRecordsFound from '../../../components/NoRecordsFound';
import { useLocalization } from '../../../hooks/Utils/useLocalization'
import TicketTableRow from './TicketTableRow';

const TicketTable = ({ data }) => {
    const strings = useLocalization();

    return (
        <div className="mx-auto px-4 my-4">

            <div
                className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
                <div className="inline-block min-w-full rounded-lg overflow-hidden">
                    <table className="min-w-full leading-normal text-header">
                        <thead>
                            <tr>
                                <th className="th min-w-max">
                                    <p className='ml-6'>{strings.table.brand}</p>
                                </th>
                                <th className="th min-w-max">
                                    <p className="min-w-max">{strings.table.ticket}</p>
                                </th>
                                <th className="th min-w-max">
                                    <p className="min-w-max">{strings.table.consultant}</p>
                                </th>
                                <th className="th min-w-max">
                                    <p className="min-w-max">{strings.table.application_informations}</p>
                                </th>
                                <th className="th min-w-max">
                                    <p className="min-w-max">{strings.table.operation}</p>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.length > 0 ? data.map((ticket, index) => (
                                <TicketTableRow
                                    key={index}
                                    ticket={ticket}
                                />
                            )) : (<tr className="bg-white hover:bg-[#f0f0f0]"><td colSpan="4"><NoRecordsFound /></td></tr>)}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default TicketTable