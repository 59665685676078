import { useLocalization } from "../../../../hooks/Utils/useLocalization";
import { colorForTermStatus, getBrandNameById, getLogoByBrandId, isItSpecified, maskPhoneNumber, memberLessonDetailList } from "../../../../components/Utility";
import { Link } from "react-router-dom";
import { url } from "../../../../routes/utility";
import useAuth from "../../../../hooks/Log/useAuth";
import { CopyToClipboard, CorporateBadge } from "../../../../components";
import classNames from "classnames";
import { TermStatusType } from "../../../../consts/Constants";

const StudentTableRow = (props) => {
  const strings = useLocalization();
  const { member } = props;
  const { employee } = useAuth();

  const notSpecified = strings.errors.it_is_not_specified;

  return (
    <tr className="bg-white hover:bg-[#f0f0f0]">
      <td className="td">
        <div className="group relative max-w-max ml-6">
          <img src={getLogoByBrandId(member.brandId)} width="43" height="48" alt="" />
          <div className="tooltip-top">
            <p>{getBrandNameById(member.brandId)}</p>
          </div>
        </div>
        {member.corporateStatus === 1 && (
          <CorporateBadge className="mt-2 ml-0.5" showFirmName={false} />
        )}
      </td>
      <td className="td">
        <div className="flex items-center">
          <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
            person
          </span>
          <Link
            to={url("member.detail.profile", { memberId: member.memberId })}
            target="_blank"
            className="font-bold text-blue hover:underline focus:underline"
          >
            <p className="min-w-max">{member.name}</p>
          </Link>
        </div>
        <div className="flex items-center">
          <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
            call
          </span>
          <div className="flex items-center gap-0.5">
            <p className="min-w-max">{maskPhoneNumber(member.phone)}</p>
            <CopyToClipboard text={member.phone} className="-mb-1" iconClassName="!text-[13px]" />
          </div>
        </div>
        <div className="flex items-center">
          <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
            mail
          </span>
          <p>{isItSpecified(member.email, notSpecified)}</p>
        </div>
      </td>
      <td className="td">
        {member.employees && member.employees.map((employee, index) => (
          <div key={index} className="badge max-w-[320px] my-1">
            <p className="min-w-max">
              <b>{employee.key}:</b> {employee.value}
            </p>
          </div>
        ))}
      </td>
      <td className="td">
        {member.terms && member.terms.map((term, index) => (
          <div key={index} className="my-1 flex gap-4 items-center">
            <div className={classNames(`!max-w-[280px] ${colorForTermStatus(term.status)}`, {
              "!bg-[#666666]": term.isFrozenTerm && term.status === TermStatusType.Active
            })}>
              <p>{term.packageName}</p>
              {(term.isFrozenTerm && term.status === TermStatusType.Active) && (
                <p className="!normal-case font-bold -mt-1 text-white">({strings.member.member_detail.terms.frozen_lesson})</p>
              )}
            </div>
            <div className="group relative">
              <p className="min-w-[45px] !max-w-[45px] text-base-text bg-card badge-color cursor-default">{term.remainingLessonCount ? term.remainingLessonCount : "0"}</p>
              <span className="tooltip-top">{strings.teacher.teacher_program.remaining_lesson_count}</span>
            </div>
            <p className="min-w-max">{memberLessonDetailList(term.detail, employee.TimeZone)}</p>
          </div>
        ))}
      </td>
    </tr>
  );
};

export default StudentTableRow

