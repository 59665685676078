import { useEffect, useRef } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LogoBeyaz } from "../../assets/svg";
import useAuth from "../../hooks/Log/useAuth";
import { useLocalization } from "../../hooks/Utils/useLocalization";
import { url } from "../../routes/utility";
import { employeeLogout } from "../../store/EmployeeSlice";
import {
  animateArrow, closeSidebarMobile, toggleProfile, toggleSidebarMobile
} from "../Toggle";
import { employeeTypeTranslations } from "../Utility";
import { ProductReportOperations, AccountingOperations, EmployeeOperations, HistoryOperations, MainOperations, MemberOperations, ReportOperations, TeacherOperations, UseCaseOperations } from "./components";
import useMobileView from "../../hooks/Utils/useMobileView";
import {alotechLogout} from "../../store/AlotechSlice";

const SidebarMobile = () => {

  const strings = useLocalization();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { employee, employeeType } = useAuth();
  const { isMobileView } = useMobileView();

  const language = useSelector(state => state.localizationStore.language);

  const sidebarRef = useRef();
  const profileContentRef = useRef();
  const mobileOverlayRef = useRef();
  const mobileButtonRef = useRef();
  const animateArrowRef = useRef();

  const location = useLocation();
  const pathName = location?.pathname;

  useEffect(() => {
    const handleSidebarOpen = () => {
      if (isMobileView) {
        toggleSidebarMobile(sidebarRef, mobileOverlayRef, mobileButtonRef);
      }
    };

    handleSidebarOpen();
  }, [isMobileView, pathName]);

  return (
    <>
      <div className="relative z-40 md:hidden" role="dialog" aria-modal="true">
        <div
          ref={mobileOverlayRef}
          id="transparent_bg"
          className="fixed inset-0 bg-gray-600 bg-opacity-75 animate-fadeIn"
        ></div>
        <OutsideClickHandler
          onOutsideClick={() =>
            closeSidebarMobile(sidebarRef, mobileOverlayRef, mobileButtonRef)
          }
        >
          <div
            id="sidebar_mobile"
            ref={sidebarRef}
            className="fixed w-[250px] inset-0 z-40 flex duration-500"
          >
            <div className="relative flex w-full max-w-[250px] flex-1 flex-col bg-white pb-4">
              <div className="flex items-center justify-between pr-2 px-3 bg-blue h-[62px] shadow-lg">
                <Link
                  to={url("dashboard")}
                  className="flex flex-shrink-0 items-center px-4 mb-[3px]"
                >
                  <img
                    className="h-[33px] w-auto"
                    src={LogoBeyaz}
                    alt="ClickIVO Prime"
                  />
                </Link>

                {/* <!-- Mobil view --> */}
                <button
                  ref={mobileButtonRef}
                  className="mr-4"
                  onClick={() =>
                    toggleSidebarMobile(
                      sidebarRef,
                      mobileOverlayRef,
                      mobileButtonRef
                    )
                  }
                >
                  <span className="material-symbols-outlined text-white text-3xl menu-btn">
                    menu
                  </span>
                </button>
              </div>
              <div className="h-0 flex-1 overflow-y-scroll sm:overflow-y-hidden hover:overflow-y-scroll">
                {/* <!-- Mobil --> */}
                <nav className="space-y-1 px-2">
                  <div>
                    <div id="accordion_mobil" className="w-full text-[13px]">
                      <input
                        type="checkbox"
                        name="panel"
                        id="panel-mobil"
                        className="hidden"
                      />
                      <a
                        id="panel-mobil"
                        htmlFor="panel-1"
                        className="label relative block bg-transparent px-4 py-4 cursor-pointer"
                        onClick={() => {
                          toggleProfile(profileContentRef);
                          animateArrow(animateArrowRef);
                        }}
                      >
                        <div className="flex items-center px-1">
                          <div className="ml-2 flex items-center justify-between w-full toggled">
                            <div>
                              <p className="mb-[2px] text-[#777]">
                                {employee.FullName}
                              </p>
                              <p className="font-bold text-[#555]">
                                {employeeTypeTranslations(employeeType, language)}
                              </p>
                            </div>
                            <div
                              ref={animateArrowRef}
                              className="dropdown-arrow transition-transform duration-500 absolute right-4 top-[calc(50%-2px)]"
                            ></div>
                          </div>
                          <img
                            className="rounded-full order-first"
                            src={
                              process.env.REACT_APP_PROFILE_URL +
                              "/employee/" +
                              employee.ProfilePicture
                            }
                            width="42"
                            height="42"
                            alt=""
                          />
                        </div>
                      </a>
                      <div
                        ref={profileContentRef}
                        className="accordion__content py-3 px-5 overflow-hidden transition-max-height duration-500 animate-fadeOut max-h-0 bg-transparent -mt-[25px] w-full"
                      >
                        <p className="mb-1">
                          {strings.sidebar.profile.my_profile}
                        </p>
                        <a
                          className="py-1 px-2 cursor-pointer hover:bg-gray-100 focus:bg-blue flex w-full"
                          onClick={() => {
                            navigate(url("dashboard"));
                            dispatch(employeeLogout());
                            dispatch(alotechLogout());
                          }}
                        >
                          {strings.sidebar.profile.logout}
                        </a>
                      </div>
                    </div>
                    <hr className="mx-2" />
                  </div>
                  <div className="pt-4">
                    
                    <MainOperations />

                    <MemberOperations />

                  </div>

                  <TeacherOperations />

                  <AccountingOperations />

                  <ReportOperations />

                  <ProductReportOperations />

                  <EmployeeOperations />

                  <UseCaseOperations />

                  <HistoryOperations />

                </nav>
              </div>
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    </>
  );
};

export default SidebarMobile;
