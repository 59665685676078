import { fetchApi } from '../index';

//Demo kayıt sırasında kullanılan servis.
export const AddDemoProduct = async (model) => {
    const url = '/member/v3/term/add/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

//Saatlik indirim kısmında kullanılan servis
export const AddHourlyDiscount = async (model) => {
    const url = '/member/v1/member/add_hourly_discount/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const GetHourlyDiscountRates = async (languageTitleType) => {
    const url = '/member/v1/hourly_discount_rate/get_hourly_discount_rates/';

    const payload = {
        languageTitleType: languageTitleType
    };

    return await fetchApi(url, payload);
}

export const GetFamilyDiscountRates = async (brandId) => {
    const url = '/member/v1/campaign/family_discounts/';

    const payload = {
        brandId: brandId
    };

    return await fetchApi(url, payload);
}

//Kullanıcının sadece demo kayıtı olup olmadığını anlamamıza yarayan servis.
export const CheckDemoByMemberId = async (memberId) => {
    const url = '/member/v1/term/check_demo_only_by_member_id/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const ChangePackage = async (model) => {
    const url = '/member/v1/sales/change_package/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}