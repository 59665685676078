import {useLocalization} from "../../hooks/Utils/useLocalization";
import {PageTitle} from "../../components";
import {Accordion} from "../Member/components";
import React from "react";

const CallReport = () => {
    const strings = useLocalization();

    return (
        <>
            <PageTitle title={strings.sidebar.report.call_report} />

            <Accordion
                title={strings.report.call_report.information_title}
                outSideClose={true}
                isOpen={true}
                content={<p dangerouslySetInnerHTML={{ __html: strings.report.call_report.information_one}}></p>}
            />
            <div className="flex justify-center mt-5">
                <iframe title="call" width="1725" height="1725" src="https://lookerstudio.google.com/embed/reporting/3ba4c1ca-005e-4154-9d5f-457323db68e7/page/BTKpD" frameBorder="0" allowFullScreen></iframe>
            </div>
        </>
    )
}

export default CallReport;