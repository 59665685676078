import React, { useEffect, useState } from 'react';
import { DropdownMultiSelect, showTopAlert } from '../../../../../../components';
import { ContactType } from '../../../../../../consts/Constants';
import { useLocalization } from '../../../../../../hooks/Utils/useLocalization';
import { AddMemberContactPermission, GetMemberContactPermissions } from '../../../../../../services/Member/MemberContactService';


const ContactPermission = (props) => {

    const { memberId, closeButton } = props;
    const strings = useLocalization();

    const [contactType, setContactType] = useState([]);
    const [dropdownContactType, setDropdownContactType] = useState([]);
    const [contactPermissions, setContactPermissions] = useState([]);

    const allContactTypes = ContactType.map(contact => contact.id);

    //Mevcut iletişim izinlerini getiriyoruz.
    const getMemberContactPermissions = async (memberId) => {
        const result = await GetMemberContactPermissions(memberId);
        if (result.status === 200) {
            setContactPermissions(contactPermissions => JSON.parse(result.content));
            setDropdownContactType(allContactTypes.filter(type => !contactPermissions.includes(type)));
        }
        
    }

    const submitHandle = async e => {
        e.preventDefault();

        const model = {
            memberId: memberId,
            contactTypeIds: contactType,
        };

        //İletişim izinlerini güncelliyoruz
        const result = await AddMemberContactPermission(model);
        if (result.status === 200) {
            showTopAlert(strings.member.member_detail.others.contact_permission_success, "success");
            setTimeout(() => closeButton(), 1500);
        } else {
            showTopAlert(strings.errors.an_error_occurred + " - " + result.content, "error");
        }
    }

    useEffect(() => {
        memberId && getMemberContactPermissions(memberId)
    }, [memberId])

    useEffect(() => {

        // Dropdown'a getMemberContactPermissions'dan gelen verilerin tersini gönderip
        // iletişim izni verilenlerin seçili görünmesini sağlıyoruz.
        const contactPermissionIds = contactPermissions.map(contactPermission => contactPermission.contactTypeId)
        setDropdownContactType(allContactTypes.filter(type => !contactPermissionIds.includes(type)));
    }, [contactPermissions])

    useEffect(() => {

        setContactType(allContactTypes.filter(type => !dropdownContactType.includes(type)));
    }, [dropdownContactType])

    return (
        <form onSubmit={submitHandle} className="flex flex-col text-start gap-5">
            <div className="flex flex-col px-[10px] pb-0 w-full">
                <label>{strings.member.member_detail.others.contact_permission}</label>
                <DropdownMultiSelect
                    classnames="md:max-w-full"
                    data={ContactType}
                    //Servisten gelen veriler boş array yerine eklenecektir.
                    selectedData={dropdownContactType}
                    setSelectedData={setDropdownContactType}
                    title={strings.detail_search_titles.contact_permission}
                    randomId="contactPermissionListMS"
                    inputPlaceholder={strings.detail_search_titles.search_contact}
                />
            </div>
            <div className="flex gap-3 mt-3 justify-center">
                <button
                    type='submit'
                    className="button search-button gap-3 text-white bg-blue border-blue focus:border-blue px-10"
                >
                    {strings.button.save_upper}
                </button>
                <button
                    onClick={closeButton}
                    className="button search-button gap-3 text-white bg-red border-red focus:border-red px-10"
                >
                    {strings.button.cancel_upper}
                </button>
            </div>
        </form>
    )
};

export default ContactPermission;