const PriceShape = ({ color="#1572E8", children }) => {
    return (
        <svg width="230" height="220" viewBox="0 0 230 220" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23 64L23 155" stroke={color} strokeWidth="2" strokeLinecap="round"/>
            <path d="M1 51L1.00001 169" stroke={color} strokeWidth="2" strokeLinecap="round"/>
            <path d="M207 64L207 155" stroke={color} strokeWidth="2" strokeLinecap="round"/>
            <path d="M229 51L229 169" stroke={color} strokeWidth="2" strokeLinecap="round"/>
            <path d="M4.66089 42.0593L115.661 2.05933" stroke={color} strokeWidth="2" strokeLinecap="round"/>
            <path d="M115.997 1.89551L224.942 41.1611" stroke={color} strokeWidth="2" strokeLinecap="round"/>
            <path d="M25.5708 56.5593L115.751 24.057" stroke={color} strokeWidth="2" strokeLinecap="round"/>
            <path d="M115.951 24.0239L201.988 55.0331" stroke={color} strokeWidth="2" strokeLinecap="round"/>
            <path d="M224.942 178.5L113.942 218.5" stroke={color} strokeWidth="2" strokeLinecap="round"/>
            <path d="M113.606 218.664L4.66078 179.398" stroke={color} strokeWidth="2" strokeLinecap="round"/>
            <path d="M204.032 164L113.852 196.502" stroke={color} strokeWidth="2" strokeLinecap="round"/>
            <path d="M113.652 196.535L27.6148 165.526" stroke={color} strokeWidth="2" strokeLinecap="round"/>
            <foreignObject x="0" y="0" width="100%" height="100%">
                {children}
            </foreignObject>
        </svg>
    )

}

export default PriceShape;